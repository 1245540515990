/* eslint-disable prettier/prettier */
/* eslint-disable no-dupe-else-if */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable use-isnan */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable sonarjs/no-inverted-boolean-check */


/* eslint-disable prettier/prettier */

/* eslint-disable no-constant-condition */

/* eslint-disable no-compare-neg-zero */
/* eslint-disable sonarjs/no-identical-expressions */
/* eslint-disable no-console */
/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-small-switch */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-irregular-whitespace */

/* eslint-disable no-console */

/* eslint-disable no-debugger */

import React, { useEffect, useState, useContext } from "react";
import { ClubReq, ldwReq, policyReq } from "./paymentgrid";
import { ReactComponent as DeleteIcon } from "../../assets/images/layer28.svg";
import { ReactComponent as Visacard } from "../../../src/assets/images/Visa-Card.svg";
import { ReactComponent as Mastercard } from "../../../src/assets/images/Mastercard.svg";
import CurrencyInput from "react-currency-input-field";
import { ReactComponent as SuccessIcon } from "../../../src/assets/images/success-icon.svg";
import { ReactComponent as Txt2payimg } from "../../../src/assets/images/Txt-Pay-Icon.svg";
import { ReactComponent as AlertIcon } from "../../assets/images/no-records-found.svg";
import { ReactComponent as AmericanExpress } from "../../assets/images/American Express - img.svg";
import { ReactComponent as DinersClub } from "../../assets/images/Diners Club-img.svg";
import { ReactComponent as JCB } from "../../assets/images/JCB - img.svg";
import { ReactComponent as Paypal } from "../../assets/images/paypal-img.svg";
import { ReactComponent as Discover } from "../../assets/images/Discover - img.svg";
import { ReactComponent as Info } from "../../assets/images/info.svg";
import {
  UserStateContext,
  UserThunksContext,
} from "../../context/user/user-contexts";
import moment from "moment";
import { OriginContext } from "../../context/OriginContext";
import { AMOrigin, PAYMENT_ORIGIN_TYPE, IN_STORE_T2P_ORIGIN, FINAL_PAYMENT_AGREEMENT, NOT_ALLOWED_AGREEMENT_TYPE } from "../../constants/constants";
import AdjustDueDatecalci from "./AdjustDueDateCalci";
import NsfCheckPay from "../../racstrapComponents/microfrontends/NsfcheckPay";
// import { CustomerdataContext } from "../../context/customer/customerdata";
//syed change 8 (icons)
import { ReactComponent as Infosus } from "../../assets/images/infosus.svg";
import { ReactComponent as Deleteicon } from "../../assets/images/delete.svg";

import { ReactComponent as Step1img } from "../../assets/images/step1img.svg";
import { ReactComponent as Step2img } from "../../assets/images/step2img.svg";
import { ClipLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import { ContainerContext } from "../../app/App";
import { CustomPropInterface } from "../../index";
//import 'react-phone-number-input/style.css';
// import PhoneInput from 'react-phone-number-input/input';
import { Payment } from "./AcceptPayment";
import { ProcessPayment } from "./ProcessPayment";
import { AvailableCredits } from "./AvailableCredits";
import { CardTokendto } from "../../domain/deletecardpayload";
import { GetAllPaymentInfoRequest } from "../../../src/domain/getpaymentinforequest";
import OutsideClickHandler from "react-outside-click-handler";
import {
  GetPaymentInformation,
  AllPaymentInfo,
  AmountDueResponse,
} from "../../../src/domain/getpaymentinforesponse";
import { PaymentCustomerIdFiltersDTO } from "../../domain/getCustomerCardDTO";
import { getRegistrationId, loadVantivIFrame } from "./vantiv-iframe.service";
import { CreditCard } from "./CreditCard";
import { ProcessPaymentLogRequest } from "./ProcessPaymentLogRequest";
import Autopay from "./AutopayPopup";
import { AutopaySendMessageRequest } from "../../domain/Autopaypayload";
import {
  AgreementVals,
  ReceiptCategoriesRequestDTO,
  AcceptClubPaymentParam,
  AcceptPaymentParam,
  CheqAndMO,
  AcceptPaymentInfo,
  CardChk,
} from "../../../src/components/microfrontends/paymentgrid";
import { PaymentInfo } from "../../domain/swipecardpayload";
import {
  SaveCardRequestDTO,
  CustomerInfo,
  BillAddressInfo,
  SaveBillinginfo,
} from "../../domain/Savecardpayload";
import { Text2payDTO } from "../../domain/sendtxt2pay";
import {
  Deletecustomercard,
  Savecustomercard,
  Swipecustomercard,
  getpaymentinfo,
  calculatePaymentModule,
  GetCardInfo,
  LDwServiceCall,
  PolicyServiceCall,
  Sendtxt2pay,
  ActiveClub,
  // UnEnrollAutoPay,
  EnrollAutopay,
  UpdateAlert,
  sendMessage,
  GetCustomerAlert,
  getprofileaccess,
  GetAlert,
  GetLegalSatatus,
  getmenuandstore,
  getsecondfactor,
  getCurrentInfo,
  getPinDetails,
  CustomerCoverage,
  StoreswipeDevice,
  GetState,
  GetEmployeeID,
  GetRole,
  getpaymentHistory,
  CoworkerRequired,
  GetCustomerByIdInfo,
  GetTaxRateConv,
  GetCustomerCoverage,
  GetCustomerAddress,
  UpdateAddressInfo,
  getTax,
  getRetentionOffers,
  postAgreementActivity,
} from "../../api/user";
//import { isInteger } from "lodash";
import { ReactComponent as ExpandAlert } from "../../assets/images/expand-alert-icon.svg";
import { ReactComponent as ExpandCancel } from "../../assets/images/alert-cancel-icon.svg";
import {
  RACSelect,
  RACTextbox,
  RACButton,
  RACCheckBox,
  RadioColor,
  RACRadio,
  RACModalCard,
  RACToggle,
  RACChip,
  RACTable,
  RACTableRow,
  RACTableCell,
  RACBadge,
  Grid,
  Typography,
  List,
  ListItem,
  Card,
  CardContent,
  Box,
  CircularProgress,
  RACCOLOR,
  makeStyles,
  RACAlert,
  RACInfoIcon,
  RACTooltip,
} from "@rentacenter/racstrap";
import { DeliveryContext } from "../../context/DeliveryContext";
import { userTraceContextData } from "../../context/UserTraceContext";
// import { ReactComponent as AccordianOpenIcon } from "../../assets/images/accordion-open-icon.svg";
// import { ReactComponent as AccordianCloseIcon } from "../../assets/images/accordion-close-icon.svg";
import AccordianOpenIcon from "../../assets/images/opened-accordian.png";
import AccordianCloseIcon from "../../assets/images/closed-accordian.png";
import PopUpCarryWaive from "../../racstrapComponents/microfrontends/Carrywaive";
import { ReactComponent as InfoiconGrey } from "../../assets/images/Info-icon-grey.svg";
import { ReactComponent as RacLogo } from "../../assets/images/RAC-Logo.svg";
import NoRecordsFound from "../../assets/images/alertIconpng.png";
import PinField from "react-pin-input";
// import { fas } from "@fortawesome/free-solid-svg-icons";
import { parse } from "path";
import { useHistory } from "react-router";
import { group } from "console";
import { takePaymentStyles } from "../../stylesJS/makeStyles";
import { number } from "yup";
import SecondFactor from "../../racstrapComponents/microfrontends/TwoFactorPopup";
import {
  ClubActivityInfo,
  ClubHistoryGridInfo,
  ClubInfo,
  CustomerInfoHis,
  Rootobject,
} from "../../domain/getPaymentHistory";
/**
 * Changes:Carry Rent
 * Developer:Muzzamil
 * Desc:Import the carry rent omponent
 */
import { CarryRent } from "./CarryRent";
import ClubPayment from "./clubPaymentHistory";
import DeliverySlots from "./paymentHistory";
import AdjustDueDatePopup from "../../racstrapComponents/microfrontends/AdjustDueDate";
import PromoFreeDays from "../../racstrapComponents/microfrontends/PromoFreeDays";
import EditSchedule from "../../racstrapComponents/microfrontends/EditSchedule";
import NonInventorySales from "../../racstrapComponents/microfrontends/CashSale";
import { Redirect, useLocation } from "react-router-dom";
import { AppRoute } from "../../config/route-config";
import { PayHistory } from "../../stylesJS/PayHistory";
import AcceptEpo from "./AcceptEpo";
import EpoSchedule from "./EpoSchedule";
import { CustomerInformation } from "./CustomerInformation";
import { ChartContext } from "../../context/paymentTriangle/chartcontext";
import { HistorydataContext } from "../../context/history/historyContext";
import { orderBy } from "lodash";
import { StateDataContext } from "../../context/stateapi/stateapicontext";
import { CustomerdataContext } from "../../context/customer/customerdata";
import FreeTime from "./FreeTime"; // Added By Me
import CreditChargeBack from "./CreditChargeBack";
import PopUpCarryWaiveLate from "./CarrywaiveLateFee";
import PopUpBackRent from "../../racstrapComponents/microfrontends/Backrent";
import ChargeoffPopup from "./ChargeOff";
import { RentaldataContext } from "../../context/Riscontext";
import RefundDeposit from "./RefundDeposit";
import NonInventorySalesUpdated from "./NonInventorySale";
import calculationModulePayloadFormation from "./commonModulePayloadFormation";
import { ReactComponent as RetentionIcon } from "./../../assets/images/Retention.svg";
import { ReactComponent as ExchangeIcon } from "../../assets/images/Exchange.svg";
import { customerStyles } from "../../stylesJS/customerStyles";
import RACExchange from "./RACExchange";
import { validateForExchange } from "./customer validation/ExchangeUtils";
import ExchangeAgreementsNavigate from "./ExchangeAgreementsNavigate";
import { isStringWithDigits } from "../../utils/utils";
import { PaymentOriginModal } from "./PaymentOriginModal";

let coWorkerRole = "";
let addrowarray: any;
let backRentValues: any = {
  backRentOwed: "0.00",
  amount: "0.00",
  salesTax: "0.00",
  taxRate: "0.00",
  total: "0.00",
};
let isRevenueRecogActivated = false;
let diffStoreErrLbl = false;
let resetInvoked = false;
let basicPayGridValues: any = [];
let unenrollautopayagreementids: any = [];
let totalduereceiptidsupport: any = [];
let tendertypeglobalarray: tenderType[] = [];
let FloatInputTxt: any;
let AgreementSelect: AgreementVals[] = [];
let agreeementarray: any = [];
let swipeCardDetails: any[] = [];
let acceptPaymentParam: AcceptPaymentParam[] = [];
let actLDWArr: ldwReq[] = [];
let actPolicyArr: ldwReq[] = [];
let suspenseError: any[] = [];
let diffStoreError: any[] = [];
let epoError: any[] = [];
let localGetPayment;
// let diffStoreError: any[] = [];
let ReceiptCategoriesRequestParam: ReceiptCategoriesRequestDTO[] = [];
let swipeCardAmt: any[] = [];
let AcceptclubPaymentParam: AcceptClubPaymentParam | undefined = undefined;
let showAutoPayPopup = false;
let localGetPayData;
/*The paygrid values is the array of objects which consist all the data that needs to binded*/
interface tenderType {
  tendertype: string;
  Amount: string;
  paymentMethodID?: string;
}

interface custCoverage {
  ldwAmount: any;
  ldwTax?: number;
  policyAmount: any;
  isLDW: boolean;
  isPolicy: boolean;
  policyTax?: number;
  ldwInfo?: any;
}
interface paygrid {
  epoCongratulations?:any
  epoAmountDuePayOff?:any
  suspenseUseRevRecog?:any
  storeMerchantId?:any
  toggleOptionalServicePerformed?:any,
  isLdwCoverageAllowed?:any,
  isPolicyCoverageAllowed?:any,
  coverageStatus?:any,
  toggleOptionalService?:any,
  isAcceptEPODiscounts?: any;
  isFinalTransferSusUseCancel?: any;
  isFinalTransferSusUseApplied?: any;
  carriedLate?: any;
  carriedLateTax?: any;
  otherIncomeType?: any;
  initialPaymentFlag: any;
  isZeroPayment: boolean;
  constLateFee: any;
  waivedLateFee: any;
  isReturnAgrFlag?: number;
  lateFeeTaxRate: any;
  accepoEPoAgrnoteInfo: any;
  agreementNoteDetails: any;
  transferOldSuspense: any;
  suspenseAdd: any;
  editedEpoAmount: any;
  acceptEpoFlag: any;
  actualPastDueRent: any;
  actualEpoAmount: any;
  suspenseSub: any;
  AgreementNumber: string;
  Agreementid: number;
  Agreementtype: string;
  Agreementdesc: string;
  ReasonRefcode?: string;
  Schedule: string;
  CurrentDueDate: string;
  Nextduedate: string;
  CurrentDaysLate: number;
  Amount: number;
  SAC_Date: string;
  SuspenseAmount: number;
  EPOAmount: number;
  Latelast10: number;
  PromoFreeDays: number;
  Autopay: string;
  store_number: number;
  number_of_paymentsmade: number;
  sign_status: string;
  confirmeddeliversystatus: boolean;
  Taxamount: number;
  Autopay_enrollmentdate: string;
  clubactive: boolean;
  Phonenumber: string;
  EmailAddress: string;
  partyId: string;
  clubName: string;
  clubMembershipNumber: string;
  clubCoverageTermination: string;
  clubFlag: boolean;
  clublastLate10: number;
  cardLastFour: number;
  agrLdwAmount: number;
  COA: any;
  ipSuspense: any;
  carriedLateFee: any;
  wlDeposit: any;
  htSuspense: any;
  suspense: any;
  racTirePay: any;
  storeCityName: string;
  stateProvinceName: string;
  ldwTaxAmount?: number;
  rpayTaxAmount?: number;
  lFeeTaxAmount?: number;
  lateFee?: number;
  policyTaxAmount?: number;
  ldwPercentage?: number;
  actualAmountDue?: number;
  convTax?: number;
  policyAmount?: number;
  deliveryAmount?: number;
  deliveryAmountTax?: number;
  mattressFee?: number;
  mattressFeeTax?: number;
  processingFee?: number;
  processingFeeTax?: number;
  extensionAmount?: number;
  noOfDaysFree?: number;
  taxGeoCode?: string;
  zipPlus4UsedForTax?: string;
  EPOTax: number;
  dueDateModified?: boolean;
  agreementRateDetails?: any;
  taxRate?: string;
  coverageEndDate?: string | null;
  epoTax?: string | null;
  policyStatus?: string | null;
  editSchedule: null | string;
  agreementRate: any;
  miscellaneousItemsNum: number | null;
  carryRentAmountUsed: any;
  carryRentTaxUsed: any;
  carryRentAmountAdd: any;
  isLdwAllowed: string | any;
  carryLdwAmount: number;
  carryLdwAmountTax: number;
  nineOneOneFee?: number;
  cellPhoneActivation?: number;
  cellPhoneActivationTax?: number;
  miscellaneousItemInfo: any;
  pastDueRent?: number;
  PromoFreeDaysApplied?: any;
  FreeTime?: any; // Added By Me
  isCOATransfered: any;
  isSwitchoutDeliveryPending: 0; // Added By Me
  originAgreementId?: any;
  clubDocumentSignStatus?: any;
  customerClubId: any;
  isClubDeactivateTriggered: any;
  taxEngineData: any;
  storeMerchantID: any;
  risDeliveryfeeTax?: any;
  employeePurchasePlan?: any;
  openDate?:any;
  agreementStatus?:any;
  isExchanged?: any;//RAC2
  parentAgreementid?: any;
}
interface AutoPayType {
  schedule: string;
  autoPay: boolean;
  itemIndex: number;
  agreementID: number;
}

interface AgrAmtTax {
  agreementId: number;
  totalAmount: number;
  taxAmount: number;
}
let agrAmtTaxArray: AgrAmtTax[] = [];
let autoPayEnrollArray: AutoPayType[] = [];
let CardGrid: any;
let chqAndMo: CheqAndMO[] = [];
//let paymentagreements: paygrid[] = [];
let revenueRecogConfigFlag=0
let Clubagreementinsertion: paygrid[] = [];
let customerName = "";
let selectedGrid;
let clubInitialCheckFlag = true;
let previousAmt = 0.0;
let previousState = false;
let chargeCardValue = 0.0;

const useClasses = makeStyles((theme: any) => ({
  navLink: {
    transition:
      "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out",
    position: "relative",
    fontFamily: "OpenSans-semibold",
    color: "#000",
  },
  navLinkactive: {
    color: "#2179FE",
    borderBottom: "5px solid #2468FF !important",
    fontFamily: "OpenSans-semibold",
    "&:before": {
      content: "''",
      position: "absolute",
      height: "5px",
      width: "10px",
      background: "#2468ff",
      left: "-5px",
      bottom: "-5px",
      borderTopLeftRadius: "5px",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      height: "5px",
      width: "10px",
      background: "#2468ff",
      right: "-5px",
      bottom: "-5px",
      borderTopRightRadius: "5px",
    },
  },
  dropdowntoggle: {
    display: "inline-block",
    marginLeft: "0.255em",
    verticalAlign: "0.255em",
    content: "",
    borderTop: "0.3em solid",
    borderRight: "0.3em solid transparent",
    borderBottom: "0",
    borderLeft: "0.3em solid transparent",
  },
  dropdownitemcustom: {
    color: "#2468FF",
    fontFamily: "OpenSans-bold",
    backgroundColor: "white",
    textDecoration: "none",
    cursor: "pointer",
  },
  popUp: {
    position: "absolute",
    bottom: "100%",
    backgroundColor: "white",
    width: "200px",
    padding: "15px",
    "& li": {
      listStyle: "none",
    },
  },
  popUpHide: {
    display: "none",
  },
  customMenuContainer: {
    border: "1px solid #2468FF",
  },
  paymentBtnHover: {
    "&:hover": {
      color: "white",
      backgroundColor: "#0d6efd",
      borderColor: "#0d6efd",
    },
  },
}));

/**RIS_PC_NO_38 global array is declared */
let obj: any = [];

export const RevenueRecog = (props: any) => {
  const {
    clubsStatus,
    customerOrder,
    // searchResult
  } = useContext(DeliveryContext);
  const {
    userTraceValue,
  } = useContext(userTraceContextData);
  const [userTraceData, setUserTraceData] = userTraceValue;
  const [userTracePayload,setUserTracePayload]= useState<any>([]);
  
  const { setUpatedInventory } = useContext(RentaldataContext);
  const [needCarryWaveAuth, setNeedCarryWaveAuth] = useState(false);
  const [needNSFAuth, setNeedNSFAuth] = useState(false);
  const [needCCCBAuth, setNeedCCCBAuth] = useState(false);
  const { redirectOrigin } = useContext(OriginContext);
  let adjustCommitmentData: any;
  let [commitmentDataState, setCommitmentDataState] = useState<any>([]);
  const [clubBtnStatus, setclubBtnStatus] = clubsStatus;
  const [custOrder, setCustOrder] = customerOrder;
  const [phoneNumberRequired, setphoneNumberRequired] = useState<any>(false);
  const { chartdata, setchartdata } = useContext(ChartContext);
  const { statedata, setstatedata } = useContext(StateDataContext);
  const {
    paymentFunctionFlag,
    setpaymentFunctionFlag,
    setCommitmentPayTringle,
  } = useContext(CustomerdataContext);
  const { customerdata, setcustomerdata, isExchangeEnabled, setExchangeEnabled, exchangeNavigationPopup, setExchangeNavigationPopup, setExchangePeriod, setReturnPeriod, exchangePeriod, returnPeriod  } = useContext(CustomerdataContext);
  const userdata: any = useContext(UserStateContext);
  const userthunkdata: any = useContext(UserThunksContext);
  let [currentServiceFlags, setcurrentServiceFlags] = useState<any>({
    isCustomerRedirection: 1,
  });
   //Final Payment Suspense
   const [makingEpoWithSusEPOAmountDue, setMakingEpoWithSusEPOAmountDue] = useState<any>();
   const [makingEPOWithSusEPOAmountDueAgreementNumArray, setMakingEPOWithSusEPOAmountDueAgreementNumArray] = useState<any>([]);
   const [makingEPOWithExactSusEPOAgreementNumArray, setMakingEPOWithExactSusEPOAgreementNumArray] = useState<any>([]);
   const [makingEPOWithOverSusEPOAgreementNumArray, setMakingEPOWithOverSusEPOAgreementNumArray] = useState<any>([]);
   const [makingEPOWithExactSusEPOAgreementDescArray, setMakingEPOWithExactSusEPOAgreementDescArray] = useState<any>([]);
   const [makingEpoWithExactSusEPO, setMakingEpoWithExactSusEPO] = useState<any>();
   const [makingEpoWithOverSusEPO, setMakingEpoWithOverSusEPO] = useState<any>();
   const [finalTranSuspense, setFinalTranSuspense] = useState<any>();
   const [finalTransferSuspenseCOAAndChange, setFinalTransferSuspenseCOAAndChange] = useState<any>(false);
   const [finalDifferenceAmount, setFinalDifferenceAmount] = useState<any>(0);
   const [finalSusTransDisable, setFinalSusTransDisable] = useState<any>(false);
   const [payGridPayloadLoad,setPayGridPayloadLoad]=useState<any>(false);
  //
  const [restrictMultiCard, setRestrictMultiCard] = useState<boolean>(false);
  const [customerInformation, setCustomerInformation] = useState<any>();
  const [enableScheduleLoader, setEnableScheduleLoader] = useState(false);
  const [takeCommitmentData, setTakeCommitmentData] = useState("");
  const [coWorkerRoleCarryWaive, setCoWorkerRoleCarryWaive] = useState("");
  const [isCommitmentInSIMS, setisCommitmentInSIMS] = useState(false);
  const [isCommitmentBroken, setIsCommitmentBroken] = useState(false);
  const [BrknCmtMessage, setBrknCmtMessage] = useState("");
  const [isPendingAgrTransfer, setIsPendingAgrTransfer] = useState(false);
  const [clubActivate, setClubActivate] = useState<any>();
  const [opentransferSuspense, setOpenTransferSuspense] = useState(false);
  const [newCOATotal, setNewCOATotal] = useState("0.00");
  const [disableEPOByEPP, setDisableEPOByEPP] = useState(false);
  const [isFromAccountMgmt, setIsFromAccountMgmt] = useState(false);
  const [ConvFeeWithOutTax, setConvFeeWithOutTax] = useState<any>(0);
  const [suspenseButton, setsuspenseButton] = useState<any>(false);
  const [checkdisable, setcheckdisable] = useState<any>(false);
  const [instoreDisable, setinstoreDisable] = useState<any>(false);
  const [configFeeOnlyTax, setconfigFeeOnlyTax] = useState<any>(0);
  const [lateFeeTaxRateCus, setLateFeeTaxRateCus] = useState<any>([]);
  const [susbtndisabled, setsusbtndisabled] = useState<any>(false);
  const [saveCardPopUp, setsaveCardPopUp] = useState<any>(false);
  const [saveCardSuccess, setsaveCardSuccess] = useState<any>(false);
  const [deleteCardPopUp, setdeleteCardPopUp] = useState<any>(false);
  const [deleteCardSuccess, setdeleteCardSuccess] = useState<any>(false);
  const [failedToDeleteCardError, setFailedToDeleteCardError] = useState<any>("");
  const [deleteCardFail, setdeleteCardFail] = useState<any>(false);
  const [somethingWentWrong,setSomethingWentWrong]= useState<any>(false);
  const [primaryAddressErrorFlag,setPrimaryAddressErrorFlag] = useState<boolean>(false) //this flag is used to show the active primary address popup
  const [postalCodeZip5ErrorFlag,setPostalCodeZip5ErrorFlag] = useState<any>(0) // this flag is used to render the popup and Verbiage of incorrect Zip code and phone number
  const [saveCardFail, setsaveCardFail] = useState<any>(false);
  const [failedToSaveCardError, setFailedToSaveCardError] = useState<any>("");
  const [popUpCarrywaive, setPopUpCarryWaive] = useState<boolean>();
  const [popUpCarrywaiveLate, setPopupCarrywaiveLate] = useState<boolean>();
  const [transferDifferenceZero, settransferDifferenceZero] =
    useState<any>(false);
  const [storeMerchantId, setstoreMerchantId] = useState<any>("");
  const [storezip, setstorezip] = useState<any>("");
  const [openOtherIncome, setOpenOtherIncome] = useState(false);
  const [taxPayload, setTaxPayload] = useState<any>();
  const [customerzipcodenull, setcustomerzipcodenull] = useState<any>(false);
  const [suspenseAllDelete, setsuspenseAllDelete] = useState<any>(false);
  const [FinalSuspenseDelete, setFinalSuspenseDelete] = useState<any>(false);
  const [suspenseDeleteExpection, setSuspenseDeleteExpection] =
    useState<any>(false);
  const [returnAgrSuspense, setReturnAgrSuspense] = useState<any>(false);
  /**Multiple primary Address */
  const [primaryAddressUpdated, setPrimaryAddressUpdated] =
    useState<boolean>(false);
  const [primAddPopUp, setPrimAddPopUp] = useState<any>(false);
  const [primAddSavePopup, setPrimAddSavePopup] = useState<any>(false);
  // eslint-disable-next-line prefer-const
  let [primaryAddArr, setPrimaryAddArr] = useState<any>([]);
  const [customerAddressActive, setCustomerAddressActive] = useState<any>();
  const [radioArray, setRadioArray] = useState<any>([]);
  const [inActivePopSaveDisable, setInActivePopSaveDisable] =
    useState<boolean>(true);
  const [primaryAddressIndex, setPrimaryAddressIndex] = useState<any>();
  const [selectedPrimAddress, setselectedPrimAddress] = useState<any>();

  //BackRent
  let [backRentPopUp, setBackRentPopUp] = useState<boolean>();
  const [backRentInfo, setBackRentInfo] = useState({
    backRentOwed: "0.00",
    amount: "0.00",
    salesTax: "0.00",
    taxRate: "0.00",
    total: "0.00",
  });
  const [backRentEditInfo, setbackRentEditInfo] = useState<any>();
  let [backRentTax, setBackRentTax] = useState<any>();
  {
    /*
     *PS_CO_11
     *StateVariables used for NSF check type
     */
  }
  //Nsf Check payment
  const [nsfPopUp, setNsfPopUp] = useState<boolean>();
  //second level authentication

  //Credit Card Charge Back Repayment
  const [cccbDisable, setCccbDisable] = useState<boolean>(false); //It is used for the hide and show of Adjust CCCB button
  const [NSFpaymentAdjust, setNSFpaymentAdjust] = useState<boolean>(false);
  const [cccb, setCccb] = useState<any>();
  const navlinkClass = useClasses();
  const classesForExchange = customerStyles();
  const classes = takePaymentStyles();
  const history = useHistory();
  /**
   * RIS_PC_NO_38 ,RIS_PC_NO_40 The state variable used for Rental item sales
   * */

  const [risselectedItems, setrisselectedItems] = useState<any>();
  const [risRemovepaymentbtnFlag, setRisRemovepaymentbtnFlag] = useState(false);
  const [risRemovepaymentdisableFlag, setRisRemovepaymentdisableFlag] =
    useState(false);
  // const [editRisselecteditem,setEditRisselecteditem]=useState()
  const [adjustRisbtnFlag, setadjustRisbtnFlag] = useState(false);
  const [adjustdisableFlag, setAdjustdisableFlag] = useState<boolean>();
  const [risFlag, setRisFlag] = useState(false);
  let [stateProvinceName, setStateProvinceName] = useState<any>();
  let [storeCityName, setStoreCityName] = useState<any>();
  // const [ConvFeeWithOutTax,setConvFeeWithOutTax] = useState<any>(0)
  // const [configFeeOnlyTax,setconfigFeeOnlyTax]=useState<any>(0)

  // chargeOffObj
  const [chargeoffInfo, setchargeoffInfo] = useState<any>([]);
  const [chargeoffDropdownReason, setChrgeoffDropdownReason] = useState<any>();
  // const [adjustchargeoffFlag,setadjustchargeoffFlag]=useState(true)
  const [editchargeoffdatas, seteditchargeoffdatas] = useState<any>();
  const [adjustChargeoffFlag, setadjustChargeoffFlag] = useState(false);
  const [chargeoffadjustFlag, setchargeoffadjustFlag] = useState<boolean>();
  const [editChargeoffFlag, setEditChargeoffFlag] = useState<boolean>(false);
  let [chargeoffPopupFlag, setchargeoffPopupFlag] = useState(false);
  const [restrictBindRemaining, setRestrictBindRemaining] = useState(false);
  const [totalRentalRevenueAmount, settotalRentalRevenueAmount] = useState('')
  const [showTooltip, setShowTooltip] = useState(false);
  let checkBoxCountchargeoffFlag = false;

  const [taxExemption, setTaxExemption] = useState<any>("");
  const temp = {
    checktype: "0",
    Type: "",
    checkno: "",
    Written_To: "",
    Written_by: "",
    Amount: "0.00",
  };
  const cardrequirements = {
    CardCss: `${navlinkClass.navLink} ${classes.p3}`,
    Checked: false,
    checkbox: false,
    swipcard: false,
    newCard: false,
    editcrd: false,
    delt: false,
    Proceedbtn: true,
    tran: false,
    CardNumberValidation: false,
    chkcvv: "",
    chkamt: "0.00",
    txt2pay: false,
    txt2paybtn: true,
    txt2payCardOnFile: false,
    PaymentOrgin: "Store",
    drpdown: false,
    APDeletePopup: false,
    ConvAmt: ConvFeeWithOutTax,
    Otheraction: true,
    Newtotal: ConvFeeWithOutTax,
    Ldwhide: true,
    Policyhide: true,
  };

  const newcardinformation = {
    card: "",
    card_holder_name: "",
    card1: "",
    card2: "",
    card_last_four: "",
    cvv: "",
    name: "",
    address_line_1: "",
    address_line2: "",
    city: "",
    state: "",
    zip_code: "",
  };

  // swipe popup state variable
  let swipeObj = {
    load: false,
    tran: false,
    infopopup: false,
  };
  const [swipevar, setswipevar] = useState(swipeObj);

  const Validation = {
    SwipeAmtValidationtxtbox: false,
    State_validation: false,
    Zip_code_validation: false,
    Address_line1_validation: false,
    Name_validation: false,
    Cvv_validation: false,
    expiration_validation: false,
    Card_holder_validation: false,
    CardValidation: false,
    Phone_no_validation: false,
    chargeCardValidation: false,
  };

  const CardObj = {
    Cardname: "",
    address_1: "",
    address_2: "",
    zip: "",
    state: "0",
    city: "",
  };

  const APUP = {
    IsAP: false,
    IsUP: false,
  };

  const tempcustCoverage: custCoverage = {
    ldwAmount: "",
    ldwTax: 0,
    policyAmount: "",
    isLDW: false,
    isPolicy: false,
  };

  //Accept EPO Changes
  const SLAObj = {
    TwoFactorCancelClick: false,
    TwoFactorCompleted: false,
  };

  // State declared for payment History by syed azimudeen
  const [PaymentHistory, setPaymentHistory] = useState(false);
  const [ClubPaymentHistory, setClubPaymentHistory] = useState(false);
  const [retentionAlertMessage, setRetentionAlertMessage] = useState("");
  const [openRetentionAlert, setOpenRetentionAlert] = useState(false);

  let [uniqueAgreementID, setuniqueAgreementID] = useState<any>([]); //to get the unique agreement data from the response to set it in the drop down
  let [paymentHisGrid, setpaymentHisGrid] = useState<any>([]); //to get the payment history data from the response
  const [clubActivityInfo, setclubActivityInfo] = useState<any>([]); //to get the club activity data from the response
  const [agreementRentPaid,setAgreementRentPaid] = useState<any>([])
  const [ClubGridInfoResp, setClubGridInfoResp] = useState<any>([]); //to get the club payment grid data from the response
  let [commoninformation, setcommoninformation] = useState<any>(); //to get the common customer information data from the response
  let [clubinformation, setclubinformation] = useState<any>(); //to get the club information data from the response

  let [payhisflag, setpayhisflag] = useState<boolean>(true); //to get the club information data from the response
  let [payclubflag, setpayclubflag] = useState<boolean>(true); //to get the club information data from the response

  const [mobtn, setmobtn] = useState(false);
  const [cashbtn, setcashbtn] = useState(false);
  const [defaultVal, setdefaultVal]: any = useState();
  const [DD, setDD]: any = useState();
  const [load, setload] = useState(false);
  const [Autopaybutton, setAutopaybutton] = useState(true);
  const [customerCoverageObject, setcustomerCoverageObject] =
    useState(tempcustCoverage);
  const [updatedarray, setupdatedarray]: any = useState([]);
  const [agreementarraystate, setagreementarraystate]: any = useState([]);
  const [IsClubSelectedForAutopay, setIsClubSelectedForAutopay] =
    useState(false);

  const [propsautopayopen, setpropsautopayopen] = useState(-1);
  const [APpopupcompleted, setAPpopupcompleted] = useState(-1);
  const [ResponseObject, setResponseObject]: any = useState();
  const [actualEpoAmount, setactualEpoAmount] = useState<any>(0);
  const [editedEPOAmount, seteditedEPOAmount] = useState<any>(0);
  // //const{customerid,agreementFlag}=useParams<Params>()

  const [CreditRadio, setCreditRadio] = useState(false);
  const [changeRadio, setchangeRadio] = useState(true);
  const [APUPStates, setAPUPStates] = useState(APUP);
  const [chkMoney, setchkMoney]: any = useState();
  // Validation state variables starts here
  const [EditCard, setEditCard]: any = useState(CardObj);
  const [Name_validation, setName_validation] = useState(false);
  const [Address_line1_validation, setAddress_line1_validation] =
    useState(false);
  const [State_validation, setState_validation] = useState(false);
  const [Zip_code_validation, setZip_code_validation] = useState(false);
  const [amount_feild, setamount_feild]: any = useState();

  // Validation state variables ends here
  const [paymentSchedule, setpaymentSchedule]: any = useState(2);
  //State variable of payment origin option ends here
  const [chkboxselect, setchkboxselect] = useState(false);
  //footer components state variables
  const [PopupClass, setPopupClass] = useState<string>(
    `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
  );
  const [ActiveLDW, setActiveLDW] = useState(false);
  const [TerminateLDW, setTerminateLDW] = useState(false);
  const [Activepolicy, setActivepolicy] = useState(false);
  const [DeActivepolicy, setDeActivepolicy] = useState(false);
  const [paymentMade, setpaymentMade] = useState(false);
  const [paymentMadeOptimized, setpaymentMadeOptimized] = useState(false); //used as dependency for the useEffect which helps to empty the Global variable.
  //footer components state variables ends here

  //configFlag
  let [configFlags, setconfigFlags] = useState<any>({
    commonModuleFlag: 0,
    isAdjustDueDateFeatureFlag: 0,
    isEditScheduleFeatureFlag: 0,
    isCarryRentFeatureFlag: 0,
    isAcceptEpoFeatureFlag: 0,
    isPaymentHistoryFeatureFlag: 0,
    isCashSaleFeatureFlag: 0,
    isPaymentTraingleFeatureFlag: 0,
    isAddApplyTransferSuspenseFeatureFlag: 0,
    isPaymentFunctionsFeatureFlag: 0,
    isActivateClub: 0,
    isRegularFreeTimeAllowed: 0, // Added By Me
    isServiceFreeTimeAllowed: 0, // Added By Me
    isRegExtFreeTimeAllowed: 0, // Added By Me
    isPromoFreeTimeallowed: 0, // Added By Me
    raftApiFlag: 0,
    carryWaveLateFee: 0,
    optimizedPayment: 0,
    RevenueRecognition: 0,
    isRentalItemSaleFlag: 0,
    isBackRentFlag: 0,
    isChargeOffSkipStolen: 0,
    isNSFPaymentsFlag: 0,
    isCCCBPaymentsFlag: 0,
    isRemoveAmountDue: 0,
    isCloudSwipeCustomerCard: 0,
    isCloudStoreSwipeDevicelist: 0,
    isPaymentDonation: 0,
    isRacDbWebLead: 0,
    isFinalPaymentTransferSuspense:0,
    rewardsEligibilityAmount: 0,
    isTxt2PayCardOnFile: 0,
    customerRewards:0,
    swipeCustomerCard:0

  });

  const [Popupfields, setPopupfields] = useState(newcardinformation);
  const [Validator, setValidator] = useState(Validation);
  const [gettotamt, setgettotamt]: any = useState("0.00");
  const [swipeamt, setswipeamt] = useState("0.00");
  const [Cardrequire, setCardrequire] = useState(cardrequirements);
  const [calcModuleResponse, setCalcModuleResponse] = useState<any>("");
  const [cashtabCss, setcashtabCss] = useState<any>(
    `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`
  );
  const [cardtabCss, setcardtabCss] = useState<any>(
    `${navlinkClass.navLink} ${classes.p3}`
  );
  const [checkMoneyOrdertabCss, setcheckMoneyOrdertabCss] = useState<any>(
    `${navlinkClass.navLink} ${classes.p3}`
  );
  const [cashselected, setcashselected] = useState(0); //This state is used to enable and disable cash div so that check div will be visible.
  const [gridhide, setgridhide] = useState(""); //This state is used for the accordion logic.
  const [addcheck, setaddcheck] = useState<boolean>(false); //This state is used for the addcheck popup enable and disable.
  const [suspensePopupClass, setsuspensePopupClass] = useState<string>(
    // `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
  );
  const [addsuspense, setaddsuspense] = useState<boolean>(false); //This state is used for the add suspense  popup enable and disable.
  const [suspenseAddLoader, setSuspenseAddLoader] = useState<boolean>(false);
  const [tenderUseEffectCalculation,setTenderUseEffectCalculation]= useState<boolean>(false);
  const [usesuspense, setusesuspense] = useState<boolean>(false); //This state is used for the use suspense  popup enable and disable.
  const [transuspense, settransuspense] = useState<boolean>(false); //This state is used for the use suspense  popup enable and disable.
  const [refundsuspense, setrefundsuspense] = useState<boolean>(false); //This state is used for the use suspense  popup enable and disable.
  let [addsuslist, setaddsuslist] = useState<boolean>(true); //This state is used for the add suspense  popup enable and disable.
  let [disableTransfer, setDisableTransfer] = useState<boolean>(true); //This state is used for the add suspense  popup enable and disable.
  const [refundsuslist, setrefundsuslist] = useState<boolean>(true); //This state is used for the use suspense  popup enable and disable.
  const [transfersuslist, settransfersuslist] = useState<boolean>(true); //This state is used for the use suspense  popup enable and disable.
  const [AddsusText, setAddsusText] = useState(false);
  const [UsesusText, setUsesusText] = useState(false);
  const [deactivateClubAmount, setdeactivateClubAmount] = useState<any>("0.00");
  const [susamount, setsusamount] = useState();
  const [addsus, setaddsus]: any = useState("0.00"); //This is the state is used to cash text box in the suspens add amount.
  const [usesus, setusesus]: any = useState("0.00"); //This is the state is used to cash text box in the suspens add amount.
  const [transsus, settranssus]: any = useState("0.00"); //This is the state is used to cash text box in the suspens add amount.
  const [refundsus, setrefundsus]: any = useState("0.00"); //This is the state is used to cash text box in the suspens add amount.
  /*  state variable for suspense */
  let [totolsuspenseamount, settotolsuspenseamount] = useState<any>("0.00");
  let [totalnewsuspenseamount, settotalnewsuspenseamount] =
    useState<any>("0.00"); //to set the new total amount
  let [differenceamount, setdifferenceamount] = useState<any>("0.00"); //to set the new total amount
  let [totalsuspensemaster, settotalsuspensemaster]: any = useState([]); //This state is used to setstate the reciept item that needs to be displayed in the suspense due div.
  let [mastersuspensearray, setmastersuspensearray] = useState<any>([]);
  let [masteraddsuspensearray, setmasteraddsuspensearray] = useState<any>([]);
  let [masterusesuspensearray, setmasterusesuspensearray] = useState<any>([]);
  let [mastereditsuspensearray, setmastereditsuspensearray] = useState<any>([]);
  let [mastertranssuspensearray, setmastertranssuspensearray] = useState<any>(
    []
  );
  let [masterrefundsuspensearray, setmasterrefundsuspensearray] = useState<any>(
    []
  );
  let commitmentTOS = false;
  const [addmoneyorder, setaddmoneyorder] = useState<boolean>(false); //This state is used for the addmoneyorder popup enable and disable.
  const [adddata, setadddata] = useState<any>(temp); //This state is used for the getting data from the addcheck popup or add moneyorder popup and also used to bind it.
  const [ConrolUpdate, setConrolUpdate] = useState("0"); //This state is used to specify the difference between update and cancel button if cancel then setadddata state will be get nulled.
  const [button, setbutton] = useState(false); //This state is used to hide and show update and save button based on the triggeration.
  const [adddataanother, setaddataanother] = useState(chqAndMo); //This is the state in which the added object in the setadddata will be pushed inside and map function will be used in this to bind the data in dynamic grid.
  const [uploadindex, setuploadindex] = useState(0); //This is the state which is used to denote the index at which the data gets uploaded onclick of update button.
  const [addcash, setaddcash]: any = useState("0.00"); //This is the state is used to cash text box in the cash tendertype.
  let [totaldue, settotaldue]: any = useState(0.0); //This is the state which is used to calculate the total due.
  const [checking, setchecking]: any = useState("0"); //This is used to delete the data in the dynamic grid .It is used to bind the grid instantly after the data gets deleted in the adddataanother array.
  const [totalDueSusAddAmount, setTotalDueSusAddAmount]: any = useState(0);
  const [totalDueSusUseAmount, setTotalDueSusUseAmount]: any = useState(0);
  const [RemaniningAmtStatic, setRemaniningAmtStatic]: any = useState(0); //This state is used to set the remaining amount as static for backup and several operation purposes.
  const [remainingamountdue, setremainingamountdue]: any = useState(0); //This state is used to set the remaining amount due.
  const [personalcheckalert, setpersonalcheckalert] = useState<any>(false); //This state is used to enable the personalcheck validation alert box.
  const [travellercheckalert, settravellercheckalert] = useState<any>(false); //This state is used to enable the travellercheck alert box.
  const [totalamountcheck, settotalamountcheck] = useState<any>(0); //This is the state which is used to calculate the totalamount check.
  const [cashamountcheckbox, setcashamountcheckbox] = useState<any>(); //This state is used to take the all the digit the cash amount text box based on it the remaininng amount and change gets updated.
  const [unenrollautopayopen, setunenrollautopayopen] = useState(false);
  //validation state variable's are here
  const [checknovalid, setchecknovalid] = useState(false); //This state is used to validate the check no.
  const [Writtento, setwrittento] = useState(false); //This state is used to validate the written to field.
  const [writtenby, setwritenby] = useState(false); //This state is used to validate the written by field.
  const [Amount, setAmount] = useState(false); //This state is used to validate the Amount field.
  const [Type, setType] = useState(false); //This state is used to validate the check type.
  const [CityValidation, setCityValidation] = useState(false);
  const [change, setchange] = useState<any>(0); //This state is used to show the change.
  const [uploadtotal, setuploadtotal] = useState<any>(0); //This is used to get the backup of the amount at that particular grid in order if the update button is triggered.
  const [totalcontrol, settotalcontrol] = useState<any>(0); //This is used to differntiate the upload and save button inorder to calculate total amount check.
  let [totaltax, settotaltax]: any = useState(0.0); //This state variable is used to set the total tax in the total due div.
  const [Acceptpaymentbuttonenable, setAcceptpaymentbuttonenable] =
    useState(true); //This state is used for enable and disable of the acceptpayment button.
  const [tooltipdisplay, settooltipdisplay] = useState(""); //This state is used to display the tooltip for the dynamically binded grid.
  const [tooltipdesdisplay, settooltipdesdisplay] = useState(""); //This state is for the tooltipdisplay of the description grid.
  const [tooltipdispalygrid, settooltipdisplaygrid] = useState(""); //This state is for the display of the tooltip on dynamically generated grid.
  const [cancelindication, setcancelindication] = useState(0); //This state is used for the indication of cancel button triggeration.
  let [totalduereceiptid, settotalduereceiptid]: any = useState([]); //This state is used to setstate the reciept item that needs to be displayed in the total due div.
  let [totalduetendertype, settotalduetendertype]: any = useState([]);
  const [editcardindex, seteditcardindex]: any = useState(0);
  const [editcardnumberbind, seteditcardnumberbind]: any = useState(0);
  const [chkbox, setchkbox] = useState<any>([-1]);
  const [OverAll_Chk, setOverAll_Chk] = useState(false);
  let acceptPay: AcceptPaymentParam[] = [];
  const [AcceptPayments, SetAcceptPayments]: any = useState(acceptPay);
  let rcpCat: ReceiptCategoriesRequestDTO[] = [];
  const [ReceiptCategory, setReceiptCategory] = useState(rcpCat);
  const [AcceptClub, setAcceptClub] = useState<AcceptClubPaymentParam>();
  const [policyService_state, setpolicyService_state]: any = useState(1);
  const [getCardService_state, setgetCardService_state]: any = useState([]);
  const [profileAccResp, setprofileAccResp]: any = useState(undefined);
  const [ActionState, setActionState]: any = useState();
  const [showLDWPolicy, setshowLDWPolicy]: any = useState(false);
  const [clubState, setclubState]: any = useState();
  let [paygridvalues, setpaygridvalues] = useState<any>([]);
  const [radioBtn, setradioBtn] = useState("0");
  const [autopaypopup, setautopaypopup]: any = useState(false);
  const [cardGridData, setCardGridData] = useState(CardGrid);
  const [CardErrorLbl, setCardErrorLbl]: any = useState(false);
  const [suspErrLbl, setsuspErrLbl]: any = useState(false);
  // const [epoErrLbl, setepoErrLbl]: any = useState(false);
  const [acceptPayment, setAcceptPayment] = useState<any>();
  const [getpayment, setgetpayment] = useState<any>();
  let avaArr: AllPaymentInfo[] = [];
  const [aval_det, setaval_det] = useState(avaArr);
  let [paymentOrigin, setpaymentOrigin] = useState("Store");
  const [empId, setempId] = useState("");
  const [loadAlerts, setLoadAlerts] = useState<boolean>(true);
  const [CustomAlerttxt, setCustomAlerttxt] = useState("");
  const [disableAlert, setdisableAlert] = useState<boolean>(false);
  const [Enabletxt, setEnabletxt] = useState(true);
  const [AlertWizard, setAlertWizard] = useState<any>([]);
  const [AlertIsOpen, setIsOpen] = useState(false);
  const [objAlertdata, setobjAlertdata] = useState<any>([]);
  const [AlertUpdateObj, setAlertUpdateObj] = useState<any | []>([]);
  const [AlertAuthenticationNeeded, setAlertAuthenticationNeeded] =
    useState<any>();
  const [authenType, setauthenType] = useState<"ALERT" | "">("");
  const [openPinModal, setOpenPinModal] = useState(false);
  const [otp, setotp] = useState("");
  const [somethingone, setsomethingone] = React.useState(
    "modal fade show expandPopup modal-top"
  );
  const [somethingtwo, setsomethingtwo] = React.useState(
    "modal fade modal-top"
  );
  const [loader, setloader] = useState<boolean>(false);
  const [InvalidPin, setInvalidPin] = React.useState<any>();
  const [Continue, setContinue] = React.useState(true);
  const [getStore, setGetStore] = useState<any>();
  const [openModal, setOpenModal] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [userID, setUserID] = useState("");
  const [password, setPassword] = useState("");
  const [errorline, setErrorLine] = useState(false);
  const [popupFlag, setpopupFlag] = useState(false);
  const [role, setRole] = useState("");
  const [isAlertLoading, setIsAlertLoading] = useState<boolean>(false);
  const [isSecondAuthenValid, setIsSecondAuthenValid] =
    useState<boolean>(false);
  const [LegalHold, setLegalHold] = useState<any>([]);
  const [isLegalHold, setIsLegalHold] = useState<boolean>(false);
  const [legalHoldApplied, setLegalHoldApplied] = useState<0 | 1 | 2>(0);
  const [OtherActionHidden, setOtherActionHidden] = useState(true);
  const [PaymentAmt, setPaymentAmt] = useState(0);
  const [sendtext, setsendtext] = useState(true);
  const [bindGridCheck, setbindGridCheck] = useState(false);
  const [initagreementnumber, setinitagreementnumber] = useState<any>(0);
  const [DeveiceResp, setDeveiceResp]: any = useState();
  const [otherActState, setotherActState]: any = useState();
  const [TerminalId, setTerminalId]: any = useState("0");
  const [swipepopupshow, setswipepopupshow] = useState(false);
  const [swipeFlag, setswipeFlag] = useState(false);
  const [nextBtn, setnextBtn] = useState(false);
  const [Sessionstorage, setSessionstorage]: any = useState();
  const [coaamt, setcoaamt]: any = useState(0);
  const [wldepositamount, setwldepositamount]: any = useState(0);
  const [ipsuspense, setipsuspense]: any = useState(0);
  const [suspensehide, setsuspensehide]: any = useState(false);
  const [suspenseAmount, setSuspenseAmount]: any = useState(0.0);
  const [checkmonvar, setcheckmonvar]: any = useState("0");
  const [CardRadiArr, setCardRadiArr]: any = useState([]);
  //Txt2pay card on File variable declaration starts here
  const [txt2PayRadioArr, setTxt2PayRadioArr] :any = useState([]);
  const [selectedTxt2PayCard, setSelectedTxt2PayCard]: any= useState([]);
  //ends here
  const [availableCreds, setAvailableCreds] = useState(0.0);
  const [verbalecheckbox, setvarbalecheckbox]: any = useState(false);
  const [vantivError, setVantivError] = useState("");
  const [vantivIframe, setVantivIframe] = useState();
  const [secondLevelAuthenOn, setSecondLevelAuthenOn] = useState<any>(null);
  const [cardAuthNo, setcardAuthNo] = useState(undefined);
  const [cardLastFour, setcardLastFour] = useState("");
  const [showAddCard, setshowAddCard] = useState(false);
  const [cvv, setCvv] = useState("");
  const [cvvTextValue, setcvvTextValue] = useState("");
  const [selectedCheckBoxCount, setSelectedCheckBoxCount] = useState(0);
  const [mainGridloader, setmainGridloader] = useState(true);
  const [commitmentMainGridLoader,setCommitmentMainGridLoader] = useState(false);
  const [convtax, setconvtax] = useState(0);
  const [finalagr, setfinalagr] = useState<any>(null);
  const [swipeDisable, setswipeDisable] = useState(false);
  // const [diffStoreErrLbl, setdiffStoreErrLbl]: any = useState(false);
  const [cardtxtDisable, setcardtxtDisable] = useState(false);
  const [Latelast10value, setLatelast10value]: any = useState(0.0);
  const [overpayMoneyord, setoverpayMoneyord]: any = useState(false);
  const [addNewCard, setaddNewCard] = useState(false);
  const [cardDetails, setcardDetails]: any = useState([]);
  const [cardAmt, setCardAmt]: any = useState([{ amount: 0.0 }]);
  const [swipeDeviceValue, setswipeDeviceValue] = useState("0.00");
  const [nameVal, setnameVal] = useState(false);
  const [addressval1, setaddressval1] = useState(false);
  const [ZipVal, setZipVal] = useState(false);
  const [cityVal, setcityVal] = useState(false);
  const [stateVal, setstateVal] = useState(false);
  const [TranFail, setTranFail] = useState(false);
  const [txtChange, settxtChange] = useState(false);
  const [pageLoadedInitial, setpageLoadedInitial] = useState(true);
  const [clubCheckDisabled, setclubCheckDisabled] = useState(false);
  const [showChargeCard, setshowChargeCard] = useState(false);
  const [chargeCardAmt, setchargeCardAmt] = useState("0.00");
  const [convAmtWTax, setconvAmtWTax] = useState("0.00");
  const [chargeCardDisable, setchargeCardDisable] = useState(true);
  const [chargeCardContinue, setchargeCardContinue] = useState(true);
  const [otherActionSpinner, setotherActionSpinner] = useState(false);
  const [chargeBillInfo, setChargeBillInfo]: any = useState(undefined);
  const [paymentOriginDisable, setpaymentOriginDisable] = useState(false);
  const [creditDisable, setcreditDisable] = useState(false);
  const [AlertLoadCount, setAlertLoadCount] = useState<number>(0);
  const [AlertLoad, setAlertLoad] = useState<any | []>([]);
  const [activeAlert, setActiveAlert] = useState<[] | any>([]);
  const [availableCredsremaining, setavailableCredsremaining] = useState<any>(
    []
  );
  const { paymentSearchValue } = useContext(DeliveryContext);
  const [searchVal, setsearchVal] = paymentSearchValue;
  let [enableCashPayment, setenableCashPayment] = useState(false);
  let [fromManageCard, setFromManageCard] = useState(false);
  const [manageCardsla, setManageCardsla] = useState(false);

  /**
   * changes : AdjustDueDate , Edit Schedule
   * Develepor: Muzzammil
   * desc : state variables used for both adjust due date and edit schedule for take payment
   */
  const [unverifiedCustomer, setunverifiedCustomer] = useState<boolean>(false);
  const [deliveryUnconfiremd, setdeliveryUnconfiremd] =
    useState<boolean>(false);
  const [agreementDeliveryUncomfirmed, setagreementDeliveryUncomfirmed] =
    useState<any>([]);
  const [carryMessage, setcarryMessage] = useState<string>("");
  let [paymentEditScheduleDropDown, setpaymentEditScheduleDropDown] =
    useState<any>();
  const [agrId, setAgrId] = useState<any>();
  let [checkedAgr, setcheckedAgr] = useState<any>([]); //gets the oncheck agreement details
  let [adjustDueDateSelectedAgre, setadjustDueDateSelectedAgre] = useState<any>(
    []
  ); //push all selected agreementIds
  const [adjustButton, setadjustButton] = useState<boolean>(); //responsible for displaying and not displaying the adjustduedate button
  const [promoFreeTimeButton, setpromoFreeTimeButton] = useState<any>();
  const [selectedOnlyCashSale, setselectedOnlyCashSale] = useState<any>(false);
  const [addMoneyOrderDisable, setAddMoneyOrderDisable] = useState<any>(false);
  
  const [editButton, seteditButton] = useState<boolean>();
  const [isZeroDollarPayment, setIsZeroDollarPayment] = useState<any>(false); //responsible for displaying and not displaying the adjustduedate button
  const [disableAdjustDueDateButton, setdisableAdjustDueDateButton] =
    useState<boolean>(false); //disabling the adjust due date button if the agreement on checked is from the different store compared to login store
  const [openAdjustDueDate, setopenAdjustDueDate] = useState<boolean>(false); //on true --> accesing the adjust due date comeponent
  const [openPromoFreeDays, setopenPromoFreeDays] = useState<boolean>(false);
  const [editSchedulePopUp, seteditSchedulePopUp] = useState<boolean>(); //on true -->accesing the edit schedule component
  const [adjustAllButton, setadjustAllButton] = useState<boolean>(false);
  const [openFreeTimeRetention, setOpenFreeTimeRetention] = useState(false);
  const [customerGCID, setCustomerGCID] = useState<any>("");

  //set to default
  const [adjustTerminateLdwAmount, setadjustTerminateLdwAmount] =
    useState<any>();
  //cash sale
  const [nonInventoryPopUp, setnonInventoryPopUp] = useState<boolean>(false);
  const [misNumberValue, setmisNumberValue] = useState<any>(false);
  const [OtherIncomeHidden, setOtherIncomeHidden] = useState(false);
  const [EditNoninventoryData, setEditNoninventoryData] = useState<any>();
  const [otherIncomePopupClass, setotherIncomePopupClass] = useState<string>(
    `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
  );
  //carry rent
  const [isCommitmentInOtherStore, setIsCommitmentInOtherStore] =
    useState(false);
  const [carryRentWidget, setcarryRentWidget] = useState<boolean>(false);
  // const [commitmentAmount, setCommitmentAmount] = useState<any>('0.00');
  let [carryRentAmount, setcarryRentAmount] = useState<any>("0.00");
  const [totalCalciTextValue, settotalCalciTextValue] = useState<any>("0.00");
  const [carryRentInitialAndFinal, setcarryRentInitialAndFinal] =
    useState<boolean>(false);
  const [carryRentCheck, setcarryRentCheck] = useState<any>(false);
  const [selectallChecked, setselectallChecked] = useState<any>(false);
  const [carryMax, setcarryMax] = useState<any>(false);
  const [agreecarryUsed, setagreecarryUsed] = useState<any>(0);
  const [agreecarryLateUsed, setagreecarryLateUsed] = useState<any>(0);
  let [defaultPaygridvalues, setdefaultPaygridvalues] = useState<any>(); //setting the default getpayment info
  const [toDefaultActive, settoDefaultActive] = useState<boolean>(false);
  let [defaultTotaldue, setdefaultTotaldue] = useState<any>(0);
  //multiple card Muzzammil
  let [multipleCard, setmultipleCard] = useState<any>([]);
  let [chargeCardArray, setchargeCardArray] = useState<any>([]);
  const [cardToken, setcardToken] = useState<any>([]);
  //Accept EPO Changes
  const [showAcceptEpoPopup, setShowAcceptEpoPopup] = useState(false);
  const [epoPopup, setepoPopup] = useState<any>();
  const [epoShedulePopup, setEpoShedulePopup] = useState(false);
  const [showEPOGridBtn, setshowEPOGridBtn] = useState(false);
  const [newcustomer, setnewcustomer] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(false);
  const [epoPopupClass, setepoPopupClass] = useState<string>(
    `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
  );
  //activateClub
  const [clubStatus, setclubStatus] = useState("Club");
  const [
    deactivateClubCustomerRedirection,
    setdeactivateClubCustomerRedirection,
  ] = useState(false);
  const [clubStatusPopup, setclubStatusPopup] = useState({
    activateClub: false,
    deactivateClub: false,
    pendingClub: false,
  });
  const [clubAmount, setclubAmount] = useState("");
  const [errorPopup, seterrorPopup] = useState(false);
  const [masterloader, setmasterloader] = useState(false);
  const [isclubOnSameStore, setisclubOnSameStore] = useState(true);
  const [redirectToAccountManagement, setRedirectToAccountManagement] =
    useState(false);

  let [agreementAddressDetails, setAgreementAddressDetails] = useState<any>();
  //ChargeCard and Add New Card Details pre-fill, variable used
  const prefillObj = {
    customerPrimaryAddress: "",
    customerSecondaryAddress: "",
    customerZip: "",
    customerCity: "",
    customerState: "",
    name: "",
  };
  const [customerInfopreFill, setCustomerInfopreFill] = useState(prefillObj);
  const [chargeCardFlag, setChargeCardFlag] = useState(false);

  // Retention starts
  const [isRetentionEnabled, setIsRetentionEnabled] = useState<boolean>(false);
  const [openSLA, setOpenSLA] = useState<boolean>(false);
  const [openOfferLoading, setOpenOfferLoading] = useState<boolean>(false);
  const [choosenAgreementNumber, setChoosenAgreementNumber] = useState<any>();
  const [choosenAgreementID, setChoosenAgreementID] = useState<any>();
  const [availableRetentionOffers, setAvailableOffers] = useState<any>();
  const [offerResponse, setOfferResponse] = useState<any>();
  const [openRetention, setOpenRetention] = useState<boolean>(false);
  const [choosenOptionIndex, setChoosenIndex] = useState<any>();
  const [choosenRetentionOptions, setChoosenRetentionOptions] = useState<any>();
  const [choosenOfferReason, setChoosenOfferReason] = useState<string>();
  const [retentionOptions, setRetentionOptions] = useState<any>();
  const [openReinstateAlert, setOpenReinstateAlert] = useState<boolean>(false);
  const [openActivityLoading, setOpenActivityLoading] =
    useState<boolean>(false);
  const availableOffers = [
    "Product",
    "No Longer Needs",
    "EPO too high",
    "Renewal rate too high",
    "Cannot make current payment",
    "Service",
    "Moving",
    "Total RTO/Term too high",
    "SAC too high",
  ];
  // Retention end
  const [exceededAmount, setExceededAmount] = useState(0);
  const [currentRole, setCurrentRole] = useState('');
  const [totallyUsedFTAmount, setTotallyUsedFTAmount] = useState<number>(0);
  const [usedFreeTimeAmounts, setUsedFreeTimeAmouts] = useState<any>([]);
  const [currentFTAmount, setCurrentFTAmount] = useState<any>(0);
  const [rentPaid, setRentPaid] = useState<any>(0);//RAC2
  const [exchangeSelected, setExchangeSelected] = useState<boolean>(false);//RAC2
  const [isExchangedAgreements, setExchangedAgreements] = useState<any>([]);//RAC2
  const [exchangeAgreementNumber, setExchangeAgreementNumber] = useState<boolean>(false);//RAC2
  const [isAutoPayEnabledAgreement, setIsAutoPayEnabledAgreement] = useState<any>('');
  const [isExchangeFuncEnabled, setExchangeFuncEnabled] = useState<boolean>(false);
  const [parentAgreementIdMapper, setParentAgreementIdMapper] = useState<any>('');
  const [isExchangeAgreementIndicator, setIsExchangeIndicator] = useState<any>('');
  const [exchangeAgreementNumberMapper, setExchangeAgreementNumberMapper] = useState<any>('')
  const [pendingAgreements, setPendingAgreements] = useState<any>([]);
  const [autoPayEnrolledAgreement, setAutoPayEnrolledAgreements] = useState<any>([]);
  const [exchangedAgreementsNumberPopup, setExchangedAgreementsNumberPopup] = useState<any>(false);
  const [exchangeAgrMapper, setExchangeAgrMapper] = useState<any>([]);
  const [isAutoPayPopupOpen, setIsAutoPayPopupOpen] = useState<boolean>(false);
  const [exchangeSuspenseAmount, setExchangeSuspenseAmount] = useState<any>(0);
  const [currentExchangeAgreementNumber, setCurrentExchangeAgreementNumber] = useState<any>('');
  /**
   * state use to check if the commitment is open for the customer.
   */
  const [isCommitmentOpen, setIsCommitmentOpen] = useState<boolean>(false);

    //capped End

  const location = useLocation<any>();
  const { ristotalsales, ristype, risNo, risreason, Risresponse }: any =
    (location && location.state) || "";
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  let SacAmt = 0;
  let epoAmt = 0;
  let currentUserResponse: any;
  let COAamount = 0.0;
  let backRentAmount = 0.0;
  let selectedValue: any;
  let swipeAmt = 0.0;
  let selectedLabel: any;
  let swiperes: any;
  let cardSaveDetail: any;
  let stateDD: any;
  let truearrayforcheckbox: any[] = [];
  const urlParams = new URLSearchParams(window.location.search);
  const returnType = urlParams.get("type");
  const retentionType = urlParams.get("CRTYPE");
  const exchangeAgreementId = urlParams.get('exchangeAgreementId');//RAC2
  const [registerNumber, setRegisterNumber] = useState("");
  const [openRDS, setOpenRDS] = useState(false);
  const [xBoxEditData, setXboxEditData] = useState<any>();
  const [acimaEditData, setAcimaEditData] = useState<any>();
  const [xBoxEditDataCollected, setXboxEditDataCollected] = useState<any>([]);
  const [acimaEditDataCollected, setAcimaEditDataCollected] = useState<any>([]);
  const RSDArrayValues = [
    {
      securityDepositAgreementNumber: "Select",
      securityDepositAgreement: "Select",
      depositAmount: "0.00",
      amountCollected: "0.00",
      totalToRefund: "0.00",
      reasonDescription: "",
    }
    // ,
    // {
    //   securityDepositAgreement: "0257486",
    //   depositAmount: "100.00",
    //   amountCollected: "0.00",
    //   totalToRefund: "100.00",
    //   reasonDescription: "",
    // },
    // {
    //   securityDepositAgreement: "9621445",
    //   depositAmount: "50.00",
    //   amountCollected: "0.00",
    //   totalToRefund: "50.00",
    //   reasonDescription: "",
    // },
    // {
    //   securityDepositAgreement: "7412658",
    //   depositAmount: "60.00",
    //   amountCollected: "0.00",
    //   totalToRefund: "60.00",
    //   reasonDescription: "",
    // },
    // {
    //   securityDepositAgreement: "321585",
    //   depositAmount: "25.00",
    //   amountCollected: "0.00",
    //   totalToRefund: "25.00",
    //   reasonDescription: "",
    // },
  ];
  const CoworkerNum = props.throwConfigValues;
  const configDetails= CoworkerNum.storeProfileResponse.configDetails[0];
  const [masterRSDData, setMasterRSDData] = useState(RSDArrayValues);
  const [securityDepositData, setSecurityDepositData] = useState([]);
  const [totalSurrenderAmount, setTotalSurrenderAmount] = useState(0);
  const [totalDepositAmount, setTotalDepositAmount] = useState(0);

  const [receiptOffSet, setReceiptOffSet] = useState(0);
  const [clubOffSet, setClubOffSet] = useState(0);
  const [scrollLoader, setscrollLoader] = useState(false);
  const [hasMore, sethasMore] = useState(true);
  const [hasMoreReceipt, sethasMoreReceipt] = useState(true);
  const [hasMoreClubRcp, sethasMoreClubRcp] = useState(true);
  const [agreementPayloadDecider, setAgreementPayloadDecider]: any = useState([]);
  // const [isCloudStoreSwipeDevicelist, setIsCloudStoreSwipeDevicelist] =
  //   useState<number>(0);

   //Payment Donation	
   const [donationType, setDonationType] = useState<any>({	
    isDonationEnabled:false,
    donationType:'',
    donationDesc:""	
  });	
  const donationDescType={
    'BGC': "Boys and Girls Club :",
    'STON': "Fill The Fridge :"
  }
  const [donationAmount, setDonationAmount] = useState<any>({	
    respFlag: false,	
    type: "",	
    amount: "0.00",	
    validateFlag:false	
  })	
  let [donationBtnFlag, setDonationBtnFlag] = useState<any>(false)	
  let [donationAmountAdd, setDonationAmountAdd] = useState<any>(0)	
  const [donationttxtBoxFlag, setDonationtextBoxFlag] = useState<boolean>(true)
  let [chargeCardContinueButtonFlag, setChargeCardContinueButtonFlag] = useState<boolean>(false)
  //cappedFreeTime starts
  const [cappedFreeTimeValues, setCappedFreeTimeValues] = useState<any>({
    cappedFreeTimePercentage: 0,
    cappedFreeTimeRegularFlag: false,
    cappedFreeTimeServiceFlag: false,
    cappedFreeTimeRetentionFlag: false,
    cappedFreeTimeInitPaymentFlag: false,
    cappedFreeTimePromoFlag: false,
    cappedFreeTimePopup: false
  });

  const [cappedFreeTimeResponseValues, setCappedFreeTimeResponseValues] = useState<any>({
    cappedFreeTimeSMRRAmount: 0,
    cappedFreeTimeRegular: 0,
    cappedFreeTimeService: 0,
    cappedFreeTimeRetention: 0,
    cappedFreeTimeInitPayment: 0,
    cappedFreeTimePromo: 0,
  });
  const [autoPayLoader, setAutoPayLoader] = useState<any>(false);
  //capped End

  //payment origin popup
  const [showPayOrgPopup, setShowPayOrgPopup] = useState(false);

// Reseting global variables
useEffect(()=>{
 
  return ()=>{
    console.log("INSIDE CleanerFunction");
    coWorkerRole = "";
addrowarray=null;
backRentValues = {
backRentOwed: "0.00",
amount: "0.00",
salesTax: "0.00",
taxRate: "0.00",
total: "0.00",
};
isRevenueRecogActivated = false;
diffStoreErrLbl = false;
resetInvoked = false;
basicPayGridValues= [];
unenrollautopayagreementids= [];
totalduereceiptidsupport = [];
tendertypeglobalarray = [];
FloatInputTxt=null;
AgreementSelect = [];
agreeementarray= [];
swipeCardDetails= [];
acceptPaymentParam= [];
actLDWArr= [];
actPolicyArr= [];
suspenseError= [];
diffStoreError= [];
epoError= [];
localGetPayment;
ReceiptCategoriesRequestParam = [];
swipeCardAmt= [];
AcceptclubPaymentParam = undefined;
showAutoPayPopup = false;
localGetPayData=null;
agrAmtTaxArray = [];
autoPayEnrollArray = [];
CardGrid=null;
chqAndMo = [];
revenueRecogConfigFlag=0
Clubagreementinsertion = [];
customerName = "";
selectedGrid=null;
clubInitialCheckFlag = true;
previousAmt = 0.0;
previousState = false;
chargeCardValue = 0.0;

  };
 
},[]);

// useEffect(() => {
//   if (agreementId == '0' && exchangeAgrMapper && exchangeAgrMapper?.length) {
//     console.log(exchangeAgrMapper, 'Exchanged Agreement Numbers');
//     setExchangedAgreementsNumberPopup(true);
//   }
// }, [exchangeAgrMapper])

  useEffect(() => {
    let totalRDSToAdd = 0;
    let totalRDSToSubtract = 0;
    const mapperFilter = securityDepositData.filter((el: any) => !(el.RSDLabel.includes('Collect')));
    mapperFilter.map((el: any) => {
      const filteredSurrenderPair: any = securityDepositData.filter((elem: any) =>
        elem.RSDLabel.includes(`${el.securityDepositAgreementNumber} - Collect`)
      );
      
      if (
        filteredSurrenderPair.length &&
        Number(filteredSurrenderPair[0].RSDAmount) <= Number(el.RSDAmount)
      ) {
        totalRDSToSubtract =
          totalRDSToSubtract + (Number(el.RSDAmount) - Number(filteredSurrenderPair[0].RSDAmount))
      } else if (
        filteredSurrenderPair.length &&
        Number(filteredSurrenderPair[0].RSDAmount) > Number(el.RSDAmount)
      ) {
        totalRDSToAdd =
          totalRDSToAdd +
          (Number(filteredSurrenderPair[0].RSDAmount) - Number(el.RSDAmount));
      }
    });

    setTotalSurrenderAmount(totalRDSToSubtract);
    setTotalDepositAmount(totalRDSToAdd);
  }, [securityDepositData]);

  useEffect(() => {
    /* eslint-disable no-console */
    if (
      paymentFunctionFlag.payFunc == 0 &&
      paymentFunctionFlag.isCashSale == 1
    ) {
      setnonInventoryPopUp(true);
    }
    let JsonsessionStoreDetails: any =
      window.sessionStorage.getItem("storeDetails");
    let sessionStoreDetails: any;
    try {
      sessionStoreDetails = JSON.parse(atob(JsonsessionStoreDetails));
    } catch (e: any) {
    }
    if (sessionStoreDetails?.ccPaymentMerchantId != null || undefined || "") {
      setstoreMerchantId(sessionStoreDetails?.ccPaymentMerchantId);
      setstorezip(sessionStoreDetails?.zip);
    }
    getPaymentinfo()
    // getpaid();
    getCustomerAddressInformation();
    pageload();
    stateApi();
    getCardDetails();
    getCustomerInformation();
  }, []);
  const getPaymentinfo=async()=>{
    let paymentFunctionFlag=0
    let commonModuleFlag=0
    let paymentDetails:any;

        if (
          CoworkerNum !== undefined ||
          (null &&
            CoworkerNum.storeProfileResponse !== undefined &&
            CoworkerNum.storeProfileResponse &&
            CoworkerNum.storeProfileResponse.configDetails !== undefined &&
            CoworkerNum.storeProfileResponse.configDetails.length > 0 &&
            CoworkerNum.storeProfileResponse.configDetails[0].configDetails !==
              undefined &&
            CoworkerNum.storeProfileResponse.configDetails[0].configDetails
              .length > 0)
        ) {
          for (
            let i = 0;
            i <
            CoworkerNum.storeProfileResponse.configDetails[0].configDetails
              .length;
            i++
          ) {
            const configArray =
              CoworkerNum.storeProfileResponse.configDetails[0].configDetails;
            
            if (configArray[i].paramKeyName == "PaymentFunctions") {
              paymentFunctionFlag= Number(
                configArray[i].paramValue
              );
             
            }
            if (configArray[i].paramKeyName == "CommonModulePaymentFlag") {
              commonModuleFlag= Number(
                configArray[i].paramValue
              );
            }
            
          }
        }
  if(paymentFunctionFlag==1){
    paymentDetails = await getpaymentinfo({
      customerID: customerId,
      agreementStatus: ["ACTIVE", "PEND"],
      commonModuleFlag:commonModuleFlag,
    });
    
  }
 
    getpaid(undefined,undefined,paymentDetails)
  }
  useEffect(() => {
    if (
      configFlags.isPaymentHistoryFeatureFlag == 1 &&
      configFlags.isPaymentFunctionsFeatureFlag == 1
    ) {
      getpayHis();
    }
    if (configFlags.isPaymentFunctionsFeatureFlag == 1) {
      getAlerts();
    }
    if (
      configFlags.isCloudStoreSwipeDevicelist == 1 &&
      configFlags.optimizedPayment == 1 &&
      configFlags.SwipeCardPayment == 1
    ) {
      swipeDevice_api();
    } else if (
      configFlags.isCloudStoreSwipeDevicelist == 0 &&
      configFlags.optimizedPayment == 1 &&
      configFlags.SwipeCardPayment == 1
    ) {
      swipeDevice_api();
    }
  }, [configFlags]);

  useEffect(() => {
    if (redirectOrigin === AMOrigin) {
      setIsFromAccountMgmt(true);
    }
  }, []);
  //This useEffect is trigger when chargeCard or NewCard button is Clicked
  useEffect(() => {
    if (chargeCardFlag == true) {
      let obj = {
        address_line_1: customerInfopreFill.customerPrimaryAddress,
        address_line2: customerInfopreFill.customerSecondaryAddress,
        city: customerInfopreFill.customerCity,
        state: customerInfopreFill.customerState,
        zip_code: customerInfopreFill.customerZip,
        name: customerInfopreFill.name,
      };
      window.sessionStorage.setItem(
        "billName",
        String(customerInfopreFill.name)
      );
      window.sessionStorage.setItem(
        "billAdd1",
        String(customerInfopreFill.customerPrimaryAddress)
      );
      window.sessionStorage.setItem(
        "billAdd2",
        String(customerInfopreFill.customerSecondaryAddress)
      );
      window.sessionStorage.setItem(
        "billState",
        String(customerInfopreFill.customerState)
      );
      window.sessionStorage.setItem(
        "billCity",
        String(customerInfopreFill.customerCity)
      );
      window.sessionStorage.setItem(
        "billZip",
        String(customerInfopreFill.customerZip)
      );
      setPopupfields({
        ...Popupfields,
        address_line_1: obj.address_line_1,
        address_line2: obj.address_line2,
        city: obj.city,
        zip_code: obj.zip_code,
        state: obj.state,
        name: obj.name,
      });
      setdefaultVal(obj.state);
      setChargeCardFlag(false);
      cardSaveDetail = Popupfields;
    }
  }, [chargeCardFlag]);

  useEffect(() => {
    if (userdata.user?.permissions?.NAV_MENU?.includes("RPDCUST")) {
      currentServiceFlags.isCustomerRedirection = 1;
    } else {
      currentServiceFlags.isCustomerRedirection = 0;
    }
  }, [userdata]);


  useEffect(() => {
    //debugger;
    if (primaryAddArr.length > 1) {
      const radioarr = new Array(primaryAddArr?.length).fill(false);
      setRadioArray(radioarr);
      setPrimAddPopUp(true);
    }
  }, [primaryAddArr]);

  useEffect(() => {
    const collectedRetentionOptions: any = [];
    if (offerResponse) {
      const filteredReason: any = offerResponse?.returnReasons.filter(
        (el: any) => el.reason == choosenOfferReason
      );
      offerResponse?.agreementOffers.forEach((offer: any) => {
        offer.updateDateTime = new Date(offer.updateDateTime);
      });
      const agreementData: any = offerResponse?.agreementOffers.sort(
        (a: any, b: any) => b.updateDateTime - a.updateDateTime
      );
      if (agreementData[0]?.exchangeOfferDescription && !isExchangeFuncEnabled) {
        collectedRetentionOptions.push({
          displayLabel: agreementData[0]?.exchangeOfferDescription,
          offerValue: {
            redirectionPage: 'Inventory',
            functionalityInPage: 'AGREXC',
            description: agreementData[0].exchangeOfferDescription,
            agreementExchangeAmount: agreementData[0]?.exchangeAmount,
          },
        });
      }

      if (agreementData[0]?.exchangeOfferDescription && !isExchangeFuncEnabled) {
        collectedRetentionOptions.push({
          displayLabel: agreementData[0]?.exchangeOfferDescription,
          offerValue: {
            redirectionPage: 'Inventory',
            functionalityInPage: 'AGREXC',
            description: agreementData[0].exchangeOfferDescription,
            agreementExchangeAmount: agreementData[0]?.exchangeAmount,
          },
        });
      }

      if (choosenOfferReason == "Product" || choosenOfferReason == "Service") {
        collectedRetentionOptions.push(
          {
            displayLabel: "Repair or switch out product",
            offerValue: {
              redirectionPage: "Agreement",
              functionalityInPage: "SW",
            },
          },
          {
            displayLabel: "Customer does not accept any offer",
          }
        );
      } else if (choosenOfferReason == "No Longer Needs") {
        collectedRetentionOptions.push(
          {
            displayLabel: "Explain lifetime reinstatement to the customer",
            offerValue: {
              redirectionPage: "Agreement",
              functionalityInPage: "R",
            },
          },
          {
            displayLabel: "Customer does not accept any offer",
          }
        );
      } else if (choosenOfferReason == "Moving") {
        collectedRetentionOptions.push(
          {
            displayLabel: "Give customers their local RAC store info",
            offerValue: {
              redirectionPage: "StoreManagement",
              functionalityInPage: "MOVE",
            },
          },
          {
            displayLabel: "Customer does not accept any offer",
          }
        );
      } else if (choosenOfferReason == 'RAC Exchange') { //RAC2
        collectedRetentionOptions.push(
          {
            displayLabel: agreementData[0]?.exchangeOfferDescription,
            offerValue: {
              redirectionPage: 'Inventory',
              functionalityInPage: 'AGMXNGS',
              description: 'Agreement Exchange Selected',
            }
          },
          {
            displayLabel: "Customer does not accept any offer"
          }
        );
      } else {
        // let alteredReasonDescription: any = '';
        // if (choosenOfferReason == 'Early Purchase Option (EPO) too high') {
        //   alteredReasonDescription = 'EPO too High';
        // } else if (choosenOfferReason == 'Same as Cash (SAC) too high') {
        //   alteredReasonDescription = 'SAC too High';
        // } else if (choosenOfferReason == 'Term Rent to own (TRTO) too high') {
        //   alteredReasonDescription = 'Total RTO/Term too high';
        // } else {
        //   alteredReasonDescription = choosenOfferReason;
        // }

        filteredReason.map((el: any) => {
          if (el.epoEligible == "Yes") {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].epoOfferDescription,
              offerValue: {
                redirectionPage: "Payment",
                functionalityInPage: "EPO",
                currentEpo: agreementData[0].currentEpo,
                epoDiscount: agreementData[0].epoDiscount,
                newEpo: agreementData[0].newEpo,
              },
            });
          }
          if (el.rateReductionEligible == "Yes") {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].rateOfferDescription,
              offerValue: {
                redirectionPage: "Agreement",
                functionalityInPage: "RR",
                currentRate: agreementData[0].currentRate,
                rateReduction: agreementData[0].rateReduction,
                newRate: agreementData[0].newRate,
              },
            });
          }
          if (el.termReductionEligible == "Yes") {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].termOfferReduction,
              offerValue: {
                redirectionPage: "Agreement",
                functionalityInPage: "TR",
                currentRemainingTerm: agreementData[0].currentRemainingTerm,
                termReduction: agreementData[0].termReduction,
                newRemainingTerm: agreementData[0].newRemainingTerm,
              },
            });
          }
          if (el.extensionEligible == "Yes") {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].extensionOfferDescription,
              offerValue: {
                redirectionPage: "Payment",
                functionalityInPage: "FREETIME",
                extension: agreementData[0].extension,
              },
            });
          }
        });
        if (collectedRetentionOptions.length) {
          collectedRetentionOptions.push({
            displayLabel: "Customer does not accept any offer",
          });
        } else {
          collectedRetentionOptions.push({
            displayLabel: "No retention offer option available",
          });
        }
      }
    } else {
      collectedRetentionOptions.push({
        displayLabel: "No retention offer option available",
      });
    }
    setRetentionOptions(collectedRetentionOptions);
  }, [choosenOfferReason]);

  const adjustrentalitemsaleOnclick = () => {
    // debugger;
    let editResponse: any = {};
    // paygridvalues.map((val:any)=>risselectedItems.inventoryId==val.Agreementid? editresponse={...risselectedItems}:null)
    paygridvalues.map((val: any, index: any) =>
      checkedAgr[0].Agreementid == val.Agreementid
        ? (editResponse = {
            ...obj.filter((e) => val.AgreementNumber == e.inventoryNumber),
          })
        : null
    );
    // setEditRisselecteditem({...risEditItemValue});
    obj.map((val, index) => {
      val.inventoryNumber == editResponse[0].inventoryNumber
        ? obj.splice(index, 1)
        : null;
    });
    // setEditresponse(editResponse)
    history.push({
      pathname: "/payment1/rentalitemsale",
      state: {
        editresponse: editResponse[0],
        customerID: customerId,
        customerName: `${customerdata.data?.GetCustomer?.value?.firstName} ${customerdata.data?.GetCustomer?.value?.lastName}`,
        editNo: "1",
      },
    });
  };

  //RIS_PC_NO_39 once the RIS response is received,the risSelecteditem() function is triggered.

  // const risSelecteditem = async () => {


  //   let selectallFlag = true;
  //   let storeDetails = getpayment?.paymentInfo;


  //   const type = ristype;

  //   if (Risresponse != undefined) {
  //     // setadjustRisbtnFlag(true)
  //     let coa = 0.0;
  //     let suspense = 0.0;
  //     let htSuspense = 0.0;
  //     let ipSuspense = 0.0;
  //     let wlDeposit = 0.0;

  //     if (
  //       getpayment?.paymentInfo?.remainingCredits !== undefined &&
  //       getpayment?.paymentInfo?.remainingCredits?.length > 0
  //     ) {
  //       let rcInfo = getpayment?.paymentInfo?.remainingCredits;
  //       let rcData = rcInfo?.filter((x) => x?.bucket.toLowerCase() === "coa");
  //       if (
  //         rcData != undefined &&
  //         rcData.length > 0 &&
  //         rcData[0].accountBalance > 0
  //       ) {
  //         COAamount = rcData[0]?.accountBalance;
  //         coa = Number(COAamount);
  //       }
  //       rcData = rcInfo?.filter((x) => x?.bucket.toLowerCase() === "suspense");
  //       if (
  //         rcData != undefined &&
  //         rcData.length > 0 &&
  //         rcData[0].accountBalance > 0
  //       ) {
  //         suspense = Number(rcData[0]?.accountBalance);
  //       }

  //       rcData = rcInfo.filter(
  //         (x) => x?.bucket.toLowerCase() === "ht suspense"
  //       );
  //       if (
  //         rcData != undefined &&
  //         rcData?.length > 0 &&
  //         rcData[0]?.accountBalance > 0
  //       ) {
  //         htSuspense = Number(rcData[0]?.accountBalance);
  //       }
  //       rcData = rcInfo.filter(
  //         (x) => x?.bucket.toLowerCase() === "ip suspense"
  //       );
  //       if (
  //         rcData != undefined &&
  //         rcData.length > 0 &&
  //         rcData[0].accountBalance > 0
  //       ) {
  //         ipSuspense = Number(rcData[0].accountBalance);
  //       }
  //     }
  //     let wlDepositDetail: any =
  //       getpayment?.balance == undefined ? [] : getpayment?.balance;
  //     let objBalanceDetails: any = wlDepositDetail?.filter(
  //       (x: any) => x?.balanceType == "WEBLEAD"
  //     );

  //     if (objBalanceDetails != undefined && objBalanceDetails?.length > 0) {
  //       if (Number(objBalanceDetails[0]?.balanceAmount) > 0) {
  //         wlDeposit = Number(objBalanceDetails[0]?.balanceAmount);
  //       } else {
  //         wlDeposit = 0.0;
  //       }
  //     }
  //     if (obj.length >= 1) {
  //       for (let i = 0; i < obj.length; i++) {
  //         const rispaygridvaluesNew = {
  //           AgreementNumber: obj[i].inventoryNumber,
  //           Agreementdesc: obj[i].inventoryDescription,
  //           Agreementid: obj[i].inventoryId,
  //           Agreementtype: type,
  //           Amount: Number(obj[i].totalsales),
  //           Autopay: "null",
  //           Autopay_enrollmentdate: null,
  //           COA: coa,
  //           CurrentDaysLate: 0,
  //           CurrentDueDate: "-",
  //           EPOAmount: 0,
  //           EPOTax: 0,
  //           actualEpoAmount: 0,
  //           EmailAddress: "",
  //           Latelast10: 0,
  //           Nextduedate: "-",
  //           Phonenumber: "",
  //           PromoFreeDays: 0,
  //           SAC_Date: "",
  //           Schedule: "-",
  //           SuspenseAmount: 0,
  //           Taxamount: Number(obj[i].salestax),
  //           // Number(salesTax)
  //           actualAmountDue: Number(obj[i].totalsales),
  //           agrLdwAmount: 0,
  //           agreementRate: obj[i].totalsales,
  //           agreementRateDetails: null,
  //           cardLastFour: 0,
  //           carriedLateFee: 0,
  //           carryRentAmountAdd: 0,
  //           carryRentAmountUsed: 0,
  //           carryRentTaxUsed: 0,
  //           clubCoverageTermination: null,
  //           clubFlag: true,
  //           clubMembershipNumber: "",
  //           clubName: "",
  //           clubactive: false,
  //           clublastLate10: 0,
  //           confirmeddeliversystatus: false,
  //           convTax: configFeeOnlyTax,
  //           coverageEndDate: "",
  //           deliveryAmount: Number(obj[i]?.Deliveryfee),
  //           deliveryAmountTax: 0,
  //           dueDateModified: false,
  //           editSchedule: "",
  //           extensionAmount: 0,
  //           htSuspense: 0,
  //           ipSuspense: 0,
  //           lFeeTaxAmount: 0,
  //           suspenseAdd: 0,
  //           suspenseSub: 0,
  //           lateFee: 0,
  //           ldwPercentage: NaN,
  //           ldwTaxAmount: 0,
  //           mattressFee: 0,
  //           mattressFeeTax: 0,
  //           noOfDaysFree: NaN,
  //           number_of_paymentsmade: 0,
  //           partyId: "",
  //           policyAmount: 0,
  //           policyTaxAmount: 0,
  //           processingFee: 0,
  //           processingFeeTax: 0,
  //           racTirePay: 0,
  //           rpayTaxAmount: 0,
  //           sign_status: "",
  //           stateProvinceName:
  //             stateProvinceName != undefined ? stateProvinceName : "",
  //           //  configFlags.isPaymentFunctionsFeatureFlag == 1
  //           //    ? getpayment?.paymentInfo?.storeInfo?.stateProvinceName
  //           //    : "",
  //           storeCityName: storeCityName != undefined ? storeCityName : "",
  //           //  configFlags.isPaymentFunctionsFeatureFlag == 1
  //           //    ? getpayment?.paymentInfo?.storeInfo?.city
  //           //    : "",
  //           store_number: String(window.sessionStorage.getItem("storeNumber")),
  //           suspense: 0,
  //           taxGeoCode: "",
  //           taxRate: "",
  //           wlDeposit: wlDeposit,
  //           zipPlus4UsedForTax: "",
  //           nineOneOneFee: 0,
  //           cellPhoneActivation: Number(obj[i].totalsales),
  //           // cellPhoneActivationTax: Number(val.ristotalsales),
  //           miscellaneousItemsNum: 0,
  //           isLdwAllowed: "0",
  //           carryLdwAmountTax: 0,
  //           carryLdwAmount: 0,
  //           cellPhoneActivationTax: 0,
  //           miscellaneousItemInfo: undefined,
  //           pastDueRent: 0,
  //           PromoFreeDaysApplied: 0,
  //           FreeTime: null, // Added By Me
  //           isCOATransfered: false,
  //           isSwitchoutDeliveryPending: 0, // Added By Me
  //           editedEpoAmount: 0,
  //           actualPastDueRent: 0,
  //           acceptEpoFlag: 0,
  //           // restitutiontypeAgree:1,
  //           originAgreementId: null,
  //           // restitutionInfo: res,
  //           clubDocumentSignStatus: null,

  //           transferOldSuspense: 0,

  //           agreementNoteDetails: {
  //             noteAmountDueIncTax: "0.00",
  //             remainingNoteCost: "0.00",
  //             remainingAgreementCost: "0.00",
  //             isNoteAgreement: "0",
  //             NotAmountFinal: "0",
  //             editedNoteAmount: "",
  //             remainingNoteCostAmount: "0.00",
  //           },
  //           accepoEPoAgrnoteInfo: undefined,
  //           customerClubId: "",
  //           isClubDeactivateTriggered: false,
  //           lateFeeTaxRate: 0,
  //           isReturnAgrFlag: 0,
  //           constLateFee: 0,
  //           waivedLateFee: 0,
  //           initialPaymentFlag: 0,
  //           taxEngineData: null,
  //           storeMerchantID: null,
  //           otherIncomeType: "Rental Item Sale",
  //           // restitutiontypeAgree:1,
  //           // restitutionInfo: res,
  //         };
  //         let data: any = [];
  //         const sameStoreData = paygridvalues.filter(
  //           (el) =>
  //             el.store_number ==
  //               String(window.sessionStorage.getItem("storeNumber")) &&
  //             el.Agreementtype != "Club"
  //         );
  //         //  data=[...sameStoreData,...rispaygridvaluesNew]
  //         sameStoreData.push(rispaygridvaluesNew);
  //         const differentStoreData = paygridvalues.filter(
  //           (el) =>
  //             el.store_number !==
  //               String(window.sessionStorage.getItem("storeNumber")) &&
  //             el.Agreementtype != "Club"
  //         );
  //         const club = paygridvalues.filter((x) => x.Agreementtype == "Club");
  //         let sortableData = [...club, ...sameStoreData, ...differentStoreData];
  //         paygridvalues = [...sortableData];

  //       }
  //     }
  //   }

  //   otherIncomeOnblur();
  //   setpaygridvalues(paygridvalues);

  //   history.replace({
  //     pathname: `/payment1/paymentinformation/${customerId}/0`,
  //     state: {
  //       Risresponse: {},
  //       ristype: "Rental Item Sale",
  //       risNo: 0,
  //       editNo: 0,
  //     },
  //   });
  // };

  // const removepaymentrisOnclick = async () => {
  //   debugger;

  //   let selectallFlag = true;
  //   if (paygridvalues.length == 1) {
  //     selectallFlag = false;
  //   }
  //   let index = paygridvalues.findIndex(
  //     (x) =>
  //       x.Agreementtype == "Rental Item Sale" &&
  //       x.AgreementNumber == checkedAgr[0].AgreementNumber
  //   );
  //   let objCheAdjust = document.getElementById(
  //     "chk" + index
  //   ) as HTMLInputElement;
  //   objCheAdjust.checked = false;
  //   await settotaldueamount(
  //     "chk" + index,
  //     index,
  //     paygridvalues[index].Amount,
  //     paygridvalues[index].Agreementid,
  //     undefined
  //   );
  //   paygridvalues = paygridvalues.filter(
  //     (e) => e.AgreementNumber != checkedAgr[0].AgreementNumber
  //   );
  //   obj.map((val, index) =>
  //     val.inventoryNumber == checkedAgr[0].AgreementNumber &&
  //     checkedAgr[0].Agreementtype == "Rental Item Sale"
  //       ? obj.splice(index, 1)
  //       : null
  //   );
  //   let selectedItem = checkedAgr.filter(
  //     (x) => x.Agreementtype == "Rental Item Sale"
  //   );

  //   setpaygridvalues(paygridvalues);
  //   setRisRemovepaymentbtnFlag(false);

  //   // if (agreementId == "0" && selectallFlag) {
  //   //   selectallcheckbox(true);
  //   // }

  //   setRisRemovepaymentbtnFlag(false);
  //   setadjustRisbtnFlag(false);
  // };
  useEffect(() => {
    // debugger
    if (risFlag == true) {
      let checkBoxArray = document.getElementsByName("grid");

      // checkBoxArray.forEach(function (item,index) {
      for (let index = 0; index < checkBoxArray.length; index++) {
        let checkBoxobj = checkBoxArray[index] as HTMLInputElement;
        let checkBox = checkBoxobj;
        if (paygridvalues[index]?.Agreementtype == "Rental Item Sale") {
          if (checkBox.checked == false) {
            checkBox.checked = true;
            settotaldueamount(
              "chk" + index,
              index,
              paygridvalues[index].Amount,
              paygridvalues[index].Agreementid,
              undefined
            );
          } else if (checkBox.checked == true) {
            editAdjustRes({ resArray: checkedAgr });
          }
        }
      }
      // )
    }
  }, [risFlag]);

  let falsearrayforcheckbox: any[] = [];
  let loggedInStoreNumber: any = window.sessionStorage.getItem("storeNumber");
  let storeNumber: any = "";
  let newStore: boolean;
  //static array of payment origin
  let paymentOriginarr = [
    { label: "In Store", value: "Store" },
    { label: "Over the Phone", value: "Pay By Phone" },
  ];
  //Static array of DevicName dropdown
  const DeviceNameArrfunc = () => {
    if (
      configFlags?.isCloudStoreSwipeDevicelist == 0 &&
      DeveiceResp !== undefined &&
      DeveiceResp.status == 200 &&
      DeveiceResp.data !== undefined &&
      DeveiceResp.data.StorePaymentDeviceResponse !== undefined &&
      DeveiceResp.data.StorePaymentDeviceResponse.devices !== undefined &&
      DeveiceResp.data.StorePaymentDeviceResponse.devices !== null &&
      DeveiceResp.data.StorePaymentDeviceResponse.devices.length > 0
    ) {
      return DeveiceResp.data.StorePaymentDeviceResponse.devices.map(
        (values: any) => {
          const storeNumber = String(Number(values.storeNumber));
          const terminalId = String(values.termNumber)?.slice(-3);
          return { label: values.name, value: terminalId };
        }
      );
    } else if (
      configFlags?.isCloudStoreSwipeDevicelist == 1 &&
      DeveiceResp !== undefined &&
      DeveiceResp.status == 200 &&
      DeveiceResp.data !== undefined &&
      DeveiceResp.data.devices !== undefined &&
      DeveiceResp.data.devices !== null &&
      DeveiceResp.data.devices.length > 0
    ) {
      return DeveiceResp.data.devices.map((values: any) => {
        const storeNumber = String(Number(values.storeNumber));
        const terminalId = String(values.termNumber)?.slice(-3);
        return { label: values.name, value: terminalId };
      });
    } else {
      return [];
    }
  };
  //static array of check info popup dropdown
  let CheckDropdownArr = [
    { value: "0", label: "Select" },
    { value: "1", label: "Personal check" },
    { value: "2", label: "Travellers check" },
    { value: "3", label: "Payroll Check" },
    { value: "4", label: "Government Check" },
    { value: "5", label: "Cashiers Check" },
    { value: "6", label: "Club Claim Check" },
  ];

  //this useeffect is to set the onchange variable of the devicename in the session storage
  useEffect(() => {
    setSessionstorage(String(window.sessionStorage.getItem("deviceName")));
    setTerminalId(String(window.sessionStorage.getItem("TerminalId")));
    setRegisterNumber(String(window.sessionStorage.getItem("registerNumber")));

    if (TerminalId === undefined) {
      setTerminalId("0");
      setSessionstorage(
        String(window.sessionStorage.setItem("TerminalId", "0"))
      );
    }
  }, []);
  useEffect(() => {
  }, [policyService_state]);
  useEffect(() => {
    if (paymentMade) {
      ReceiptCategoriesRequestParam = [];
      acceptPaymentParam = [];
      AcceptclubPaymentParam = undefined;
      clubInitialCheckFlag = true;
    }
  }, [paymentMade]);
  //UseEffect helps to empty the global variable when the page is navigated once after taking the payment.
  useEffect(()=>{
    if (paymentMadeOptimized) {
      
      tendertypeglobalarray=[]
    }
  },[paymentMadeOptimized]);
  useEffect(() => {
    if (APpopupcompleted > 1) {
      if(autoPayLoader){
        setmasterloader(true);
      }
      if (ResponseObject.customerPaymentMethodId !== undefined) {
        let apResponseObject = [...ResponseObject.AgreementIds];
        let AgreementIDS = [...ResponseObject.AgreementIds];
        let index = ResponseObject.AgreementIds?.indexOf("Club");
        if (index !== -1) {
          AgreementIDS?.splice(index, 1);
        }

        let amountvalue = 0;
        let Schedule = "Weekly";
        if (IsClubSelectedForAutopay == false) {
          Schedule = paygridvalues.filter(
            (x) => x.Agreementid === apResponseObject[0]
          )[0]?.Schedule;
        } else {
          Schedule = paygridvalues.filter((x) => x.Agreementtype === "Club")[0]
            .Schedule;
        }
      
        for (let i = 0; i < apResponseObject.length; i++) {
          let amtDueResp = paygridvalues.filter(
            (x) => String(x.Agreementid) == String(apResponseObject[i]) || String(x.Agreementtype) == String(apResponseObject[i])
          );
          let autoPayAmountWithTax=0;
          if(amtDueResp?.length>0){
            switch(Schedule){
              case 'Weekly' :
                autoPayAmountWithTax =(Number(amtDueResp[0]?.agreementRateDetails?.weeklyRent)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.weeklyRent):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.weeklyRentTax)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.weeklyRentTax):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.weeklyLdw)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.weeklyLdw):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.weeklyLdwTax)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.weeklyLdwTax):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.weeklyPolicy)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.weeklyPolicy):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.weeklyPolicyTax)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.weeklyPolicyTax):0)
              break;
              case 'Bi-Weekly':
                autoPayAmountWithTax =(Number(amtDueResp[0]?.agreementRateDetails?.biWeeklyRent)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.biWeeklyRent):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.biWeeklyRentTax)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.biWeeklyRentTax):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.biWeeklyLdw)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.biWeeklyLdw):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.biWeeklyLdwTax)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.biWeeklyLdwTax):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.biWeeklyPolicy)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.biWeeklyPolicy):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.biWeeklyPolicyTax)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.biWeeklyPolicyTax):0)
              break;
              case 'Monthly':
                autoPayAmountWithTax =(Number(amtDueResp[0]?.agreementRateDetails?.monthlyRent)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.monthlyRent):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.monthlyRentTax)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.monthlyRentTax):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.monthlyLdw)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.monthlyLdw):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.monthlyLdwTax)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.monthlyLdwTax):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.monthlyPolicy)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.monthlyPolicy):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.monthlyPolicyTax)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.monthlyPolicyTax):0)
              break;
              case 'Semi-Monthly':
                autoPayAmountWithTax =(Number(amtDueResp[0]?.agreementRateDetails?.semiMonthlyRent)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.semiMonthlyRent):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.semiMonthlyRentTax)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.semiMonthlyRentTax):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.semiMonthlyLdw)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.semiMonthlyLdw):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.semiMonthlyLdwTax)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.semiMonthlyLdwTax):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.semiMonthlyPolicy)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.semiMonthlyPolicy):0)+
                                    (Number(amtDueResp[0]?.agreementRateDetails?.semiMonthlyPolicyTax)>0? 
                                    Number(amtDueResp[0]?.agreementRateDetails?.semiMonthlyPolicyTax):0)
              break;
              default :
              autoPayAmountWithTax=0

            }
          }
          if (amtDueResp[0] != undefined && apResponseObject[i] != "Club") {
            
            amountvalue =
              Number(amountvalue) +
              Number(autoPayAmountWithTax);
          } else if (apResponseObject[i] == "Club") {
            let objClubInfo =
              getpayment?.paymentInfo.clubInformation[0].clubMembershipNumber;
            let amtDueResp = agrAmtTaxArray.filter(
              (x) => x.agreementId == Number(objClubInfo)
            );
            amountvalue =
              Number(amountvalue) +
              Number(autoPayAmountWithTax);
          }
        }
        let number = String(
          getpayment?.paymentInfo.customerInfo[0].phoneNumber
        );
        number = number.replace(/[^\d]/g, "");
        let Payload2: AutopaySendMessageRequest = {
          sendTextFlag: 1,
          callTxtReq: {
            language: "E",
            phoneNumber: String(number),
            textMessage: "TXT2AC",
            messageType: "TXT2AC",
            messageTemplateKey: "TXT2AC-MESSAGE",
            messageSubstitutes: {
              schedule: Schedule,
              amount: String(Number(amountvalue).toFixed(2)),
              lastFour: getCardService_state.cardResp.filter(
                (x) =>
                  x.customerPaymentMethodId ===
                  ResponseObject.customerPaymentMethodId
              )[0].cardLastFour,
            },
            callBackParams: {
              lastName: String(
                getpayment?.paymentInfo.customerInfo[0].lastName
              ),
              firstName: String(
                getpayment?.paymentInfo.customerInfo[0].firstName
              ),
              customerId: String(customerId),
              customerPaymentMethodId: ResponseObject.customerPaymentMethodId, //issue
              agreementIds: AgreementIDS,
              clubId:
                index !== -1
                  ? String(
                      getpayment?.paymentInfo.clubInformation[0].customerClubId
                    )
                  : "", //issue
            },
            storeNumber: String(window.sessionStorage.getItem("storeNumber")),
            transactionId: customerId,
          },
        };
        sendMessage(Payload2);
      } else {
        let number = String(
          getpayment?.paymentInfo.customerInfo[0].phoneNumber
        );
        number = number.replace(/[^\d]/g, "");
        let Payload: AutopaySendMessageRequest = {
          sendTextFlag: 0,
          signupReq: {
            phoneNumber: String(number),
            storeNumber: String(window.sessionStorage.getItem("storeNumber")),
            partyId: customerId,
            emailAddress: String(
              getpayment?.paymentInfo.customerInfo[0].emailAddress
            ),
          },
        };
        sendMessage(Payload);
      }
    }
    showAutoPayPopup = false;
    setpropsautopayopen(-1);
    if(autoPayLoader){
      setmasterloader(false);
      history.push(`/agreement/rental/delivery/${customerId}/${agreementId}?exagreementid=${exchangeAgreementId}`)
    }
  }, [APpopupcompleted]);

  useEffect(() => {
    if (showAddCard) {
      callVantivIframe(1);
    }
    if (showChargeCard) {
      callVantivIframe(2);
    }
  }, [showAddCard, showChargeCard]);

  // UseEffect for Txt2Pay enable disable - saraswathi;
  useEffect(() => {
    let isChecked = 0;
    for (let i = 0; i < document.getElementsByName("grid").length; i++) {
      if (
        (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
        true
      ) {
        isChecked = 1;
      }
    }
    if (
      isChecked == 1 &&
      totaldue > 0 &&
      (remainingamountdue === totaldue || tendertypeglobalarray.length === 0)
    ) {
      setCardrequire({ ...Cardrequire, txt2paybtn: false });
    } else {
      setCardrequire({ ...Cardrequire, txt2paybtn: true });
    }
    setPaymentAmt(totaldue);
  }, [remainingamountdue]);
  // Assign Alert - code ends here - Lokesh N
  interface agrarr {
    agreementId: number;
  }

  interface ParamType {
    customerId: string;
    agreementId: string;
  }
  const { customerId } = useParams<ParamType>();
  const { agreementId } = useParams<ParamType>();
  const [customerparamid]: any = useState(customerId);
  const [agreementparamid]: any = useState(agreementId);
  let unrollautopayagreementids: agrarr[] = [];
  let Enrollautopaagreementids: any = [];
  let bindgridmanipulation: any;

  bindgridmanipulation = [];
  let chkboxArr: any = [];

  const getCardDetails = async () => {
    let pageload = await GetCardInfo(customerId);
    let cardinfo: any = [];
    let getCardRes: any = [];
    let filteredCardData = pageload?.paymentMethods?.filter(
      (el) =>
        el.cardTypeRef !== "PP" &&
        el.cardTypeRef !== "BL" &&
        el.cardTypeRef !== "EC" &&
        el.cardTypeRef !== "GC" &&
        el.cardTypeRef !== "VM" &&
        el.cardTypeRef !== null &&
        el.cardTypeRef !== ""
    );
    if (filteredCardData?.length > 0) {
      for (let i = 0; i < filteredCardData?.length; i++) {
        cardinfo.push({
          accountNumber: filteredCardData[i]?.tokenInfo?.accountNumber,
          apFlag: 0,
          billingAddressDetails: {
            addressLine1: filteredCardData[i]?.billingAddress?.addressLine1,
            addressLine2: filteredCardData[i]?.billingAddress?.addressLine2,
            city: filteredCardData[i]?.billingAddress?.city,
            state: filteredCardData[i]?.billingAddress?.state,
            zip: filteredCardData[i]?.billingAddress?.postalCode,
          },
          billingAddressId:
            filteredCardData[i]?.billingAddress?.billingAddressId,
          cardExpiration: filteredCardData[i]?.tokenInfo?.expirationDate,
          cardHolderName: filteredCardData[i]?.cardHolderName,
          cardLastFour: filteredCardData[i]?.lastFour,
          cardRef: filteredCardData[i]?.cardTypeRef,
          cardToken: filteredCardData[i]?.tokenInfo?.paymentToken,
          cardType: filteredCardData[i]?.cardType,
          customerId: Number(filteredCardData[i]?.customerId),
          customerPaymentMethodId: filteredCardData[i]?.paymentMethodId,
          routingNumber: filteredCardData[i]?.tokenInfo?.routingNumber,
        });
      }
      pageload = { cardResp: cardinfo };
    } else {
      pageload = { cardResp: [] };
    }

    setgetCardService_state(pageload);

    if (pageload.cardResp !== undefined && pageload?.cardResp?.length > 0) {
      const Card_FalseArray: any = new Array(pageload.cardResp.length).fill(
        false
      );
      setCardRadiArr(Card_FalseArray);
      setTxt2PayRadioArr(Card_FalseArray);
      setEditCard({
        ...EditCard,
        address_1:
          pageload.cardResp[editcardindex].billingAddressDetails.addressLine1,
        address_2:
          pageload.cardResp[editcardindex].billingAddressDetails.addressLine2,
        Cardname: pageload.cardResp[editcardindex].cardHolderName,
        city: pageload.cardResp[editcardindex].billingAddressDetails.city,
        zip: pageload.cardResp[editcardindex].billingAddressDetails.zip,
        state: pageload.cardResp[editcardindex].billingAddressDetails.state,
        cardExpDate: pageload.cardResp[editcardindex].cardExpiration,
      });
      let mulCardArr: any = [];
      let cardToken: any = [];
      for (let i = 0; i < pageload.cardResp.length; i++) {
        mulCardArr.push({
          lastFour: String(pageload.cardResp[i].cardLastFour),
          Amount: "0.00",
          billingAddress: pageload.cardResp[i].billingAddressDetails,
          expirationDate: pageload.cardResp[i].cardExpiration,
          paymentId: pageload.cardResp[i].customerPaymentMethodId,
          cardType: pageload.cardResp[i].cardType,
          cvv: "",
          cvvEncrypt: "",
          chargeCard: false,
          cardToken: pageload.cardResp[i].cardToken,
          cardRef: pageload.cardResp[i].cardRef,
        });
        cardToken.push({
          lastFour: String(pageload.cardResp[i].cardLastFour),
          cardToken: pageload.cardResp[i].cardToken,
        });
      }
      setcardToken(cardToken);
      setmultipleCard(mulCardArr);

    }
  };


  const pageload = async () => {
    let Role = "";
    if (containerData != undefined) {
      currentUserResponse = containerData.GetEmployeeId();
      Role = containerData.GetRole();
      setempId(currentUserResponse);
      setCurrentRole(Role);
      const menuConfig = containerData.GetMenuConfig();
      if(menuConfig && menuConfig.some((item: any)=> ( item.appComponentFunctionName=='Cash Payment'))){
        enableCashPayment = validateCashPayment(menuConfig);
        setenableCashPayment(enableCashPayment);
      }
    } else {
      const GetcurrentUser = await GetEmployeeID();
      if (GetcurrentUser.status === 200) {
        const GetcurrentUserResponse = GetcurrentUser.data;
        const EmployeeId = GetcurrentUserResponse.employeeId;
        setempId(EmployeeId);
        const GetMenuPayload = {
          coworkerId: EmployeeId,
        };
        const GetMenuDetails: any = await GetRole(GetMenuPayload);
        if (GetMenuDetails.status === 200) {
          const GetMenuDetailsResponse = GetMenuDetails.data;
          Role = GetMenuDetailsResponse.coworkerProfile.role;
          setCurrentRole(Role);
        if(GetMenuDetailsResponse.menuConfig && GetMenuDetailsResponse.menuConfig.some((item: any)=> ( item.appComponentFunctionName=='Cash Payment'))){  
          enableCashPayment = validateCashPayment(GetMenuDetailsResponse.menuConfig);
          setenableCashPayment(enableCashPayment);
        }
      }
      }
    }
    const payload = {
      module: "Accept Payment",
      storeNumber: window.sessionStorage.getItem("storeNumber"),
      currentRole: Role,
    };
    setCoWorkerRoleCarryWaive(Role);
    const profileResp = await getprofileaccess(payload);
    setprofileAccResp(profileResp);
  };

  const validateCashPayment = (menuConfig: any) => {
    return !(menuConfig.some((item: any)=> ( item.appComponentFunctionName=='Cash Payment' && item.edit == 1)))
  }

  const isManageCardEnabled = (payGrid: any) => {
   if(payGrid) {
      return (payGrid.some((item: any)=> ( item.autopayEnrollmentDate != null && item.storeNumber == String(window.sessionStorage.getItem('storeNumber')))))
   }
   return false;
  }

  const getCardAfterUpdate = async () => {
    let pageload = await GetCardInfo(customerId);
    let cardinfo: any = [];
    let getCardRes: any = [];
    if (pageload?.paymentMethods?.length > 0) {
      for (let i = 0; i < pageload?.paymentMethods?.length; i++) {
        cardinfo.push({
          accountNumber: pageload?.paymentMethods[i]?.tokenInfo?.accountNumber,
          apFlag: 0,
          billingAddressDetails: {
            addressLine1:
              pageload?.paymentMethods[i]?.billingAddress?.addressLine1,
            addressLine2:
              pageload?.paymentMethods[i]?.billingAddress?.addressLine2,
            city: pageload?.paymentMethods[i]?.billingAddress?.city,
            state: pageload?.paymentMethods[i]?.billingAddress?.state,
            zip: pageload?.paymentMethods[i]?.billingAddress?.postalCode,
          },
          billingAddressId:
            pageload?.paymentMethods[i]?.billingAddress?.billingAddressId,
          cardExpiration:
            pageload?.paymentMethods[i]?.tokenInfo?.expirationDate,
          cardHolderName: pageload?.paymentMethods[i]?.cardHolderName,
          cardLastFour: pageload?.paymentMethods[i]?.lastFour,
          cardRef: pageload?.paymentMethods[i]?.cardTypeRef,
          cardToken: pageload?.paymentMethods[i]?.tokenInfo?.paymentToken,
          cardType: pageload?.paymentMethods[i]?.cardType,
          customerId: Number(pageload?.paymentMethods[i]?.customerId),
          customerPaymentMethodId: pageload?.paymentMethods[i]?.paymentMethodId,
          routingNumber: pageload?.paymentMethods[i]?.tokenInfo?.routingNumber,
        });
      }
      pageload = { cardResp: cardinfo };
    } else {
      pageload = { cardResp: [] };
    }
    setgetCardService_state(pageload);
    if (pageload.cardResp !== undefined) {
      const Card_FalseArray: any = new Array(pageload.cardResp.length).fill(
        false
      );
      setCardRadiArr(Card_FalseArray);
      setTxt2PayRadioArr(Card_FalseArray);
      setEditCard({
        ...EditCard,
        address_1:
          pageload.cardResp[editcardindex].billingAddressDetails.addressLine1,
        address_2:
          pageload.cardResp[editcardindex].billingAddressDetails.addressLine2,
        Cardname: pageload.cardResp[editcardindex].cardHolderName,
        city: pageload.cardResp[editcardindex].billingAddressDetails.city,
        zip: pageload.cardResp[editcardindex].billingAddressDetails.zip,
        state: pageload.cardResp[editcardindex].billingAddressDetails.state,
        cardExpDate: pageload.cardResp[editcardindex].cardExpiration,
      });
    }
    let mulCardArr: any = [];
    let cardToken: any = [];
    let mulCardUI: any = [];
    for (let i = 0; i < pageload.cardResp.length; i++) {
      mulCardArr.push({
        lastFour: String(pageload.cardResp[i].cardLastFour),
        Amount: "0.00",
        billingAddress: pageload.cardResp[i].billingAddressDetails,
        expirationDate: pageload.cardResp[i].cardExpiration,
        paymentId: pageload.cardResp[i].customerPaymentMethodId,
        cardType: pageload.cardResp[i].cardType,
        cvv: "",
        cvvEncrypt: "",
        chargeCard: false,
        cardToken: pageload.cardResp[i].cardToken,
        cardRef: pageload.cardResp[i].cardRef,
      });
      cardToken.push({
        lastFour: String(pageload.cardResp[i].cardLastFour),
        cardToken: pageload.cardResp[i].cardToken,
      });
    }
    setcardToken(cardToken);
    setmultipleCard(mulCardArr);
    tendertypeglobalarray = tendertypeglobalarray.filter(
      (x) => !x.tendertype.split(" - ")[0].includes("Card ending")
    );
    settotalduetendertype(tendertypeglobalarray);
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    //
    const tenderAmount = Number(
      tendertypeglobalarray.length == 0
        ? 0.0
        : tendertypeglobalarray
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
    );
    if (
      Number(totaldue) -
        Number(availableCreds)+
        Number(donationAmountAdd) +
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount) >
      Number(tenderAmount)
    ) {
      setremainingamountdue(
        Number(
          Number(totaldue) -
            Number(availableCreds)+
            Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) -
            Number(tenderAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
        ).toFixed(2)
      );
    } else {
      setremainingamountdue("0.00");
      setchange(
        Number(
          Number(tenderAmount) -
            Number(totaldue) -
            Number(availableCreds)+
            Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
        ).toFixed(2)
      );
    }
    // tenderAmountCalculation()
    setTenderUseEffectCalculation(true)
  };
  const unenrollAutoPayCloseClick = () => {
    setvarbalecheckbox(false);
    setsendtext(true);
    setunenrollautopayopen(false);
  };
  const AutoPayCloseClick = () => {
    setautopaypopup(false);
    setAutopaybutton(true);
    if(exchangeAgreementId){
      history.push(`/agreement/rental/delivery/${customerId}/${agreementId}?exagreementid=${exchangeAgreementId}`)
    }
  };
  const AutoPayNotnowClick = () => {
    setautopaypopup(false);
    setAutopaybutton(true);
    setvarbalecheckbox(false);
    setsendtext(true);
    if(exchangeAgreementId){
      history.push(`/agreement/rental/delivery/${customerId}/${agreementId}?exagreementid=${exchangeAgreementId}`)
    }
  };
  const AutoPayClearClick = () => {
    setvarbalecheckbox(false);
    setupdatedarray(unerollemptyarray);
  };
  const unCheckandChecking = (indexValue: any) => {
    let checkuncheckarr: any = [];
    updatedarray.map((value: any, index: any) => {
      if (Number(indexValue) == Number(index) && updatedarray[index] == true) {
        checkuncheckarr.push(false);
      } else if (
        Number(indexValue) == Number(index) &&
        updatedarray[index] == false
      ) {
        checkuncheckarr.push(true);
      } else {
        checkuncheckarr.push(updatedarray[index]);
      }
    });
    setupdatedarray(checkuncheckarr);
  };
  const phoneNumberRequiredPopUp = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          {phoneNumberRequired == true ? (
            <Typography className={classes.racErrorFunction}>
              This action requires a primary phone number please update the
              primary phone number
            </Typography>
          ) : null}
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={() => setphoneNumberRequired(false)}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const zipCodeRequiredPopUp = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          <Typography className={classes.racErrorFunction}>
            There is no customer zip code available amount due will be
            calculated based on store zip code. use payment traingle if customer
            zip code needs to update in payment page.
          </Typography>
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={() => setcustomerzipcodenull(false)}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  /**Multiple Primary Address */
  const renderPrimaryAddress = () => {
    return (
      <>
        <Grid
          style={{
            marginLeft: "32%",
            fontFamily: "OpenSans-bold",
            fontSize: "medium",
            marginTop: "-14px",
          }}
        >
          Customer is having multiple primary address.
        </Grid>
        <Grid
          style={{
            marginLeft: "31%",
            fontFamily: "OpenSans-bold",
            fontSize: "medium",
          }}
        >
          Please select any one of the address as primary
        </Grid>
        <Grid>
          <RACTable
            renderTableHead={renderHeadPrimAddress}
            renderTableContent={() => renderBodyPrimAddress(primaryAddArr)}
          />
        </Grid>
        <Grid style={{ marginTop: "3%" }}>
          <RACButton
            variant="outlined"
            color="inherit"
            style={{ marginLeft: "75%", padding: "8px 26px" }}
            onClick={() => {
              setInActivePopSaveDisable(true);
              redirectionTopaymentsearch();
            }}
            // paddingTop:'8px' , paddingBottom:'8px' , paddingLeft:'2%' , paddingRight:'2%'
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            className={classes.me1}
            style={{ float: "right", marginRight: "2%", padding: "8px 20px" }}
            disabled={inActivePopSaveDisable}
            onClick={() => savebtnPrimary()}
          >
            Save
          </RACButton>
        </Grid>
      </>
    );
  };

  /**Save button onclick function in Multiple Primary Address */
  const savebtnPrimary = async () => {
    // debugger;
    let count = 0;

    for (let i = 0; i < primaryAddArr?.length; i++) {
      if (
        primaryAddArr[i].agreementIdArr?.length > 0 &&
        String(primaryAddArr[i].addressId) !=
          String(primaryAddArr[Number(primaryAddressIndex)].addressId)
      ) {
        // setMultipleAgreementPrimaryAddress(true)
        count++;
      }
    }
    setPrimAddPopUp(false);

    if (count > 0) {
      setPrimAddSavePopup(true);
    } else {
      PrimarySaveBtn(undefined);
    }
  };

  /*This function is triggered when yes or no button is clicked  */
  const PrimarySaveBtn = async (type: any) => {
    // debugger
    let active = "Y";
    let addressType = "PRIM";
    let savePayload: any = [];
    // let selectedPrimaryAgreeID:any
    const primDeliveAddressId: any = [];
    // const allAddressDataActive: any = []

    for (let j = 0; j < primaryAddArr?.length; j++) {
      let selectedPrimaryAddress = 0;

      if (
        String(primaryAddArr[j].addressId) ==
        String(primaryAddArr[Number(primaryAddressIndex)].addressId)
      ) {
        selectedPrimaryAddress = 1;
      } else {
        if (
          primaryAddArr[j].agreementIdArr?.length > 0 &&
          selectedPrimaryAddress == 0
        ) {
          if (type == "No") {
            active = "Y";

            addressType = "DELIV";
          } else if (type == "Yes") {
            active = "N";

            addressType = "PRIM";

            primaryAddArr[Number(primaryAddressIndex)].agreementIdArr = [
              ...primaryAddArr[Number(primaryAddressIndex)].agreementIdArr,
              ...primaryAddArr[j].agreementIdArr,
            ];

            primaryAddArr[j].agreementIdArr = [];
          }
        } else {
          active = "N";

          addressType = "PRIM";

          primaryAddArr[j].agreementIdArr = [];
        }
      }
    }
    for (let i = 0; i < primaryAddArr?.length; i++) {
      active = "Y";

      addressType = "PRIM";

      let selectedPrimaryAddress = 0;

      if (
        String(primaryAddArr[i].addressId) ==
        String(primaryAddArr[Number(primaryAddressIndex)].addressId)
      ) {
        selectedPrimaryAddress = 1;
      } else {
        if (
          primaryAddArr[i].agreementIdArr?.length > 0 &&
          selectedPrimaryAddress == 0
        ) {
          if (type == "No") {
            active = "Y";

            addressType = "DELIV";
          } else if (type == "Yes") {
            active = "N";

            addressType = "PRIM";
          }
        } else {
          active = "N";

          addressType = "PRIM";
        }
      }

      savePayload.push({
        city: primaryAddArr[i].city,
        addressType: addressType,
        postalCode: primaryAddArr[i].postalCode,
        active: active,
        addressLine1: primaryAddArr[i].addressLine1,
        addressLine2: primaryAddArr[i].addressLine2,
        state: primaryAddArr[i].state,
        agreementIds: primaryAddArr[i].agreementIdArr,
        addressId: primaryAddArr[i].addressId,
      });
      primDeliveAddressId.push(primaryAddArr[i].addressId);
    }

    // // for (let j = 0; j < allAddressDataActive?.length; j++) {
    // //   if (!primDeliveAddressId.includes(allAddressDataActive[j]?.addressId)) {
    // //     primDeliveAddressId.push(customerAddressActive?.GetCustomerAddress?.value?.addresses[j].addressId)
    // //     savePayload.push({
    // //       "city": allAddressDataActive[j]?.city,
    // //       "addressType": allAddressDataActive[j]?.addressType,
    // //       "postalCode": allAddressDataActive[j]?.postalCode,
    // //       "active": Number(allAddressDataActive[j]?.active) == 1 ? "Y" : "N",
    // //       "addressLine1": allAddressDataActive[j]?.addressLine1,
    // //       "addressLine2": allAddressDataActive[j]?.addressLine2,
    // //       "state": allAddressDataActive[j]?.state,
    // //       "agreementIds": allAddressDataActive[j]?.agreementIdArr,
    // //       "addressId": allAddressDataActive[j]?.addressId
    // //     })
    //   }
    // }

    const UpdateCustomerRequest = {
      customerId: customerId,
      addresses: savePayload,
    };
    setPrimAddSavePopup(false);
    setPrimAddPopUp(false);
    setmasterloader(true);
    const result = await UpdateAddressInfo(UpdateCustomerRequest);
    setmasterloader(false);
    if (result?.data?.updateAddress?.value?.customerId !== undefined) {
      setPrimAddPopUp(false);
      setPrimaryAddressUpdated(true);
    } else {
      seterrorPopup(true);
    }
  };
  /**Confirmation pop-up rendering function in Multiple Primary Address */
  const renderPrimarySaveAddress = () => {
    return (
      <Grid container>
        <Grid
          style={{
            marginLeft: "20px",
            marginRight: "20px",
            fontFamily: "OpenSans-bold",
            fontSize: "16px",
          }}
        >
          Other Primary Address are associated with agreements. Do you want to
          change it to new primary address
        </Grid>
        {/* , marginTop:'-10px' */}
        {/* <Grid style={{marginLeft:'31%' , fontFamily:'OpenSans-bold' , fontSize:'medium'}}>Please select any one of the address as primary</Grid>   */}
        <Grid
          container
          className={`${classes.justifycontentcenter} ${classes.spacerMT4}`}
        >
          <RACButton
            variant="outlined"
            color="inherit"
            // style={{marginLeft:'25%', padding:'8px 26px'}}
            className={`${classes.me2} ${classes.foc}`}
            onClick={() => PrimarySaveBtn("No")}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            // className={classes.me1}
            // style={{float:'right' , marginRight:'25%' , padding:'8px 20px'}}
            // disabled={inActivePopSaveDisable}
            onClick={() => {
              PrimarySaveBtn("Yes");
              // setPrimAddSavePopup(false)
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const headerPrimAdd = [
    "Radio",
    "Address Line 1",
    "Address Line 2",
    "Zip",
    "City",
    "State",
    "Store#",
    "Agreement Count",
  ];
  /**Funtion to render the table header in Multiple Primary Address */
  const renderHeadPrimAddress = () => (
    <>
      {headerPrimAdd.map((val: any, index: any) => {
        if (val !== "Radio") {
          return (
            <RACTableCell
              // style={{borderBottomStyle: 'groove' }}
              key={index}
            >
              {val}
            </RACTableCell>
          );
        } else {
          return (
            <RACTableCell
              // style={{borderBottomStyle: 'groove'}}
              key={index}
            ></RACTableCell>
          );
        }
      })}
    </>
  );
  const hideLengthyText = (Text: any, MaxLength: number, Mask: any) => {
    return Text == "" || Text == null
      ? "-"
      : Text.length > MaxLength
      ? Text.substring(0, MaxLength) + Mask
      : Text;
  };
  const hideLengthyTextAgreementNumber = (Text: any) => {
    return Text;
  };

  /**Function to render the table content in Multiple Primary Address */
  const renderBodyPrimAddress = (primAddDetails: any) => {
    if (primAddDetails.length > 0) {
      return primAddDetails.map((obj: any, index: any) => {
        return (
          <React.Fragment key={index}>
            <RACTableRow
              key={index}
              style={{
                backgroundColor: "white",
                paddingLeft: "5%",
                paddingRight: "5%",
              }}
            >
              <RACTableCell>
                <RACRadio
                  value=""
                  // eslint-disable-next-line sonarjs/no-redundant-boolean
                  checked={radioArray[index] == false ? false : true}
                  onClick={() => {
                    handleChangeForPrimAddress(index);
                  }}
                />
              </RACTableCell>
              <RACTableCell style={{ paddingRight: "1%" }}>
                {obj.addressLine1}
              </RACTableCell>
              <RACTableCell>
                {obj.addressLine2 == "" || obj.addressLine2 == null
                  ? "-"
                  : obj.addressLine2}
              </RACTableCell>
              <RACTableCell>{obj.postalCode}</RACTableCell>
              <RACTableCell>{obj.city}</RACTableCell>
              <RACTableCell>{obj.state}</RACTableCell>
              <RACTableCell title={obj.storeNumberMultiple}>
                {hideLengthyText(obj.storeNumberMultiple, 15, "...")}
              </RACTableCell>
              <RACTableCell title={obj.agreementNumber}>
                {hideLengthyTextAgreementNumber(obj.agreementIdArr?.length)}
              </RACTableCell>
            </RACTableRow>
          </React.Fragment>
        );
      });
    }
  };

  const handleChangeForPrimAddress = (index: any) => {
    const rad: any = radioArray.map((item: any, position: any) => {
      return index == position ? true : false;
    });
    setPrimaryAddressIndex(String(index));
    setRadioArray([...rad]);
    primaryAddArr.map((val: any, indexNew: any) => {
      if (indexNew == index && rad[index] == true) {
        setselectedPrimAddress(val);
      } else if (indexNew == val && rad[index] == false) {
        rad[index] == true;
        setRadioArray([...rad]);
      }
    });
    setInActivePopSaveDisable(false);
  };

  const primaryAddresspopupok = () => {
    setPrimaryAddressUpdated(false);
    window.location.reload();
  };

  const primaryAddressupdatedSuccessfully = () => {
    return (
      <Grid container>
        <Grid
          style={{
            marginLeft: "18px",
            marginRight: "15px",
            fontFamily: "OpenSans-bold",
            fontSize: "16px",
          }}
        >
          Primary Address is updated successfully
        </Grid>
        <RACButton
          style={{ marginTop: "20px", marginLeft: "180px" }}
          variant="contained"
          color="primary"
          id="assignAlertBtn"
          className={classes.mt1}
          onClick={() => {
            primaryAddresspopupok();
          }}
        >
          Ok
        </RACButton>
      </Grid>
    );
  };

  const suspenseDeleteFunc = () => {
    const checkSomeFinalSuspenseUse = totalsuspensemaster?.some(
      (el) => el.sustype == "FinalTransferUsed" || el.sustype == "FinalUsed"
    );
    const returnAGRId = paygridvalues?.some((el) => el.isReturnAGR == 1);
    let checkReturnAGRSuspenseUse = [];
    if (returnAGRId?.length > 0) {
      checkReturnAGRSuspenseUse = totalsuspensemaster?.some(
        (el) => String(el.AgreementNumber) == String(returnAGRId[0].Agreementid)
      );
    }

    if (
      checkSomeFinalSuspenseUse?.length > 0 ||
      checkReturnAGRSuspenseUse?.length > 0
    ) {
      setSuspenseDeleteExpection(true);
      if (checkSomeFinalSuspenseUse?.length > 0) {
        setFinalSuspenseDelete(true);
      }
      if (checkReturnAGRSuspenseUse?.length > 0) {
        setReturnAgrSuspense(true);
      }
    } else {
      setsuspenseAllDelete(true);
    }
  };

  const finalPaymentUseSuspenseDeleteFunc = async (agreementNumber: any) => {
    let finalPaymentAGRSIndex = paygridvalues.findIndex(
      (x) => String(x.AgreementNumber) == String(agreementNumber)
    );
    finalPaymentAGRSIndex = Number(finalPaymentAGRSIndex);
    let objAGR = getpayment.amountDue.amountDueResponse.filter(
      (x) =>
        String(x.agreementId) ==
        String(paygridvalues[finalPaymentAGRSIndex].Agreementid)
    );
    paygridvalues[finalPaymentAGRSIndex].Amount =
      Number(objAGR[0]?.totalAmount) + Number(objAGR[0]?.totalTax);
    paygridvalues[finalPaymentAGRSIndex].Taxamount = Number(
      objAGR[0]?.totalTax
    );
    paygridvalues[finalPaymentAGRSIndex].Nextduedate = objAGR[0]?.nextDueDate;
    paygridvalues[finalPaymentAGRSIndex].suspenseSub = 0;
    paygridvalues[finalPaymentAGRSIndex].isFinalTransferSusUseApplied = false;
    paygridvalues[finalPaymentAGRSIndex].isFinalTransferSusUseCancel = false;
     multipleAdjust(); // Handles the other functionalities enable disable and transfer suspense functionality
    editAdjustRes({ resArray: checkedAgr }); // Responsible for the payload formations and setting amounts accordimgly (remaining amount due , total due , available credits)
  };

  const finalsuspenseOverallDelete = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          {suspenseDeleteExpection == true && returnAgrSuspense == false ? (
            <Typography className={classes.racErrorFunction}>
              Final payment Suspense cannot be deleted. Please use set to
              default
            </Typography>
          ) : suspenseDeleteExpection == false && returnAgrSuspense == true ? (
            <Typography className={classes.racErrorFunction}>
              Return agreement Suspense cannot be deleted. Please use set to
              default
            </Typography>
          ) : (
            <Typography className={classes.racErrorFunction}>
              Final payment & Return agreement Suspense cannot be deleted.
              Please use set to default
            </Typography>
          )}
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={() => setSuspenseDeleteExpection(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };

  const suspenseOverAllDelete = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          <Typography className={classes.racErrorFunction}>
            Are you sure to delete all the suspenses?
          </Typography>
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={() => deleteclick()}
          >
            Yes
          </RACButton>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={() => setsuspenseAllDelete(false)}
          >
            No
          </RACButton>
        </Grid>
      </div>
    );
  };
  const EnrollAutoPayment = () => {
    if (paygridvalues[0].Phonenumber == "") {
      setphoneNumberRequired(true);
    } else {
      showAutoPayPopup = true;
      setpropsautopayopen(1);
      agreeementarray = [];
      if (
        getCardService_state.length == 0 ||
        getCardService_state.cardResp === undefined
      ) {
        setautopaypopup(true);
      } else {
        for (let i = 0; i < paygridvalues.length; i++) {
          if (
            (document.getElementsByName("grid")[i] as HTMLInputElement)
              .checked === true
          ) {
            if (paygridvalues[i].Agreementtype === "Club") {
              setIsClubSelectedForAutopay(true);
              agreeementarray.push("Club");
            } else {
              agreeementarray.push(paygridvalues[i].Agreementid);
            }
          }
        }
        setagreementarraystate(agreeementarray);
      }
    }
  };

  const onClickRemoveDueAmount = async () => {
    checkedAgr.map((val: any) => {
      if (val.Agreementtype == "EPO Rent" || val.Agreementtype == "PIF Rent") {
        val.Agreementtype = "Rent";
      }
      val.acceptEpoFlag = 0;
      val.Amount = 0.0;
      val.Taxamount = 0.0;
      val.rpayTaxAmount = 0.0;
      val.agrLdwAmount = 0.0;
      val.lateFee = 0.0;
      val.lFeeTaxAmount = 0.0;
      val.ldwTaxAmount = 0.0;
      val.suspenseAdd = 0.0;
      val.Nextduedate = val.CurrentDueDate;
      val.isZeroPayment = true;
      (val.waivedLateFee = 0),
        (val.FreeTime = null),
        (val.suspenseSub = 0),
        (val.dueDateModified = false);
      val.carriedLate = 0;
      val.carryRentAmountUsed = 0;
    });
    await multipleAdjust();
    setcheckedAgr([...checkedAgr]);
    editAdjustRes({ resArray: checkedAgr });
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:"RemoveAmountDue"
    }
    setUserTraceData([...userTraceData,traceObj])
  };

  const AutoPaySubmitClick = () => {
    let isSignUp = false;
    for (let i = 0; i < document.getElementsByName("grid").length; i++) {
      let isChecked = (
        document.getElementsByName("grid")[i] as HTMLInputElement
      ).checked;
      if (isChecked == true) {
        Enrollautopaagreementids.push(paygridvalues[i].Agreementid);
      }
      if (
        getCardService_state.length === 0 &&
        getCardService_state.cardResp === undefined
      ) {
        let paymentsMade = paygridvalues[i].number_of_paymentsmade;

        if (isChecked == true) {
          if (paymentsMade === 0) {
            let payload1 = {
              agreementIds: Enrollautopaagreementids,
            };
            EnrollAutopay(payload1);
          } else {
            isSignUp = true;
            break;
          }
        }
      }
    }
    if (isSignUp) {
      let number = String(getpayment?.paymentInfo.customerInfo[0].phoneNumber);
      number = number.replace(/[^\d]/g, "");
      let Payload: AutopaySendMessageRequest = {
        sendTextFlag: 0,
        signupReq: {
          phoneNumber: String(number),
          storeNumber: String(window.sessionStorage.getItem("storeNumber")),
          partyId: customerId,
          emailAddress: String(
            getpayment?.paymentInfo.customerInfo[0].emailAddress
          ),
        },
      };
      sendMessage(Payload);
    }
    setvarbalecheckbox(false);
    setautopaypopup(false);
    setAutopaybutton(true);
    if (exchangeAgreementId) {
      history.push(`/agreement/rental/delivery/${customerId}/${agreementId}?exagreementid=${exchangeAgreementId}`)
    }
  };

  

  const LDW_apiCall = async (Num: any, otherActData?, manualIndex?) => {
    if (carryRentWidget == true) {
      carryOnchange();
    }
    if (actLDWArr.length > 0) {
      let objLdwAction = actLDWArr.filter(
        (x) =>
          x.agreementId ==
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].Agreementid
          )
      );
      if (objLdwAction[0] != undefined) {
        objLdwAction[0].action = Number(Num);
      } else {
        let ldwParam: ldwReq = {
          agreementId: Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].Agreementid
          ),
          action: Number(Num),
          cusCoverageData:
            otherActData?.custCoverageresp !== undefined
              ? otherActData?.custCoverageresp.data
              : otherActState.data,
        };
        actLDWArr.push(ldwParam);
      }
    } else {
      let ldwParam: ldwReq = {
        agreementId: Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .Agreementid
        ),
        action: Number(Num),
        cusCoverageData:
          otherActData?.custCoverageresp !== undefined
            ? otherActData?.custCoverageresp.data
            : otherActState.data,
      };
      actLDWArr.push(ldwParam);
    }

    /* eslint-disable no-console */

    let objLdWAmount = totalduereceiptid.filter(
      (x) =>
        x.agreement_id ==
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .AgreementNumber
    );
    if (Num === 2) {
      if (paygridvalues[0].Agreementtype == "Club") {
        document
          .getElementById("maincheckbox")
          ?.setAttribute("disabled", "true");
        setclubCheckDisabled(true);
        AcceptclubPaymentParam = undefined;
      }

      let totalAdjustedLdwAmount = "0";
      totalAdjustedLdwAmount =
        otherActData?.resp !== undefined
          ? otherActData?.resp.ldwAmount
          : customerCoverageObject.ldwAmount;

      let TerminateLdw_Amt =
        parseFloat(totaldue) - parseFloat(totalAdjustedLdwAmount);
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].Amount =
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Amount - parseFloat(totalAdjustedLdwAmount);
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].Taxamount =
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .Taxamount
        ) -
        parseFloat(
          String(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].ldwTaxAmount
          )
        );
      const totaltaxamount =
        totaltax -
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .ldwTaxAmount
        );
      settotaltax(totaltaxamount);
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].agrLdwAmount = 0;
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].ldwTaxAmount = 0;
      if (objLdWAmount[0] != undefined) {
        objLdWAmount[0].agreement_totalamount = TerminateLdw_Amt;
      }

      setRemaniningAmtStatic(
        Number(RemaniningAmtStatic) - parseFloat(totalAdjustedLdwAmount)
      );
      settotaldue(TerminateLdw_Amt);
      setremainingamountdue(
        Number(RemaniningAmtStatic) - parseFloat(totalAdjustedLdwAmount)
      ); 
      setAcceptpaymentbuttonenable(true);
      setActionState(1);
      resetPayment(undefined);
    } else {
      if (paygridvalues[0].Agreementtype == "Club") {
        document.getElementById("maincheckbox")?.removeAttribute("disabled");
        setclubCheckDisabled(false);
      }

      let ActiveLdw_Amt =
        parseFloat(totaldue) +
        parseFloat(
          otherActData?.resp !== undefined
            ? otherActData?.resp.ldwAmount
            : customerCoverageObject.ldwAmount
        ) +
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .carryLdwAmount
        ) +
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .carryLdwAmountTax
        );
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].Amount =
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Amount +
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .carryLdwAmount
        ) +
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .carryLdwAmountTax
        ) +
        parseFloat(
          otherActData?.resp !== undefined
            ? otherActData?.resp.ldwAmount
            : customerCoverageObject.ldwAmount
        );
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].agrLdwAmount = parseFloat(
        String(
          Number(
            otherActData?.resp !== undefined
              ? otherActData?.resp.ldwAmount
              : customerCoverageObject.ldwAmount
          ) +
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].carryLdwAmountTax
          ) +
            Number(
              paygridvalues[
                manualIndex !== undefined ? manualIndex : selectedGrid
              ].carryLdwAmount
            )
        )
      );
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].ldwTaxAmount = parseFloat(
        String(
          otherActData?.resp !== undefined
            ? otherActData?.resp.ldwAmount
            : customerCoverageObject.ldwAmount
        ) +
          String(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].carryLdwAmountTax
          )
      );

      const totaltaxamount =
        totaltax +
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .ldwTaxAmount
        );
      settotaltax(totaltaxamount);
      if (objLdWAmount[0] != undefined) {
        objLdWAmount[0].agreement_totalamount = ActiveLdw_Amt;
      }
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].Taxamount = Number(
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .Taxamount
        ) +
          parseFloat(
            String(
              Number(
                paygridvalues[
                  manualIndex !== undefined ? manualIndex : selectedGrid
                ].ldwTaxAmount
              )
            )
          )
      );
      setRemaniningAmtStatic(
        Number(RemaniningAmtStatic) +
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].carryLdwAmount
          ) +
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].carryLdwAmountTax
          ) +
          parseFloat(
            otherActData?.resp !== undefined
              ? otherActData?.resp.ldwAmount
              : customerCoverageObject.ldwAmount
          )
      );

      settotaldue(ActiveLdw_Amt);
      setremainingamountdue(
        Number(RemaniningAmtStatic) +
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].carryLdwAmount
          ) +
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].carryLdwAmountTax
          ) +
          parseFloat(
            otherActData?.resp !== undefined
              ? otherActData?.resp.ldwAmount
              : customerCoverageObject.ldwAmount
          )
      ); //change here .tofixed
      setAcceptpaymentbuttonenable(true);
      setActionState(2);
      resetPayment(undefined);
    }
    let receiptObj = ReceiptCategoriesRequestParam.filter(
      (e) =>
        e.agreementId ==
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Agreementid
    );
    let acceptObj = acceptPaymentParam.filter(
      (e) =>
        e.agreementId ==
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Agreementid
    );
    if (receiptObj[0] != undefined) {
      receiptObj[0].ldwFeeAmount = Number(
        paygridvalues[
          manualIndex !== undefined ? manualIndex : selectedGrid
        ].agrLdwAmount.toFixed(2)
      );
      // receiptObj[0].ldwFeeAmount = Num === 2 ? 0.00 : Number(Number(
      //   Number(
      //     otherActData?.resp !== undefined
      //       ? otherActData?.resp.ldwAmount
      //       : customerCoverageObject.ldwAmount
      //   ) -
      //     Number(
      //       otherActData?.resp !== undefined
      //         ? otherActData?.resp.ldwTax
      //         : customerCoverageObject.ldwTax
      //     ) +
      //     Number(
      //       paygridvalues[
      //         manualIndex !== undefined ? manualIndex : selectedGrid
      //       ].carryLdwAmount
      //     )
      // ).toFixed(2));

      receiptObj[0].ldwFeeAmountTax = Number(
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .ldwTaxAmount
        ).toFixed(2)
      );
      // receiptObj[0].ldwFeeAmountTax = Number(
      //   otherActData?.resp !== undefined
      //     ? otherActData?.resp.ldwTax
      //     : customerCoverageObject.ldwTax
      // );
      receiptObj[0].amountDue = Number(
        (
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .Amount -
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].lateFee
          )
        ).toFixed(2)
      );
      receiptObj[0].totalTax = Number(
        paygridvalues[
          manualIndex !== undefined ? manualIndex : selectedGrid
        ].Taxamount.toFixed(2)
      );
    }
    if (acceptObj[0] != undefined) {
      acceptObj[0].ldwFeeAmount = Number(
        paygridvalues[
          manualIndex !== undefined ? manualIndex : selectedGrid
        ].agrLdwAmount.toFixed(2)
      );
      // receiptObj[0].ldwFeeAmount = Num === 2 ? 0.00 : Number(Number(
      //   Number(
      //     otherActData?.resp !== undefined
      //       ? otherActData?.resp.ldwAmount
      //       : customerCoverageObject.ldwAmount
      //   ) -
      //     Number(
      //       otherActData?.resp !== undefined
      //         ? otherActData?.resp.ldwTax
      //         : customerCoverageObject.ldwTax
      //     ) +
      //     Number(
      //       paygridvalues[
      //         manualIndex !== undefined ? manualIndex : selectedGrid
      //       ].carryLdwAmount
      //     )
      // ).toFixed(2));

      acceptObj[0].agreementAmountDue = Number(
        (
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].Amount
          ) +
          (Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].acceptEpoFlag
          ) == 1
            ? Number(
                paygridvalues[
                  manualIndex !== undefined ? manualIndex : selectedGrid
                ].suspenseSub
              )
            : 0)
        ).toFixed(2)
      );
      acceptObj[0].totalTax = Number(
        paygridvalues[
          manualIndex !== undefined ? manualIndex : selectedGrid
        ].Taxamount.toFixed(2)
      );
    }
    paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid].toggleOptionalServicePerformed=Num==1?'Y':'N'
  };
  
  const Policy_apiCall = async (Num, otherActData?, manualIndex?) => {
    if (actPolicyArr.length > 0) {
      let objLdwAction = actPolicyArr.filter(
        (x) =>
          x.agreementId ==
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].Agreementid
          )
      );
      if (objLdwAction[0] != undefined) {
        objLdwAction[0].action = Number(Num);
      } else {
        let ldwParam: ldwReq = {
          agreementId: Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].Agreementid
          ),
          action: Number(Num),
          cusCoverageData:
            otherActData?.custCoverageresp !== undefined
              ? otherActData?.custCoverageresp.data
              : otherActState.data,
        };
        actPolicyArr.push(ldwParam);
      }
    } else {
      let ldwParam: ldwReq = {
        agreementId: Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .Agreementid
        ),
        action: Number(Num),
        cusCoverageData:
          otherActData?.custCoverageresp !== undefined
            ? otherActData?.custCoverageresp.data
            : otherActState.data,
      };
      actPolicyArr.push(ldwParam);
    }
    /* eslint-disable no-console */
    let objLdWAmount = totalduereceiptid.filter(
      (x) =>
        x.agreement_id ==
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .AgreementNumber
    );
    if (Num === 2) {
      setpolicyService_state(1);
      let TerminateLdw_Amt =
        parseFloat(totaldue) -
        parseFloat(
          otherActData?.resp !== undefined
            ? otherActData?.resp.policyAmount
            : customerCoverageObject.policyAmount
        );
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].Amount =
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Amount -
        parseFloat(
          otherActData?.resp !== undefined
            ? otherActData?.resp.policyAmount
            : customerCoverageObject.policyAmount
        );
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].Taxamount =
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .Taxamount
        ) -
        parseFloat(
          String(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].policyTaxAmount
          )
        );
      const totaltaxamount =
        totaltax -
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .policyTaxAmount
        );
      settotaltax(totaltaxamount);
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].policyAmount = 0;
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].policyTaxAmount = 0;
      if (objLdWAmount[0] != undefined) {
        objLdWAmount[0].agreement_totalamount = TerminateLdw_Amt;
      }
      setRemaniningAmtStatic(
        Number(RemaniningAmtStatic) -
          parseFloat(
            otherActData?.resp !== undefined
              ? otherActData?.resp.policyAmount
              : customerCoverageObject.policyAmount
          )
      );
      settotaldue(TerminateLdw_Amt);
      setremainingamountdue(
        Number(RemaniningAmtStatic) -
          parseFloat(
            otherActData?.resp !== undefined
              ? otherActData?.resp.policyAmount
              : customerCoverageObject.policyAmount
          )
      ); //change here .tofixed
      setAcceptpaymentbuttonenable(true);
      resetPayment(undefined);
    } else {
      setpolicyService_state(2);
      let ActiveLdw_Amt =
        parseFloat(totaldue) +
        parseFloat(
          otherActData?.resp !== undefined
            ? otherActData?.resp.policyAmount
            : customerCoverageObject.policyAmount
        );
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].Amount =
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Amount +
        parseFloat(
          otherActData?.resp !== undefined
            ? otherActData?.resp.policyAmount
            : customerCoverageObject.policyAmount
        );
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].policyAmount = parseFloat(
        String(
          Number(
            otherActData?.resp !== undefined
              ? otherActData?.resp.policyAmount
              : customerCoverageObject.policyAmount
          ) -
            Number(
              otherActData?.resp !== undefined
                ? otherActData?.resp.policyAmount
                : customerCoverageObject.policyAmount
            )
        )
      );
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].policyTaxAmount = parseFloat(
        String(
          otherActData?.resp !== undefined
            ? otherActData?.resp.policyAmount
            : customerCoverageObject.policyAmount
        )
      );

      const totaltaxamount =
        totaltax +
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .policyTaxAmount
        );
      settotaltax(totaltaxamount);

      if (objLdWAmount[0] != undefined) {
        objLdWAmount[0].agreement_totalamount = ActiveLdw_Amt;
      }
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].Taxamount = Number(
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .Taxamount
        ) +
          parseFloat(
            String(
              paygridvalues[
                manualIndex !== undefined ? manualIndex : selectedGrid
              ].policyTaxAmount
            )
          )
      );
      setRemaniningAmtStatic(
        Number(RemaniningAmtStatic) +
          parseFloat(
            otherActData?.resp !== undefined
              ? otherActData?.resp.policyAmount
              : customerCoverageObject.policyAmount
          )
      );

      settotaldue(ActiveLdw_Amt);
      setremainingamountdue(
        Number(RemaniningAmtStatic) +
          parseFloat(
            otherActData?.resp !== undefined
              ? otherActData?.resp.policyAmount
              : customerCoverageObject.policyAmount
          )
      ); //change here .tofixed
      setAcceptpaymentbuttonenable(true);
      resetPayment(undefined);
    }
    let receiptObj = ReceiptCategoriesRequestParam.filter(
      (e) =>
        e.agreementId ==
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Agreementid
    );
    let acceptObj = acceptPaymentParam.filter(
      (e) =>
        e.agreementId ==
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Agreementid
    );
    if (receiptObj[0] != undefined) {
      receiptObj[0].racTirePay = Number(
        paygridvalues[
          manualIndex !== undefined ? manualIndex : selectedGrid
        ].policyAmount.toFixed(2)
      );
      receiptObj[0].racTirePayTax = Number(
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .policyTaxAmount
        ).toFixed(2)
      );
      receiptObj[0].amountDue = Number(
        (
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .Amount -
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].lateFee
          )
        ).toFixed(2)
      );
      receiptObj[0].totalTax = Number(
        paygridvalues[
          manualIndex !== undefined ? manualIndex : selectedGrid
        ].Taxamount.toFixed(2)
      );
    }
    if (acceptObj[0] != undefined) {
      acceptObj[0].ldwFeeAmount = Number(
        paygridvalues[
          manualIndex !== undefined ? manualIndex : selectedGrid
        ].policyAmount.toFixed(2)
      );
      acceptObj[0].agreementAmountDue = Number(
        (
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].Amount
          ) +
          (Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].acceptEpoFlag
          ) == 1
            ? Number(
                paygridvalues[
                  manualIndex !== undefined ? manualIndex : selectedGrid
                ].suspenseSub
              )
            : 0)
        ).toFixed(2)
      );
      acceptObj[0].totalTax = Number(
        paygridvalues[
          manualIndex !== undefined ? manualIndex : selectedGrid
        ].Taxamount.toFixed(2)
      );
    }
    paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid].toggleOptionalServicePerformed=Num==1?'Y':'N'
  };
  const ClubApiCall = async () => {
    let paymentScheduleId;
    if (
      getpayment?.paymentInfo.clubDaysLateAndPaymentSchedule[0]
        .paymentSchedule === "Bi-Weekly"
    ) {
      paymentScheduleId = 1;
    } else if (
      getpayment?.paymentInfo.clubDaysLateAndPaymentSchedule[0]
        .paymentSchedule === "Weekly"
    ) {
      paymentScheduleId = 2;
    } else if (
      getpayment?.paymentInfo.clubDaysLateAndPaymentSchedule[0]
        .paymentSchedule === "Monthly"
    ) {
      paymentScheduleId = 3;
    } else if (
      getpayment?.paymentInfo.clubDaysLateAndPaymentSchedule[0]
        .paymentSchedule === "Semi-Monthly"
    ) {
      paymentScheduleId = 4;
    }
    setpaymentSchedule(paymentScheduleId);
    const clubParam: ClubReq = {
      paymentScheduleId: paymentSchedule,
      customerId: customerId,
      isCreate: clubState,
      storeNumber: window.sessionStorage.getItem("storeNumber"),
    };
    let ClubFunc = await ActiveClub(clubParam);
  };
  let unenrollautopayfilterdata = paygridvalues.filter(
    (x) =>
      x.Autopay_enrollmentdate !== "" &&
      x.Autopay_enrollmentdate !== null &&
      x.Autopay_enrollmentdate !== undefined
  );
  let unenrollfalsearray: any = new Array(unenrollautopayfilterdata.length);
  let unerollemptyarray: any = new Array(unenrollautopayfilterdata.length);
  unerollemptyarray.fill(false);
  unenrollfalsearray.fill(false);

  const unenrollautopay = () => {
    if (paygridvalues[0].Phonenumber == "") {
      setphoneNumberRequired(true);
    } else {
      setunenrollautopayopen(true);
      for (let i = 0; i < document.getElementsByName("grid").length; i++) {
        if (
          (document.getElementsByName("grid")[i] as HTMLInputElement)
            .checked === true
        ) {
          unenrollautopayagreementids.push(paygridvalues[i].Agreementid);
        }
      }

      for (let i = 0; i < unenrollautopayfilterdata.length; i++) {
        if (
          unenrollautopayagreementids[i] ===
          unenrollautopayfilterdata[i].Agreementid
        ) {
          unenrollfalsearray[i] = true;
        }
      }
      setupdatedarray(unenrollfalsearray);
    }
  };

  useEffect(() => {
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

      let Amount =0
      let suspenseEligibleAgreementCount=0
      let paygridvaluesStore = paygridvalues?.filter(x=>x.store_number==String(window.sessionStorage.getItem("storeNumber")))
      for(let i=0 ; i<checkedAgr?.length;i++){
        if(checkedAgr[i].otherIncomeType==''
        &&checkedAgr[i].Agreementtype!="Club"
        &&checkedAgr[i].Agreementtype!="Note Payment"
        &&checkedAgr[i].Nextduedate!="SAC"
        &&checkedAgr[i].Nextduedate!="EPO"
        &&checkedAgr[i].Nextduedate!="PIF"){
          Amount=Amount+Number(checkedAgr[i]?.Amount)
        }
      }
      //Suspense Eligibility Check 
      for(let i=0 ; i<paygridvaluesStore?.length;i++){
        if(paygridvaluesStore[i].otherIncomeType==''
        &&paygridvaluesStore[i].Agreementtype!="Club"
        &&paygridvaluesStore[i].Agreementtype!="Note Payment"
        &&paygridvaluesStore[i].Nextduedate!="SAC"
        &&paygridvaluesStore[i].Nextduedate!="EPO"
        &&paygridvaluesStore[i].Nextduedate!="PIF"){
          suspenseEligibleAgreementCount++
        }
      }
      
      if (
        parseFloat(change) >=
        parseFloat(
          String(
            Number(Amount)
          )
        )&&suspenseEligibleAgreementCount>0&& parseFloat(change) !=0
      ) {
        setsuspensehide(true);
      } else {
        setsuspensehide(false);
      }
 
    if (changeRadio === true) {
      setchangeRadio(true);
      setCreditRadio(false);
    } else {
      setchangeRadio(false);
      setCreditRadio(true);
    }

    if (CreditRadio === true) {
      setchangeRadio(false);
      setCreditRadio(true);
    } else {
      setchangeRadio(true);
      setCreditRadio(false);
    }
  }, [change]);

  let getpaymentpayload: GetAllPaymentInfoRequest = {
    customer_id: localStorage.getItem("customerId"),
    AgreementIdCustomerId: [
      {
        agreement_id: 773286343,
        customer_id: localStorage.getItem("customerId"),
      },
      {
        agreement_id: 7656757656,
        customer_id: localStorage.getItem("customerId"),
      },
    ],
    storeNumber: 3451,
    customerZipCode5: 34827,
    customerZip4: 7632673,
    customerState: "czechoslovakia",
    customerDOB: "07/05/2000",
  };

  /*
   ** changes made for -payment history service call
   ** changes made By - Muzzammil
   */
  const agrPaymentHistory = async (agreementOffSetParam: any) => {
    let customerId = customerparamid;
    let getpayhisstate1: any = [];
    getpayhisstate1 = await getpaymentHistory(customerId, "agr", agreementOffSetParam);
    settotalRentalRevenueAmount(getpayhisstate1?.value?.customerInfo?.totalRentalRevenue)

    return getpayhisstate1;
  };
  const recpaymentHistory = async (receiptOffSetParam: any) => {
    let customerId = customerparamid;
    let selectAllReceipts: any = [];
    selectAllReceipts = await getpaymentHistory(customerId, "recp", receiptOffSetParam);
    return selectAllReceipts;
  };
  const clubPaymentHistory = async (clubOffSetParam: any) => {
    let customerId = customerparamid;
    let getClubReceipts: any = [];
    getClubReceipts = await getpaymentHistory(customerId, "club", clubOffSetParam);
    return getClubReceipts;
  };

  const getClubWiseAllData = async () => {
    setscrollLoader(true);
    let clubPayData: any = [];
    await Promise.all([clubPaymentHistory(clubOffSet + 10)]).then(
      (resp) => {
        [clubPayData] = resp;
      }
    );

    // const clubActivityInfoLocal: ClubActivityInfo[] =
    //   clubPayData.value.clubActivityInfo;
    const ClubGridInfoRespLocal: ClubHistoryGridInfo[] =
      clubPayData.value.clubHistoryGridInfo;
    // let clubInfoobj: ClubInfo = clubPayData.value.clubInfo;
    if(ClubGridInfoRespLocal.length) {
      setClubGridInfoResp([...ClubGridInfoResp, ...ClubGridInfoRespLocal]); // Need to check this
      //setclubActivityInfo([...clubActivityInfo, ...clubActivityInfoLocal]);
      //setclubinformation(clubInfoobj); // Need to check this
      setClubOffSet(clubOffSet + 10);
    } else {
      sethasMoreClubRcp(false);
    }
  }

  const getReceiptWiseAllData = async () => {
    setscrollLoader(true);
    let selectAllReceipts: any = [];
    await Promise.all([recpaymentHistory(receiptOffSet + 10)]).then(
      (resp) => {
        [selectAllReceipts] = resp;
      }
    );
    let paymentHisGridLocal: any = [...paymentHisGrid];
    if(selectAllReceipts.value.customerPaymentHistory.length) {
      paymentHisGridLocal[0].push(...selectAllReceipts.value.customerPaymentHistory);
      setReceiptOffSet(receiptOffSet + 10)
    } else {
      sethasMoreReceipt(false);
    }
    console.log('paymentHisGridLocal in lop', paymentHisGridLocal,paymentHisGrid);
    
    setpaymentHisGrid(paymentHisGridLocal);
  }

  const getAgreementWiseAllData = async (indexObtained: any) => {
    setscrollLoader(true);
    const filterByAgreementNumber: any = agreementPayloadDecider.filter((el: any) => el.agreementNumber == paymentHisGrid[indexObtained][0].agreementNumber);
    console.log('filterByAgreementNumber psxkjcds', filterByAgreementNumber);
    
    if(filterByAgreementNumber[0].isFurtherDataThere) {
      let getpayhisstate1: any = [];
      await Promise.all([agrPaymentHistory(filterByAgreementNumber[0].offset + 10)]).then(
        (resp) => {
          [getpayhisstate1] = resp;
        }
      ); // Need to call with offset      
      let collectedUniqueAgrData: any = [];
      collectedUniqueAgrData =
        getpayhisstate1.value.paymentHistoryGridInfo.filter(
          (o2) => o2.agreementNumber === paymentHisGrid[indexObtained][0].agreementNumber
        );
      console.log('collectedUniqueAgrData cdv', collectedUniqueAgrData);
      
      
      if(collectedUniqueAgrData.length) {
        let paymentHisGridLocal: any = [...paymentHisGrid];
        paymentHisGridLocal[indexObtained].push(...collectedUniqueAgrData);
        setpaymentHisGrid(paymentHisGridLocal);
        const localAgrPayloadDecider: any = [...agreementPayloadDecider];
        localAgrPayloadDecider.map((el: any) => {
          if(el.agreementNumber == paymentHisGrid[indexObtained][0].agreementNumber) {
            el.offset = el.offset + 10;
          }
        })
        setAgreementPayloadDecider(localAgrPayloadDecider);
      } else {
        const localAgrPayloadDecider: any = [...agreementPayloadDecider];
        localAgrPayloadDecider.map((el: any) => {
          if(el.agreementNumber == paymentHisGrid[indexObtained][0].agreementNumber) {
            el.isFurtherDataThere = false;
          }
        })
        setAgreementPayloadDecider(localAgrPayloadDecider);
      }
    }
    else{
      setscrollLoader(false);
    }
  }

  const getpayHis = async () => {
    let getpayhisstate1: any = [];
    let selectAllReceipts: any = [];
    let clubPayData: any = [];
    await Promise.all([agrPaymentHistory(0), recpaymentHistory(0), clubPaymentHistory(0)]).then(
      (resp) => {
        [getpayhisstate1, selectAllReceipts, clubPayData] = resp;
      }
    );

    const uniqueAgreementID1 = [
      ...new Set(
        getpayhisstate1.value.paymentHistoryGridInfo.map(
          (data) => data.agreementNumber
        )
      ),
    ];
    const uniqueAgrID = [
      ...new Set(
        getpayhisstate1.value.paymentHistoryGridInfo.map(
          (data) => data.agreementId
        )
      ),
    ]; // To assign ID only for Agr
    const clubActivityInfo: ClubActivityInfo[] =
      clubPayData.value.clubActivityInfo; // Only for club
    const ClubGridInfoResp: ClubHistoryGridInfo[] =
      clubPayData.value.clubHistoryGridInfo; // Only for club
    let customerInfoobj1: CustomerInfoHis = getpayhisstate1.value.customerInfo; // Customer information
    let clubInfoobj: ClubInfo = clubPayData.value.clubInfo; // Only for club
    if (uniqueAgreementID1.length == 0) {
      uniqueAgreementID.push({
        label: "No record",
        value: "123456",
      });
    } else {
      uniqueAgreementID.push({
        label: "All",
        value: "0",
      });
      let indexcount = 1;
      uniqueAgreementID1.map((o1: any, index) => {
        uniqueAgreementID.push({
          label: o1.toString(),
          value: indexcount.toString(),
          ID: uniqueAgrID[index],
        });
        indexcount++;
      });
    } // Need only once
    setClubGridInfoResp([...ClubGridInfoResp]); // Only for club
    setclubActivityInfo([...clubActivityInfo]); // Only for club
    setuniqueAgreementID(uniqueAgreementID); // List of agreement in drop down
    setcommoninformation(customerInfoobj1); // Customer information
    setclubinformation(clubInfoobj); // Only for club
    let paymentHisGrid: any = [[]];
    uniqueAgreementID1.map((o1, index) => {
      paymentHisGrid[index] =
        getpayhisstate1.value.paymentHistoryGridInfo.filter(
          (o2) => o2.agreementNumber === o1
        );
    }); // Forms the array of arrays which gets seperated by agreements
    setAgreementRentPaid(getpayhisstate1.value.paymentHistoryGridInfo)
    paymentHisGrid.unshift(selectAllReceipts.value.customerPaymentHistory); // Appends all the receipts in the 0th position
    console.log('paymentHisGrid plops', paymentHisGrid);
    
    setpaymentHisGrid(paymentHisGrid); // All the receipt and agreement specific array are assigned - final data

    const constructAgreementPayloadDecider: any = [];

    uniqueAgreementID1.forEach((el) => {
      constructAgreementPayloadDecider.push({
        agreementNumber: el,
        offset: 0,
        isFurtherDataThere: true
      })
    });
    setAgreementPayloadDecider(constructAgreementPayloadDecider)

    if (paymentHisGrid[0].length > 0) {
      setpayhisflag(false); // Responsible for Button Click
    } // Need once
    if (ClubGridInfoResp.length > 0) {
      setpayclubflag(false); // Responsible for View Club Payment History Button disable state
    } // Need once

    if (paymentHisGrid[0].length == 0 && ClubGridInfoResp.length > 0) {
      setpayhisflag(false);
      setpayclubflag(false);
    } // Need once
  };

  let cashamountarray: any;
  cashamountarray = [];

  const getCustomerAddressInformation = async () => {
    let addressResponse: any;
    let primaryAddressArr: any = [];
    let primaryAddress: any = [];
    const addressID: any = [];
    const customer_id: any = customerId;
    addressResponse = await GetCustomerAddress(customer_id, "Y");
    setCustomerAddressActive(addressResponse);
    primaryAddress =
      addressResponse?.data?.GetCustomerAddress?.value?.addresses?.filter(
        (x) => x.addressType == "PRIM"
      );
    if (primaryAddress !== undefined && primaryAddress?.length > 0) {
      for (let i = 0; i < primaryAddress?.length; i++) {
        let multipleAggrementIdArr: any = [];
        const splitStoreNumberArr: any = [];
        let multipleAggrementId: any = "";
        let storeNumberMultiple: any = "";
        let multipleAgreementNumber: any = "";
        const splitarr: any = [];

        if (
          primaryAddress[i]?.addressId != null &&
          primaryAddress[i]?.addressId != ""
        ) {
          const filteredAddress1 = primaryAddress?.filter(
            (val: any) =>
              val?.addressType == "PRIM" &&
              val.addressId == primaryAddress[i]?.addressId
            // && val.storeNumber==primaryAddress[i]?.storeNumber
          );
          if (
            filteredAddress1.length != 0 &&
            !addressID?.includes(primaryAddress[i]?.addressId)
          ) {
            addressID.push(primaryAddress[i]?.addressId);

            for (let j = 0; j < filteredAddress1?.length; j++) {
              if (
                filteredAddress1[j].agreementId != null &&
                filteredAddress1[j].agreementId != ""
              ) {
                multipleAggrementId =
                  multipleAggrementId == ""
                    ? filteredAddress1[j].agreementId
                    : multipleAggrementId +
                      ", " +
                      filteredAddress1[j].agreementId;
                multipleAggrementIdArr.push(filteredAddress1[j]?.agreementId);
              }
              if (
                filteredAddress1[j].storeNumber != null &&
                filteredAddress1[j].storeNumber != "" &&
                !splitStoreNumberArr.includes(filteredAddress1[j].storeNumber)
              ) {
                storeNumberMultiple =
                  storeNumberMultiple == ""
                    ? filteredAddress1[j].storeNumber
                    : storeNumberMultiple +
                      ", " +
                      filteredAddress1[j].storeNumber;
                splitStoreNumberArr.push(filteredAddress1[j].storeNumber);
              }
              if (
                filteredAddress1[j].agreementNumber != null &&
                filteredAddress1[j].agreementNumber != ""
              ) {
                multipleAgreementNumber =
                  multipleAgreementNumber == ""
                    ? filteredAddress1[j].agreementNumber
                    : multipleAgreementNumber +
                      ", " +
                      filteredAddress1[j].agreementNumber;
                splitarr.push(filteredAddress1[j].agreementNumber);
              }
            }

            primaryAddressArr.push({
              agreementNumbersCount: filteredAddress1?.length,
              agreementNumber: multipleAgreementNumber,
              addressLine1: filteredAddress1[0]?.addressLine1,
              addressLine2: filteredAddress1[0]?.addressLine2,
              addressTypeDesc: filteredAddress1[0]?.addressTypeDesc,
              storeNumberMultiple: storeNumberMultiple,
              storeNumberArr: splitStoreNumberArr,
              agreementDescription: filteredAddress1[0]?.agreementDescription,
              agreementStatus: filteredAddress1[0]?.agreementStatus,
              addressId: filteredAddress1[0]?.addressId,
              city: filteredAddress1[0]?.city,
              state: filteredAddress1[0]?.state,
              active: filteredAddress1[0]?.active,
              stateName: filteredAddress1[0]?.stateName,
              postalCode: filteredAddress1[0]?.postalCode,
              storeNumber: filteredAddress1[0]?.storeNumber,
              agreementId: multipleAggrementId,
              agreementIdArr: multipleAggrementIdArr,
              addressID: addressID,
              latitude: null,
              longitude: null,
              createdBy: filteredAddress1[0]?.createdBy,
              lastModifiedBy: filteredAddress1[0]?.lastModifiedBy,
              lastModifiedDate: filteredAddress1[0]?.lastModifiedDate,
              verifiedDate: filteredAddress1[0]?.verifiedDate,
              createdSource: filteredAddress1[0]?.createdSource,
              lastModifiedSource: filteredAddress1[0]?.lastModifiedSource,
              plus4: filteredAddress1[0]?.plus4,
            });
          }
        }
      }
      setPrimaryAddArr(primaryAddressArr);
    }
  };

  const getCustomerInformation = async () => {
    let result: any = undefined;
    let customerPrimaryAddress: any = "";
    let customerSecondaryAddress: any = "";
    let customerCity = "";
    let customerdob = "";
    let customerPhoneNumber = "";
    let customerEmailAdress = "";
    let customerFname = "";
    let customerLname = "";
    let customerPlusFour = "";
    let customerZip = "";
    let customerState = "";

    const customer_id: any = customerId;
    const Storenumber: any = String(
      window.sessionStorage.getItem("storeNumber")
    );

    result = await GetCustomerByIdInfo(customer_id, Storenumber);
    setcustomerdata(result);
    if (result != undefined && result != "") {
      // debugger;
      result = result.data;
      if (result.GetCustomer.value.addresses != undefined) {
        setCustomerInformation(result.GetCustomer.value);
        let customerAdressFilterArray: any =
          result.GetCustomer.value.addresses?.filter(
            (x) => x.addressType == "PRIM"
          );
        if (
          customerAdressFilterArray?.length == 0 &&
          result.GetCustomer.value.addresses?.length > 0
        ) {
          customerAdressFilterArray = [result.GetCustomer.value.addresses[0]];
        }
        customerPrimaryAddress =
          customerAdressFilterArray?.length > 0
            ? customerAdressFilterArray[0].addressLine1
            : "";
        customerSecondaryAddress =
          customerAdressFilterArray?.length > 0
            ? customerAdressFilterArray[0].addressLine2
            : "";
        customerPlusFour =
          customerAdressFilterArray?.length > 0
            ? customerAdressFilterArray[0].postalCode?.split("-")[1]
            : "";
        customerZip =
          customerAdressFilterArray?.length > 0
            ? customerAdressFilterArray[0].postalCode?.split("-")[0]
            : "";
        customerState =
          customerAdressFilterArray?.length > 0
            ? customerAdressFilterArray[0].state
            : "";
        customerCity =
          customerAdressFilterArray?.length > 0
            ? customerAdressFilterArray[0].city
            : "";
      }

      if (result?.GetApproval?.value?.customerApprovalDetails?.verificationStatus == "PENDING") {
        setunverifiedCustomer(true);
      }

      customerdob = result.GetCustomer.value.dateOfBirth;
      let customerPhoneDetails: any = result.GetCustomer.value.phones?.filter(
        (x) => {
          x.primary == "Y";
        }
      );
      if (
        customerPhoneDetails?.length == 0 &&
        result.GetCustomer.value?.phones?.length > 0
      ) {
        customerPhoneDetails = [result.GetCustomer.value.phones[0]];
      } else {
        customerPhoneDetails = [];
      }
      customerPhoneNumber =
        customerPhoneDetails?.length > 0
          ? customerPhoneDetails[0].phoneNumber
          : "";
      customerEmailAdress = result.GetCustomer.value.emailAddress;
      customerFname = result.GetCustomer.value.firstName;
      customerLname = result.GetCustomer.value.lastName;
      //chargeCard and NewCard Details pre-fill
      setCustomerInfopreFill({
        ...customerInfopreFill,
        customerPrimaryAddress: customerPrimaryAddress,
        customerSecondaryAddress: customerSecondaryAddress,
        customerZip: customerZip,
        customerCity: customerCity,
        customerState: customerState,
        name: customerFname + " " + customerLname,
      });
    }
  };

  const updateCappedFreeTimeResponseValues = (key: any, value: any, setFunction: any) => {
    setFunction(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const getpaid = async (origin?, resetInvoke?: any,paymentDetails?:any) => {
    debugger;
    try{
    swipeCardDetails = [];
    let recordsFound = 0;
    tendertypeglobalarray = [];
    revenueRecogConfigFlag=0;
    let calculationModuleRes = calcModuleResponse;

    let lateFeeTaxRate: any = lateFeeTaxRateCus;
    if (resetInvoke == undefined) {
      totalduereceiptidsupport = [];
    }
    addrowarray = [];
    agrAmtTaxArray = [];
    showAutoPayPopup = false;
    Clubagreementinsertion = [];
    basicPayGridValues = paygridvalues;
    let storeInfo: any = [];
    setpaygridvalues([]);
    paygridvalues = [];
    let getpaymentstate;
    let configConvFeeWithOutTax = 0;
    let configConvFeeTax = 0;
    let configPermissions = {
      commonModuleFlag: 0,
      isAdjustDueDateFeatureFlag: 0,
      isEditScheduleFeatureFlag: 0,
      isCarryRentFeatureFlag: 0,
      isAcceptEpoFeatureFlag: 0,
      isPaymentHistoryFeatureFlag: 0,
      isCashSaleFeatureFlag: 0,
      isPaymentTraingleFeatureFlag: 0,
      isAddApplyTransferSuspenseFeatureFlag: 0,
      isPaymentFunctionsFeatureFlag: 0,
      isActivateClub: 0,
      isRegularFreeTimeAllowed: 0, // Added by me
      isServiceFreeTimeAllowed: 0, // Added by me
      isRegExtFreeTimeAllowed: 0, // Added by me
      isPromoFreeTimeallowed: 0, // Added by me
      raftApiFlag: 0,
      carryWaveLateFee: 0,
      optimizedPayment: 0,
      RevenueRecognition: 0,
      isRentalItemSaleFlag: 0,
      isBackRentFlag: 0,
      isChargeOffSkipStolen: 0,
      isNSFPaymentsFlag: 0,
      isCCCBPaymentsFlag: 0,
      isRemoveAmountDue: 0,
      isCloudSwipeCustomerCard: 0,
      isCloudStoreSwipeDevicelist: 0,
      allowRefundSecurityDeposit: 0,
      isXboxMembershipAllowed: 0,
      isXboxGiftCardAllowed: 0,
      isOtherSaleAllowed: 0,
      isAcimaRecoveryAllowed: 0,
      isPaymentDonation: 0,
      isRacDbWebLead: 0,
      isFinalPaymentTransferSuspense:0,
      ManageCards : 0,
      rewardsEligibilityAmount: 0,
      customerRewards: 0,
      isTxt2PayCardOnFile: 0,
      swipeCustomerCard:0
    };
    let cappedFreeTimePercentage = 0;
    let enableCappedFreeTime = false;
    if (defaultPaygridvalues == undefined) {
      let paymentFunctionFlagValue = 0;
      if (Number(paymentFunctionFlag.payFunc) == 1) {
        const bodyobject = {
          storeNumbers: [String(window.sessionStorage.getItem("storeNumber"))],
          paramKeyNames: [
            "PaymentHistory",
            "AcceptEPO",
            "CarryRent",
            "PaymentFunctions",
            "EditSchedule",
            "AdjustDue",
            "CashSale",
            "PaymentFee",
            "PaymentTriangle",
            "AddApplyTransferSuspense",
            "ActivateClub",
            "RegularFreeTime", // Added by me
            "ServiceFreeTime", // Added by me
            "RetentionExtensionFreeTime", // Added by me
            "PromoFreeTime", // Added by me
            "RaftApiFlag",
            "CarryWaveLateFee",
            "OptimizedPayment",
            "RevenueRecognition",
            "CloudSwipeCustomerCard",
            "CloudStoreSwipeDevicelist",
            "RacDbWebLead",
            "PaymentSearchCustomerRetention",
            "CustomerPaymentPageCustomerRetention",
            "ManageCards"
          ],
        };
        const CoworkerNum = props.throwConfigValues;

        if (
          CoworkerNum !== undefined ||
          (null &&
            CoworkerNum.storeProfileResponse !== undefined &&
            CoworkerNum.storeProfileResponse &&
            CoworkerNum.storeProfileResponse.configDetails !== undefined &&
            CoworkerNum.storeProfileResponse.configDetails.length > 0 &&
            CoworkerNum.storeProfileResponse.configDetails[0].configDetails !==
              undefined &&
            CoworkerNum.storeProfileResponse.configDetails[0].configDetails
              .length > 0)
        ) {
          for (
            let i = 0;
            i <
            CoworkerNum.storeProfileResponse.configDetails[0].configDetails
              .length;
            i++
          ) {
            const configArray =
              CoworkerNum.storeProfileResponse.configDetails[0].configDetails;
            if (configArray[i].paramKeyName == "CashSale") {
              configPermissions.isCashSaleFeatureFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "PaymentHistory") {
              configPermissions.isPaymentHistoryFeatureFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "AcceptEPO") {
              configPermissions.isAcceptEpoFeatureFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "CarryRent") {
              configPermissions.isCarryRentFeatureFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "EditSchedule") {
              configPermissions.isEditScheduleFeatureFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "AdjustDue") {
              configPermissions.isAdjustDueDateFeatureFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "PaymentTriangle") {
              configPermissions.isPaymentTraingleFeatureFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "ActivateClub") {
              configPermissions.isActivateClub = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "AddApplyTransferSuspense") {
              configPermissions.isAddApplyTransferSuspenseFeatureFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "PaymentFunctions") {
              configPermissions.isPaymentFunctionsFeatureFlag = Number(
                configArray[i].paramValue
              );
              paymentFunctionFlagValue = Number(configArray[i].paramValue);
            }
            if (configArray[i].paramKeyName == "RegularFreeTime") {
              configPermissions.isRegularFreeTimeAllowed = Number(
                configArray[i].paramValue
              );
            } // Added by me
            if (configArray[i].paramKeyName == "ServiceFreeTime") {
              configPermissions.isServiceFreeTimeAllowed = Number(
                configArray[i].paramValue
              );
            } // Added by me
            if (configArray[i].paramKeyName == "RetentionExtensionFreeTime") {
              configPermissions.isRegExtFreeTimeAllowed = Number(
                configArray[i].paramValue
              );
            } // Added by me
            if (configArray[i].paramKeyName == "BackRent") {
              configPermissions.isBackRentFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "ChargeOffSkipStolen") {
              configPermissions.isChargeOffSkipStolen = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "RentalItemSale") {
              configPermissions.isRentalItemSaleFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "NSFPayments") {
              configPermissions.isNSFPaymentsFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "CCCBPayments") {
              configPermissions.isCCCBPaymentsFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "PromoFreeTime") {
              configPermissions.isPromoFreeTimeallowed = Number(
                configArray[i].paramValue
              );
            } // Added by me
            if (configArray[i].paramKeyName == "PaymentFee") {
              configConvFeeWithOutTax = Number(configArray[i].paramValue);
              setConvFeeWithOutTax(configArray[i].paramValue);
            }
            if (configArray[i].paramKeyName == "RaftApiFlag") {
              configPermissions.raftApiFlag = Number(configArray[i].paramValue);
            }
            if (configArray[i].paramKeyName == "CarryWaveLateFee") {
              configPermissions.carryWaveLateFee = Number(
                configArray[i].paramValue
              );
            }

            if (configArray[i].paramKeyName == "OptimizedPayment") {
              configPermissions.optimizedPayment = Number(
                configArray[i].paramValue
              );
            }

            if (configArray[i].paramKeyName == "CommonModulePaymentFlag") {
              configPermissions.commonModuleFlag = Number(
                configArray[i].paramValue
              );
            }

            if (configArray[i].paramKeyName == "RevenueRecognition") {
              configPermissions.RevenueRecognition = Number(
                configArray[i].paramValue
              );
              isRevenueRecogActivated =
                Number(configArray[i].paramValue) == 1 ? true : false;
            }
            if (configArray[i].paramKeyName == "RemoveAmountDue") {
              configPermissions.isRemoveAmountDue = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "CloudSwipeCustomerCard") {
              configPermissions.isCloudSwipeCustomerCard = Number(
                configArray[i].paramValue
              );
              // setIsCloudSwipeCustomerCard(Number(configArray[i].paramValue));
            }
            if (configArray[i].paramKeyName == "CloudStoreSwipeDevicelist") {
              configPermissions.isCloudStoreSwipeDevicelist = Number(
                configArray[i].paramValue
              );
              // setIsCloudStoreSwipeDevicelist(Number(configArray[i].paramValue));
            }
            if (configArray[i].paramKeyName == "RefundSecurityDeposit") {
              configPermissions.allowRefundSecurityDeposit = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "CustomerPaymentPageCustomerRetention") {
              setIsRetentionEnabled(
                Number(configArray[i].paramValue) > 0 ? true : false
              );
            }

            if (configArray[i].paramKeyName == "AgreementExchange") {
              setExchangeEnabled(
                Number(configArray[i].paramValue) > 0 ? true : false
              );
            }
            if (configArray[i].paramKeyName == "AgreementExchangePeriod") {
              setExchangePeriod(
                configArray[i].paramValue ? configArray[i].paramValue : ''
              );
            }
            if (configArray[i].paramKeyName == "AgreementExchangeReturnPeriod") { 
              setReturnPeriod(
                configArray[i].paramValue ? configArray[i].paramValue : ''
              );
            }

            if (configArray[i].paramKeyName == "XboxMembership") {
              configPermissions.isXboxMembershipAllowed = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "XboxGiftCard") {
              configPermissions.isXboxGiftCardAllowed = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "OtherSale") {
              configPermissions.isOtherSaleAllowed = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "AcimaRecovery") {
              configPermissions.isAcimaRecoveryAllowed = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "PaymentDonation") {
              configPermissions.isPaymentDonation = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "RacDbWebLead") {
              configPermissions.isRacDbWebLead = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "FinalPaymentTransferSuspense") {
              configPermissions.isFinalPaymentTransferSuspense = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "ManageCards"){
              configPermissions.ManageCards = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "CappedFreeTimePromo") {
              updateCappedFreeTimeResponseValues(
                'cappedFreeTimePromoFlag', Number(configArray[i].paramValue) > 0 ? true : false,setCappedFreeTimeValues
              )
            }
            if (configArray[i].paramKeyName == "CappedFreeTimeInitPayment") {
              updateCappedFreeTimeResponseValues(
                'cappedFreeTimeInitPaymentFlag', Number(configArray[i].paramValue) > 0 ? true : false,setCappedFreeTimeValues
              )
            }
            if (configArray[i].paramKeyName == "CappedFreeTimeRetention") {
              updateCappedFreeTimeResponseValues(
                'cappedFreeTimeRetentionFlag', Number(configArray[i].paramValue) > 0 ? true : false,setCappedFreeTimeValues
              )
            }
            if (configArray[i].paramKeyName == "CappedFreeTimeService") {
              updateCappedFreeTimeResponseValues(
                'cappedFreeTimeServiceFlag', Number(configArray[i].paramValue) > 0 ? true : false,setCappedFreeTimeValues
              )
            }
            if (configArray[i].paramKeyName == "CappedFreeTimeRegular") {
              updateCappedFreeTimeResponseValues(
                'cappedFreeTimeRegularFlag', Number(configArray[i].paramValue) > 0 ? true : false,setCappedFreeTimeValues
              )
            }
            if (configArray[i].paramKeyName == "CappedFreeTimePercentage") {
              updateCappedFreeTimeResponseValues(
                'cappedFreeTimePercentage', Number(
                  configArray[i].paramValue), setCappedFreeTimeValues
                )   
              cappedFreeTimePercentage = Number(
                configArray[i].paramValue);
            }
            if (configArray[i].paramKeyName == "CappedFreeTimeEnabled") {
              updateCappedFreeTimeResponseValues(
                'enableCappedFreeTime', Number(
                  configArray[i].paramValue) > 0 ? true : false, setCappedFreeTimeValues
                )  
              enableCappedFreeTime =  Number(configArray[i].paramValue) > 0 ? true : false;
            }
            if (configArray[i].paramKeyName == "RACExchange") {
              setExchangeFuncEnabled(Number(configArray[i].paramValue) == 1 ? true : false);
            }
            if (configArray[i].paramKeyName == "RewardsEligibilityAmount") {
              configPermissions.rewardsEligibilityAmount = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "Txt2PayCardOnFile") {
              configPermissions.isTxt2PayCardOnFile = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "CustomerRewards") {
              configPermissions.customerRewards = Number(
                configArray[i].paramValue
              );
            }
          }
        }
      } else {
        paymentFunctionFlagValue = 0;
        configPermissions = configFlags;
        configPermissions.isCashSaleFeatureFlag = Number(
          paymentFunctionFlag.isCashSale
        );
        configConvFeeWithOutTax = Number(paymentFunctionFlag.convFeeWithOutTax);
        setConvFeeWithOutTax(paymentFunctionFlag.convFeeWithOutTax);
      }
      configFlags=configPermissions
      setconfigFlags(configFlags);
      if (enableCappedFreeTime) {
        const payload = {
          agreementNumbers: null,
          percentage: cappedFreeTimePercentage.toString(),
          storeNumber: sessionStorage.getItem('storeNumber')
        };
        const cappedFreeTimeResponse = await getRetentionOffers(payload);
        console.log(cappedFreeTimeResponse, 'cappedFreeTimeResponse');
        if (cappedFreeTimeResponse?.status == 200 && cappedFreeTimeResponse?.data) {
          const monthlyRates = cappedFreeTimeResponse?.data?.monthSummary;
          const SMRRAmount = cappedFreeTimeResponse?.data?.smrPercentageAmount;
          updateCappedFreeTimeResponseValues('cappedFreeTimeSMRRAmount', SMRRAmount ? parseFloat(SMRRAmount) : 0, setCappedFreeTimeResponseValues);//Assign the smrPercentageAmount key
          updateCappedFreeTimeResponseValues('cappedFreeTimeRegular', monthlyRates?.ftOtherTotalAmount ? parseFloat(monthlyRates?.ftOtherTotalAmount) : 0, setCappedFreeTimeResponseValues);//Assign the ftOtherTotalAmount key	
          updateCappedFreeTimeResponseValues('cappedFreeTimeService', monthlyRates?.ftServiceTotalAmount ? parseFloat(monthlyRates?.ftServiceTotalAmount) : 0, setCappedFreeTimeResponseValues);//Assign the ftServiceTotalAmount key
          updateCappedFreeTimeResponseValues('cappedFreeTimeRetention', monthlyRates?.ftReiTotalAmount ? parseFloat(monthlyRates?.ftReiTotalAmount) : 0, setCappedFreeTimeResponseValues);//Assign the ftReiTotalAmount key
          updateCappedFreeTimeResponseValues('cappedFreeTimeInitPayment', monthlyRates?.ftDelTotalAmount ? parseFloat(monthlyRates?.ftDelTotalAmount) : 0, setCappedFreeTimeResponseValues);//Assign the ftDelTotalAmount key
          updateCappedFreeTimeResponseValues('cappedFreeTimePromo', monthlyRates?.ftPromoTotalAmount ? parseFloat(monthlyRates?.ftPromoTotalAmount) : 0, setCappedFreeTimeResponseValues);//Assign the ftPromoTotalAmount key
        }
        else {
          updateCappedFreeTimeResponseValues('cappedFreeTimeSMRRAmount', 0, setCappedFreeTimeResponseValues);//Assign the smrPercentageAmount key
          updateCappedFreeTimeResponseValues('cappedFreeTimeRegular', 0, setCappedFreeTimeResponseValues);//Assign the ftOtherTotalAmount key	
          updateCappedFreeTimeResponseValues('cappedFreeTimeService', 0, setCappedFreeTimeResponseValues);//Assign the ftServiceTotalAmount key
          updateCappedFreeTimeResponseValues('cappedFreeTimeRetention', 0, setCappedFreeTimeResponseValues);//Assign the ftReiTotalAmount key
          updateCappedFreeTimeResponseValues('cappedFreeTimeInitPayment', 0, setCappedFreeTimeResponseValues);//Assign the ftDelTotalAmount key
          updateCappedFreeTimeResponseValues('cappedFreeTimePromo', 0, setCappedFreeTimeResponseValues);
        }
      }
      let customerPrimaryAddress: any = "";
      let customerSecondaryAddress: any = "";
      let customerCity = "";
      let customerdob = "";
      let customerPhoneNumber = "";
      let customerEmailAdress = "";
      let customerFname = "";
      let customerLname = "";
      let customerPlusFour = "";
      let customerZip = "";
      let customerState = "";
      if (paymentFunctionFlagValue == 1) {
        getpaymentstate = paymentDetails
        if (configPermissions.commonModuleFlag == 1) {
          calculationModuleRes = getpaymentstate.commonModuleRes;
          setCalcModuleResponse(calculationModuleRes);
          if(calculationModuleRes){
            let convFee = calculationModuleRes?.customerFees?.filter(
              (x) => x.itemCategory == "CONVENFEE"
            );
            if(convFee?.length>0){
              configConvFeeWithOutTax = Number(convFee[0].amount);
              setConvFeeWithOutTax(convFee[0].amount);
            }

          }
          
        }
        // setmainGridloader(false);
        // setPayGridPayloadLoad(true)
      } else {
        const cusDob = moment(customerdob).format("MM/DD/YYYY");
        getpaymentstate = {
          paymentInfo: {
            paymentInfo: [],
            paymentOrigin: [],
            clubInformation: [],
            deliveryInformation: [],
            numberOfPaymentsMadeInfo: [],
            remainingCredits: [],
            documentSignStatus: [],
            paymentHistoryInfo: [],
            customerInfo: [
              {
                addressLine1: customerPrimaryAddress,
                addressLine2: customerSecondaryAddress,
                city: customerCity,
                dob: cusDob,
                emailAddress: customerEmailAdress,
                entPartyId: null,
                firstName: customerFname,
                lastName: customerLname,
                phoneNumber: customerPhoneNumber,
                plus4: customerPlusFour,
                salesLead: null,
                state: customerState,
                totalPaymentsMade: 0,
                verified: "Y",
                verifiedDate: null,
                zip: customerZip,
              },
            ],
            promoFreeDays: [],
            clubDaysLateAndPaymentSchedule: [],
            clubPromoInfo: [],
            clubInfoResponse: [],
            lastLate10GraphInfo: [],
            cashSaleInfo: null,
          },
          amountDue: { amountDueResponse: [] },
          taxTotal: [],
          paymentScheule: [],
          balance: [],
        };
      }

      let agreementDetails: any;
      agreementDetails = getpaymentstate?.amountDue?.amountDueResponse?.filter(
        (x) => x.agreementType != "Club"
      );
      if (
        getpaymentstate?.paymentInfo?.customerInfo[0]?.globalCustomerId !=
          null &&
        getpaymentstate?.paymentInfo?.customerInfo[0]?.globalCustomerId != "" &&
        getpaymentstate?.paymentInfo?.customerInfo[0]?.globalCustomerId !=
          undefined
      ) {
        setCustomerGCID(
          getpaymentstate?.paymentInfo?.customerInfo[0]?.globalCustomerId
        );
      }
      agreementAddressDetails = agreementDetails;
      setAgreementAddressDetails(agreementAddressDetails);
      let convFeeTaxSet = false;
      let lateFeeTaxApplicable = true;

      let agreementDetailsConvFee = agreementDetails?.filter(
        (x) =>
          String(x.storeNumber) ==
          String(window.sessionStorage.getItem("storeNumber"))
      );

      if (
        !Array.isArray(agreementDetailsConvFee) ||
        agreementDetailsConvFee?.length === 0
      ) {
        setConvFeeWithOutTax(configConvFeeWithOutTax); // Return default value configConvFeeWithOutTax if the array is undefined or not an array
      }

      let leastConvFee: any = 0; // Initialize the variable with a high value

      for (let i = 0; i < agreementDetailsConvFee?.length; i++) {
        const transaction = agreementDetailsConvFee[i];
        if (
          transaction.convFee !== undefined &&
          (typeof transaction.convFee === "number" ||
            (typeof transaction.convFee === "string" &&
              transaction.convFee !== ""))
        ) {
          const currentConvFee = parseFloat(transaction.convFee);
          if (i == 0) {
            leastConvFee = currentConvFee;
          }
          if (!isNaN(currentConvFee) && currentConvFee <= leastConvFee) {
            leastConvFee = currentConvFee;
            convFeeTaxSet = true;
            configConvFeeWithOutTax = Number(leastConvFee);
            configConvFeeTax = Number(transaction.convFeeTax);
            setConvFeeWithOutTax(Number(leastConvFee));
            setconfigFeeOnlyTax(Number(transaction.convFeeTax));
          }
        }
        // if ((transaction.lFeeTaxAmount == undefined || transaction.lFeeTaxAmount == 0 || transaction.lFeeTaxAmount == null)  && transaction.lateFee != undefined && transaction.lateFee > 0){
        //   lateFeeTaxApplicable = false;
        // }
      }

      // if (leastConvFee === Infinity) {
      //   setConvFeeWithOutTax(configConvFeeWithOutTax);; // Return default value configConvFeeWithOutTax if there are no valid convFee values
      // }else{
      //   configConvFeeWithOutTax=Number(leastConvFee)
      //   setConvFeeWithOutTax(Number(leastConvFee))
      // }

      if (configPermissions.isRacDbWebLead == 1) {
        getpaymentstate.balance =
          getpaymentstate.paymentInfo.remainingCredits.filter(
            (x) =>
              String(x.storeNumber) ==
                String(window.sessionStorage.getItem("storeNumber")) &&
              x.bucket == "WL Deposit"
          );
        
        console.log('Inside the RACDBWebLead Flow', getpaymentstate?.balance);

        for (let i = 0; i < getpaymentstate.balance?.length; i++) {
          getpaymentstate.balance[i].balanceAmount =
            getpaymentstate.balance[i].accountBalance;
          getpaymentstate.balance[i].balanceType = "WEBLEAD";
        }

        if (getpaymentstate.balance?.length > 1) {
          let balanceSumAmount = 0;
          for (let i = 0; i < getpaymentstate.balance?.length; i++) {
            balanceSumAmount =
              balanceSumAmount +
              Number(getpaymentstate.balance[i].balanceAmount);
          }
          getpaymentstate.balance = [
            {
              agreementId: getpaymentstate.balance[0].agreementId,
              balanceAmount: Number(Number(balanceSumAmount).toFixed(2)),
              balanceType: getpaymentstate.balance[0].balanceType,
              storeNumber: getpaymentstate.balance[0].storeNumber,
            },
          ];
        }
      } else {

      getpaymentstate.balance = getpaymentstate.balance.filter(
        (x) =>
          String(x.storeNumber) ==
            String(window.sessionStorage.getItem("storeNumber")) &&
          x.balanceType == "WEBLEAD"
      );
      if (getpaymentstate.balance?.length > 1) {
        let balanceSumAmount = 0;
        for (let i = 0; i < getpaymentstate.balance?.length; i++) {
          balanceSumAmount =
            balanceSumAmount + Number(getpaymentstate.balance[i].balanceAmount);
        }
        getpaymentstate.balance = [
          {
            agreementId: getpaymentstate.balance[0].agreementId,
            balanceAmount: Number(Number(balanceSumAmount).toFixed(2)),
            balanceType: getpaymentstate.balance[0].balanceType,
            balanceTypeDesc: getpaymentstate.balance[0].balanceTypeDesc,
            entPartyAccountId: getpaymentstate.balance[0].entPartyAccountId,
            partyId: getpaymentstate.balance[0].partyId,
            promoFreeTime: getpaymentstate.balance[0].promoFreeTime,
            racdbCustomerId: getpaymentstate.balance[0].racdbCustomerId,
            racdbCustometAccountId:
              getpaymentstate.balance[0].racdbCustometAccountId,
            storeNumber: getpaymentstate.balance[0].storeNumber,
          },
        ];
      }

    }
      getpaymentstate.paymentInfo.remainingCredits =
        getpaymentstate.paymentInfo.remainingCredits.filter(
          (x) =>
            String(x.storeNumber) ==
            String(window.sessionStorage.getItem("storeNumber"))
        );
      let objChartData = getpaymentstate.paymentInfo.lastLate10GraphInfo;
      setchartdata(objChartData);
      //phone conv Fee
      // if (Number(configConvFeeWithOutTax) != 0) {
      let zip5 = "";
      let zip4 = "0000";
      let taxExemptAsOfToday = "";
      let postalZip =
        getpaymentstate?.paymentInfo?.customerInfo[0]?.zip == null
          ? null
          : getpaymentstate?.paymentInfo?.customerInfo[0]?.zip?.replace(
              /-/g,
              ""
            );
      let JsonsessionStoreDetails: any =
        window.sessionStorage.getItem("storeDetails");
      let sessionStoreDetails: any;
      try {
        sessionStoreDetails = JSON.parse(atob(JsonsessionStoreDetails));
      } catch (e: any) {
      }
      /** 
       *  If the customer has incorrect or zip code of length not equal to 5 then navigated to the
            customer page by displaying the popup
      */
            let postalCodeZip5=  getpaymentstate?.paymentInfo?.customerInfo[0]?.zip == null
            ? null
            : getpaymentstate?.paymentInfo?.customerInfo[0]?.zip?.split("-")[0]
            let phoneNumberPrimary= getpaymentstate?.paymentInfo?.customerInfo[0]?.phoneNumber  ==null||""?""
            :getpaymentstate?.paymentInfo?.customerInfo[0]?.phoneNumber.toString()
            
            if (!isStringWithDigits(5, postalCodeZip5)) {
              setPostalCodeZip5ErrorFlag(1)
            } 
            else if(phoneNumberPrimary?.length!=10)
            {
              setPostalCodeZip5ErrorFlag(2)
            }

      if (!isStringWithDigits(5, postalZip)) {
        zip5 = sessionStoreDetails?.zip?.split("-")[0];
        zip4 = sessionStoreDetails?.zip?.split("-")[1];
        if (!isStringWithDigits(4, zip4)) {
          zip4 = "0000";
        }
      } else {
        zip5 = getpaymentstate.paymentInfo?.customerInfo[0]?.zip;
        if (getpaymentstate.paymentInfo?.customerInfo[0]?.plus4?.length == 4) {
          zip4 = getpaymentstate.paymentInfo?.customerInfo[0]?.plus4;
        } else {
          zip4 = "0000";
        }
      }
      if (
        getpaymentstate.paymentInfo.customerInfo[0].isTaxExempted !=
          undefined &&
        getpaymentstate.paymentInfo.customerInfo[0].isTaxExempted != null &&
        getpaymentstate.paymentInfo.customerInfo[0].isTaxExempted != ""
      ) {
        taxExemptAsOfToday =
          getpaymentstate.paymentInfo.customerInfo[0].isTaxExempted;
        setTaxExemption(taxExemptAsOfToday);
      }
      const convFeeTaxReq = {
        taxInputRequest: [
          {
            receiptItemCategory: "CONVENFEE",
            amount: String(configConvFeeWithOutTax),
            taxInputId: 32,
          },
          {
            receiptItemCategory: "LTRPAYFEE",
            amount: String(10.0),
            taxInputId: 33,
          },
          {
            receiptItemCategory: "BKRPAYTAX",
            amount: String(10.0),
            taxInputId: 34,
          },
        ],
        storeNumber: String(window.sessionStorage.getItem("storeNumber")),
        customerZipCode5:
          zip5 == "" ? getpaymentstate.paymentInfo.customerInfo[0].zip : zip5,
        customerZip4:
          zip4 == "" ? getpaymentstate.paymentInfo.customerInfo[0].plus4 : zip4,
        customerState: getpaymentstate.paymentInfo.customerInfo[0].state,
        taxExemptAsOfToday:
          taxExemptAsOfToday == ""
            ? false
            : taxExemptAsOfToday == "N"
            ? false
            : true,
        customerDOB: getpaymentstate.paymentInfo.customerInfo[0].dob,
      };

      const formedTaxEnginePayload: any = {
        customerState: getpaymentstate.paymentInfo.customerInfo[0].state,
        postalCode: zip5 + "-" + zip4,
        customerId: customerId,
        apiCall: "",
        dateOfBirth: moment(
          getpaymentstate.paymentInfo.customerInfo[0].dob
        ).format("YYYY-MM-DD"),
        taxRateOnly: "N",
        taxExemptAsOfToday: taxExemptAsOfToday == "" ? "N" : taxExemptAsOfToday,
      };
      let taxInputsData: any = [];
      taxInputsData.push(
        {
          amount: String(configConvFeeWithOutTax),
          taxInputId: "32",
          itemCategory: "CONVENFEE",
        },
        {
          amount: String(10.0),
          taxInputId: "33",
          itemCategory: "LTRPAYFEE",
        },
        {
          amount: String(10.0),
          taxInputId: "34",
          itemCategory: "BKRPAYTAX",
        }
      );
      formedTaxEnginePayload.storeInputs = [
        {
          taxInputs: taxInputsData,
          storeNumber: String(window.sessionStorage.getItem("storeNumber")),
        },
      ];
      if (configPermissions.optimizedPayment == 0) {
        const taxServiceRes = await GetTaxRateConv(convFeeTaxReq);
        const taxRateRes =
          taxServiceRes?.data?.TaxCalculatorRespone?.taxOutPutResponse?.filter(
            (obj) => obj.taxInputId == 32
          );
        lateFeeTaxRate =
          taxServiceRes?.data?.TaxCalculatorRespone?.taxOutPutResponse?.filter(
            (obj) => obj.taxInputId == 33
          );
        const backrentTax =
          taxServiceRes?.data?.TaxCalculatorRespone?.taxOutPutResponse?.filter(
            (obj) => obj.taxInputId == 34
          );
        if (backrentTax?.length > 0) {
          backRentTax = Number(backrentTax[0]?.taxRate);
          backRentValues.taxRate = Number(backrentTax[0]?.taxRate);
          setBackRentTax(backRentTax);
        }
        setLateFeeTaxRateCus(lateFeeTaxRate);

        if (taxRateRes?.length > 0) {
          configConvFeeTax = Number(taxRateRes[0]?.taxAmount);
          setconfigFeeOnlyTax(configConvFeeTax);
        }
      } else if (configPermissions.optimizedPayment == 1) {
        let taxServiceRes;
        let taxRateRes;
        let backrentTax;
        // if(configPermissions.commonModuleFlag==1){
        //    //Convenfee
        //    let convFee = calculationModuleRes?.customerFees?.filter(
        //     (x) => x.itemCategory == "CONVENFEE"
        //   );
        //    taxRateRes = 
        //    [{
        //     "itemCategory": "CONVENFEE",
        //     "taxOutputId": "32",
        //     "taxRateType": "ST",
        //     "taxRate": convFee[0]?.taxRate==undefined?"0.00":convFee[0]?.taxRate,
        //     "itemCategoryTax": "CONVENFEETAX",
        //     "amount":  convFee[0]?.amount==undefined?"0.00":convFee[0]?.amount,
        //     "taxAmount":  convFee[0]?.tax==undefined?"0.00":convFee[0]?.tax
        //   }]
        //   let logedInStoreDetails = calculationModuleRes?.paymentDetails?.filter(
        //     (x) => x.storeNumber ==  String(window.sessionStorage.getItem("storeNumber"))
        //   );
        //   // const latFeeAray =logedInStoreDetails[0]?.agreementPaymentDetails
        //   let latFeeAray:any=[]
        //   const filteredAmountDetailsLatFee = logedInStoreDetails[0]?.agreementPaymentDetails
        //     ?.reduce((acc, obj) => {
        //       latFeeAray = obj?.amountDetails?.filter(x => x?.itemCategory === 'LTRPAY');
        //     }, []);

        //   lateFeeTaxRate =[{
        //     "itemCategory": "LTRPAYFEE",
        //     "taxOutputId": "33",
        //     "taxRateType": "ST",
        //     "taxRate":latFeeAray[0]?.taxRate==undefined?"0.00":latFeeAray[0]?.taxRate,
        //     "itemCategoryTax": "RFEETAX",
        //     "amount":  latFeeAray[0]?.amount==undefined?"0.00":latFeeAray[0]?.amount,
        //     "taxAmount":  latFeeAray[0]?.tax==undefined?"0.00":latFeeAray[0]?.tax
        //   }]

        //   // const backRentArray = logedInStoreDetails[0]?.otherIncome?.filter(obj => obj.itemCategory=='BKRPAYTAX');
        //   let backRentArray:any=[]
        //   const filteredAmountDetailsBackrent = logedInStoreDetails[0]?.agreementPaymentDetails
        //     ?.reduce((acc, obj) => {
        //       backRentArray = obj?.amountDetails?.filter(x => x?.itemCategory === 'LTRPAY');
        //     }, []);
  
        //    backrentTax =[{
        //     "itemCategory": "BKRPAYTAX",
        //     "taxOutputId": "34",
        //     "taxRateType": "ST",
        //     "taxRate":backRentArray[0]?.taxRate==undefined?"0.00":backRentArray[0]?.taxRate,
        //     "itemCategoryTax": "RFEETAX",
        //     "amount":  backRentArray[0]?.amount==undefined?"0.00":backRentArray[0]?.amount,
        //     "taxAmount":  backRentArray[0]?.tax==undefined?"0.00":backRentArray[0]?.tax
        // }]
        // }else if(configPermissions.commonModuleFlag==0){
          taxServiceRes = await getTax(formedTaxEnginePayload);
          taxRateRes =
           taxServiceRes?.data?.taxResponse[0]?.taxOutputs?.filter(
             (obj) => obj.taxOutputId == "32"
           );
         lateFeeTaxRate =
           taxServiceRes?.data?.taxResponse[0]?.taxOutputs?.filter(
             (obj) => obj.taxOutputId == "33"
           );
 
          backrentTax =
           taxServiceRes?.data?.taxResponse[0]?.taxOutputs?.filter(
             (obj) => obj.taxOutputId == "34"
           );
        // }
        
        setLateFeeTaxRateCus(lateFeeTaxRate);
        if (backrentTax?.length > 0) {
          backRentTax = Number(backrentTax[0]?.taxRate);
          backRentValues.taxRate = Number(backrentTax[0]?.taxRate);
          setBackRentTax(backRentTax);
        }

        if (taxRateRes?.length > 0 && convFeeTaxSet == false) {
          configConvFeeTax = Number(taxRateRes[0]?.taxAmount);
          setconfigFeeOnlyTax(configConvFeeTax);
        }
      }
      
      
      // } else {
      //   setconfigFeeOnlyTax(configConvFeeTax);
      // }
      if (getpaymentstate != undefined && getpaymentstate.length != 0) {
        const chargeOffRefCodes = [
          "CUSTINIT",
          "CHARGEOFF",
          "COURTORD",
          "COLLAGCY",
        ];
        let paymentScheduleResp =
          getpaymentstate.paymentScheule?.references.filter(
            (x: any) => x.referenceKey == "PAYMENT_SCHEDULE"
          );

        let chargeoffReasonResp =
          getpaymentstate.paymentScheule?.references.filter(
            (x: any) => x.referenceKey == "AGREEMENT_STATUS_REASON"
          );
        chargeoffReasonResp = chargeoffReasonResp[0]?.referenceDetails;
        chargeoffReasonResp = chargeoffReasonResp?.filter((ref: any) =>
          chargeOffRefCodes.includes(ref.referenceCode)
        );
        chargeoffReasonResp = chargeoffReasonResp?.sort(function (
          obj1: any,
          obj2: any
        ) {
          return obj1?.description.localeCompare(obj2.description);
        });

        let arr: any = [];
        for (let i = 0; i < chargeoffReasonResp?.length; i++) {
          let dropdownval: any = {
            label: chargeoffReasonResp[i]?.description,
            value: chargeoffReasonResp[i]?.referenceCode,
          };
          arr.push(dropdownval);
        }
        setChrgeoffDropdownReason(arr);
        paymentEditScheduleDropDown=paymentScheduleResp
        setpaymentEditScheduleDropDown(paymentScheduleResp);
      }
      defaultPaygridvalues = getpaymentstate;
      setdefaultPaygridvalues(getpaymentstate);
    } else {
      getpaymentstate = defaultPaygridvalues;
      configConvFeeWithOutTax = ConvFeeWithOutTax;
      configConvFeeTax = configFeeOnlyTax;
      calculationModuleRes = calcModuleResponse;
      configPermissions = configFlags;
    }

    //Common Module

    if (configPermissions.commonModuleFlag == 1) {
      let amountDueResponseCalculateModule: any = [];
      let refundSecurityDepositeModule: any =[]
      if (calculationModuleRes) {

        let calculatepaymentStoreRes =
            calculationModuleRes?.paymentDetails?.filter(
              (x) =>
                x.storeNumber ==
                String( window.sessionStorage.getItem("storeNumber"))
            );

            if(calculatepaymentStoreRes.length>0){
              let sdAgreements= calculatepaymentStoreRes[0]?.agreementPaymentDetails?.filter(
                (x) =>
                  x.agreementType =="Promo"
              );
              

              if(sdAgreements?.length>0)
              {
                for(let i=0;i<sdAgreements?.length;i++)
                {

                  let sdOtherIncomeAgr= calculatepaymentStoreRes[0]?.otherIncome.filter((x)=> x.itemCategory=="SECDEP" && x.agreementId==sdAgreements[i].agreementId)
                  
                  if(Number(sdOtherIncomeAgr[0]?.amount)>0)
                  {
                    let obj = {
                      securityDepositAgreementNumber: sdAgreements[i]?.agreementNumber,
                      securityDepositAgreement: sdAgreements[i]?.agreementId,
                      depositAmount: (Number(sdOtherIncomeAgr[0]?.amount)+Number(sdOtherIncomeAgr[0]?.tax)).toFixed(2),
                      amountCollected: "0.00",
                      totalToRefund: (Number(sdOtherIncomeAgr[0]?.amount)+Number(sdOtherIncomeAgr[0]?.tax)).toFixed(2),
                      reasonDescription: "",
                    }
                    refundSecurityDepositeModule.push(obj)
                  }
                }
                console.log("objobj",refundSecurityDepositeModule)
                setMasterRSDData([...masterRSDData,...refundSecurityDepositeModule])
              }

            }

        // Common Module Agreement Loop
        for (
          let i = 0;
          i < getpaymentstate?.paymentInfo?.paymentInfo?.length;
          i++
        ) {
          let calculatepaymentStoreRes =
            calculationModuleRes?.paymentDetails?.filter(
              (x) =>
                x.storeNumber ==
                getpaymentstate?.paymentInfo?.paymentInfo[i]?.storeNumber
            );

          if (calculatepaymentStoreRes?.length > 0) {
            // Agr array push

            let calculatePaymentResAgr =
              calculatepaymentStoreRes[0]?.agreementPaymentDetails?.filter(
                (x) =>
                  x.agreementId ==
                  getpaymentstate?.paymentInfo?.paymentInfo[i].agreementId
              );

             

            if (calculatePaymentResAgr?.length > 0) {
              let getPaymentinfoRes =
                getpaymentstate?.paymentInfo?.paymentInfo[i];

              let lateFee = calculatePaymentResAgr[0].amountDetails?.filter(
                (x) =>
                  x.itemCategory == "LTRPAYFEE" || x.itemCategory == "LTPIFFEE"
              );
              let pastDueRent = calculatePaymentResAgr[0].amountDetails?.filter(
                (x) => x.itemCategory == "LTRPAY"
              );

              let policyAmount =
                calculatePaymentResAgr[0].amountDetails?.filter(
                  (x) => x.itemCategory == "RACTIREPAY"
                );

              let initialPaymentInfo =
                calculatePaymentResAgr[0].amountDetails?.filter(
                  (x) => x.itemCategory == "INITPAY"
                );

              let ldw = calculatePaymentResAgr[0].amountDetails?.filter(
                (x) => x.itemCategory == "LDW" || x.itemCategory == "LTLDW"
              );

              let convFee = calculationModuleRes?.customerFees?.filter(
                (x) => x.itemCategory == "CONVENFEE"
              );

              let rpayTaxAmount =
                calculatePaymentResAgr[0]?.amountDetails?.filter(
                  (x) =>
                    x.itemCategory == "RPAY" ||
                    x.itemCategory == "LTRPAY" ||
                    x.itemCategory == "INITPAY"||
                    x.itemCategory == "EPO"||
                    x.itemCategory == "PIF"||
                    x.itemCategory == "SAC"||
                    x.itemCategory == "PREINITPAY"||
                    x.itemCategory == "POA"||
                    x.itemCategory == "INPIF"
                );

              let extensionAmount =
                calculatePaymentResAgr[0]?.amountDetails?.filter(
                  (x) => x.itemCategory == "EXTAMT"
                );

              let deliveryFee =
                calculatePaymentResAgr[0]?.amountDetails?.filter(
                  (x) => x.itemCategory == "DELIVFEE"
                );

              let processingFee =
                calculatePaymentResAgr[0]?.amountDetails?.filter(
                  (x) => x.itemCategory == "PROCESSINGFEE"
                );

              let mattressfee =
                calculatePaymentResAgr[0]?.amountDetails?.filter(
                  (x) => x.itemCategory == "MATTRESSFEE"
                );

              // let getAmountDueAgrRes =
              //   getpaymentstate?.amountDue?.amountDueResponse?.filter(
              //     (x) => x.agreementId == calculatePaymentResAgr[0].agreementId,
              //   );

              let getAMountDueObj = {
                amountDue:
                  calculatePaymentResAgr[0]?.scheduleRate == undefined
                    ? "0.00"
                    : calculatePaymentResAgr[0]?.scheduleRate,

                amountDueLateRent:
                  pastDueRent?.length > 0 ? pastDueRent[0]?.amount : "0.00",

                ldwAmount: ldw?.length > 0 ? ldw[0].amount : "0.00",

                ldwLateRent: ldw?.length > 0 ? ldw[0].amount : "0.00",

                lateFee:
                  lateFee[0]?.amount == undefined ? "0.00" : lateFee[0]?.amount,

                lFeeTaxAmount:
                  lateFee[0]?.tax == undefined ? "0.00" : lateFee[0]?.tax,

                pastDueRent:
                  calculatePaymentResAgr[0]?.pastDueRent == undefined
                    ? "0.00"
                    : calculatePaymentResAgr[0]?.pastDueRent,

                policyAmount:
                  policyAmount[0]?.amount == undefined
                    ? "0.00"
                    : policyAmount[0]?.amount,

                policyLateRent:
                  policyAmount[0]?.amount == undefined
                    ? "0.00"
                    : policyAmount[0]?.amount,

                policyTaxAmount:
                  policyAmount[0]?.tax == undefined
                    ? "0.00"
                    : policyAmount[0]?.tax,

                isSwitchoutDeliveryPending: calculatePaymentResAgr[0]?.agreementRateDetails?.switchoutDeliveryStatus=='Y'?1:0, 

                agreementId: calculatePaymentResAgr[0]?.agreementId,

                agreementStatus: calculatePaymentResAgr[0]?.agreementStatus,
                suspenseAmount: calculatePaymentResAgr[0]?.suspenseBalance != ''? calculatePaymentResAgr[0]?.suspenseBalance: "0.00",
                coPaymentAmount: null,

                coWebleadUsed: null,

                nextDueDate:
                  calculatePaymentResAgr[0]?.isFinalPayment != undefined &&
                  calculatePaymentResAgr[0]?.isFinalPayment != "" &&
                  calculatePaymentResAgr[0]?.agreementType != "PIF Note"
                    ? ["INAGMEPO","INEPO"].includes(calculatePaymentResAgr[0]?.isFinalPayment)?"EPO":
                    calculatePaymentResAgr[0]?.isFinalPayment=="INAGMSAC"?"SAC":
                    calculatePaymentResAgr[0]?.agreementType=='EPO Rent'&& calculatePaymentResAgr[0].isNoteAgreement=='Y' && calculatePaymentResAgr[0].nextDueDate != null?
                    moment(calculatePaymentResAgr[0]?.nextDueDate).format("MM/DD/YYYY"): calculatePaymentResAgr[0]?.isFinalPayment:
                    calculatePaymentResAgr[0]?.agreementType == "PIF Note"? "PIF" 
                    : calculatePaymentResAgr[0]?.nextDueDate != "" ||
                      calculatePaymentResAgr[0]?.nextDueDate != "SAC" ||
                      calculatePaymentResAgr[0]?.nextDueDate != "PIF" ||
                      calculatePaymentResAgr[0]?.nextDueDate != "EPO" ||
                      calculatePaymentResAgr[0]?.nextDueDate != null
                    ? moment(calculatePaymentResAgr[0]?.nextDueDate).format(
                        "MM/DD/YYYY"
                      )
                    : calculatePaymentResAgr[0]?.nextDueDate,

                pastDueDate:
                  calculatePaymentResAgr[0]?.currentDueDate != "" ||
                  calculatePaymentResAgr[0]?.currentDueDate != "SAC" ||
                  calculatePaymentResAgr[0]?.currentDueDate != "PIF" ||
                  calculatePaymentResAgr[0]?.currentDueDate != "EPO" ||
                  calculatePaymentResAgr[0]?.currentDueDate != null
                    ? moment(calculatePaymentResAgr[0]?.currentDueDate).format(
                        "MM/DD/YYYY"
                      )
                    : calculatePaymentResAgr[0]?.currentDueDate,

                epoAmount: (
                  Number(
                    calculatePaymentResAgr[0]?.agreementRateDetails
                      .estimatedEpoAmountIncTax
                  ) - Number(calculatePaymentResAgr[0]?.suspenseBalance)
                ).toFixed(2), //--Need to Add

                agreementType: calculatePaymentResAgr[0]?.agreementType,

                initialPaymentFlag: initialPaymentInfo?.length > 0 ? 1 : 0,

                extensionAmont:
                  extensionAmount?.length == 0
                    ? "0.00"
                    : extensionAmount[0]?.amount, //

                extensionAmontTax:
                  extensionAmount?.length == 0
                    ? "0.00"
                    : extensionAmount[0]?.tax,

                deliveryAmount:
                  deliveryFee?.length == 0 ? "0.00" : deliveryFee[0]?.amount,

                deliveryAmountTax:
                  deliveryFee?.length == 0 ? "0.00" : deliveryFee[0]?.tax,

                processingFee:
                  processingFee?.length == 0
                    ? "0.00"
                    : processingFee[0]?.amount,

                processingFeeTax:
                  processingFee?.length == 0 ? "0.00" : processingFee[0]?.tax,

                mattressFee:
                  mattressfee?.length == 0 ? "0.00" : mattressfee[0]?.amount,

                mattressFeeTax:
                  mattressfee?.length == 0 ? "0.00" : mattressfee[0]?.tax,

                totalLateAmount: calculatePaymentResAgr[0]?.totalAmount,

                totalLateTax: calculatePaymentResAgr[0]?.totalTax,

                initialPaymentTotal: (
                  Number(calculatePaymentResAgr[0]?.totalAmount) +
                  Number(calculatePaymentResAgr[0]?.totalTax)
                ).toFixed(2),

                rpayTaxAmount: rpayTaxAmount?.length,

                ldwTaxAmount: ldw?.length > 0 ? ldw[0].tax : 0,

                taxGeoCode:
                  calculatePaymentResAgr[0]?.amountDetails[0]?.taxGeoCode, //--Use any one category

                totalAmount: calculatePaymentResAgr[0]?.totalAmount,

                totalTax: calculatePaymentResAgr[0]?.totalTax,

                epoTax:
                  calculatePaymentResAgr[0]?.agreementRateDetails
                    .estimatedEpoTax, //Need to Add

                taxRate: rpayTaxAmount[0]?.taxRate, //--Use any one category

                coverageEndDate:
                  calculatePaymentResAgr[0]?.agreementRateDetails
                    .coverageEndDate, //Need to add

                policyStatus: calculatePaymentResAgr[0]?.policyStatus
                  ? calculatePaymentResAgr[0]?.policyStatus
                  : "", //Need to add

                ldwPercentage:
                  calculatePaymentResAgr[0]?.agreementRateDetails.ldwPercentage, //Need to add

                agreementRateDetails:
                  calculatePaymentResAgr[0]?.agreementRateDetails, //Need to add

                isLdwAllowed:
                  calculatePaymentResAgr[0]?.agreementRateDetails.isLdwAllowed, //Need to add

                storeNumber: calculatepaymentStoreRes[0]?.storeNumber,

                convFee: Number(convFee[0]?.amount),

                convFeeTax: convFee[0]?.tax,

                zipPlus4UsedForTax:
                  calculatePaymentResAgr[0]?.amountDetails[0]
                    ?.zipPlus4UsedForTax,
              };
              //Agreement Rate Details Mapping
              let carryRent = calculatePaymentResAgr[0]?.amountDetails?.filter(
                (x) => x.itemCategory == "CRPAY"
              );
              let carryLateRent =
                calculatePaymentResAgr[0]?.amountDetails?.filter(
                  (x) => x.itemCategory == "CLFEEPAY"
                );
              if (getAMountDueObj.agreementRateDetails) {
                getAMountDueObj.agreementRateDetails.employeePurchasePlan =  calculatePaymentResAgr[0]?.agreementRateDetails?.isEppAgreement
                getAMountDueObj.agreementRateDetails.carriedLate =
                  carryLateRent?.length > 0 ? carryLateRent[0].amount : "0.00";
                getAMountDueObj.agreementRateDetails.carriedLateTax =
                  carryLateRent?.length > 0 ? carryLateRent[0].tax : "0.00";
                getAMountDueObj.agreementRateDetails.carryRent =
                  carryRent?.length > 0 ? carryRent[0].amount : "0.00";
                getAMountDueObj.agreementRateDetails.carryRentTax =
                  carryRent?.length > 0 ? carryRent[0].tax : "0.00";
                getAMountDueObj.agreementRateDetails.daysLate =
                  calculatePaymentResAgr[0]?.daysLate == undefined
                    ? "0.00"
                    : calculatePaymentResAgr[0]?.daysLate;
                getAMountDueObj.agreementRateDetails.isNoteAgreement =
                  calculatePaymentResAgr[0]?.isNoteAgreement == undefined
                    ? ""
                    : calculatePaymentResAgr[0]?.isNoteAgreement == "Y"
                    ? "1"
                    : 0;
                getAMountDueObj.agreementRateDetails.scheduleRefCode =
                  calculatePaymentResAgr[0]?.schedule;
                getAMountDueObj.agreementRateDetails.numberPaymentMade =
                  calculatePaymentResAgr[0]?.numberOfPaymentsMade;
                getAMountDueObj.agreementRateDetails.rentPaid =
                  calculatePaymentResAgr[0]?.rentPaid;
                getAMountDueObj.agreementRateDetails.cashPrice =
                  calculatePaymentResAgr[0]?.cashPrice;
                getAMountDueObj.agreementRateDetails.totalCost =
                  calculatePaymentResAgr[0]?.totalCost;
                  //////

                 
                getAMountDueObj.agreementRateDetails.perDayLdw = calculatePaymentResAgr[0]?.agreementRateDetails.perDayLdw?calculatePaymentResAgr[0]?.agreementRateDetails?.perDayLdw:"0.00";
                getAMountDueObj.agreementRateDetails.perDayLdwTax = calculatePaymentResAgr[0]?.agreementRateDetails.perDayLdwTax?calculatePaymentResAgr[0]?.agreementRateDetails?.perDayLdwTax:"0.00";
                getAMountDueObj.agreementRateDetails.weeklyLdw = calculatePaymentResAgr[0]?.agreementRateDetails.weeklyLdw?calculatePaymentResAgr[0]?.agreementRateDetails?.weeklyLdw:"0.00";
                getAMountDueObj.agreementRateDetails.weeklyLdwTax = calculatePaymentResAgr[0]?.agreementRateDetails.weeklyLdwTax?calculatePaymentResAgr[0]?.agreementRateDetails?.weeklyLdwTax:"0.00";
                getAMountDueObj.agreementRateDetails.biWeeklyLdw = calculatePaymentResAgr[0]?.agreementRateDetails.biWeeklyLdw?calculatePaymentResAgr[0]?.agreementRateDetails?.biWeeklyLdw:"0.00";
                getAMountDueObj.agreementRateDetails.biWeeklyLdwTax = calculatePaymentResAgr[0]?.agreementRateDetails.biWeeklyLdwTax?calculatePaymentResAgr[0]?.agreementRateDetails?.biWeeklyLdwTax:"0.00";

                getAMountDueObj.agreementRateDetails.semiMonthlyLdw = calculatePaymentResAgr[0]?.agreementRateDetails.semiMonthlyLdw?calculatePaymentResAgr[0]?.agreementRateDetails?.semiMonthlyLdw:"0.00";

                getAMountDueObj.agreementRateDetails.semiMonthlyLdwTax = calculatePaymentResAgr[0]?.agreementRateDetails.semiMonthlyLdwTax?calculatePaymentResAgr[0]?.agreementRateDetails?.semiMonthlyLdwTax:"0.00";

                getAMountDueObj.agreementRateDetails.monthlyLdw = calculatePaymentResAgr[0]?.agreementRateDetails.monthlyLdw?calculatePaymentResAgr[0]?.agreementRateDetails?.monthlyLdw:"0.00";

                getAMountDueObj.agreementRateDetails.monthlyLdwTax = calculatePaymentResAgr[0]?.agreementRateDetails.monthlyLdwTax?calculatePaymentResAgr[0]?.agreementRateDetails?.monthlyLdwTax:"0.00";
                
                getAMountDueObj.agreementRateDetails.perDayPolicy = calculatePaymentResAgr[0]?.agreementRateDetails.perDayPolicy?calculatePaymentResAgr[0]?.agreementRateDetails?.perDayPolicy:"0.00";

                getAMountDueObj.agreementRateDetails.perDayPolicyTax = calculatePaymentResAgr[0]?.agreementRateDetails.perDayPolicyTax?calculatePaymentResAgr[0]?.agreementRateDetails?.perDayPolicyTax:"0.00";

                getAMountDueObj.agreementRateDetails.weeklyPolicy = calculatePaymentResAgr[0]?.agreementRateDetails.weeklyPolicy?calculatePaymentResAgr[0]?.agreementRateDetails?.weeklyPolicy:"0.00";

                getAMountDueObj.agreementRateDetails.weeklyPolicyTax = calculatePaymentResAgr[0]?.agreementRateDetails.weeklyPolicyTax?calculatePaymentResAgr[0]?.agreementRateDetails?.weeklyPolicyTax:"0.00";

                getAMountDueObj.agreementRateDetails.biWeeklyPolicy = calculatePaymentResAgr[0]?.agreementRateDetails.biWeeklyPolicy?calculatePaymentResAgr[0]?.agreementRateDetails?.biWeeklyPolicy:"0.00";

                getAMountDueObj.agreementRateDetails.biWeeklyPolicyTax = calculatePaymentResAgr[0]?.agreementRateDetails.biWeeklyPolicyTax?calculatePaymentResAgr[0]?.agreementRateDetails?.biWeeklyPolicyTax:"0.00";

                getAMountDueObj.agreementRateDetails.semiMonthlyPolicy = calculatePaymentResAgr[0]?.agreementRateDetails.semiMonthlyPolicy?calculatePaymentResAgr[0]?.agreementRateDetails?.semiMonthlyPolicy:"0.00";

                getAMountDueObj.agreementRateDetails.semiMonthlyPolicyTax = calculatePaymentResAgr[0]?.agreementRateDetails.semiMonthlyPolicyTax?calculatePaymentResAgr[0]?.agreementRateDetails?.semiMonthlyPolicyTax:"0.00";

                getAMountDueObj.agreementRateDetails.monthlyPolicy = calculatePaymentResAgr[0]?.agreementRateDetails.monthlyPolicy?calculatePaymentResAgr[0]?.agreementRateDetails?.monthlyPolicy:"0.00";

                getAMountDueObj.agreementRateDetails.monthlyPolicyTax = calculatePaymentResAgr[0]?.agreementRateDetails.monthlyPolicyTax?calculatePaymentResAgr[0]?.agreementRateDetails?.monthlyPolicyTax:"0.00";

                // getAMountDueObj.agreementRateDetails.monthlyPolicyTax = calculatePaymentResAgr[0]?.agreementRateDetails.perDayLdw?calculatePaymentResAgr[0]?.agreementRateDetails?.perDayLdw:"0.00";

                getAMountDueObj.agreementRateDetails.perDayRent =calculatePaymentResAgr[0]?.agreementRateDetails.perDayRent?calculatePaymentResAgr[0]?.agreementRateDetails?.perDayRent:"0.00" 

                getAMountDueObj.agreementRateDetails.perDayRentTax =calculatePaymentResAgr[0]?.agreementRateDetails.perDayRentTax?calculatePaymentResAgr[0]?.agreementRateDetails?.perDayRentTax:"0.00"    

                getAMountDueObj.agreementRateDetails.weeklyRent = calculatePaymentResAgr[0]?.agreementRateDetails.weeklyRent?calculatePaymentResAgr[0]?.agreementRateDetails?.weeklyRent:"0.00"    

                getAMountDueObj.agreementRateDetails.weeklyRentTax = calculatePaymentResAgr[0]?.agreementRateDetails.weeklyRentTax?calculatePaymentResAgr[0]?.agreementRateDetails?.weeklyRentTax:"0.00" 

                getAMountDueObj.agreementRateDetails.biWeeklyRent = calculatePaymentResAgr[0]?.agreementRateDetails.biWeeklyRent?calculatePaymentResAgr[0]?.agreementRateDetails?.biWeeklyRent:"0.00" 

                getAMountDueObj.agreementRateDetails.biWeeklyRentTax = calculatePaymentResAgr[0]?.agreementRateDetails.biWeeklyRentTax?calculatePaymentResAgr[0]?.agreementRateDetails?.biWeeklyRentTax:"0.00"     

                getAMountDueObj.agreementRateDetails.semiMonthlyRent = calculatePaymentResAgr[0]?.agreementRateDetails.semiMonthlyRent?calculatePaymentResAgr[0]?.agreementRateDetails?.semiMonthlyRent:"0.00"      

                getAMountDueObj.agreementRateDetails.semiMonthlyRentTax = calculatePaymentResAgr[0]?.agreementRateDetails.semiMonthlyRentTax?calculatePaymentResAgr[0]?.agreementRateDetails?.semiMonthlyRentTax:"0.00"       

                getAMountDueObj.agreementRateDetails.monthlyRent = calculatePaymentResAgr[0]?.agreementRateDetails.monthlyRent?calculatePaymentResAgr[0]?.agreementRateDetails?.monthlyRent:"0.00"   

                getAMountDueObj.agreementRateDetails.monthlyRentTax = calculatePaymentResAgr[0]?.agreementRateDetails.monthlyRentTax?calculatePaymentResAgr[0]?.agreementRateDetails?.monthlyRentTax:"0.00"  

                getAMountDueObj.agreementRateDetails.noteAmountIncTax = Number(Number(calculatePaymentResAgr[0]?.agreementRateDetails?.noteCost)+
                Number(calculatePaymentResAgr[0]?.agreementRateDetails?.noteTax))?.toFixed(2)

                getAMountDueObj.agreementRateDetails.remainingNoteCost= calculatePaymentResAgr[0]?.agreementRateDetails.remainingNoteCost?calculatePaymentResAgr[0]?.agreementRateDetails?.remainingNoteCost:"0.00"

                getAMountDueObj.agreementRateDetails.noteCost =calculatePaymentResAgr[0]?.agreementRateDetails.noteCost?calculatePaymentResAgr[0]?.agreementRateDetails?.noteCost:"0.00"

                getAMountDueObj.agreementRateDetails.noteTax = calculatePaymentResAgr[0]?.agreementRateDetails.noteTax?calculatePaymentResAgr[0]?.agreementRateDetails?.noteTax:"0.00"

                // getAMountDueObj.agreementRateDetails.employeePurchasePlan = calculatePaymentResAgr[0]?.agreementRateDetails.perDayLdw?calculatePaymentResAgr[0]?.agreementRateDetails?.perDayLdw:"0.00"
                getAMountDueObj.agreementRateDetails.isTransferInitiated = calculatePaymentResAgr[0]?.agreementRateDetails.isTransferInitiated?calculatePaymentResAgr[0]?.agreementRateDetails?.isTransferInitiated=="Y"?"1":"0":"0"
                // getAMountDueObj.agreementRateDetails.cashPrice =calculatePaymentResAgr[0]?.agreementRateDetails.perDayLdw?calculatePaymentResAgr[0]?.agreementRateDetails?.perDayLdw:"0.00"
                // getAMountDueObj.agreementRateDetails.totalCost = calculatePaymentResAgr[0]?.agreementRateDetails.perDayLdw?calculatePaymentResAgr[0]?.agreementRateDetails?.perDayLdw:"0.00"
                
                


                  //////
              }

              amountDueResponseCalculateModule.push(getAMountDueObj);
              getpaymentstate.taxTotal?.push({
                agreementId: calculatePaymentResAgr[0]?.agreementId,
                amount: Number(calculatePaymentResAgr[0]?.totaltax),
              });
            } else {
              getpaymentstate?.paymentInfo?.paymentInfo?.splice(0, i);
            }
          }
        }
        // Common Module Club Loop
        //This will be based up on the getPaymentInfo Club
        for (
          let i = 0;
          i < getpaymentstate?.paymentInfo?.clubInformation?.length;
          i++
        ) {
          let calculatepaymentStoreRes =
            calculationModuleRes?.paymentDetails?.filter(
              (x) =>
                x.storeNumber ==
                getpaymentstate?.paymentInfo?.clubInformation[0]?.storeNumber
            );

          let calculatePaymentResClub =
            calculatepaymentStoreRes[0]?.clubPaymentDetails?.filter(
              (x) =>
                x.clubMembershipNumber ==
                getpaymentstate?.paymentInfo?.clubInformation[0]
                  ?.clubMembershipNumber
            );

          if (calculatePaymentResClub?.length) {
            // let getAmountDueClubRes =
            //   getpaymentstate?.amountDue?.amountDueResponse?.filter(
            //     (x) =>
            //       x.customerClubId ==
            //       Number(calculatePaymentResClub[0].customerClubId),
            //   );

            let convFee = calculationModuleRes?.customerFees?.filter(
              (x) => x.itemCategory == "CONVENFEE"
            );
            let taxRateCategory = calculationModuleRes?.customerFees?.filter(
              (x) =>
                x.itemCategory == "PRELIMCLUB" || x.itemCategory == "CLUBPAY"
            );

            let getAmountDueClub = {
              clubAmountDue: calculatePaymentResClub[0]?.scheduleRate,

              clubAmountLateRent: calculatePaymentResClub[0]?.totalAmount,

              customerClubId: Number(calculatePaymentResClub[0].customerClubId),

              originAgreementIdStatus:
                calculatePaymentResClub[0]?.originAgreementStatus,

              clubStatus: calculatePaymentResClub[0]?.clubStatus,

              lateFee: "0.00",

              nextDueDate:
                calculatePaymentResClub[0]?.nextDueDate != "" ||
                calculatePaymentResClub[0]?.nextDueDate != "SAC" ||
                calculatePaymentResClub[0]?.nextDueDate != "PIF" ||
                calculatePaymentResClub[0]?.nextDueDate != "EPO" ||
                calculatePaymentResClub[0]?.nextDueDate != null
                  ? moment(calculatePaymentResClub[0]?.nextDueDate).format(
                      "MM/DD/YYYY"
                    )
                  : calculatePaymentResClub[0]?.nextDueDate,

              pastDueDate:
                calculatePaymentResClub[0]?.currentDueDate != "" ||
                calculatePaymentResClub[0]?.currentDueDate != "SAC" ||
                calculatePaymentResClub[0]?.currentDueDate != "PIF" ||
                calculatePaymentResClub[0]?.currentDueDate != "EPO" ||
                calculatePaymentResClub[0]?.currentDueDate != null
                  ? moment(calculatePaymentResClub[0]?.currentDueDate).format(
                      "MM/DD/YYYY"
                    )
                  : calculatePaymentResClub[0]?.currentDueDate,

              epoAmount: "0.00",

              agreementType: "",

              initialPaymentFlag:
                calculatePaymentResClub[0]?.clubStatus == "P" ? 1 : 0,

              clubTax: calculatePaymentResClub[0]?.totalTax,

              taxGeoCode: calculatePaymentResClub[0]?.taxGeoCode,

              totalAmount: calculatePaymentResClub[0]?.totalAmount,

              totalTax: calculatePaymentResClub[0]?.totalTax,

              epoTax: "0.00",

              // taxRate: calculatePaymentResClub[0]?.taxRate,

              coverageEndDate: "9999-01-01", //Need to check

              policyStatus: null,

              agreementRateDetails: calculatePaymentResClub[0]?.clubRateDetails,

              isLdwAllowed: null,

              storeNumber: calculatepaymentStoreRes[0].storeNumber,

              clubCreatedCustomerId: customerId,

              priority: "0",

              convFee: Number(convFee[0]?.amount),

              convFeeTax: convFee[0]?.tax,

              zipPlus4UsedForTax:
                calculatePaymentResClub[0]?.zipPlus4UsedForTax,

              totalLateAmount: calculatePaymentResClub[0]?.totalAmount,

              totalLateTax: calculatePaymentResClub[0]?.totaltax,

              taxRate: taxRateCategory[0]?.taxRate,
            };

            if (getAmountDueClub.agreementRateDetails) {
              getAmountDueClub.agreementRateDetails.carriedLate = "0.00";
              getAmountDueClub.agreementRateDetails.carriedLateTax = "0.00";
              getAmountDueClub.agreementRateDetails.carryRent = "0.00";
              getAmountDueClub.agreementRateDetails.carryRentTax = "0.00";
              getAmountDueClub.agreementRateDetails.daysLate = "0";
              getAmountDueClub.agreementRateDetails.perDayLdw = "0";
              getAmountDueClub.agreementRateDetails.perDayLdwTax = "0";
              getAmountDueClub.agreementRateDetails.weeklyLdw = "0";
              getAmountDueClub.agreementRateDetails.weeklyLdwTax = "0";
              getAmountDueClub.agreementRateDetails.biWeeklyLdw = "0";
              getAmountDueClub.agreementRateDetails.biWeeklyLdwTax = "0";
              getAmountDueClub.agreementRateDetails.semiMonthlyLdw = "0";
              getAmountDueClub.agreementRateDetails.semiMonthlyLdwTax = "0";
              getAmountDueClub.agreementRateDetails.monthlyLdw = "0";
              getAmountDueClub.agreementRateDetails.monthlyLdwTax = "0";
              getAmountDueClub.agreementRateDetails.perDayPolicy = "0";
              getAmountDueClub.agreementRateDetails.perDayPolicyTax = "0";
              getAmountDueClub.agreementRateDetails.weeklyPolicy = "0";
              getAmountDueClub.agreementRateDetails.weeklyPolicyTax = "0";
              getAmountDueClub.agreementRateDetails.biWeeklyPolicy = "0";
              getAmountDueClub.agreementRateDetails.biWeeklyPolicyTax = "0";
              getAmountDueClub.agreementRateDetails.semiMonthlyPolicy = "0";
              getAmountDueClub.agreementRateDetails.semiMonthlyPolicyTax = "0";
              getAmountDueClub.agreementRateDetails.monthlyPolicy = "0";
              getAmountDueClub.agreementRateDetails.monthlyPolicyTax = "0";
              getAmountDueClub.agreementRateDetails.monthlyPolicyTax = "0";
              getAmountDueClub.agreementRateDetails.perDayRent =
                calculatePaymentResClub[0]?.clubRateDetails.perDayClub;
              getAmountDueClub.agreementRateDetails.perDayRentTax =
                calculatePaymentResClub[0]?.clubRateDetails.perDayClubTax;
              getAmountDueClub.agreementRateDetails.weeklyRent =
                calculatePaymentResClub[0]?.clubRateDetails.weeklyClub;
              getAmountDueClub.agreementRateDetails.weeklyRentTax =
                calculatePaymentResClub[0]?.clubRateDetails.weeklyClubTax;
              getAmountDueClub.agreementRateDetails.biWeeklyRent =
                calculatePaymentResClub[0]?.clubRateDetails.biWeeklyClub;
              getAmountDueClub.agreementRateDetails.biWeeklyRentTax =
                calculatePaymentResClub[0]?.clubRateDetails.biWeeklyClubTax;
              getAmountDueClub.agreementRateDetails.semiMonthlyRent =
                calculatePaymentResClub[0]?.clubRateDetails.semiMonthlyClub;
              getAmountDueClub.agreementRateDetails.semiMonthlyRentTax =
                calculatePaymentResClub[0]?.clubRateDetails.semiMonthlyClubTax;
              getAmountDueClub.agreementRateDetails.monthlyRent =
                calculatePaymentResClub[0]?.clubRateDetails.monthlyClub;
              getAmountDueClub.agreementRateDetails.monthlyRentTax =
                calculatePaymentResClub[0]?.clubRateDetails.monthlyClubTax;
              getAmountDueClub.agreementRateDetails.noteAmountIncTax = "0";
              getAmountDueClub.agreementRateDetails.remainingNoteCost = "0";
              getAmountDueClub.agreementRateDetails.noteCost = "0";
              getAmountDueClub.agreementRateDetails.noteTax = "0";
              getAmountDueClub.agreementRateDetails.employeePurchasePlan = "0";
              getAmountDueClub.agreementRateDetails.isTransferInitiated = "0";
              getAmountDueClub.agreementRateDetails.cashPrice = "0";
              getAmountDueClub.agreementRateDetails.totalCost = "0";
              getAmountDueClub.agreementRateDetails.scheduleRefCode =
                calculatePaymentResClub[0]?.schedule;
              getAmountDueClub.agreementRateDetails.numberPaymentMade =
                calculatePaymentResClub[0]?.numberOfPaymentsMade
                  ? "0"
                  : calculatePaymentResClub[0]?.numberOfPaymentsMade;
              getAmountDueClub.agreementRateDetails.rentPaid = "0";
            }

            amountDueResponseCalculateModule.push(getAmountDueClub);
            getpaymentstate.taxTotal?.push({
              agreementId: "Club",
              amount: Number(calculatePaymentResClub[0]?.totaltax),
            });
            getpaid;
          } else {
            getpaymentstate?.paymentInfo?.clubInformation?.splice(0, i);
          }
        }
        // Common Module Other Income loop
        getpaymentstate.amountDue.otherIncomeDetails = [];
        getpaymentstate.amountDue.skipStolenDetails=[];
        
          let commonModuleOtheIncomeData =
            calculationModuleRes?.paymentDetails?.filter(
              (x) =>
                x.storeNumber ==
                String(window.sessionStorage.getItem("storeNumber"))
            );

            if(commonModuleOtheIncomeData.length > 0)
            {
            // if (calculationModuleRes?.length) {
              //NSF OTHER INCOME
              for (let i = 0; i < commonModuleOtheIncomeData?.length; i++) {
                let calculationModuleReSNSF =
                  commonModuleOtheIncomeData[0].otherIncome?.filter(
                    (x) => x.itemCategory == "NSFCHK" || x.itemCategory == "RETCHKFEE" 
                  );
  
             
                for (let i = 0; i < calculationModuleReSNSF?.length; i++) {
                  if(!getpaymentstate.amountDue.otherIncomeDetails?.some(item=>item.receiptTransactionId==Number(calculationModuleReSNSF[i]?.receiptTransactionId))){
                  let calculationModuleReSNSFFEEtAX =
                    commonModuleOtheIncomeData[0].otherIncome?.filter(
                      (x) =>
                        x.itemCategory == "RETCHKFEE" &&
                        Number(x.receiptTransactionId) ==
                        Number(calculationModuleReSNSF[i]?.receiptTransactionId)
                    );
                  let otherIncomeNSFObj = {
                    receiptTransactionId: Number(
                      calculationModuleReSNSF[i]?.receiptTransactionId
                    ),
                    checkWrittenBy:
                      calculationModuleReSNSF[i]?.otherIncomeInfo.checkWrittenBy,
                    checkNumber:
                      calculationModuleReSNSF[i]?.otherIncomeInfo.checkNumber,
                    cardLastFour:
                      calculationModuleReSNSF[i]?.otherIncomeInfo
                        .cardNumberLastFour,
                    returnDate:
                      calculationModuleReSNSF[i]?.otherIncomeInfo.returnDate,
                    nsfFee: Number(
                      calculationModuleReSNSF[i]?.otherIncomeInfo.nsfFee
                    ),
                    balanceFee: Number(
                      calculationModuleReSNSF[i]?.otherIncomeInfo.balanceFee
                    ),
                    balanceAmount: Number(
                      calculationModuleReSNSF[i]?.otherIncomeInfo.balanceAmount
                    ),
                    amount: Number(
                      calculationModuleReSNSF[i]?.otherIncomeInfo.amount
                    ),
                    storeNumber: String(
                      window.sessionStorage.getItem("storeNumber")
                    ),
                    customerAccountId:
                      calculationModuleReSNSF[i]?.otherIncomeInfo.customerAccountId,
                    accountType: calculationModuleReSNSF[i]?.otherIncomeInfo.accountType,
                    feeTax: calculationModuleReSNSFFEEtAX[0]?.tax==undefined?"0.00":calculationModuleReSNSFFEEtAX[0]?.tax,
                    feeTaxRate: calculationModuleReSNSFFEEtAX[0]?.taxRate==undefined?"0.00":calculationModuleReSNSFFEEtAX[0]?.taxRate
                  };
                  getpaymentstate.amountDue.otherIncomeDetails.push(otherIncomeNSFObj);
                }
                }
              
                
                // }
                // }
  
                //CCCB OTHER INCOME
  
                let calculationModuleReSCCCB =
                  commonModuleOtheIncomeData[0].otherIncome?.filter(
                    (x) => x.itemCategory == "CCCB"|| x.itemCategory == "CCCBFEE" 
                  );
                for (let i = 0; i < calculationModuleReSCCCB?.length; i++) {
                  if(!getpaymentstate.amountDue.otherIncomeDetails?.some(item=>item.receiptTransactionId==Number(calculationModuleReSCCCB[i]?.receiptTransactionId))){
                  let calculationModuleReSCCCBFEEtAX =
                    commonModuleOtheIncomeData[0].otherIncome?.filter(
                      (x) =>
                      x.itemCategory == "CCCBFEE" &&
                        Number(x.receiptTransactionId) ==
                        Number(calculationModuleReSCCCB[i].receiptTransactionId)
                        
                    );
                  let otherIncomeCCCBObj = {
                    receiptTransactionId: Number(
                      calculationModuleReSCCCB[i]?.receiptTransactionId
                    ),
                    checkWrittenBy:
                      calculationModuleReSCCCB[i]?.otherIncomeInfo.checkWrittenBy,
                    checkNumber:
                      calculationModuleReSCCCB[i]?.otherIncomeInfo.checkNumber,
                    cardLastFour:
                      calculationModuleReSCCCB[i]?.otherIncomeInfo.cardNumberLastFour,
                    returnDate:
                      calculationModuleReSCCCB[i]?.otherIncomeInfo.returnDate,
                    nsfFee: Number(
                      calculationModuleReSCCCB[i]?.otherIncomeInfo.nsfFee
                    ),
                    balanceFee: Number(
                      calculationModuleReSCCCB[i]?.otherIncomeInfo.balanceFee
                    ),
                    balanceAmount: Number(
                      calculationModuleReSCCCB[i]?.otherIncomeInfo.balanceAmount
                    ),
                    amount: Number(
                      calculationModuleReSCCCB[i]?.otherIncomeInfo.amount
                    ),
                    storeNumber: String(window.sessionStorage.getItem("storeNumber")),
                    customerAccountId:
                      calculationModuleReSCCCB[i]?.otherIncomeInfo.customerAccountId,
                    accountType: calculationModuleReSCCCB[i]?.otherIncomeInfo?.accountType,
                    feeTax: calculationModuleReSCCCBFEEtAX[0]?.tax==undefined?"0.00": calculationModuleReSCCCBFEEtAX[0]?.tax,
                    cccbFee:  calculationModuleReSCCCBFEEtAX[0]?.amount==undefined?"0.00": calculationModuleReSCCCBFEEtAX[0]?.amount,
                  };
                  getpaymentstate.amountDue.otherIncomeDetails.push(otherIncomeCCCBObj);
                }
                }
              
                
  
                let calculateModuleSkipStolen =
                  commonModuleOtheIncomeData[0].otherIncome?.filter(
                    (x) => x.itemCategory == "RESTITPAY"
                  );
  
                if (calculateModuleSkipStolen?.length > 0) {
                  for (let i = 0; i < calculateModuleSkipStolen?.length; i++) {
  
                    let skipStolenObj = {
                      customerAccountId: calculateModuleSkipStolen[i]?.customerAccountId,
                      epoAmountOwned: Number(calculateModuleSkipStolen[i]?.otherIncomeInfo.epoAmountOwed),
                      agreementId: Number(calculateModuleSkipStolen[i]?.agreementId),
                      agreementNumber: calculateModuleSkipStolen[i]?.otherIncomeInfo?.agreementNumber,
                      closeDate: calculateModuleSkipStolen[i]?.otherIncomeInfo?.closeDate,
                      storeNumber: calculateModuleSkipStolen[i]?.otherIncomeInfo?.storeNumber,
                      remainingEpoAmount: Number(calculateModuleSkipStolen[i]?.otherIncomeInfo?.remainingEpoAmount),
                      remainingEpoTax: calculateModuleSkipStolen[i]?.tax,
                      taxRate: calculateModuleSkipStolen[i]?.taxRate
                    }
                    getpaymentstate?.amountDue?.skipStolenDetails.push(skipStolenObj)
                  }
  
                }
              }
          }
        // }

        if (getpaymentstate?.amountDue?.amountDueResponse) {
          getpaymentstate.amountDue.amountDueResponse =
            amountDueResponseCalculateModule;
        }
      }
    }

    //

    //

    let chargeoffArray: any = [];
    if (
      getpaymentstate != undefined &&
      getpaymentstate?.amountDue != undefined &&
      getpaymentstate?.amountDue?.skipStolenDetails != undefined &&
      getpaymentstate?.amountDue?.skipStolenDetails.length != 0
    ) {
      let chargeOffDetails =
        getpaymentstate?.amountDue?.skipStolenDetails.filter(
          (x) =>
            x.storeNumber ==
            String(window.sessionStorage.getItem("storeNumber"))
        );
      if (chargeOffDetails.length > 0) {
        for (let i = 0; i < chargeOffDetails.length; i++) {
          //  let tax=Number(chargeOffDetails[i].remainingEpoAmount!=undefined&&chargeOffDetails[i].remainingEpoAmount!=null?chargeOffDetails[i].remainingEpoAmount:0)*
          //          Number(chargeOffDetails[i].taxRate!=undefined&&chargeOffDetails[i].taxRate!=null?chargeOffDetails[i].taxRate:0)
          let EPOamountWithtax = (
            Number(
              chargeOffDetails[i].remainingEpoAmount != undefined &&
                chargeOffDetails[i].remainingEpoAmount != null
                ? chargeOffDetails[i].remainingEpoAmount
                : 0
            ) +
            Number(
              chargeOffDetails[i].remainingEpoTax != undefined &&
                chargeOffDetails[i].remainingEpoTax != null
                ? chargeOffDetails[i].remainingEpoTax
                : 0
            )
          ).toFixed(2);

          let details = {
            AgreementNo:
              chargeOffDetails[i].agreementNumber != undefined &&
              chargeOffDetails[i].agreementNumber != null
                ? chargeOffDetails[i].agreementNumber
                : "",
            AgreementID:
              chargeOffDetails[i].agreementId != undefined &&
              chargeOffDetails[i].agreementId != null
                ? chargeOffDetails[i].agreementId
                : "",
            CloseDate:
              chargeOffDetails[i].closeDate != undefined &&
              chargeOffDetails[i].closeDate != null
                ? moment(chargeOffDetails[i].closeDate).format("MM/DD/YYYY")
                : "",
            EPOamountOwed:
              chargeOffDetails[i].epoAmountOwned != undefined &&
              chargeOffDetails[i].epoAmountOwned != null
                ? chargeOffDetails[i].epoAmountOwned
                : 0,
            EPOamountRemaining:
              chargeOffDetails[i].remainingEpoAmount != undefined &&
              chargeOffDetails[i].remainingEpoAmount != null
                ? chargeOffDetails[i].remainingEpoAmount
                : 0,
            EPOamountWithtax: EPOamountWithtax,
            remainingEpoTax:
              chargeOffDetails[i].remainingEpoTax != undefined &&
              chargeOffDetails[i].remainingEpoTax != null
                ? chargeOffDetails[i].remainingEpoTax
                : 0,
            Amount: "0.00",
            Reason: "select",
            ReasonRefcode: "select",
            taxRate: chargeOffDetails[i].taxRate,
          };
          if (
            details.EPOamountRemaining != "" &&
            details.EPOamountRemaining != 0 &&
            details.EPOamountRemaining != "0.00" &&
            details.EPOamountOwed != 0 &&
            details.EPOamountOwed != ""
          ) {
            chargeoffArray.push(details);
          }
        }
        setchargeoffInfo(chargeoffArray);
      }
    }

    if (
      getpaymentstate?.paymentInfo?.storeInfo?.transferAgreementStatus === 1
    ) {
      setIsPendingAgrTransfer(true);
    }
    if (getpaymentstate?.paymentInfo?.storeInfo?.length > 0) {
      storeInfo = getpaymentstate?.paymentInfo?.storeInfo.filter(
        (x) =>
          x.storeNumber == String(window.sessionStorage.getItem("storeNumber"))
      );
      stateProvinceName = storeInfo[0]?.stateProvinceName;
      storeCityName = storeInfo[0]?.city;
    }
    setStateProvinceName(stateProvinceName);
    setStoreCityName(storeCityName);
    if (storeInfo.length) {
      if (storeInfo[0].isDonationEnabled !== undefined
        &&
        storeInfo[0].donationType !== undefined
        &&
        storeInfo[0].donationDesc !== undefined
        &&
        storeInfo[0].isDonationEnabled !== null
        &&
        storeInfo[0].donationType !== null &&
        storeInfo[0].donationDesc !== null
      ) {
        let donationObj = {
          isDonationEnabled: storeInfo[0].isDonationEnabled == 'Y' ? true : storeInfo[0].isDonationEnabled == 'N' ? false : false,
          donationType: storeInfo[0].donationType,
          donationDesc: storeInfo[0].donationDesc
        }
        setDonationType(donationObj)
      }
    }

    if (
      getpaymentstate.status !== undefined &&
      getpaymentstate.status !== 200
    ) {
      setbindGridCheck(true);
      customerName = "-";
      setmainGridloader(false);
      setPayGridPayloadLoad(false)
      setgetpayment(undefined);
      localGetPayment = undefined;
    } else {
      let getpaid: AllPaymentInfo = getpaymentstate;
      setgetpayment(getpaymentstate);
      localGetPayment = getpaymentstate;

      let diffStoreCount = 0;
      let storeNumber = String(window.sessionStorage.getItem("storeNumber"));
      if (getpaymentstate) {
        getpaymentstate.paymentInfo.paymentInfo.map((obj) => {
          if (String(obj.storeNumber) != String(storeNumber)) {
            diffStoreCount = diffStoreCount + 1;
          }
        });
        getpaymentstate.paymentInfo.clubInformation.map((obj) => {
          if (String(obj.storeNumber) != String(storeNumber)) {
            diffStoreCount = diffStoreCount + 1;
          }
        })
      }
      if (diffStoreCount == 0) {
        diffStoreErrLbl = false;
      } else {
        diffStoreErrLbl = true;
      }

      if (
        getpaymentstate?.commitmentDetails !== "" &&
        getpaymentstate?.commitmentDetails !== undefined
      ) {
        const isCommitmentInSameStoreBoolean =
          getpaymentstate.commitmentDetails.commitments.every(
            (el) =>
              el.storeNumber ==
              String(window.sessionStorage.getItem("storeNumber"))
          );
        setIsCommitmentInOtherStore(!isCommitmentInSameStoreBoolean);
        // setCommitmentAmount(getpaymentstate.commitmentDetails?.commitments[0]?.commitmentAmount);
        const basicFilter =
          getpaymentstate.commitmentDetails.commitments.filter(
            (obj) =>
              obj.createdSource == "RACDB" &&
              obj.storeNumber ==
                String(window.sessionStorage.getItem("storeNumber")) &&
              obj.commitmentStatusCode == "OP"
          );
          if(basicFilter?.length) 
            setIsCommitmentOpen(true);
          
        const collectBrokenCommitment =
          getpaymentstate.commitmentDetails.commitments.filter(
            (obj) =>
              obj.commitmentStatusCode == "BR" &&
              (obj?.lastPaymentDate == "" ||
              obj?.lastPaymentDate == null ||
              obj?.lastPaymentDate == undefined
                ? true
                : new Date(obj?.lastPaymentDate) <
                  new Date(
                    moment(obj.commitmentDate?.split("T")[0])?.format(
                      "YYYY-MM-DD"
                    )
                  ))
          );
        if (collectBrokenCommitment.length) {
          const dateTimeString = collectBrokenCommitment[0].commitmentDate;
          const dateTimeParts = dateTimeString.split("T");
          const date = moment(dateTimeParts[0]).format("MM/DD/YYYY");
          const twelveHourTime = new Date(dateTimeString).toLocaleTimeString(
            "en-US",
            { hour: "numeric", minute: "numeric", hour12: true }
          );
          setBrknCmtMessage(`Broken Commitment - ${date} ${twelveHourTime}`);
          setIsCommitmentBroken(true);
        }
        setCommitmentPayTringle(basicFilter[0]);
        setTakeCommitmentData(basicFilter[0]);
        let filteredClubData: any = [];
        let filteredAgreementData: any = [];
        let additionalData: any = [];
        if (basicFilter.length && basicFilter[0].commitmentDetail !== null) {
          filteredClubData = basicFilter[0].commitmentDetail.filter(
            (obj) => Number(obj.contractTypeId) == 2
          );
          filteredAgreementData = basicFilter[0].commitmentDetail.filter(
            (obj) =>
              (Number(obj.contractTypeId) == 1 ||
                Number(obj.contractTypeId) == 3) &&
              obj.commitmentTransRefCode !== "WOS" &&
              obj.commitmentTransRefCode !== "AEPDA" &&
              obj.commitmentTransRefCode !== "AEA" &&
              obj.commitmentTransRefCode !== "TAS"
          );
          additionalData = basicFilter[0].commitmentDetail.filter(
            (obj) =>
              obj.commitmentTransRefCode === "WOS" ||
              obj.commitmentTransRefCode === "AEPDA" ||
              obj.commitmentTransRefCode === "AEA" ||
              obj.commitmentTransRefCode === "TAS"
          );
        }
        let commitmentStruture: any = [];
        let CommitmentClubAgreementStructure: any = [];
        if (filteredClubData.length > 0) {
          filteredClubData.map((clubObj, clubIndex) => {
            if (clubIndex === 0) {
              CommitmentClubAgreementStructure.push({
                agreementID: clubObj.contractId,
                adjustedInfo: [
                  {
                    type: clubObj.commitmentTransRefCode,
                    value: clubObj.commitmentTransValue,
                    priority:
                      clubObj.commitmentTransRefCode == "ES"
                        ? 1
                        : clubObj.commitmentTransRefCode == "DDA"
                        ? 2
                        : clubObj.commitmentTransRefCode == "DC"
                        ? 3
                        : null,
                  },
                ],
              });
            } else {
              const isAgreementThere =
                CommitmentClubAgreementStructure.findIndex(
                  (el) => el.agreementID == clubObj.contractId
                );
              CommitmentClubAgreementStructure[
                isAgreementThere
              ].adjustedInfo.push({
                type: clubObj.commitmentTransRefCode,
                value: clubObj.commitmentTransValue,
                priority:
                  clubObj.commitmentTransRefCode == "ES"
                    ? 1
                    : clubObj.commitmentTransRefCode == "DDA"
                    ? 2
                    : clubObj.commitmentTransRefCode == "DC"
                    ? 3
                    : null,
              });
            }
          });
        }
        if (filteredAgreementData.length) {
          filteredAgreementData.map((obj, index) => {
            if (index === 0) {
              if (obj.commitmentTransRefCode === "FTT") {
                const waiveFilter = additionalData.filter(
                  (obj) => obj.commitmentTransRefCode === "WOS"
                );
                const dueDateFilter = filteredAgreementData.filter(
                  (objDueDate) =>
                    objDueDate.commitmentTransRefCode === "DDA" &&
                    Number(objDueDate.contractId) == Number(obj.contractId)
                );

                commitmentStruture.push({
                  agreementID: obj.contractId,
                  adjustedInfo: [
                    {
                      type: obj.commitmentTransRefCode,
                      value: {
                        nextDueDate: dueDateFilter[0].commitmentTransValue,
                        freeTimeType: obj.commitmentTransValue,
                        waiveOptService:
                          waiveFilter[0].commitmentTransValue == "True" ||
                          waiveFilter[0].commitmentTransValue == "true"
                            ? true
                            : false,
                      },
                      priority: 4,
                    },
                  ],
                });
              } else if (obj.commitmentTransRefCode === "AEF") {
                const pastDueAmountFilter = additionalData.filter(
                  (obj) => obj.commitmentTransRefCode === "AEPDA"
                );
                const acceptEpoAmountFilter = additionalData.filter(
                  (obj) => obj.commitmentTransRefCode === "AEA"
                );
                commitmentStruture.push({
                  agreementID: obj.contractId,
                  adjustedInfo: [
                    {
                      type: obj.commitmentTransRefCode,
                      value: {
                        pastDueRent:
                          pastDueAmountFilter[0].commitmentTransValue,
                        epoAmount:
                          acceptEpoAmountFilter[0].commitmentTransValue,
                      },
                      priority: 3,
                    },
                  ],
                });
              } else if (obj.commitmentTransRefCode === "TUS") {
                const transferAddFilter = additionalData.filter(
                  (obj) => obj.commitmentTransRefCode === "TAS"
                );
                let transferData: any = [];
                transferAddFilter.map((transObj) => {
                  transferData.push({
                    agreementID: transObj.contractId,
                    transferType: transObj.commitmentTransRefCode,
                    value: transObj.commitmentTransValue,
                  });
                });
                commitmentStruture.push({
                  agreementID: obj.contractId,
                  adjustedInfo: [
                    {
                      type: obj.commitmentTransRefCode,
                      value: [
                        ...transferData,
                        {
                          agreementID: obj.contractId,
                          transferType: obj.commitmentTransRefCode,
                          value: obj.commitmentTransValue,
                        },
                      ],
                      priority: 5,
                    },
                  ],
                });
              } else if (obj.commitmentTransRefCode === "DDA") {
                const isFTTAvailable = filteredAgreementData.some(
                  (objFTT) =>
                    Number(objFTT.contractId) === Number(obj.contractId) &&
                    objFTT.commitmentTransRefCode == "FTT"
                );
                if (!isFTTAvailable) {
                  commitmentStruture.push({
                    agreementID: obj.contractId,
                    adjustedInfo: [
                      {
                        type: obj.commitmentTransRefCode,
                        value: obj.commitmentTransValue,
                        priority: 4,
                      },
                    ],
                  });
                }
              } else if(obj.commitmentTransRefCode === "LFC"){
                commitmentStruture.push({
                  agreementID: obj.contractId,
                  adjustedInfo: [
                    {
                      type: obj.commitmentTransRefCode,
                      value: obj.commitmentTransValue,
                      priority: 1,
                    },
                  ],
                });

              }
              else if(obj.commitmentTransRefCode === "LFW"){
                commitmentStruture.push({
                  agreementID: obj.contractId,
                  adjustedInfo: [
                    {
                      type: obj.commitmentTransRefCode,
                      value: obj.commitmentTransValue,
                      priority: 1,
                    },
                  ],
                });

              } else {
                commitmentStruture.push({
                  agreementID: obj.contractId,
                  adjustedInfo: [
                    {
                      type: obj.commitmentTransRefCode,
                      value: obj.commitmentTransValue,
                      priority:
                        obj.commitmentTransRefCode == "TOS" ||
                        obj.commitmentTransRefCode == "AOS" ||
                        obj.commitmentTransRefCode == "LFW" ||
                        obj.commitmentTransRefCode == "LFC" 
                          ? 1
                          : obj.commitmentTransRefCode == "ES"
                          ? 2
                          : obj.commitmentTransRefCode == "AEF"
                          ? 3
                          : obj.commitmentTransRefCode == "FTT" ||
                            obj.commitmentTransRefCode == "DDA"
                          ? 4
                          : obj.commitmentTransRefCode == "AS" ||
                            obj.commitmentTransRefCode == "US" ||
                            obj.commitmentTransRefCode == "TAS" ||
                            obj.commitmentTransRefCode == "TUS"
                          ? 5
                          : null,
                    },
                  ],
                });
              }
            } else {
              const isAgreementThere = commitmentStruture.findIndex(
                (el) => el.agreementID == obj.contractId
              );
              if (isAgreementThere >= 0) {
                if (obj.commitmentTransRefCode === "FTT") {
                  const waiveFilter = additionalData.filter(
                    (obj) => obj.commitmentTransRefCode === "WOS"
                  );
                  const dueDateFilter = filteredAgreementData.filter(
                    (objDueDate) =>
                      objDueDate.commitmentTransRefCode === "DDA" &&
                      Number(objDueDate.contractId) == Number(obj.contractId)
                  );
                  commitmentStruture[isAgreementThere].adjustedInfo.push({
                    type: obj.commitmentTransRefCode,
                    value: {
                      nextDueDate: dueDateFilter[0].commitmentTransValue,
                      freeTimeType: obj.commitmentTransValue,
                      waiveOptService: waiveFilter[0].commitmentTransValue,
                    },
                    priority: 4,
                  });
                } else if (obj.commitmentTransRefCode === "AEF") {
                  const pastDueAmountFilter = additionalData.filter(
                    (obj) => obj.commitmentTransRefCode === "AEPDA"
                  );
                  const acceptEpoAmountFilter = additionalData.filter(
                    (obj) => obj.commitmentTransRefCode === "AEA"
                  );
                  commitmentStruture[isAgreementThere].adjustedInfo.push({
                    type: obj.commitmentTransRefCode,
                    value: {
                      pastDueRent: pastDueAmountFilter[0].commitmentTransValue,
                      epoAmount: acceptEpoAmountFilter[0].commitmentTransValue,
                    },
                    priority: 3,
                  });
                } else if (obj.commitmentTransRefCode === "DDA") {
                  const isFTTAvailable = filteredAgreementData.some(
                    (objFTT) =>
                      Number(objFTT.contractId) === Number(obj.contractId) &&
                      objFTT.commitmentTransRefCode == "FTT"
                  );
                  if (!isFTTAvailable) {
                    commitmentStruture[isAgreementThere].adjustedInfo.push({
                      type: obj.commitmentTransRefCode,
                      value: obj.commitmentTransValue,
                      priority: 4,
                    });
                  }
                } else {
                  commitmentStruture[isAgreementThere].adjustedInfo.push({
                    type: obj.commitmentTransRefCode,
                    value: obj.commitmentTransValue,
                    priority:
                      obj.commitmentTransRefCode == "TOS" ||
                      obj.commitmentTransRefCode == "AOS"
                        ? 1
                        : obj.commitmentTransRefCode == "ES"
                        ? 2
                        : obj.commitmentTransRefCode == "AEF"
                        ? 3
                        : obj.commitmentTransRefCode == "FTT" ||
                          obj.commitmentTransRefCode == "DDA"
                        ? 4
                        : obj.commitmentTransRefCode == "AS" ||
                          obj.commitmentTransRefCode == "US" ||
                          obj.commitmentTransRefCode == "TAS" ||
                          obj.commitmentTransRefCode == "TUS"
                        ? 5
                        : null,
                  });
                }
              } else {
                if (obj.commitmentTransRefCode === "FTT") {
                  const waiveFilter = additionalData.filter(
                    (obj) => obj.commitmentTransRefCode === "WOS"
                  );
                  const dueDateFilter = filteredAgreementData.filter(
                    (objDueDate) =>
                      objDueDate.commitmentTransRefCode === "DDA" &&
                      Number(objDueDate.contractId) == Number(obj.contractId)
                  );
                  commitmentStruture.push({
                    agreementID: obj.contractId,
                    adjustedInfo: [
                      {
                        type: obj.commitmentTransRefCode,
                        value: {
                          nextDueDate: dueDateFilter[0].commitmentTransValue,
                          freeTimeType: obj.commitmentTransValue,
                          waiveOptService: waiveFilter[0].commitmentTransValue,
                        },
                        priority: 4,
                      },
                    ],
                  });
                } else if (obj.commitmentTransRefCode === "AEF") {
                  const pastDueAmountFilter = additionalData.filter(
                    (obj) => obj.commitmentTransRefCode === "AEPDA"
                  );
                  const acceptEpoAmountFilter = additionalData.filter(
                    (obj) => obj.commitmentTransRefCode === "AEA"
                  );
                  commitmentStruture.push({
                    agreementID: obj.contractId,
                    adjustedInfo: [
                      {
                        type: obj.commitmentTransRefCode,
                        value: {
                          pastDueRent:
                            pastDueAmountFilter[0].commitmentTransValue,
                          epoAmount:
                            acceptEpoAmountFilter[0].commitmentTransValue,
                        },
                        priority: 3,
                      },
                    ],
                  });
                } else if (obj.commitmentTransRefCode === "TUS") {
                  const transferAddFilter = additionalData.filter(
                    (obj) => obj.commitmentTransRefCode === "TAS"
                  );
                  let transferData: any = [];
                  transferAddFilter.map((transObj) => {
                    transferData.push({
                      agreementID: transObj.contractId,
                      transferType: transObj.commitmentTransRefCode,
                      value: transObj.commitmentTransValue,
                    });
                  });
                  commitmentStruture.push({
                    agreementID: obj.contractId,
                    adjustedInfo: [
                      {
                        type: obj.commitmentTransRefCode,
                        value: [
                          ...transferData,
                          {
                            agreementID: obj.contractId,
                            transferType: obj.commitmentTransRefCode,
                            value: obj.commitmentTransValue,
                          },
                        ],
                      },
                    ],
                  });
                } else if (obj.commitmentTransRefCode === "DDA") {
                  const isFTTAvailable = filteredAgreementData.some(
                    (objFTT) =>
                      Number(objFTT.contractId) === Number(obj.contractId) &&
                      objFTT.commitmentTransRefCode == "FTT"
                  );
                  if (!isFTTAvailable) {
                    commitmentStruture.push({
                      agreementID: obj.contractId,
                      adjustedInfo: [
                        {
                          type: obj.commitmentTransRefCode,
                          value: obj.commitmentTransValue,
                          priority: 4,
                        },
                      ],
                    });
                  }
                } else if(obj.commitmentTransRefCode === "LFC"){
                  commitmentStruture.push({
                    agreementID: obj.contractId,
                    adjustedInfo: [
                      {
                        type: obj.commitmentTransRefCode,
                        value: obj.commitmentTransValue,
                        priority: 1,
                      },
                    ],
                  });

                }
                else if(obj.commitmentTransRefCode === "LFW"){
                  commitmentStruture.push({
                    agreementID: obj.contractId,
                    adjustedInfo: [
                      {
                        type: obj.commitmentTransRefCode,
                        value: obj.commitmentTransValue,
                        priority: 1,
                      },
                    ],
                  });

                } else {
                  commitmentStruture.push({
                    agreementID: obj.contractId,
                    adjustedInfo: [
                      {
                        type: obj.commitmentTransRefCode,
                        value: obj.commitmentTransValue,
                        priority:
                          obj.commitmentTransRefCode == "TOS" ||
                          obj.commitmentTransRefCode == "AOS" ||
                          obj.commitmentTransRefCode == "LFW" ||
                          obj.commitmentTransRefCode == "LFC" 
                            ? 1
                            : obj.commitmentTransRefCode == "ES"
                            ? 2
                            : obj.commitmentTransRefCode == "AEF"
                            ? 3
                            : obj.commitmentTransRefCode == "FTT" ||
                              obj.commitmentTransRefCode == "DDA"
                            ? 4
                            : obj.commitmentTransRefCode == "AS" ||
                              obj.commitmentTransRefCode == "US" ||
                              obj.commitmentTransRefCode == "TAS" ||
                              obj.commitmentTransRefCode == "TUS"
                            ? 5
                            : null,
                      },
                    ],
                  });
                }
              }
            }
          });
        }

        adjustCommitmentData = {
          commitmentDetail: commitmentStruture,
          CommitmentClubAgreement:
            CommitmentClubAgreementStructure.length > 0
              ? CommitmentClubAgreementStructure
              : null,
        };
      }
      localGetPayData = getpaymentstate;
      setaval_det(getpaymentstate);
      if (getpaid !== undefined) {
        if (
          getpaid.paymentInfo.remainingCredits !== undefined &&
          getpaid.paymentInfo.remainingCredits.length > 0
        ) {
          let rcInfo = getpaid.paymentInfo.remainingCredits;
          let rcBRData = rcInfo.filter(
            (x: any) =>
              x.bucket.toLowerCase() === "back rent" &&
              x.storeNumber == window.sessionStorage.getItem("storeNumber")
          );
          // debugger;
          if (rcBRData != undefined && rcBRData.length > 0) {
            for (let i = 0; i < rcBRData.length; i++) {
              backRentAmount =
                Number(backRentAmount) + Number(rcBRData[i].accountBalance);
              backRentValues.backRentOwed = backRentAmount;
              backRentValues.amount = backRentAmount;
            }
            let salesTax =
              Number(backRentValues.amount) * Number(backRentValues.taxRate);
            let total = Number(backRentValues.amount) + Number(salesTax);
            setBackRentInfo({
              ...backRentInfo,
              backRentOwed: Number(backRentAmount).toFixed(2),
              amount: Number(backRentAmount).toFixed(2),
              salesTax: Number(salesTax).toFixed(2),
              total: Number(total).toFixed(2),
              taxRate: backRentValues.taxRate,
            });
          }
        }

        if (getpaid.amountDue.amountDueResponse.length > 0) {
          // if (
          //   getpaid?.paymentInfo?.customerInfo[0]?.zip == null ||
          //   String(getpaid?.paymentInfo?.customerInfo[0]?.zip) == "" ||
          //   String(getpaid?.paymentInfo?.customerInfo[0]?.zip).length != 5
          // ) {
          //   setcustomerzipcodenull(true);
          // }

          for (let i = 0; i < getpaid.paymentInfo.paymentInfo.length; i++) {
            let objAgreementType = getpaid.amountDue.amountDueResponse.filter(
              (x) =>
                String(x.agreementId) ===
                String(getpaid.paymentInfo.paymentInfo[i].agreementId)
            );
            if (objAgreementType[0]?.agreementType != "Rent to Rent - Loaner") {
              let payinfoarray: paygrid = {
                epoCongratulations:false,
                epoAmountDuePayOff:false,
                suspenseUseRevRecog:0,
                storeMerchantId:"",
                Agreementid: 0,
                AgreementNumber: "",
                Agreementtype: "",
                Agreementdesc: "",
                Schedule: "",
                CurrentDueDate: "05/07/2000",
                Nextduedate: "05/07/2000",
                CurrentDaysLate: 0,
                Amount: 0,
                SAC_Date: "05/07/2000",
                SuspenseAmount: 0,
                EPOAmount: 0,
                Latelast10: 0,
                PromoFreeDays: 0,
                Autopay: "-",
                store_number: 0,
                number_of_paymentsmade: 0,
                sign_status: "",
                confirmeddeliversystatus: true,
                Taxamount: 0,
                Autopay_enrollmentdate: "05/07/2000",
                clubactive: true,
                Phonenumber: "+1465656776767665656",
                EmailAddress: "something@gmail.com",
                partyId: "54362464176",
                clubName: "ndskjfn",
                clubMembershipNumber: "sdfhuds",
                clubCoverageTermination: "",
                clubFlag: true,
                clublastLate10: 5432,
                cardLastFour: 5674,
                agrLdwAmount: 0,
                COA: 0,
                ipSuspense: 0,
                carriedLateFee: 0,
                wlDeposit: 0,
                htSuspense: 0,
                suspense: 0,
                racTirePay: 0,
                storeCityName: "",
                stateProvinceName: "",
                ldwTaxAmount: 0,
                rpayTaxAmount: 0,
                lFeeTaxAmount: 0,
                lateFee: 0,
                policyTaxAmount: 0,
                ldwPercentage: undefined,
                actualAmountDue: 0,
                convTax: configConvFeeTax,
                policyAmount: 0,
                deliveryAmount: 0,
                deliveryAmountTax: 0,
                noOfDaysFree: 0,
                extensionAmount: 0,
                mattressFee: 0,
                mattressFeeTax: 0,
                processingFee: 0,
                processingFeeTax: 0,
                taxGeoCode: "",
                zipPlus4UsedForTax: "",
                EPOTax: 0,
                dueDateModified: false,
                agreementRateDetails: null,
                taxRate: "",
                coverageEndDate: "",
                epoTax: "",
                policyStatus: "",
                editSchedule: "",
                agreementRate: "",
                carryRentAmountUsed: 0,
                carryRentTaxUsed: 0,
                carryRentAmountAdd: 0,
                miscellaneousItemsNum: 0,
                isLdwAllowed: "0",
                carryLdwAmountTax: 0,
                carryLdwAmount: 0,
                nineOneOneFee: 0,
                cellPhoneActivation: 0,
                cellPhoneActivationTax: 0,
                miscellaneousItemInfo: undefined,
                pastDueRent: 0,
                suspenseAdd: 0,
                PromoFreeDaysApplied: 0,
                FreeTime: null, // Added By Me
                isCOATransfered: false,
                isSwitchoutDeliveryPending: 0, // Added By Me
                suspenseSub: 0,
                actualEpoAmount: 0,
                editedEpoAmount: 0,
                actualPastDueRent: 0,
                acceptEpoFlag: 0,
                originAgreementId: null,
                clubDocumentSignStatus: null,
                transferOldSuspense: 0,
                carriedLate: 0,
                carriedLateTax: 0,
                agreementNoteDetails: {
                  noteAmountDueIncTax: "0.00",
                  remainingNoteCost: "0.00",
                  remainingAgreementCost: "0.00",
                  isNoteAgreement: "0",
                  NotAmountFinal: "0",
                  editedNoteAmount: "",
                  remainingNoteCostAmount: "0.00",
                },
                accepoEPoAgrnoteInfo: undefined,
                customerClubId: "",
                isClubDeactivateTriggered: false,
                lateFeeTaxRate:
                  lateFeeTaxRate[0]?.taxRate == undefined
                    ? 0
                    : lateFeeTaxRate[0]?.taxRate,
                isReturnAgrFlag: 0,
                constLateFee: 0,
                waivedLateFee: 0,
                initialPaymentFlag: 0,
                taxEngineData: null,
                storeMerchantID: null,
                isZeroPayment: false,
                otherIncomeType: "",
                isFinalTransferSusUseCancel: false,
                isFinalTransferSusUseApplied: false,
                isLdwCoverageAllowed:'',
                isPolicyCoverageAllowed:'',
                coverageStatus:'',
                toggleOptionalService:'',
                toggleOptionalServicePerformed:'',
                isAcceptEPODiscounts: {
                  epoDiscount: "0.00",
                  pastDueDiscount: "0.00",
                  noteDiscount: "0.00",
                },
                employeePurchasePlan: false,
                isExchanged: '0',
                parentAgreementid: '0',
                openDate: '',
                agreementStatus:''
              };
              recordsFound = recordsFound + 1;
              let objAgreementId =
                getpaid.paymentInfo.paymentInfo[i].agreementId;
              let objPromoFreeDays = getpaid.paymentInfo.paymentInfo.filter(
                (x) => x.agreementId === objAgreementId
              );
              let objcurrentdayslate =
                getpaid.paymentInfo.paymentHistoryInfo.filter(
                  (x) => String(x.agreementId) === String(objAgreementId)
                );
              let objepoamount = getpaid.amountDue.amountDueResponse.filter(
                (x) => x.agreementId === objAgreementId
              );
              let objsignstatus =
                getpaid.paymentInfo.documentSignStatus.length > 0
                  ? getpaid.paymentInfo.documentSignStatus.filter(
                      (x) => x.agreementId === objAgreementId
                    )
                  : [];
              let objNumberOfPaymentsMade =
                getpaid.paymentInfo.numberOfPaymentsMadeInfo.filter(
                  (x) => x.agreementId == objAgreementId
                );

              payinfoarray.number_of_paymentsmade =
                objNumberOfPaymentsMade !== undefined
                  ? objNumberOfPaymentsMade[0].noOfPaymentsMade
                  : 0;

              let objDeliveryFlag =
                getpaid.paymentInfo.deliveryInformation?.filter(
                  (x) => String(x.agreementId) === String(objAgreementId)
                );
              let objCustInfo: any = getpaid.paymentInfo.customerInfo[0];
              payinfoarray.Phonenumber =
                objCustInfo?.phoneNumber !== undefined &&
                objCustInfo?.phoneNumber !== null
                  ? objCustInfo?.phoneNumber.toString()
                  : "";
              payinfoarray.Agreementid =
                getpaid.paymentInfo.paymentInfo[i].agreementId;
              payinfoarray.Agreementtype = objepoamount[0].agreementType;
              payinfoarray.otherIncomeType = objepoamount[0].agreementType =="Promo"?"Promo":""
              payinfoarray.AgreementNumber = String(
                getpaid.paymentInfo.paymentInfo[i].agreementNumber
              );
              payinfoarray.Agreementdesc =
                getpaid.paymentInfo.paymentInfo[i].agreementDesc == null
                  ? ""
                  : getpaid.paymentInfo.paymentInfo[i].agreementDesc;
              payinfoarray.Schedule =
                getpaid.paymentInfo.paymentInfo[i]?.schedule;
              if (objcurrentdayslate[0] != undefined)
                payinfoarray.CurrentDaysLate =
                  objcurrentdayslate.length > 0 &&
                  objcurrentdayslate[0].noOfDaysLate !== null
                    ? objcurrentdayslate[0].noOfDaysLate
                    : 0;
              else payinfoarray.CurrentDaysLate = 0;
              let objamountdue = getpaid.amountDue.amountDueResponse.filter(
                (x) => x.agreementId === objAgreementId
              );

              //V2 new changes Muzzammil agreementRateDetails
              let agreementRateDetailsObj =
                getpaid.amountDue.amountDueResponse.filter(
                  (x) => x.agreementId === objAgreementId
                );
              payinfoarray.taxRate = agreementRateDetailsObj[0].taxRate;
              payinfoarray.agreementRateDetails =
              agreementRateDetailsObj[0].agreementRateDetails;
              payinfoarray.isLdwCoverageAllowed=agreementRateDetailsObj[0].agreementRateDetails.isLdwCoverageAllowed;
              payinfoarray.isPolicyCoverageAllowed=agreementRateDetailsObj[0].agreementRateDetails.isPolicyCoverageAllowed;
              payinfoarray.coverageStatus=agreementRateDetailsObj[0].agreementRateDetails.coverageStatus;
              payinfoarray.toggleOptionalService=agreementRateDetailsObj[0].agreementRateDetails.toggleOptionalService;
              payinfoarray.agreementRateDetails =
                agreementRateDetailsObj[0].agreementRateDetails;
              payinfoarray.carriedLate =
                Number(
                  agreementRateDetailsObj[0].agreementRateDetails?.carriedLate
                ) > 0
                  ? Number(
                      agreementRateDetailsObj[0].agreementRateDetails
                        ?.carriedLate
                    )
                  : 0;
              payinfoarray.carriedLateTax =
                Number(
                  agreementRateDetailsObj[0].agreementRateDetails
                    ?.carriedLateTax
                ) > 0
                  ? Number(
                      agreementRateDetailsObj[0].agreementRateDetails
                        ?.carriedLateTax
                    )
                  : 0;
              payinfoarray.coverageEndDate =
                agreementRateDetailsObj[0].coverageEndDate;
              payinfoarray.epoTax = agreementRateDetailsObj[0].epoTax;
              payinfoarray.policyStatus =
                agreementRateDetailsObj[0].policyStatus;
              payinfoarray.agreementRate = agreementRateDetailsObj[0].amountDue;
              payinfoarray.carryRentAmountUsed =
                agreementRateDetailsObj[0].agreementRateDetails.carryRent ==
                undefined
                  ? 0.0
                  : agreementRateDetailsObj[0].agreementRateDetails.carryRent;
              payinfoarray.carryRentTaxUsed =
                agreementRateDetailsObj[0].agreementRateDetails.carryRentTax ==
                undefined
                  ? 0.0
                  : agreementRateDetailsObj[0].agreementRateDetails
                      .carryRentTax;
              payinfoarray.isLdwAllowed =
                agreementRateDetailsObj[0].isLdwAllowed == undefined
                  ? "0"
                  : agreementRateDetailsObj[0].isLdwAllowed;
              //V2 new changes Muzzammil agreementRateDetails End
              let taxamount1 = Number(
                getpaid.taxTotal.filter(
                  (x) => x.agreementId === objAgreementId.toString()
                )[0].amount
              ).toFixed(2);
              payinfoarray.Taxamount =
                Number(taxamount1) +
                Number(payinfoarray.carryRentTaxUsed) +
                Number(payinfoarray.carriedLateTax) +
                Number(payinfoarray.carryLdwAmountTax);
              let ldwamountcalculation = "0.00";
              if (
                objamountdue[0].ldwLateRent !== undefined &&
                objamountdue[0].ldwLateRent !== null &&
                objamountdue[0].ldwLateRent !== "" &&
                objamountdue[0].ldwLateRent !== "0"
              ) {
                ldwamountcalculation = Number(
                  objamountdue[0].ldwLateRent
                ).toFixed(2);
              } else if (
                objamountdue[0].ldwAmount !== undefined &&
                objamountdue[0].ldwAmount !== null &&
                objamountdue[0].ldwAmount !== ""
              ) {
                ldwamountcalculation = Number(
                  objamountdue[0].ldwAmount
                ).toFixed(2);
              }
              if (objamountdue[0].extensionAmont != undefined) {
                payinfoarray.extensionAmount = Number(
                  objamountdue[0].extensionAmont
                );
                payinfoarray.noOfDaysFree = Number(objamountdue[0].freeDays);
              } else {
                payinfoarray.extensionAmount = 0;
                payinfoarray.noOfDaysFree = 0;
              }
              payinfoarray.ldwPercentage = Number(
                objamountdue[0].ldwPercentage
              );

              payinfoarray.taxGeoCode = String(objamountdue[0].taxGeoCode);
              payinfoarray.zipPlus4UsedForTax = String(
                objamountdue[0].zipPlus4UsedForTax
              );
              if (
                objamountdue[0].policyLateRent !== null &&
                objamountdue[0].policyLateRent !== undefined &&
                objamountdue[0].policyLateRent !== "" &&
                objamountdue[0].policyLateRent !== "0"
              ) {
                payinfoarray.policyTaxAmount = objamountdue[0].policyTaxAmount;
                payinfoarray.policyAmount = Number(
                  Number(objamountdue[0].policyLateRent).toFixed(2)
                );
              } else if (
                objamountdue[0].policyAmount !== null &&
                objamountdue[0].policyAmount !== undefined &&
                objamountdue[0].policyAmount !== ""
              ) {
                payinfoarray.policyTaxAmount = objamountdue[0].policyTaxAmount;
                payinfoarray.policyAmount = Number(
                  Number(objamountdue[0].policyAmount).toFixed(2)
                );
              }
              if (
                objamountdue[0].lateFee != 0 &&
                objcurrentdayslate[0] != undefined
              ) {
                payinfoarray.lateFee =
                  objcurrentdayslate[0].noOfDaysLate > 0
                    ? Number(Number(objamountdue[0].lateFee).toFixed(2))
                    : 0.0;
                payinfoarray.constLateFee =
                  objcurrentdayslate[0].noOfDaysLate > 0
                    ? Number(Number(objamountdue[0].lateFee).toFixed(2))
                    : 0.0;

                payinfoarray.lFeeTaxAmount = objamountdue[0].lFeeTaxAmount;
              }
              if (
                objamountdue[0].amountDueLateRent !== undefined &&
                objamountdue[0].amountDueLateRent !== null &&
                objamountdue[0].amountDueLateRent !== "" &&
                objamountdue[0].amountDueLateRent !== "0"
              ) {
                payinfoarray.Amount = Number(objamountdue[0].amountDueLateRent);
                payinfoarray.rpayTaxAmount = objamountdue[0].rpayTaxAmount;
                payinfoarray.pastDueRent = Number(objamountdue[0].pastDueRent);
                payinfoarray.actualPastDueRent = Number(
                  objamountdue[0].pastDueRent
                );
              } else {
                payinfoarray.Amount = Number(objamountdue[0].amountDue);
                payinfoarray.rpayTaxAmount = objamountdue[0].rpayTaxAmount;
              }
              if (objamountdue[0].deliveryAmount != undefined) {
                payinfoarray.deliveryAmount = Number(
                  objamountdue[0].deliveryAmount
                );
                payinfoarray.deliveryAmountTax = Number(
                  objamountdue[0].deliveryAmountTax
                );
              }

              if (objamountdue[0].mattressFee != undefined) {
                payinfoarray.mattressFee = Number(objamountdue[0].mattressFee);
                payinfoarray.mattressFeeTax = Number(
                  objamountdue[0].mattressFeeTax
                );
              }
              if (objamountdue[0].processingFee != undefined) {
                payinfoarray.processingFee = Number(
                  objamountdue[0].processingFee
                );
                payinfoarray.processingFeeTax = Number(
                  objamountdue[0].processingFeeTax
                );
              }

              payinfoarray.actualAmountDue = Number(
                payinfoarray.Amount + Number(payinfoarray.rpayTaxAmount)
              );
              payinfoarray.initialPaymentFlag = Number(
                objamountdue[0].initialPaymentFlag
              );
              if (objamountdue[0].initialPaymentFlag != 1) {
                payinfoarray.Amount =
                  Number(payinfoarray.Amount.toFixed(2)) +
                  Number(payinfoarray.lateFee) +
                  Number(payinfoarray.carriedLate) +
                  Number(payinfoarray.carriedLateTax) +
                  Number(taxamount1) +
                  Number(ldwamountcalculation) +
                  Number(payinfoarray.deliveryAmount) +
                  Number(payinfoarray.policyAmount) +
                  Number(payinfoarray.carryRentAmountUsed) +
                  Number(payinfoarray.carryRentTaxUsed);
                payinfoarray.Amount = Number(
                  AddTrailingZerosToAmount(payinfoarray.Amount)
                );
              } else {
                payinfoarray.Amount = Number(
                  (
                    Number(
                      Number(objamountdue[0].initialPaymentTotal).toFixed(2)
                    ) +
                    Number(payinfoarray.carryRentAmountUsed) +
                    Number(payinfoarray.carryRentTaxUsed)
                  ).toFixed(2)
                );
                payinfoarray.Amount = Number(
                  AddTrailingZerosToAmount(payinfoarray.Amount)
                );
              }

              if (objamountdue[0] != undefined) {
                setCardrequire({
                  ...Cardrequire,
                  ConvAmt: String(
                    Number(configConvFeeTax) + Number(configConvFeeWithOutTax)
                  ),
                });
                payinfoarray.convTax = Number(configConvFeeTax);
              }
              payinfoarray.Nextduedate = objamountdue[0].nextDueDate;
              payinfoarray.CurrentDueDate = objamountdue[0].pastDueDate;
              payinfoarray.employeePurchasePlan = getpaid.paymentInfo.paymentInfo[i].employeePurchasePlan;
              payinfoarray.openDate = getpaid.paymentInfo.paymentInfo[i].openDate;
              payinfoarray.isExchanged = parseInt(getpaid?.paymentInfo?.paymentInfo[i]?.isExchanged);
              payinfoarray.parentAgreementid = getpaid.paymentInfo.paymentInfo[i]?.parentAgreementId;
              payinfoarray.agreementStatus = agreementRateDetailsObj[0].agreementStatus;
              const clubFlag =
                getpaid.paymentInfo.clubInformation[0] == undefined
                  ? false
                  : getpaid.paymentInfo.clubInformation[0].clubFlag;
              if (clubFlag == true) {
                setclubState(0);
                payinfoarray.clubName =
                  getpaid.paymentInfo.clubInformation[0].clubName;
                payinfoarray.clubMembershipNumber =
                  getpaid.paymentInfo.clubInformation[0].clubMembershipNumber;
                payinfoarray.clubCoverageTermination =
                  getpaid.paymentInfo.clubInformation[0].clubCoverageTermination;
                payinfoarray.clublastLate10 =
                  getpaid.paymentInfo.clubInformation[0].clublastLate10;
                payinfoarray.cardLastFour = Number(
                  getpaid.paymentInfo.clubInformation[0].cardLastFour
                );
              }
              payinfoarray.originAgreementId =
                getpaid.paymentInfo.clubInformation[0]?.originAgreementId;
              payinfoarray.clubDocumentSignStatus =
                getpaid.paymentInfo.clubInformation[0]?.clubDocumentSignStatus;
              setclubState(1);
              payinfoarray.SAC_Date =
                getpaid.paymentInfo.paymentInfo[i].sacDate;
             // payinfoarray.SuspenseAmount = Number(
              //   getpaid.paymentInfo.paymentInfo[i].suspenseAmount
              // );
              payinfoarray.SuspenseAmount = Number(
                objamountdue[0]?.suspenseAmount
              );
              // payinfoarray.transferOldSuspense = Number(
              //   getpaid.paymentInfo.paymentInfo[i].suspenseAmount
              // );
              payinfoarray.transferOldSuspense = Number(
                objamountdue[0]?.suspenseAmount
              );
              let getNoteDetails = getpaid.amountDue.amountDueResponse.filter(
                (x) => x.agreementId === objAgreementId
              );
              if (getNoteDetails?.length > 0 && getNoteDetails != undefined) {
                payinfoarray.agreementNoteDetails.isNoteAgreement =
                  getNoteDetails[0].agreementRateDetails.isNoteAgreement;
                payinfoarray.agreementNoteDetails.remainingAgreementCost =
                  getNoteDetails[0].agreementRateDetails.remainingAgreementCost;
                payinfoarray.agreementNoteDetails.remainingNoteCost = Number(
                  getNoteDetails[0].agreementRateDetails.noteCost
                );
                payinfoarray.agreementNoteDetails.noteAmountDueIncTax =
                  Number(getNoteDetails[0].agreementRateDetails.noteCost) +
                  Number(getNoteDetails[0].agreementRateDetails.noteTax);
                payinfoarray.agreementNoteDetails.NotAmountFinal = Number(
                  getNoteDetails[0].agreementRateDetails.noteAmountIncTax
                );
                payinfoarray.agreementNoteDetails.remainingNoteCostAmount =
                  Number(
                    getNoteDetails[0].agreementRateDetails.remainingNoteCost
                  );

                // payinfoarray.EPOAmount=  payinfoarray.EPOAmount+Number(getNoteDetails[0].agreementRateDetails.noteCost)+Number(getNoteDetails[0].agreementRateDetails.noteTax)
              }
              payinfoarray.EPOAmount =
                objepoamount.length == 0
                  ? 0.0
                  : Number(objepoamount[0].epoAmount) +
                    Number(getpaid.paymentInfo.paymentInfo[i].suspenseAmount);
              payinfoarray.EPOTax =
                objepoamount.length == 0 ? 0.0 : Number(objepoamount[0].epoTax);
              payinfoarray.Latelast10 =
                getpaid.paymentInfo.paymentInfo[i].lastLate10;
              payinfoarray.PromoFreeDays =
                objPromoFreeDays.length > 0 &&
                objPromoFreeDays[0].promoFreeDays !== null
                  ? parseInt(objPromoFreeDays[0].promoFreeDays.toString())
                  : 0;
              payinfoarray.Autopay = String(
                getpaid.paymentInfo.paymentInfo[i].cardLastFour
              );
              payinfoarray.store_number =
                getpaid.paymentInfo.paymentInfo[i].storeNumber;
                let merchentIdPayload = getpaymentstate.paymentInfo.storeInfo?.filter(x=>x.storeNumber==String(getpaymentstate.paymentInfo.paymentInfo[i].storeNumber)) 
                payinfoarray.storeMerchantId=merchentIdPayload?.length>0?merchentIdPayload[0]?.merchantId:null
              payinfoarray.sign_status =
                objsignstatus.length == 0
                  ? "Not Signed"
                  : objsignstatus[0].documentSignStatus;
              payinfoarray.Autopay_enrollmentdate =
                getpaid.paymentInfo.paymentInfo[i].autopayEnrollmentDate;
              payinfoarray.number_of_paymentsmade =
                objNumberOfPaymentsMade.length > 0 &&
                objamountdue[0].initialPaymentFlag == 0
                  ? objNumberOfPaymentsMade[0].noOfPaymentsMade
                  : 0;
              payinfoarray.confirmeddeliversystatus =
                objDeliveryFlag.length > 0
                  ? objDeliveryFlag[0].deliveryFlag
                  : false;
              payinfoarray.agrLdwAmount = Number(ldwamountcalculation);
              payinfoarray.ldwTaxAmount = objamountdue[0].ldwTaxAmount;
              //Added rcData.accountbalance>0  condition to handle negative values in the available credits-Mano Ranjith
              let wlDepositDetail =
                getpaid.balance == undefined ? [] : getpaid.balance;
              let objBalanceDetails = wlDepositDetail.filter(
                (x) => x.balanceType == "WEBLEAD"
              );
              if (getpaymentstate?.paymentInfo?.storeInfo.length) {
                getpaymentstate?.paymentInfo?.storeInfo.map((el) => {
                  if (
                    el.storeNumber ==
                    getpaid.paymentInfo.paymentInfo[i].storeNumber
                  ) {
                    payinfoarray.storeMerchantID = el.merchantId;
                  }
                });
              }
              if (
                objBalanceDetails != undefined &&
                objBalanceDetails.length > 0
              ) {
                if (Number(objBalanceDetails[0].balanceAmount) > 0) {
                  payinfoarray.wlDeposit = objBalanceDetails[0].balanceAmount;
                } else {
                  payinfoarray.wlDeposit = 0.0;
                }
              }
              if (
                getpaid.paymentInfo.remainingCredits !== undefined &&
                getpaid.paymentInfo.remainingCredits.length > 0
              ) {
                let rcInfo = getpaid.paymentInfo.remainingCredits;
                let rcData = rcInfo.filter(
                  (x) => x.bucket.toLowerCase() === "coa"
                );
                if (
                  rcData != undefined &&
                  rcData.length > 0 &&
                  rcData[0].accountBalance > 0
                ) {
                  COAamount = rcData[0].accountBalance;
                  payinfoarray.COA = COAamount;
                }
                rcData = rcInfo.filter(
                  (x) => x.bucket.toLowerCase() === "suspense"
                );
                if (
                  rcData != undefined &&
                  rcData.length > 0 &&
                  rcData[0].accountBalance > 0
                ) {
                  payinfoarray.suspense = rcData[0].accountBalance;
                }

                rcData = rcInfo.filter(
                  (x) => x.bucket.toLowerCase() === "ht suspense"
                );
                if (
                  rcData != undefined &&
                  rcData.length > 0 &&
                  rcData[0].accountBalance > 0
                ) {
                  payinfoarray.htSuspense = rcData[0].accountBalance;
                }
                rcData = rcInfo.filter(
                  (x) => x.bucket.toLowerCase() === "ip suspense"
                );
                if (
                  rcData != undefined &&
                  rcData.length > 0 &&
                  rcData[0].accountBalance > 0
                ) {
                  payinfoarray.ipSuspense = rcData[0].accountBalance;
                }
              }
              payinfoarray.stateProvinceName =
                getpaid.paymentInfo.paymentInfo[i].stateProvinceName;
              payinfoarray.storeCityName =
                getpaid.paymentInfo.paymentInfo[i].storeCityName;
              //return Agr Code
              const urlParams = new URLSearchParams(window.location.search);
              //epo carried late ,carried rent
              payinfoarray.EPOAmount =
                Number(payinfoarray.EPOAmount) +
                Number(payinfoarray.carriedLate) +
                Number(payinfoarray.carriedLateTax) +
                Number(payinfoarray.carryRentAmountUsed) +
                Number(payinfoarray.carryRentTaxUsed);
              payinfoarray.EPOTax =
                Number(payinfoarray.EPOTax) +
                Number(payinfoarray.carriedLateTax) +
                Number(payinfoarray.carryRentTaxUsed);
              let returnAGRParamValue: any;
              let CPSAGRParamValue: any;

              if (configPermissions.commonModuleFlag == 1) {
                let calculatePaymentRes =
                  getpaymentstate?.amountDue?.amountDueResponse.filter(
                    (x) => x.agreementId == payinfoarray.Agreementid
                  );
                (payinfoarray.Amount = Number(
                  (
                    Number(calculatePaymentRes[0].totalAmount) +
                    Number(calculatePaymentRes[0].totalTax)
                  ).toFixed(2)
                )),
                  (payinfoarray.Taxamount = Number(
                    calculatePaymentRes[0].totalTax
                  ));
              }
              const returnType = urlParams.get("type");
              if (returnType == "RETURN" || returnType == "CHRGOFF") {
                returnAGRParamValue = urlParams.get("agreementId");
                CPSAGRParamValue = urlParams.get("cps");
              }
              if (
                returnAGRParamValue != "" &&
                returnAGRParamValue != null &&
                returnAGRParamValue != undefined &&
                Number(returnAGRParamValue) > 0
              ) {
                if (
                  String(payinfoarray.Agreementid) ==
                  String(returnAGRParamValue)
                ) {
                  payinfoarray.Amount = 0.0;
                  payinfoarray.Taxamount = 0.0;
                  payinfoarray.rpayTaxAmount = 0.0;
                  payinfoarray.agrLdwAmount = 0.0;
                  payinfoarray.lateFee = 0.0;
                  payinfoarray.lFeeTaxAmount = 0.0;
                  payinfoarray.ldwTaxAmount = 0.0;
                  payinfoarray.Nextduedate = payinfoarray.CurrentDueDate;
                  payinfoarray.suspenseSub = payinfoarray.SuspenseAmount;
                  payinfoarray.isReturnAgrFlag = 1;
                  if (String(CPSAGRParamValue) == "0") {
                    setradioBtn("1");
                    setCreditRadio(true);
                    setchangeRadio(false);
                  } else {
                    setradioBtn("0");
                    setCreditRadio(false);
                    setchangeRadio(true);
                  }
                }
              }
              const filteredAmtDueResponse: any =
                getpaid.amountDue.amountDueResponse.filter((obj) => {

                  return (
                    Number(obj.agreementId) ===
                    Number(getpaid.paymentInfo.paymentInfo[i].agreementId)
                  );
                }); // Newly Added
              if (filteredAmtDueResponse.length > 0) {
                payinfoarray.isSwitchoutDeliveryPending =
                  filteredAmtDueResponse[0].isSwitchoutDeliveryPending;
              } // Newly Added
              paygridvalues.push(payinfoarray);
              customerName =
                getpaid.paymentInfo.customerInfo[0].firstName +
                " " +
                getpaid.paymentInfo.customerInfo[0].lastName;

              let objAgrArray: AgrAmtTax = {
                agreementId: objAgreementId,
                totalAmount: Number(objamountdue[0].totalAmount),
                taxAmount: Number(objamountdue[0].totalTax),
              };
              agrAmtTaxArray.push(objAgrArray);
            }
          }
          //Filter the club values to check if it the associated with the Prelim Status Agreement and check 
          //CLUB status Active / pending
          let objclubAmountDuePrelimCheck:any = getpaid.amountDue.amountDueResponse.filter(
            (x) => x.clubAmountDue !== undefined
          );
          if (
            getpaid.paymentInfo.clubInformation.length > 0 &&
            getpaid.paymentInfo.clubInformation[0].clubFlag === true&&
            !(objclubAmountDuePrelimCheck[0]?.originAgreementIdStatus=='PRELIM'&&objclubAmountDuePrelimCheck[0]?.clubStatus=='P')

          ) {
            let payinfoarray1: paygrid = {
              storeMerchantId:"",
              AgreementNumber: "8748973573985",
              Agreementid: 0,
              Agreementtype: "",
              Agreementdesc: "",
              Schedule: "",
              CurrentDueDate: "05/07/2000",
              Nextduedate: "05/07/2000",
              CurrentDaysLate: 0,
              Amount: 0,
              SAC_Date: "05/07/2000",
              SuspenseAmount: 0,
              EPOAmount: 0,
              Latelast10: 0,
              PromoFreeDays: 0,
              Autopay: "-",
              store_number: 0,
              number_of_paymentsmade: 0,
              sign_status: "",
              confirmeddeliversystatus: true,
              Taxamount: 0,
              Autopay_enrollmentdate: "05/07/2000",
              clubactive: true,
              Phonenumber: "+1465656776767665656",
              EmailAddress: "something@gmail.com",
              partyId: "54362464176",
              clubName: "ndskjfn",
              clubMembershipNumber: "sdfhuds",
              clubCoverageTermination: "",
              clubFlag: true,
              clublastLate10: 5432,
              cardLastFour: 5674,
              agrLdwAmount: 0,
              COA: 0,
              ipSuspense: 0,
              carriedLateFee: 0,
              wlDeposit: 0,
              htSuspense: 0,
              suspense: 0,
              lateFee: 0,
              convTax: configConvFeeTax,
              racTirePay: 0,
              storeCityName: "",
              stateProvinceName: "",
              ldwPercentage: undefined,
              taxGeoCode: "",
              zipPlus4UsedForTax: "",
              EPOTax: 0,
              dueDateModified: false,
              agreementRateDetails: null,
              taxRate: "",
              coverageEndDate: "",
              epoTax: "",
              policyStatus: "",
              editSchedule: "",
              agreementRate: "",
              carryRentAmountUsed: 0,
              carryRentTaxUsed: 0,
              carryRentAmountAdd: 0,
              miscellaneousItemsNum: 0,
              isLdwAllowed: "0",
              carryLdwAmount: 0,
              carryLdwAmountTax: 0,
              nineOneOneFee: 0,
              cellPhoneActivation: 0,
              cellPhoneActivationTax: 0,
              miscellaneousItemInfo: undefined,
              PromoFreeDaysApplied: 0,
              FreeTime: null, // Added By Me
              isCOATransfered: false,
              isSwitchoutDeliveryPending: 0, // Added By Me
              suspenseSub: 0,
              actualEpoAmount: 0,
              actualPastDueRent: 0,
              acceptEpoFlag: 0,
              editedEpoAmount: 0,
              originAgreementId: 0,
              suspenseAdd: 0,
              clubDocumentSignStatus: 0,
              transferOldSuspense: 0,
              carriedLate: 0,
              carriedLateTax: 0,
              agreementNoteDetails: {
                noteAmountDueIncTax: "0.00",
                remainingNoteCost: "0.00",
                remainingAgreementCost: "0.00",
                isNoteAgreement: "0",
                NotAmountFinal: "0",
                editedNoteAmount: "",
                remainingNoteCostAmount: "0.00",
              },
              isLdwCoverageAllowed:'',
              isPolicyCoverageAllowed:'',
              coverageStatus:'',
              toggleOptionalService:'',
              toggleOptionalServicePerformed:"",
              accepoEPoAgrnoteInfo: undefined,
              customerClubId: "",
              isClubDeactivateTriggered: false,
              lateFeeTaxRate:
                lateFeeTaxRate[0]?.taxRate == undefined
                  ? 0
                  : lateFeeTaxRate[0]?.taxRate,
              isReturnAgrFlag: 0,
              constLateFee: 0,
              waivedLateFee: 0,
              initialPaymentFlag: 0,
              taxEngineData: null,
              storeMerchantID: null,
              isZeroPayment: false,
              otherIncomeType: "",
              isFinalTransferSusUseCancel: false,
              isFinalTransferSusUseApplied: false,
            };
            recordsFound = recordsFound + 1;
            window.sessionStorage.setItem("LDWaction", String(""));
            let objClubInfo = getpaid.paymentInfo.clubInformation[0];
            let objCustInfo = getpaid.paymentInfo.customerInfo[0];
            let objClubDaysLPAndPS =
              getpaid.paymentInfo.clubDaysLateAndPaymentSchedule[0];
            let objclubAmountDue = getpaid.amountDue.amountDueResponse.filter(
              (x) => x.clubAmountDue !== undefined
            );
            payinfoarray1.customerClubId =
              getpaid.paymentInfo?.clubInformation[0]?.customerClubId;
            payinfoarray1.AgreementNumber =
              objClubInfo.clubMembershipNumber !== undefined
                ? String(objClubInfo.clubMembershipNumber)
                : "0";
            payinfoarray1.Agreementid =
              objClubInfo.clubMembershipNumber !== undefined
                ? Number(objClubInfo.clubMembershipNumber)
                : 0;
            payinfoarray1.Agreementtype = "Club";
            payinfoarray1.Agreementdesc =
              objClubInfo.primaryCustomer == null
                ? objCustInfo.firstName + " " + objCustInfo.lastName
                : String(objClubInfo.primaryCustomer);
            payinfoarray1.Schedule =
              objClubDaysLPAndPS?.paymentSchedule !== undefined
                ? objClubDaysLPAndPS?.paymentSchedule
                : "";
            payinfoarray1.CurrentDueDate = objclubAmountDue[0].pastDueDate;
            payinfoarray1.taxRate = objclubAmountDue[0].taxRate;
            payinfoarray1.agreementRateDetails =
              objclubAmountDue[0].agreementRateDetails;
            payinfoarray1.coverageEndDate = objclubAmountDue[0].coverageEndDate;
            payinfoarray1.epoTax = objclubAmountDue[0].epoTax;
            payinfoarray1.policyStatus = objclubAmountDue[0].policyStatus;
            payinfoarray1.agreementRate = objclubAmountDue[0].clubAmountDue;
            payinfoarray1.isLdwAllowed =
              objclubAmountDue[0].isLdwAllowed == undefined
                ? "0"
                : objclubAmountDue[0].isLdwAllowed;
            //adjust due date Muzzammil  agreementRateDetails End
            payinfoarray1.Nextduedate = String(objclubAmountDue[0].nextDueDate);
            payinfoarray1.CurrentDaysLate =
              objClubDaysLPAndPS.noOfDaysLate !== null
                ? objClubDaysLPAndPS.noOfDaysLate
                : 0;
            let taxamount2 = Number(
              getpaid.taxTotal.filter((x) => x.agreementId === "Club")[0].amount
            );
            payinfoarray1.Taxamount = taxamount2;
            payinfoarray1.taxGeoCode = String(objclubAmountDue[0].taxGeoCode);
            payinfoarray1.zipPlus4UsedForTax = String(
              objclubAmountDue[0].zipPlus4UsedForTax
            );
            payinfoarray1.Amount =
              objclubAmountDue[0].clubAmountLateRent === null ||
              objclubAmountDue[0].clubAmountLateRent === undefined ||
              objclubAmountDue[0].clubAmountLateRent === "" ||
              objclubAmountDue[0].clubAmountLateRent === "0"
                ? Number(objclubAmountDue[0].clubAmountDue) + taxamount2
                : Number(objclubAmountDue[0].clubAmountLateRent) + taxamount2;
            payinfoarray1.Amount = Number(
              AddTrailingZerosToAmount(payinfoarray1.Amount)
            );
            payinfoarray1.SAC_Date = "-";
            payinfoarray1.SuspenseAmount = 0;
            payinfoarray1.EPOAmount = 0;
            payinfoarray1.Latelast10 = objClubInfo.clublastLate10;
            payinfoarray1.PromoFreeDays = 0;
            payinfoarray1.Autopay = String(
              objClubInfo.cardLastFour !== null ||
                objClubInfo.cardLastFour !== undefined ||
                objClubInfo.cardLastFour !== ""
                ? objClubInfo.cardLastFour
                : "0"
            );
            payinfoarray1.Autopay_enrollmentdate = String(
              getpaid.paymentInfo.clubInformation.length > 0 &&
                getpaid.paymentInfo.clubInformation[0].autopayEnrollmentDate !==
                  undefined &&
                getpaid.paymentInfo.clubInformation[0].autopayEnrollmentDate !==
                  "" &&
                getpaid.paymentInfo.clubInformation[0].autopayEnrollmentDate !==
                  null
                ? getpaid?.paymentInfo.clubInformation[0].autopayEnrollmentDate
                : ""
            );
            payinfoarray1.originAgreementId =
              getpaid.paymentInfo.clubInformation[0]?.originAgreementId;
            payinfoarray1.clubDocumentSignStatus =
              getpaid.paymentInfo.clubInformation[0]?.clubDocumentSignStatus;

            payinfoarray1.store_number =
              getpaid.paymentInfo.clubInformation[0].storeNumber == undefined
                ? 0
                : getpaid.paymentInfo.clubInformation[0].storeNumber;
            
            let merchentIdPayload = getpaymentstate.paymentInfo.storeInfo?.filter(x=>x.storeNumber==String(getpaymentstate.paymentInfo.clubInformation[0].storeNumber)) 
            payinfoarray1.storeMerchantId=merchentIdPayload?.length>0?merchentIdPayload[0]?.merchantId:null
            payinfoarray1.clubactive = true;
            payinfoarray1.Phonenumber =
              objCustInfo.phoneNumber !== undefined &&
              objCustInfo.phoneNumber !== null
                ? objCustInfo.phoneNumber.toString()
                : "";
            payinfoarray1.EmailAddress =
              objCustInfo.phoneNumber !== undefined
                ? objCustInfo.emailAddress
                : "";
            payinfoarray1.partyId =
              objCustInfo.entPartyId !== null
                ? objCustInfo.entPartyId.toString()
                : "";
            payinfoarray1.clubName =
              getpaid !== undefined ? objClubInfo.clubName : "";
            payinfoarray1.clubMembershipNumber =
              getpaid !== undefined ? objClubInfo.clubMembershipNumber : "";
            payinfoarray1.agrLdwAmount = 0;
            payinfoarray1.lateFee = objclubAmountDue[0].lateFee;
            payinfoarray1.constLateFee = objclubAmountDue[0].lateFee;

            if (configPermissions.commonModuleFlag == 1) {
              let calculatePaymentRes =
                getpaymentstate.amountDue.amountDueResponse.filter(
                  (x) => x.customerClubId == payinfoarray1.customerClubId
                );
              (payinfoarray1.Amount = Number(
                (
                  Number(calculatePaymentRes[0].totalAmount) +
                  Number(calculatePaymentRes[0].totalTax)
                ).toFixed(2)
              )),
                (payinfoarray1.Taxamount = Number(
                  calculatePaymentRes[0].totalTax
                ));
            }
            let wlDepositDetail =
              getpaid.balance == undefined ? [] : getpaid.balance;
            let objBalanceDetails = wlDepositDetail.filter(
              (x) => x.balanceType == "WEBLEAD"
            );
            if (getpaymentstate?.paymentInfo?.storeInfo.length) {
              getpaymentstate?.paymentInfo?.storeInfo.map((el) => {
                if (
                  el.storeNumber ==
                  getpaid.paymentInfo.clubInformation[0].storeNumber
                ) {
                  payinfoarray1.storeMerchantID = el.merchantId;
                }
              });
            }
            if (
              objBalanceDetails != undefined &&
              objBalanceDetails.length > 0
            ) {
              if (Number(objBalanceDetails[0].balanceAmount) > 0) {
                payinfoarray1.wlDeposit = objBalanceDetails[0].balanceAmount;
              } else {
                payinfoarray1.wlDeposit = 0.0;
              }
            }
            if (
              getpaid.paymentInfo.remainingCredits !== undefined &&
              getpaid.paymentInfo.remainingCredits.length > 0
            ) {
              let rcInfo = getpaid.paymentInfo.remainingCredits;
              let rcData = rcInfo.filter(
                (x) => x.bucket.toLowerCase() === "coa"
              );
              if (
                rcData != undefined &&
                rcData.length > 0 &&
                rcData[0].accountBalance > 0
              ) {
                COAamount = rcData[0].accountBalance;
                payinfoarray1.COA = COAamount;
              }
              rcData = rcInfo.filter(
                (x) => x.bucket.toLowerCase() === "suspense"
              );
              if (
                rcData != undefined &&
                rcData.length > 0 &&
                rcData[0].accountBalance > 0
              ) {
                payinfoarray1.suspense = rcData[0].accountBalance;
              }
              rcData = rcInfo.filter(
                (x) => x.bucket.toLowerCase() === "ht suspense"
              );
              if (
                rcData != undefined &&
                rcData.length > 0 &&
                rcData[0].accountBalance > 0
              ) {
                payinfoarray1.htSuspense = rcData[0].accountBalance;
              }
              rcData = rcInfo.filter(
                (x) => x.bucket.toLowerCase() === "ip suspense"
              );
              if (
                rcData != undefined &&
                rcData.length > 0 &&
                rcData[0].accountBalance > 0
              ) {
                payinfoarray1.ipSuspense = rcData[0].accountBalance;
              }
            }
            payinfoarray1.initialPaymentFlag = Number(
              objclubAmountDue[0].initialPaymentFlag
            );
            if (objclubAmountDue[0].initialPaymentFlag === 0) {
              payinfoarray1.number_of_paymentsmade = 1;
            }
            paygridvalues.push(payinfoarray1);

            let objAgrArray: AgrAmtTax = {
              agreementId:
                objClubInfo.clubMembershipNumber !== undefined
                  ? Number(objClubInfo.clubMembershipNumber)
                  : 0,
              totalAmount: Number(objclubAmountDue[0].totalAmount),
              taxAmount: Number(objclubAmountDue[0].totalTax),
            };
            agrAmtTaxArray.push(objAgrArray);
          }
        }

        truearrayforcheckbox = new Array(
          getpaid.paymentInfo.paymentInfo.length
        ).fill(true);
        falsearrayforcheckbox = new Array(
          getpaid.paymentInfo.paymentInfo.length
        ).fill(false);
        /*
         *This code is used for showing agreement of the store which logged in first.
         *Here We are getting the store number from the session storage.
         *The data we gonna bind in the grid are sorted based on the sort() method.
         *The club agreements gets filtered from the sorted array and it has been in a separate array Clubagreementinsertion.
         *Then the data or agreements of the currently logged in store has been filtered and also its also concatenated with the clubagreementinsertion array.
         *Then the other storedata also gets filtered and it also gets concatenated.
         *The entire concatenated array has been setstated in the paygridvalues.
         */
        let currentstorenumber = window.sessionStorage.getItem("storeNumber");
        let sortableData: any = [];
        if (resetInvoke == undefined) {
          sortableData = paygridvalues;
        } else {
          const sameStoreData = basicPayGridValues.filter(
            (el) =>
              el.store_number ==
              String(window.sessionStorage.getItem("storeNumber"))
          );
          const differentStoreData = basicPayGridValues.filter(
            (el) =>
              el.store_number !==
              String(window.sessionStorage.getItem("storeNumber"))
          );
          sortableData = [...sameStoreData, ...differentStoreData];
        }

        // let payAgrs = sortableData.sort((a: any, b: any) =>
        //   a["store_number"] < b["store_number"] ? -1 : 1
        // );
        let payAgrs = sortableData;
        let Clubagreementinsertion = payAgrs.filter(
          (x) => x.Agreementtype === "Club"
        );
        let curStoreData = payAgrs.filter(
          (x) =>
            x.store_number.toString() === currentstorenumber &&
            x.Agreementtype !== "Club"
        );
        Clubagreementinsertion.push(...curStoreData);
        let otherStoreData = payAgrs.filter(
          (x) =>
            x.store_number.toString() !== currentstorenumber &&
            x.Agreementtype !== "Club"
        );
        Clubagreementinsertion.push(...otherStoreData);
        // setpaygridvalues(Clubagreementinsertion);
        let coa = 0.0;
      let suspense = 0.0;
      let htSuspense = 0.0;
      let ipSuspense = 0.0;
      let wlDeposit = 0.0;

      if (
        getpaymentstate?.paymentInfo?.remainingCredits !== undefined &&
        getpaymentstate?.paymentInfo?.remainingCredits?.length > 0
      ) {
        let rcInfo = getpaymentstate?.paymentInfo?.remainingCredits;
        let rcData = rcInfo?.filter((x) => x?.bucket.toLowerCase() === "coa");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          COAamount = rcData[0]?.accountBalance;
          coa = Number(COAamount);
        }
        rcData = rcInfo?.filter((x) => x?.bucket.toLowerCase() === "suspense");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          suspense = Number(rcData[0]?.accountBalance);
        }

        rcData = rcInfo.filter(
          (x) => x?.bucket.toLowerCase() === "ht suspense"
        );
        if (
          rcData != undefined &&
          rcData?.length > 0 &&
          rcData[0]?.accountBalance > 0
        ) {
          htSuspense = Number(rcData[0]?.accountBalance);
        }
        rcData = rcInfo.filter(
          (x) => x?.bucket.toLowerCase() === "ip suspense"
        );
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          ipSuspense = Number(rcData[0].accountBalance);
        }
      }
      let wlDepositDetail: any =
      getpaymentstate?.balance == undefined ? [] : getpaymentstate?.balance;
      let objBalanceDetails: any = wlDepositDetail?.filter(
        (x: any) => x?.balanceType == "WEBLEAD"
      );
      if (objBalanceDetails != undefined && objBalanceDetails?.length > 0) {
        if (Number(objBalanceDetails[0]?.balanceAmount) > 0) {
          wlDeposit = Number(objBalanceDetails[0]?.balanceAmount);
        } else {
          wlDeposit = 0.0;
        }
      }
        let NSFgridValues: any = [];
        let CCCBgridvalues: any = [];
        let NSFDetails: any = getpaid?.amountDue?.otherIncomeDetails?.filter(
          (x) =>
            x.accountType == "NSF" &&
            String(x.storeNumber) ==
              String(window.sessionStorage.getItem("storeNumber"))
        );
        let CCCBDetails: any = getpaid?.amountDue?.otherIncomeDetails?.filter(
          (x) =>
            x.accountType == "CCCB" &&
            String(x.storeNumber) ==
              String(window.sessionStorage.getItem("storeNumber"))
        );
        if (Number(configPermissions?.isNSFPaymentsFlag) == 1 && resetInvoke == undefined) {
          for (let i = 0; i < NSFDetails?.length; i++) {
            let nsftax = Number(NSFDetails[i].feeTax);
            let nsfAmt = Number(NSFDetails[i].balanceFee);
            let nsfTotal = Number(
              (
                Number(NSFDetails[i].balanceAmount) +
                Number(
                  NSFDetails[i].feeTax == undefined ? 0 : NSFDetails[i].feeTax
                ) +
                Number(NSFDetails[i].balanceFee)
              ).toFixed(2)
            );
            let nsfFeeTaxRate=Number(NSFDetails[i].feeTaxRate)
            const nsfValues = {
              AgreementNumber: String(
                Number(NSFDetails[i].receiptTransactionId)
              ),
              Agreementid: String(Number(NSFDetails[i].receiptTransactionId)),
              Agreementdesc: "NSF Check Repayment",
              Agreementtype: "NSF Check",
              Amount: nsfTotal,
              Autopay: "null",
              nsfFee: nsfAmt,
              nsfData: NSFDetails[i],
              Autopay_enrollmentdate: null,
              COA: coa,
              CurrentDaysLate: 0,
              CurrentDueDate: "-",
              EPOAmount: 0,
              EPOTax: 0,
              EmailAddress: "",
              Latelast10: 0,
              Nextduedate: "-",
              Phonenumber: "",
              PromoFreeDays: 0,
              SAC_Date: "",
              Schedule: "",
              SuspenseAmount: 0,
              Taxamount: nsftax,
              actualAmountDue: nsfTotal,
              agrLdwAmount: 0,
              agreementRate: nsfAmt,
              agreementRateDetails: {},
              cardLastFour: 0,
              carriedLateFee: 0,
              carryRentAmountAdd: 0,
              carryRentAmountUsed: 0,
              carryRentTaxUsed: 0,
              clubCoverageTermination: null,
              clubFlag: true,
              clubMembershipNumber: "",
              clubName: "",
              clubactive: false,
              clublastLate10: 0,
              confirmeddeliversystatus: true,
              convTax: configFeeOnlyTax,
              coverageEndDate: "",
              deliveryAmount: 0,
              deliveryAmountTax: 0,
              dueDateModified: false,
              editSchedule: "",
              extensionAmount: 0,
              htSuspense: 0,
              ipSuspense: ipSuspense,
              lFeeTaxAmount: 0,
              suspenseAdd: 0,
              suspenseSub: 0,
              lateFee: 0,
              ldwPercentage: NaN,
              ldwTaxAmount: 0,
              mattressFee: 0,
              mattressFeeTax: 0,
              noOfDaysFree: NaN,
              number_of_paymentsmade: 0,
              partyId: "",
              isZeroPayment: false,
              policyAmount: 0,
              policyTaxAmount: 0,
              processingFee: 0,
              processingFeeTax: 0,
              racTirePay: 0,
              rpayTaxAmount: 0,
              sign_status: "",
              stateProvinceName:
                storeInfo[0]?.stateProvinceName != undefined
                  ? storeInfo[0]?.stateProvinceName
                  : "",
              //  configFlags.isPaymentFunctionsFeatureFlag == 1
              //    ? getpayment?.paymentInfo?.storeInfo?.stateProvinceName
              //    : "",
              storeCityName:
                storeInfo[0]?.city != undefined ? storeInfo[0]?.city : "",
              //  configFlags.isPaymentFunctionsFeatureFlag == 1
              //    ? getpayment?.paymentInfo?.storeInfo?.city
              //    : "",
              store_number: String(
                window.sessionStorage.getItem("storeNumber")
              ),
              suspense: 0,
              taxGeoCode: "",
              taxRate:nsfFeeTaxRate==undefined?"":nsfFeeTaxRate,
              wlDeposit: wlDeposit,
              zipPlus4UsedForTax: "",
              nineOneOneFee: 0,
              cellPhoneActivation: 0,
              cellPhoneActivationTax: 0,
              otherIncomeType: "NSF Check",
              // cellPhoneActivation: Number(res.salesPrice),
              // cellPhoneActivationTax: Number(salesTax),
              miscellaneousItemsNum: 0,
              miscellaneousItemInfo: 0,
              isLdwAllowed: "0",
              carryLdwAmountTax: 0,
              carryLdwAmount: 0,
              pastDueRent: 0,
              PromoFreeDaysApplied: 0,
              FreeTime: null, // Added By Me
              isCOATransfered: false,
              isSwitchoutDeliveryPending: 0, // Added By Me
              editedEpoAmount: 0,
              actualPastDueRent: 0,
              acceptEpoFlag: 0,
              // restitutiontypeAgree:1,
              originAgreementId: null,
              // restitutionInfo: res,
              clubDocumentSignStatus: null,

              transferOldSuspense: 0,

              agreementNoteDetails: {
                noteAmountDueIncTax: "0.00",
                remainingNoteCost: "0.00",
                remainingAgreementCost: "0.00",
                isNoteAgreement: "0",
                NotAmountFinal: "0",
                editedNoteAmount: "",
                remainingNoteCostAmount: "0.00",
              },
              accepoEPoAgrnoteInfo: undefined,
              customerClubId: "",
              isClubDeactivateTriggered: false,
              lateFeeTaxRate: 0,
              isReturnAgrFlag: 0,
              constLateFee: 0,
              waivedLateFee: 0,
              initialPaymentFlag: 0,
              taxEngineData: null,
              storeMerchantID: null,
            };
            recordsFound = recordsFound + 1;
            NSFgridValues.push(nsfValues);
            // paygridvalues.push(nsfValues)
          }
        }
        if (Number(configPermissions?.isCCCBPaymentsFlag) == 1 && resetInvoke == undefined) {
          for (let i = 0; i < CCCBDetails?.length; i++) {
            let CCCBAmount = Number(
              (
                Number(CCCBDetails[i]?.balanceAmount) +
                Number(
                  CCCBDetails[i]?.feeTax == undefined ? 0 : CCCBDetails[i]?.feeTax
                ) +
                Number(CCCBDetails[i]?.cccbFee == undefined ? 0 :CCCBDetails[i]?.cccbFee)
              ).toFixed(2)
            );
            // let CCCBAmount = Number(CCCBDetails[i].balanceAmount);
            const cccbValues = {
              AgreementNumber: String(CCCBDetails[i].receiptTransactionId),
              Agreementid: String(CCCBDetails[i].receiptTransactionId),
              Agreementdesc: "Credit Card Repayment",
              Agreementtype: "Credit Card Charge Back",
              Amount: CCCBAmount,
              Autopay: "null",
              CreditCardNumber: String(CCCBDetails[i].cardLastFour),
              chargeBackDate: String(CCCBDetails[i].createdDate),
              chargeAmount: CCCBAmount,
              cccbFee:CCCBDetails[i]?.cccbFee??0,
              chargeBackAmount: CCCBAmount,
              Autopay_enrollmentdate: null,
              COA: coa,
              cccbData: CCCBDetails[i],
              CurrentDaysLate: 0,
              CurrentDueDate: "-",
              EPOAmount: 0,
              EPOTax: 0,
              EmailAddress: "",
              Latelast10: 0,
              Nextduedate: "-",
              Phonenumber: "",
              PromoFreeDays: 0,
              SAC_Date: "",
              Schedule: "",
              SuspenseAmount: 0,
              Taxamount: Number(CCCBDetails[i]?.feeTax?? 0),
              actualAmountDue: CCCBAmount,
              agrLdwAmount: 0,
              agreementRate: CCCBAmount,
              agreementRateDetails: {},
              cardLastFour: 0,
              carriedLateFee: 0,
              carryRentAmountAdd: 0,
              carryRentAmountUsed: 0,
              carryRentTaxUsed: 0,
              clubCoverageTermination: null,
              clubFlag: true,
              clubMembershipNumber: "",
              clubName: "",
              clubactive: false,
              clublastLate10: 0,
              confirmeddeliversystatus: true,
              convTax: configFeeOnlyTax,
              coverageEndDate: "",
              deliveryAmount: 0,
              deliveryAmountTax: 0,
              dueDateModified: false,
              editSchedule: "",
              extensionAmount: 0,
              htSuspense: 0,
              ipSuspense: ipSuspense,
              lFeeTaxAmount: 0,
              suspenseAdd: 0,
              suspenseSub: 0,
              lateFee: 0,
              ldwPercentage: NaN,
              ldwTaxAmount: 0,
              mattressFee: 0,
              mattressFeeTax: 0,
              noOfDaysFree: NaN,
              number_of_paymentsmade: 0,
              partyId: "",
              policyAmount: 0,
              policyTaxAmount: 0,
              processingFee: 0,
              processingFeeTax: 0,
              racTirePay: 0,
              rpayTaxAmount: 0,
              sign_status: "",
              isZeroPayment: false,
              stateProvinceName:
                storeInfo[0]?.stateProvinceName != undefined
                  ? storeInfo[0]?.stateProvinceName
                  : "",
              //  configFlags.isPaymentFunctionsFeatureFlag == 1
              //    ? getpayment?.paymentInfo?.storeInfo?.stateProvinceName
              //    : "",
              storeCityName:
                storeInfo[0]?.city != undefined ? storeInfo[0]?.city : "",
              //  configFlags.isPaymentFunctionsFeatureFlag == 1
              //    ? getpayment?.paymentInfo?.storeInfo?.city
              //    : "",
              store_number: String(
                window.sessionStorage.getItem("storeNumber")
              ),
              suspense: 0,
              taxGeoCode: "",
              taxRate: "",
              wlDeposit: wlDeposit,
              zipPlus4UsedForTax: "",
              nineOneOneFee: 0,
              cellPhoneActivation: 0,
              cellPhoneActivationTax: 0,
              otherIncomeType: "Credit Card Charge Back",
              // cellPhoneActivation: Number(res.salesPrice),
              // cellPhoneActivationTax: Number(salesTax),
              miscellaneousItemsNum: 0,
              miscellaneousItemInfo: 0,
              isLdwAllowed: "0",
              carryLdwAmountTax: 0,
              carryLdwAmount: 0,
              pastDueRent: 0,
              PromoFreeDaysApplied: 0,
              FreeTime: null, // Added By Me
              isCOATransfered: false,
              isSwitchoutDeliveryPending: 0, // Added By Me
              editedEpoAmount: 0,
              actualPastDueRent: 0,
              acceptEpoFlag: 0,
              // restitutiontypeAgree:1,
              originAgreementId: null,
              // restitutionInfo: res,
              clubDocumentSignStatus: null,

              transferOldSuspense: 0,

              agreementNoteDetails: {
                noteAmountDueIncTax: "0.00",
                remainingNoteCost: "0.00",
                remainingAgreementCost: "0.00",
                isNoteAgreement: "0",
                NotAmountFinal: "0",
                editedNoteAmount: "",
                remainingNoteCostAmount: "0.00",
              },
              accepoEPoAgrnoteInfo: undefined,
              customerClubId: "",
              isClubDeactivateTriggered: false,
              lateFeeTaxRate: 0,
              isReturnAgrFlag: 0,
              constLateFee: 0,
              waivedLateFee: 0,
              initialPaymentFlag: 0,
              taxEngineData: null,
              storeMerchantID: null,
              isFinalTransferSusUseCancel: false,
              isFinalTransferSusUseApplied: false,
            };
            recordsFound = recordsFound + 1;
            CCCBgridvalues.push(cccbValues);
            // paygridvalues.push(cccbValues)
          }
        }
        let sameStoreDataRIS: any = [];
        let arr: any = [];
        if (
          risNo == 1 &&
          Risresponse != undefined &&
          Object.keys(Risresponse).length != 0
          && resetInvoke == undefined
        ) {
          obj.push(Risresponse);
          if (obj?.length >= 1) {
            for (let i = 0; i < obj?.length; i++) {
              arr.push(obj[i].inventoryNumber);
              const rispaygridvaluesNew = {
                AgreementNumber: obj[i].inventoryNumber,
                Agreementdesc: obj[i].inventoryDescription,
                Agreementid: obj[i].inventoryId,
                Agreementtype: "Rental Item Sale",
                Amount: Number(obj[i].totalsales),
                Autopay: "null",
                Autopay_enrollmentdate: null,
                COA: coa,
                CurrentDaysLate: 0,
                CurrentDueDate: "-",
                EPOAmount: 0,
                EPOTax: 0,
                actualEpoAmount: 0,
                EmailAddress: "",
                Latelast10: 0,
                Nextduedate: "-",
                Phonenumber: "",
                PromoFreeDays: 0,
                SAC_Date: "",
                Schedule: "-",
                SuspenseAmount: 0,
                Taxamount: Number(obj[i].salestax),
                // Number(salesTax)
                actualAmountDue: Number(obj[i].totalsales),
                agrLdwAmount: 0,
                agreementRate: obj[i].totalsales,
                agreementRateDetails: null,
                cardLastFour: 0,
                carriedLateFee: 0,
                carryRentAmountAdd: 0,
                carryRentAmountUsed: 0,
                carryRentTaxUsed: 0,
                clubCoverageTermination: null,
                clubFlag: true,
                clubMembershipNumber: "",
                clubName: "",
                clubactive: false,
                clublastLate10: 0,
                confirmeddeliversystatus: false,
                convTax: configFeeOnlyTax,
                coverageEndDate: "",
                deliveryAmount: Number(
                  obj[i]?.Deliveryfee == undefined ? 0 : obj[i]?.Deliveryfee
                ),
                risDeliveryfeeTax: Number(
                  obj[i].DeliverfeeTax == undefined ? 0 : obj[i].DeliverfeeTax
                ),
                deliveryAmountTax: 0,
                dueDateModified: false,
                editSchedule: "",
                extensionAmount: 0,
                htSuspense: 0,
                ipSuspense: ipSuspense,
                lFeeTaxAmount: 0,
                suspenseAdd: 0,
                suspenseSub: 0,
                lateFee: 0,
                ldwPercentage: NaN,
                ldwTaxAmount: 0,
                mattressFee: 0,
                mattressFeeTax: 0,
                noOfDaysFree: NaN,
                number_of_paymentsmade: 0,
                partyId: "",
                policyAmount: 0,
                policyTaxAmount: 0,
                processingFee: 0,
                processingFeeTax: 0,
                racTirePay: 0,
                rpayTaxAmount: 0,
                sign_status: "",
                stateProvinceName:
                  storeInfo[0]?.stateProvinceName != undefined
                    ? storeInfo[0]?.stateProvinceName
                    : "",
                //  configFlags.isPaymentFunctionsFeatureFlag == 1
                //    ? getpayment?.paymentInfo?.storeInfo?.stateProvinceName
                //    : "",
                storeCityName:
                  storeInfo[0]?.city != undefined ? storeInfo[0]?.city : "",
                //  configFlags.isPaymentFunctionsFeatureFlag == 1
                //    ? getpayment?.paymentInfo?.storeInfo?.city
                //    : "",
                store_number: String(
                  window.sessionStorage.getItem("storeNumber")
                ),
                suspense: 0,
                taxGeoCode: "",
                taxRate: "",
                wlDeposit: wlDeposit,
                zipPlus4UsedForTax: "",
                nineOneOneFee: 0,
                cellPhoneActivation: Number(obj[i].totalsales),
                // cellPhoneActivationTax: Number(val.ristotalsales),
                miscellaneousItemsNum: 0,
                isLdwAllowed: "0",
                carryLdwAmountTax: 0,
                carryLdwAmount: 0,
                cellPhoneActivationTax: 0,
                miscellaneousItemInfo: undefined,
                pastDueRent: 0,
                PromoFreeDaysApplied: 0,
                FreeTime: null, // Added By Me
                isCOATransfered: false,
                isSwitchoutDeliveryPending: 0, // Added By Me
                editedEpoAmount: 0,
                actualPastDueRent: 0,
                acceptEpoFlag: 0,
                // restitutiontypeAgree:1,
                originAgreementId: null,
                // restitutionInfo: res,
                clubDocumentSignStatus: null,

                transferOldSuspense: 0,

                agreementNoteDetails: {
                  noteAmountDueIncTax: "0.00",
                  remainingNoteCost: "0.00",
                  remainingAgreementCost: "0.00",
                  isNoteAgreement: "0",
                  NotAmountFinal: "0",
                  editedNoteAmount: "",
                  remainingNoteCostAmount: "0.00",
                },
                accepoEPoAgrnoteInfo: undefined,
                customerClubId: "",
                isClubDeactivateTriggered: false,
                lateFeeTaxRate: 0,
                isReturnAgrFlag: 0,
                constLateFee: 0,
                waivedLateFee: 0,
                initialPaymentFlag: 0,
                taxEngineData: null,
                storeMerchantID: null,
                otherIncomeType: "Rental Item Sale",
                isFinalTransferSusUseCancel: false,
                isFinalTransferSusUseApplied: false,
                // restitutiontypeAgree:1,
                // restitutionInfo: res,
              };
              let data: any = [];
              recordsFound = recordsFound + 1;
              sameStoreDataRIS.push(rispaygridvaluesNew);
            }
            setUpatedInventory(arr);
          }
          history.replace({
            pathname: `/payment1/paymentinformation/${customerId}/0`,
            state: {
              Risresponse: {},
              ristype: "Rental Item Sale",
              risNo: 0,
              editNo: 0,
            },
          });
        } else if (risNo == 0 && Risresponse == undefined && resetInvoke == undefined) {
          if (obj?.length >= 1) {
            for (let i = 0; i < obj?.length; i++) {
              arr.push(obj[i].inventoryNumber);
              const rispaygridvaluesNew = {
                AgreementNumber: obj[i].inventoryNumber,
                Agreementdesc: obj[i].inventoryDescription,
                Agreementid: obj[i].inventoryId,
                Agreementtype: "Rental Item Sale",
                Amount: Number(obj[i].totalsales),
                Autopay: "null",
                Autopay_enrollmentdate: null,
                COA: coa,
                CurrentDaysLate: 0,
                CurrentDueDate: "-",
                EPOAmount: 0,
                EPOTax: 0,
                actualEpoAmount: 0,
                EmailAddress: "",
                Latelast10: 0,
                Nextduedate: "-",
                Phonenumber: "",
                PromoFreeDays: 0,
                SAC_Date: "",
                Schedule: "-",
                SuspenseAmount: 0,
                Taxamount: Number(obj[i].salestax),
                // Number(salesTax)
                actualAmountDue: Number(obj[i].totalsales),
                agrLdwAmount: 0,
                agreementRate: obj[i].totalsales,
                agreementRateDetails: null,
                cardLastFour: 0,
                carriedLateFee: 0,
                carryRentAmountAdd: 0,
                carryRentAmountUsed: 0,
                carryRentTaxUsed: 0,
                clubCoverageTermination: null,
                clubFlag: true,
                clubMembershipNumber: "",
                clubName: "",
                clubactive: false,
                clublastLate10: 0,
                confirmeddeliversystatus: false,
                convTax: configFeeOnlyTax,
                coverageEndDate: "",
                deliveryAmount: Number(
                  obj[i]?.Deliveryfee == undefined ? 0 : obj[i]?.Deliveryfee
                ),
                risDeliveryfeeTax: Number(
                  obj[i].DeliverfeeTax == undefined ? 0 : obj[i].DeliverfeeTax
                ),
                deliveryAmountTax: 0,
                dueDateModified: false,
                editSchedule: "",
                extensionAmount: 0,
                htSuspense: 0,
                ipSuspense: ipSuspense,
                lFeeTaxAmount: 0,
                suspenseAdd: 0,
                suspenseSub: 0,
                lateFee: 0,
                ldwPercentage: NaN,
                ldwTaxAmount: 0,
                mattressFee: 0,
                mattressFeeTax: 0,
                noOfDaysFree: NaN,
                number_of_paymentsmade: 0,
                partyId: "",
                policyAmount: 0,
                policyTaxAmount: 0,
                processingFee: 0,
                processingFeeTax: 0,
                racTirePay: 0,
                rpayTaxAmount: 0,
                sign_status: "",
                stateProvinceName:
                  storeInfo[0]?.stateProvinceName != undefined
                    ? storeInfo[0]?.stateProvinceName
                    : "",
                //  configFlags.isPaymentFunctionsFeatureFlag == 1
                //    ? getpayment?.paymentInfo?.storeInfo?.stateProvinceName
                //    : "",
                storeCityName:
                  storeInfo[0]?.city != undefined ? storeInfo[0]?.city : "",
                //  configFlags.isPaymentFunctionsFeatureFlag == 1
                //    ? getpayment?.paymentInfo?.storeInfo?.city
                //    : "",
                store_number: String(
                  window.sessionStorage.getItem("storeNumber")
                ),
                suspense: 0,
                taxGeoCode: "",
                taxRate: "",
                wlDeposit: wlDeposit,
                zipPlus4UsedForTax: "",
                nineOneOneFee: 0,
                cellPhoneActivation: Number(obj[i].totalsales),
                // cellPhoneActivationTax: Number(val.ristotalsales),
                miscellaneousItemsNum: 0,
                isLdwAllowed: "0",
                carryLdwAmountTax: 0,
                carryLdwAmount: 0,
                cellPhoneActivationTax: 0,
                miscellaneousItemInfo: undefined,
                pastDueRent: 0,
                PromoFreeDaysApplied: 0,
                FreeTime: null, // Added By Me
                isCOATransfered: false,
                isSwitchoutDeliveryPending: 0, // Added By Me
                editedEpoAmount: 0,
                actualPastDueRent: 0,
                acceptEpoFlag: 0,
                // restitutiontypeAgree:1,
                originAgreementId: null,
                // restitutionInfo: res,
                clubDocumentSignStatus: null,

                transferOldSuspense: 0,

                agreementNoteDetails: {
                  noteAmountDueIncTax: "0.00",
                  remainingNoteCost: "0.00",
                  remainingAgreementCost: "0.00",
                  isNoteAgreement: "0",
                  NotAmountFinal: "0",
                  editedNoteAmount: "",
                  remainingNoteCostAmount: "0.00",
                },
                accepoEPoAgrnoteInfo: undefined,
                customerClubId: "",
                isClubDeactivateTriggered: false,
                lateFeeTaxRate: 0,
                isReturnAgrFlag: 0,
                constLateFee: 0,
                waivedLateFee: 0,
                initialPaymentFlag: 0,
                taxEngineData: null,
                storeMerchantID: null,
                newSuspenseSub: 0,
                otherIncomeType: "Rental Item Sale",
                isFinalTransferChange: false,
                isFinalTransferSusUseCancel: false,
                isFinalTransferSusUseApplied: false,
                // restitutiontypeAgree:1,
                // restitutionInfo: res,
              };
              let data: any = [];
              recordsFound = recordsFound + 1;
              sameStoreDataRIS.push(rispaygridvaluesNew);
            }
            setUpatedInventory(arr);
          }
          // setRisRemovepaymentbtnFlag(true)
          // setRisFlag(true)
          history.replace({
            pathname: `/payment1/paymentinformation/${customerId}/0`,
            state: {
              Risresponse: {},
              ristype: "Rental Item Sale",
              risNo: 0,
              editNo: 0,
            },
          });
        }else{
          obj=[];
          setUpatedInventory([])
        }
        let FilteredSameStoreData = Clubagreementinsertion?.filter(
          (el) =>
            el.store_number ==
              String(window.sessionStorage.getItem("storeNumber")) &&
            el.Agreementtype != "Club"
        );
        FilteredSameStoreData?.push(
          ...sameStoreDataRIS,
          ...NSFgridValues,
          ...CCCBgridvalues
        );
        let ClubFiltered = Clubagreementinsertion?.filter(
          (x) => x.Agreementtype == "Club"
        );
        const differentStoreData = Clubagreementinsertion.filter(
          (el) =>
            el.store_number !==
              String(window.sessionStorage.getItem("storeNumber")) &&
            el.Agreementtype != "Club"
        );
        paygridvalues = [
          ...ClubFiltered,
          ...FilteredSameStoreData,
          ...differentStoreData,
        ];

        //Checking if isRevenueRecogActivated Needs to activate based on the agreements/clubs with in the same store or not

        // for(let i=0;i<paygridvalues?.length;i++){
          const paygridValuesSameStoreCount = paygridvalues?.filter(x=>x.store_number==String(window.sessionStorage.getItem("storeNumber")))
          if(paygridValuesSameStoreCount?.length==paygridvalues?.length&&isRevenueRecogActivated){
            isRevenueRecogActivated=false
            configPermissions.RevenueRecognition=0
            configFlags = configPermissions
            setconfigFlags(configFlags)
          }
        // }
        // const addedAdditionalData=[...Clubagreementinsertion,...NSFgridValues,...CCCBgridvalues];
        /**
         * Over payment Suspense loop for the agreements excluding the club and other incomes
         */
        const finalDataToCheck = [...ClubFiltered ,...FilteredSameStoreData, ...differentStoreData]
         if(configPermissions?.isFinalPaymentTransferSuspense==0){
          for (let i = 0; i < paygridvalues?.length; i++) {
            if (
              paygridvalues[i].Agreementtype != "Club" &&
              paygridvalues[i].otherIncomeType == ""
            ) {
              if (
                (paygridvalues[i].Nextduedate == "SAC" ||
                  paygridvalues[i].Nextduedate == "PIF" ||
                  paygridvalues[i].Nextduedate == "EPO") &&
                Number(paygridvalues[i].SuspenseAmount) > 0
              ) {
                paygridvalues[i].suspenseSub = Number(
                  paygridvalues[i].SuspenseAmount
                );
                paygridvalues[i].isFinalTransferSusUseApplied = true;
              } else if (
                Number(paygridvalues[i].SuspenseAmount) >=
                  Number(paygridvalues[i].EPOAmount) &&
                Number(paygridvalues[i].SuspenseAmount) > 0
              ) {
                paygridvalues[i].suspenseSub = Number(
                  paygridvalues[i].SuspenseAmount
                );
                paygridvalues[i].Nextduedate =
                  new Date(paygridvalues[i].SAC_Date) >= new Date()
                    ? "SAC"
                    : "EPO";
                paygridvalues[i].Amount = Number(paygridvalues[i].EPOAmount);
                paygridvalues[i].Taxamount = 0.0;
                paygridvalues[i].isFinalTransferSusUseCancel = true;
                paygridvalues[i].isFinalTransferSusUseApplied = true;
                paygridvalues[i].Agreementtype = "EPO Rent";
              }
            }
          }
        }
      
        setpaygridvalues(finalDataToCheck);
      
        setmainGridloader(false);

        //Changes for Exchagne P2
        // if (isExchangeFuncEnabled) {
          let exchangedAgreementsArray: any = [];
          let autoPayAgreements: any = [];
        let exchangeAgreementsList: any = {};
        let exchangeAgreementsNumberMapper: any = {};
        const exchangeAgreementMapper: any = [];
        let pendingAgreements: any = [];
        let agreementStatus: any = {};
          paygridvalues.forEach((element) => {
            if (element?.isExchanged && element?.store_number == String(window.sessionStorage.getItem('storeNumber'))) {
              exchangedAgreementsArray.push(element?.Agreementid);
              exchangeAgreementMapper.push({
                agreementId: element?.Agreementid,
                agreementNumber: element?.AgreementNumber,
                agreementDesc: element?.Agreementdesc,
                storeNumber: element?.store_number
              })
            }
            if (element?.Autopay && element?.Autopay != 'null' && element?.Autopay != 'undefined' && element?.Autopay != '') {
              autoPayAgreements.push(element?.Agreementid);
            }
            if (element?.Agreementid == exchangeAgreementId) {
              setExchangeAgreementNumber(element?.AgreementNumber);
            }
            if(element?.agreementStatus == 'PEND'){
              pendingAgreements.push(element?.Agreementid)
            }
            exchangeAgreementsList[element?.Agreementid] = element?.parentAgreementid;
            exchangeAgreementsNumberMapper[element?.Agreementid] = element?.AgreementNumber;
            agreementStatus[element?.Agreementid] = element?.isExchanged;
          })
          console.log('autoPayAgreements', autoPayAgreements);
          setParentAgreementIdMapper(exchangeAgreementsList);
          setIsExchangeIndicator(agreementStatus);
          setExchangeAgreementNumberMapper(exchangeAgreementsNumberMapper);
          setPendingAgreements(pendingAgreements);
          setAutoPayEnrolledAgreements(autoPayAgreements);
          if (!exchangeAgrMapper?.length) {
            setExchangeAgrMapper(exchangeAgreementMapper);
            if (exchangeAgreementMapper && agreementId == '0' && exchangeAgreementMapper.length) {
              setExchangedAgreementsNumberPopup(true);
            }
          }

          console.log(exchangeAgreementMapper, 'exchangeAgreementMapper');
          if (autoPayAgreements.includes(exchangeAgreementId)) {
            setIsAutoPayEnabledAgreement(true);
          }
          console.log('exchangedAgreementsArray', exchangedAgreementsArray);
          setExchangedAgreements(exchangedAgreementsArray);
          let checkedValues = document.getElementsByName("grid");
          checkedValues.forEach((ele, i) => {
            let objCheckBox = ele as HTMLInputElement;
            let objAgrID: any = objCheckBox.value.split("|")[0];
            if (exchangedAgreementsArray.includes(objAgrID)) {
              objCheckBox.disabled = true;
              objCheckBox.checked = false;
            }
          })
        // }
        
        
        if (agreementId !== "0") {
          setCardrequire({ ...Cardrequire, txt2paybtn: false });
        } else {
          setAcceptpaymentbuttonenable(true);
        }
        if (resetInvoke == undefined) {
          acceptPaymentParam = [];
        }
        swipeAmt = 0.0;
        previousAmt = 0.0;
        swipeCardAmt = [];
        if(resetInvoke == undefined) {
          actLDWArr = [];
        }
        actPolicyArr = [];
        epoError = [];
        suspenseError = [];
        diffStoreError = [];
        setpageLoadedInitial(false);
        ReceiptCategoriesRequestParam = [];
        AcceptclubPaymentParam = undefined;
        clubInitialCheckFlag = true;
        if (
          agreementId == "0" &&
          recordsFound > 0 &&
          isRevenueRecogActivated == false
        ) {
          if (
            retentionType &&
            (retentionType == "FREETIME" || retentionType == "EPO")
          ) {
            const filterRetentionAgr = Clubagreementinsertion.filter(
              (el) =>
                el.AgreementNumber ==
                location?.state?.retentionData?.agreementNumber
            );
            retentionSelection(true, undefined, filterRetentionAgr);
          } else {
            selectallcheckbox(true, undefined);
          }
        } else if (
          agreementId == "0" &&
          recordsFound > 0 &&
          isRevenueRecogActivated &&
          resetInvoke == undefined
        ) {
          if (
            retentionType &&
            (retentionType == "FREETIME" || retentionType == "EPO")
          ) {
            const filterRetentionAgr = Clubagreementinsertion.filter(
              (el) =>
                el.AgreementNumber ==
                location?.state?.retentionData?.agreementNumber
            );
            retentionSelection(true, undefined, filterRetentionAgr);
          } else if (diffStoreErrLbl) {
            selectallcheckboxRevenue(
              false,
              undefined,
              true,
              finalDataToCheck
            );
          } else {
            selectallcheckboxRevenue(
              true,
              undefined,
              true,
              finalDataToCheck
            );
          }
        }
        if (
          getpaid.amountDue !== undefined &&
          getpaid.amountDue.amountDueResponse.length > 0
        )
          setconvAmtWTax(
            (
              Number(configConvFeeWithOutTax) + Number(configConvFeeTax)
            ).toFixed(2)
          );
        /**
         * Changes: 3 Payment Verification
         * Developer: Muzzammil
         * Psuedo_Code:PC_1.1
         * Description: set the state variable as true if the customer is not verified and make more than 3 payments
         *
         */
        if (
          sortableData.length == 1 &&
          sortableData[0].Agreementtype != "Club"
          // &&
          // paygridvalues[0].FreeTime == null // Added By Me
        ) {
          await loadOtherActions(0,configPermissions);
        }
        if (agreementId != "0" && resetInvoke !== true) {
          adjustDueDateSelectedAgre.push(agreementId);
          let clubvalue = sortableData.filter(
            (x) => x.Agreementtype == "Club" && x.number_of_paymentsmade == 0
          );
          setSelectedCheckBoxCount(1);
          if (clubvalue.length > 0) {
            adjustDueDateSelectedAgre.push(clubvalue[0].Agreementid);
            setSelectedCheckBoxCount(2);
          }
          adjustDueDateSelectedAgre = Array.from(
            new Set(adjustDueDateSelectedAgre)
          );
          setadjustDueDateSelectedAgre(adjustDueDateSelectedAgre);
          // if (isRevenueRecogActivated) {
          //   multipleAdjustRevenue();
          // } else {
          multipleAdjust();
          // }
        }
        let isSIMSCreatedSource = false;
        if (getpaymentstate.commitmentDetails !== "") {
          const SIMSCommitment =
            getpaymentstate.commitmentDetails.commitments.filter(
              (obj) =>
                obj.createdSource == "ENTERPRISE" &&
                obj.commitmentStatusCode == "OP"
            );

          if (SIMSCommitment.length) {
            isSIMSCreatedSource = true;
          }
        }
        if (
          adjustCommitmentData !== undefined &&
          (adjustCommitmentData?.commitmentDetail.length > 0 ||
            adjustCommitmentData?.CommitmentClubAgreement !== null) &&
          origin == undefined &&
          retentionType == undefined
        ) {
            // to set the commitmentDataState which is used to check of those accounts during the page load
          // only the commitment taken items are pushed to the array 
          let agreementIds:any = [];
          adjustCommitmentData?.commitmentDetail.map((item:any) => {
            if(item?.adjustedInfo[0]?.type  || item?.agreementID) agreementIds.push(item?.agreementID);
          });
          //code to push the customer club Id
          if(adjustCommitmentData?.CommitmentClubAgreement) {
            const customerClubId = paygridvalues.find((e:any) => e.customerClubId == adjustCommitmentData?.CommitmentClubAgreement[0]?.agreementID);
            if(customerClubId) agreementIds.push(customerClubId?.AgreementNumber);
          }
          console.log(agreementIds, 'agreementIds bfr');
          //For RACExchange Remove Commitment Agreement Ids
          const exchangedIds : any = paygridvalues
            .filter((ele: any) => ele.isExchanged === 1)
            .map((ele: any) => ele.Agreementid.toString());

          agreementIds = agreementIds.filter((id: any) => !exchangedIds.includes(id.toString()));
          console.log(agreementIds, 'agreementIds afr');
          //set it to the state variable which is used 
          commitmentDataState = agreementIds;
          setCommitmentDataState(commitmentDataState);

          adjustCommitmentDataBind(finalDataToCheck,configPermissions);
        } else if (isSIMSCreatedSource) {
          setisCommitmentInSIMS(true);
        }

        //RIS_PC_NO_39 This condition checks whether the response is received from the Rentalitemsale

        if (retentionType && retentionType == "FREETIME") {
          setRetentionAlertMessage(
            `Retention extension for agreement ${location?.state?.retentionData?.agreementNumber} is going to set up with a retention option. Customer has upto ${location?.state?.retentionData?.extentionDays} days extension available with a recommend. You will need to complete the extension payment to finalize`
          );
          setOpenRetentionAlert(true);
        } else if (retentionType && retentionType == "EPO") {
          setRetentionAlertMessage(
            `Retention EPO reduction for agreement ${location?.state?.retentionData?.agreementNumber} is going to get set up with ${location?.state?.retentionData?.retentionEPOMessage}. You will need to complete the EPO payment to finalize`
          );
          setOpenRetentionAlert(true);
        }
        setconvAmtWTax(
          (
            Number(configConvFeeWithOutTax) + Number(configConvFeeTax)
          ).toFixed(2))
        //FinalPaymentTransferSuspense
        // setFinalPaymentTransferSuspenseEnable(true)
        if(configPermissions?.isFinalPaymentTransferSuspense==1 && resetInvoke !== true){
          finalPaymentTransferSusFunc()
          // setFinalPaymentTransferSuspenseEnable(false)
        }
       
      }
    }
    setPayGridPayloadLoad(false)
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:agreementId!='0'?"Initial Payment Pageload":"Regular Payment pageload"
    }
    setUserTraceData([...userTraceData,traceObj]);


  }catch{
    if(paymentDetails?.commonModuleRes?.code==400 && paymentDetails?.commonModuleRes?.error==`customerId - ${String(customerId)} is Not Valid`)
    {
      setPrimaryAddressErrorFlag(true)
      setPayGridPayloadLoad(false)
    }else{
      setSomethingWentWrong(true)
    setPayGridPayloadLoad(false)
    }
  }
  setRestrictBindRemaining(false);
  };

  const someThingWentWrongOk =()=>{
    if (location?.state?.redirectSource === "am") {
      setRedirectToAccountManagement(true);
    }else{
      redirectionTopaymentsearch();
    }
  }


  // useEffect(() => {
    
  // }, [finalPaymentTransferSuspenseEnable,configFlags]);

  //Final Payment Transfer Functions And Render parts

  const finalPaymentTransferSusFunc=async()=>{
    let makingEPOWithSusEPOAmountDueAgreementNum:string[]=[];
    let makingEPOWithExactSusEPOAgreementNum:string[]=[];
    let makingEPOWithOverSusEPOAgreementNum:string[]=[];
    let makingEPOWithExactSusEPOAgreementDesc:string[]=[]
    let eligibleTransferAgrementsCount:any=0
    let allAgreementOverSuspenseCount:any=0

    for (let i = 0; i < paygridvalues?.length; i++) {
      if (
        paygridvalues[i].Agreementtype != "Club" &&
        paygridvalues[i].otherIncomeType == ""&&
        paygridvalues[i].store_number== String(window.sessionStorage.getItem("storeNumber")) &&
        !paygridvalues[i].isExchanged
      ) {
        //makingEPOWithSusEPOAmountDueCount
        if(Number(paygridvalues[i].SuspenseAmount)>=(Number(paygridvalues[i].EPOAmount)-Number(paygridvalues[i].Amount))&&(Number(paygridvalues[i].suspenseSub)==0)&&(Number(paygridvalues[i].SuspenseAmount)>0)&&Number(paygridvalues[i].SuspenseAmount)<(Number(paygridvalues[i].EPOAmount))&&paygridvalues[i].epoAmountDuePayOff==false){
          makingEPOWithSusEPOAmountDueAgreementNum.push(paygridvalues[i].AgreementNumber)
        }
        if((Number(paygridvalues[i].SuspenseAmount)>=Number(paygridvalues[i].EPOAmount)&&paygridvalues[i].epoCongratulations==false)){
          makingEPOWithExactSusEPOAgreementNum.push(paygridvalues[i].AgreementNumber)
          makingEPOWithExactSusEPOAgreementDesc.push(paygridvalues[i].Agreementdesc)
        }
        if(Number(paygridvalues[i].SuspenseAmount)>Number(paygridvalues[i].EPOAmount)&&(Number(paygridvalues[i].suspenseSub)==0)&&paygridvalues[i].Nextduedate!='SAC'
        &&paygridvalues[i].Nextduedate!='EPO'
          &&paygridvalues[i].Nextduedate!='PIF'){
          makingEPOWithOverSusEPOAgreementNum.push(paygridvalues[i].AgreementNumber)
          
        }
        if( paygridvalues[i].Agreementtype != "Club" &&
        paygridvalues[i].Nextduedate != "PIF" &&
        paygridvalues[i].Nextduedate != "SAC" &&
        paygridvalues[i].Nextduedate != "EPO" &&
        paygridvalues[i].number_of_paymentsmade > 0 &&
        paygridvalues[i].otherIncomeType==''&&
        String(paygridvalues[i].store_number) ==
          String(window.sessionStorage.getItem("storeNumber"))){
            if(Number(paygridvalues[i].SuspenseAmount)>=Number(paygridvalues[i].EPOAmount)){
              allAgreementOverSuspenseCount++
            }
            eligibleTransferAgrementsCount++
          }
      }
    }
    
    if(makingEPOWithOverSusEPOAgreementNum?.length>0&&eligibleTransferAgrementsCount>1&&allAgreementOverSuspenseCount!=eligibleTransferAgrementsCount){
      setMakingEPOWithOverSusEPOAgreementNumArray(makingEPOWithOverSusEPOAgreementNum) 
      setMakingEpoWithSusEPOAmountDue(false)
      setMakingEpoWithExactSusEPO(false)
      setMakingEpoWithOverSusEPO(true)
    }else if(makingEPOWithSusEPOAmountDueAgreementNum?.length>0&&makingEPOWithOverSusEPOAgreementNum?.length==0&&makingEPOWithExactSusEPOAgreementNum?.length==0){
      setMakingEPOWithSusEPOAmountDueAgreementNumArray(makingEPOWithSusEPOAmountDueAgreementNum)
      setMakingEpoWithSusEPOAmountDue(true)
      setMakingEpoWithExactSusEPO(false)
      setMakingEpoWithOverSusEPO(false)
   }else
   if(makingEPOWithExactSusEPOAgreementNum?.length>0){
     setMakingEPOWithExactSusEPOAgreementNumArray(makingEPOWithExactSusEPOAgreementNum)
     setMakingEPOWithExactSusEPOAgreementDescArray(makingEPOWithExactSusEPOAgreementDesc)
     setMakingEpoWithSusEPOAmountDue(false)
     setMakingEpoWithExactSusEPO(true)
     setMakingEpoWithOverSusEPO(false)
   }else if(makingEPOWithOverSusEPOAgreementNumArray?.length!=0||
      makingEPOWithExactSusEPOAgreementNumArray?.length!=0||
      makingEPOWithSusEPOAmountDueAgreementNumArray?.length!=0){
      setMakingEpoWithSusEPOAmountDue(false)
      setMakingEpoWithExactSusEPO(false)
      setMakingEpoWithOverSusEPO(false)
      setMakingEPOWithOverSusEPOAgreementNumArray([])
      setMakingEPOWithExactSusEPOAgreementNumArray([])
      setMakingEPOWithExactSusEPOAgreementDescArray([])
      setMakingEPOWithSusEPOAmountDueAgreementNumArray([])
      paygridvalues=[...paygridvalues]
      await multipleAdjust()
      editAdjustRes({ resArray: checkedAgr });
    }
    

  }

  const makingEPOWithSusEPOAmountDueYes=()=>{
    for (let i = 0; i < paygridvalues?.length; i++) {
      if (
        paygridvalues[i].Agreementtype != "Club" &&
        paygridvalues[i].otherIncomeType == ""&&
        paygridvalues[i].store_number== String(window.sessionStorage.getItem("storeNumber"))
      ) {
        
        if (
          Number(paygridvalues[i].SuspenseAmount) >=
          Number(paygridvalues[i].EPOAmount)-Number(paygridvalues[i].Amount)
        ) {
          paygridvalues[i].suspenseSub = Number(
            paygridvalues[i].SuspenseAmount
          );
          paygridvalues[i].Nextduedate =
            new Date(paygridvalues[i].SAC_Date) >= new Date()
              ? "SAC"
              : "EPO";
          paygridvalues[i].Agreementtype="EPO Rent" 
          paygridvalues[i].Amount = Number(paygridvalues[i].EPOAmount);
          paygridvalues[i].Taxamount = Number(paygridvalues[i].EPOTax);
          paygridvalues[i].isFinalTransferSusUseCancel = true;
          paygridvalues[i].isFinalTransferSusUseApplied = true;
        }
      }
    }
    paygridvalues=[...paygridvalues]
    finalPaymentTransferSusFunc()
  }

  const makingEPOWithSusEPOAmountDueNo=()=>{
    for(let i=0;i<paygridvalues?.length;i++){
      if(makingEPOWithSusEPOAmountDueAgreementNumArray?.includes(paygridvalues[i].AgreementNumber))
      paygridvalues[i].epoAmountDuePayOff=true
    }
    finalPaymentTransferSusFunc()
    setMakingEpoWithSusEPOAmountDue(false)
  }

  const makingEPOWithExactSusEPOOk=async()=>{
    for(let i=0;i<paygridvalues?.length;i++){
      if(makingEPOWithExactSusEPOAgreementNumArray?.includes(paygridvalues[i].AgreementNumber))
      paygridvalues[i].epoCongratulations=true
    }
     makingEPOWithOverSusEPONo()

  }

  const makingEPOWithOverSusEPOYes=()=>{
    let insertArray: any[] = [];
    let totalsusamount = 0;
    let transferedSupenseAmount = 0;

    for (let i = 0; i < paygridvalues?.length; i++) {
      if (
        paygridvalues[i].Agreementtype != "Club" &&
        paygridvalues[i].Nextduedate != "PIF" &&
        paygridvalues[i].Nextduedate != "SAC" &&
        paygridvalues[i].Nextduedate != "EPO" &&
        paygridvalues[i].number_of_paymentsmade > 0 &&
        paygridvalues[i].otherIncomeType==''&&
        String(paygridvalues[i].store_number) ==
          String(window.sessionStorage.getItem("storeNumber")) &&
        !paygridvalues[i].isExchanged
      ) {
        totalsusamount =
          Number(totalsusamount) + Number(paygridvalues[i].SuspenseAmount);
        transferedSupenseAmount =
          Number(transferedSupenseAmount) +
          Number(paygridvalues[i].transferOldSuspense);
        insertArray.push({
          sustype: "type",
          agreementnumber: paygridvalues[i].Agreementid,
          agreementid: paygridvalues[i].AgreementNumber,
          schedure: paygridvalues[i].Schedule,
          paymentamount: paygridvalues[i].Amount,
          epoamount: paygridvalues[i].EPOAmount,
          suspenseamount: Number(paygridvalues[i].SuspenseAmount).toFixed(2),
          newsuspense: Number(paygridvalues[i].SuspenseAmount).toFixed(2),
          agreementType: paygridvalues[i].Agreementtype,
        });
        if( Number(paygridvalues[i].SuspenseAmount)>Number(paygridvalues[i].EPOAmount)){
          setFinalSusTransDisable(true)
        }
        settotolsuspenseamount(Number(totalsusamount).toFixed(2));
        setFinalDifferenceAmount(
          Number(
            (Number(transferedSupenseAmount) - Number(totalsusamount)).toFixed(
              2
            )
          )
        );
        if (Number(totalsusamount) - Number(transferedSupenseAmount) == 0) {
          settransferDifferenceZero(false);
        } else {
          settransferDifferenceZero(true);
        }
      }
    }
    let allEligibleAgreementCOunt=0;
    for(let i=0;i<insertArray?.length;i++){
      if(Number(insertArray[i]?.newsuspense)==Number(insertArray[i]?.epoamount)){
        allEligibleAgreementCOunt++
      }
    }
    if(allEligibleAgreementCOunt==insertArray?.length){
      setFinalTransferSuspenseCOAAndChange(true)
    }else{
      setFinalTransferSuspenseCOAAndChange(false)
    }
    setmastertranssuspensearray([...insertArray]);
    setFinalTranSuspense(true);
   
  }
  const makingEPOWithOverSusEPOYesTransfer=async()=>{
   
      let insertArray: any = [];
      let finalDifferenceAmountTotal =Number(finalDifferenceAmount)
      for (let i = 0; i < mastertranssuspensearray.length; i++) {
        let indexValue: any = paygridvalues.findIndex(
          (x) =>
            String(x.Agreementid) ===
            String(mastertranssuspensearray[i].agreementnumber)
        );
        if (
          mastertranssuspensearray[i].sustype == "TransferAdd" ||
          "TransferUsed"
        ) {
          if (
            Number(mastertranssuspensearray[i].newsuspense) -
              Number(paygridvalues[indexValue].transferOldSuspense) >
            0
          ) {
            insertArray.push({
              sustype: "TransferAdd",
              agreementnumber: mastertranssuspensearray[i].agreementnumber,
              agreementid: mastertranssuspensearray[i].agreementid,
              schedure: mastertranssuspensearray[i].schedure,
              paymentamount: mastertranssuspensearray[i].paymentamount,
              epoamount: Number(mastertranssuspensearray[i].epoamount),
              suspenseamount: mastertranssuspensearray[i].suspenseamount,
              newsuspense:
                Number(mastertranssuspensearray[i].newsuspense) -
                Number(paygridvalues[indexValue].transferOldSuspense),
              agreementType: mastertranssuspensearray[i].agreementType,
            });
            // let indexValue:any= checkedAgr.findIndex( x => String(x.Agreementid)  ===  String(mastertranssuspensearray[i].agreementnumber))
            // checkedAgr[indexValue].transferNewSuspense = Number(mastertranssuspensearray[i].newsuspense)
            paygridvalues[indexValue].SuspenseAmount = Number(
              mastertranssuspensearray[i].newsuspense
            );
          } else if (
            Number(mastertranssuspensearray[i].newsuspense) -
              Number(paygridvalues[indexValue].transferOldSuspense) <
            0
          ) {
            insertArray.push({
              sustype: "TransferUsed",
              agreementnumber: mastertranssuspensearray[i].agreementnumber,
              agreementid: mastertranssuspensearray[i].agreementid,
              schedure: mastertranssuspensearray[i].schedure,
              paymentamount: mastertranssuspensearray[i].paymentamount,
              epoamount: Number(mastertranssuspensearray[i].epoamount),
              suspenseamount: mastertranssuspensearray[i].suspenseamount,
              newsuspense:
                Number(paygridvalues[indexValue].transferOldSuspense) -
                Number(mastertranssuspensearray[i].newsuspense),
              agreementType: mastertranssuspensearray[i].agreementType,
            });
            // let indexValue:any= checkedAgr.findIndex( x => String(x.Agreementid)  ===  String(mastertranssuspensearray[i].agreementnumber))
            // checkedAgr[indexValue].transferNewSuspense = Number(mastertranssuspensearray[i].newsuspense)
            // debugger;
            paygridvalues[indexValue].SuspenseAmount = Number(
              mastertranssuspensearray[i].newsuspense
            );
            const useFilterTransferBack = masterusesuspensearray.filter(
              function (e) {
                return (
                  e.sustype != "type" &&
                  Number(e.newsuspense) > 0 &&
                  String(e.agreementid) ==
                    String(mastertranssuspensearray[i].agreementid)
                );
              }
            );
            
            //Suspense Difference use splt
            while(Number(finalDifferenceAmountTotal)!=0&&(Number(paygridvalues[indexValue].SuspenseAmount)< Number(paygridvalues[indexValue].transferOldSuspense))){
              let count =0
              if(Number(paygridvalues[indexValue].SuspenseAmount)< Number(paygridvalues[indexValue].transferOldSuspense)){
                count++
                let diffAmount = Number(paygridvalues[indexValue].transferOldSuspense)-Number(paygridvalues[indexValue].SuspenseAmount)
                if(finalDifferenceAmountTotal-diffAmount<=0){
                  paygridvalues[indexValue].SuspenseAmount=Number(paygridvalues[indexValue].SuspenseAmount)+Number(finalDifferenceAmountTotal)
                  finalDifferenceAmountTotal=0
                }else if(finalDifferenceAmountTotal-diffAmount>0){
                  paygridvalues[indexValue].SuspenseAmount=Number(paygridvalues[indexValue].SuspenseAmount)+Number(finalDifferenceAmountTotal)-Number(diffAmount)
                  finalDifferenceAmountTotal=finalDifferenceAmountTotal-diffAmount
                }
                
               
              }
              if(finalDifferenceAmountTotal==0){
                break;
              }
            }
            setFinalDifferenceAmount(finalDifferenceAmountTotal)
          }
        }
        // if (paygridvalues[indexValue].isReturnAgrFlag == 1) {
        //   paygridvalues[indexValue].suspenseSub =
        //     paygridvalues[indexValue].SuspenseAmount;
        //   returnSusAmount = Number(paygridvalues[indexValue].SuspenseAmount);
        // }
      }
      const usefilterzero = masterusesuspensearray.filter(function (e) {
        return e.sustype != "type" && Number(e.newsuspense) > 0;
      });
      const addfilterzero = masteraddsuspensearray.filter(function (e) {
        return e.sustype != "type" && Number(e.newsuspense) > 0;
      });
      setmastertranssuspensearray(insertArray);
      settotalsuspensemaster([
        ...usefilterzero,
        ...addfilterzero,
        ...insertArray,
      ]);
      settransuspense(false);
      setFinalTranSuspense(false);
      finalPaymentTransferSusFunc()
    
  }

  const finalTransSuspensePopUp = () => {
    return (
      <Grid
        item
        data-testid="addcheckbtnmode"
        id="check-info"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="carrywaive"
        aria-hidden="true"
      >
        <Grid container spacing={2} className={classes.px3}>
          <RACTable
            className={classes.racGrid}
            renderTableHead={suspenseBindGridHead}
            renderTableContent={finalPaymentSuspenseTransbindgrid}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={7}>
          <Grid
            className={` ${Payclasses.payHistbgLightBlue} ${Payclasses.payHistp3} ${Payclasses.payHistmt2} ${Payclasses.payHistborderRadius5}`}
          >
            <Grid container className={Payclasses.payHistjustifySpaceBetween}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={7}
                className={Payclasses.payHistmb2}
              >
                <Grid container>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                    >
                      {" "}
                      Current Suspense Total:{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                    >
                      {"  " + "$" + totolsuspenseamount}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={6}
                lg={5}
                className={Payclasses.payHistmb2}
              >
                <Grid container>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                    >
                      {" "}
                      New Suspense Total :{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                    >
                      {"$" + Number(totalnewsuspenseamount).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={6}
                lg={5}
                className={Payclasses.payHistmb2}
              >
                <Grid container>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                    >
                      {" "}
                      Difference :{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                    >
                      ${finalTransferSuspenseCOAAndChange?"0.00":Number(finalDifferenceAmount).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={6}
                lg={5}
                className={Payclasses.payHistmb2}
              >
                <Grid container>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                    >
                      {" "}
                      Change/COA :{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                    >
                      ${Number(finalTransferSuspenseCOAAndChange?finalDifferenceAmount:"0.00").toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>

        <Grid className={`${classes.floatRight} ${classes.modalfooter}`}>
          <RACButton
            // type="button"
            className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
            variant="outlined"
            color="primary"
            style={{
              backgroundColor: "white",
              textTransform: "none",
              fontWeight: "bolder",
              paddingLeft: "14px",
              paddingRight: "14px",
              color: "#2179fe",
            }}
            onClick={() => makingEPOWithOverSusEPONo()}
            data-bs-toggle="modal"
          >
            Cancel
          </RACButton>
          <RACButton
            className={`${classes.paymentSmallBtn1}`}
            color="primary"
            variant="contained"
            disabled={finalSusTransDisable||(Number(finalDifferenceAmount)!=0&&finalTransferSuspenseCOAAndChange==false)}
            // eslint-disable-next-line react-hooks/rules-of-hooks
            onClick={() => makingEPOWithOverSusEPOYesTransfer()}
            data-testid="addchecksave"
          >
            Transfer
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const MakingEpoWithSusEPOAmountDuePopUp=()=>{
    return (
      <Grid item id="transatraction" aria-hidden="true">
        <Grid item md={12} className={classes.textcenter}>
          <Typography
            className={`${classes.bold} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
          >
            Customer has enough suspense amount to EPO with this payment for this agreements {String(makingEPOWithSusEPOAmountDueAgreementNumArray)} would you like to payoff ?
          </Typography>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => makingEPOWithSusEPOAmountDueNo()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            No
          </RACButton>
          
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => makingEPOWithSusEPOAmountDueYes()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  }

  const MakingEpoWithExactSusEPOPopUp=()=>{
    return (
      <Grid item id="transatraction" aria-hidden="true">
        <Grid item md={12} className={classes.textcenter}>
          <Typography
            className={`${classes.bold} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
          >
                     
                     Congratulations, {" "}
                  {getpayment !== undefined
                    ? getpayment.paymentInfo.customerInfo[0].firstName +
                      " " +
                      getpayment.paymentInfo.customerInfo[0].lastName
                    : null}
              {" "}This is the final payment on your {String(makingEPOWithExactSusEPOAgreementDescArray)} To celebrate, we want to offer you our current
             promotion for paid in full customers! Today is the only day you can take advantage of this offer, so what would you like to rent next ?.
          </Typography>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          
          
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => makingEPOWithExactSusEPOOk()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
    
  }

  
  const MakingEpoWithOverSusEPOPopUp=()=>{
    return (
      <Grid item id="transatraction" aria-hidden="true">
        <Grid item md={12} className={classes.textcenter}>
          <Typography
            className={`${classes.bold} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
          >
            Suspense amount will cause agreement {String(makingEPOWithOverSusEPOAgreementNumArray)} to payoff 
            Do you want to transfer the remaining suspense amount ?
          </Typography>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => makingEPOWithOverSusEPONo()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            No
          </RACButton>
          
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => makingEPOWithOverSusEPOYes()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  }

  const finalPaymentSuspenseTransbindgrid = () => {
    return mastertranssuspensearray.map((value, index: any) => {

      return (
        <RACTableRow key={index} className={Payclasses.payHistbgTransparent}>
          <RACTableCell>
            <Typography variant="caption" color="primary">
              {value.agreementid}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption"> {value.schedure} </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">{value.paymentamount}</Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">
              {" "}
              {value.epoamount.toFixed(2)}{" "}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">{value.suspenseamount}</Typography>
          </RACTableCell>
          <RACTableCell>
            {/* <CurrencyInput
                type="text"
                name="checkAmount"
                className={classes.formcontrol3}
                value={addsus}
                decimalsLimit={2}
                data-testid="amountbox"
              /> */}

            {/* {AddsusText ? (
              <RACTextbox
                isCurrency={true}
                className={`${classes.custDigit} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                value={value.newsuspense || "0.00"}
                data-testid="suspensetextbox"
                type={"number"}
                name={'suspenseTxtbox'+index}
                digitFormat={"currency"}
                maxlength={10}
                OnKeydown={(e: any) => {
                  if (e.key.toLowerCase() === "delete") {
                    cashamounttextbox("0.00");
                  }
                }}

                OnChange={(e: any) => {
                  masterSusOnchange(
                    e,
                    "Use",
                    index
                  );
                }}

                Onblur={(e: any) => {
                  masterSusOnBlur(
                    e,
                    "Use",
                    index
                  );
                }}
              />
              ) :null }
            {Amount === true ? (
              <label className={classes.mandatoryfield}>
                Amount must be greater than 0.
              </label>
            ) : null} */}

            {/* {UsesusText ? ( */}

            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={value.newsuspense}
              disabled={
                mastertranssuspensearray.length === 1 &&
                Number(mastertranssuspensearray[0].suspenseamount) > 0
                  ? true
                  : false
              }
              data-testid="suspensetextbox"
              // default={"0.00"}
              // type={"number"}
              name={"suspenseTxtbox" + index}
              // digitFormat={"currency"}
              errorMessage={
                Number(value.newsuspense) > Number(value.epoamount)
                  ? "Amount greater than epo"
                  : ""
              }
              maxlength={10}
              OnChange={(e: any) => {
                masterSusFinalOnchange(
                  e,
                  "Trans",
                  index,
                  Number(value.newsuspense) > Number(value.epoamount)
                );
              }}
              Onblur={(e: any) => {
                masterSusFinalOnBlur(
                  e,
                  "Trans",
                  index,
                  Number(value.newsuspense) > Number(value.epoamount)
                );
              }}
            />
            {/* ) :null } */}
          </RACTableCell>
        </RACTableRow>
      );
    });
  };
  const masterSusFinalOnchange = (
    e: any,
    type: string,
    index = 0,
    disabled: boolean
  ) => {
    settranssus("0.00");
    setFinalSusTransDisable(disabled)
    let inputText: any = e.target.value;

    if (type == "Trans") {
      settranssus(e.target.value);
      mastertranssuspensearray[index].newsuspense = inputText;
      // mastersuspensearray[index].newsuspense =AddTrailingZerosToAmount(inputText);
      mastertranssuspensearray[index].sustype = type;
      // setmasteraddsuspensearray(mastertranssuspensearray)
      let newtransferedSuspense = 0;
      let ActualSuspenseAmount = 0;
      for (let i = 0; i < mastertranssuspensearray.length; i++) {
        newtransferedSuspense =
          newtransferedSuspense +
          Number(mastertranssuspensearray[i].newsuspense);
        ActualSuspenseAmount =
          ActualSuspenseAmount +
          Number(mastertranssuspensearray[i].suspenseamount);
      }
      if (
        Number(Number(ActualSuspenseAmount).toFixed(2)) -
          Number(Number(newtransferedSuspense).toFixed(2)) ==
        0
      ) {
        settransferDifferenceZero(false);
      } else {
        settransferDifferenceZero(true);
      }
      let count = 0;
      for (let i = 0; i < mastertranssuspensearray.length; i++) {
        if (
          Number(mastertranssuspensearray[i].newsuspense) >
          Number(mastertranssuspensearray[i].epoamount)
        ) {
          
          count = 1;
        }
      }
      if (count == 0) {
        setFinalSusTransDisable(false)
      } else {
        setFinalSusTransDisable(true)
      }
      setFinalDifferenceAmount(
        Number(ActualSuspenseAmount) - Number(newtransferedSuspense)
      );
      let allEligibleAgreementCOunt=0;
      for(let i=0;i<mastertranssuspensearray?.length;i++){
        if(Number(mastertranssuspensearray[i]?.newsuspense)==Number(mastertranssuspensearray[i]?.epoamount)){
          allEligibleAgreementCOunt++
        }
      }
      if(allEligibleAgreementCOunt==mastertranssuspensearray?.length){
        setFinalTransferSuspenseCOAAndChange(true)
      }else{
        setFinalTransferSuspenseCOAAndChange(false)
      }
      finalTransferSuspenseCOAAndChange
      settotalnewsuspenseamount(newtransferedSuspense);
    }
    setmastertranssuspensearray(mastertranssuspensearray);
  };
  const masterSusFinalOnBlur = (
    e: any,
    type: string,
    index: any,
    disabled: boolean
  ) => {
    // debugger
    // debugger;
    setFinalSusTransDisable(disabled)
    let inputText: any =
      e.target.value == "" ||
      e.target.value == undefined ||
      Number.isNaN(e.target.value)
        ? 0.0
        : e.target.value;

    if (type == "Trans") {
      let totaltransnewsusamount: number;
      totaltransnewsusamount = 0;

      for (let i = 0; i < mastertranssuspensearray.length; i++) {
        totaltransnewsusamount =
          totaltransnewsusamount + mastertranssuspensearray[i].newsuspense;
      }

      settotalnewsuspenseamount(totaltransnewsusamount);
      for (let i = 0; i < mastertranssuspensearray.length; i++) {
        if (
          mastertranssuspensearray[i].suspenseamount <
          mastertranssuspensearray[i].newsuspense
        ) {
          mastertranssuspensearray[i].sustype = "TransferAdd";
        } else {
          mastertranssuspensearray[i].sustype = "TransferUsed";
        }
      }
    }
    setaddsus(AddTrailingZerosToAmount(inputText));
    let totalnewsusamount: number;
    totalnewsusamount = 0;

    for (let i = 0; i < masteraddsuspensearray.length; i++) {
      totalnewsusamount =
        totalnewsusamount + Number(masteraddsuspensearray[i].newsuspense);
    }

    let newtransferedSuspense = 0;
    let ActualSuspenseAmount = 0;
    for (let i = 0; i < mastertranssuspensearray.length; i++) {
      newtransferedSuspense =
        newtransferedSuspense + Number(mastertranssuspensearray[i].newsuspense);
      ActualSuspenseAmount =
        ActualSuspenseAmount +
        Number(mastertranssuspensearray[i].suspenseamount);
    }
    if (
      Number(Number(ActualSuspenseAmount).toFixed(2)) -
        Number(Number(newtransferedSuspense).toFixed(2)) ==
      0
    ) {
      settransferDifferenceZero(false);
    } else {
      settransferDifferenceZero(true);
    }
    let count = 0;
    for (let i = 0; i < mastertranssuspensearray.length; i++) {
      if (
        Number(mastertranssuspensearray[i].newsuspense) >
        Number(mastertranssuspensearray[i].epoamount)
      ) {
        
        count = 1;
      }
    }
    if (count == 0) {
      setFinalSusTransDisable(false)
    } else {
      setFinalSusTransDisable(true)
    }
    settotalnewsuspenseamount(newtransferedSuspense);
  };
  const makingEPOWithOverSusEPONo=()=>{
    for (let i = 0; i < paygridvalues?.length; i++) {
      if (
        paygridvalues[i].Agreementtype != "Club" &&
        paygridvalues[i].otherIncomeType == ""&&
        paygridvalues[i].store_number== String(window.sessionStorage.getItem("storeNumber"))
      ) {
        
        if (
          (paygridvalues[i].Nextduedate == "SAC" ||
            paygridvalues[i].Nextduedate == "PIF" ||
            paygridvalues[i].Nextduedate == "EPO") &&
          paygridvalues[i].SuspenseAmount > 0
        ) {
         
          paygridvalues[i].suspenseSub = Number(
            paygridvalues[i].SuspenseAmount
          );
          paygridvalues[i].isFinalTransferSusUseApplied = true;
        } else if (
          (Number(paygridvalues[i].SuspenseAmount).toFixed(2) == Number(paygridvalues[i].EPOAmount).toFixed(2))||
          (Number(paygridvalues[i].SuspenseAmount) >= Number(paygridvalues[i].EPOAmount))
        ) {
         

          paygridvalues[i].suspenseSub = Number(
            paygridvalues[i].SuspenseAmount
          );
          paygridvalues[i].Nextduedate =
            new Date(paygridvalues[i].SAC_Date) >= new Date()
              ? "SAC"
              : "EPO";
          paygridvalues[i].Agreementtype="EPO Rent"
          paygridvalues[i].Amount = Number(paygridvalues[i].EPOAmount);
          paygridvalues[i].Taxamount = Number(paygridvalues[i].EPOTax);
          paygridvalues[i].isFinalTransferSusUseCancel = true;
          paygridvalues[i].isFinalTransferSusUseApplied = true;
        }
      }
    }
    paygridvalues=[...paygridvalues]
    setFinalTranSuspense(false);
    finalPaymentTransferSusFunc()
    
  }

  //

  const adjustCommitmentDataBind = async (payGridData: any,configPermissions?:any) => {
    // setmainGridloader(true)
    setCommitmentMainGridLoader(true)
    let collectedAgreementID: any = [];
    if(agreementId !== "0") {
      payGridData.map((payGridObj, index) => {
        if(payGridObj.Agreementid == agreementId) {
          collectedAgreementID.push(payGridObj.Agreementid);
        }
        const clubAvailable = payGridData.filter((el) => el.Agreementtype == 'Club');
        
        if(clubAvailable.length && clubAvailable[0].store_number == String(window.sessionStorage.getItem("storeNumber"))){
          collectedAgreementID.push(clubAvailable[0].Agreementid);
        }
      });
    } else if(isRevenueRecogActivated) {
      payGridData.map((payGridObj, index) => {
        if(payGridObj.store_number == String(window.sessionStorage.getItem("storeNumber"))) {
          collectedAgreementID.push(payGridObj.Agreementid);
        }
      });
    } else {
      payGridData.map((payGridObj, index) => {
        collectedAgreementID.push(payGridObj.Agreementid);
      });
    }
    setadjustDueDateSelectedAgre(collectedAgreementID);
    if (adjustCommitmentData.commitmentDetail.length > 0) {
      adjustCommitmentData?.commitmentDetail.map((agrObj) => {
        agrObj.adjustedInfo.sort(
          (a, b) => parseFloat(a.priority) - parseFloat(b.priority)
        );
      });
    }
    if (adjustCommitmentData?.CommitmentClubAgreement !== null) {
      adjustCommitmentData?.CommitmentClubAgreement[0].adjustedInfo.sort(
        (a, b) => parseFloat(a.priority) - parseFloat(b.priority)
      );
    }
    if(configPermissions?.commonModuleFlag==0){
      if (adjustCommitmentData.CommitmentClubAgreement !== null) {
        for (let index = 0; index < payGridData.length; index++) {
          for (
            let j = 0;
            j < adjustCommitmentData.CommitmentClubAgreement.length;
            j++
          ) {
            let payGridObj = payGridData[index];
            let obj = adjustCommitmentData?.CommitmentClubAgreement[j];
            if (Number(payGridObj.customerClubId) == Number(obj.agreementID)) {
              for (let k = 0; k < obj.adjustedInfo.length; k++) {
                let clubObject = obj.adjustedInfo[k];
                if (clubObject.type === "DDA") {
                  let coverageEndDateInfo: any = [];
                  const adjustDueDatereq: any = [];
                  let coverageEndDate = "";
                  let scheduleRef = "";
                  let policyStatus = "";
                  if (actLDWArr.length > 0) {
                    coverageEndDateInfo = actLDWArr.filter(
                      (x) =>
                        String(x.agreementId) ===
                        String(payGridData[index].Agreementid)
                    );
                    if (coverageEndDateInfo.length > 0) {
                      if (Number(coverageEndDateInfo[0].action) == 2) {
                        const currentdate: Date = new Date(
                          new Date().toLocaleString("en-US", {
                            timeZone: "America/Chicago",
                          })
                        );
                        const current_date = new Date(
                          currentdate.getFullYear(),
                          currentdate.getMonth(),
                          currentdate.getDate()
                        );
                        coverageEndDate =
                          moment(current_date).format("MM/DD/YYYY");
                      } else if (Number(coverageEndDateInfo[0].action) == 1) {
                        coverageEndDate = "12/31/2099";
                        if (
                          Number(
                            payGridData[index].agreementRateDetails.perDayLdw
                          ) == 0
                        ) {
                          payGridData[index].agreementRateDetails.perDayLdw = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.weeklyRate
                            ) / 7
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.perDayLdwTax = (
                            (Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.weeklyRate
                            ) /
                              7) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.weeklyLdw =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.weeklyRate
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.weeklyLdwTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.weeklyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.biWeeklyLdw =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.biweeklyRate
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.biWeeklyLdwTax =
                            (
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .scheduleRates.biweeklyRate
                              ) *
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .ldwTaxRate
                              )
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.monthlyLdw =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.monthlyRate
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.monthlyLdwTax =
                            (
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .scheduleRates.monthlyRate
                              ) *
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .ldwTaxRate
                              )
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.semiMonthlyLdw =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.semimonthlyRate
                            ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.semiMonthlyLdwTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.semimonthlyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                        }
                      }
                    }
                  }
                  if (coverageEndDate == "") {
                    coverageEndDate = String(payGridData[index].coverageEndDate);
                  }
                  if (
                    payGridData[index]?.editSchedule != "" &&
                    payGridData[index]?.editSchedule != null
                  ) {
                    scheduleRef = payGridData[index]?.editSchedule;
                  } else {
                    scheduleRef =
                      payGridData[index]?.agreementRateDetails?.scheduleRefCode;
                  }
                  if (actPolicyArr.length > 0) {
                    const policyChangeArrInfo = actPolicyArr.filter(
                      (x) =>
                        String(x.agreementId) ===
                        String(payGridData[index].Agreementid)
                    );
                    if (policyChangeArrInfo.length > 0) {
                      if (Number(policyChangeArrInfo[0].action) == 2) {
                        policyStatus = "I";
                      } else if (Number(policyChangeArrInfo[0].action) == 1) {
                        policyStatus = "A";
                        if (
                          Number(
                            payGridData[index].agreementRateDetails.perDayPolicy
                          ) == 0
                        ) {
                          payGridData[index].agreementRateDetails.perDayPolicy = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.weeklyRate
                            ) / 7
                          ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.perDayPolicyTax = (
                            (Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.weeklyRate
                            ) /
                              7) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.weeklyPolicy =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.weeklyRate
                            ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.weeklyPolicyTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.weeklyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.biWeeklyPolicy =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.biweeklyRate
                            ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.biWeeklyPolicyTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.biweeklyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.monthlyPolicy =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.monthlyRate
                            ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.monthlyPolicyTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.monthlyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.semiMonthlyPolicy = Number(
                            coverageEndDateInfo[0].cusCoverageData.policyInfo
                              .scheduleRates.semimonthlyRate
                          ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.semiMonthlyPolicyTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.semimonthlyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                        }
                      }
                    }
                  }
                  if (policyStatus == "" || policyStatus == null) {
                    policyStatus = String(payGridData[index].policyStatus);
                  }
                  const res = {
                    agreementId: String(payGridData[index].Agreementid),
                    agreementRateDetails: payGridData[index].agreementRateDetails,
                    currentDueDate: String(payGridData[index].CurrentDueDate),
                    dueDate: String(payGridData[index].Nextduedate),
                    coverageEndDate: coverageEndDate,
                    taxRate: String(payGridData[index].taxRate),
                    lateFee: String(payGridData[index].lateFee),
                    lateFeeTax: String(payGridData[index].lFeeTaxAmount),
                    policyStatus: String(policyStatus),
                    epoAmount: String(payGridData[index].EPOAmount),
                    epoTax: String(payGridData[index].EPOTax),
                    sacDate: String(payGridData[index].SAC_Date),
                    isLdwAllowed: String(payGridData[index].isLdwAllowed),
                    agreementNumber: String(payGridData[index].AgreementNumber),
                    carryRent: String(payGridData[index].carryRentAmountUsed),
                    carryRentTax: String(payGridData[index].carryRentTaxUsed),
                    scheduleRefCode: scheduleRef,
                  };
                  adjustDueDatereq.push(res);
                  const calcireq = {
                    rateResponse: adjustDueDatereq,
                    dueDate: moment(clubObject.value).format("MM/DD/YYYY"),
                  };
                  let resFromAdjCalci = await AdjustDueDatecalci(calcireq);
                  let nextduedate;
                  if (
                    resFromAdjCalci[0].nextDueDate != "SAC" &&
                    resFromAdjCalci[0].nextDueDate != "PIF" &&
                    resFromAdjCalci[0].nextDueDate != "EPO"
                  ) {
                    nextduedate = moment(resFromAdjCalci[0].nextDueDate).format(
                      "MM/DD/YYYY"
                    );
                  } else {
                    nextduedate = resFromAdjCalci[0].nextDueDate;
                  }
                  (payGridData[index].Nextduedate = nextduedate),
                    (payGridData[index].Amount = Number(
                      (
                        Number(resFromAdjCalci[0].totalAmount) +
                        Number(resFromAdjCalci[0].totalTax)
                      ).toFixed(2)
                    )),
                    // props.adjustDueDateReq[i].Schedule = props.adjustDueDateReq[i].Schedule,
                    (payGridData[index].Taxamount = Number(
                      resFromAdjCalci[0].totalTax
                    ));
                  payGridData[index].policyAmount = Number(
                    resFromAdjCalci[0].policyAmount
                  );
                  // props.adjustDueDateReq[i].editSchedule=selectedSchedule
                  (payGridData[index].ldwTaxAmount = Number(
                    resFromAdjCalci[0].ldwTaxAmount
                  )),
                    (payGridData[index].agrLdwAmount = Number(
                      resFromAdjCalci[0].ldwAmount
                    ));
                  payGridData[index].policyTaxAmount = Number(
                    resFromAdjCalci[0].policyTaxAmount
                  );
                  payGridData[index].agreementRate = Number(
                    resFromAdjCalci[0].agreementRate
                  );
                  payGridData[index].rpayTaxAmount = Number(
                    resFromAdjCalci[0].rpayTaxAmount
                  );
                  payGridData[index].Agreementtype = "Club";
                  let filterAgreementData: any = [];
                  if(agreementId !== "0") {
                    filterAgreementData = payGridData.filter(
                      (el) =>
                        el.Agreementid == agreementId
                    );
                    filterAgreementData.push(payGridData[index])
                  } else {
                    filterAgreementData = payGridData.filter(
                      (el) =>
                        el.store_number ==
                        window.sessionStorage.getItem("storeNumber")
                    );
                  }
                  let colAgrId: any = [];
                  if(agreementId !== "0") {
                    filterAgreementData.map((el: any) => {
                      if(el.Agreementid == agreementId) {
                        colAgrId.push(el.Agreementid);
                      }
                    });
                  } else {
                    filterAgreementData.map((el: any) => {
                      colAgrId.push(el.Agreementid);
                    });
                  }
                  editCommitmentRes({
                    resArray: filterAgreementData,
                    adjustEditflag: 1,
                    selectedAgr: colAgrId,
                    adjustedAgreement: payGridData[index].Agreementid,
                  });
                } else if (clubObject.type === "ES") {
                  const adjustDueDatereq: any = [];
                  let coverageEndDateInfo: any = [];
                  let policyStatus = "";
                  let coverageEndDate = "";
                  if (actLDWArr.length > 0) {
                    coverageEndDateInfo = actLDWArr.filter(
                      (x) =>
                        String(x.agreementId) ===
                        String(payGridData[index].Agreementid)
                    );
                    if (coverageEndDateInfo.length > 0) {
                      if (Number(coverageEndDateInfo[0].action) == 2) {
                        const currentdate: Date = new Date(
                          new Date().toLocaleString("en-US", {
                            timeZone: "America/Chicago",
                          })
                        );
                        const current_date = new Date(
                          currentdate.getFullYear(),
                          currentdate.getMonth(),
                          currentdate.getDate()
                        );
                        coverageEndDate =
                          moment(current_date).format("MM/DD/YYYY");
                      } else if (Number(coverageEndDateInfo[0].action) == 1) {
                        coverageEndDate = "12/31/2099";
                        if (
                          Number(
                            payGridData[index].agreementRateDetails.perDayLdw
                          ) == 0
                        ) {
                          payGridData[index].agreementRateDetails.perDayLdw = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.weeklyRate
                            ) / 7
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.perDayLdwTax = (
                            (Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.weeklyRate
                            ) /
                              7) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.weeklyLdw =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.weeklyRate
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.weeklyLdwTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.weeklyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.biWeeklyLdw =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.biweeklyRate
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.biWeeklyLdwTax =
                            (
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .scheduleRates.biweeklyRate
                              ) *
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .ldwTaxRate
                              )
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.monthlyLdw =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.monthlyRate
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.monthlyLdwTax =
                            (
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .scheduleRates.monthlyRate
                              ) *
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .ldwTaxRate
                              )
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.semiMonthlyLdw =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.semimonthlyRate
                            ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.semiMonthlyLdwTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.semimonthlyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                        }
                      }
                    }
                  }
                  if (coverageEndDate == "") {
                    coverageEndDate = String(payGridData[index].coverageEndDate);
                  }
                  if (actPolicyArr.length > 0) {
                    const policyChangeArrInfo = actPolicyArr.filter(
                      (x) =>
                        String(x.agreementId) ===
                        String(payGridData[index].Agreementid)
                    );
                    if (policyChangeArrInfo.length > 0) {
                      if (Number(policyChangeArrInfo[0].action) == 2) {
                        policyStatus = "I";
                      } else if (Number(policyChangeArrInfo[0].action) == 1) {
                        policyStatus = "A";
                        if (
                          Number(
                            payGridData[index].agreementRateDetails.perDayPolicy
                          ) == 0
                        ) {
                          payGridData[index].agreementRateDetails.perDayPolicy = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.weeklyRate
                            ) / 7
                          ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.perDayPolicyTax = (
                            (Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.weeklyRate
                            ) /
                              7) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.weeklyPolicy =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.weeklyRate
                            ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.weeklyPolicyTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.weeklyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.biWeeklyPolicy =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.biweeklyRate
                            ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.biWeeklyPolicyTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.biweeklyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.monthlyPolicy =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.monthlyRate
                            ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.monthlyPolicyTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.monthlyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.semiMonthlyPolicy = Number(
                            coverageEndDateInfo[0].cusCoverageData.policyInfo
                              .scheduleRates.semimonthlyRate
                          ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.semiMonthlyPolicyTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.semimonthlyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                        }
                      }
                    }
                  }
                  if (policyStatus == "") {
                    policyStatus = String(payGridData[index].policyStatus);
                  }
                  const res = {
                    agreementId: String(payGridData[index].Agreementid),
                    agreementRateDetails: payGridData[index].agreementRateDetails,
                    currentDueDate: String(payGridData[index].CurrentDueDate),
                    dueDate: String(payGridData[index].Nextduedate),
                    coverageEndDate: String(coverageEndDate),
                    taxRate: String(payGridData[index].taxRate),
                    lateFee: String(payGridData[index].lateFee),
                    lateFeeTax: String(payGridData[index].lFeeTaxAmount),
                    policyStatus: String(policyStatus),
                    epoAmount: String(payGridData[index].EPOAmount),
                    epoTax: String(payGridData[index].EPOTax),
                    sacDate: String(payGridData[index].SAC_Date),
                    carryRent: String(payGridData[index].carryRentAmountUsed),
                    carryRentTax: String(payGridData[index].carryRentTaxUsed),
                    agreementNumber: String(payGridData[index].AgreementNumber),
                    scheduleRefCode: "",
                  };
                  adjustDueDatereq.push(res);
                  const editScheudleRequest = {
                    rateResponse: adjustDueDatereq,
                    schedule: clubObject.value,
                  };
                  const adjustDueRes = await AdjustDueDatecalci(
                    editScheudleRequest
                  );

                  let nextduedate;
                  if (
                    adjustDueRes[0].nextDueDate != "SAC" &&
                    adjustDueRes[0].nextDueDate != "PIF" &&
                    adjustDueRes[0].nextDueDate != "EPO"
                  ) {
                    nextduedate = moment(adjustDueRes[0].nextDueDate).format(
                      "MM/DD/YYYY"
                    );
                  } else {
                    nextduedate = adjustDueRes[0].nextDueDate;
                  }
                  // if(adjustedObj.value === 'MON'){

                  // }
                  let updatedSchedule = "";
                  if (clubObject.value.includes("SEMI")) {
                    updatedSchedule = "Semi-Monthly";
                  } else if (clubObject.value === "MON") {
                    updatedSchedule = "Monthly";
                  } else if (clubObject.value === "BIWK") {
                    updatedSchedule = "Bi-Weekly";
                  } else if (clubObject.value === "WK") {
                    updatedSchedule = "Weekly";
                  }

                  (payGridData[index].Amount =
                    Number(adjustDueRes[0].totalAmount) +
                    Number(adjustDueRes[0].totalTax)),
                    (payGridData[index].Schedule = updatedSchedule),
                    (payGridData[index].Taxamount = Number(
                      adjustDueRes[0].totalTax
                    )),
                    (payGridData[index].Nextduedate = nextduedate);
                  payGridData[index].policyAmount = Number(
                    adjustDueRes[0].policyAmount
                  );
                  payGridData[index].editSchedule = clubObject.value;
                  (payGridData[index].ldwTaxAmount = Number(
                    adjustDueRes[0].ldwTaxAmount
                  )),
                    (payGridData[index].agrLdwAmount = Number(
                      adjustDueRes[0].ldwAmount
                    ));
                  payGridData[index].policyTaxAmount = Number(
                    adjustDueRes[0].policyTaxAmount
                  );
                  payGridData[index].agreementRate = Number(
                    adjustDueRes[0].agreementRate
                  );
                  payGridData[index].Agreementtype = "Club";
                  // adjustDueRes[0].agreementType;
                  // adjustDueRes[index].Agreementtype = "Club";
                  //   ? "Club"
                  //   : adjustDueRes[0].agreementType;
                  payGridData[index].rpayTaxAmount = Number(
                    adjustDueRes[0].rpayTaxAmount
                  );
                  // resArray: [payGridData[index]],
                  // selectedAgr: [payGridData[index].Agreementid],
                  let filterAgreementData: any = [];
                  if(agreementId !== "0") {
                    filterAgreementData = payGridData.filter(
                      (el) =>
                        el.Agreementid == agreementId
                    );
                    filterAgreementData.push(payGridData[index])
                  } else {
                    filterAgreementData = payGridData.filter(
                      (el) =>
                        el.store_number ==
                        window.sessionStorage.getItem("storeNumber")
                    );
                  }
                  let colAgrId: any = [];
                  if(agreementId !== "0") {
                    filterAgreementData.map((el: any) => {
                      if(el.Agreementid == agreementId) {
                        colAgrId.push(el.Agreementid);
                      }
                    });
                  } else {
                    filterAgreementData.map((el: any) => {
                      colAgrId.push(el.Agreementid);
                    });
                  }
                  editCommitmentRes({
                    resArray: filterAgreementData,
                    adjustEditflag: 0,
                    selectedAgr: colAgrId,
                  });
                  // })();
                } else if (clubObject.type === "DC") {
                  let agrIdWithoutClub: any = [];
                  payGridData.map((payGridObj, index) => {
                    if (payGridObj.Agreementtype !== "Club") {
                      agrIdWithoutClub.push(payGridObj.Agreementid);
                    }
                  });
                  await deActivatePopupYesHandleChange();
                  let filterAgreementData: any = [];
                  if(agreementId !== "0") {
                    filterAgreementData = payGridData.filter(
                      (el) =>
                        el.Agreementid == agreementId
                    );
                    filterAgreementData.push(payGridData[index])
                  } else {
                    filterAgreementData = payGridData.filter(
                      (el) =>
                        el.store_number ==
                        window.sessionStorage.getItem("storeNumber")
                    );
                  }
                  let colAgrId: any = [];
                  if(agreementId !== "0") {
                    filterAgreementData.map((el: any) => {
                      if(el.Agreementid == agreementId) {
                        colAgrId.push(el.Agreementid);
                      }
                    });
                  } else {
                    filterAgreementData.map((el: any) => {
                      colAgrId.push(el.Agreementid);
                    });
                  }
                  await editCommitmentRes({
                    resArray: filterAgreementData,
                    selectedAgr: agrIdWithoutClub,
                  });
                  setclubStatus("A");
                }
              }
            }
          }
        }
      }
      if (adjustCommitmentData.commitmentDetail.length > 0) {
        for (let index = 0; index < payGridData.length; index++) {
          for (
            let j = 0;
            j < adjustCommitmentData?.commitmentDetail.length;
            j++
          ) {
            let payGridObj = payGridData[index];
            let commitmentObj = adjustCommitmentData?.commitmentDetail[j];
            if (
              Number(payGridObj.Agreementid) == Number(commitmentObj.agreementID)
            ) {
              for (let k = 0; k < commitmentObj.adjustedInfo.length; k++) {
                let adjustedObj = commitmentObj.adjustedInfo[k];
                if (adjustedObj.type === "DDA") {
                  // alert("DDA Reached");
                  // let adjustDueRes: any = [];
                  let coverageEndDateInfo: any = [];
                  const adjustDueDatereq: any = [];
                  let coverageEndDate = "";
                  let scheduleRef = "";
                  let policyStatus = "";
                  if (actLDWArr.length > 0) {
                    coverageEndDateInfo = actLDWArr.filter(
                      (x) =>
                        String(x.agreementId) ===
                        String(payGridData[index].Agreementid)
                    );
                    if (coverageEndDateInfo.length > 0) {
                      if (Number(coverageEndDateInfo[0].action) == 2) {
                        const currentdate: Date = new Date(
                          new Date().toLocaleString("en-US", {
                            timeZone: "America/Chicago",
                          })
                        );
                        const current_date = new Date(
                          currentdate.getFullYear(),
                          currentdate.getMonth(),
                          currentdate.getDate()
                        );
                        coverageEndDate =
                          moment(current_date).format("MM/DD/YYYY");
                      } else if (Number(coverageEndDateInfo[0].action) == 1) {
                        coverageEndDate = "12/31/2099";
                        if (
                          Number(
                            payGridData[index].agreementRateDetails.perDayLdw
                          ) == 0
                        ) {
                          payGridData[index].agreementRateDetails.perDayLdw = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.weeklyRate
                            ) / 7
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.perDayLdwTax = (
                            (Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.weeklyRate
                            ) /
                              7) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.weeklyLdw =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.weeklyRate
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.weeklyLdwTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.weeklyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.biWeeklyLdw =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.biweeklyRate
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.biWeeklyLdwTax =
                            (
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .scheduleRates.biweeklyRate
                              ) *
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .ldwTaxRate
                              )
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.monthlyLdw =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.monthlyRate
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.monthlyLdwTax =
                            (
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .scheduleRates.monthlyRate
                              ) *
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .ldwTaxRate
                              )
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.semiMonthlyLdw =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.semimonthlyRate
                            ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.semiMonthlyLdwTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.semimonthlyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                        }
                      }
                    }
                  }
                  if (coverageEndDate == "") {
                    coverageEndDate = String(payGridData[index].coverageEndDate);
                  }
                  if (
                    payGridData[index].editSchedule != "" &&
                    payGridData[index].editSchedule != null
                  ) {
                    scheduleRef = payGridData[index].editSchedule;
                  } else {
                    scheduleRef =
                      payGridData[index].agreementRateDetails.scheduleRefCode;
                  }
                  if (actPolicyArr.length > 0) {
                    const policyChangeArrInfo = actPolicyArr.filter(
                      (x) =>
                        String(x.agreementId) ===
                        String(payGridData[index].Agreementid)
                    );
                    if (policyChangeArrInfo.length > 0) {
                      if (Number(policyChangeArrInfo[0].action) == 2) {
                        policyStatus = "I";
                      } else if (Number(policyChangeArrInfo[0].action) == 1) {
                        policyStatus = "A";
                        if (
                          Number(
                            payGridData[index].agreementRateDetails.perDayPolicy
                          ) == 0
                        ) {
                          payGridData[index].agreementRateDetails.perDayPolicy = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.weeklyRate
                            ) / 7
                          ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.perDayPolicyTax = (
                            (Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.weeklyRate
                            ) /
                              7) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.weeklyPolicy =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.weeklyRate
                            ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.weeklyPolicyTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.weeklyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.biWeeklyPolicy =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.biweeklyRate
                            ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.biWeeklyPolicyTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.biweeklyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.monthlyPolicy =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.monthlyRate
                            ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.monthlyPolicyTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.monthlyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.semiMonthlyPolicy = Number(
                            coverageEndDateInfo[0].cusCoverageData.policyInfo
                              .scheduleRates.semimonthlyRate
                          ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.semiMonthlyPolicyTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.semimonthlyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                        }
                      }
                    }
                  }
                  if (policyStatus == "" || policyStatus == null) {
                    policyStatus = String(payGridData[index].policyStatus);
                  }
                  const res = {
                    agreementId: String(payGridData[index].Agreementid),
                    agreementRateDetails: payGridData[index].agreementRateDetails,
                    currentDueDate: String(payGridData[index].CurrentDueDate),
                    dueDate: String(payGridData[index].Nextduedate),
                    coverageEndDate: coverageEndDate,
                    taxRate: String(payGridData[index].taxRate),
                    lateFee: String(payGridData[index].lateFee),
                    lateFeeTax: String(payGridData[index].lFeeTaxAmount),
                    policyStatus: String(policyStatus),
                    epoAmount: String(payGridData[index].EPOAmount),
                    epoTax: String(payGridData[index].EPOTax),
                    sacDate: String(payGridData[index].SAC_Date),
                    isLdwAllowed: String(payGridData[index].isLdwAllowed),
                    agreementNumber: String(payGridData[index].AgreementNumber),
                    carryRent: String(payGridData[index].carryRentAmountUsed),
                    carryRentTax: String(payGridData[index].carryRentTaxUsed),
                    scheduleRefCode: scheduleRef,
                  };
                  adjustDueDatereq.push(res);
                  const calcireq = {
                    rateResponse: adjustDueDatereq,
                    dueDate: moment(adjustedObj.value).format("MM/DD/YYYY"),
                  };
     
                  let resFromAdjCalci = await AdjustDueDatecalci(calcireq);
                  let nextduedate;
                  if (
                    resFromAdjCalci[0].nextDueDate != "SAC" &&
                    resFromAdjCalci[0].nextDueDate != "PIF" &&
                    resFromAdjCalci[0].nextDueDate != "EPO"
                  ) {
                    nextduedate = moment(resFromAdjCalci[0].nextDueDate).format(
                      "MM/DD/YYYY"
                    );
                  } else {
                    nextduedate = resFromAdjCalci[0].nextDueDate;
                  }
                  (payGridData[index].Nextduedate = nextduedate),
                    (payGridData[index].Amount = Number(
                      (
                        Number(resFromAdjCalci[0].totalAmount) +
                        Number(resFromAdjCalci[0].totalTax)
                      ).toFixed(2)
                    )),
                    // props.adjustDueDateReq[i].Schedule = props.adjustDueDateReq[i].Schedule,
                    (payGridData[index].Taxamount = Number(
                      resFromAdjCalci[0].totalTax
                    ));
                  payGridData[index].policyAmount = Number(
                    resFromAdjCalci[0].policyAmount
                  );
                  payGridData[index].dueDateModified = true;
                  // props.adjustDueDateReq[i].editSchedule=selectedSchedule
                  (payGridData[index].ldwTaxAmount = Number(
                    resFromAdjCalci[0].ldwTaxAmount
                  )),
                    (payGridData[index].agrLdwAmount = Number(
                      resFromAdjCalci[0].ldwAmount
                    ));
                  payGridData[index].policyTaxAmount = Number(
                    resFromAdjCalci[0].policyTaxAmount
                  );
                  payGridData[index].agreementRate = Number(
                    resFromAdjCalci[0].agreementRate
                  );
                  payGridData[index].rpayTaxAmount = Number(
                    resFromAdjCalci[0].rpayTaxAmount
                  );
                  payGridData[index].Agreementtype =
                    resFromAdjCalci[0].agreementType;
                  let filterAgreementData: any = [];
                  if(agreementId !== "0") {
                    filterAgreementData = payGridData.filter(
                      (el) =>
                        el.Agreementid == agreementId
                    );
                    filterAgreementData.push(payGridData[index])
                  } else {
                    filterAgreementData = payGridData.filter(
                      (el) =>
                        el.store_number ==
                        window.sessionStorage.getItem("storeNumber")
                    );
                  }
                  let colAgrId: any = [];
                  if(agreementId !== "0") {
                    filterAgreementData.map((el: any) => {
                      if(el.Agreementid == agreementId) {
                        colAgrId.push(el.Agreementid);
                      }
                    });
                  } else {
                    filterAgreementData.map((el: any) => {
                      colAgrId.push(el.Agreementid);
                    });
                  }
                  editCommitmentRes({
                    resArray: filterAgreementData,
                    adjustEditflag: 1,
                    selectedAgr: colAgrId,
                    adjustedAgreement: payGridData[index].Agreementid,
                  });
                } else if (adjustedObj.type === "FTT") {
                  // alert("Apply Free Time");
                  const nextduedate = moment(
                    adjustedObj.value.nextDueDate
                  ).format("MM/DD/YYYY");
                  const currentduedate = moment(
                    payGridData[index].CurrentDueDate
                  ).format("MM/DD/YYYY");
                  const date1 = new Date(currentduedate);
                  const date2 = new Date(nextduedate);
                  const time_difference = date2.getTime() - date1.getTime();
                  const days_difference = time_difference / (1000 * 3600 * 24);
                  if (adjustedObj.value.waiveOptService == true) {
                    // alert("reached the if condition of not null");
                    payGridData[index].Amount = 0.0;
                    payGridData[index].Taxamount = 0.0;
                    payGridData[index].policyAmount = 0.0;
                    payGridData[index].ldwTaxAmount = 0.0;
                    payGridData[index].agrLdwAmount = 0.0;
                    payGridData[index].policyTaxAmount = 0.0;
                    payGridData[index].agreementRate = 0.0;
                    payGridData[index].rpayTaxAmount = 0.0;
                    payGridData[index].Latelast10 = 0.0;
                    payGridData[index].lateFee = 0.0;
                    payGridData[index].lFeeTaxAmount = 0.0;
                    payGridData[index].Nextduedate = nextduedate;
                    payGridData[index].PromoFreeDaysApplied =
                      adjustedObj.value.freeTimeType == "PROMO"
                        ? Number(days_difference)
                        : payGridData[index].PromoFreeDaysApplied;
                    payGridData[index].FreeTime = {
                      freeTimeType: adjustedObj.value.freeTimeType,
                      freeTimeDays: Number(days_difference).toFixed(),
                      isOptServiceWaived: adjustedObj.value.waiveOptService,
                    };
                    // resArray: [payGridData[index]],
                    // selectedAgr: [payGridData[index].Agreementid],
                    let filterAgreementData: any = [];
                    if(agreementId !== "0") {
                      filterAgreementData = payGridData.filter(
                        (el) =>
                          el.Agreementid == agreementId
                      );
                      filterAgreementData.push(payGridData[index])
                    } else {
                      filterAgreementData = payGridData.filter(
                        (el) =>
                          el.store_number ==
                          window.sessionStorage.getItem("storeNumber")
                      );
                    }
                    let colAgrId: any = [];
                    if(agreementId !== "0") {
                      filterAgreementData.map((el: any) => {
                        if(el.Agreementid == agreementId) {
                          colAgrId.push(el.Agreementid);
                        }
                      });
                    } else {
                      filterAgreementData.map((el: any) => {
                        colAgrId.push(el.Agreementid);
                      });
                    }
                    editCommitmentRes({
                      resArray: filterAgreementData,
                      selectedAgr: colAgrId,
                    });
                  } else {
                    // alert("Free Time Reached");
                    let coverageEndDateInfo: any = [];
                    const adjustDueDatereq: any = [];
                    let coverageEndDate = "";
                    let policyStatus = "";
                    if (actLDWArr.length > 0) {
                      coverageEndDateInfo = actLDWArr.filter(
                        (x) =>
                          String(x.agreementId) ===
                          String(payGridData[index].Agreementid)
                      );
                      if (coverageEndDateInfo.length > 0) {
                        if (Number(coverageEndDateInfo[0].action) == 2) {
                          coverageEndDate = moment().format("MM/DD/YYYY");
                        } else if (Number(coverageEndDateInfo[0].action) == 1) {
                          coverageEndDate = "12/31/2099";
                          if (
                            Number(
                              payGridData[index].agreementRateDetails.perDayLdw
                            ) == 0
                          ) {
                            payGridData[index].agreementRateDetails.perDayLdw = (
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .scheduleRates.weeklyRate
                              ) / 7
                            ).toFixed(2);
                            payGridData[index].agreementRateDetails.perDayLdwTax =
                              (
                                (Number(
                                  coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                    .scheduleRates.weeklyRate
                                ) /
                                  7) *
                                Number(
                                  coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                    .ldwTaxRate
                                )
                              ).toFixed(2);
                            payGridData[index].agreementRateDetails.weeklyLdw =
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .scheduleRates.weeklyRate
                              ).toFixed(2);
                            payGridData[index].agreementRateDetails.weeklyLdwTax =
                              (
                                Number(
                                  coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                    .scheduleRates.weeklyRate
                                ) *
                                Number(
                                  coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                    .ldwTaxRate
                                )
                              ).toFixed(2);
                            payGridData[index].agreementRateDetails.biWeeklyLdw =
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .scheduleRates.biweeklyRate
                              ).toFixed(2);
                            payGridData[
                              index
                            ].agreementRateDetails.biWeeklyLdwTax = (
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .scheduleRates.biweeklyRate
                              ) *
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .ldwTaxRate
                              )
                            ).toFixed(2);
                            payGridData[index].agreementRateDetails.monthlyLdw =
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .scheduleRates.monthlyRate
                              ).toFixed(2);
                            payGridData[
                              index
                            ].agreementRateDetails.monthlyLdwTax = (
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .scheduleRates.monthlyRate
                              ) *
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .ldwTaxRate
                              )
                            ).toFixed(2);
                            payGridData[
                              index
                            ].agreementRateDetails.semiMonthlyLdw = Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.semimonthlyRate
                            ).toFixed(2);
                            payGridData[
                              index
                            ].agreementRateDetails.semiMonthlyLdwTax = (
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .scheduleRates.semimonthlyRate
                              ) *
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .ldwTaxRate
                              )
                            ).toFixed(2);
                          }
                        }
                      }
                    }
                    if (coverageEndDate == "") {
                      coverageEndDate = String(
                        payGridData[index].coverageEndDate
                      );
                    }
                    if (actPolicyArr.length > 0) {
                      // props.ldwChangeArr
                      const policyChangeArrInfo = actPolicyArr.filter(
                        (x) =>
                          String(x.agreementId) ===
                          String(payGridData[index].Agreementid)
                      );
                      if (policyChangeArrInfo.length > 0) {
                        if (Number(policyChangeArrInfo[0].action) == 2) {
                          policyStatus = "I";
                        } else if (Number(policyChangeArrInfo[0].action) == 1) {
                          policyStatus = "A";
                          if (
                            Number(
                              payGridData[index].agreementRateDetails.perDayPolicy
                            ) == 0
                          ) {
                            payGridData[index].agreementRateDetails.perDayPolicy =
                              (
                                Number(
                                  coverageEndDateInfo[0].cusCoverageData
                                    .policyInfo.scheduleRates.weeklyRate
                                ) / 7
                              ).toFixed(2);
                            payGridData[
                              index
                            ].agreementRateDetails.perDayPolicyTax = (
                              (Number(
                                coverageEndDateInfo[0].cusCoverageData.policyInfo
                                  .scheduleRates.weeklyRate
                              ) /
                                7) *
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.policyInfo
                                  .ldwTaxRate
                              )
                            ).toFixed(2);
                            payGridData[index].agreementRateDetails.weeklyPolicy =
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.policyInfo
                                  .scheduleRates.weeklyRate
                              ).toFixed(2);
                            payGridData[
                              index
                            ].agreementRateDetails.weeklyPolicyTax = (
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.policyInfo
                                  .scheduleRates.weeklyRate
                              ) *
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.policyInfo
                                  .ldwTaxRate
                              )
                            ).toFixed(2);
                            payGridData[
                              index
                            ].agreementRateDetails.biWeeklyPolicy = Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.biweeklyRate
                            ).toFixed(2);
                            payGridData[
                              index
                            ].agreementRateDetails.biWeeklyPolicyTax = (
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.policyInfo
                                  .scheduleRates.biweeklyRate
                              ) *
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.policyInfo
                                  .ldwTaxRate
                              )
                            ).toFixed(2);
                            payGridData[
                              index
                            ].agreementRateDetails.monthlyPolicy = Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.monthlyRate
                            ).toFixed(2);
                            payGridData[
                              index
                            ].agreementRateDetails.monthlyPolicyTax = (
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.policyInfo
                                  .scheduleRates.monthlyRate
                              ) *
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.policyInfo
                                  .ldwTaxRate
                              )
                            ).toFixed(2);
                            payGridData[
                              index
                            ].agreementRateDetails.semiMonthlyPolicy = Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.semimonthlyRate
                            ).toFixed(2);
                            payGridData[
                              index
                            ].agreementRateDetails.semiMonthlyPolicyTax = (
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.policyInfo
                                  .scheduleRates.semimonthlyRate
                              ) *
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.policyInfo
                                  .ldwTaxRate
                              )
                            ).toFixed(2);
                          }
                        }
                      }
                    }
                    if (policyStatus == "") {
                      policyStatus = String(payGridData[index].policyStatus);
                    }
                    let scheduleRef = "";
                    if (
                      payGridData[index].editSchedule != "" &&
                      payGridData[index].editSchedule != null
                    ) {
                      scheduleRef = payGridData[index].editSchedule;
                    } else {
                      scheduleRef =
                        payGridData[index].agreementRateDetails.scheduleRefCode;
                    }
                    const res = {
                      agreementId: String(payGridData[index].Agreementid),
                      agreementRateDetails:
                        payGridData[index].agreementRateDetails,
                      currentDueDate: String(payGridData[index].CurrentDueDate),
                      dueDate: String(payGridData[index].Nextduedate),
                      coverageEndDate: coverageEndDate,
                      taxRate: String(payGridData[index].taxRate),
                      lateFee: String(payGridData[index].lateFee),
                      lateFeeTax: String(payGridData[index].lFeeTaxAmount),
                      policyStatus: String(policyStatus),
                      epoAmount: String(payGridData[index].EPOAmount),
                      epoTax: String(payGridData[index].EPOTax),
                      sacDate: String(payGridData[index].SAC_Date),
                      isLdwAllowed: String(payGridData[index].isLdwAllowed),
                      agreementNumber: String(payGridData[index].AgreementNumber),
                      carryRent: String(payGridData[index].carryRentAmountUsed),
                      carryRentTax: String(payGridData[index].carryRentTaxUsed),
                      scheduleRefCode: scheduleRef,
                    };
                    adjustDueDatereq.push(res);
                    const calcireq = {
                      rateResponse: adjustDueDatereq,
                      dueDate: nextduedate,
                    };
                    let adjustDueRes = await AdjustDueDatecalci(calcireq);
                    let nextduedateUpdated;
                    if (
                      adjustDueRes[0].nextDueDate != "SAC" &&
                      adjustDueRes[0].nextDueDate != "PIF" &&
                      adjustDueRes[0].nextDueDate != "EPO"
                    ) {
                      nextduedateUpdated = moment(
                        adjustDueRes[0].nextDueDate
                      ).format("MM/DD/YYYY");
                    } else {
                      nextduedateUpdated = adjustDueRes[0].nextDueDate;
                    }
                    payGridData[index].Nextduedate = nextduedateUpdated;
                    payGridData[index].Amount =
                      Number(adjustDueRes[0].policyAmount) +
                      Number(adjustDueRes[0].ldwAmount) +
                      Number(adjustDueRes[0].ldwTaxAmount) +
                      Number(adjustDueRes[0].policyTaxAmount);
                    payGridData[index].Taxamount =
                      Number(adjustDueRes[0].ldwTaxAmount) +
                      Number(adjustDueRes[0].policyTaxAmount);
                    payGridData[index].policyAmount = Number(
                      adjustDueRes[0].policyAmount
                    );
                    payGridData[index].ldwTaxAmount = Number(
                      adjustDueRes[0].ldwTaxAmount
                    );
                    payGridData[index].agrLdwAmount = Number(
                      adjustDueRes[0].ldwAmount
                    );
                    payGridData[index].policyTaxAmount = Number(
                      adjustDueRes[0].policyTaxAmount
                    );
                    payGridData[index].agreementRate = 0.0;
                    payGridData[index].rpayTaxAmount = 0.0;
                    payGridData[index].PromoFreeDaysApplied =
                      adjustedObj.value.freeTimeType == "PROMO"
                        ? Number(days_difference)
                        : payGridData[index].PromoFreeDaysApplied;
                    payGridData[index].Latelast10 = 0.0;
                    payGridData[index].lateFee = 0.0;
                    payGridData[index].lFeeTaxAmount = 0.0;
                    payGridData[index].FreeTime = {
                      freeTimeType: adjustedObj.value.freeTimeType,
                      freeTimeDays: Number(days_difference).toFixed(),
                      isOptServiceWaived: adjustedObj.value.waiveOptService,
                    };
                    // resArray: [payGridData[index]],
                    // selectedAgr: [payGridData[index].Agreementid],
                    let filterAgreementData: any = [];
                    if(agreementId !== "0") {
                      filterAgreementData = payGridData.filter(
                        (el) =>
                          el.Agreementid == agreementId
                      );
                      filterAgreementData.push(payGridData[index])
                    } else {
                      filterAgreementData = payGridData.filter(
                        (el) =>
                          el.store_number ==
                          window.sessionStorage.getItem("storeNumber")
                      );
                    }
                    let colAgrId: any = [];
                    if(agreementId !== "0") {
                      filterAgreementData.map((el: any) => {
                        if(el.Agreementid == agreementId) {
                          colAgrId.push(el.Agreementid);
                        }
                      });
                    } else {
                      filterAgreementData.map((el: any) => {
                        colAgrId.push(el.Agreementid);
                      });
                    }
                    editCommitmentRes({
                      resArray: filterAgreementData,
                      selectedAgr: colAgrId,
                    });
                    // })();
                  }
                } else if (adjustedObj.type === "TOS") {
                  commitmentTOS = true;
                  const collectAgrIDS: any = [];
                  payGridData.map((payGridObj: any, index) => {
                    if(payGridObj.Agreementtype !== 'Club') {
                      collectAgrIDS.push(payGridObj.Agreementid);
                    }
                  });
                  
                  setadjustDueDateSelectedAgre(collectAgrIDS);
                  if (adjustedObj.value === "LDW") {
                    selectedGrid = index;
                    // (async () => {
                    const callLoadOtherAction: any = await commitmentLoadAction(
                      index, configPermissions
                    );
                    if (callLoadOtherAction !== undefined) {
                      const terminateLdw_Resp: any = await LDW_apiCall(
                        2,
                        callLoadOtherAction,
                        index
                      );
                      let filterAgreementData: any = [];
                      if(agreementId !== "0") {
                        filterAgreementData = payGridData.filter(
                          (el) =>
                            el.Agreementid == agreementId
                        );
                        filterAgreementData.push(payGridData[index])
                      } else {
                        filterAgreementData = payGridData.filter(
                          (el) =>
                            el.store_number ==
                            window.sessionStorage.getItem("storeNumber")
                        );
                      }
                      let colAgrId: any = [];
                      if(agreementId !== "0") {
                        filterAgreementData.map((el: any) => {
                          if(el.Agreementid == agreementId) {
                            colAgrId.push(el.Agreementid);
                          }
                        });
                      } else {
                        filterAgreementData.map((el: any) => {
                          colAgrId.push(el.Agreementid);
                        });
                      }
                      editCommitmentRes({
                        resArray: filterAgreementData,
                        selectedAgr: colAgrId,
                      });
                    }
                    // })();
                  } else if (adjustedObj.value === "Policy") {
                    selectedGrid = index;
                    // (async () => {
                    const callLoadOtherAction: any = await commitmentLoadAction(
                      index,configPermissions
                    );
                    if (callLoadOtherAction !== undefined) {
                      const terminateLdw_Resp: any = await Policy_apiCall(
                        2,
                        callLoadOtherAction,
                        index
                      );
                      let filterAgreementData: any = [];
                      if(agreementId !== "0") {
                        filterAgreementData = payGridData.filter(
                          (el) =>
                            el.Agreementid == agreementId
                        );
                        filterAgreementData.push(payGridData[index])
                      } else {
                        filterAgreementData = payGridData.filter(
                          (el) =>
                            el.store_number ==
                            window.sessionStorage.getItem("storeNumber")
                        );
                      }
                      let colAgrId: any = [];
                      if(agreementId !== "0") {
                        filterAgreementData.map((el: any) => {
                          if(el.Agreementid == agreementId) {
                            colAgrId.push(el.Agreementid);
                          }
                        });
                      } else {
                        filterAgreementData.map((el: any) => {
                          colAgrId.push(el.Agreementid);
                        });
                      }
                      editCommitmentRes({
                        resArray: filterAgreementData,
                        selectedAgr: colAgrId,
                      });
                    }
                    // })();
                  }
                } else if (adjustedObj.type === "AOS") {
                  if (adjustedObj.value === "LDW") {
                    selectedGrid = index;
                    // (async () => {
                    const callLoadOtherAction: any = await commitmentLoadAction(
                      index,configPermissions
                    );
                    if (callLoadOtherAction !== undefined) {
                      const terminateLdw_Resp: any = await LDW_apiCall(
                        1,
                        callLoadOtherAction,
                        index
                      );
                      let filterAgreementData: any = [];
                      if(agreementId !== "0") {
                        filterAgreementData = payGridData.filter(
                          (el) =>
                            el.Agreementid == agreementId
                        );
                        filterAgreementData.push(payGridData[index])
                      } else {
                        filterAgreementData = payGridData.filter(
                          (el) =>
                            el.store_number ==
                            window.sessionStorage.getItem("storeNumber")
                        );
                      }
                      let colAgrId: any = [];
                      if(agreementId !== "0") {
                        filterAgreementData.map((el: any) => {
                          if(el.Agreementid == agreementId) {
                            colAgrId.push(el.Agreementid);
                          }
                        });
                      } else {
                        filterAgreementData.map((el: any) => {
                          colAgrId.push(el.Agreementid);
                        });
                      }
                      editCommitmentRes({
                        resArray: filterAgreementData,
                        selectedAgr: colAgrId,
                      });
                    }
                    // })();
                  } else if (adjustedObj.value === "Policy") {
                    selectedGrid = index;
                    // (async () => {
                    const callLoadOtherAction: any = await commitmentLoadAction(
                      index , configPermissions
                    );
                    if (callLoadOtherAction !== undefined) {
                      const terminateLdw_Resp: any = await Policy_apiCall(
                        1,
                        callLoadOtherAction,
                        index
                      );
                      let filterAgreementData: any = [];
                      if(agreementId !== "0") {
                        filterAgreementData = payGridData.filter(
                          (el) =>
                            el.Agreementid == agreementId
                        );
                        filterAgreementData.push(payGridData[index])
                      } else {
                        filterAgreementData = payGridData.filter(
                          (el) =>
                            el.store_number ==
                            window.sessionStorage.getItem("storeNumber")
                        );
                      }
                      let colAgrId: any = [];
                      if(agreementId !== "0") {
                        filterAgreementData.map((el: any) => {
                          if(el.Agreementid == agreementId) {
                            colAgrId.push(el.Agreementid);
                          }
                        });
                      } else {
                        filterAgreementData.map((el: any) => {
                          colAgrId.push(el.Agreementid);
                        });
                      }
                      editCommitmentRes({
                        resArray: filterAgreementData,
                        selectedAgr: colAgrId,
                      });
                    }
                    // })();
                  }
                } else if (adjustedObj.type === "AS") {
                  let insertArray: any[] = [];
                  insertArray.push({
                    sustype:
                      Number(payGridData[index].suspenseAdd) > 0 ? "Add" : "type",
                    agreementnumber: payGridData[index].Agreementid,
                    agreementid: payGridData[index].AgreementNumber,
                    schedure: payGridData[index].Schedule,
                    paymentamount: payGridData[index].Amount,
                    epoamount: payGridData[index].EPOAmount,
                    suspenseamount: Number(
                      payGridData[index].SuspenseAmount
                    ).toFixed(2),
                    newsuspense: Number(payGridData[index].suspenseAdd).toFixed(
                      2
                    ),
                    agreementType: payGridData[index].Agreementtype,
                  });
                  setaddsus("0.00");
                  setusesus("0.00");
                  settranssus("0.00");
                  setaddsus(adjustedObj.value);
                  insertArray[0].newsuspense = adjustedObj.value;
                  insertArray[0].sustype = "Add"; // Check This
                  setmasteraddsuspensearray(insertArray);

                  const addfilterzero = insertArray.filter(function (e) {
                    return e.sustype != "type" && Number(e.newsuspense) > 0;
                  });
                  const usefilterzero = masterusesuspensearray.filter(function (
                    e
                  ) {
                    return e.sustype != "type" && Number(e.newsuspense) > 0;
                  });
                  settotalsuspensemaster([...addfilterzero, ...usefilterzero]);
                  for (let i = 0; i < addfilterzero.length; i++) {
                    let index = paygridvalues.findIndex(
                      (x) =>
                        String(x.Agreementid) ===
                        String(addfilterzero[i].agreementnumber)
                    );
                    paygridvalues[index].suspenseAdd = Number(
                      addfilterzero[i].newsuspense
                    );
                    // paygridvalues[index].Amount=Number(paygridvalues[index].actualAmount)+Number(addfilterzero[i].newsuspense)
                    let receiptObj = ReceiptCategoriesRequestParam.filter(
                      (e) =>
                        e.agreementId == Number(addfilterzero[i].agreementnumber)
                    );
                    let receiptObjaccept = acceptPaymentParam.filter(
                      (e) =>
                        e.agreementId == Number(addfilterzero[i].agreementnumber)
                    );
                    if (receiptObj[0] != undefined) {
                      receiptObj[0].suspenseAdd = Number(
                        addfilterzero[i].newsuspense
                      );
                    }
                    if (
                      String(addfilterzero[i].agreementnumber) ==
                      String(receiptObjaccept[0].agreementId)
                    ) {
                      acceptPaymentParam[i].addTransferSusFlag = 1;
                    }
                  }
                  setReceiptCategory(ReceiptCategoriesRequestParam);
                  let totalsusamount: number;
                  totalsusamount = 0;
                  for (let i = 0; i < addfilterzero.length; i++) {
                    totalsusamount =
                      totalsusamount + Number(addfilterzero[i].newsuspense);
                  }
                  // setsuspensePopupClass(
                  //   `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
                  // );
                  setaddsuspense(false);
                  let tenderedAmount = 0;
                  for (let i = 0; i < tendertypeglobalarray.length; i++) {
                    tenderedAmount =
                      tenderedAmount + Number(tendertypeglobalarray[i].Amount);
                  }
                  let resAmount = 0.0;
                  for (let i = 0; i < checkedAgr.length; i++) {
                    resAmount = Number(checkedAgr[i].Amount) + Number(resAmount);
                  }
                  const addSuspenseAmount = addfilterzero.filter(function (e) {
                    return e.sustype == "Add" && Number(e.newsuspense) > 0;
                  });
                  const useSuspenseAmount = usefilterzero.filter(function (e) {
                    return e.sustype == "Used" && Number(e.newsuspense) > 0;
                  });

                  let addSusTotalAmount =
                    addSuspenseAmount.length == 0
                      ? 0.0
                      : addSuspenseAmount
                          .map((bill) => bill.newsuspense)
                          .reduce((acc, bill) =>
                            (Number(bill) + Number(acc)).toFixed(2)
                          );
                  let usedSusTotalAmount =
                    useSuspenseAmount.length == 0
                      ? 0.0
                      : useSuspenseAmount
                          .map((bill) => bill.newsuspense)
                          .reduce((acc, bill) =>
                            (Number(bill) + Number(acc)).toFixed(2)
                          );

                  let resTotalAmount = 0.0;
                  if (paymentOrigin == "Pay By Phone") {
                    resAmount = Number(resAmount) + Number(convAmtWTax);
                  }
                  resAmount = Number(resAmount) - Number(carryRentAmount);
                  resTotalAmount =
                    totalduetendertype.length == 0
                      ? 0.0
                      : totalduetendertype
                          .map((bill) => bill.Amount)
                          .reduce((acc, bill) =>
                            (Number(bill) + Number(acc)).toFixed(2)
                          );
                  if (Number(resTotalAmount) > Number(resAmount)) {
                    resetPayment(undefined);
                    setremainingamountdue(
                      Number(
                        (
                          Number(resAmount) -
                          Number(availableCreds)+
                          Number(donationAmountAdd) +
                          Number(addSusTotalAmount) -
                          Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                        ).toFixed(2)
                      )
                    );
                    setchange("0.00");
                    setAcceptpaymentbuttonenable(true);
                  } else {
                    if (
                      Number(resTotalAmount) >=
                      Number(
                        (
                          Number(resAmount) -
                          Number(availableCreds)+
                          Number(donationAmountAdd) +
                          Number(addSusTotalAmount) -
                          Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                        ).toFixed(2)
                      )
                    ) {
                      setchange(
                        Number(resTotalAmount) -
                          Number(
                            (
                              Number(resAmount) -
                              Number(availableCreds)+
                              Number(donationAmountAdd) +
                              Number(addSusTotalAmount) -
                              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                            ).toFixed(2)
                          )
                      );
                      setremainingamountdue("0.00");
                      setRemaniningAmtStatic("0.00");
                      setAcceptpaymentbuttonenable(false);
                    } else {
                      setremainingamountdue(
                        Number(
                          (
                            Number(resAmount) -
                            Number(availableCreds) +
                            Number(donationAmountAdd)+
                            Number(addSusTotalAmount) -
                            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                          ).toFixed(2)
                        ) - Number(resTotalAmount)
                      );
                      setRemaniningAmtStatic(
                        Number(
                          (
                            Number(resAmount) -
                            Number(availableCreds)+
                            Number(donationAmountAdd) +
                            Number(addSusTotalAmount) -
                            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                          ).toFixed(2)
                        ) - Number(resTotalAmount)
                      );
                      setchange("0.00");
                      setAcceptpaymentbuttonenable(true);
                    }
                  }

                  settotaldue(resAmount);
                  // if (isRevenueRecogActivated) {
                  //   multipleAdjustRevenue();
                  // } else {
                    multipleAdjust();
                  // }
                  let filterAgreementData: any = [];
                  if(agreementId !== "0") {
                    filterAgreementData = payGridData.filter(
                      (el) =>
                        el.Agreementid == agreementId
                    );
                    filterAgreementData.push(payGridData[index])
                  } else {
                    filterAgreementData = payGridData.filter(
                      (el) =>
                        el.store_number ==
                        window.sessionStorage.getItem("storeNumber")
                    );
                  }
                  let colAgrId: any = [];
                  if(agreementId !== "0") {
                    filterAgreementData.map((el: any) => {
                      if(el.Agreementid == agreementId) {
                        colAgrId.push(el.Agreementid);
                      }
                    });
                  } else {
                    filterAgreementData.map((el: any) => {
                      colAgrId.push(el.Agreementid);
                    });
                  }
                  editCommitmentRes({
                    resArray: filterAgreementData,
                    selectedAgr: colAgrId,
                  });
                } else if (adjustedObj.type === "US") {
                  let insertArray: any[] = [];
                  insertArray.push({
                    sustype:
                      Number(payGridData[index].suspenseSub) > 0
                        ? "Used"
                        : "type",
                    agreementnumber: payGridData[index].Agreementid,
                    agreementid: payGridData[index].AgreementNumber,
                    schedure: payGridData[index].Schedule,
                    paymentamount: payGridData[index].Amount,
                    epoamount: payGridData[index].EPOAmount,
                    suspenseamount: Number(
                      payGridData[index].SuspenseAmount
                    ).toFixed(2),
                    newsuspense: Number(payGridData[index].suspenseSub).toFixed(
                      2
                    ),
                    agreementType: payGridData[index].Agreementtype,
                  });

                  setaddsus("0.00");
                  setusesus("0.00");
                  settranssus("0.00");
                  setusesus(adjustedObj.value);
                  insertArray[0].newsuspense = adjustedObj.value;
                  insertArray[0].sustype = "Used";
                  setmasterusesuspensearray(insertArray);
                  const addfilterzero = masteraddsuspensearray.filter(function (
                    e
                  ) {
                    return e.sustype != "type" && Number(e.newsuspense) > 0;
                  });
                  const usefilterzero = insertArray.filter(function (e) {
                    return e.sustype != "type" && Number(e.newsuspense) > 0;
                  });

                  settotalsuspensemaster([...addfilterzero, ...usefilterzero]);

                  for (let i = 0; i < usefilterzero.length; i++) {
                    let index = paygridvalues.findIndex(
                      (x) =>
                        String(x.Agreementid) ===
                        String(usefilterzero[i].agreementnumber)
                    );
                    paygridvalues[index].suspenseSub = Number(
                      usefilterzero[i].newsuspense
                    );
                    if (
                      usefilterzero[i].agreementnumber ==
                      ReceiptCategoriesRequestParam[i].agreementId
                    ) {
                      ReceiptCategoriesRequestParam[i].suspenseSub = Number(
                        usefilterzero[i].newsuspense
                      );
                    }
                  }
                  setReceiptCategory(ReceiptCategoriesRequestParam);
                  let totalsusamountuse: number;
                  totalsusamountuse = 0;
                  for (let i = 0; i < usefilterzero.length; i++) {
                    totalsusamountuse =
                      totalsusamountuse + Number(usefilterzero[i].newsuspense);
                  }
                  let resAmount = 0.0;
                  for (let i = 0; i < checkedAgr.length; i++) {
                    resAmount = Number(checkedAgr[i].Amount) + Number(resAmount);
                  }
                  const addSuspenseAmount = addfilterzero.filter(function (e) {
                    return e.sustype == "Add" && Number(e.newsuspense) > 0;
                  });
                  const useSuspenseAmount = usefilterzero.filter(function (e) {
                    return e.sustype == "Used" && Number(e.newsuspense) > 0;
                  });
                  let addSusTotalAmount =
                    addSuspenseAmount.length == 0
                      ? 0.0
                      : addSuspenseAmount
                          .map((bill) => bill.newsuspense)
                          .reduce((acc, bill) =>
                            (Number(bill) + Number(acc)).toFixed(2)
                          );
                  let usedSusTotalAmount =
                    useSuspenseAmount.length == 0
                      ? 0.0
                      : useSuspenseAmount
                          .map((bill) => bill.newsuspense)
                          .reduce((acc, bill) =>
                            (Number(bill) + Number(acc)).toFixed(2)
                          );

                  let resTotalAmount = 0.0;
                  if (paymentOrigin == "Pay By Phone") {
                    resAmount = Number(resAmount) + Number(convAmtWTax);
                  }
                  resAmount = Number(resAmount) - Number(carryRentAmount);
                  resTotalAmount =
                    totalduetendertype.length == 0
                      ? 0.0
                      : totalduetendertype
                          .map((bill) => bill.Amount)
                          .reduce((acc, bill) =>
                            (Number(bill) + Number(acc)).toFixed(2)
                          );

                  if (Number(resTotalAmount) > Number(resAmount)) {
                    resetPayment(undefined);
                    setremainingamountdue(
                      Number(
                        (
                          Number(resAmount) -
                          Number(availableCreds)+
                          Number(donationAmountAdd) +
                          Number(addSusTotalAmount) -
                          Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                        ).toFixed(2)
                      )
                    );
                    setchange("0.00");
                    setAcceptpaymentbuttonenable(true);
                  } else {
                    if (
                      Number(resTotalAmount) >=
                      Number(
                        (
                          Number(resAmount) -
                          Number(availableCreds)+
                          Number(donationAmountAdd) +
                          Number(addSusTotalAmount) -
                          Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                        ).toFixed(2)
                      )
                    ) {
                      setchange(
                        Number(resTotalAmount) -
                          Number(
                            (
                              Number(resAmount) -
                              Number(availableCreds)+
                              Number(donationAmountAdd) +
                              Number(addSusTotalAmount) -
                              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                            ).toFixed(2)
                          )
                      );
                      setremainingamountdue("0.00");
                      setRemaniningAmtStatic("0.00");
                      setAcceptpaymentbuttonenable(false);
                    } else {
                      setremainingamountdue(
                        Number(
                          (
                            Number(resAmount) -
                            Number(availableCreds)+
                            Number(donationAmountAdd) +
                            Number(addSusTotalAmount) -
                            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                          ).toFixed(2)
                        ) - Number(resTotalAmount)
                      );
                      setRemaniningAmtStatic(
                        Number(
                          (
                            Number(resAmount) -
                            Number(availableCreds)+
                            Number(donationAmountAdd) +
                            Number(addSusTotalAmount) -
                            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                          ).toFixed(2)
                        ) - Number(resTotalAmount)
                      );
                      setchange("0.00");
                      setAcceptpaymentbuttonenable(true);
                    }
                  }

                  settotaldue(resAmount);
                  // if (isRevenueRecogActivated) {
                  //   multipleAdjustRevenue();
                  // } else {
                    multipleAdjust();
                  // }
                  let filterAgreementData: any = [];
                  if(agreementId !== "0") {
                    filterAgreementData = payGridData.filter(
                      (el) =>
                        el.Agreementid == agreementId
                    );
                    filterAgreementData.push(payGridData[index])
                  } else {
                    filterAgreementData = payGridData.filter(
                      (el) =>
                        el.store_number ==
                        window.sessionStorage.getItem("storeNumber")
                    );
                  }
                  let colAgrId: any = [];
                  if(agreementId !== "0") {
                    filterAgreementData.map((el: any) => {
                      if(el.Agreementid == agreementId) {
                        colAgrId.push(el.Agreementid);
                      }
                    });
                  } else {
                    filterAgreementData.map((el: any) => {
                      colAgrId.push(el.Agreementid);
                    });
                  }
                  editCommitmentRes({
                    resArray: filterAgreementData,
                    selectedAgr: colAgrId,
                  });
                } else if (adjustedObj.type === "AEF") {
                  const totalWithOutTax = Number(
                    Number(
                      Number(adjustedObj.value.epoAmount) +
                        Number(adjustedObj.value.pastDueRent) +
                        payGridData[index].lateFee
                    ).toFixed(2)
                  );
                  const epoFieldValuesObject = {
                    pastDueRent: Number(adjustedObj.value.pastDueRent).toFixed(2),
                    epoAmt: Number(adjustedObj.value.epoAmount),
                    taxAmt: Number(
                      (
                        totalWithOutTax * Number(payGridData[index].taxRate)
                      ).toFixed(2)
                    ),
                    total: (
                      Number(
                        Number(adjustedObj.value.epoAmount) +
                          Number(adjustedObj.value.pastDueRent) +
                          Number(payGridData[index].lateFee) +
                          Number(
                            (
                              totalWithOutTax * payGridData[index].taxRate
                            ).toFixed(2)
                          )
                      ) - Number(payGridData[index].SuspenseAmount)
                    ).toFixed(2),
                  };
                  selectedGrid = index;
                  await acceptEpoButton(
                    epoFieldValuesObject,
                    false,
                    "CommitmentEPO"
                  );
                  let filterAgreementData: any = [];
                  if(agreementId !== "0") {
                    filterAgreementData = payGridData.filter(
                      (el) =>
                        el.Agreementid == agreementId
                    );
                    filterAgreementData.push(payGridData[index])
                  } else {
                    filterAgreementData = payGridData.filter(
                      (el) =>
                        el.store_number ==
                        window.sessionStorage.getItem("storeNumber")
                    );
                  }
                  let colAgrId: any = [];
                  if(agreementId !== "0") {
                    filterAgreementData.map((el: any) => {
                      if(el.Agreementid == agreementId) {
                        colAgrId.push(el.Agreementid);
                      }
                    });
                  } else {
                    filterAgreementData.map((el: any) => {
                      colAgrId.push(el.Agreementid);
                    });
                  }
                  editCommitmentRes({
                    resArray: filterAgreementData,
                    selectedAgr: colAgrId,
                  });
                } else if (adjustedObj.type === "ES") {
                  const adjustDueDatereq: any = [];
                  let coverageEndDateInfo: any = [];
                  let policyStatus = "";
                  let coverageEndDate = "";

                  if (actLDWArr.length > 0) {
                    coverageEndDateInfo = actLDWArr.filter(
                      (x) =>
                        String(x.agreementId) ===
                        String(payGridData[index].Agreementid)
                    );

                    if (coverageEndDateInfo.length > 0) {
                      if (Number(coverageEndDateInfo[0].action) == 2) {
                        const currentdate: Date = new Date(
                          new Date().toLocaleString("en-US", {
                            timeZone: "America/Chicago",
                          })
                        );
                        const current_date = new Date(
                          currentdate.getFullYear(),
                          currentdate.getMonth(),
                          currentdate.getDate()
                        );
                        coverageEndDate =
                          moment(current_date).format("MM/DD/YYYY");
                      } else if (Number(coverageEndDateInfo[0].action) == 1) {
                        coverageEndDate = "12/31/2099";
                        if (
                          Number(
                            payGridData[index].agreementRateDetails.perDayLdw
                          ) == 0
                        ) {
                          payGridData[index].agreementRateDetails.perDayLdw = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.weeklyRate
                            ) / 7
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.perDayLdwTax = (
                            (Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.weeklyRate
                            ) /
                              7) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.weeklyLdw =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.weeklyRate
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.weeklyLdwTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.weeklyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.biWeeklyLdw =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.biweeklyRate
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.biWeeklyLdwTax =
                            (
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .scheduleRates.biweeklyRate
                              ) *
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .ldwTaxRate
                              )
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.monthlyLdw =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.monthlyRate
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.monthlyLdwTax =
                            (
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .scheduleRates.monthlyRate
                              ) *
                              Number(
                                coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                  .ldwTaxRate
                              )
                            ).toFixed(2);
                          payGridData[index].agreementRateDetails.semiMonthlyLdw =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.semimonthlyRate
                            ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.semiMonthlyLdwTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .scheduleRates.semimonthlyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.ldwInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                        }
                      }
                    }
                  }
                  if (coverageEndDate == "") {
                    coverageEndDate = String(payGridData[index].coverageEndDate);
                  }
                  if (actPolicyArr.length > 0) {
                    const policyChangeArrInfo = actPolicyArr.filter(
                      (x) =>
                        String(x.agreementId) ===
                        String(payGridData[index].Agreementid)
                    );
                    if (policyChangeArrInfo.length > 0) {
                      if (Number(policyChangeArrInfo[0].action) == 2) {
                        policyStatus = "I";
                      } else if (Number(policyChangeArrInfo[0].action) == 1) {
                        policyStatus = "A";
                        if (
                          Number(
                            payGridData[index].agreementRateDetails.perDayPolicy
                          ) == 0
                        ) {
                          payGridData[index].agreementRateDetails.perDayPolicy = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.weeklyRate
                            ) / 7
                          ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.perDayPolicyTax = (
                            (Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.weeklyRate
                            ) /
                              7) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.weeklyPolicy =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.weeklyRate
                            ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.weeklyPolicyTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.weeklyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.biWeeklyPolicy =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.biweeklyRate
                            ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.biWeeklyPolicyTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.biweeklyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[index].agreementRateDetails.monthlyPolicy =
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.monthlyRate
                            ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.monthlyPolicyTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.monthlyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.semiMonthlyPolicy = Number(
                            coverageEndDateInfo[0].cusCoverageData.policyInfo
                              .scheduleRates.semimonthlyRate
                          ).toFixed(2);
                          payGridData[
                            index
                          ].agreementRateDetails.semiMonthlyPolicyTax = (
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .scheduleRates.semimonthlyRate
                            ) *
                            Number(
                              coverageEndDateInfo[0].cusCoverageData.policyInfo
                                .ldwTaxRate
                            )
                          ).toFixed(2);
                        }
                      }
                    }
                  }
                  if (policyStatus == "") {
                    policyStatus = String(payGridData[index].policyStatus);
                  }
                  const res = {
                    agreementId: String(payGridData[index].Agreementid),
                    agreementRateDetails: payGridData[index].agreementRateDetails,
                    currentDueDate: String(payGridData[index].CurrentDueDate),
                    dueDate: String(payGridData[index].Nextduedate),
                    coverageEndDate: String(coverageEndDate),
                    taxRate: String(payGridData[index].taxRate),
                    lateFee: String(payGridData[index].lateFee),
                    lateFeeTax: String(payGridData[index].lFeeTaxAmount),
                    policyStatus: String(policyStatus),
                    epoAmount: String(payGridData[index].EPOAmount),
                    epoTax: String(payGridData[index].EPOTax),
                    sacDate: String(payGridData[index].SAC_Date),
                    carryRent: String(payGridData[index].carryRentAmountUsed),
                    carryRentTax: String(payGridData[index].carryRentTaxUsed),
                    agreementNumber: String(payGridData[index].AgreementNumber),
                    scheduleRefCode: "",
                  };
                  adjustDueDatereq.push(res);
                  const editScheudleRequest = {
                    rateResponse: adjustDueDatereq,
                    schedule: adjustedObj.value,
                  };
                  const adjustDueRes = await AdjustDueDatecalci(
                    editScheudleRequest
                  );
                  let nextduedate;
                  if (
                    adjustDueRes[0].nextDueDate != "SAC" &&
                    adjustDueRes[0].nextDueDate != "PIF" &&
                    adjustDueRes[0].nextDueDate != "EPO"
                  ) {
                    nextduedate = moment(adjustDueRes[0].nextDueDate).format(
                      "MM/DD/YYYY"
                    );
                  } else {
                    nextduedate = adjustDueRes[0].nextDueDate;
                  }
                  // if(adjustedObj.value === 'MON'){

                  // }
                  let updatedSchedule = "";
                  if (adjustedObj.value.includes("SEMI")) {
                    updatedSchedule = "Semi-Monthly";
                  } else if (adjustedObj.value === "MON") {
                    updatedSchedule = "Monthly";
                  } else if (adjustedObj.value === "BIWK") {
                    updatedSchedule = "Bi-Weekly";
                  } else if (adjustedObj.value === "WK") {
                    updatedSchedule = "Weekly";
                  }

                  (payGridData[index].Amount =
                    Number(adjustDueRes[0].totalAmount) +
                    Number(adjustDueRes[0].totalTax)),
                    (payGridData[index].Schedule = updatedSchedule),
                    (payGridData[index].Taxamount = Number(
                      adjustDueRes[0].totalTax
                    )),
                    (payGridData[index].Nextduedate = nextduedate);
                  payGridData[index].policyAmount = Number(
                    adjustDueRes[0].policyAmount
                  );
                  payGridData[index].editSchedule = adjustedObj.value;
                  (payGridData[index].ldwTaxAmount = Number(
                    adjustDueRes[0].ldwTaxAmount
                  )),
                    (payGridData[index].agrLdwAmount = Number(
                      adjustDueRes[0].ldwAmount
                    ));
                  payGridData[index].policyTaxAmount = Number(
                    adjustDueRes[0].policyTaxAmount
                  );
                  payGridData[index].agreementRate = Number(
                    adjustDueRes[0].agreementRate
                  );
                  payGridData[index].Agreementtype =
                    adjustDueRes[0].agreementType;
                  // adjustDueRes[index].Agreementtype == "Club"
                  //   ? "Club"
                  //   : adjustDueRes[0].agreementType;
                  payGridData[index].rpayTaxAmount = Number(
                    adjustDueRes[0].rpayTaxAmount
                  );
                  // resArray: [payGridData[index]],
                  // selectedAgr: [payGridData[index].Agreementid],
                  let filterAgreementData: any = [];
                  if(agreementId !== "0") {
                    filterAgreementData = payGridData.filter(
                      (el) =>
                        el.Agreementid == agreementId
                    );
                    filterAgreementData.push(payGridData[index])
                  } else {
                    filterAgreementData = payGridData.filter(
                      (el) =>
                        el.store_number ==
                        window.sessionStorage.getItem("storeNumber")
                    );
                  }
                  let colAgrId: any = [];
                  if(agreementId !== "0") {
                    filterAgreementData.map((el: any) => {
                      if(el.Agreementid == agreementId) {
                        colAgrId.push(el.Agreementid);
                      }
                    });
                  } else {
                    filterAgreementData.map((el: any) => {
                      colAgrId.push(el.Agreementid);
                    });
                  }
                  editCommitmentRes({
                    resArray: filterAgreementData,
                    adjustEditflag: 0,
                    selectedAgr: colAgrId,
                  });
                  // })();
                } else if (adjustedObj.type === "TUS") {
                  let insertArray: any[] = [];
                  let totalsusamount = 0;
                  let transferedSupenseAmount = 0;
                  for (let i = 0; i < paygridvalues?.length; i++) {
                    if (
                      paygridvalues[i].Agreementtype != "Club" &&
                      paygridvalues[i].Nextduedate != "PIF" &&
                      paygridvalues[i].Nextduedate != "SAC" &&
                      paygridvalues[i].Nextduedate != "EPO" &&
                      paygridvalues[i].number_of_paymentsmade > 0 &&
                      String(paygridvalues[i].store_number) ==
                        String(window.sessionStorage.getItem("storeNumber")) &&
                      !paygridvalues[i].isExchanged
                    ) {
                      totalsusamount =
                        Number(totalsusamount) +
                        Number(paygridvalues[i].SuspenseAmount);
                      transferedSupenseAmount =
                        Number(transferedSupenseAmount) +
                        Number(paygridvalues[i].transferOldSuspense);
                      insertArray.push({
                        sustype: "type",
                        agreementnumber: paygridvalues[i].AgreementNumber,
                        agreementid: paygridvalues[i].Agreementid,
                        schedure: paygridvalues[i].Schedule,
                        paymentamount: paygridvalues[i].Amount,
                        epoamount: paygridvalues[i].EPOAmount,
                        suspenseamount: Number(
                          paygridvalues[i].SuspenseAmount
                        ).toFixed(2),
                        newsuspense: Number(
                          paygridvalues[i].SuspenseAmount
                        ).toFixed(2),
                        agreementType: paygridvalues[i].Agreementtype,
                      });
                      settotolsuspenseamount(Number(totalsusamount).toFixed(2));
                      setdifferenceamount(
                        Number(
                          (
                            Number(transferedSupenseAmount) -
                            Number(totalsusamount)
                          ).toFixed(2)
                        )
                      );
                    }
                  }

                  setmastertranssuspensearray([...insertArray]);
                  setaddsus("0.00");
                  setusesus("0.00");
                  settranssus("0.00");
                  insertArray.map((obj) => {
                    adjustedObj.value.map((adjObj, index) => {
                      if (
                        Number(adjObj.agreementID) === Number(obj.agreementid)
                      ) {
                        insertArray[index].newsuspense = adjObj.value;
                        insertArray[index].sustype = "Trans";
                      }
                    });
                  });

                  let newtransferedSuspense = 0;
                  let ActualSuspenseAmount = 0;
                  for (let i = 0; i < insertArray.length; i++) {
                    newtransferedSuspense =
                      newtransferedSuspense + Number(insertArray[i].newsuspense);
                    ActualSuspenseAmount =
                      ActualSuspenseAmount +
                      Number(insertArray[i].suspenseamount);
                  }
                  settotalnewsuspenseamount(newtransferedSuspense);
                  let totaltransnewsusamount: number;
                  totaltransnewsusamount = 0;
                  for (let i = 0; i < insertArray.length; i++) {
                    totaltransnewsusamount =
                      totaltransnewsusamount + insertArray[i].newsuspense;
                  }
                  settotalnewsuspenseamount(totaltransnewsusamount);
                  for (let i = 0; i < insertArray.length; i++) {
                    if (
                      insertArray[i].suspenseamount < insertArray[i].newsuspense
                    ) {
                      insertArray[i].sustype = "TransferAdd";
                    } else {
                      insertArray[i].sustype = "TransferUsed";
                    }
                  }
                  let newInsertArray: any = [];
                  for (let i = 0; i < insertArray.length; i++) {
                    let indexValue: any = paygridvalues.findIndex(
                      (x) =>
                        String(x.Agreementid) ===
                        String(insertArray[i].agreementid)
                    );
                    if (
                      insertArray[i].sustype == "TransferAdd" ||
                      "TransferUsed"
                    ) {
     
                      if (
                        Number(insertArray[i].newsuspense) -
                          Number(paygridvalues[indexValue].transferOldSuspense) >
                        0
                      ) {
                        newInsertArray.push({
                          sustype: "TransferAdd",
                          agreementnumber: insertArray[i].agreementnumber,
                          agreementid: insertArray[i].agreementid,
                          schedure: insertArray[i].schedure,
                          paymentamount: insertArray[i].paymentamount,
                          epoamount: Number(insertArray[i].epoamount),
                          suspenseamount: insertArray[i].suspenseamount,
                          newsuspense:
                            Number(insertArray[i].newsuspense) -
                            Number(paygridvalues[indexValue].transferOldSuspense),
                          agreementType: insertArray[i].agreementType,
                        });
                        // let indexValue:any= checkedAgr.findIndex( x => String(x.Agreementid)  ===  String(mastertranssuspensearray[i].agreementnumber))
                        // checkedAgr[indexValue].transferNewSuspense = Number(mastertranssuspensearray[i].newsuspense)
                        paygridvalues[indexValue].SuspenseAmount = Number(
                          insertArray[i].newsuspense
                        );
                      } else if (
                        Number(insertArray[i].newsuspense) -
                          Number(paygridvalues[indexValue].transferOldSuspense) <
                        0
                      ) {
                        newInsertArray.push({
                          sustype: "TransferUsed",
                          agreementnumber: insertArray[i].agreementnumber,
                          agreementid: insertArray[i].agreementid,
                          schedure: insertArray[i].schedure,
                          paymentamount: insertArray[i].paymentamount,
                          epoamount: Number(insertArray[i].epoamount),
                          suspenseamount: insertArray[i].suspenseamount,
                          newsuspense:
                            Number(
                              paygridvalues[indexValue].transferOldSuspense
                            ) - Number(insertArray[i].newsuspense),
                          agreementType: insertArray[i].agreementType,
                        });
                        // let indexValue:any= checkedAgr.findIndex( x => String(x.Agreementid)  ===  String(mastertranssuspensearray[i].agreementnumber))
                        // checkedAgr[indexValue].transferNewSuspense = Number(mastertranssuspensearray[i].newsuspense)
                        paygridvalues[indexValue].SuspenseAmount = Number(
                          insertArray[i].newsuspense
                        );
                        const useFilterTransferBack =
                          masterusesuspensearray.filter(function (e) {
                            return (
                              e.sustype != "type" &&
                              Number(e.newsuspense) > 0 &&
                              String(e.agreementid) ==
                                String(insertArray[i].agreementid)
                            );
                          });
                        if (useFilterTransferBack?.length > 0) {
                          if (
                            Number(insertArray[i].newsuspense) <
                            Number(useFilterTransferBack[0].newsuspense)
                          ) {
                            masterusesuspensearray =
                              masterusesuspensearray.filter(function (e) {
                                return (
                                  String(e.agreementid) !=
                                  String(useFilterTransferBack[0].agreementid)
                                );
                              });
                            for (
                              let i = 0;
                              i < useFilterTransferBack.length;
                              i++
                            ) {
                              let index = paygridvalues.findIndex(
                                (x) =>
                                  String(x.Agreementid) ===
                                  String(useFilterTransferBack[0].agreementnumber)
                              );
                              paygridvalues[index].suspenseSub = 0;
                              // paygridvalues[index].Amount=Number(paygridvalues[index].actualAmount)+Number(paygridvalues[index].suspenseAdd)-Number(usefilterzero[i].newsuspense)
                              let receiptObj =
                                ReceiptCategoriesRequestParam.filter(
                                  (e) =>
                                    e.agreementId ==
                                    Number(
                                      useFilterTransferBack[0].agreementnumber
                                    )
                                );
                              if (
                                useFilterTransferBack[0].agreementnumber ==
                                receiptObj[0].agreementId
                              ) {
                                receiptObj[0].suspenseSub = 0;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  const usefilterzero = masterusesuspensearray.filter(function (
                    e
                  ) {
                    return e.sustype != "type" && Number(e.newsuspense) > 0;
                  });
                  const addfilterzero = masteraddsuspensearray.filter(function (
                    e
                  ) {
                    return e.sustype != "type" && Number(e.newsuspense) > 0;
                  });

                  setmastertranssuspensearray(newInsertArray);
                  settotalsuspensemaster([
                    ...usefilterzero,
                    ...addfilterzero,
                    ...newInsertArray,
                  ]);

                  // let addfilter = totalsuspensemaster.filter(function (e) {
                  //   return e.sustype == "Add";
                  // });
                  // let usefilter = totalsuspensemaster.filter(function (e) {
                  //   return e.sustype == "Used";
                  // });

                  let resAmount = 0.0;
                  for (let i = 0; i < paygridvalues.length; i++) {
                    resAmount =
                      Number(paygridvalues[i].Amount) + Number(resAmount);
                  }

                  const addSuspenseAmount = addfilterzero.filter(function (e) {
                    return e.sustype == "Add" && Number(e.newsuspense) > 0;
                  });
                  const useSuspenseAmount = usefilterzero.filter(function (e) {
                    return e.sustype == "Used" && Number(e.newsuspense) > 0;
                  });
                  let addSusTotalAmount =
                    addSuspenseAmount.length == 0
                      ? 0.0
                      : addSuspenseAmount
                          .map((bill) => bill.newsuspense)
                          .reduce((acc, bill) =>
                            (Number(bill) + Number(acc)).toFixed(2)
                          );

                  let usedSusTotalAmount =
                    useSuspenseAmount.length == 0
                      ? 0.0
                      : useSuspenseAmount
                          .map((bill) => bill.newsuspense)
                          .reduce((acc, bill) =>
                            (Number(bill) + Number(acc)).toFixed(2)
                          );
                  let resTotalAmount = 0.0;
                  if (paymentOrigin == "Pay By Phone") {
                    resAmount = Number(resAmount) + Number(convAmtWTax);
                  }
                  resTotalAmount =
                    totalduetendertype.length == 0
                      ? 0.0
                      : totalduetendertype
                          .map((bill) => bill.Amount)
                          .reduce((acc, bill) =>
                            (Number(bill) + Number(acc)).toFixed(2)
                          );

                  if (Number(resTotalAmount) > Number(resAmount)) {
                    resetPayment(undefined);
                    setremainingamountdue(
                      Number(
                        (
                          Number(resAmount) -
                          Number(availableCreds)+
                          Number(donationAmountAdd) +
                          Number(addSusTotalAmount) -
                          Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                        ).toFixed(2)
                      )
                    );
                    setchange("0.00");
                    setAcceptpaymentbuttonenable(true);
                  } else {
                    if (
                      Number(resTotalAmount) >=
                      Number(
                        (
                          Number(resAmount) -
                          Number(availableCreds) +
                          Number(donationAmountAdd)+
                          Number(addSusTotalAmount) -
                          Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                        ).toFixed(2)
                      )
                    ) {
                      setchange(
                        Number(resTotalAmount) -
                          Number(
                            (
                              Number(resAmount) -
                              Number(availableCreds)+
                              Number(donationAmountAdd) +
                              Number(addSusTotalAmount) -
                              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                            ).toFixed(2)
                          )
                      );
                      setremainingamountdue("0.00");
                      setRemaniningAmtStatic("0.00");
                      setAcceptpaymentbuttonenable(false);
                    } else {
                      setremainingamountdue(
                        Number(
                          (
                            Number(resAmount) -
                            Number(availableCreds)+
                            Number(donationAmountAdd) +
                            Number(addSusTotalAmount) -
                            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                          ).toFixed(2)
                        ) - Number(resTotalAmount)
                      );
                      setRemaniningAmtStatic(
                        Number(
                          (
                            Number(resAmount) -
                            Number(availableCreds)+
                            Number(donationAmountAdd) +
                            Number(addSusTotalAmount) -
                            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                          ).toFixed(2)
                        ) - Number(resTotalAmount)
                      );
                      setchange("0.00");
                      setAcceptpaymentbuttonenable(true);
                    }
                  }
                    multipleAdjust();
                  let filterAgreementData: any = [];
                  if(agreementId !== "0") {
                    filterAgreementData = payGridData.filter(
                      (el) =>
                        el.Agreementid == agreementId
                    );
                    filterAgreementData.push(payGridData[index])
                  } else {
                    filterAgreementData = payGridData.filter(
                      (el) =>
                        el.store_number ==
                        window.sessionStorage.getItem("storeNumber")
                    );
                  }
                  let colAgrId: any = [];
                  if(agreementId !== "0") {
                    filterAgreementData.map((el: any) => {
                      if(el.Agreementid == agreementId) {
                        colAgrId.push(el.Agreementid);
                      }
                    });
                  } else {
                    filterAgreementData.map((el: any) => {
                      colAgrId.push(el.Agreementid);
                    });
                  }
                  editCommitmentRes({
                    resArray: filterAgreementData,
                    selectedAgr: colAgrId,
                  });
                }
              }
            }
          }
        }
      }
    }else {
      //common module commitment club
      if (adjustCommitmentData.CommitmentClubAgreement !== null) {
        for (let index = 0; index < payGridData.length; index++) {
          for (
            let j = 0;
            j < adjustCommitmentData.CommitmentClubAgreement.length;
            j++
          ) {
            let payGridObj = payGridData[index];
            let obj = adjustCommitmentData?.CommitmentClubAgreement[j];
            if (Number(payGridObj.customerClubId) == Number(obj.agreementID)) {
              for (let k = 0; k < obj.adjustedInfo.length; k++) {
                let clubObject = obj.adjustedInfo[k];
                if (clubObject.type === "DDA"&&clubObject.type != "DC") {
                  payGridObj.dueDateModified=true
                  payGridObj.Nextduedate=moment(clubObject.value).format('MM/DD/YYYY')
                } else if (clubObject.type === "ES"&&clubObject.type != "DC") {
                  payGridObj.editSchedule=clubObject.value
                  const EditScheduleDescriptionArray = paymentEditScheduleDropDown[0]?.referenceDetails?.filter(

                    (word) =>  word.referenceCode == clubObject.value
                  
                  );
                  payGridObj.Schedule=EditScheduleDescriptionArray[0]?.description

                } else if (clubObject.type === "DC") {
                  let agrIdWithoutClub: any = [];
                  payGridData.map((payGridObj, index) => {
                    if (payGridObj.Agreementtype !== "Club") {
                      agrIdWithoutClub.push(payGridObj.Agreementid);
                    }
                  });
                  await deActivatePopupYesHandleChange();
                  // let filterAgreementData: any = [];
                  // if(agreementId !== "0") {
                  //   filterAgreementData = payGridData.filter(
                  //     (el) =>
                  //       el.Agreementid == agreementId
                  //   );
                  //   filterAgreementData.push(payGridData[index])
                  // } else {
                  //   filterAgreementData = payGridData.filter(
                  //     (el) =>
                  //       el.store_number ==
                  //       window.sessionStorage.getItem("storeNumber")
                  //   );
                  // }
                  // let colAgrId: any = [];
                  // if(agreementId !== "0") {
                  //   filterAgreementData.map((el: any) => {
                  //     if(el.Agreementid == agreementId) {
                  //       colAgrId.push(el.Agreementid);
                  //     }
                  //   });
                  // } else {
                  //   filterAgreementData.map((el: any) => {
                  //     colAgrId.push(el.Agreementid);
                  //   });
                  // }
                  // await editCommitmentRes({
                  //   resArray: filterAgreementData,
                  //   selectedAgr: agrIdWithoutClub,
                  // });
                  setclubStatus("A");
                }
              }
            }
          }
        }
      }

      if (adjustCommitmentData.commitmentDetail.length > 0) {
        for (let index = 0; index < payGridData.length; index++) {
          for (
            let j = 0;
            j < adjustCommitmentData?.commitmentDetail.length;
            j++
          ) {
            let payGridObj = payGridData[index];
            let commitmentObj = adjustCommitmentData?.commitmentDetail[j];
            if (
              Number(payGridObj.Agreementid) == Number(commitmentObj.agreementID)
            ) {
              for (let k = 0; k < commitmentObj.adjustedInfo.length; k++) {
                let adjustedObj = commitmentObj.adjustedInfo[k];
                if (adjustedObj.type === "DDA") {
                  payGridObj.dueDateModified=true
                  payGridObj.Nextduedate=moment(adjustedObj.value).format('MM/DD/YYYY')
                } else if (adjustedObj.type === "FTT") {
                  const nextduedate = moment(
                    adjustedObj.value.nextDueDate
                  ).format("MM/DD/YYYY");
                  const currentduedate = moment(
                    payGridData[index].CurrentDueDate
                  ).format("MM/DD/YYYY");
                  const date1 = new Date(currentduedate);
                  const date2 = new Date(nextduedate);
                  const time_difference = date2.getTime() - date1.getTime();
                  const days_difference = time_difference / (1000 * 3600 * 24);
                  if (adjustedObj.value.waiveOptService == true) {
                    // alert("reached the if condition of not null");
                    payGridData[index].Amount = 0.0;
                    payGridData[index].Taxamount = 0.0;
                    payGridData[index].policyAmount = 0.0;
                    payGridData[index].ldwTaxAmount = 0.0;
                    payGridData[index].agrLdwAmount = 0.0;
                    payGridData[index].policyTaxAmount = 0.0;
                    payGridData[index].agreementRate = 0.0;
                    payGridData[index].rpayTaxAmount = 0.0;
                    payGridData[index].Latelast10 = 0.0;
                    payGridData[index].lateFee = 0.0;
                    payGridData[index].lFeeTaxAmount = 0.0;
                    payGridData[index].Nextduedate = nextduedate;
                    payGridData[index].PromoFreeDaysApplied =
                      adjustedObj.value.freeTimeType == "PROMO"
                        ? Number(days_difference)
                        : payGridData[index].PromoFreeDaysApplied;
                    payGridData[index].FreeTime = {
                      freeTimeType: adjustedObj.value.freeTimeType,
                      freeTimeDays: Number(days_difference).toFixed(),
                      isOptServiceWaived: adjustedObj.value.waiveOptService,
                    };
                    // resArray: [payGridData[index]],
                    // selectedAgr: [payGridData[index].Agreementid],
                    let filterAgreementData: any = [];
                    if(agreementId !== "0") {
                      filterAgreementData = payGridData.filter(
                        (el) =>
                          el.Agreementid == agreementId
                      );
                      filterAgreementData.push(payGridData[index])
                    } else {
                      filterAgreementData = payGridData.filter(
                        (el) =>
                          el.store_number ==
                          window.sessionStorage.getItem("storeNumber")
                      );
                    }
                    let colAgrId: any = [];
                    if(agreementId !== "0") {
                      filterAgreementData.map((el: any) => {
                        if(el.Agreementid == agreementId) {
                          colAgrId.push(el.Agreementid);
                        }
                      });
                    } else {
                      filterAgreementData.map((el: any) => {
                        colAgrId.push(el.Agreementid);
                      });
                    }
                    editCommitmentRes({
                      resArray: filterAgreementData,
                      selectedAgr: colAgrId,
                    });
                  }else{
                    payGridData[index].Nextduedate = nextduedate;
                    payGridData[index].PromoFreeDaysApplied =
                      adjustedObj.value.freeTimeType == "PROMO"
                        ? Number(days_difference)
                        : payGridData[index].PromoFreeDaysApplied;
                    payGridData[index].FreeTime = {
                      freeTimeType: adjustedObj.value.freeTimeType,
                      freeTimeDays: Number(days_difference).toFixed(),
                      isOptServiceWaived: adjustedObj.value.waiveOptService,
                    };
                  }
                  
                } else if (adjustedObj.type === "TOS") {
                  commitmentTOS = true;
                  const collectAgrIDS: any = [];
                  payGridData.map((payGridObj: any, index) => {
                    if(payGridObj.Agreementtype !== 'Club') {
                      collectAgrIDS.push(payGridObj.Agreementid);
                    }
                  });
                  console.log('collectAgrIDS', collectAgrIDS);
                  
                  setadjustDueDateSelectedAgre(collectAgrIDS);
                  if (adjustedObj.value === "LDW") {
                    selectedGrid = index;
                    // (async () => {
                    const callLoadOtherAction: any = await commitmentLoadAction(
                      index, configPermissions
                    );
                    if (callLoadOtherAction !== undefined) {
                      const terminateLdw_Resp: any = await LDW_apiCall(
                        2,
                        callLoadOtherAction,
                        index
                      );
                      let filterAgreementData: any = [];
                      // if(agreementId !== "0") {
                      //   filterAgreementData = payGridData.filter(
                      //     (el) =>
                      //       el.Agreementid == agreementId
                      //   );
                      //   filterAgreementData.push(payGridData[index])
                      // } else {
                      //   filterAgreementData = payGridData.filter(
                      //     (el) =>
                      //       el.store_number ==
                      //       window.sessionStorage.getItem("storeNumber")
                      //   );
                      // }
                      // let colAgrId: any = [];
                      // if(agreementId !== "0") {
                      //   filterAgreementData.map((el: any) => {
                      //     if(el.Agreementid == agreementId) {
                      //       colAgrId.push(el.Agreementid);
                      //     }
                      //   });
                      // } else {
                      //   filterAgreementData.map((el: any) => {
                      //     colAgrId.push(el.Agreementid);
                      //   });
                      // }
                      // editCommitmentRes({
                      //   resArray: filterAgreementData,
                      //   selectedAgr: colAgrId,
                      // });
                    }
                    // })();
                  } else if (adjustedObj.value === "Policy") {
                    selectedGrid = index;
                    // (async () => {
                    const callLoadOtherAction: any = await commitmentLoadAction(
                      index,configPermissions
                    );
                    if (callLoadOtherAction !== undefined) {
                      const terminateLdw_Resp: any = await Policy_apiCall(
                        2,
                        callLoadOtherAction,
                        index
                      );
                      // let filterAgreementData: any = [];
                      // if(agreementId !== "0") {
                      //   filterAgreementData = payGridData.filter(
                      //     (el) =>
                      //       el.Agreementid == agreementId
                      //   );
                      //   filterAgreementData.push(payGridData[index])
                      // } else {
                      //   filterAgreementData = payGridData.filter(
                      //     (el) =>
                      //       el.store_number ==
                      //       window.sessionStorage.getItem("storeNumber")
                      //   );
                      // }
                      // let colAgrId: any = [];
                      // if(agreementId !== "0") {
                      //   filterAgreementData.map((el: any) => {
                      //     if(el.Agreementid == agreementId) {
                      //       colAgrId.push(el.Agreementid);
                      //     }
                      //   });
                      // } else {
                      //   filterAgreementData.map((el: any) => {
                      //     colAgrId.push(el.Agreementid);
                      //   });
                      // }
                      // editCommitmentRes({
                      //   resArray: filterAgreementData,
                      //   selectedAgr: colAgrId,
                      // });
                    }
                    // })();
                  }
                } else if (adjustedObj.type === "AOS") {
                  if (adjustedObj.value === "LDW") {
                    selectedGrid = index;
                    // (async () => {
                    const callLoadOtherAction: any = await commitmentLoadAction(
                      index,configPermissions
                    );
                    if (callLoadOtherAction !== undefined) {
                      const terminateLdw_Resp: any = await LDW_apiCall(
                        1,
                        callLoadOtherAction,
                        index
                      );
                      // let filterAgreementData: any = [];
                      // if(agreementId !== "0") {
                      //   filterAgreementData = payGridData.filter(
                      //     (el) =>
                      //       el.Agreementid == agreementId
                      //   );
                      //   filterAgreementData.push(payGridData[index])
                      // } else {
                      //   filterAgreementData = payGridData.filter(
                      //     (el) =>
                      //       el.store_number ==
                      //       window.sessionStorage.getItem("storeNumber")
                      //   );
                      // }
                      // let colAgrId: any = [];
                      // if(agreementId !== "0") {
                      //   filterAgreementData.map((el: any) => {
                      //     if(el.Agreementid == agreementId) {
                      //       colAgrId.push(el.Agreementid);
                      //     }
                      //   });
                      // } else {
                      //   filterAgreementData.map((el: any) => {
                      //     colAgrId.push(el.Agreementid);
                      //   });
                      // }
                      // editCommitmentRes({
                      //   resArray: filterAgreementData,
                      //   selectedAgr: colAgrId,
                      // });
                    }
                    // })();
                  } else if (adjustedObj.value === "Policy") {
                    selectedGrid = index;
                    // (async () => {
                    const callLoadOtherAction: any = await commitmentLoadAction(
                      index , configPermissions
                    );
                    if (callLoadOtherAction !== undefined) {
                      const terminateLdw_Resp: any = await Policy_apiCall(
                        1,
                        callLoadOtherAction,
                        index
                      );
                      // let filterAgreementData: any = [];
                      // if(agreementId !== "0") {
                      //   filterAgreementData = payGridData.filter(
                      //     (el) =>
                      //       el.Agreementid == agreementId
                      //   );
                      //   filterAgreementData.push(payGridData[index])
                      // } else {
                      //   filterAgreementData = payGridData.filter(
                      //     (el) =>
                      //       el.store_number ==
                      //       window.sessionStorage.getItem("storeNumber")
                      //   );
                      // }
                      // let colAgrId: any = [];
                      // if(agreementId !== "0") {
                      //   filterAgreementData.map((el: any) => {
                      //     if(el.Agreementid == agreementId) {
                      //       colAgrId.push(el.Agreementid);
                      //     }
                      //   });
                      // } else {
                      //   filterAgreementData.map((el: any) => {
                      //     colAgrId.push(el.Agreementid);
                      //   });
                      // }
                      // editCommitmentRes({
                      //   resArray: filterAgreementData,
                      //   selectedAgr: colAgrId,
                      // });
                    }
                    // })();
                  }
                } else if(adjustedObj.type ==="LFC"){
                  payGridData[index].iscarriedLateFeeValue= Number(adjustedObj.value).toFixed(2)
                  payGridData[index].carriedLateFee=Number(adjustedObj.value).toFixed(2)
                }else if(adjustedObj.type ==="LFW"){
                  payGridData[index].waivedLateFeeValue= Number(adjustedObj.value).toFixed(2)
                  payGridData[index].waivedLateFee=Number(adjustedObj.value).toFixed(2)
                }else if (adjustedObj.type === "AS") {
                  payGridObj.suspenseAdd=Number(adjustedObj.value)
                } else if (adjustedObj.type === "US") {
                  payGridObj.suspenseSub=Number(adjustedObj.value)
                } else if (adjustedObj.type === "AEF") {
                  const totalWithOutTax = Number(
                    Number(
                      Number(adjustedObj.value.epoAmount) +
                        Number(adjustedObj.value.pastDueRent) +
                        payGridData[index].lateFee
                    ).toFixed(2)
                  );
                  const epoFieldValuesObject = {
                    pastDueRent: Number(adjustedObj.value.pastDueRent).toFixed(2),
                    epoAmt: Number(adjustedObj.value.epoAmount),
                    taxAmt: Number(
                      (
                        totalWithOutTax * Number(payGridData[index].taxRate)
                      ).toFixed(2)
                    ),
                    total: (
                      Number(
                        Number(adjustedObj.value.epoAmount) +
                          Number(adjustedObj.value.pastDueRent) +
                          Number(payGridData[index].lateFee) +
                          Number(
                            (
                              totalWithOutTax * payGridData[index].taxRate
                            ).toFixed(2)
                          )
                      ) - Number(payGridData[index].SuspenseAmount)
                    ).toFixed(2),
                  };
                  selectedGrid = index;
                  await acceptEpoButton(
                    epoFieldValuesObject,
                    false,
                    "CommitmentEPO"
                  );
                  // let filterAgreementData: any = [];
                  // if(agreementId !== "0") {
                  //   filterAgreementData = payGridData.filter(
                  //     (el) =>
                  //       el.Agreementid == agreementId
                  //   );
                  //   filterAgreementData.push(payGridData[index])
                  // } else {
                  //   filterAgreementData = payGridData.filter(
                  //     (el) =>
                  //       el.store_number ==
                  //       window.sessionStorage.getItem("storeNumber")
                  //   );
                  // }
                  // let colAgrId: any = [];
                  // if(agreementId !== "0") {
                  //   filterAgreementData.map((el: any) => {
                  //     if(el.Agreementid == agreementId) {
                  //       colAgrId.push(el.Agreementid);
                  //     }
                  //   });
                  // } else {
                  //   filterAgreementData.map((el: any) => {
                  //     colAgrId.push(el.Agreementid);
                  //   });
                  // }
                  // editCommitmentRes({
                  //   resArray: filterAgreementData,
                  //   selectedAgr: colAgrId,
                  // });
                } else if (adjustedObj.type === "ES") {
                  payGridObj.editSchedule=adjustedObj.value
                  const EditScheduleDescriptionArray = paymentEditScheduleDropDown[0]?.referenceDetails?.filter(

                    (word) =>  word.referenceCode == adjustedObj.value
                  
                  );
                  payGridObj.Schedule=EditScheduleDescriptionArray[0]?.description
                } else if (adjustedObj.type === "TUS") {
                    let insertArray: any[] = [];
                    let totalsusamount = 0;
                    let transferedSupenseAmount = 0;
                    for (let i = 0; i < paygridvalues?.length; i++) {
                      if (
                        paygridvalues[i].Agreementtype != "Club" &&
                        paygridvalues[i].Nextduedate != "PIF" &&
                        paygridvalues[i].Nextduedate != "SAC" &&
                        paygridvalues[i].Nextduedate != "EPO" &&
                        paygridvalues[i].number_of_paymentsmade > 0 &&
                        String(paygridvalues[i].store_number) ==
                          String(window.sessionStorage.getItem("storeNumber")) &&
                        !paygridvalues[i].isExchanged
                      ) {
                        totalsusamount =
                          Number(totalsusamount) +
                          Number(paygridvalues[i].SuspenseAmount);
                        transferedSupenseAmount =
                          Number(transferedSupenseAmount) +
                          Number(paygridvalues[i].transferOldSuspense);
                        insertArray.push({
                          sustype: "type",
                          agreementnumber: paygridvalues[i].AgreementNumber,
                          agreementid: paygridvalues[i].Agreementid,
                          schedure: paygridvalues[i].Schedule,
                          paymentamount: paygridvalues[i].Amount,
                          epoamount: paygridvalues[i].EPOAmount,
                          suspenseamount: Number(
                            paygridvalues[i].SuspenseAmount
                          ).toFixed(2),
                          newsuspense: Number(
                            paygridvalues[i].SuspenseAmount
                          ).toFixed(2),
                          agreementType: paygridvalues[i].Agreementtype,
                        });
                        settotolsuspenseamount(Number(totalsusamount).toFixed(2));
                        setdifferenceamount(
                          Number(
                            (
                              Number(transferedSupenseAmount) -
                              Number(totalsusamount)
                            ).toFixed(2)
                          )
                        );
                      }
                    }
  
                    setmastertranssuspensearray([...insertArray]);
                    setaddsus("0.00");
                    setusesus("0.00");
                    settranssus("0.00");
                    insertArray.map((obj) => {
                      adjustedObj.value.map((adjObj, index) => {
                        if (
                          Number(adjObj.agreementID) === Number(obj.agreementid)
                        ) {
                          insertArray[index].newsuspense = adjObj.value;
                          insertArray[index].sustype = "Trans";
                        }
                      });
                    });
  
                    let newtransferedSuspense = 0;
                    let ActualSuspenseAmount = 0;
                    for (let i = 0; i < insertArray.length; i++) {
                      newtransferedSuspense =
                        newtransferedSuspense + Number(insertArray[i].newsuspense);
                      ActualSuspenseAmount =
                        ActualSuspenseAmount +
                        Number(insertArray[i].suspenseamount);
                    }
                    settotalnewsuspenseamount(newtransferedSuspense);
                    let totaltransnewsusamount: number;
                    totaltransnewsusamount = 0;
                    for (let i = 0; i < insertArray.length; i++) {
                      totaltransnewsusamount =
                        totaltransnewsusamount + insertArray[i].newsuspense;
                    }
                    settotalnewsuspenseamount(totaltransnewsusamount);
                    for (let i = 0; i < insertArray.length; i++) {
                      if (
                        insertArray[i].suspenseamount < insertArray[i].newsuspense
                      ) {
                        insertArray[i].sustype = "TransferAdd";
                      } else {
                        insertArray[i].sustype = "TransferUsed";
                      }
                    }
                    let newInsertArray: any = [];
                    for (let i = 0; i < insertArray.length; i++) {
                      let indexValue: any = paygridvalues.findIndex(
                        (x) =>
                          String(x.Agreementid) ===
                          String(insertArray[i].agreementid)
                      );
                      if (
                        insertArray[i].sustype == "TransferAdd" ||
                        "TransferUsed"
                      ) {
                        if (
                          Number(insertArray[i].newsuspense) -
                            Number(paygridvalues[indexValue].transferOldSuspense) >
                          0
                        ) {
                          newInsertArray.push({
                            sustype: "TransferAdd",
                            agreementnumber: insertArray[i].agreementnumber,
                            agreementid: insertArray[i].agreementid,
                            schedure: insertArray[i].schedure,
                            paymentamount: insertArray[i].paymentamount,
                            epoamount: Number(insertArray[i].epoamount),
                            suspenseamount: insertArray[i].suspenseamount,
                            newsuspense:
                              Number(insertArray[i].newsuspense) -
                              Number(paygridvalues[indexValue].transferOldSuspense),
                            agreementType: insertArray[i].agreementType,
                          });
                          // let indexValue:any= checkedAgr.findIndex( x => String(x.Agreementid)  ===  String(mastertranssuspensearray[i].agreementnumber))
                          // checkedAgr[indexValue].transferNewSuspense = Number(mastertranssuspensearray[i].newsuspense)
                          paygridvalues[indexValue].SuspenseAmount = Number(
                            insertArray[i].newsuspense
                          );
                        } else if (
                          Number(insertArray[i].newsuspense) -
                            Number(paygridvalues[indexValue].transferOldSuspense) <
                          0
                        ) {
                          newInsertArray.push({
                            sustype: "TransferUsed",
                            agreementnumber: insertArray[i].agreementnumber,
                            agreementid: insertArray[i].agreementid,
                            schedure: insertArray[i].schedure,
                            paymentamount: insertArray[i].paymentamount,
                            epoamount: Number(insertArray[i].epoamount),
                            suspenseamount: insertArray[i].suspenseamount,
                            newsuspense:
                              Number(
                                paygridvalues[indexValue].transferOldSuspense
                              ) - Number(insertArray[i].newsuspense),
                            agreementType: insertArray[i].agreementType,
                          });
                          // let indexValue:any= checkedAgr.findIndex( x => String(x.Agreementid)  ===  String(mastertranssuspensearray[i].agreementnumber))
                          // checkedAgr[indexValue].transferNewSuspense = Number(mastertranssuspensearray[i].newsuspense)
                          paygridvalues[indexValue].SuspenseAmount = Number(
                            insertArray[i].newsuspense
                          );
                          const useFilterTransferBack =
                            masterusesuspensearray.filter(function (e) {
                              return (
                                e.sustype != "type" &&
                                Number(e.newsuspense) > 0 &&
                                String(e.agreementid) ==
                                  String(insertArray[i].agreementid)
                              );
                            });
                          if (useFilterTransferBack?.length > 0) {
                            if (
                              Number(insertArray[i].newsuspense) <
                              Number(useFilterTransferBack[0].newsuspense)
                            ) {
                              masterusesuspensearray =
                                masterusesuspensearray.filter(function (e) {
                                  return (
                                    String(e.agreementid) !=
                                    String(useFilterTransferBack[0].agreementid)
                                  );
                                });
                              for (
                                let i = 0;
                                i < useFilterTransferBack.length;
                                i++
                              ) {
                                let index = paygridvalues.findIndex(
                                  (x) =>
                                    String(x.Agreementid) ===
                                    String(useFilterTransferBack[0].agreementnumber)
                                );
                                paygridvalues[index].suspenseSub = 0;
                                // paygridvalues[index].Amount=Number(paygridvalues[index].actualAmount)+Number(paygridvalues[index].suspenseAdd)-Number(usefilterzero[i].newsuspense)
                                let receiptObj =
                                  ReceiptCategoriesRequestParam.filter(
                                    (e) =>
                                      e.agreementId ==
                                      Number(
                                        useFilterTransferBack[0].agreementnumber
                                      )
                                  );
                                if (
                                  useFilterTransferBack[0].agreementnumber ==
                                  receiptObj[0].agreementId
                                ) {
                                  receiptObj[0].suspenseSub = 0;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    const usefilterzero = masterusesuspensearray.filter(function (
                      e
                    ) {
                      return e.sustype != "type" && Number(e.newsuspense) > 0;
                    });
                    const addfilterzero = masteraddsuspensearray.filter(function (
                      e
                    ) {
                      return e.sustype != "type" && Number(e.newsuspense) > 0;
                    });
  
                    setmastertranssuspensearray(newInsertArray);
                    settotalsuspensemaster([
                      ...usefilterzero,
                      ...addfilterzero,
                      ...newInsertArray,
                    ]);
  
                    // let addfilter = totalsuspensemaster.filter(function (e) {
                    //   return e.sustype == "Add";
                    // });
                    // let usefilter = totalsuspensemaster.filter(function (e) {
                    //   return e.sustype == "Used";
                    // });
  
                    let resAmount = 0.0;
                    for (let i = 0; i < paygridvalues.length; i++) {
                      resAmount =
                        Number(paygridvalues[i].Amount) + Number(resAmount);
                    }
  
                    const addSuspenseAmount = addfilterzero.filter(function (e) {
                      return e.sustype == "Add" && Number(e.newsuspense) > 0;
                    });
                    const useSuspenseAmount = usefilterzero.filter(function (e) {
                      return e.sustype == "Used" && Number(e.newsuspense) > 0;
                    });
                    let addSusTotalAmount =
                      addSuspenseAmount.length == 0
                        ? 0.0
                        : addSuspenseAmount
                            .map((bill) => bill.newsuspense)
                            .reduce((acc, bill) =>
                              (Number(bill) + Number(acc)).toFixed(2)
                            );
  
                    let usedSusTotalAmount =
                      useSuspenseAmount.length == 0
                        ? 0.0
                        : useSuspenseAmount
                            .map((bill) => bill.newsuspense)
                            .reduce((acc, bill) =>
                              (Number(bill) + Number(acc)).toFixed(2)
                            );
                    let resTotalAmount = 0.0;
                    if (paymentOrigin == "Pay By Phone") {
                      resAmount = Number(resAmount) + Number(convAmtWTax);
                    }
                    resTotalAmount =
                      totalduetendertype.length == 0
                        ? 0.0
                        : totalduetendertype
                            .map((bill) => bill.Amount)
                            .reduce((acc, bill) =>
                              (Number(bill) + Number(acc)).toFixed(2)
                            );
  
                    if (Number(resTotalAmount) > Number(resAmount)) {
                      resetPayment(undefined);
                      setremainingamountdue(
                        Number(
                          (
                            Number(resAmount) -
                            Number(availableCreds)+
                            Number(donationAmountAdd) +
                            Number(addSusTotalAmount) -
                            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                          ).toFixed(2)
                        )
                      );
                      setchange("0.00");
                      setAcceptpaymentbuttonenable(true);
                    } else {
                      if (
                        Number(resTotalAmount) >=
                        Number(
                          (
                            Number(resAmount) -
                            Number(availableCreds) +
                            Number(donationAmountAdd)+
                            Number(addSusTotalAmount) -
                            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                          ).toFixed(2)
                        )
                      ) {
                        setchange(
                          Number(resTotalAmount) -
                            Number(
                              (
                                Number(resAmount) -
                                Number(availableCreds)+
                                Number(donationAmountAdd) +
                                Number(addSusTotalAmount) -
                                Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                              ).toFixed(2)
                            )
                        );
                        setremainingamountdue("0.00");
                        setRemaniningAmtStatic("0.00");
                        setAcceptpaymentbuttonenable(false);
                      } else {
                        setremainingamountdue(
                          Number(
                            (
                              Number(resAmount) -
                              Number(availableCreds)+
                              Number(donationAmountAdd) +
                              Number(addSusTotalAmount) -
                              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                            ).toFixed(2)
                          ) - Number(resTotalAmount)
                        );
                        setRemaniningAmtStatic(
                          Number(
                            (
                              Number(resAmount) -
                              Number(availableCreds)+
                              Number(donationAmountAdd) +
                              Number(addSusTotalAmount) -
                              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                            ).toFixed(2)
                          ) - Number(resTotalAmount)
                        );
                        setchange("0.00");
                        setAcceptpaymentbuttonenable(true);
                      }
                    }
                      multipleAdjust();
                    let filterAgreementData: any = [];
                    if(agreementId !== "0") {
                      filterAgreementData = payGridData.filter(
                        (el) =>
                          el.Agreementid == agreementId
                      );
                      filterAgreementData.push(payGridData[index])
                    } else {
                      filterAgreementData = payGridData.filter(
                        (el) =>
                          el.store_number ==
                          window.sessionStorage.getItem("storeNumber")
                      );
                    }
                    let colAgrId: any = [];
                    if(agreementId !== "0") {
                      filterAgreementData.map((el: any) => {
                        if(el.Agreementid == agreementId) {
                          colAgrId.push(el.Agreementid);
                        }
                      });
                    } else {
                      filterAgreementData.map((el: any) => {
                        colAgrId.push(el.Agreementid);
                      });
                    }
                    editCommitmentRes({
                      resArray: filterAgreementData,
                      selectedAgr: colAgrId,
                    });
                }
                                  
              }
            }
          }
        }
      }

      commonModuleCallCommitment({adjustDueDateReq:paygridvalues}, payGridData)
      
    }
  };
  const commonModuleCallCommitment=async(props, payGridData)=>{
    let commonModulePayload = await calculationModulePayloadFormation( {
      customerId:customerId,
      storeNumber:window.sessionStorage.getItem("storeNumber"),
      checkedAgr:props.adjustDueDateReq})
    let commonModuleServiceRes = await calculatePaymentModule(commonModulePayload)
    commonModuleServiceRes=commonModuleServiceRes.data
    for (let i = 0; i < props.adjustDueDateReq.length; i++) {
     
      
      let commonModuleAgrRes :any =[]
     if(props.adjustDueDateReq[i].Agreementtype!='Club'){
      commonModuleAgrRes = commonModuleServiceRes?.paymentDetails[0]?.agreementPaymentDetails.filter(
        (x) =>
          String(x.agreementId) ===
          String(props.adjustDueDateReq[i].Agreementid)
      );
     }else{
      commonModuleAgrRes = commonModuleServiceRes?.paymentDetails[0]?.clubPaymentDetails.filter(
        (x) =>
          String(x.clubMembershipNumber) ===
          String(props.adjustDueDateReq[i].AgreementNumber)
      );
     }
      let nextduedate;
      if(commonModuleAgrRes?.length){
      // if (
      //   props.adjustDueDateReq[i].Nextduedate != "SAC" &&
      //   props.adjustDueDateReq[i].Nextduedate != "PIF" &&
      //   props.adjustDueDateReq[i].Nextduedate != "EPO"
      // ) {
      //   nextduedate = moment(props.adjustDueDateReq[i].Nextduedate).format("MM/DD/YYYY");
      // } else {
      //   nextduedate = props.adjustDueDateReq[i].Nextduedate;
      // }

      if (
        commonModuleAgrRes[0].nextDueDate != "SAC" &&
        commonModuleAgrRes[0].nextDueDate != "PIF" &&
        commonModuleAgrRes[0].nextDueDate != "EPO" &&
        commonModuleAgrRes[0].nextDueDate != null
      ) {
        nextduedate = moment(commonModuleAgrRes[0]?.nextDueDate).format("MM/DD/YYYY");
      } else {
        nextduedate = commonModuleAgrRes[0].nextDueDate==null&& commonModuleAgrRes[0]?.isFinalPayment && commonModuleAgrRes[0]?.agreementType!="PIF Note"?commonModuleAgrRes[0]?.isFinalPayment=='INEPO'?'EPO':commonModuleAgrRes[0]?.isFinalPayment:commonModuleAgrRes[0].nextDueDate;
      }

      
      (props.adjustDueDateReq[i].Nextduedate = nextduedate),
      props.adjustDueDateReq[i].isAdjustDueDateValue=undefined;
      props.adjustDueDateReq[i].iscarriedLateFeeValue= undefined;
      props.adjustDueDateReq[i].waivedLateFeeValue=undefined;
        props.adjustDueDateReq[i].Amount = Number(
          (Number(commonModuleAgrRes[0].totalAmount)+Number(commonModuleAgrRes[0].totalTax)).toFixed(2)
        );
        if(props.adjustDueDateReq[i].acceptEpoFlag == 1)
        {
          props.adjustDueDateReq[i].EPOAmount=(Number(commonModuleAgrRes[0].totalAmount)+Number(commonModuleAgrRes[0].totalTax)).toFixed(2)
          props.adjustDueDateReq[i].EPOTax=Number(commonModuleAgrRes[0].totalTax).toFixed(2)
        }
      let ldw=commonModuleAgrRes[0].amountDetails?.filter((x)=>x.itemCategory == "LDW"||x.itemCategory == "LTLDW")
      let policyAmount =commonModuleAgrRes[0].amountDetails?.filter((x)=>x.itemCategory=='RACTIREPAY')
      let carryLateFee =commonModuleAgrRes[0].amountDetails?.filter((x)=>x.itemCategory=='CLFEEPAY')
      let lateFee = commonModuleAgrRes[0].amountDetails?.filter((x)=>x.itemCategory=='LTRPAYFEE')
      let carryRentUsed = commonModuleAgrRes[0].amountDetails?.filter((x)=>x.itemCategory=='CRPAY')
      props.adjustDueDateReq[i].Taxamount = Number(commonModuleAgrRes[0].totalTax)
      props.adjustDueDateReq[i].policyAmount = policyAmount?.length>0?Number(policyAmount[0].amount):0
      props.adjustDueDateReq[i].ldwTaxAmount =ldw?.length>0?Number(ldw[0].tax):0
      props.adjustDueDateReq[i].agrLdwAmount =ldw?.length>0?Number(ldw[0].amount):0
      props.adjustDueDateReq[i].policyTaxAmount= policyAmount?.length>0?Number(policyAmount[0].tax):0
      props.adjustDueDateReq[i].carriedLateTax = carryLateFee?.length>0?Number(carryLateFee[0].tax):0
      props.adjustDueDateReq[i].lFeeTaxAmount = lateFee?.length>0?Number(lateFee[0].tax):0
      props.adjustDueDateReq[i].lateFee= lateFee?.length>0?Number(lateFee[0].amount):0
      props.adjustDueDateReq[i].carryRentTaxUsed =carryRentUsed?.length>0?Number(carryRentUsed[0].tax):0
      props.adjustDueDateReq[i].agreementRate = commonModuleAgrRes?.length>0?Number(commonModuleAgrRes[0].scheduleRate):0
      props.adjustDueDateReq[i].rpayTaxAmount = commonModuleAgrRes?.length>0?Number(commonModuleAgrRes[0].totalTax):0
      props.adjustDueDateReq[i].Agreementtype =
      props.adjustDueDateReq[i].Agreementtype == "Club"
          ? "Club"
          : commonModuleAgrRes[0].agreementType;
      }
     
    }

     //commitment taken accounts only should be checked on the page load
     //to set the check all check box
     let adjustDueDateSelectedAgreNew:any = [];
     if (commitmentDataState.length != paygridvalues.length) setOverAll_Chk(false);
     let clubvalue = paygridvalues.filter(
      (x) => x.Agreementtype == "Club" && x.number_of_paymentsmade == 0
    );
     let checkBoxArray = document.getElementsByName("grid");
     checkBoxArray.forEach(function (item, index) {
       let obj = item as HTMLInputElement;
       let dataVal = obj.value.split("|");
       if (dataVal?.length > 0 && commitmentDataState.includes(dataVal[0]) && paygridvalues.length) { 
        obj.checked = true;
        let commitmentTakenAgreement=paygridvalues.filter((x)=>x.Agreementid == dataVal[0]);
        adjustDueDateSelectedAgreNew.push(commitmentTakenAgreement[0]?.Agreementid);
        
       } 
       else if(agreementId ==String(dataVal[0])) { 
        obj.checked = true;
        adjustDueDateSelectedAgreNew.push(agreementId);
       }
       else if(agreementId!="0"&&clubvalue?.length > 0)
       {
        obj.checked = true;
        adjustDueDateSelectedAgreNew.push(clubvalue[0].Agreementid);
       }
       else{
        obj.checked = false;
       }
     });
     
     adjustDueDateSelectedAgreNew = Array.from(
      new Set(adjustDueDateSelectedAgreNew)
    );
     adjustDueDateSelectedAgre=adjustDueDateSelectedAgreNew;
     setadjustDueDateSelectedAgre(adjustDueDateSelectedAgre);
    await multipleAdjust();


    if(commitmentTOS) {
      const localCheckedAgr = [...checkedAgr];
      const localChkAgrWOClub = localCheckedAgr.filter((el) => el.Agreementtype !== 'Club');
    
      console.log('checkedAgr in acpepo', checkedAgr, paygridvalues);
      const agrToCheck = paygridvalues.filter((el) => el.Agreementtype !== 'Club');
          
      editAdjustRes({ resArray: localChkAgrWOClub});
      selectallcheckboxRevenue(
        false,
        undefined,
        true,
        agrToCheck
      );
    } else {
      editAdjustRes({ resArray: checkedAgr});
    }
    // editAdjustRes({ resArray: checkedAgr});
    // if(agreementId == "0" && !isRevenueRecogActivated) {
    //   selectallcheckbox(true, undefined);
    // } else if(agreementId == "0" && isRevenueRecogActivated && diffStoreErrLbl) {
    //   selectallcheckboxRevenue(
    //     false,
    //     undefined,
    //     true,
    //     payGridData
    //   );
    // } else if (agreementId == "0" && isRevenueRecogActivated && !diffStoreErrLbl) {
    //   selectallcheckboxRevenue(
    //     true,
    //     undefined,
    //     true,
    //     payGridData
    //   );
    // }
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:"Open Commmitment Page Load "
    }
    setUserTraceData([...userTraceData,traceObj])
    // setmainGridloader(false)
    setCommitmentMainGridLoader(false)
    setIsCommitmentOpen(false);
  }

  useEffect(() => {
    if(isRevenueRecogActivated && !restrictBindRemaining) {
      Bindremainingamount();
    } else if (!isRevenueRecogActivated) {
      Bindremainingamount();
    }
  }, [paygridvalues]);

  useEffect(() => {
    Gridbindingunenrollautopay();
  }, [updatedarray]);
  const fixedChange = (value: string) => {
    if (value == undefined || value == null || parseFloat(value) < 0.0) {
      return "0.00";
    } else {
      return String(parseFloat(value).toFixed(2));
    }
  };

  const swipeDevice_api = async () => {
    let swipeDevicePayload = {
      cloudStoreSwipeDevicelist:
        configFlags?.isCloudStoreSwipeDevicelist == 1 ? "Y" : "N",
      storeNumber: window.sessionStorage.getItem("storeNumber"),
    };

    let swipe_storeResp = await StoreswipeDevice(swipeDevicePayload);
    setDeveiceResp(swipe_storeResp);
  };

  const stateApi = async () => {
    const res1 = await GetState();
    const statesList = res1.data.map((state: any) => {
      return { label: state.stateAbbreviation, value: state.stateAbbreviation };
    });
    const sortedStatesList: any = orderBy(statesList, ["label"], ["asc"]);
    setstatedata(sortedStatesList);
    if (res1.data.length > 0) {
      const buildStateDropDown: any[] = [];
      res1.data.map((value: any, index: any) => {
        if (index == 0) {
          buildStateDropDown.push({ label: "Select", value: "0" });
        }
        buildStateDropDown.push({
          label: value.stateAbbreviation,
          value: value.stateAbbreviation,
        });
      });
      setdefaultVal(buildStateDropDown[0].value);
      setDD(buildStateDropDown);
    }
  };

  useEffect(() => {
    receiptitemsbind();
  }, [totalduereceiptid]);

  useEffect(() => {
    if (
      APUPStates.IsAP === APUPStates.IsUP &&
      APUPStates.IsAP !== false &&
      APUPStates.IsUP !== false
    ) {
      setAPUPStates({ ...APUPStates, IsAP: false, IsUP: false });
    }
  }, [APUPStates]);

  const receiptitemsbind = () => {
    if (paygridvalues !== undefined) {
      if (totalduereceiptid.length > 0) {
        return totalduereceiptid.map((value: any, index: any) => {
          const findAgrId = paygridvalues?.find((e: any) => e?.AgreementNumber == value?.agreement_id);
          if (!isExchangedAgreements.includes(findAgrId?.Agreementid)) {
            return (
              <Grid
                item
                key={index}
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                <Typography
                  variant="caption"
                  className={`${classes.floatLeft}  ${classes.semibold} ${classes.totalsublabelstyle} ${classes.colorwhite} `}
                >
                  {value.agreement_id == "Adjust Back Rent"
                    ? "Back Rent"
                    : value.agreement_id}
                </Typography>
                <Grid
                  item
                  className={` ${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
                >
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    $
                    {value.agreement_totalamount !== undefined &&
                      value.agreement_totalamount !== null
                      ? parseFloat(value.agreement_totalamount).toFixed(2)
                      : 0.0}
                  </Typography>
                </Grid>
              </Grid>
            );
          }
        });
      }
    } else {
      return (
        <Grid className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}>
          <Typography
            className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
          >
            12345
          </Typography>
          <Typography
            className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
          >
            <Typography>${"0.00"}</Typography>
          </Typography>
        </Grid>
      );
    }
  };

  const renderRSDList = () => {
    return securityDepositData.map((value: any, index: any) => {
      return (
        <Grid
          item
          key={index}
          className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
        >
          <Typography
            variant="caption"
            className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
          >
            {value.RSDLabel}
          </Typography>
          <Typography
            variant="caption"
            className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
          >
            <Typography className={classes.displayInline}>
              {value.RSDLabel.includes("Refund")
                ? `$(${value.RSDAmount})`
                : `$ ${value.RSDAmount}`}
            </Typography>
          </Typography>
        </Grid>
      );
    });
  };

  const suspenseitemsbind = () => {
    if (paygridvalues !== undefined) {
      if (totalsuspensemaster.length > 0) {
        return totalsuspensemaster.map((value: any, index: any) => {
          return (
            <Grid
              item
              key={index}
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              <Typography
                variant="caption"
                className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
              >
                {value.agreementid} {"-"}{" "}
                {value.sustype == "TransferAdd" || value.sustype == "Add"
                  ? "Add"
                  : "Use"}
                <span> </span>
                {value.sustype == "TransferAdd" ||
                value.sustype == "TransferUsed" ? (
                  <Infosus
                    title={"Transfer"}
                    onMouseOver={() => {
                      "Transfer";
                    }}
                  ></Infosus>
                ) : null}
                {/* {value?.isFinalTransferSusUseCancel ? (
                  <Deleteicon
                    onClick={() =>
                      finalPaymentUseSuspenseDeleteFunc(value.agreementid)
                    }
                  ></Deleteicon>
                ) : null} */}
                <span> </span>
              </Typography>
              <Typography
                variant="caption"
                className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
              >
                <Typography className={classes.displayInline}>
                  $
                  {value.newsuspense !== undefined && value.newsuspense !== null
                    ? parseFloat(value.newsuspense).toFixed(2)
                    : 0.0}
                </Typography>
              </Typography>
            </Grid>
          );
        });
      }

      // } else {
      //   return (
      //     <Grid className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}>
      //       <Typography className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}>
      //         {agreementId}
      //       </Typography>
      //       <Typography className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}>
      //         <Typography>${parseFloat(fixedChange(totaldue))}</Typography>
      //       </Typography>
      //     </Grid>
      //   );
      // }
    } else {
      return (
        <Grid className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}>
          <Typography
            className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
          >
            12345
          </Typography>
          <Typography
            className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
          >
            <Typography>${"0.00"}</Typography>
          </Typography>
        </Grid>
      );
    }
  };

  //mapping function for getCard service

  const Bindcardsymbol = (arg: any) => {
    if (arg === "Master") {
      return <Mastercard className={classes.cardSize}></Mastercard>;
    }
    if (arg === "American Express") {
      return <AmericanExpress className={classes.cardSize}></AmericanExpress>;
    }
    if (arg === "JCB") {
      return <JCB className={classes.cardSize}></JCB>;
    }
    if (arg === "Diners Club") {
      return <DinersClub className={classes.cardSize}></DinersClub>;
    }
    if (arg === "PayPal") {
      return <Paypal className={classes.cardSize}></Paypal>;
    }
    if (arg === "Discover") {
      return <Discover className={classes.cardSize}></Discover>;
    } else {
      return <Visacard className={classes.cardSize}></Visacard>;
    }
  };

  const CVVChange = (e: any, index: any) => {
    let count = 0;
    setCvv(e.target.value);
    let checked = 0;
    let enablepayment = true;
    let fieldtype = new RegExp("^[0-9|*]*$");
    if (fieldtype.test(e.target.value)) {
      if (String(e.target.value).length == 1) {
        enablepayment = false;
        multipleCard[index].cvv = String(multipleCard[index].cvv) + String(e.target.value).replace(/\*/g, "");
        multipleCard[index].cvvEncrypt = e.target.value.replace(/\d/g, "*");
        setcvvTextValue(multipleCard[index].cvvEncrypt);
        setAcceptpaymentbuttonenable(true);
      } else if (String(e.target.value).length == 2) {
        enablepayment = false;
        multipleCard[index].cvv = String(multipleCard[index].cvv) + String(e.target.value).replace(/\*/g, "");
        multipleCard[index].cvvEncrypt = e.target.value.replace(/\d/g, "*");
        setcvvTextValue(multipleCard[index].cvvEncrypt);
        setAcceptpaymentbuttonenable(true);
      } else if (String(e.target.value).length == 3) {
        multipleCard[index].cvv = String(multipleCard[index].cvv) + String(e.target.value).replace(/\*/g, "");
        multipleCard[index].cvvEncrypt = e.target.value.replace(/\d/g, "*");
        setcvvTextValue(multipleCard[index].cvvEncrypt);
      } else if (String(e.target.value).length == 4) {
        multipleCard[index].cvv = String(multipleCard[index].cvv) + String(e.target.value).replace(/\*/g, "");
        multipleCard[index].cvvEncrypt = e.target.value.replace(/\d/g, "*");
        setcvvTextValue(multipleCard[index].cvvEncrypt);
      } else {
        multipleCard[index].cvvEncrypt = "";
        multipleCard[index].cvv = "";
        setcvvTextValue("");
        setAcceptpaymentbuttonenable(true);
        enablepayment = false;
      }
      for (let i = 0; i < document.getElementsByName("grid").length; i++) {
        if (
          (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
          true
        ) {
          checked = 1;
        }
      }
      
    let cvvCount = 0;
    for (let i = 0; i < multipleCard.length; i++) {
      if (
        Number(multipleCard[i].Amount > 0) &&
        multipleCard[i].cvvEncrypt.length < 3
      ) {
        enablepayment = false;
        // setcvvTextValue("")
        cvvCount++;
      }
    }
    if (cvvCount > 0) {
      setcvvTextValue("");
    } else {
      setcvvTextValue("***");
    }
    if (
      Number(remainingamountdue) <= 0 &&
      selectedCheckBoxCount != 0 &&
      enablepayment &&
      !CardErrorLbl&&
      Number(chargeCardAmt) == 0
    ) {
      setAcceptpaymentbuttonenable(false);
    } else {
      setAcceptpaymentbuttonenable(true);
    }
    } else {
      multipleCard[index].cvvEncrypt = "";
      // setAcceptpaymentbuttonenable(true);
      // enablepayment = false;
      for (let i = 0; i < multipleCard.length; i++) {
        if (
          Number(multipleCard[i].Amount > 0) &&
          multipleCard[i].cvvEncrypt.length < 3
        ) {
          enablepayment = false;
          // setcvvTextValue("")
          // cvvCount++;
        }
      }
      if (
        Number(remainingamountdue) <= 0 &&
        selectedCheckBoxCount != 0 &&
        enablepayment &&
        !CardErrorLbl&&
        Number(chargeCardAmt) == 0
      ) {
        setAcceptpaymentbuttonenable(false);
      } else {
        setAcceptpaymentbuttonenable(true);
      }
    }
  };

  function ChkDivbinding(
    index: any,
    cardToken: any,
    lastFour: any,
    billingDetails: any,
    expireDate: any,
    cardType: any,
    customerPaymentMethodId: string
  ) {
    return chkbox.map((chkvalue: any) => {
      return (
        <Grid
          item
          className={`${classes.raccollg7} ${classes.floatLeft}`}
          key={index}
          id={"div-" + index}
          data-testid="2ndChkboxDivId"
          style={
            CardRadiArr[index] == false
              ? { display: "none" }
              : { display: "block" }
          }
        >
          <Grid
            item
            className={`${classes.raccollg5} ${classes.mt3} ${classes.px2} ${classes.floatLeft}`}
          >
            <div
              className={`${classes.inputgroup} ${classes.m0} ${classes.mt2}`}
            >
              <span className={classes.inputgrouptext}>$</span>
              <CurrencyInput
                data-testid="2ndtextinput"
                required={true}
                value={
                  multipleCard == undefined
                    ? "0.00"
                    : multipleCard[index] == undefined
                    ? "0.00"
                    : multipleCard[index].Amount
                }
                name="chkAmt"
                disabled={cardtxtDisable}
                type="text"
                onBlurCapture={(e) => cardTextonblur(e, index)}
                onBlur={(e) =>
                  Amtonclickbtn2ndbox(
                    e,
                    lastFour,
                    billingDetails,
                    index,
                    expireDate,
                    cardToken,
                    cardType,
                    customerPaymentMethodId
                  )
                }
                decimalsLimit={2}
                onValueChange={(value, name) =>
                  chkfunc(
                    index,
                    value,
                    name,
                    lastFour,
                    billingDetails,
                    expireDate,
                    cardToken,
                    customerPaymentMethodId
                  )
                }
                className={`${classes.formcontrol3} ${classes.textend}`}
                id={"txtCurr" + index}
              />
            </div>
          </Grid>
          <Grid
            item
            className={`${classes.raccollg4} ${classes.mt4} ${classes.floatLeft} ${classes.ps3}`}
          >
            <Grid
              item
              className={`${classes.floatLeft} ${classes.w25} ${classes.pt2}`}
            >
              <Typography
                htmlFor="exampleFormControlInput1"
                className={classes.formLabel}
                data-testid="CvvId"
                component={"label"}
              >
                CVV
              </Typography>
            </Grid>
            <Grid
              item
              className={`${classes.w50} ${classes.floatLeft} ${classes.ps2}`}
            >
              <RACTextbox
                isCurrency={false}
                name="chkcvv"
                id={"CVV" + index}
                pattern="\d*"
                maxlength={4}
                value={
                  multipleCard == undefined
                    ? ""
                    : multipleCard[index] == undefined
                    ? ""
                    : multipleCard[index].cvvEncrypt
                }
                OnChange={(e) => CVVChange(e, index)} //cvv on change
              />
            </Grid>
          </Grid>
          <Grid
            item
            className={`${classes.raccollg3} ${classes.mt3} ${classes.floatLeft} ${classes.px2}`}
          >
            <p
              className={`${classes.textend} ${classes.editCardbtn}`}
              data-bs-toggle="modal"
              data-bs-target="#editcard"
              onClick={() => edit(index)}
              data-testid="EditId"
            >
              Edit Card
            </p>
          </Grid>
        </Grid>
      );
    });
  }

  const GetcardMpping = () => {
    if (
      getCardService_state.cardResp !== undefined ||
      getCardService_state.length > 0
    ) {
      return getCardService_state.cardResp.map((value: any, index: any) => {
        return (
          <Grid
            item
            key={index}
            className={`${classes.w100} ${classes.floatLeft} ${classes.cardSplitBorder}`}
          >
            <Grid
              item
              className={`${classes.raccollg5} ${classes.pt2} ${classes.mt3} ${classes.floatLeft} ${classes.pe0}`}
            >
              <Grid
                item
                className={`${classes.formcheck} ${classes.mar6}  ${classes.ps0} ${classes.floatLeft} ${classes.alignContentCenter}`}
              >
                <RACCheckBox
                  style={{ float: "left" }}
                  defaultValue=""
                  id={"card-2" + index}
                  value={index}
                  name="Card CheckBox"
                  checked={CardRadiArr[index]}
                  disabled={cardtxtDisable}
                  onChange={(e) => {
                    if (isRevenueRecogActivated) {
                      SelectCardOptionChangeRevenue(
                        index,
                        value.cardExpiration,
                        value.cardToken,
                        value.cardLastFour,
                        String(value.customerPaymentMethodId)
                      );
                    } else {
                      SelectCardOptionChange(
                        index,
                        value.cardExpiration,
                        value.cardToken,
                        value.cardLastFour,
                        String(value.customerPaymentMethodId)
                      );
                    }
                  }}
                  data-testid="2ndCardChkboxId"
                />
                <Typography
                  className={`${classes.font14} ${classes.alignContentCenter}`}
                  htmlFor={"card-2" + index}
                  id={"ce" + index}
                  component={"label"}
                >
                  {" "}
                  {Bindcardsymbol(value.cardType)} Card ending with{" "}
                  {value.cardLastFour}
                </Typography>
              </Grid>
            </Grid>
            {ChkDivbinding(
              index,
              value.cardToken,
              value.cardLastFour,
              value.billingAdressDetails,
              value.cardExpiration,
              value.cardType,
              String(value.customerPaymentMethodId)
            )}
          </Grid>
        );
      });
    }
  };

  const tendertypebind = () => {
    return totalduetendertype.map((value: any, index: any) => {
      return (
        <Grid
          item
          key={index}
          className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
        >
          <Typography
            variant="caption"
            className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
          >
            {value.tendertype}
          </Typography>
          <Typography
            variant="caption"
            className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
          >
            <Typography className={classes.displayInline}>
              ${value.Amount}
            </Typography>
          </Typography>
        </Grid>
      );
    });
  };
  /*The settotaldueamount is used to calculate the total due based on the checkbox checking and also checking
   the status of the agreement > 3 number of payments and it is also used to check the check box for total selection if all the check box are selected
  Here the remaining amount due also calculated and also based on that the change value gets increased if remaining amount due is 0 and also total tax amount also gets calculated*/
  const descriptiontooltip = (e: any) => {
    settooltipdesdisplay(e);
  };

  const calculateTotalDueAmount = (
    id: any,
    index: any,
    paramAmount: any,
    paramAgrId: number,
    paramCheckedCount: number
  ) => {
    let sortedPayGridValue: any = paygridvalues;
    paygridvalues = [];
    // let payAgrs = paygridvalues.sort((a: any, b: any) =>
    // a["store_number"] < b["store_number"] ? -1 : 1
    // );
    let currentstorenumber = window.sessionStorage.getItem("storeNumber");
    let Clubagreementinsertion = sortedPayGridValue.filter(
      (x) => x.Agreementtype === "Club"
    );
    let curStoreData = sortedPayGridValue.filter(
      (x) =>
        x.store_number.toString() === currentstorenumber &&
        x.Agreementtype !== "Club"
    );
    Clubagreementinsertion.push(...curStoreData);
    let otherStoreData = sortedPayGridValue.filter(
      (x) =>
        x.store_number.toString() !== currentstorenumber &&
        x.Agreementtype !== "Club"
    );
    Clubagreementinsertion.push(...otherStoreData);
    sortedPayGridValue = Clubagreementinsertion;
    paygridvalues = sortedPayGridValue;
    // let filteredClubData: any = [];
    // let filteredNormalData: any = [];
    // paygridvalues.map((payGridObj, index) => {
    //   if (payGridObj.Agreementtype == "Club") {
    //     filteredClubData.push(payGridObj);
    //   } else {
    //     filteredNormalData.push(payGridObj);
    //   }
    // });
    // sortedPayGridValue = [...filteredClubData, ...filteredNormalData];
    epoAmt = Number(sortedPayGridValue[index].EPOAmount);
    let agrAmount = Number(sortedPayGridValue[index].Amount.toFixed(2));
    let curDate = new Date();
    let sacDate = new Date(sortedPayGridValue[index].SAC_Date);
    let disableAcceptPaymentButton = true;

    if (agrAmount >= epoAmt && curDate <= sacDate) {
      SacAmt = agrAmount - epoAmt;
    }
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let objId = id;
    let AmountDue = paramAmount;
    let addtotaldue = 0;
    let resultSelecteAgre = sortedPayGridValue.filter(function (e) {
      return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
    });

    resultSelecteAgre = resultSelecteAgre.filter((e : any) => !e?.isExchanged);
    
    let amount = 0;
    for (let i = 0; i < resultSelecteAgre.length; i++) {
      amount = amount + Number(resultSelecteAgre[i].Amount);
    }
    let objAvailCreditsAmt = 0;
    if (Number(amount) <= Number(sortedPayGridValue[index].wlDeposit)) {
      objAvailCreditsAmt = amount;
      objAvailCreditsAmt =
        diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
      setAvailableCreds(objAvailCreditsAmt);
      if (diffStoreErrLbl && isRevenueRecogActivated) {
        setavailableCredsremaining([]);
      } else {
        setavailableCredsremaining([
          {
            balanceType: "WEBLEAD",
            accountBalance: Number(amount).toFixed(2),
          },
        ]);
      }
    } else if (Number(amount) > Number(sortedPayGridValue[index].wlDeposit)) {
      objAvailCreditsAmt =
        Number(sortedPayGridValue[index].COA) +
        Number(sortedPayGridValue[index].wlDeposit) +
        Number(
          (Number(amount) - Number (sortedPayGridValue[index].wlDeposit) 
          > Number(sortedPayGridValue[index].ipSuspense) 
          ? Number(sortedPayGridValue[index].ipSuspense) : 
          (Number(amount) - Number (sortedPayGridValue[index].wlDeposit))));
      objAvailCreditsAmt =
        diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
      setAvailableCreds(objAvailCreditsAmt);
      if (diffStoreErrLbl && isRevenueRecogActivated) {
        setavailableCredsremaining([]);
      } else {
        setavailableCredsremaining([
          {
            balanceType: "WEBLEAD",
            accountBalance: Number(sortedPayGridValue[index].wlDeposit).toFixed(
              2
            ),
          },
          {
            balanceType: "COA",
            accountBalance: Number(sortedPayGridValue[index].COA).toFixed(2),
          },
          {
            balanceType: "IP Suspence",
            accountBalance: Number((Number(amount) - Number (sortedPayGridValue[index].wlDeposit) 
            > Number(sortedPayGridValue[index].ipSuspense) 
            ? Number(sortedPayGridValue[index].ipSuspense) : 
            (Number(amount) - Number (sortedPayGridValue[index].wlDeposit)))).toFixed(2),
          },
        ]);
      }
    } else if (Number(amount) == 0) {
      objAvailCreditsAmt =
        Number(sortedPayGridValue[index].COA) 
        // +
        // Number(sortedPayGridValue[index].ipSuspense);
      objAvailCreditsAmt =
        diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
      setAvailableCreds(objAvailCreditsAmt);
      if (diffStoreErrLbl && isRevenueRecogActivated) {
        setavailableCredsremaining([]);
      } else {
        setavailableCredsremaining([
          {
            balanceType: "COA",
            accountBalance: Number(sortedPayGridValue[index].COA).toFixed(2),
          },
          // {
          //   balanceType: "IP Suspence",
          //   accountBalance: Number(
          //     sortedPayGridValue[index].ipSuspense
          //   ).toFixed(2),
          // },
        ]);
      }
    }

    // Donation	
    if(Number(amount) == 0 && donationBtnFlag)	
    {	
      donationAmountAdd=0;	
      setDonationAmountAdd(donationAmountAdd);	
      setDonationAmount({...donationAmount,amount:"0.00"})	
      setDonationtextBoxFlag(true);	
      setDonationBtnFlag(false);	
    }

    let chargeCardAmount = 0;
    let swipeCardAmount = 0;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Ending with")
      ) {
        chargeCardAmount =
          chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Swipe ending")
      ) {
        swipeCardAmount =
          swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
    const CardAmount = Number(
      multipleCard.length == 0
        ? 0.0
        : multipleCard
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
    );
    let objTenderAmount =
      Number(addcash) +
      Number(totalamountcheck) +
      Number(CardAmount) +
      Number(chargeCardAmount) +
      Number(swipeCardAmount);

    let objCheBox = document.getElementById(objId) as HTMLInputElement;
    let selAgrID = Number(objCheBox.value.split("|")[0]);
    if (objCheBox.checked === true) {
      loadOtherActions();

      /*Acceptpayment button tooltip display starts here*/
      if (sortedPayGridValue[index].number_of_paymentsmade >= 3) {
        if (sortedPayGridValue[index].sign_status === "") {
          let toolTipMsg =
            sortedPayGridValue[index].confirmeddeliversystatus !== false
              ? "Please sign the agreement"
              : "Please sign the agreement and update the confirmed delivery status";
          settooltipdisplay(toolTipMsg);
        } else if (
          sortedPayGridValue[index].sign_status !== "" &&
          sortedPayGridValue[index].confirmeddeliversystatus === false
        ) {
          settooltipdisplay("Please update the confirmed delivery status");
        }
      }
      /*Acceptpayment button tooltip display ends here*/
      /*Total due receiptitem binding starts here*/
      let agreementDetails = {
        agreement_id:
          sortedPayGridValue[index].AgreementNumber == "Adjust"
            ? "Miscellaneous Item " +
              sortedPayGridValue[index].miscellaneousItemsNum
            : sortedPayGridValue[index].AgreementNumber,
        agreement_totalamount: parseFloat(fixedChange(AmountDue)),
      };
      totalduereceiptidsupport.push(agreementDetails);
      /*Total due receiptitem binding ends here*/

      let totaltaxamount = 0;
      totaltaxamount = totaltax + sortedPayGridValue[index].Taxamount;
      // settotaltax(totaltaxamount);
      totaltax = totaltaxamount;
      settotaltax(totaltax);

      addtotaldue = parseFloat(totaldue) + parseFloat(AmountDue);
      let objPmtAmtNoTender = addtotaldue;
      let objPmtAmtWithTender: any;
      if (resetInvoked) {
        objPmtAmtWithTender = Number(objPmtAmtNoTender);
      } else {
        objPmtAmtNoTender =
          objPmtAmtNoTender -
          objAvailCreditsAmt+
          Number(donationAmountAdd) +
          Number(addSusTotalAmount) -
          Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount); //32.14-17.86
        objPmtAmtWithTender = Number(objPmtAmtNoTender - objTenderAmount); //14.28 - 0
      }
      totaldue = addtotaldue;
      settotaldue(totaldue);

      // settotaldue(addtotaldue);
      if (objPmtAmtWithTender === 0.0) {
        setRemaniningAmtStatic(objPmtAmtNoTender);
        setremainingamountdue(0.0);
        setchange(0.0);
      } else if (objPmtAmtWithTender > 0.0) {
        setRemaniningAmtStatic(objPmtAmtNoTender);
        setremainingamountdue(objPmtAmtWithTender);
        setchange(0.0);
      } else if (objPmtAmtWithTender < 0.0) {
        setRemaniningAmtStatic(objPmtAmtNoTender);
        setremainingamountdue(0.0);
        setchange(Math.abs(objPmtAmtWithTender));
      }
      let checkedCount = 0;
      let objChkBoxList = document.getElementsByName("grid");
      for (let i = 0; i < objChkBoxList.length; i++) {
        let objCheckBox = objChkBoxList[i] as HTMLInputElement;
        if (objCheckBox.checked === true) {
          checkedCount++;
        }
      }
      if (objPmtAmtWithTender <= 0.0 && checkedCount > 0) {
        disableAcceptPaymentButton = false;
      } else {
        disableAcceptPaymentButton = true;
      }

      let ldwamountcalculation = "0.00";
      let objCarriedLateFee = 0;
      let objAmount = "0.00";
      let clubFlag = 0;
      let ldwFeeTax;
      let pifAmt = 0;
      let pifTax = 0;
      let sacTax = 0;
      let pifFlag = 0;
      let sacAmt = 0;
      let epoAmt = 0;
      let epoTax = 0;
      if (getpayment !== undefined && getpayment !== null) {
        let objAmtDue;
        if (sortedPayGridValue[index].Agreementtype === "Club") {
          objAmtDue = getpayment.amountDue.amountDueResponse.filter(
            (x) => x.clubAmountDue !== undefined
          );
        } else {
          objAmtDue = getpayment.amountDue.amountDueResponse.filter(
            (x) => x.agreementId === sortedPayGridValue[index].Agreementid
          );
        }
        if (objAmtDue.length > 0) {
          if (
            objAmtDue[0].ldwLateRent !== undefined &&
            objAmtDue[0].ldwLateRent !== null &&
            objAmtDue[0].ldwLateRent !== "" &&
            objAmtDue[0].ldwLateRent !== "0"
          ) {
            ldwamountcalculation = objAmtDue[0].ldwLateRent;
            ldwFeeTax = Number(objAmtDue[0].ldwTaxAmount);
          } else if (
            objAmtDue[0].ldwAmount !== undefined &&
            objAmtDue[0].ldwAmount !== null &&
            objAmtDue[0].ldwAmount !== ""
          ) {
            ldwamountcalculation = objAmtDue[0].ldwAmount;
            ldwFeeTax = Number(objAmtDue[0].ldwTaxAmount);
          }

          if (
            objAmtDue[0].amountDueLateRent !== undefined &&
            objAmtDue[0].amountDueLateRent !== null &&
            objAmtDue[0].amountDueLateRent !== "" &&
            objAmtDue[0].amountDueLateRent !== "0"
          ) {
            objAmount = objAmtDue[0].amountDueLateRent;
          } else if (objAmtDue[0].amountDue !== undefined) {
            objAmount = String(objAmtDue[0].amountDue);
          } else if (
            objAmtDue[0].clubAmountLateRent !== undefined &&
            objAmtDue[0].clubAmountLateRent !== null &&
            objAmtDue[0].clubAmountLateRent !== ""
          ) {
            clubFlag = 1;
            objAmount = objAmtDue[0].clubAmountLateRent;
          } else {
            clubFlag = 1;
            objAmount = String(objAmtDue[0].clubAmountDue);
          }
        }

        let objPayHistory = getpayment.paymentInfo.paymentHistoryInfo.filter(
          (x) => x.agreementId === sortedPayGridValue[index].Agreementid
        );
        if (objPayHistory !== undefined && objPayHistory.length > 0) {
          objCarriedLateFee = objPayHistory[0].carriedLateFee;
        }
        let agreementAmount = Number(
          (
            sortedPayGridValue[index].Amount -
            Number(sortedPayGridValue[index].lateFee) +
            Number(sortedPayGridValue[index].suspenseSub)
          ).toFixed(2)
        );

        if (sortedPayGridValue[index].Agreementtype == "EPO Rent") {
          if (sortedPayGridValue[index].Nextduedate == "EPO") {
            epoAmt =
              sortedPayGridValue[index].acceptEpoFlag == 0
                ? Number(
                    (
                      agreementAmount - sortedPayGridValue[index].Taxamount
                    ).toFixed(2)
                  )
                : Number(sortedPayGridValue[index].EPOAmount) +
                  Number(sortedPayGridValue[index].suspenseSub) -
                  Number(sortedPayGridValue[index].lateFee) -
                  Number(sortedPayGridValue[index].EPOTax);
            epoTax =
              sortedPayGridValue[index].acceptEpoFlag == 0
                ? Number(sortedPayGridValue[index].rpayTaxAmount)
                : Number(sortedPayGridValue[index].EPOTax) -
                  Number(sortedPayGridValue[index].lFeeTaxAmount);
          } else {
            sacAmt =
              sortedPayGridValue[index].acceptEpoFlag == 0
                ? Number(
                    (
                      agreementAmount - sortedPayGridValue[index].Taxamount
                    ).toFixed(2)
                  )
                : Number(sortedPayGridValue[index].EPOAmount) +
                  Number(sortedPayGridValue[index].suspenseSub) -
                  Number(sortedPayGridValue[index].lateFee) -
                  Number(sortedPayGridValue[index].EPOTax);
            sacTax =
              sortedPayGridValue[index].acceptEpoFlag == 0
                ? Number(sortedPayGridValue[index].rpayTaxAmount)
                : Number(sortedPayGridValue[index].EPOTax) -
                  Number(sortedPayGridValue[index].lFeeTaxAmount);
          }
          setfinalagr(Number(sortedPayGridValue[index].Agreementid));
          pifFlag = 1;
        } else if (sortedPayGridValue[index].Agreementtype == "PIF Rent") {
          pifAmt =
            sortedPayGridValue[index].acceptEpoFlag == 0
              ? Number(
                  (
                    agreementAmount - sortedPayGridValue[index].Taxamount
                  ).toFixed(2)
                )
              : Number(sortedPayGridValue[index].EPOAmount) +
                Number(sortedPayGridValue[index].suspenseSub) -
                Number(sortedPayGridValue[index].lateFee) -
                Number(sortedPayGridValue[index].EPOTax);
          pifTax =
            sortedPayGridValue[index].acceptEpoFlag == 0
              ? Number(sortedPayGridValue[index].rpayTaxAmount)
              : Number(sortedPayGridValue[index].EPOTax) -
                Number(sortedPayGridValue[index].lFeeTaxAmount);
          setfinalagr(Number(sortedPayGridValue[index].Agreementid));
          pifFlag = 1;
        }
        if (
          ((pifFlag == 1 &&
            Number(sortedPayGridValue[index].SuspenseAmount) > 0) ||
            (Number(
              sortedPayGridValue[index].SuspenseAmount >=
                sortedPayGridValue[index].EPOAmount
            ) &&
              sortedPayGridValue[index].Agreementtype !== "Club")) &&
          sortedPayGridValue[index].miscellaneousItemsNum == 0 &&
          Number(sortedPayGridValue[index].suspenseSub) == 0 &&
          sortedPayGridValue[index].Agreementtype !== "Rental Item Sale" &&
          sortedPayGridValue[index].Agreementtype !== "NSF Check" &&
          sortedPayGridValue[index].Agreementtype !==
            "Credit Card Charge Back" &&
          sortedPayGridValue[index].Agreementtype !== "Back Rent" &&
          sortedPayGridValue[index].Agreementtype !== "Restitution"
        ) {
          setsuspErrLbl(true);
          suspenseError.push(sortedPayGridValue[index].Agreementid);
        }
        if (
          Number(
            sortedPayGridValue[index].EPOAmount <
              sortedPayGridValue[index].Amount
          ) &&
          pifFlag != 1 &&
          sortedPayGridValue[index].Agreementtype !== "Club" &&
          sortedPayGridValue[index].miscellaneousItemsNum == 0 &&
          sortedPayGridValue[index].Agreementtype !== "Rental Item Sale" &&
          sortedPayGridValue[index].Agreementtype !== "NSF Check" &&
          sortedPayGridValue[index].Agreementtype !==
            "Credit Card Charge Back" &&
          sortedPayGridValue[index].Agreementtype !== "Back Rent" &&
          sortedPayGridValue[index].Agreementtype !== "Restitution"
        ) {
          // setepoErrLbl(true);
          epoError.push(sortedPayGridValue[index].Agreementid);
        }
        // if (
        //   String(paygridvalues[index].store_number) !==
        //   window.sessionStorage.getItem("storeNumber")
        // ) {
        //   setdiffStoreErrLbl(true);
        //   diffStoreError.push(paygridvalues[index].Agreementid);
        // }
      }

      let ChangeAmt = change;

      if (sortedPayGridValue[index].miscellaneousItemsNum == 0) {
        let ReceiptArray = {
          customerId: Number(customerId),
          schedule:
            sortedPayGridValue[index].Schedule +
            "|" +
            sortedPayGridValue[index].AgreementNumber,
          amountDue: Number(
            clubFlag !== 1 && pifFlag == 0
              ? (
                  sortedPayGridValue[index].Amount -
                  Number(sortedPayGridValue[index].lateFee)
                ).toFixed(2)
              : 0
          ),
          ldwFeeAmount: Number(sortedPayGridValue[index].agrLdwAmount),
          clubPayment: Number(
            clubFlag === 1
              ? Number(sortedPayGridValue[index].Amount.toFixed(2)) -
                  Number(sortedPayGridValue[index].Taxamount)
              : 0
          ),
          clubPaymentTax: Number(
            clubFlag === 1 ? sortedPayGridValue[index].Taxamount : 0
          ),
          creditOnAccountAdd: 0,
          creditOnAccountSub: Number(sortedPayGridValue[index].COA),
          integratedPaymentSuspense:
            -1 * Number(sortedPayGridValue[index].ipSuspense),
          initialPayment:
            clubFlag === 1
              ? 0
              : sortedPayGridValue[index].number_of_paymentsmade === 0
              ? 1
              : 0,
          netLateFee: 0,
          carriedLateFee: 0,
          wlDeposit: -1 * Number(sortedPayGridValue[index].wlDeposit),
          htSuspense: 0,
          suspenseAdd: Number(sortedPayGridValue[index].suspenseAdd),
          suspenseSub: Number(sortedPayGridValue[index].suspenseSub), //Number(paygridvalues[index].suspense),
          racTirePay: Number(sortedPayGridValue[index].policyAmount),
          agreementId:
            clubFlag !== 1
              ? Number(sortedPayGridValue[index].Agreementid)
              : undefined,
          change: Number(fixedChange(ChangeAmt)),
          totalTax: sortedPayGridValue[index].Taxamount,
          sac: Number(sacAmt),
          sacTax: Number(sacTax),
          epo: Number(epoAmt),
          epoTax: Number(epoTax),
          epoDiscount:
            sortedPayGridValue[index].acceptEpoFlag == 0
              ? 0.0
              : Number(sortedPayGridValue[index].actualEpoAmount) -
                Number(sortedPayGridValue[index].editedEPOAmount),
          pastDueDiscount:
            sortedPayGridValue[index].acceptEpoFlag == 0
              ? 0.0
              : Number(sortedPayGridValue[index].actualPastDueRent) -
                Number(sortedPayGridValue[index].pastDueRent),
          pastDueAmount:
            sortedPayGridValue[index].acceptEpoFlag == 0
              ? undefined
              : Number(sortedPayGridValue[index].actualPastDueRent),
          nextDueDate:
            sortedPayGridValue[index].acceptEpoFlag == 0
              ? undefined
              : sortedPayGridValue[index].Nextduedate,
          ldwFeeAmountTax: Number(sortedPayGridValue[index].ldwTaxAmount),
          rentalPaymentTax: Number(
            pifFlag == 0 && clubFlag == 0
              ? sortedPayGridValue[index].rpayTaxAmount
              : 0
          ),
          lateRentalPaymentfeeTax: Number(
            sortedPayGridValue[index].lFeeTaxAmount
          ),
          lateRentalPaymentfee: Number(sortedPayGridValue[index].lateFee),
          racTirePayTax: Number(sortedPayGridValue[index].policyTaxAmount),
          deliveryFee: Number(sortedPayGridValue[index].deliveryAmount),
          deliveryFeeTax: Number(sortedPayGridValue[index].deliveryAmountTax),
          processingFee: Number(sortedPayGridValue[index].processingFee),
          processingFeeTax: Number(sortedPayGridValue[index].processingFeeTax),
          carryRentAmountUsed:
            sortedPayGridValue[index].FreeTime == null
              ? Number(sortedPayGridValue[index].carryRentAmountUsed)
              : 0,
          carryRentTaxUsed:
            sortedPayGridValue[index].FreeTime == null
              ? Number(sortedPayGridValue[index].carryRentTaxUsed)
              : 0,
          pif: Number(pifAmt),
          pifTax: Number(pifTax),
          extensionAmount: Number(sortedPayGridValue[index].extensionAmount),
          convenienceFee: 0,
          convenienceFeeTax: 0,
          mattressFee: Number(sortedPayGridValue[index].mattressFee),
        };

        ReceiptCategoriesRequestParam.push(ReceiptArray);
      }

      if (
        sortedPayGridValue[index].Agreementtype !== "Club" &&
        sortedPayGridValue[index].miscellaneousItemsNum == 0
      ) {
        let acceptPaymentArray = {
          extensionAmount: Number(sortedPayGridValue[index].extensionAmount),
          noOfDaysFree: Number(sortedPayGridValue[index].noOfDaysFree),
          initialPaymentFlag:
            sortedPayGridValue[index].number_of_paymentsmade == 0 ? 1 : 0,
          addTransferSusFlag: 0,
          schedule: sortedPayGridValue[index].Schedule,
          agreementId: Number(sortedPayGridValue[index].Agreementid),
          ldwFeeAmount: Number(
            sortedPayGridValue[index].agrLdwAmount.toFixed(2)
          ), // Added By Me
          // Number(paygridvalues[index].PromoFreeDaysApplied) == 0
          //   ? Number(paygridvalues[index].agrLdwAmount.toFixed(2))
          //   : 0.0,
          agreementAmountDue: Number(
            fixedChange(
              String(
                Number(sortedPayGridValue[index].Amount) +
                  (Number(sortedPayGridValue[index].acceptEpoFlag) == 1
                    ? Number(sortedPayGridValue[index].suspenseSub)
                    : 0)
              )
            )
          ),
          remainingEpoAmount: Number(
            (Number(
              sortedPayGridValue[index].EPOAmount -
                sortedPayGridValue[index].EPOTax
            ) < 0
              ? 0
              : Number(
                  sortedPayGridValue[index].EPOAmount -
                    sortedPayGridValue[index].EPOTax
                )
            ).toFixed(2)
          ),
          promoFreeDays: Number(sortedPayGridValue[index].PromoFreeDaysApplied),
          netLateFee: Number(sortedPayGridValue[index].lateFee),
          carriedLateFee: Number(
            Number(sortedPayGridValue[index].carriedLateFee).toFixed(2)
          ),
          numberOfDaysLate: sortedPayGridValue[index].CurrentDaysLate,
          originalDueDate: sortedPayGridValue[index].CurrentDueDate,
          nextDueDate: sortedPayGridValue[index].Nextduedate,
          totalTax: Number(sortedPayGridValue[index].Taxamount.toFixed(2)),
          policyAmount: Number(Number(sortedPayGridValue[index].policyAmount)),
          deliveryFeeAmount: Number(sortedPayGridValue[index].deliveryAmount),
          processingFeeAmount: Number(sortedPayGridValue[index].processingFee),
          otherFeeAmount: Number(sortedPayGridValue[index].mattressFee),
          editSchedule: sortedPayGridValue[index].editSchedule,
          freeTime: sortedPayGridValue[index].FreeTime, // Added By Me
          ldwActivate: undefined,
        };
        acceptPaymentParam.push(acceptPaymentArray);
        setAcceptClub(undefined);
      } else if (sortedPayGridValue[index].miscellaneousItemsNum == 0) {
        AcceptclubPaymentParam = {
          paymentSchedule: sortedPayGridValue[index].Schedule,
          originalDueDate: sortedPayGridValue[index].CurrentDueDate,
          nextDueDate: sortedPayGridValue[index].Nextduedate,
          numberOfDaysLate: sortedPayGridValue[index].CurrentDaysLate,
          clubTax: String(sortedPayGridValue[index].Taxamount.toFixed(2)),
          clubAmountDue: Number(
            Number(sortedPayGridValue[index].Amount).toFixed(2)
          ),
          editSchedule: sortedPayGridValue[index].editSchedule,
        };
        setAcceptClub(AcceptclubPaymentParam);
      }
      // code added for the late fee calculation added by Mano Ranjith
      let Individuallateadditionfee =
        Number(sortedPayGridValue[index].lateFee) + Latelast10value;
      setLatelast10value(Individuallateadditionfee);
    } else {
      if (totalduereceiptidsupport.length - 1 == 1) {
        loadOtherActions();
      } else {
        setOtherActionHidden(true);
        setshowLDWPolicy(false);
      }

      /*Accept payment empty tooltip if check box is not selected */
      settooltipdisplay("");

      /*Total due receiptitem binding starts here*/
      settotaldue(RemaniningAmtStatic);
      for (let i = 0; i < totalduereceiptidsupport.length; i++) {
        if (
          totalduereceiptidsupport[i].agreement_id ===
            parseInt(sortedPayGridValue[index].AgreementNumber.toString()) ||
          totalduereceiptidsupport[i].agreement_id ===
            sortedPayGridValue[index].AgreementNumber
        ) {
          totalduereceiptidsupport.splice(i, 1);
          break;
        } else if (
          Number(
            totalduereceiptidsupport[i].agreement_id.split(
              "Miscellaneous Item "
            )[1]
          ) == Number(sortedPayGridValue[index].miscellaneousItemsNum)
        ) {
          totalduereceiptidsupport.splice(i, 1);
        }
      }
      /*Total due receiptitem binding ends here*/

      let totaltaxamount = 0;
      totaltaxamount = totaltax - sortedPayGridValue[index].Taxamount;
      // settotaltax(totaltaxamount);
      totaltax = totaltaxamount;
      settotaltax(totaltax);

      let reducetotaldue = Number(totaldue) - Number(AmountDue);
      reducetotaldue = Number(reducetotaldue.toFixed(2));
      settotaldue(reducetotaldue>0?reducetotaldue:'0.00');
      let objtendertypereducechange =
        objTenderAmount +
        (paramCheckedCount > 0
          ? objAvailCreditsAmt + Number(usedSusTotalAmount) + Number(totalSurrenderAmount)
          : 0);
      objtendertypereducechange = Number(objtendertypereducechange.toFixed(2));
      let objRedPmtWithTenderAndAvailCredits =
        reducetotaldue - objtendertypereducechange + Number(donationAmountAdd)+ Number(addSusTotalAmount) + Number(totalDepositAmount);
      objRedPmtWithTenderAndAvailCredits = Number(
        objRedPmtWithTenderAndAvailCredits.toFixed(2)
      );
      objRedPmtWithTenderAndAvailCredits < 0.0
        ? setremainingamountdue(0.0)
        : setremainingamountdue(objRedPmtWithTenderAndAvailCredits); // change here .tofixed

      let objRedPmtWithNoTender =
        reducetotaldue -
        (paramCheckedCount > 0
          ? objAvailCreditsAmt+
          Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          : 0);
      objRedPmtWithNoTender = Number(objRedPmtWithNoTender.toFixed(2));
      objRedPmtWithNoTender < 0.0
        ? setRemaniningAmtStatic(0.0)
        : setRemaniningAmtStatic(objRedPmtWithNoTender);
      //amount is greater and customer still has remaing amount due to pay
      if (objRedPmtWithTenderAndAvailCredits > 0.0) {
        setremainingamountdue(objRedPmtWithTenderAndAvailCredits);
        disableAcceptPaymentButton = true;
        setchange(0.0);
        setRemaniningAmtStatic(objRedPmtWithNoTender);
      }
      //customer has removed all agreements
      else if (paramCheckedCount == 0) {
        setremainingamountdue(0.0);
        disableAcceptPaymentButton = true;
        setchange(0.0);
        setRemaniningAmtStatic(0.0);
      }
      //customer has paid exact amount either through avab credits or with tender type as well
      else if (objRedPmtWithTenderAndAvailCredits == 0.0) {
        setremainingamountdue(0.0);
        disableAcceptPaymentButton = false;
        setchange(0.0);
        setRemaniningAmtStatic(objRedPmtWithNoTender);
      }
      //customer made overpayment after unchecking
      else if (objRedPmtWithTenderAndAvailCredits < 0.0) {
        setremainingamountdue(0.0);
        disableAcceptPaymentButton = false;
        setchange(Math.abs(objRedPmtWithTenderAndAvailCredits));
        setRemaniningAmtStatic(
          objRedPmtWithNoTender >= 0.0 ? objRedPmtWithNoTender : 0.0
        );
      }

      let rcDataExists = ReceiptCategoriesRequestParam.filter(
        (x) => x.agreementId === selAgrID
      );
      let susErrorAgr = suspenseError.filter(
        (x: any) => x === String(selAgrID)
      );
      let diffStoreErrAgr = diffStoreError.filter(
        (x: any) => String(x) === String(selAgrID)
      );
      let epoErrorAgr = epoError.filter((x: any) => x === String(selAgrID));
      if (rcDataExists != undefined && rcDataExists.length > 0) {
        ReceiptCategoriesRequestParam.splice(
          ReceiptCategoriesRequestParam.indexOf(rcDataExists[0]),
          1
        );
      }

      let apDataExists = acceptPaymentParam.filter(
        (x) => x.agreementId === selAgrID
      );
      if (apDataExists != undefined && apDataExists.length > 0) {
        acceptPaymentParam.splice(
          acceptPaymentParam.indexOf(apDataExists[0]),
          1
        );
      }

      let clubRCDataExists = ReceiptCategoriesRequestParam.filter(
        (x) => x.clubPayment > 0
      );
      if (sortedPayGridValue[index].Agreementtype === "Club") {
        AcceptclubPaymentParam = undefined;
        if (clubRCDataExists != undefined && clubRCDataExists.length > 0) {
          ReceiptCategoriesRequestParam.splice(
            ReceiptCategoriesRequestParam.indexOf(clubRCDataExists[0]),
            1
          );
        }
      }
      if (susErrorAgr != undefined && susErrorAgr.length > 0) {
        suspenseError.splice(suspenseError.indexOf(susErrorAgr[0]), 1);
        if (suspenseError.length == 0) setsuspErrLbl(false);
      }
      if (epoErrorAgr != undefined && epoErrorAgr.length > 0) {
        epoError.splice(epoError.indexOf(epoErrorAgr[0]), 1);
        // if (epoError.length == 0) setepoErrLbl(false);
      }
      if (diffStoreErrAgr != undefined && diffStoreErrAgr.length > 0) {
        diffStoreError.splice(diffStoreError.indexOf(diffStoreErrAgr[0]), 1);
      }
      let collectSelectedBoxIndex: any = [];
      for (let i = 0; i < document.getElementsByName("grid").length; i++) {
        if (
          (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
          true
        ) {
          collectSelectedBoxIndex.push(i);
        }
      }
      let addedlatevalue = 0;
      for (let i = 0; i < sortedPayGridValue.length; i++) {
        for (let j = 0; j < collectSelectedBoxIndex.length; j++) {
          if (collectSelectedBoxIndex[j] == i) {
            addedlatevalue =
              Number(sortedPayGridValue[i].lateFee) + addedlatevalue;
          }
        }
      }
      // code added for the late fee subtraction added by Mano Ranjith
      // let Individuallatesubtractionfee =
      //   Latelast10value - Number(sortedPayGridValue[index].lateFee);
      setLatelast10value(addedlatevalue);
    }

    /*Total due receiptitem added to state varaible*/
    settotalduereceiptid(totalduereceiptidsupport);
    setcoaamt(sortedPayGridValue[index].COA);
    setwldepositamount(sortedPayGridValue[index].wlDeposit);
    setipsuspense(sortedPayGridValue[index].ipSuspense);
    setReceiptCategory(ReceiptCategoriesRequestParam);
    SetAcceptPayments(acceptPaymentParam);

    let checkedCount = 0;
    let objChkBoxList = document.getElementsByName("grid");
    autoPayEnrollArray = [];
    for (let i = 0; i < objChkBoxList.length; i++) {
      let objCheckBox = objChkBoxList[i] as HTMLInputElement;
      if (objCheckBox.checked === true) {
        let objAgrID = objCheckBox.value.split("|")[0];
        checkedCount++;

        let objPgVal = sortedPayGridValue.filter(
          (x) => x.Agreementid == Number(objAgrID)
        );

        let auPay: AutoPayType = {
          schedule: objPgVal[0].Schedule.toString(),
          autoPay:
            objPgVal[0].Autopay_enrollmentdate !== "" &&
            objPgVal[0].Autopay_enrollmentdate !== undefined &&
            objPgVal[0].Autopay_enrollmentdate !== null,
          itemIndex: index,
          agreementID: Number(objAgrID),
        };
        autoPayEnrollArray.push(auPay);
      }
      checkedCount == objChkBoxList.length
        ? setOverAll_Chk(true)
        : setOverAll_Chk(false);
    }
    if (checkedCount === 0) {
      disableAcceptPaymentButton = true;
      resetPayment(0);
    }

    if (
      !objCheBox.checked &&
      objTenderAmount >
        Number(totaldue) -
          Number(availableCreds) -
          Number(usedSusTotalAmount) -
          Number(totalSurrenderAmount) -
          Number(AmountDue) &&
      objTenderAmount > 0 &&
      checkedCount > 0
    ) {
      resetPayment(AmountDue);
      let reducetotaldue =
        Number(totaldue) - Number(AmountDue)+Number(donationAmountAdd) + Number(addSusTotalAmount) + Number(totalDepositAmount);
      reducetotaldue = Number(reducetotaldue.toFixed(2));
      let objRedPmtWithNoTender =
        reducetotaldue -
        (paramCheckedCount > 0
          ? objAvailCreditsAmt - Number(usedSusTotalAmount) - Number(totalSurrenderAmount)
          : 0);
      objRedPmtWithNoTender = Number(objRedPmtWithNoTender.toFixed(2));
      //amount is greater and customer still has remaing amount due to pay

      if (objRedPmtWithNoTender > 0.0) {
        setremainingamountdue(objRedPmtWithNoTender);
        disableAcceptPaymentButton = true;
        setchange(0.0);
        setRemaniningAmtStatic(objRedPmtWithNoTender);
      }
      //customer has removed all agreements
      else if (paramCheckedCount == 0) {
        setremainingamountdue(0.0);
        disableAcceptPaymentButton = true;
        setchange(0.0);
        setRemaniningAmtStatic(0.0);
      }
      //customer has paid exact amount either through avab credits or with tender type as well
      else if (objRedPmtWithNoTender == 0.0) {
        setremainingamountdue(0.0);
        disableAcceptPaymentButton = false;
        setchange(0.0);
        setRemaniningAmtStatic(objRedPmtWithNoTender);
      }
      //customer made overpayment after unchecking
      else if (objRedPmtWithNoTender < 0.0) {
        setremainingamountdue(0.0);
        disableAcceptPaymentButton = false;
        setchange(Math.abs(objRedPmtWithNoTender));
        setRemaniningAmtStatic(
          objRedPmtWithNoTender >= 0.0 ? objRedPmtWithNoTender : 0.0
        );
      }
      //disableAcceptPaymentButton = true;
    }

    let agreementgrid: AgreementVals = {
      ReceiptCategoriesRequestDTOParam: ReceiptCategory,
      acceptPayment: acceptPaymentParam,
      acceptClubPayment: AcceptClub,
    };

    let no_payments_made = sortedPayGridValue[index].number_of_paymentsmade;
    const resultSelecteAgres = sortedPayGridValue.filter(function (e) {
      return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
    });
    let noOfPaymentsAllowedToggle = 0;
    for (let i = 0; i < resultSelecteAgres.length; i++) {
      if (
        resultSelecteAgres[i].number_of_paymentsmade == 0 ||
        String(resultSelecteAgres[i].store_number) !=
          String(window.sessionStorage.getItem("storeNumber"))
      ) {
        noOfPaymentsAllowedToggle = 1;
      }
    }
    if (noOfPaymentsAllowedToggle == 0) {
      /* Autopay enrollment logic starts here */
      const distintSchedule = Array.from(
        new Set(autoPayEnrollArray.map((item) => item.schedule))
      );
      const apEnroll = Array.from(
        new Set(autoPayEnrollArray.map((item) => item.autoPay))
      );

      if (distintSchedule.length === 1 && apEnroll.length === 1) {
        if (apEnroll[0]) {
          setAPUPStates({ ...APUPStates, IsAP: false, IsUP: true });
        } else {
          setAPUPStates({ ...APUPStates, IsAP: true, IsUP: false });
        }
      } else {
        setAPUPStates({ ...APUPStates, IsAP: false, IsUP: false });
      }
      /* Autopay enrollment logic ends here */
    } else {
      setAPUPStates({ ...APUPStates, IsAP: false, IsUP: false });
    }
    setAcceptpaymentbuttonenable(disableAcceptPaymentButton);
    FormAcceptPaymentData(0, objAvailCreditsAmt);
    resetInvoked = false;
    if (carryRentWidget) {
      carryOnchange();
    }
  };

  /**
   * This method will reset all added tenders, reset the arrays, and
   * will reset the remaining amount, total due and remaining amount static.
   *
   * @param paramAmount - Amount will be passed as 0 / actual agreement amount to be reduced.
   */
  const resetPayment = (paramAmount?: number) => {
    setAcceptpaymentbuttonenable(true);
    setaddcash("0.00");
    settotalamountcheck(0.0);
    setadddata(temp);
    setgettotamt("0.00");
    addrowarray = [];
    let objChkMo: CheqAndMO[] = [];
    setaddataanother(objChkMo);
    tendertypeglobalarray = [];
    totalduetendertype=[]
    settotalduetendertype(totalduetendertype);
    const TempCard_Array: any = new Array(CardRadiArr.length).fill(false);
    setCardRadiArr(TempCard_Array);
    for (let i = 0; i < multipleCard.length; i++) {
      multipleCard[i].Amount = "0.00";
    }
    setmultipleCard(multipleCard);
    setCardErrorLbl(false);
    setCardGridData(CardGrid);
    setcvvTextValue("");
    setswipeFlag(false);
    setcardDetails([]);
    swipeCardDetails = [];
    swipeCardAmt = [];
    setcardtxtDisable(false);
    setchargeCardArray([]);
    setCardAmt([{ amount: 0.0 }]);
    setswipeamt("0.00");
    if (paramAmount === 0) {
      setRemaniningAmtStatic("0.00");
      setremainingamountdue("0.00");
      setchange(0.0);
    } else if (paramAmount != undefined) {
      const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Add" && Number(e.newsuspense) > 0;
      });
      const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Used" && Number(e.newsuspense) > 0;
      });
      let addSusTotalAmount =
        addSuspenseAmount.length == 0
          ? 0.0
          : addSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let usedSusTotalAmount =
        useSuspenseAmount.length == 0
          ? 0.0
          : useSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let objRAmt =
        Number(totaldue) -
        Number(availableCreds) +
        Number(donationAmountAdd)+
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount) -
        Number(paramAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount);
      setRemaniningAmtStatic(objRAmt.toFixed(2));
      setremainingamountdue(objRAmt.toFixed(2));
      setchange(0.0);
      let traceObj={
        iD:Number(userTraceData?.length)+1,
        type:"ResetPayment"
      }
      setUserTraceData([...userTraceData,traceObj])
    }
  };
  /**
   * changes : Adjust Due Date v2
   * Developer : Muzzammil
   * Desc : Adjust Due Date button needs to enable only when it mets certails condition
   *
   * @param id
   * @param index
   * @param amount
   * @param agreementselectedId
   * @param e
   */
  const settotaldueamount = (
    id: any,
    index: number,
    amount: number,
    agreementselectedId: number,
    e: any
  ) => {
    let sortedPayGridValue: any = paygridvalues;
    let currentstorenumber = window.sessionStorage.getItem("storeNumber");
    paygridvalues = [];
    let Clubagreementinsertion = sortedPayGridValue.filter(
      (x) => x.Agreementtype === "Club"
    );
    let curStoreData = sortedPayGridValue.filter(
      (x) =>
        x.store_number.toString() === currentstorenumber &&
        x.Agreementtype !== "Club"
    );
    Clubagreementinsertion.push(...curStoreData);
    let otherStoreData = sortedPayGridValue.filter(
      (x) =>
        x.store_number.toString() !== currentstorenumber &&
        x.Agreementtype !== "Club"
    );
    Clubagreementinsertion.push(...otherStoreData);
    sortedPayGridValue = Clubagreementinsertion;
    paygridvalues.push(...Clubagreementinsertion);
    // let filteredClubData: any = [];
    // let filteredNormalData: any = [];
    // paygridvalues.map((payGridObj, index) => {
    //   if (payGridObj.Agreementtype == "Club") {
    //     filteredClubData.push(payGridObj);
    //   } else {
    //     filteredNormalData.push(payGridObj);
    //   }
    // });
    // sortedPayGridValue = [...filteredClubData, ...filteredNormalData];
    let checked; // to check if the check box is checked or unchecked
    let objCheAdjust = document.getElementById(id) as HTMLInputElement;

    if (objCheAdjust.checked === true) {
      checked = true;
    } else {
      checked = false;
    }
    if (
      agreementselectedId !== undefined &&
      agreementselectedId !== null &&
      checked == true
    ) {
      setAgrId(agreementselectedId);
      adjustDueDateSelectedAgre.push(agreementselectedId);
      adjustDueDateSelectedAgre = Array.from(
        new Set(adjustDueDateSelectedAgre)
      );
      setadjustDueDateSelectedAgre(adjustDueDateSelectedAgre);
    } else {
      adjustDueDateSelectedAgre = Array.from(
        new Set(adjustDueDateSelectedAgre)
      );
      adjustDueDateSelectedAgre = adjustDueDateSelectedAgre.filter(function (
        value,
        index,
        arr
      ) {
        return value != agreementselectedId;
      }); //Used for to remove the unchecked agrrement id from the array that which it already pushed into the array while checked
      setadjustDueDateSelectedAgre(adjustDueDateSelectedAgre);
      if (adjustDueDateSelectedAgre.length == 0) {
        setdeliveryUnconfiremd(false);
      }
      setAgrId(undefined);
    }
    const agr: any = sortedPayGridValue.filter(
      (el: any) => el.Agreementid == agreementselectedId
    );
      multipleAdjust();
    let objCheBox = document.getElementById(id) as HTMLInputElement;
    let collectSelectedBoxCount = 0;
    for (let i = 0; i < document.getElementsByName("grid").length; i++) {
      if (
        (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
        true
      ) {
        collectSelectedBoxCount = collectSelectedBoxCount + 1;
      }
    }
    let checkedCount = collectSelectedBoxCount;
    const agrsus: any = sortedPayGridValue.filter(
      (el: any) => el.Agreementid == String(agreementselectedId)
    );
    if (objCheBox.checked === true) {
      //checkedCount = checkedCount + 1;

      let insertArray: any[] = [];
      for (let i = 0; i < paygridvalues?.length; i++) {
        if (
          paygridvalues[i].Agreementtype != "Club" &&
          paygridvalues[i].Nextduedate != "PIF" &&
          paygridvalues[i].Nextduedate != "SAC" &&
          paygridvalues[i].Nextduedate != "EPO" &&
          paygridvalues[i].number_of_paymentsmade > 0 &&
          String(paygridvalues[i].store_number) ==
            String(window.sessionStorage.getItem("storeNumber"))
        ) {
          insertArray.push({
            sustype: "type",
            agreementnumber: paygridvalues[i].Agreementid,
            agreementid: paygridvalues[i].AgreementNumber,
            schedure: paygridvalues[i].Schedule,
            paymentamount: paygridvalues[i].Amount,
            epoamount: paygridvalues[i].EPOAmount,
            suspenseamount: Number(paygridvalues[i].SuspenseAmount).toFixed(2),
            newsuspense: Number(paygridvalues[i].SuspenseAmount).toFixed(2),
            agreementType: paygridvalues[i].Agreementtype,
          });
        }
      }

      if (
        agrsus[0].Agreementtype != "Club" &&
        agrsus[0].Nextduedate != "SAC" &&
        agrsus[0].Nextduedate != "PIF" &&
        agrsus[0].Nextduedate != "EPO" &&
        agrsus[0].number_of_paymentsmade != 0
      ) {
        setaddsuslist(false);
      }

      if (
        agrsus[0].Agreementtype != "Club" &&
        agrsus[0].Nextduedate != "SAC" &&
        agrsus[0].Nextduedate != "PIF" &&
        agrsus[0].Nextduedate != "EPO" &&
        agrsus[0].number_of_paymentsmade != 0 &&
        returnType != "RETURN" &&
        returnType != "CHRGOFF"
      ) {
        setDisableTransfer(false);
      } else if (
        agrsus[0].Agreementtype != "Club" &&
        agrsus[0].Nextduedate != "SAC" &&
        agrsus[0].Nextduedate != "PIF" &&
        agrsus[0].Nextduedate != "EPO" &&
        agrsus[0].number_of_paymentsmade != 0 &&
        (returnType == "RETURN" || returnType == "CHRGOFF") &&
        insertArray.length > 1
      ) {
        setDisableTransfer(false);
      } else {
        setDisableTransfer(true);
      }

      if (checkedCount == 1) {
        setrefundsuslist(false);
      } else if (checkedCount > 1) {
        setrefundsuslist(true);
        setmastersuspensearray([]);
      }
    } else {
      if (checkedCount <= 0) {
        setaddsuslist(true);
        setDisableTransfer(true);
        setrefundsuslist(true);
        setmastersuspensearray([]);
      }
      if (checkedCount == 1) {
        setrefundsuslist(false);
      }
    }
    setSelectedCheckBoxCount(checkedCount);
    let totalsusamount: number;
    totalsusamount = 0;
    for (let i = 0; i < checkedAgr.length; i++) {
      totalsusamount = totalsusamount + checkedAgr[i].suspenseamount;
    }
    settotolsuspenseamount(totalsusamount);
    remainingamountdue;
    if (selectedCheckBoxCount > 0 && carryRentWidget == true) {
      setcarryRentCheck(true);
    }
    if (checkedCount == 0) {
      setcarryMessage("No agreements are selected");
      setcarryRentWidget(false);
      setcarryRentAmount("0.00");
      if (paymentOrigin == "Pay By Phone") {
        settotaldue(convAmtWTax);
      } else {
        settotaldue("0.00");
      }
      if (donationBtnFlag) {
        setDonationBtnFlag(false);
        setDonationAmountAdd(0);
        setDonationtextBoxFlag(true);
      }
    }
    calculateTotalDueAmount(
      id,
      index,
      amount,
      agreementselectedId,
      checkedCount
    );
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:checked?`SelectedAgrCheckBox-${agreementselectedId}`:`UnSelectedAgrCheckBox-${agreementselectedId}`
    }
    setUserTraceData([...userTraceData,traceObj])
    
  };

  const removesuspense = (agrsus: any) => {
    let removearray = JSON.parse(JSON.stringify([...totalsuspensemaster]));

    for (let i = 0; i < removearray.length; i++) {
      if (removearray[i].agreementid == agrsus[0].AgreementNumber) {
        removearray.splice(i, 1);
        break;
      }
    }
    settotalsuspensemaster(removearray);
  };

  /**changes : adjust due date v2
   * Develeoper : Muzzammil
   * Desc : if the current due date and schedule and amount due is same then we can adjust
   *        the next due date
   */

  const multipleAdjust = () => {
    acceptPaymentParam;
    AcceptclubPaymentParam;
    adjustDueDateSelectedAgre = Array.from(new Set(adjustDueDateSelectedAgre)); //removing duplicate agreementids
    let storeNumber = String(window.sessionStorage.getItem("storeNumber")); //to get the storeNumber
    const clubStatus: any = paygridvalues.filter(
      (el: any) => el?.Agreementtype == "Club"
    );
    const sameStoreAgr= paygridvalues.filter((x)=>x.store_number==storeNumber && x.Agreementtype != "Club"&& x.otherIncomeType == "")
    if (clubStatus.length > 0) {
      setdeactivateClubAmount(clubStatus[0].Amount);
    }
    if (clubCheckDisabled == false) {
      if (
        clubStatus.length > 0 &&
        !clubStatus[0].originAgreementId &&
        !clubStatus[0].clubDocumentSignStatus
      ) {
        setclubBtnStatus("A");
        setCustOrder("A");
      }
      if (clubStatus.length > 0) {
        if (clubStatus[0].number_of_paymentsmade > 0) {
          setclubStatus("D");
        } else if (clubStatus[0].number_of_paymentsmade == 0) {
          setclubStatus("P");
        }
        if (
          clubStatus[0]?.store_number == window.sessionStorage.getItem("storeNumber")
        ) {
          setisclubOnSameStore(false);
        }
        else{
          setisclubOnSameStore(true)
        }
      } else if (paygridvalues?.length == 0 ||(clubStatus.length==0 && paygridvalues?.length >0 && sameStoreAgr?.length==0) ) {
        setclubStatus("A");
        setisclubOnSameStore(true);
      } else {
        setclubStatus("A");
        setisclubOnSameStore(false);
      }
    }

    let resultSelecteAgre = paygridvalues.filter(function (e) {
      return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
    }); //to filter the only the required agrrements which is in adjustDueDateSelectedAgre array

    resultSelecteAgre = resultSelecteAgre.filter((e : any) => !e?.isExchanged);

    checkedAgr=[...resultSelecteAgre]
    setcheckedAgr(resultSelecteAgre);
    const onlyEPPSelected = resultSelecteAgre.every(
      (el) => el.agreementRateDetails?.employeePurchasePlan === "Y"
    );
    setDisableEPOByEPP(onlyEPPSelected);
    if (resultSelecteAgre.length == 0) {
      setadjustButton(false);
      seteditButton(false);
      setadjustChargeoffFlag(false);
      setadjustRisbtnFlag(false); //Rental Item Sale
      setRisRemovepaymentdisableFlag(true); //Rental Item Sale
    } //if the all the checkboxes are unchecked the array will be empty then the adjust due date button should not be shown

    let count;
    let editCount;
    let isZeroDollarCount = 0;
    let carryRentCount = 0;
    let adjustall = 0;
    let editall = 0;
    let carrycount = 0;
    let carryallowedCount = 0;
    let promoCount = 0;
    let onlyCashsaleCheckedCount = 0;
    let diffStoreCount = 0;
    let lateAmount = 0;
    if (resultSelecteAgre?.length >= 1) {
      let deliveryUnconfirmed: any = [];
      let carryUsed = 0;
      let carryLateUsed = 0;
      let carryMessage = "";
      let suspenseCount = 0;
      for (let index = 0; index < resultSelecteAgre.length; index++) {
        /**
         * changes:3 Payment verification
         * Developer: Muzzammil
         * PsuedoCode: PC_1.2
         * Description: need to check if an agreement has delivery status 0 and and made more than three payments
         *
         */

        {
          /**PS_Cccb_No_12 if the selected agreement contains the agreementtype as Credit Card Charge Back then it 
          shows the Adjust CCCB button */
        }
        let cccbPay = resultSelecteAgre.filter(
          (x) =>
            resultSelecteAgre[index].Agreementtype == "Credit Card Charge Back"
        );
        if (cccbPay.length > 0 && resultSelecteAgre?.length == 1) {
          setCccbDisable(true);
        } else {
          setCccbDisable(false);
        }

        let NSFpay = resultSelecteAgre.filter(
          (x) => resultSelecteAgre[index].Agreementtype == "NSF Check"
        );
        if (NSFpay.length > 0 && resultSelecteAgre?.length == 1) {
          setNSFpaymentAdjust(true);
        } else {
          setNSFpaymentAdjust(false);
        }
        lateAmount =
          Number(lateAmount) + Number(resultSelecteAgre[index].lateFee);
        if (
          String(resultSelecteAgre[index].store_number) != String(storeNumber)
        ) {
          diffStoreCount = diffStoreCount + 1;
        }
        if (Number(resultSelecteAgre[index].miscellaneousItemsNum) > 0) {
          onlyCashsaleCheckedCount++;
        }
        if (
          resultSelecteAgre[index].Agreementtype != "Club" &&
          resultSelecteAgre.length == 1
        ) {
          let agr = resultSelecteAgre;
          if (Number(agr[0].actualEpoAmount) == 0) {
            let actepoamount =
              agr[0] !== undefined &&
              agr[0] !== null &&
              agr[0] !== "" &&
              agr[0].EPOAmount !== undefined &&
              agr[0].EPOAmount !== null &&
              agr[0].EPOAmount !== ""
                ? Number(agr[0].EPOAmount) -
                  Number(agr[0].EPOTax) -
                  Number(
                    agr[0].pastDueRent !== undefined &&
                      agr[0].pastDueRent !== null &&
                      agr[0].pastDueRent !== ""
                      ? agr[0].pastDueRent
                      : 0.0
                  ) -
                  Number(
                    agr[0].lateFee !== undefined &&
                      agr[0].lateFee !== null &&
                      agr[0].lateFee !== ""
                      ? agr[0].lateFee
                      : 0.0
                  ) +
                  Number(agr[0].carriedLateFee)
                : "";
            agr[0].actualEpoAmount = Number(Number(actepoamount).toFixed(2));
          }
          setepoPopup({
            taxRate: Number(agr[0].taxRate),
            epoamount:
              agr[0] !== undefined &&
              agr[0] !== null &&
              agr[0] !== "" &&
              agr[0].EPOAmount !== undefined &&
              agr[0].EPOAmount !== null &&
              agr[0].EPOAmount !== ""
                ? Number(agr[0].EPOAmount) -
                  Number(agr[0].EPOTax)-
                  Number(
                    agr[0].pastDueRent !== undefined &&
                      agr[0].pastDueRent !== null &&
                      agr[0].pastDueRent !== ""
                      ? agr[0].pastDueRent
                      : 0.0
                  ) -
                  Number(
                    agr[0].lateFee !== undefined &&
                      agr[0].lateFee !== null &&
                      agr[0].lateFee !== ""
                      ? agr[0].lateFee
                      : 0.0
                  ) +
                  Number(agr[0].carriedLateFee)
                : "",
            lateFee:
              agr[0] !== undefined &&
              agr[0] !== null &&
              agr[0] !== "" &&
              agr[0].lateFee !== undefined &&
              agr[0].lateFee !== null &&
              agr[0].lateFee !== ""
                ? Number(agr[0].lateFee) + Number(agr[0].carriedLateFee)
                : "",
            suspense:
              agr[0] !== undefined &&
              agr[0] !== null &&
              agr[0] !== "" &&
              agr[0].SuspenseAmount !== undefined &&
              agr[0].SuspenseAmount !== null &&
              agr[0].SuspenseAmount !== ""
                ? agr[0].SuspenseAmount
                : "",
            taxAmount:
              agr[0] !== undefined &&
              agr[0] !== null &&
              agr[0] !== "" &&
              agr[0].EPOTax !== undefined &&
              agr[0].EPOTax !== null &&
              agr[0].EPOTax !== ""
                ? agr[0].EPOTax
                : "",
            amountDue:
              agr[0] !== undefined &&
              agr[0] !== null &&
              agr[0] !== "" &&
              agr[0].pastDueRent !== undefined &&
              agr[0].pastDueRent !== null &&
              agr[0].pastDueRent !== ""
                ? agr[0].pastDueRent
                : "",
            currentDaysLate:
              agr[0] !== undefined &&
              agr[0] !== null &&
              agr[0] !== "" &&
              agr[0].CurrentDaysLate !== undefined &&
              agr[0].CurrentDaysLate !== null &&
              agr[0].CurrentDaysLate !== ""
                ? agr[0].CurrentDaysLate
                : "",
          });
        } else if(resultSelecteAgre[index].Agreementtype != "Club" &&
        resultSelecteAgre.length > 0) {
          // for(let i=0;i<resultSelecteAgre.length;i++){
            if (Number(resultSelecteAgre[index]?.actualEpoAmount) == 0) {
              let actepoamount =
              resultSelecteAgre[index] !== undefined &&
              resultSelecteAgre[index] !== null &&
              resultSelecteAgre[index] !== "" &&
              resultSelecteAgre[index]?.EPOAmount !== undefined &&
              resultSelecteAgre[index]?.EPOAmount !== null &&
              resultSelecteAgre[index]?.EPOAmount !== ""
                  ? Number(resultSelecteAgre[index]?.EPOAmount) -
                    Number(resultSelecteAgre[index]?.EPOTax) -
                    Number(
                      resultSelecteAgre[index]?.pastDueRent !== undefined &&
                      resultSelecteAgre[index]?.pastDueRent !== null &&
                      resultSelecteAgre[index]?.pastDueRent !== ""
                        ? resultSelecteAgre[index]?.pastDueRent
                        : 0.0
                    ) -
                    Number(
                      resultSelecteAgre[index]?.lateFee !== undefined &&
                      resultSelecteAgre[index]?.lateFee !== null &&
                      resultSelecteAgre[index]?.lateFee !== ""
                        ? resultSelecteAgre[index]?.lateFee
                        : 0.0
                    ) +
                    Number(resultSelecteAgre[index]?.carriedLateFee)
                  : "";
                  resultSelecteAgre[index].actualEpoAmount = Number(Number(actepoamount).toFixed(2));
            }

          // }
          setepoPopup(undefined);
        } else {
          setepoPopup(undefined);
        }
        if (
          Number(resultSelecteAgre[index].number_of_paymentsmade) >= 3 &&
          resultSelecteAgre[index].confirmeddeliversystatus == false
        ) {
          deliveryUnconfirmed.push(resultSelecteAgre[index].AgreementNumber);
          setdeliveryUnconfiremd(true);
        }
        if (Number(resultSelecteAgre[index].isSwitchoutDeliveryPending) === 1) {
          deliveryUnconfirmed.push(
            resultSelecteAgre[index].AgreementNumber + "(Switchout)"
          );
          setdeliveryUnconfiremd(true);
        } // Newly Added
        setagreementDeliveryUncomfirmed(deliveryUnconfirmed);
        //This condition used for enable and disable adjust due date button at store level and if it is club then any store it can be adjustable
        if (
          String(storeNumber) ==
            String(resultSelecteAgre[index].store_number) ||
          (selectedCheckBoxCount <= 2 &&
            resultSelecteAgre[index].Agreementtype == "Club")
        ) {
          setdisableAdjustDueDateButton(false);
        } else {
          setdisableAdjustDueDateButton(true);
        }
        if (
          resultSelecteAgre[index].Nextduedate == "SAC" ||
          resultSelecteAgre[index].Nextduedate == "PIF" ||
          resultSelecteAgre[index].Nextduedate == "EPO" ||
          resultSelecteAgre[index].Agreementtype == "Club" ||
          resultSelecteAgre[index].number_of_paymentsmade == 0 ||
          resultSelecteAgre[index].FreeTime !== null ||
          resultSelecteAgre[index].isZeroPayment == true
        ) {
          carryallowedCount++;
          if (resultSelecteAgre[index].Agreementtype == "Club") {
            setcarryMessage("Club amount cannot be carried");
            carryMessage = "Club amount cannot be carried";
          } else {
            if (carryMessage == "") {
              setcarryMessage(
                "Selected agreement is in intital or final payment"
              );
            }
          }
          carrycount = 1;
        } else {
          carryRentCount++;
        }
        if (Number(resultSelecteAgre[index].carryRentAmountUsed) > 0) {
          carryUsed = Number(
            (
              Number(carryUsed) +
              Number(resultSelecteAgre[index].carryRentAmountUsed)
            ).toFixed(2)
          );
          setagreecarryUsed(carryUsed);
        }
        if (Number(resultSelecteAgre[index].carriedLate) > 0) {
          carryLateUsed = Number(
            (
              Number(carryLateUsed) +
              Number(resultSelecteAgre[index].carriedLate)
            ).toFixed(2)
          );
          setagreecarryLateUsed(carryLateUsed);
        }

        //carry rent Check Ends
        //For multiple selected agrrements needs to check following conditions for adjust due date
        if (
          resultSelecteAgre[index].Schedule != resultSelecteAgre[0].Schedule ||
          resultSelecteAgre[index].CurrentDueDate !=
            resultSelecteAgre[0].CurrentDueDate ||
          resultSelecteAgre[index].isZeroPayment == true ||
          resultSelecteAgre[index].Nextduedate == "SAC" ||
          resultSelecteAgre[index].Nextduedate == "PIF" ||
          resultSelecteAgre[index].Nextduedate == "EPO" ||
          resultSelecteAgre[index].Agreementtype == "Promo" ||
          resultSelecteAgre[index].isReturnAgrFlag == 1 ||
          // resultSelecteAgre[index].Agreementtype == "Note Payment" ||
          resultSelecteAgre[index].Agreementtype == "PIF Note" ||
          (resultSelecteAgre[index].number_of_paymentsmade == 0 &&
            resultSelecteAgre[index].Agreementtype != "Club") ||
          String(resultSelecteAgre[index].store_number) !=
            String(storeNumber) ||
          resultSelecteAgre[index].FreeTime !== null // Added By Me
        ) {
          count = 1;
          setadjustButton(false);
        } else {
          adjustall++;
        }
        if (
          resultSelecteAgre[index].Nextduedate == "SAC" ||
          resultSelecteAgre[index].Nextduedate == "PIF" ||
          resultSelecteAgre[index].Nextduedate == "EPO" ||
          resultSelecteAgre[index].isZeroPayment == true ||
          resultSelecteAgre[index].Agreementtype == "Note Payment" ||
          resultSelecteAgre[index].isReturnAgrFlag == 1 ||
          resultSelecteAgre[index].PromoFreeDays == 0 ||
          (resultSelecteAgre[index].number_of_paymentsmade == 0 &&
            resultSelecteAgre[index].Agreementtype != "Club") ||
          String(resultSelecteAgre[index].store_number) != String(storeNumber)
        ) {
          promoCount = 1;
          setpromoFreeTimeButton(true);
        }
        if (
          resultSelecteAgre[index].Nextduedate == "SAC" ||
          resultSelecteAgre[index].Nextduedate == "PIF" ||
          resultSelecteAgre[index].Nextduedate == "EPO" ||
          resultSelecteAgre[index].number_of_paymentsmade == 0 ||
          resultSelecteAgre[index].Agreementtype == "Club" ||
          resultSelecteAgre[index].Agreementtype == "Promo" ||
          String(resultSelecteAgre[index].store_number) != String(storeNumber)
        ) {
          suspenseCount = 1;
          setsuspenseButton(true);
        }
        if (
          resultSelecteAgre[index].Schedule != resultSelecteAgre[0].Schedule ||
          resultSelecteAgre[index].Nextduedate == "SAC" ||
          resultSelecteAgre[index].Nextduedate == "PIF" ||
          resultSelecteAgre[index].Nextduedate == "EPO" ||
          resultSelecteAgre[index].isZeroPayment == true ||
          // resultSelecteAgre[index].Agreementtype == "Note Payment" ||
          resultSelecteAgre[index].Agreementtype == "PIF Note" ||
          resultSelecteAgre[index].Agreementtype == "Promo" ||
          resultSelecteAgre[index].isReturnAgrFlag == 1 ||
          resultSelecteAgre[index].number_of_paymentsmade == 0 ||
          String(resultSelecteAgre[index].store_number) !=
            String(storeNumber) ||
          resultSelecteAgre[index].FreeTime !== null // Added By Me
        ) {
          editCount = 1;
          seteditButton(false);
        } else {
          editall++;
        }
        if (
          resultSelecteAgre[index].isReturnAgrFlag == 1 ||
          String(resultSelecteAgre[index].store_number) != String(storeNumber) // Added By Me
        ) {
          isZeroDollarCount = 1;
          setIsZeroDollarPayment(true);
        } else {
          isZeroDollarCount++;
        }
      }

      if (resultSelecteAgre.length == 0 || carryUsed == 0) {
        setagreecarryUsed(0.0);
      } else {
        setagreecarryUsed(carryUsed);
      }
      if (resultSelecteAgre.length == 0 || carryLateUsed == 0) {
        setagreecarryLateUsed(0.0);
      } else {
        setagreecarryLateUsed(carryLateUsed);
      }
      if (carryallowedCount == resultSelecteAgre.length) {
        setcarryRentCheck(true);
      }
      if (deliveryUnconfirmed.length == 0) {
        setdeliveryUnconfiremd(false);
      }
      if (carrycount == 0) {
        setcarryMessage("");
      }
      if (suspenseCount == 0) {
        setsuspenseButton(false);
      }
      if (promoCount == 0) {
        setpromoFreeTimeButton(true);
      }

      const suspenseEpoChanges = paygridvalues.filter(function (e) {
        return e.Agreementtype != "Club";
      });
      if (suspenseEpoChanges?.length > 0) {
        if (
          onlyCashsaleCheckedCount == resultSelecteAgre.length ||
          (suspenseEpoChanges?.length == 1 &&
            (suspenseEpoChanges[0]?.Nextduedate == "SAC" ||
              suspenseEpoChanges[0]?.Nextduedate == "EPO" ||
              suspenseEpoChanges[0]?.Nextduedate == "PIF"))
        ) {
          setselectedOnlyCashSale(true);
        } else {
          setselectedOnlyCashSale(false);
        }

        if (
          onlyCashsaleCheckedCount == resultSelecteAgre.length
        ) {
          setAddMoneyOrderDisable(true);
        } else {
          setAddMoneyOrderDisable(false);
        }
      }

      if (count != 1) {
        if (adjustall > 1) {
          setadjustAllButton(true);
        } else {
          setadjustAllButton(false);
        }
        setadjustButton(true);
      }
      if (editCount != 1) {
        seteditButton(true);
      }

      //
    }
    if (isZeroDollarCount == 0) {
      setIsZeroDollarPayment(false);
    }
    let totalsusamount = 0;
    let SuspenseAmountFilter = resultSelecteAgre.filter(
      (x) =>
        x.Agreementtype != "Club" &&
        x.Nextduedate != "PIF" &&
        x.Nextduedate != "SAC" &&
        x.Nextduedate != "EPO" &&
        x.number_of_paymentsmade > 0 &&
        String(x.store_number) ==
          String(window.sessionStorage.getItem("storeNumber"))
    );
    for (let i = 0; i < SuspenseAmountFilter.length; i++) {
      totalsusamount =
        Number(totalsusamount) + Number(SuspenseAmountFilter[i].SuspenseAmount);
    }
 
    let insertArray: any = [];
    for (let i = 0; i < resultSelecteAgre.length; i++) {
     // Revenue recog suspense sub handle
      if(isRevenueRecogActivated&&diffStoreCount!=0&&Number(resultSelecteAgre[i].suspenseSub)>0){
        resultSelecteAgre[i].suspenseUseRevRecog=resultSelecteAgre[i].suspenseSub
        resultSelecteAgre[i].suspenseSub=0
      }else if(isRevenueRecogActivated&&diffStoreCount==0&&Number(resultSelecteAgre[i].suspenseUseRevRecog)>0){
        resultSelecteAgre[i].suspenseSub=resultSelecteAgre[i].suspenseUseRevRecog
        resultSelecteAgre[i].suspenseUseRevRecog=0
      } 
    //
      if (Number(resultSelecteAgre[i].suspenseSub) > 0) {
        if (
          resultSelecteAgre[i].Agreementtype != "Club" &&
          ((resultSelecteAgre[i].Nextduedate != "PIF" &&
            resultSelecteAgre[i].Nextduedate != "SAC" &&
            resultSelecteAgre[i].Nextduedate != "EPO") ||
            resultSelecteAgre[i].isFinalTransferSusUseApplied) &&
          resultSelecteAgre[i].number_of_paymentsmade > 0 &&
          String(resultSelecteAgre[i].store_number) ==
            String(window.sessionStorage.getItem("storeNumber"))
        ) {
          insertArray.push({
            sustype:
              Number(resultSelecteAgre[i].suspenseSub) > 0 ? "Used" : "type",
            agreementnumber: resultSelecteAgre[i].Agreementid,
            agreementid: resultSelecteAgre[i].AgreementNumber,
            schedure: resultSelecteAgre[i].Schedule,
            paymentamount: resultSelecteAgre[i].Amount,
            epoamount: resultSelecteAgre[i].EPOAmount,
            suspenseamount: resultSelecteAgre[i].SuspenseAmount,
            newsuspense: Number(resultSelecteAgre[i].suspenseSub),
            agreementType: resultSelecteAgre[i].Agreementtype,
            transferedCOA: false,
            isFinalTransferSusUseCancel:
              resultSelecteAgre[i].isFinalTransferSusUseCancel,
          });
        } else if (
          (resultSelecteAgre[i].Nextduedate == "PIF" ||
            resultSelecteAgre[i].Nextduedate == "SAC" ||
            resultSelecteAgre[i].Nextduedate == "EPO") &&
          resultSelecteAgre[i].number_of_paymentsmade > 0 &&
          !resultSelecteAgre[i].isFinalTransferSusUseApplied &&
          String(resultSelecteAgre[i].store_number) ==
            String(window.sessionStorage.getItem("storeNumber"))
        ) {
          insertArray.push({
            sustype:
              Number(resultSelecteAgre[i].suspenseSub) > 0
                ? "FinalUsed"
                : "type",
            agreementnumber: resultSelecteAgre[i].Agreementid,
            agreementid: resultSelecteAgre[i].AgreementNumber,
            schedure: resultSelecteAgre[i].Schedule,
            paymentamount: resultSelecteAgre[i].Amount,
            epoamount: resultSelecteAgre[i].EPOAmount,
            suspenseamount: resultSelecteAgre[i].SuspenseAmount,
            newsuspense: Number(resultSelecteAgre[i].suspenseSub),
            agreementType: resultSelecteAgre[i].Agreementtype,
            transferedCOA: false,
          });
        }
      }
      if (Number(resultSelecteAgre[i].suspenseAdd) > 0) {
        if (
          resultSelecteAgre[i].Agreementtype != "Club" &&
          resultSelecteAgre[i].Nextduedate != "PIF" &&
          resultSelecteAgre[i].Nextduedate != "SAC" &&
          resultSelecteAgre[i].Nextduedate != "EPO" &&
          resultSelecteAgre[i].number_of_paymentsmade > 0 &&
          String(resultSelecteAgre[i].store_number) ==
            String(window.sessionStorage.getItem("storeNumber"))
        ) {
          insertArray.push({
            sustype:
              Number(resultSelecteAgre[i].suspenseAdd) > 0 ? "Add" : "type",
            agreementnumber: resultSelecteAgre[i].Agreementid,
            agreementid: resultSelecteAgre[i].AgreementNumber,
            schedure: resultSelecteAgre[i].Schedule,
            paymentamount: resultSelecteAgre[i].Amount,
            epoamount: resultSelecteAgre[i].EPOAmount,
            suspenseamount: resultSelecteAgre[i].SuspenseAmount,
            newsuspense: Number(resultSelecteAgre[i].suspenseAdd),
            agreementType: resultSelecteAgre[i].Agreementtype,
            transferedCOA: false,
          });
        }
      }
    }
    // debugger;
    if (resultSelecteAgre.length > 0) {
      for (let i = 0; i < paygridvalues.length; i++) {
        if (
          Number(paygridvalues[i].transferOldSuspense) -
            Number(paygridvalues[i].SuspenseAmount) <
          0
        ) {
          if (
            paygridvalues[i].Agreementtype != "Club" &&
            paygridvalues[i].Nextduedate != "PIF" &&
            paygridvalues[i].Nextduedate != "SAC" &&
            paygridvalues[i].Nextduedate != "EPO" &&
            paygridvalues[i].number_of_paymentsmade > 0 &&
            String(paygridvalues[i].store_number) ==
              String(window.sessionStorage.getItem("storeNumber"))
          ) {
            insertArray.push({
              sustype: "TransferAdd",
              agreementnumber: paygridvalues[i].Agreementid,
              agreementid: paygridvalues[i].AgreementNumber,
              schedure: paygridvalues[i].Schedule,
              paymentamount: paygridvalues[i].Amount,
              epoamount: paygridvalues[i].EPOAmount,
              suspenseamount: paygridvalues[i].SuspenseAmount,
              newsuspense:
                Number(paygridvalues[i].SuspenseAmount) -
                Number(paygridvalues[i].transferOldSuspense),
              agreementType: paygridvalues[i].Agreementtype,
              transferedCOA: false,
            });
          }
        }
        if (
          Number(paygridvalues[i].transferOldSuspense) -
            Number(paygridvalues[i].SuspenseAmount) >
          0
        ) {
          if (
            paygridvalues[i].Agreementtype != "Club" &&
            paygridvalues[i].Nextduedate != "PIF" &&
            paygridvalues[i].Nextduedate != "SAC" &&
            paygridvalues[i].Nextduedate != "EPO" &&
            paygridvalues[i].number_of_paymentsmade > 0 &&
            String(paygridvalues[i].store_number) ==
              String(window.sessionStorage.getItem("storeNumber"))
          ) {
            insertArray.push({
              sustype: "TransferUsed",
              agreementnumber: paygridvalues[i].Agreementid,
              agreementid: paygridvalues[i].AgreementNumber,
              schedure: paygridvalues[i].Schedule,
              paymentamount: paygridvalues[i].Amount,
              epoamount: paygridvalues[i].EPOAmount,
              suspenseamount: Number(paygridvalues[i].SuspenseAmount),
              newsuspense:
                Number(paygridvalues[i].transferOldSuspense) -
                Number(paygridvalues[i].SuspenseAmount),
              agreementType: paygridvalues[i].Agreementtype,
              transferedCOA: paygridvalues[i].isCOATransfered,
            });
          } else if (
            (paygridvalues[i].Nextduedate == "PIF" ||
              paygridvalues[i].Nextduedate == "SAC" ||
              paygridvalues[i].Nextduedate == "EPO") &&
            paygridvalues[i].number_of_paymentsmade > 0 &&
            String(paygridvalues[i].store_number) ==
              String(window.sessionStorage.getItem("storeNumber"))
          ) {
            insertArray.push({
              sustype: "FinalTransferUsed",
              agreementnumber: paygridvalues[i].Agreementid,
              agreementid: paygridvalues[i].AgreementNumber,
              schedure: paygridvalues[i].Schedule,
              paymentamount: paygridvalues[i].Amount,
              epoamount: paygridvalues[i].EPOAmount,
              suspenseamount: Number(paygridvalues[i].SuspenseAmount),
              newsuspense:
                Number(paygridvalues[i].transferOldSuspense) -
                Number(paygridvalues[i].SuspenseAmount),
              agreementType: paygridvalues[i].Agreementtype,
              transferedCOA: paygridvalues[i].isCOATransfered,
            });
          }
        }
      }
    }

    setLatelast10value(lateAmount);
    totalsuspensemaster = insertArray;
    settotalsuspensemaster(insertArray);
    let susAddCount = 0;
    let susUseCount = 0;
    for (let i = 0; i < insertArray.length; i++) {
      if (insertArray[i].sustype == "Add") {
        susAddCount == 1;
        setcheckdisable(true);
        setinstoreDisable(true);
        if (paymentOrigin != "Store") {
          phone({ target: { value: "Store" } });
        }
      }
      // if(insertArray[i].sustype=="Used"){
      //   susUseCount++
      // }
      // if(susAddCount>1||susUseCount>1){
      //   if(paymentOrigin!="Store"){
      //     phone({target:{value:"Store"}})
      //   }
      //   setinstoreDisable(true)
      // }
    }
    if (insertArray.length == 0) {
      setcheckdisable(false);
      setinstoreDisable(false);
    }
    settotalsuspensemaster(insertArray);

    if (resultSelecteAgre.length == 1) {
      if (
        resultSelecteAgre[0].Agreementtype != "Club" &&
        resultSelecteAgre[0].number_of_paymentsmade != 0 &&
        resultSelecteAgre[0].FreeTime == null &&
        resultSelecteAgre[0].isReturnAgrFlag != 1
      ) {
        setshowEPOGridBtn(true);
      } else {
        setshowEPOGridBtn(false);
      }
    } else {
      setshowEPOGridBtn(false);
    }
    //charge-off
    if (resultSelecteAgre?.length > 1) {
      for (let i = 0; i < resultSelecteAgre.length; i++) {
        if (
          resultSelecteAgre[i].Agreementtype == "Restitution" &&
          resultSelecteAgre[i].Nextduedate == "PIF" &&
          adjustChargeoffFlag == false
        ) {
          checkBoxCountchargeoffFlag = true;
          setadjustChargeoffFlag(true);
          setchargeoffadjustFlag(false);
        } else if (
          resultSelecteAgre[i].Agreementtype == "Restitution" &&
          resultSelecteAgre[i].Nextduedate == "PIF" &&
          adjustChargeoffFlag == true
        ) {
          checkBoxCountchargeoffFlag = true;
          // setadjustChargeoffFlag(true)
          setchargeoffadjustFlag(false);
        } else if (
          resultSelecteAgre[i].Agreementtype != "Restitution" &&
          resultSelecteAgre[i].Nextduedate != "PIF" &&
          adjustChargeoffFlag == true &&
          checkBoxCountchargeoffFlag == false
        ) {
          // setadjustChargeoffFlag(false)
          setchargeoffadjustFlag(true);
        }
      }
    }
    if (resultSelecteAgre.length == 1) {
      for (let i = 0; i < resultSelecteAgre.length; i++) {
        if (resultSelecteAgre[i].Agreementtype == "Restitution") {
          setadjustChargeoffFlag(true);
          setchargeoffadjustFlag(false);
        } else {
          setchargeoffadjustFlag(false);
          setadjustChargeoffFlag(false);
        }
      }
    }
    //Rental Item Sale
    const item = resultSelecteAgre.filter(
      (val) => val.Agreementtype == "Rental Item Sale"
    );
    if (
      resultSelecteAgre.length == 1 &&
      resultSelecteAgre[0].Agreementtype == "Rental Item Sale"
    ) {
      setRisRemovepaymentbtnFlag(true);
      setadjustRisbtnFlag(true);
      // setRisRemovepaymentbtnFlag(true)
      setRisRemovepaymentdisableFlag(false);
    } else if (resultSelecteAgre.length > 1 && item.length != 0) {
      setRisRemovepaymentbtnFlag(true);
      setRisRemovepaymentdisableFlag(true);
      setadjustRisbtnFlag(false);
    }
    if (diffStoreCount == 0) {
      diffStoreErrLbl = false;
      if(isRevenueRecogActivated){
        setmobtn(false);
        setcashbtn(false);
        setcreditDisable(false);
        setchangeRadio(true);
        setCreditRadio(false);
        setradioBtn("0");
      }
      // setdiffStoreErrLbl(false);
    } else {
      diffStoreErrLbl = true;
        if(isRevenueRecogActivated){
        let invokeResetPayment = false;
        const constantStoreNumber = resultSelecteAgre[0].store_number;
        const isOnlySameStoreSelected = resultSelecteAgre.every(
          (x) => x.store_number === constantStoreNumber
        );
        // if (carryRentWidget) {
        //   carryOnchange();
        // }
        setcarryRentWidget(false);
        if (resultSelecteAgre.length > 1 && isOnlySameStoreSelected) {
          invokeResetPayment = false;
        } else {
          invokeResetPayment = true;
        }
        if (invokeResetPayment) {
          getpaid("STD", true);
          setRestrictBindRemaining(true);
          resetPayment(undefined);
          resetInvoked = true;
        }
        setswipeDisable(true);
        // setdiffStoreErrLbl(true);
        setcreditDisable(true);
        setCreditRadio(true);
        setchangeRadio(false);
        setcashselected(1);
        setradioBtn("1");
        setmobtn(true);
        setcashbtn(true);
        setCardrequire({
          ...Cardrequire,
          drpdown: false,
          CardCss: `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`,
        });
        setcashtabCss(`${navlinkClass.navLink} ${classes.p3}`);
        setcheckMoneyOrdertabCss(`${navlinkClass.navLink} ${classes.p3}`);

      }
      // setdiffStoreErrLbl(true);
    }
    if(enableCashPayment){ 
      setcashselected(1);
      setCardrequire({
        ...Cardrequire,
        drpdown: false,
        CardCss: `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`,
      });
      setcashtabCss(`${navlinkClass.navLink} ${classes.p3}`);
      setcardtabCss(`${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`);
    }
  };

  // const multipleAdjustRevenue = () => {
  //   acceptPaymentParam;
  //   AcceptclubPaymentParam;
  //   adjustDueDateSelectedAgre = Array.from(new Set(adjustDueDateSelectedAgre)); //removing duplicate agreementids
  //   let storeNumber = String(window.sessionStorage.getItem("storeNumber")); //to get the storeNumber
  //   const clubStatus: any = paygridvalues.filter(
  //     (el: any) => el?.Agreementtype == "Club"
  //   );
  //   if (clubStatus.length > 0) {
  //     setdeactivateClubAmount(clubStatus[0].Amount);
  //   }
  //   if (clubCheckDisabled == false) {
  //     if (
  //       clubStatus.length > 0 &&
  //       !clubStatus[0].originAgreementId &&
  //       !clubStatus[0].clubDocumentSignStatus
  //     ) {
  //       setclubBtnStatus("A");
  //       setCustOrder("A");
  //     }
  //     if (clubStatus.length > 0) {
  //       if (clubStatus[0].number_of_paymentsmade > 0) {
  //         setclubStatus("D");
  //       } else if (clubStatus[0].number_of_paymentsmade == 0) {
  //         setclubStatus("P");
  //       }
  //       if (
  //         clubStatus[0]?.store_number == sessionStorage.getItem("storeNumber")
  //       ) {
  //         setisclubOnSameStore(false);
  //       }
  //     } else {
  //       setclubStatus("A");
  //       setisclubOnSameStore(false);
  //     }
  //   }

  //   const resultSelecteAgre = paygridvalues.filter(function (e) {
  //     return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
  //   }); //to filter the only the required agrrements which is in adjustDueDateSelectedAgre array
  //   setcheckedAgr(resultSelecteAgre);
  //   const onlyEPPSelected = resultSelecteAgre.every(
  //     (el) => el.agreementRateDetails?.employeePurchasePlan === "Y"
  //   );
  //   setDisableEPOByEPP(onlyEPPSelected);
  //   if (resultSelecteAgre.length == 0) {
  //     setadjustButton(false);
  //     seteditButton(false);
  //     setadjustChargeoffFlag(false);
  //     setadjustRisbtnFlag(false); //Rental Item Sale
  //     setRisRemovepaymentdisableFlag(true); //Rental Item Sale
  //   } //if the all the checkboxes are unchecked the array will be empty then the adjust due date button should not be shown

  //   let count;
  //   let editCount;
  //   let isZeroDollarCount = 0;
  //   let carryRentCount = 0;
  //   let adjustall = 0;
  //   let editall = 0;
  //   let carrycount = 0;
  //   let carryallowedCount = 0;
  //   let promoCount = 0;
  //   let onlyCashsaleCheckedCount = 0;
  //   let diffStoreCount = 0;
  //   let lateAmount = 0;
  //   if (resultSelecteAgre?.length >= 1) {
  //     let deliveryUnconfirmed: any = [];
  //     let carryUsed = 0;
  //     let carryMessage = "";
  //     let carryLateUsed = 0;
  //     let suspenseCount = 0;
  //     for (let index = 0; index < resultSelecteAgre.length; index++) {
  //       /**
  //        * changes:3 Payment verification
  //        * Developer: Muzzammil
  //        * PsuedoCode: PC_1.2
  //        * Description: need to check if an agreement has delivery status 0 and and made more than three payments
  //        *
  //        */
  //       lateAmount =
  //         Number(lateAmount) + Number(resultSelecteAgre[index].lateFee);
  //       if (
  //         String(resultSelecteAgre[index].store_number) != String(storeNumber)
  //       ) {
  //         diffStoreCount = diffStoreCount + 1;
  //       }
  //       if (Number(resultSelecteAgre[index].miscellaneousItemsNum) > 0) {
  //         onlyCashsaleCheckedCount++;
  //       }
  //       if (
  //         resultSelecteAgre[index].Agreementtype != "Club" &&
  //         resultSelecteAgre.length == 1
  //       ) {
  //         let agr = resultSelecteAgre;
  //         if (Number(agr[0].actualEpoAmount) == 0) {
  //           let actepoamount =
  //             agr[0] !== undefined &&
  //             agr[0] !== null &&
  //             agr[0] !== "" &&
  //             agr[0].EPOAmount !== undefined &&
  //             agr[0].EPOAmount !== null &&
  //             agr[0].EPOAmount !== ""
  //               ? Number(agr[0].EPOAmount) -
  //                 Number(agr[0].EPOTax) +
  //                 (Number(agr[0].acceptEpoFlag) == 1
  //                   ? Number(agr[0].suspenseSub)
  //                   : 0.0) -
  //                 Number(
  //                   agr[0].pastDueRent !== undefined &&
  //                     agr[0].pastDueRent !== null &&
  //                     agr[0].pastDueRent !== ""
  //                     ? agr[0].pastDueRent
  //                     : 0.0
  //                 ) -
  //                 Number(
  //                   agr[0].lateFee !== undefined &&
  //                     agr[0].lateFee !== null &&
  //                     agr[0].lateFee !== ""
  //                     ? agr[0].lateFee
  //                     : 0.0
  //                 )
  //               : "";
  //           agr[0].actualEpoAmount = actepoamount;
  //         }
  //         setepoPopup({
  //           taxRate: Number(agr[0].taxRate),
  //           epoamount:
  //             agr[0] !== undefined &&
  //             agr[0] !== null &&
  //             agr[0] !== "" &&
  //             agr[0].EPOAmount !== undefined &&
  //             agr[0].EPOAmount !== null &&
  //             agr[0].EPOAmount !== ""
  //               ? Number(agr[0].EPOAmount) -
  //                 Number(agr[0].EPOTax) +
  //                 Number(agr[0].agreementNoteDetails?.editedNoteAmount)!==NaN&&
  //                 agr[0].agreementNoteDetails?.editedNoteAmount !== undefined &&
  //                     agr[0].agreementNoteDetails?.editedNoteAmount !== null &&
  //                     agr[0].agreementNoteDetails?.editedNoteAmount !== ""
  //                     ? Number(agr[0].agreementNoteDetails?.editedNoteAmount)
  //                     : 0.0 +
  //                 (Number(agr[0].acceptEpoFlag) == 1
  //                   ? Number(agr[0].suspenseSub)
  //                   : 0.0) -
  //                 Number(
  //                   agr[0].pastDueRent !== undefined &&
  //                     agr[0].pastDueRent !== null &&
  //                     agr[0].pastDueRent !== ""
  //                     ? agr[0].pastDueRent
  //                     : 0.0
  //                 ) -
  //                 Number(
  //                   agr[0].lateFee !== undefined &&
  //                     agr[0].lateFee !== null &&
  //                     agr[0].lateFee !== ""
  //                     ? agr[0].lateFee
  //                     : 0.0
  //                 )
  //               : "",
  //           lateFee:
  //             agr[0] !== undefined &&
  //             agr[0] !== null &&
  //             agr[0] !== "" &&
  //             agr[0].lateFee !== undefined &&
  //             agr[0].lateFee !== null &&
  //             agr[0].lateFee !== ""
  //               ? agr[0].lateFee
  //               : "",
  //           suspense:
  //             agr[0] !== undefined &&
  //             agr[0] !== null &&
  //             agr[0] !== "" &&
  //             agr[0].SuspenseAmount !== undefined &&
  //             agr[0].SuspenseAmount !== null &&
  //             agr[0].SuspenseAmount !== ""
  //               ? agr[0].SuspenseAmount
  //               : "",
  //           taxAmount:
  //             agr[0] !== undefined &&
  //             agr[0] !== null &&
  //             agr[0] !== "" &&
  //             agr[0].EPOTax !== undefined &&
  //             agr[0].EPOTax !== null &&
  //             agr[0].EPOTax !== ""
  //               ? agr[0].EPOTax
  //               : "",
  //           amountDue:
  //             agr[0] !== undefined &&
  //             agr[0] !== null &&
  //             agr[0] !== "" &&
  //             agr[0].pastDueRent !== undefined &&
  //             agr[0].pastDueRent !== null &&
  //             agr[0].pastDueRent !== ""
  //               ? agr[0].pastDueRent
  //               : "",
  //           currentDaysLate:
  //             agr[0] !== undefined &&
  //             agr[0] !== null &&
  //             agr[0] !== "" &&
  //             agr[0].CurrentDaysLate !== undefined &&
  //             agr[0].CurrentDaysLate !== null &&
  //             agr[0].CurrentDaysLate !== ""
  //               ? agr[0].CurrentDaysLate
  //               : "",
  //         });
  //       } else {
  //         setepoPopup(undefined);
  //       }
  //       if (
  //         Number(resultSelecteAgre[index].number_of_paymentsmade) >= 3 &&
  //         resultSelecteAgre[index].confirmeddeliversystatus == false
  //       ) {
  //         deliveryUnconfirmed.push(resultSelecteAgre[index].AgreementNumber);
  //         setdeliveryUnconfiremd(true);
  //       }
  //       if (Number(resultSelecteAgre[index].isSwitchoutDeliveryPending) === 1) {
  //         deliveryUnconfirmed.push(
  //           resultSelecteAgre[index].AgreementNumber + "(Switchout)"
  //         );
  //         setdeliveryUnconfiremd(true);
  //       } // Newly Added
  //       setagreementDeliveryUncomfirmed(deliveryUnconfirmed);
  //       //This condition used for enable and disable adjust due date button at store level and if it is club then any store it can be adjustable
  //       if (
  //         String(storeNumber) ==
  //           String(resultSelecteAgre[index].store_number) ||
  //         (selectedCheckBoxCount <= 2 &&
  //           resultSelecteAgre[index].Agreementtype == "Club")
  //       ) {
  //         setdisableAdjustDueDateButton(false);
  //       } else {
  //         setdisableAdjustDueDateButton(true);
  //       }
  //       if (
  //         resultSelecteAgre[index].Nextduedate == "SAC" ||
  //         resultSelecteAgre[index].Nextduedate == "PIF" ||
  //         resultSelecteAgre[index].Nextduedate == "EPO" ||
  //         resultSelecteAgre[index].Agreementtype == "Club" ||
  //         resultSelecteAgre[index].number_of_paymentsmade == 0 ||
  //         resultSelecteAgre[index].FreeTime !== null ||
  //         resultSelecteAgre[index].isZeroPayment == true
  //       ) {
  //         carryallowedCount++;
  //         if (resultSelecteAgre[index].Agreementtype == "Club") {
  //           setcarryMessage("Club amount cannot be carried");
  //           carryMessage = "Club amount cannot be carried";
  //         } else {
  //           if (carryMessage == "") {
  //             setcarryMessage(
  //               "Selected agreement is in intital or final payment"
  //             );
  //           }
  //         }
  //         carrycount = 1;
  //       } else {
  //         carryRentCount++;
  //       }
  //       if (Number(resultSelecteAgre[index].carryRentAmountUsed) > 0) {
  //         carryUsed = Number(
  //           (
  //             Number(carryUsed) +
  //             Number(resultSelecteAgre[index].carryRentAmountUsed)
  //           ).toFixed(2)
  //         );
  //         setagreecarryUsed(carryUsed);
  //       }
  //       if (Number(resultSelecteAgre[index].carriedLate) > 0) {
  //         carryLateUsed = Number(
  //           (
  //             Number(carryLateUsed) +
  //             Number(resultSelecteAgre[index].carriedLate)
  //           ).toFixed(2)
  //         );
  //         setagreecarryLateUsed(carryLateUsed);
  //       }
  //       //carry rent Check Ends
  //       //For multiple selected agrrements needs to check following conditions for adjust due date
  //       if (
  //         resultSelecteAgre[index].Schedule != resultSelecteAgre[0].Schedule ||
  //         resultSelecteAgre[index].isZeroPayment == true ||
  //         resultSelecteAgre[index].CurrentDueDate !=
  //           resultSelecteAgre[0].CurrentDueDate ||
  //         resultSelecteAgre[index].Nextduedate == "SAC" ||
  //         resultSelecteAgre[index].Nextduedate == "PIF" ||
  //         resultSelecteAgre[index].Nextduedate == "EPO" ||
  //         resultSelecteAgre[index].isReturnAgrFlag == 1 ||
  //         resultSelecteAgre[index].Agreementtype == "Note Payment" ||
  //         (resultSelecteAgre[index].number_of_paymentsmade == 0 &&
  //           resultSelecteAgre[index].Agreementtype != "Club") ||
  //         String(resultSelecteAgre[index].store_number) !=
  //           String(storeNumber) ||
  //         resultSelecteAgre[index].FreeTime !== null // Added By Me
  //       ) {
  //         count = 1;
  //         setadjustButton(false);
  //       } else {
  //         adjustall++;
  //       }
  //       if (
  //         resultSelecteAgre[index].Nextduedate == "SAC" ||
  //         resultSelecteAgre[index].Nextduedate == "PIF" ||
  //         resultSelecteAgre[index].Nextduedate == "EPO" ||
  //         resultSelecteAgre[index].Agreementtype == "Note Payment" ||
  //         resultSelecteAgre[index].isReturnAgrFlag == 1 ||
  //         resultSelecteAgre[index].PromoFreeDays == 0 ||
  //         (resultSelecteAgre[index].number_of_paymentsmade == 0 &&
  //           resultSelecteAgre[index].Agreementtype != "Club") ||
  //         String(resultSelecteAgre[index].store_number) !=
  //           String(storeNumber) ||
  //         resultSelecteAgre[index].isZeroPayment == true
  //       ) {
  //         promoCount = 1;
  //         setpromoFreeTimeButton(true);
  //       }
  //       if (
  //         resultSelecteAgre[index].Nextduedate == "SAC" ||
  //         resultSelecteAgre[index].Nextduedate == "PIF" ||
  //         resultSelecteAgre[index].Nextduedate == "EPO" ||
  //         resultSelecteAgre[index].number_of_paymentsmade == 0 ||
  //         resultSelecteAgre[index].Agreementtype == "Club" ||
  //         String(resultSelecteAgre[index].store_number) != String(storeNumber)
  //       ) {
  //         suspenseCount = 1;
  //         setsuspenseButton(true);
  //       }
  //       if (
  //         resultSelecteAgre[index].Schedule != resultSelecteAgre[0].Schedule ||
  //         resultSelecteAgre[index].isZeroPayment == true ||
  //         resultSelecteAgre[index].Nextduedate == "SAC" ||
  //         resultSelecteAgre[index].Nextduedate == "PIF" ||
  //         resultSelecteAgre[index].Nextduedate == "EPO" ||
  //         resultSelecteAgre[index].Agreementtype == "Note Payment" ||
  //         resultSelecteAgre[index].isReturnAgrFlag == 1 ||
  //         resultSelecteAgre[index].number_of_paymentsmade == 0 ||
  //         String(resultSelecteAgre[index].store_number) !=
  //           String(storeNumber) ||
  //         resultSelecteAgre[index].FreeTime !== null // Added By Me
  //       ) {
  //         editCount = 1;
  //         seteditButton(false);
  //       } else {
  //         editall++;
  //       }
  //       if (
  //         resultSelecteAgre[index].Nextduedate == "SAC" ||
  //         resultSelecteAgre[index].Nextduedate == "PIF" ||
  //         resultSelecteAgre[index].Nextduedate == "EPO" ||
  //         resultSelecteAgre[index].isReturnAgrFlag == 1 ||
  //         String(resultSelecteAgre[index].store_number) != String(storeNumber) // Added By Me
  //       ) {
  //         isZeroDollarCount = 1;
  //         setIsZeroDollarPayment(true);
  //       } else {
  //         isZeroDollarCount++;
  //       }
  //     }

  //     if (resultSelecteAgre.length == 0 || carryUsed == 0) {
  //       setagreecarryUsed(0.0);
  //     } else {
  //       setagreecarryUsed(carryUsed);
  //     }
  //     if (resultSelecteAgre.length == 0 || carryLateUsed == 0) {
  //       setagreecarryLateUsed(0.0);
  //     } else {
  //       setagreecarryLateUsed(carryLateUsed);
  //     }
  //     if (carryallowedCount == resultSelecteAgre.length) {
  //       setcarryRentCheck(true);
  //     }
  //     if (deliveryUnconfirmed.length == 0) {
  //       setdeliveryUnconfiremd(false);
  //     }
  //     if (carrycount == 0) {
  //       setcarryMessage("");
  //     }
  //     if (suspenseCount == 0) {
  //       setsuspenseButton(false);
  //     }
  //     if (promoCount == 0) {
  //       setpromoFreeTimeButton(true);
  //     }

  //     const suspenseEpoChanges = paygridvalues.filter(function (e) {
  //       return e.Agreementtype != "Club";
  //     });
  //     if (suspenseEpoChanges?.length > 0) {
  //       if (
  //         onlyCashsaleCheckedCount == resultSelecteAgre.length ||
  //         (suspenseEpoChanges?.length == 1 &&
  //           (suspenseEpoChanges[0]?.Nextduedate == "SAC" ||
  //             suspenseEpoChanges[0]?.Nextduedate == "EPO" ||
  //             suspenseEpoChanges[0]?.Nextduedate == "PIF"))
  //       ) {
  //         setselectedOnlyCashSale(true);
  //       } else {
  //         setselectedOnlyCashSale(false);
  //       }
  //     }

  //     if (count != 1) {
  //       if (adjustall > 1) {
  //         setadjustAllButton(true);
  //       } else {
  //         setadjustAllButton(false);
  //       }
  //       setadjustButton(true);
  //     }
  //     if (editCount != 1) {
  //       seteditButton(true);
  //     }

  //     //
  //   }
  //   if (isZeroDollarCount == 0) {
  //     setIsZeroDollarPayment(false);
  //   }
  //   let totalsusamount = 0;
  //   let SuspenseAmountFilter = resultSelecteAgre.filter(
  //     (x) =>
  //       x.Agreementtype != "Club" &&
  //       x.Nextduedate != "PIF" &&
  //       x.Nextduedate != "SAC" &&
  //       x.Nextduedate != "EPO" &&
  //       x.number_of_paymentsmade > 0 &&
  //       String(x.store_number) ==
  //         String(window.sessionStorage.getItem("storeNumber"))
  //   );
  //   for (let i = 0; i < SuspenseAmountFilter.length; i++) {
  //     totalsusamount =
  //       Number(totalsusamount) + Number(SuspenseAmountFilter[i].SuspenseAmount);
  //   }

  //   let insertArray: any = [];
  //   for (let i = 0; i < resultSelecteAgre.length; i++) {
  //     if (Number(resultSelecteAgre[i].suspenseSub) > 0) {
  //       if (
  //         resultSelecteAgre[i].Agreementtype != "Club" &&
  //         resultSelecteAgre[i].Nextduedate != "PIF" &&
  //         resultSelecteAgre[i].Nextduedate != "SAC" &&
  //         resultSelecteAgre[i].Nextduedate != "EPO" &&
  //         diffStoreCount == 0 &&
  //         resultSelecteAgre[i].number_of_paymentsmade > 0 &&
  //         String(resultSelecteAgre[i].store_number) ==
  //           String(window.sessionStorage.getItem("storeNumber"))
  //       ) {
  //         insertArray.push({
  //           sustype:
  //             Number(resultSelecteAgre[i].suspenseSub) > 0 ? "Used" : "type",
  //           agreementnumber: resultSelecteAgre[i].Agreementid,
  //           agreementid: resultSelecteAgre[i].AgreementNumber,
  //           schedure: resultSelecteAgre[i].Schedule,
  //           paymentamount: resultSelecteAgre[i].Amount,
  //           epoamount: resultSelecteAgre[i].EPOAmount,
  //           suspenseamount: resultSelecteAgre[i].SuspenseAmount,
  //           newsuspense: Number(resultSelecteAgre[i].suspenseSub),
  //           agreementType: resultSelecteAgre[i].Agreementtype,
  //           transferedCOA: false,
  //         });
  //       } else if (
  //         (resultSelecteAgre[i].Nextduedate == "PIF" ||
  //           resultSelecteAgre[i].Nextduedate == "SAC" ||
  //           resultSelecteAgre[i].Nextduedate == "EPO") &&
  //         resultSelecteAgre[i].number_of_paymentsmade > 0 &&
  //         diffStoreCount == 0 &&
  //         String(resultSelecteAgre[i].store_number) ==
  //           String(window.sessionStorage.getItem("storeNumber"))
  //       ) {
  //         insertArray.push({
  //           sustype:
  //             Number(resultSelecteAgre[i].suspenseSub) > 0
  //               ? "FinalUsed"
  //               : "type",
  //           agreementnumber: resultSelecteAgre[i].Agreementid,
  //           agreementid: resultSelecteAgre[i].AgreementNumber,
  //           schedure: resultSelecteAgre[i].Schedule,
  //           paymentamount: resultSelecteAgre[i].Amount,
  //           epoamount: resultSelecteAgre[i].EPOAmount,
  //           suspenseamount: resultSelecteAgre[i].SuspenseAmount,
  //           newsuspense: Number(resultSelecteAgre[i].suspenseSub),
  //           agreementType: resultSelecteAgre[i].Agreementtype,
  //           transferedCOA: false,
  //         });
  //       }
  //     }
  //     if (Number(resultSelecteAgre[i].suspenseAdd) > 0) {
  //       if (
  //         resultSelecteAgre[i].Agreementtype != "Club" &&
  //         resultSelecteAgre[i].Nextduedate != "PIF" &&
  //         resultSelecteAgre[i].Nextduedate != "SAC" &&
  //         resultSelecteAgre[i].Nextduedate != "EPO" &&
  //         diffStoreCount == 0 &&
  //         resultSelecteAgre[i].number_of_paymentsmade > 0 &&
  //         String(resultSelecteAgre[i].store_number) ==
  //           String(window.sessionStorage.getItem("storeNumber"))
  //       ) {
  //         insertArray.push({
  //           sustype:
  //             Number(resultSelecteAgre[i].suspenseAdd) > 0 ? "Add" : "type",
  //           agreementnumber: resultSelecteAgre[i].Agreementid,
  //           agreementid: resultSelecteAgre[i].AgreementNumber,
  //           schedure: resultSelecteAgre[i].Schedule,
  //           paymentamount: resultSelecteAgre[i].Amount,
  //           epoamount: resultSelecteAgre[i].EPOAmount,
  //           suspenseamount: resultSelecteAgre[i].SuspenseAmount,
  //           newsuspense: Number(resultSelecteAgre[i].suspenseAdd),
  //           agreementType: resultSelecteAgre[i].Agreementtype,
  //           transferedCOA: false,
  //         });
  //       }
  //     }
  //   }
  //   debugger;
  //   if (resultSelecteAgre.length > 0) {
  //     for (let i = 0; i < paygridvalues.length; i++) {
  //       if (
  //         Number(paygridvalues[i].transferOldSuspense) -
  //           Number(paygridvalues[i].SuspenseAmount) <
  //         0
  //       ) {
  //         if (
  //           paygridvalues[i].Agreementtype != "Club" &&
  //           paygridvalues[i].Nextduedate != "PIF" &&
  //           paygridvalues[i].Nextduedate != "SAC" &&
  //           paygridvalues[i].Nextduedate != "EPO" &&
  //           diffStoreCount == 0 &&
  //           paygridvalues[i].number_of_paymentsmade > 0 &&
  //           String(paygridvalues[i].store_number) ==
  //             String(window.sessionStorage.getItem("storeNumber"))
  //         ) {
  //           insertArray.push({
  //             sustype: "TransferAdd",
  //             agreementnumber: paygridvalues[i].Agreementid,
  //             agreementid: paygridvalues[i].AgreementNumber,
  //             schedure: paygridvalues[i].Schedule,
  //             paymentamount: paygridvalues[i].Amount,
  //             epoamount: paygridvalues[i].EPOAmount,
  //             suspenseamount: paygridvalues[i].SuspenseAmount,
  //             newsuspense:
  //               Number(paygridvalues[i].SuspenseAmount) -
  //               Number(paygridvalues[i].transferOldSuspense),
  //             agreementType: paygridvalues[i].Agreementtype,
  //             transferedCOA: false,
  //           });
  //         }
  //       }
  //       if (
  //         Number(paygridvalues[i].transferOldSuspense) -
  //           Number(paygridvalues[i].SuspenseAmount) >
  //         0
  //       ) {
  //         if (
  //           paygridvalues[i].Agreementtype != "Club" &&
  //           paygridvalues[i].Nextduedate != "PIF" &&
  //           paygridvalues[i].Nextduedate != "SAC" &&
  //           paygridvalues[i].Nextduedate != "EPO" &&
  //           diffStoreCount == 0 &&
  //           paygridvalues[i].number_of_paymentsmade > 0 &&
  //           String(paygridvalues[i].store_number) ==
  //             String(window.sessionStorage.getItem("storeNumber"))
  //         ) {
  //           insertArray.push({
  //             sustype: "TransferUsed",
  //             agreementnumber: paygridvalues[i].Agreementid,
  //             agreementid: paygridvalues[i].AgreementNumber,
  //             schedure: paygridvalues[i].Schedule,
  //             paymentamount: paygridvalues[i].Amount,
  //             epoamount: paygridvalues[i].EPOAmount,
  //             suspenseamount: Number(paygridvalues[i].SuspenseAmount),
  //             newsuspense:
  //               Number(paygridvalues[i].transferOldSuspense) -
  //               Number(paygridvalues[i].SuspenseAmount),
  //             agreementType: paygridvalues[i].Agreementtype,
  //             transferedCOA: paygridvalues[i].isCOATransfered,
  //           });
  //         } else if (
  //           (paygridvalues[i].Nextduedate == "PIF" ||
  //             paygridvalues[i].Nextduedate == "SAC" ||
  //             paygridvalues[i].Nextduedate == "EPO") &&
  //           paygridvalues[i].number_of_paymentsmade > 0 &&
  //           diffStoreCount == 0 &&
  //           String(paygridvalues[i].store_number) ==
  //             String(window.sessionStorage.getItem("storeNumber"))
  //         ) {
  //           insertArray.push({
  //             sustype: "FinalTransferUsed",
  //             agreementnumber: paygridvalues[i].Agreementid,
  //             agreementid: paygridvalues[i].AgreementNumber,
  //             schedure: paygridvalues[i].Schedule,
  //             paymentamount: paygridvalues[i].Amount,
  //             epoamount: paygridvalues[i].EPOAmount,
  //             suspenseamount: Number(paygridvalues[i].SuspenseAmount),
  //             newsuspense:
  //               Number(paygridvalues[i].transferOldSuspense) -
  //               Number(paygridvalues[i].SuspenseAmount),
  //             agreementType: paygridvalues[i].Agreementtype,
  //             transferedCOA: paygridvalues[i].isCOATransfered,
  //           });
  //         }
  //       }
  //     }
  //   }

  //   setLatelast10value(lateAmount);
  //   totalsuspensemaster = insertArray;
  //   settotalsuspensemaster(insertArray);
  //   let susAddCount = 0;
  //   let susUseCount = 0;
  //   for (let i = 0; i < insertArray.length; i++) {
  //     if (insertArray[i].sustype == "Add") {
  //       susAddCount == 1;
  //       setcheckdisable(true);
  //       setinstoreDisable(true);
  //       if (paymentOrigin != "Store") {
  //         phone({ target: { value: "Store" } });
  //       }
  //     }
  //     // if(insertArray[i].sustype=="Used"){
  //     //   susUseCount++
  //     // }
  //     // if(susAddCount>1||susUseCount>1){
  //     //   if(paymentOrigin!="Store"){
  //     //     phone({target:{value:"Store"}})
  //     //   }
  //     //   setinstoreDisable(true)
  //     // }
  //   }
  //   if (insertArray.length == 0) {
  //     setcheckdisable(false);
  //     setinstoreDisable(false);
  //   }
  //   settotalsuspensemaster(insertArray);

  //   if (resultSelecteAgre.length == 1) {
  //     if (
  //       resultSelecteAgre[0].Agreementtype != "Club" &&
  //       resultSelecteAgre[0].number_of_paymentsmade != 0 &&
  //       resultSelecteAgre[0].FreeTime == null &&
  //       resultSelecteAgre[0].isReturnAgrFlag != 1
  //     ) {
  //       setshowEPOGridBtn(true);
  //     } else {
  //       setshowEPOGridBtn(false);
  //     }
  //   } else {
  //     setshowEPOGridBtn(false);
  //   }
  //   //charge-off
  //   if (resultSelecteAgre?.length > 1) {
  //     for (let i = 0; i < resultSelecteAgre.length; i++) {
  //       if (
  //         resultSelecteAgre[i].Agreementtype == "Restitution" &&
  //         resultSelecteAgre[i].Nextduedate == "PIF" &&
  //         adjustChargeoffFlag == false
  //       ) {
  //         checkBoxCountchargeoffFlag = true;
  //         setadjustChargeoffFlag(true);
  //         setchargeoffadjustFlag(false);
  //       } else if (
  //         resultSelecteAgre[i].Agreementtype == "Restitution" &&
  //         resultSelecteAgre[i].Nextduedate == "PIF" &&
  //         adjustChargeoffFlag == true
  //       ) {
  //         checkBoxCountchargeoffFlag = true;
  //         // setadjustChargeoffFlag(true)
  //         setchargeoffadjustFlag(false);
  //       } else if (
  //         resultSelecteAgre[i].Agreementtype != "Restitution" &&
  //         resultSelecteAgre[i].Nextduedate != "PIF" &&
  //         adjustChargeoffFlag == true &&
  //         checkBoxCountchargeoffFlag == false
  //       ) {
  //         // setadjustChargeoffFlag(false)
  //         setchargeoffadjustFlag(true);
  //       }
  //     }
  //   }
  //   if (resultSelecteAgre.length == 1) {
  //     for (let i = 0; i < resultSelecteAgre.length; i++) {
  //       if (resultSelecteAgre[i].Agreementtype == "Restitution") {
  //         setadjustChargeoffFlag(true);
  //         setchargeoffadjustFlag(false);
  //       } else {
  //         setchargeoffadjustFlag(false);
  //         setadjustChargeoffFlag(false);
  //       }
  //     }
  //   }
  //   //Rental Item Sale
  //   const item = resultSelecteAgre.filter(
  //     (val) => val.Agreementtype == "Rental Item Sale"
  //   );
  //   if (
  //     resultSelecteAgre.length == 1 &&
  //     resultSelecteAgre[0].Agreementtype == "Rental Item Sale"
  //   ) {
  //     setRisRemovepaymentbtnFlag(true);
  //     setadjustRisbtnFlag(true);
  //     // setRisRemovepaymentbtnFlag(true)
  //     setRisRemovepaymentdisableFlag(false);
  //   } else if (resultSelecteAgre.length > 1 && item.length != 0) {
  //     setRisRemovepaymentbtnFlag(true);
  //     setRisRemovepaymentdisableFlag(true);
  //     setadjustRisbtnFlag(false);
  //   }

  //   if (diffStoreCount == 0) {
  //     diffStoreErrLbl = false;
  //     setmobtn(false);
  //     setcashbtn(false);
  //     setcreditDisable(false);
  //     setchangeRadio(true);
  //     setCreditRadio(false);
  //     setradioBtn("0");
  //   } else {
  //     diffStoreErrLbl = true;
  //     let invokeResetPayment = false;
  //     const constantStoreNumber = resultSelecteAgre[0].store_number;
  //     const isOnlySameStoreSelected = resultSelecteAgre.every(
  //       (x) => x.store_number === constantStoreNumber
  //     );
  //     if (resultSelecteAgre.length > 1 && isOnlySameStoreSelected) {
  //       invokeResetPayment = false;
  //     } else {
  //       invokeResetPayment = true;
  //     }
  //     if (invokeResetPayment) {
  //       getpaid("STD", true);
  //       resetPayment(undefined);
  //       resetInvoked = true;
  //     }
  //     setswipeDisable(true);
  //     setcreditDisable(true);
  //     setCreditRadio(true);
  //     setchangeRadio(false);
  //     setcashselected(1);
  //     setradioBtn("1");
  //     setmobtn(true);
  //     setcashbtn(true);
  //     setCardrequire({
  //       ...Cardrequire,
  //       drpdown: false,
  //       CardCss: `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`,
  //     });
  //     setcashtabCss(`${navlinkClass.navLink} ${classes.p3}`);
  //     setcheckMoneyOrdertabCss(`${navlinkClass.navLink} ${classes.p3}`);
  //   }
  // };

  /**changes : Edit Schedule v2
   * Develeoper : Muzzamil
   * Desc : onclick of EditSchedulee line item set the editSchedulePopUp (true)
   */
  const editSchedule = () => {
    if (
      editButton == true &&
      selectedCheckBoxCount >= 1 &&
      disableAdjustDueDateButton == false
    ) {
      if (configFlags.optimizedPayment == "1") {
        const needCalculation = checkedAgr.some(
          (el) => el.taxEngineData == null
        );
        if (needCalculation) {
          preEditScheduleCalculation();
        }
        seteditSchedulePopUp(true);
        setPopupClass(
          `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
        );
      } else {
        seteditSchedulePopUp(true);
        setPopupClass(
          `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
        );
      }
    }
  };

  const preEditScheduleCalculation = async () => {
    setEnableScheduleLoader(true);
    // seteditSchedulePopUp(true);
    // setPopupClass(
    //   `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    // );
    let existingSchedule: any = checkedAgr[0].Schedule;
    let scheduleToBeAdjusted: any = ["WK", "BIWK", "MON", "SEMI1-15"];
    // if (existingSchedule == "Weekly") {
    //   scheduleToBeAdjusted.push("BIWK", "MON", "SEMI1-15");
    // } else if (existingSchedule == "Bi-Weekly") {
    //   scheduleToBeAdjusted.push("WK", "MON", "SEMI1-15");
    // } else if (existingSchedule == "Monthly") {
    //   scheduleToBeAdjusted.push("WK", "BIWK", "SEMI1-15");
    // } else if (existingSchedule == "Semi-Monthly") {
    //   scheduleToBeAdjusted.push("WK", "BIWK", "MON");
    // }
    //let allScheduleResponse: any = [];
    let adjustDueDatereq: any = [];
    let taxInputsData: any = [];
    for (
      let loopIndex = 0;
      loopIndex < scheduleToBeAdjusted.length;
      loopIndex++
    ) {
      for (let index = 0; index < checkedAgr.length; index++) {
        let coverageEndDateInfo: any = [];
        let policyStatus = "";
        let coverageEndDate = "";

        if (actLDWArr.length > 0) {
          coverageEndDateInfo = actLDWArr.filter(
            (x) =>
              String(x.agreementId) === String(checkedAgr[index].Agreementid)
          );

          if (coverageEndDateInfo.length > 0) {
            if (Number(coverageEndDateInfo[0].action) == 2) {
              const currentdate: Date = new Date(
                new Date().toLocaleString("en-US", {
                  timeZone: "America/Chicago",
                })
              );
              const current_date = new Date(
                currentdate.getFullYear(),
                currentdate.getMonth(),
                currentdate.getDate()
              );
              coverageEndDate = moment(current_date).format("MM/DD/YYYY");
            } else if (Number(coverageEndDateInfo[0].action) == 1) {
              coverageEndDate = "12/31/2099";
              if (
                Number(checkedAgr[index].agreementRateDetails.perDayLdw) == 0
              ) {
                checkedAgr[index].agreementRateDetails.perDayLdw = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .weeklyRate
                  ) / 7
                ).toFixed(2);
                checkedAgr[index].agreementRateDetails.perDayLdwTax = (
                  (Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .weeklyRate
                  ) /
                    7) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                  )
                ).toFixed(2);
                checkedAgr[index].agreementRateDetails.weeklyLdw = Number(
                  coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                    .weeklyRate
                ).toFixed(2);
                checkedAgr[index].agreementRateDetails.weeklyLdwTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .weeklyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                  )
                ).toFixed(2);
                checkedAgr[index].agreementRateDetails.biWeeklyLdw = Number(
                  coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                    .biweeklyRate
                ).toFixed(2);
                checkedAgr[index].agreementRateDetails.biWeeklyLdwTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .biweeklyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                  )
                ).toFixed(2);
                checkedAgr[index].agreementRateDetails.monthlyLdw = Number(
                  coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                    .monthlyRate
                ).toFixed(2);
                checkedAgr[index].agreementRateDetails.monthlyLdwTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .monthlyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                  )
                ).toFixed(2);
                checkedAgr[index].agreementRateDetails.semiMonthlyLdw = Number(
                  coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                    .semimonthlyRate
                ).toFixed(2);
                checkedAgr[index].agreementRateDetails.semiMonthlyLdwTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .semimonthlyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                  )
                ).toFixed(2);
              }
            }
          }
        }
        if (coverageEndDate == "") {
          coverageEndDate = String(checkedAgr[index].coverageEndDate);
        }
        if (actPolicyArr.length > 0) {
          const policyChangeArrInfo = actPolicyArr.filter(
            (x) =>
              String(x.agreementId) === String(checkedAgr[index].Agreementid)
          );
          if (policyChangeArrInfo.length > 0) {
            if (Number(policyChangeArrInfo[0].action) == 2) {
              policyStatus = "I";
            } else if (Number(policyChangeArrInfo[0].action) == 1) {
              policyStatus = "A";
              if (
                Number(checkedAgr[index].agreementRateDetails.perDayPolicy) == 0
              ) {
                checkedAgr[index].agreementRateDetails.perDayPolicy = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.weeklyRate
                  ) / 7
                ).toFixed(2);
                checkedAgr[index].agreementRateDetails.perDayPolicyTax = (
                  (Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.weeklyRate
                  ) /
                    7) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate
                  )
                ).toFixed(2);
                checkedAgr[index].agreementRateDetails.weeklyPolicy = Number(
                  coverageEndDateInfo[0].cusCoverageData.policyInfo
                    .scheduleRates.weeklyRate
                ).toFixed(2);
                checkedAgr[index].agreementRateDetails.weeklyPolicyTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.weeklyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate
                  )
                ).toFixed(2);
                checkedAgr[index].agreementRateDetails.biWeeklyPolicy = Number(
                  coverageEndDateInfo[0].cusCoverageData.policyInfo
                    .scheduleRates.biweeklyRate
                ).toFixed(2);
                checkedAgr[index].agreementRateDetails.biWeeklyPolicyTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.biweeklyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate
                  )
                ).toFixed(2);
                checkedAgr[index].agreementRateDetails.monthlyPolicy = Number(
                  coverageEndDateInfo[0].cusCoverageData.policyInfo
                    .scheduleRates.monthlyRate
                ).toFixed(2);
                checkedAgr[index].agreementRateDetails.monthlyPolicyTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.monthlyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate
                  )
                ).toFixed(2);
                checkedAgr[index].agreementRateDetails.semiMonthlyPolicy =
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.semimonthlyRate
                  ).toFixed(2);
                checkedAgr[index].agreementRateDetails.semiMonthlyPolicyTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.semimonthlyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate
                  )
                ).toFixed(2);
              }
            }
          }
        }
        if (policyStatus == "") {
          policyStatus = String(checkedAgr[index].policyStatus);
        }
        const res = {
          Agreementtype: checkedAgr[index].Agreementtype,
          agreementId: String(checkedAgr[index].Agreementid),
          agreementRateDetails: checkedAgr[index].agreementRateDetails,
          currentDueDate: String(checkedAgr[index].CurrentDueDate),
          dueDate: String(checkedAgr[index].Nextduedate),
          coverageEndDate: String(coverageEndDate),
          taxRate: String(checkedAgr[index].taxRate),
          carriedLate: String(checkedAgr[index].carriedLate),
          carriedLateTax: String(checkedAgr[index].carriedLateTax),
          lateFee: String(checkedAgr[index].lateFee),
          lateFeeTax: String(checkedAgr[index].lFeeTaxAmount),
          policyStatus: String(policyStatus),
          epoAmount: String(checkedAgr[index].EPOAmount),
          epoTax: String(checkedAgr[index].EPOTax),
          sacDate: String(checkedAgr[index].SAC_Date),
          carryRent: String(checkedAgr[index].carryRentAmountUsed),
          carryRentTax: String(checkedAgr[index].carryRentTaxUsed),
          agreementNumber: String(checkedAgr[index].AgreementNumber),
          scheduleRefCode: "",
        };
        adjustDueDatereq.push(res);
      }
      const editScheudleRequest = {
        rateResponse: adjustDueDatereq,
        schedule: scheduleToBeAdjusted[loopIndex], // Need to invoke with 3 diff values
      };
      const adjustDueRes = await AdjustDueDatecalci(editScheudleRequest);;
      const clubFilteredData = adjustDueRes.filter(
        (el) => el.agreementType == "Club"
      );
      const nonClubFilteredData = adjustDueRes.filter(
        (el) => el.agreementType !== "Club"
      );
      if (nonClubFilteredData.length) {
        nonClubFilteredData.map((el) => {
          taxInputsData.push(
            {
              amount: el.policyAmount,
              taxInputId: `${el.agreementId} - ${scheduleToBeAdjusted[loopIndex]}`,
              itemCategory: "RACTIREPAY",
            },
            {
              amount: el.agreementRate,
              taxInputId: `${el.agreementId} - ${scheduleToBeAdjusted[loopIndex]}`,
              itemCategory: "RPAY",
            },
            {
              amount: el.ldwAmount,
              taxInputId: `${el.agreementId} - ${scheduleToBeAdjusted[loopIndex]}`,
              itemCategory: "LDW",
            },
            {
              amount: el.lateFee,
              taxInputId: `${el.agreementId} - ${scheduleToBeAdjusted[loopIndex]}`,
              itemCategory: "LTRPAYFEE",
            },
            {
              amount: el.carryRent,
              taxInputId: `${el.agreementId} - ${scheduleToBeAdjusted[loopIndex]}`,
              itemCategory: "CRPAY",
            },
            {
              amount: el.carriedLate,
              taxInputId: `${el.agreementId} - ${scheduleToBeAdjusted[loopIndex]}`,
              itemCategory: "CLFEEPAY",
            }
          );
        });
      }
      if (clubFilteredData.length) {
        clubFilteredData.map((el) => {
          taxInputsData.push(
            {
              amount: el.policyAmount,
              taxInputId: `${el.agreementId} - ${scheduleToBeAdjusted[loopIndex]}`,
              itemCategory: "RACTIREPAY",
            },
            {
              amount: el.agreementRate,
              taxInputId: `${el.agreementId} - ${scheduleToBeAdjusted[loopIndex]}`,
              itemCategory: "CLUBPAY",
            },
            {
              amount: el.ldwAmount,
              taxInputId: `${el.agreementId} - ${scheduleToBeAdjusted[loopIndex]}`,
              itemCategory: "LDW",
            },
            {
              amount: el.lateFee,
              taxInputId: `${el.agreementId} - ${scheduleToBeAdjusted[loopIndex]}`,
              itemCategory: "LTRPAYFEE",
            },
            {
              amount: el.carryRent,
              taxInputId: `${el.agreementId} - ${scheduleToBeAdjusted[loopIndex]}`,
              itemCategory: "CRPAY",
            },
            {
              amount: el.carriedLate,
              taxInputId: `${el.agreementId} - ${scheduleToBeAdjusted[loopIndex]}`,
              itemCategory: "CLFEEPAY",
            }
          );
        });
      }
      //allScheduleResponse.push(adjustDueRes);
      adjustDueDatereq = [];
    }

    let customerAdressFilterArray: any = customerInformation?.addresses?.filter(
      (x) => {
        return x.addressTypeDesc == "Primary Address";
      }
    );

    let zip5 = "";
    let zip4 = "0000";
    let postalZip =
      defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.zip == null
        ? null
        : defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.zip?.replace(
            /-/g,
            ""
          );

    let JsonsessionStoreDetails: any =
      window.sessionStorage.getItem("storeDetails");

    let sessionStoreDetails: any;
    try {
      sessionStoreDetails = JSON.parse(atob(JsonsessionStoreDetails));
    } catch (e: any) {
    }

    if (!isStringWithDigits(5, postalZip)) {
      zip5 = sessionStoreDetails?.zip?.split("-")[0];
      zip4 = sessionStoreDetails?.zip?.split("-")[1];
      if (!isStringWithDigits(4, zip4)) {
        zip4 = "0000";
      }
    } else {
      zip5 = defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.zip;
      if (
        defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.plus4?.length == 4
      ) {
        zip4 = defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.plus4;
      } else {
        zip4 = "0000";
      }
    }
    const formedTaxPayload: any = {
      customerState: defaultPaygridvalues.paymentInfo?.customerInfo[0]?.state,
      postalCode: zip5 + "-" + zip4,
      customerId: customerId,
      apiCall: "",
      dateOfBirth: moment(
        defaultPaygridvalues.paymentInfo.customerInfo[0].dob
      ).format("YYYY-MM-DD"),
      taxRateOnly: "N",
      taxExemptAsOfToday: taxExemption == "" ? "N" : taxExemption,
    };
    formedTaxPayload.storeInputs = [
      {
        taxInputs: taxInputsData,
        storeNumber: String(window.sessionStorage.getItem("storeNumber")),
      },
    ];
    setTaxPayload(formedTaxPayload);
    const taxEngineResponse = await getTax(formedTaxPayload);
    if (taxEngineResponse.status == 200) {
      const updatePaygridValues = paygridvalues;
      updatePaygridValues.map((el) => {
        const filteredTaxData =
          taxEngineResponse.data.taxResponse[0].taxOutputs.filter((x) =>
            x.taxOutputId.includes(el.Agreementid)
          );
        if (filteredTaxData.length) {
          el.taxEngineData = filteredTaxData;
        }
      });
      setpaygridvalues(updatePaygridValues);
      const updateCheckedAgreement = checkedAgr;
      updateCheckedAgreement.map((el) => {
        const filteredTaxData =
          taxEngineResponse.data.taxResponse[0].taxOutputs.filter((x) =>
            x.taxOutputId.includes(el.Agreementid)
          );
        if (filteredTaxData.length) {
          el.taxEngineData = filteredTaxData;
        }
      });
      setcheckedAgr(updateCheckedAgreement);
    }
    setEnableScheduleLoader(false);
    // enableScheduleLoader = false
  };

  const AddTrailingZerosToAmount = (inputValue: any) => {
    inputValue = Number(inputValue).toFixed(2);
    let a = Number(inputValue).toFixed(2).split(".");

    let retValue = inputValue;
    if (a.length < 2) {
      retValue = inputValue + ".00";
    } else if (a.length === 2 && Number(a[1]) * 10 < 100) {
      if (a[1] !== "") {
        const b = a[1].split("");
        if (b[0] !== "0") {
          retValue = inputValue + "0";
        }
        if (b[0] === "0" && b[1] === undefined) {
          retValue = inputValue + "0";
        }
      } else {
        retValue = inputValue + ".00";
      }
    }
    return retValue == "NaN.00" ? "0.00" : retValue;
  };

  const setcheckamountblur = (e: any) => {
    if (
      e.target.value !== "" &&
      e.target.value !== undefined &&
      Number(e.target.value) > 0
    ) {
      const a = adddata.Amount.split(".");

      if (a.length < 2) {
        setadddata({ ...adddata, Amount: adddata.Amount + ".00" });
      } else if (a.length === 2 && a[1] * 10 < 100) {
        if (a[1] !== "") {
          const b = a[1].split("");
          if (b[0] !== "0") {
            const amount = adddata.Amount + "0";
            setadddata({ ...adddata, Amount: amount });
          }
          if (b[0] === "0" && b[1] === undefined) {
            setadddata({ ...adddata, Amount: adddata.Amount + "0" });
          }
        } else {
          setadddata({ ...adddata, Amount: adddata.Amount + "00" });
        }
      }
    }
  };

  const masterSusOnBlur = (
    e: any,
    type: string,
    index: any,
    disabled: boolean
  ) => {
    let inputText: any =
      e.target.value == "" ||
      e.target.value == undefined ||
      Number.isNaN(e.target.value)
        ? 0.0
        : e.target.value;

    if (type == "Trans") {
      let totaltransnewsusamount: number;
      totaltransnewsusamount = 0;

      for (let i = 0; i < mastertranssuspensearray.length; i++) {
        totaltransnewsusamount =
          totaltransnewsusamount + mastertranssuspensearray[i].newsuspense;
      }

      settotalnewsuspenseamount(totaltransnewsusamount);

      for (let i = 0; i < mastertranssuspensearray.length; i++) {
        if (
          mastertranssuspensearray[i].suspenseamount <
          mastertranssuspensearray[i].newsuspense
        ) {
          mastertranssuspensearray[i].sustype = "TransferAdd";
        } else {
          mastertranssuspensearray[i].sustype = "TransferUsed";
        }
      }
    }
    setaddsus(AddTrailingZerosToAmount(inputText));
    let totalnewsusamount: number;
    totalnewsusamount = 0;

    for (let i = 0; i < masteraddsuspensearray.length; i++) {
      totalnewsusamount =
        totalnewsusamount + Number(masteraddsuspensearray[i].newsuspense);
    }
    let newtransferedSuspense = 0;
    let ActualSuspenseAmount = 0;
    for (let i = 0; i < mastertranssuspensearray.length; i++) {
      newtransferedSuspense =
        newtransferedSuspense + Number(mastertranssuspensearray[i].newsuspense);
      ActualSuspenseAmount =
        ActualSuspenseAmount +
        Number(mastertranssuspensearray[i].suspenseamount);
    }
    if (
      Number(Number(ActualSuspenseAmount).toFixed(2)) -
        Number(Number(newtransferedSuspense).toFixed(2)) ==
      0
    ) {
      settransferDifferenceZero(false);
    } else {
      settransferDifferenceZero(true);
    }
    let count = 0;
    for (let i = 0; i < mastertranssuspensearray.length; i++) {
      if (
        Number(mastertranssuspensearray[i].newsuspense) >
        Number(mastertranssuspensearray[i].epoamount)
      ) {
        count = 1;
      }
    }
    if (count == 0) {
      setsusbtndisabled(false);
    } else {
      setsusbtndisabled(true);
    }
    if(type=="Used")
    {
      let count = 0;
      for (let i = 0; i < masterusesuspensearray.length; i++) {
        if (
          Number(masterusesuspensearray[i].newsuspense) >
          Number(masterusesuspensearray[i].suspenseamount)
        ) {
          count = 1;
        }
      }
      if (count == 0) {
        setsusbtndisabled(false);
      } else {
        setsusbtndisabled(true);
      }
    }

    if(type=="Add")
    {
      let count = 0;
      for (let i = 0; i < masteraddsuspensearray.length; i++) {
        if (
          Number(masteraddsuspensearray[i].newsuspense) >
            Number(
              (
                Number(masteraddsuspensearray[i].epoamount) -
                Number(masteraddsuspensearray[i].suspenseamount)
              ).toFixed(2)
            ) ||
          Number(Number(masteraddsuspensearray[i].newsuspense).toFixed(2)) ==
            Number(
              (
                Number(masteraddsuspensearray[i].epoamount) -
                Number(masteraddsuspensearray[i].suspenseamount)
              ).toFixed(2)
            )
        ) {
          count = 1;
        }
      }
      if (count == 0) {
        setsusbtndisabled(false);
      } else {
        setsusbtndisabled(true);
      }

    }
    settotalnewsuspenseamount(newtransferedSuspense);
  };

  const masterSusOnchange = (
    e: any,
    type: string,
    index = 0,
    disabled: boolean
  ) => {
    // debugger;
    setaddsus("0.00");
    setusesus("0.00");
    settranssus("0.00");
    let inputText: any = e.target.value;

    if (type == "Add") {
      setaddsus(e.target.value);
      masteraddsuspensearray[index].newsuspense = inputText;
      masteraddsuspensearray[index].sustype = type;
      // }
      let count = 0;
      for (let i = 0; i < masteraddsuspensearray.length; i++) {
        if (
          Number(masteraddsuspensearray[i].newsuspense) >
            Number(
              (
                Number(masteraddsuspensearray[i].epoamount) -
                Number(masteraddsuspensearray[i].suspenseamount)
              ).toFixed(2)
            ) ||
          Number(Number(masteraddsuspensearray[i].newsuspense).toFixed(2)) ==
            Number(
              (
                Number(masteraddsuspensearray[i].epoamount) -
                Number(masteraddsuspensearray[i].suspenseamount)
              ).toFixed(2)
            )
        ) {
          count = 1;
        }
      }
      if (count == 0) {
        setsusbtndisabled(false);
      } else {
        setsusbtndisabled(true);
      }
    }

    if (type == "Used") {
      setusesus(e.target.value);
      masterusesuspensearray[index].newsuspense = inputText;
      masterusesuspensearray[index].sustype = type;
      let count = 0;
      for (let i = 0; i < masterusesuspensearray.length; i++) {
        if (
          Number(masterusesuspensearray[i].newsuspense) >
          Number(masterusesuspensearray[i].suspenseamount)
        ) {
          count = 1;
        }
      }
      if (count == 0) {
        setsusbtndisabled(false);
      } else {
        setsusbtndisabled(true);
      }

      // }
    }
    // debugger;
    if (type == "Trans") {
      settranssus(e.target.value);
      mastertranssuspensearray[index].newsuspense = inputText;
      // mastersuspensearray[index].newsuspense =AddTrailingZerosToAmount(inputText);
      mastertranssuspensearray[index].sustype = type;
      // setmasteraddsuspensearray(mastertranssuspensearray)
      let newtransferedSuspense = 0;
      let ActualSuspenseAmount = 0;
      for (let i = 0; i < mastertranssuspensearray.length; i++) {
        newtransferedSuspense =
          newtransferedSuspense +
          Number(mastertranssuspensearray[i].newsuspense);
        ActualSuspenseAmount =
          ActualSuspenseAmount +
          Number(mastertranssuspensearray[i].suspenseamount);
      }
      if (
        Number(Number(ActualSuspenseAmount).toFixed(2)) -
          Number(Number(newtransferedSuspense).toFixed(2)) ==
        0
      ) {
        settransferDifferenceZero(false);
      } else {
        settransferDifferenceZero(true);
      }
      let count = 0;
      for (let i = 0; i < mastertranssuspensearray.length; i++) {
        if (
          Number(mastertranssuspensearray[i].newsuspense) >
          Number(mastertranssuspensearray[i].epoamount)
        ) {
          count = 1;
        }
      }
      if (count == 0) {
        setsusbtndisabled(false);
      } else {
        setsusbtndisabled(true);
      }
      setdifferenceamount(
        Number(ActualSuspenseAmount) - Number(newtransferedSuspense)
      );
      settotalnewsuspenseamount(newtransferedSuspense);
    }

    setmasteraddsuspensearray(masteraddsuspensearray);
    setmasterusesuspensearray(masterusesuspensearray);
    setmastertranssuspensearray(mastertranssuspensearray);
  };

  const billingAddressValidation =(resp:any) =>{
    // debugger;
    let obj:any
    let newaddressLine1=""
    let newstate=""
    let zip5=""
    let arr:any=[]
    if(resp!=undefined)
    {
     arr.push(resp)
    let errorFlag=arr.some((x)=>x?.addressLine1 !=null&& x?.addressLine1 !="" && x?.state != null && x?.state != "" && isStringWithDigits(5, x?.zip))
    if(errorFlag==false)
    {
      let postalZip = defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.zip == null ? null :
      defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.zip?.replace(/-/g, "")
      let addressLine1=defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.addressLine1==null?null:
      defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.addressLine1
      let state=defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.state==null?null:
      defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.state
      if (
        (!isStringWithDigits(5, postalZip)) && (addressLine1 == undefined ||
        addressLine1 == null ||
        addressLine1 == "" )&&
        (state == undefined ||
        state == null ||
        state == "")
      ) {
        let JsonsessionStoreDetails: any =
          window.sessionStorage.getItem("storeDetails");
        let sessionStoreDetails: any;
        try {
          sessionStoreDetails = JSON.parse(atob(JsonsessionStoreDetails));
        } catch (e: any) {
        }
        if (!isStringWithDigits(5, postalZip)) {
          zip5 = sessionStoreDetails?.zip?.split("-")[0];
          // zip4 = sessionStoreDetails?.zip?.split("-")[1];
        }
        newaddressLine1=sessionStoreDetails?.addressLine1;
        newstate=sessionStoreDetails?.state
      }
    obj={
      addressLine1:newaddressLine1==""?addressLine1:newaddressLine1,
      addressLine2: resp.addressLine2==null?"":resp.addressLine2,
      city: resp.city,
      state: newstate==""?state:newstate,
      zip:zip5==""?postalZip:zip5
    }
    }else{
      obj=
      {
        addressLine1:resp?.addressLine1,
        addressLine2: resp?.addressLine2==null?"":resp?.addressLine2,
        city:resp?.city,
        state:resp?.state,
        zip:resp?.zip
      }
    }
  }
    return obj
  }

  const redirectionTopaymentsearch = () => {
    const request: any = searchVal;
    request.cancelClick = false;
    setsearchVal(request);
    history.push({
      pathname: `/payment1/paymentsearch`,
    });
  };
  const redirectionToCustomer = () => {
    history.push({
      pathname: `/customer/update/${customerId}/customer`,
    });
  };

  const redirectToCustScrollToPage = (alertTypeId: any) => {
    history.push({
      pathname: `/customer/update/${customerId}/customer?alertTypeId=${alertTypeId}`,
    });
  };

  const redirectionToAgreement = (agreementId: string, payGrid: any) => {
    if (
      payGrid?.otherIncomeType != "Credit Card Charge Back" ||
      payGrid?.otherIncomeType != "NSF Check"
    ) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${agreementId}`,
      });
    }
  };
  
  const risRedirectionToInventory = (InventoryNum:any, payGrid:any)=>{
    obj=[]
    setUpatedInventory([])
    if(payGrid?.Agreementtype=="Rental Item Sale")
    {
      history.push({
        pathname: `/inventory/${InventoryNum}/inventoryinformation`,
      });
    }

  }

  /*This function is used to set the amount to the state on blur of the amount box in the cash tab.
  Based on this amount and condiions of change and remaining amount the amount gets updated.*/
  const cashamounttextboxblur = (e: any) => {
    let InputTxt = e.target.value === "" ? "0.00" : e.target.value;
    FloatInputTxt = InputTxt;

    /** Code for adding tender array for CASH. The logic is
     * we splice the current tender array if it has "Cash" tender
     * and will re-add the same if the textbox value is greater than 0
     * If the textbox value is 0 we will remove the cash tender from
     * tender array.
     */

    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (tendertypeglobalarray[i].tendertype === "Cash") {
        tendertypeglobalarray.splice(i, 1);
        break;
      }
    }

    if (InputTxt !== "" && parseFloat(InputTxt) !== 0.0) {
      let InputTxtArr = InputTxt.split("");
      /**
       * Below code is to set 1000 separator for the amount entered.
       */
      if (InputTxtArr.includes(",") === true) {
        InputTxtArr = InputTxtArr.filter((element: any) => element !== ",");
        let TempString = "";
        for (let i = 0; i <= InputTxtArr.length - 1; i++) {
          TempString = TempString + InputTxtArr[i];
        }
        FloatInputTxt = parseFloat(TempString);
      } else {
        FloatInputTxt = parseFloat(InputTxt);
      }
      const FloatInputTxttofixed = fixedChange(FloatInputTxt);
      const tendertypedetails = {
        tendertype: "Cash",
        Amount: FloatInputTxttofixed,
      };

      tendertypeglobalarray.push(tendertypedetails);
    }

    settotalduetendertype(tendertypeglobalarray);

    /**
     * Code for adding trailing 0's after the decimal
     * based on the amount entered in the cash amount
     * textbox.
     */
    setaddcash(AddTrailingZerosToAmount(FloatInputTxt));

    /**
     * If input text is greater than the remaining amount due then
     *  set remaining amount due = 0
     *  set change as ( input text )  - remaining amount due
     *  enable the "Accept Payment" button
     *
     * If input text is less than or equal to remianing amount due then
     *  set remaining amount due = remaining amount due - input text
     *  set change = 0
     *  disable the "Accept Payment" button
     */
    if (InputTxt !== "" && InputTxt !== undefined) {
      InputTxt = String(InputTxt).replace(",", "");
      let objRemAmount = 0;
      let objChangeAmount = 0;
      let chargeCardAmount = 0;
      let swipeCardAmount = 0;
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Ending with")
        ) {
          chargeCardAmount =
            chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Swipe ending")
        ) {
          swipeCardAmount =
            swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }
      const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Add" && Number(e.newsuspense) > 0;
      });
      const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Used" && Number(e.newsuspense) > 0;
      });
      let addSusTotalAmount =
        addSuspenseAmount.length == 0
          ? 0.0
          : addSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let usedSusTotalAmount =
        useSuspenseAmount.length == 0
          ? 0.0
          : useSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
      const cardamount = Number(
        multipleCard.length == 0
          ? 0.0
          : multipleCard
              .map((bill) => bill.Amount)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
      );
      let sumOfOtherTenderAmount =
        Number(addcash) +
        Number(totalamountcheck) +
        Number(cardamount) +
        Number(availableCreds) +
        Number(swipeCardAmount) +
        Number(chargeCardAmount)-
        Number(donationAmountAdd) -
        Number(addSusTotalAmount) +
        Number(usedSusTotalAmount) + Number(totalSurrenderAmount) - Number(totalDepositAmount);
      let remAmtDueWithOutTenders =
        Number(Number(totaldue).toFixed(2)) -
        Number(sumOfOtherTenderAmount.toFixed(2));
      if (remAmtDueWithOutTenders < 0) {
        objRemAmount = 0.0;
        objChangeAmount = -1 * remAmtDueWithOutTenders;
      } else {
        objRemAmount = remAmtDueWithOutTenders;
        objChangeAmount = 0;
      }

      setremainingamountdue(objRemAmount.toFixed(2));

      setchange(objChangeAmount.toFixed(2));

      /***
       * If the difference between total due amount and sum of all tenders
       * and available credits is less than or equal to 0 then
       * enable the "Accept Payment" button else disable the
       * "Accept Payment" button.
       */
      let isChecked = 0;
      for (let i = 0; i < document.getElementsByName("grid").length; i++) {
        if (
          (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
          true
        ) {
          isChecked = 1;
        }
      }
      if (isChecked == 1 && remAmtDueWithOutTenders <= 0) {
        if (cardamount > 0 && cvvTextValue.length >= 3)
          setAcceptpaymentbuttonenable(false);
        else if (cardamount > 0 && swipeFlag == false)
          setAcceptpaymentbuttonenable(true);
        else setAcceptpaymentbuttonenable(false);
      } else {
        setAcceptpaymentbuttonenable(true);
      }
    }

    FormAcceptPaymentData(0, 0);

    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:"CashAmountTextOnBlur"
    }
    setUserTraceData([...userTraceData,traceObj])
  };

  const calculatetotalamountRetention = (filterRetentionAgr: any) => {
    if (paygridvalues.length > 0) {
      let TotalAmountDue = 0;
      let Totaltax = 0;
      for (let i = 0; i <= paygridvalues.length - 1; i++) {
        if (
          paygridvalues[i].AgreementNumber ==
          filterRetentionAgr[0].AgreementNumber
        ) {
          if (!paygridvalues[i]?.isExchanged) {
            TotalAmountDue = TotalAmountDue + paygridvalues[i].Amount;
            Totaltax = Totaltax + paygridvalues[i].Taxamount;
          }
        }
      }
      const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Add" && Number(e.newsuspense) > 0;
      });
      const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Used" && Number(e.newsuspense) > 0;
      });
      let addSusTotalAmount =
        addSuspenseAmount.length == 0
          ? 0.0
          : addSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let usedSusTotalAmount =
        useSuspenseAmount.length == 0
          ? 0.0
          : useSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      const resultSelecteAgre = paygridvalues.filter(function (e) {
        return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
      });
      let amount = 0;
      for (let i = 0; i < resultSelecteAgre.length; i++) {
        amount = amount + Number(resultSelecteAgre[i].Amount);
      }
      let objAvailCreditsAmt = 0;
      if (Number(amount) <= Number(paygridvalues[0].wlDeposit)) {
        objAvailCreditsAmt = amount;
        objAvailCreditsAmt =
          diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
        setAvailableCreds(objAvailCreditsAmt);
        if (diffStoreErrLbl && isRevenueRecogActivated) {
          setavailableCredsremaining([]);
        } else {
          setavailableCredsremaining([
            {
              balanceType: "WEBLEAD",
              accountBalance: Number(amount).toFixed(2),
            },
          ]);
        }
      } else if (Number(amount) > Number(paygridvalues[0].wlDeposit)) {
        objAvailCreditsAmt =
          Number(paygridvalues[0].COA) +
          Number(paygridvalues[0].wlDeposit) +
          Number(
            (Number(amount) - Number (paygridvalues[0].wlDeposit) 
            > Number(paygridvalues[0].ipSuspense) 
            ? Number(paygridvalues[0].ipSuspense) : 
            (Number(amount) - Number (paygridvalues[0].wlDeposit))));
        objAvailCreditsAmt =
          diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
        setAvailableCreds(objAvailCreditsAmt);
        if (diffStoreErrLbl && isRevenueRecogActivated) {
          setavailableCredsremaining([]);
        } else {
          setavailableCredsremaining([
            {
              balanceType: "WEBLEAD",
              accountBalance: Number(paygridvalues[0].wlDeposit).toFixed(2),
            },
            {
              balanceType: "COA",
              accountBalance: Number(paygridvalues[0].COA).toFixed(2),
            },
            {
              balanceType: "IP Suspence",
              accountBalance: Number((Number(amount) - Number (paygridvalues[0].wlDeposit) 
              > Number(paygridvalues[0].ipSuspense) 
              ? Number(paygridvalues[0].ipSuspense) : 
              (Number(amount) - Number (paygridvalues[0].wlDeposit)))).toFixed(2),
            },
          ]);
        }
      } else if (Number(amount) == 0) {
        objAvailCreditsAmt =
          Number(paygridvalues[0].COA) 
          // + Number(paygridvalues[0].ipSuspense);
        objAvailCreditsAmt =
          diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
        setAvailableCreds(objAvailCreditsAmt);
        if (diffStoreErrLbl && isRevenueRecogActivated) {
          setavailableCredsremaining([]);
        } else {
          setavailableCredsremaining([
            {
              balanceType: "COA",
              accountBalance: Number(paygridvalues[0].COA).toFixed(2),
            },
            // {
            //   balanceType: "IP Suspence",
            //   accountBalance: Number(paygridvalues[0].ipSuspense).toFixed(2),
            // },
          ]);
        }
      }
      let chargeCardAmount = 0;
      let swipeCardAmount = 0;
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Ending with")
        ) {
          chargeCardAmount =
            chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Swipe ending")
        ) {
          swipeCardAmount =
            swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }
      // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
      const CardAmount = Number(
        multipleCard.length == 0
          ? 0.0
          : multipleCard
              .map((bill) => bill.Amount)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
      );


      let objTenderAmount =
        Number(addcash) +
        Number(totalamountcheck) +
        Number(CardAmount) +
        Number(chargeCardAmount) +
        Number(swipeCardAmount);

      let objRemainingAmt =
        Number(TotalAmountDue.toFixed(2)) -
        Number(objAvailCreditsAmt.toFixed(2)) -
        Number(objTenderAmount.toFixed(2)) +
        Number(donationAmountAdd)+
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount);
      if (paymentOrigin === "Pay By Phone") {
        objRemainingAmt = objRemainingAmt + Number(convAmtWTax);
        TotalAmountDue = TotalAmountDue + Number(convAmtWTax);
      }

      settotaltax(Totaltax);
      if (defaultTotaldue == undefined || Number(defaultTotaldue) == 0) {
        let risAmount: any = 0;
        let otherAmount: any = 0;
        for (let i = 0; i < paygridvalues.length; i++) {
          if (paygridvalues[i].Agreementtype == "Rental Item Sale") {
            risAmount = risAmount + Number(paygridvalues[i].Amount);
          }
          //  else
          // if(paygridvalues[i].Agreementtype =="NSF Check")
          // {
          //   otherAmount=otherAmount + Number(paygridvalues[i].Amount)
          // }else
          // if(paygridvalues[i].Agreementtype=="Credit Card Charge Back")
          // {
          //   otherAmount=otherAmount + Number(paygridvalues[i].Amount)
          // }
        }
        let NewTotalAmountDue =
          Number(TotalAmountDue) - (Number(risAmount) + Number(otherAmount));

        setdefaultTotaldue(NewTotalAmountDue);
      }
      //Rental Item sale

      // if (defaultTotaldue != undefined || Number(defaultTotaldue) != 0) {
      //   defaultTotaldue = TotalAmountDue;
      // }
      // setdefaultTotaldue(defaultTotaldue);
      settotaldue(TotalAmountDue);
      if (objRemainingAmt <= 0.0) {
        setremainingamountdue(0.0);
        setRemaniningAmtStatic(0.0);
        setAcceptpaymentbuttonenable(false);
        objRemainingAmt === 0
          ? setchange(0.0)
          : setchange(Math.abs(objRemainingAmt));
      } else {
        setremainingamountdue(objRemainingAmt);
        setRemaniningAmtStatic(objRemainingAmt);
        setAcceptpaymentbuttonenable(true);
        setchange(0.0);
      }
      setcoaamt(paygridvalues[0].COA);
      setwldepositamount(paygridvalues[0].wlDeposit);
      setipsuspense(paygridvalues[0].ipSuspense);
    }
  };

  /*This method is used to calculate the total amount if select all check box is checked */
  const calculatetotalamount = () => {
    if (paygridvalues.length > 0) {
      let TotalAmountDue = 0;
      let Totaltax = 0;
      for (let i = 0; i <= paygridvalues.length - 1; i++) {
        if (!paygridvalues[i]?.isExchanged) {
          TotalAmountDue = TotalAmountDue + paygridvalues[i].Amount;
          Totaltax = Totaltax + paygridvalues[i].Taxamount;
        }
      }
      const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Add" && Number(e.newsuspense) > 0;
      });
      const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Used" && Number(e.newsuspense) > 0;
      });
      let addSusTotalAmount =
        addSuspenseAmount.length == 0
          ? 0.0
          : addSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let usedSusTotalAmount =
        useSuspenseAmount.length == 0
          ? 0.0
          : useSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      const resultSelecteAgre = paygridvalues.filter(function (e) {
        return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
      });
      let amount = 0;
      for (let i = 0; i < resultSelecteAgre.length; i++) {
        amount = amount + Number(resultSelecteAgre[i].Amount);
      }
      let objAvailCreditsAmt = 0;
      if (Number(amount) <= Number(paygridvalues[0].wlDeposit)) {
        objAvailCreditsAmt = amount;
        objAvailCreditsAmt =
          diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
        setAvailableCreds(objAvailCreditsAmt);
        if (diffStoreErrLbl && isRevenueRecogActivated) {
          setavailableCredsremaining([]);
        } else {
          setavailableCredsremaining([
            {
              balanceType: "WEBLEAD",
              accountBalance: Number(amount).toFixed(2),
            },
          ]);
        }
      } else if (Number(amount) > Number(paygridvalues[0].wlDeposit)) {
        objAvailCreditsAmt =
          Number(paygridvalues[0].COA) +
          Number(paygridvalues[0].wlDeposit) +
          Number(
            (Number(amount) - Number (paygridvalues[0].wlDeposit) 
            > Number(paygridvalues[0].ipSuspense) 
            ? Number(paygridvalues[0].ipSuspense) : 
            (Number(amount) - Number (paygridvalues[0].wlDeposit))));
        objAvailCreditsAmt =
          diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
        setAvailableCreds(objAvailCreditsAmt);
        if (diffStoreErrLbl && isRevenueRecogActivated) {
          setavailableCredsremaining([]);
        } else {
          setavailableCredsremaining([
            {
              balanceType: "WEBLEAD",
              accountBalance: Number(paygridvalues[0].wlDeposit).toFixed(2),
            },
            {
              balanceType: "COA",
              accountBalance: Number(paygridvalues[0].COA).toFixed(2),
            },
            {
              balanceType: "IP Suspence",
              accountBalance: Number((Number(amount) - Number (paygridvalues[0].wlDeposit) 
              > Number(paygridvalues[0].ipSuspense) 
              ? Number(paygridvalues[0].ipSuspense) : 
              (Number(amount) - Number (paygridvalues[0].wlDeposit)))).toFixed(2),
            },
          ]);
        }
      } else if (Number(amount) == 0) {
        objAvailCreditsAmt =
          Number(paygridvalues[0].COA) 
          // + Number(paygridvalues[0].ipSuspense);
        objAvailCreditsAmt =
          diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
        setAvailableCreds(objAvailCreditsAmt);
        if (diffStoreErrLbl && isRevenueRecogActivated) {
          setavailableCredsremaining([]);
        } else {
          setavailableCredsremaining([
            {
              balanceType: "COA",
              accountBalance: Number(paygridvalues[0].COA).toFixed(2),
            },
            // {
            //   balanceType: "IP Suspence",
            //   accountBalance: Number(paygridvalues[0].ipSuspense).toFixed(2),
            // },
          ]);
        }
      }
      let chargeCardAmount = 0;
      let swipeCardAmount = 0;
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Ending with")
        ) {
          chargeCardAmount =
            chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Swipe ending")
        ) {
          swipeCardAmount =
            swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }
      // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
      const CardAmount = Number(
        multipleCard.length == 0
          ? 0.0
          : multipleCard
              .map((bill) => bill.Amount)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
      );

      let objTenderAmount =
        Number(addcash) +
        Number(totalamountcheck) +
        Number(CardAmount) +
        Number(chargeCardAmount) +
        Number(swipeCardAmount);

      let objRemainingAmt =
        Number(TotalAmountDue.toFixed(2)) -
        Number(objAvailCreditsAmt.toFixed(2)) -
        Number(objTenderAmount.toFixed(2)) +
        Number(donationAmountAdd)+
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount);
      if (paymentOrigin === "Pay By Phone") {
        objRemainingAmt = objRemainingAmt + Number(convAmtWTax);
        TotalAmountDue = TotalAmountDue + Number(convAmtWTax);
      }

      settotaltax(Totaltax);
      if (defaultTotaldue == undefined || Number(defaultTotaldue) == 0) {
        let risAmount: any = 0;
        let otherAmount: any = 0;
        for (let i = 0; i < paygridvalues.length; i++) {
          if (paygridvalues[i].Agreementtype == "Rental Item Sale") {
            risAmount = risAmount + Number(paygridvalues[i].Amount);
          }
          // else
          // if(paygridvalues[i].Agreementtype =="NSF Check")
          // {
          //   otherAmount=otherAmount + Number(paygridvalues[i].Amount)
          // }else
          // if(paygridvalues[i].Agreementtype=="Credit Card Charge Back")
          // {
          //   otherAmount=otherAmount + Number(paygridvalues[i].Amount)
          // }
        }
        let NewTotalAmountDue =
          Number(TotalAmountDue) - (Number(risAmount) + Number(otherAmount));

        setdefaultTotaldue(NewTotalAmountDue);
      }
      //Rental Item sale

      // if(defaultTotaldue != undefined || Number(defaultTotaldue) != 0)
      // {
      //    defaultTotaldue=TotalAmountDue

      // }
      // setdefaultTotaldue(defaultTotaldue);
      settotaldue(TotalAmountDue);
      if (objRemainingAmt <= 0.0) {
        setremainingamountdue(0.0);
        setRemaniningAmtStatic(0.0);
        setAcceptpaymentbuttonenable(false);
        objRemainingAmt === 0
          ? setchange(0.0)
          : setchange(Math.abs(objRemainingAmt));
      } else {
        setremainingamountdue(objRemainingAmt);
        setRemaniningAmtStatic(objRemainingAmt);
        setAcceptpaymentbuttonenable(true);
        setchange(0.0);
      }
      setcoaamt(paygridvalues[0].COA);
      setwldepositamount(paygridvalues[0].wlDeposit);
      setipsuspense(paygridvalues[0].ipSuspense);
    }
  };

  const calculatetotalamountPartial = (choosenAgreements: any) => {
    if (choosenAgreements.length > 0) {
      let TotalAmountDue = 0;
      let Totaltax = 0;
      for (let i = 0; i <= choosenAgreements.length - 1; i++) {
        if (!choosenAgreements[i]?.isExchanged) {
          TotalAmountDue = TotalAmountDue + choosenAgreements[i].Amount;
          Totaltax = Totaltax + choosenAgreements[i].Taxamount;
        }
      }
      const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Add" && Number(e.newsuspense) > 0;
      });
      const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Used" && Number(e.newsuspense) > 0;
      });
      let addSusTotalAmount =
        addSuspenseAmount.length == 0
          ? 0.0
          : addSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let usedSusTotalAmount =
        useSuspenseAmount.length == 0
          ? 0.0
          : useSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      const resultSelecteAgre = choosenAgreements.filter(function (e) {
        return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
      });
      let amount = 0;
      for (let i = 0; i < resultSelecteAgre.length; i++) {
        amount = amount + Number(resultSelecteAgre[i].Amount);
      }
      let objAvailCreditsAmt = 0;
      if (Number(amount) <= Number(choosenAgreements[0].wlDeposit)) {
        objAvailCreditsAmt = amount;
        objAvailCreditsAmt =
          diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
        setAvailableCreds(objAvailCreditsAmt);
        if (diffStoreErrLbl && isRevenueRecogActivated) {
          setavailableCredsremaining([]);
        } else {
          setavailableCredsremaining([
            {
              balanceType: "WEBLEAD",
              accountBalance: Number(amount).toFixed(2),
            },
          ]);
        }
      } else if (Number(amount) > Number(choosenAgreements[0].wlDeposit)) {
        objAvailCreditsAmt =
          Number(choosenAgreements[0].COA) +
          Number(choosenAgreements[0].wlDeposit) +
          Number(
            (Number(amount) - Number (choosenAgreements[0].wlDeposit) 
            > Number(choosenAgreements[0].ipSuspense) 
            ? Number(choosenAgreements[0].ipSuspense) : 
            (Number(amount) - Number (choosenAgreements[0].wlDeposit))));
        objAvailCreditsAmt =
          diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
        setAvailableCreds(objAvailCreditsAmt);
        if (diffStoreErrLbl && isRevenueRecogActivated) {
          setavailableCredsremaining([]);
        } else {
          setavailableCredsremaining([
            {
              balanceType: "WEBLEAD",
              accountBalance: Number(choosenAgreements[0].wlDeposit).toFixed(2),
            },
            {
              balanceType: "COA",
              accountBalance: Number(choosenAgreements[0].COA).toFixed(2),
            },
            {
              balanceType: "IP Suspence",
              accountBalance: Number((Number(amount) - Number (choosenAgreements[0].wlDeposit) 
              > Number(choosenAgreements[0].ipSuspense) 
              ? Number(choosenAgreements[0].ipSuspense) : 
              (Number(amount) - Number (choosenAgreements[0].wlDeposit)))).toFixed(2),
            },
          ]);
        }
      } else if (Number(amount) == 0) {
        objAvailCreditsAmt =
          Number(choosenAgreements[0].COA) 
          // +
          // Number(choosenAgreements[0].ipSuspense);
        objAvailCreditsAmt =
          diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
        setAvailableCreds(objAvailCreditsAmt);
        if (diffStoreErrLbl && isRevenueRecogActivated) {
          setavailableCredsremaining([]);
        } else {
          setavailableCredsremaining([
            {
              balanceType: "COA",
              accountBalance: Number(choosenAgreements[0].COA).toFixed(2),
            },
            // {
            //   balanceType: "IP Suspence",
            //   accountBalance: Number(choosenAgreements[0].ipSuspense).toFixed(
            //     2
            //   ),
            // },
          ]);
        }
      }
      let chargeCardAmount = 0;
      let swipeCardAmount = 0;
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Ending with")
        ) {
          chargeCardAmount =
            chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Swipe ending")
        ) {
          swipeCardAmount =
            swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }
      // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
      const CardAmount = Number(
        multipleCard.length == 0
          ? 0.0
          : multipleCard
              .map((bill) => bill.Amount)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
      );
      let objTenderAmount =
        Number(addcash) +
        Number(totalamountcheck) +
        Number(CardAmount) +
        Number(chargeCardAmount) +
        Number(swipeCardAmount);

      let objRemainingAmt =
        Number(TotalAmountDue.toFixed(2)) -
        Number(objAvailCreditsAmt.toFixed(2)) -
        Number(objTenderAmount.toFixed(2)) +
        Number(donationAmountAdd)+
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount);
      if (paymentOrigin === "Pay By Phone") {
        objRemainingAmt = objRemainingAmt + Number(convAmtWTax);
        TotalAmountDue = TotalAmountDue + Number(convAmtWTax);
      }

      settotaltax(Totaltax);
      if (defaultTotaldue == undefined || Number(defaultTotaldue) == 0) {
        let risAmount: any = 0;
        let otherAmount: any = 0;
        for (let i = 0; i < paygridvalues.length; i++) {
          if (paygridvalues[i].Agreementtype == "Rental Item Sale") {
            risAmount = risAmount + Number(paygridvalues[i].Amount);
          }
          //  else
          // if(paygridvalues[i].Agreementtype =="NSF Check")
          // {
          //   otherAmount=otherAmount + Number(paygridvalues[i].Amount)
          // }else
          // if(paygridvalues[i].Agreementtype=="Credit Card Charge Back")
          // {
          //   otherAmount=otherAmount + Number(paygridvalues[i].Amount)
          // }
        }
        let NewTotalAmountDue =
          Number(TotalAmountDue) - (Number(risAmount) + Number(otherAmount));

        setdefaultTotaldue(NewTotalAmountDue);
      }
      settotaldue(TotalAmountDue);
      if (objRemainingAmt <= 0.0) {
        setremainingamountdue(0.0);
        setRemaniningAmtStatic(0.0);
        setAcceptpaymentbuttonenable(false);
        objRemainingAmt === 0
          ? setchange(0.0)
          : setchange(Math.abs(objRemainingAmt));
      } else {
        setremainingamountdue(objRemainingAmt);
        setRemaniningAmtStatic(objRemainingAmt);
        setAcceptpaymentbuttonenable(true);
        setchange(0.0);
      }
      setcoaamt(choosenAgreements[0].COA);
      setwldepositamount(choosenAgreements[0].wlDeposit);
      setipsuspense(choosenAgreements[0].ipSuspense);
    } else {
      settotaltax(0);
    }
  };

  const Dynamicstategen = (e: any) => {};
  /*This method is used to calculate the total amount on unchecking all the check boxes   */
  const subtractamount = () => {
    if (paygridvalues.length > 0) {
      let TotalAmountDue = totaldue;
      let Totaltax = totaltax;
      let remAmt = 0;
      setaddcash("0.00");
      settotalamountcheck(0.0);
      for (let i = 0; i <= paygridvalues.length - 1; i++) {
        if (!paygridvalues[i]?.isExchanged) {
          TotalAmountDue = Number(TotalAmountDue) - paygridvalues[i].Amount;
          Totaltax = Totaltax - paygridvalues[i].Taxamount;
        }
      }
      const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Add" && Number(e.newsuspense) > 0;
      });
      const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Used" && Number(e.newsuspense) > 0;
      });
      let addSusTotalAmount =
        addSuspenseAmount.length == 0
          ? 0.0
          : addSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let usedSusTotalAmount =
        useSuspenseAmount.length == 0
          ? 0.0
          : useSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      remAmt =
        Number(TotalAmountDue) -
        Number(availableCreds)+
        Number(donationAmountAdd) +
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount);
      remAmt = Number(remAmt.toFixed(2));
      totaltax=Totaltax
      settotaltax(totaltax);
      totaldue=TotalAmountDue
      settotaldue(totaldue);
      if (remAmt <= 0.0) {
        setremainingamountdue(0.0);
        remAmt === 0 ? setchange(0.0) : setchange(Math.abs(remAmt));
      } else {
        setremainingamountdue(remAmt);
        setchange(0.0);
      }
      setRemaniningAmtStatic(remAmt);
    }
  };
  /*This function is used to set the check type whether it is check or money order*/
  const setchecktype = (e: any) => {
    if (e.target.value !== "0") {
      setadddata({ ...adddata, checktype: e.target.value });
      setType(false);
    } else {
      setadddata({ ...adddata, checktype: e.target.value });
      setType(true);
    }
  };

  /**
   * This method is used to set or increase the cash amount
   * based on the cash amount button click.
   * @param e - Pass the amount given in the button.
   */
  const setcashamount = (e: number) => {
    let objAmt = Number(addcash) + e;
    setaddcash(objAmt.toFixed(2));
    setcashamountcheckbox(objAmt);
    let objTenderArr = tendertypeglobalarray.filter(
      (x) => x.tendertype === "Cash"
    );
    if (objTenderArr.length > 0) {
      objTenderArr[0].Amount = objAmt.toFixed(2);
    } else {
      const tendertypedetails = {
        tendertype: "Cash",
        Amount: objAmt.toFixed(2),
      };
      tendertypeglobalarray.push(tendertypedetails);
    }
    settotalduetendertype(tendertypeglobalarray);
    let chargeCardAmount = 0;
    let swipeCardAmount = 0;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Ending with")
      ) {
        chargeCardAmount =
          chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Swipe ending")
      ) {
        swipeCardAmount =
          swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);

    const CardAmount = Number(
      multipleCard.length == 0
        ? 0.0
        : multipleCard
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
    );

    let objTenderAmount =
      objAmt +
      Number(totalamountcheck) +
      Number(CardAmount) +
      Number(chargeCardAmount) +
      Number(swipeCardAmount);
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    let objChgAmt = Number(
      Number(
        Number(totaldue) -
          objTenderAmount -
          Number(availableCreds)+
          Number(donationAmountAdd) +
          Number(addSusTotalAmount) -
          Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
      ).toFixed(2)
    );
    if (objChgAmt > 0) {
      setremainingamountdue(objChgAmt);
    } else {
      setchange(Math.abs(objChgAmt).toFixed(2));
      setremainingamountdue(0.0);
      if (CardAmount > 0 && cvvTextValue.length >= 3)
        setAcceptpaymentbuttonenable(false);
      else if (CardAmount > 0 && swipeFlag == false)
        setAcceptpaymentbuttonenable(true);
      else setAcceptpaymentbuttonenable(false);
    }
    FormAcceptPaymentData(Number(objAmt), 0);

    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:"Cash Tiles"
    }
    setUserTraceData([...userTraceData,traceObj])
  };

  useEffect(() => {
    if (checking === 1) {
      BindGridcheck();
      /*Bindagreementamount();*/
    }
    setchecking("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking]);

  useEffect(() => {
    if (totalsuspensemaster?.length > 0) {
      const addSuspenseAmount = totalsuspensemaster?.filter(function (e) {
        return e.sustype == "Add" && Number(e.newsuspense) > 0;
      });

      const useSuspenseAmount = totalsuspensemaster?.filter(function (e) {
        return e.sustype == "Used" && Number(e.newsuspense) > 0;
      });
      let addSusTotalAmount =
        addSuspenseAmount?.length == 0
          ? 0.0
          : addSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let usedSusTotalAmount =
        useSuspenseAmount?.length == 0
          ? 0.0
          : useSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

      setTotalDueSusAddAmount(addSusTotalAmount);
      setTotalDueSusUseAmount(usedSusTotalAmount);
    } else {
      setTotalDueSusAddAmount(0.0);
      setTotalDueSusUseAmount(0.0);
    }
  }, [totalsuspensemaster]);

  //Onclick of change radio button starts here
  const RadiobtnChange = (e: any) => {
    if (e.target.value === "credit") {
      setradioBtn("1");
      setchangeRadio(false);
      setCreditRadio(true);
    } else if (e.target.value === "change") {
      setradioBtn("0");
      setCreditRadio(false);
      setchangeRadio(true);
    }
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:e.target.value=="credit"?"CreditRadioButton":"ChangeRadioButton"
    }
    setUserTraceData([...userTraceData,traceObj])
  };
  const verbalcheckbox = (e: any) => {
    if (e.target.checked === true) {
      setvarbalecheckbox(true);
      setsendtext(false);
    } else {
      setvarbalecheckbox(false);
      setsendtext(true);
    }
  };
  const Gridbindingunenrollautopay = () => (
    <>
      {unenrollautopayfilterdata != undefined &&
      unenrollautopayfilterdata.length > 0
        ? unenrollautopayfilterdata.map((value, index: any) => {
            return (
              <RACTableRow key={index} className={classes.tablerowtheme}>
                <RACTableCell>
                  {/* <input className="form-check-input" type="checkbox" name="popupgrid" onChange={(e) => { unCheckandChecking(index) }} checked={updatedarray[index]} value={index} id={value.Agreementid + ',' + 'id'} /> */}
                  <RACCheckBox
                    //className={`${classes.formcheckinput} ${classes.checkboxStyle}`}
                    name="popupgrid"
                    onChange={() => unCheckandChecking(index)}
                    checked={updatedarray[index]}
                    value={index}
                    id={value.Agreementid + "," + "id"}
                  />
                </RACTableCell>
                <RACTableCell>{value.Agreementdesc}</RACTableCell>
                <RACTableCell>${value.Amount.toFixed(2)}</RACTableCell>
                <RACTableCell>{value.Schedule}</RACTableCell>
                <RACTableCell>{value.Nextduedate}</RACTableCell>
              </RACTableRow>
            );
          })
        : null}
    </>
  );

  /*These functions are used to set the value from the textbox to the state. Each function is called based on the onchange event in the add check popup or add moneyorder popup*/
  const setcheckno = (e: any) => {
    setadddata({
      ...adddata,
      checkno: e.target.value.replace(/\D/, ""),
      Type: e.target.name,
    });
    setchecknovalid(false);
  };
  const setcheckwrittento = (e: any) => {
    setadddata({
      ...adddata,
      Written_To: e.target.value.replace(/[^A-Za-z']/gi, ""),
    });
    setwrittento(false);
  };
  const setcheckwrittentby = (e: any) => {
    setadddata({
      ...adddata,
      Written_by: e.target.value.replace(/[^A-Za-z']/gi, ""),
    });
    setwritenby(false);
  };
  const setcheckamount = (e: any) => {
    if (parseFloat(e) < 100000.0 || e === undefined) {
      setadddata({ ...adddata, Amount: e });
      setAmount(false);
    }
  };
  const cashamounttextbox = (inputText: any) => {
    let chargeCardAmount = 0;
    let swipeCardAmount = 0;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Ending with")
      ) {
        chargeCardAmount =
          chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Swipe ending")
      ) {
        swipeCardAmount =
          swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
    const cardamount = Number(
      multipleCard.length == 0
        ? 0.0
        : multipleCard
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
    );
    inputText = String(inputText).replace(",", "");
    if (validateCashInput(inputText)) {
      setaddcash(inputText);
      if (
        inputText != "" &&
        inputText != undefined &&
        parseFloat(inputText) < 100000.0
      ) {
        setcashamountcheckbox(inputText);
        const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
          return e.sustype == "Add" && Number(e.newsuspense) > 0;
        });
        const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
          return e.sustype == "Used" && Number(e.newsuspense) > 0;
        });
        let addSusTotalAmount =
          addSuspenseAmount.length == 0
            ? 0.0
            : addSuspenseAmount
                .map((bill) => bill.newsuspense)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
        let usedSusTotalAmount =
          useSuspenseAmount.length == 0
            ? 0.0
            : useSuspenseAmount
                .map((bill) => bill.newsuspense)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
        let remAmtDue =
          Number(totaldue) -
          Number(availableCreds) -
          Number(totalamountcheck) -
          cardamount -
          Number(chargeCardAmount) -
          Number(swipeCardAmount)+
          Number(donationAmountAdd) +
          Number(addSusTotalAmount) -
          Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount);
        setremainingamountdue(AddTrailingZerosToAmount(remAmtDue));
      }
    }
  };

  /*This accrodionclick function is used to hide and visible the data that needs to rendered.*/
  const Accordionclick = (index: any) => {
    if (index === gridhide) {
      setgridhide("");
    } else {
      setgridhide(index);
    }
  };

  /*This onsave function is used to bind the data generated inside the grid of check or moneyorder.Here if the e.target.value==1 then it represents the check else the moneyorder*/
  const save = (e: any) => {
    /* eslint-disable no-debugger */
    setCardrequire({ ...Cardrequire, txt2paybtn: true });
    settotalcontrol(0);
    setcancelindication(0);
    const arr = {
      check_type: adddata.checktype,
      Type_info: adddata.Type,
      check_no: adddata.checkno,
      Written_too: adddata.Written_To,
      Written_by: adddata.Written_by,
      Amount_rac: adddata.Amount,
    };
    if (checkmonvar === "1") {
      let flag1 = 0;
      if (adddata.checktype === "0") {
        flag1 = 1;
        setType(true);
      }
      if (adddata.checkno === "") {
        flag1 = 1;
        setchecknovalid(true);
      }
      if (adddata.Written_To === "") {
        flag1 = 1;
        setwrittento(true);
      }
      if (adddata.Written_by === "") {
        flag1 = 1;
        setwritenby(true);
      }
      if (
        adddata.Amount === "" ||
        Number(adddata.Amount) <= 0.0 ||
        Number(adddata.Amount) <= 0 ||
        Number.isNaN(Number(adddata.Amount))
      ) {
        flag1 = 1;
        setAmount(true);
      }
      if (
        adddata.checktype !== "0" &&
        adddata.Written_To !== "" &&
        adddata.Written_by !== "" &&
        adddata.Amount !== "" &&
        adddata.checkno !== "" &&
        flag1 === 0
      ) {
        if (
          adddata.checktype === "1" &&
          Number(Number(adddata.Amount).toFixed(2)) > Number(Number(remainingamountdue).toFixed(2))
        ) {
          setpersonalcheckalert(true);
        } else if (
          adddata.checktype === "2" &&
          Number(Number(adddata.Amount).toFixed(2)) > Number(Number(remainingamountdue).toFixed(2))
        ) {
          settravellercheckalert(true);
        } else {
          setaddcheck(false);
          setaddmoneyorder(false);
          addrowarray.push(arr);
          setaddataanother(addrowarray);
          const reduceremaingamount =
            Number(Number(remainingamountdue).toFixed(2)) -
            Number(Number(adddata.Amount).toFixed(2));
          let chargeCardAmount = 0;
          let swipeCardAmount = 0;
          for (let i = 0; i < tendertypeglobalarray.length; i++) {
            if (
              tendertypeglobalarray[i].tendertype
                .split(" - ")[0]
                .includes("Card Ending with")
            ) {
              chargeCardAmount =
                chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
            }
          }
          for (let i = 0; i < tendertypeglobalarray.length; i++) {
            if (
              tendertypeglobalarray[i].tendertype
                .split(" - ")[0]
                .includes("Card Swipe ending")
            ) {
              swipeCardAmount =
                swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
            }
          }
          // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
          let cardamount = Number(
            multipleCard.length == 0
              ? 0.0
              : multipleCard
                  .map((bill) => bill.Amount)
                  .reduce((acc, bill) =>
                    (Number(bill) + Number(acc)).toFixed(2)
                  )
          );
          cardamount =
            Number(cardamount) +
            Number(chargeCardAmount) +
            Number(swipeCardAmount);
          if (reduceremaingamount >= 0) {
            setremainingamountdue(reduceremaingamount);
            if (reduceremaingamount === 0) {
              if (cardamount > 0 && cvvTextValue.length >= 3) {
                setAcceptpaymentbuttonenable(false);
              } else if (cardamount > 0 && swipeFlag == false)
                setAcceptpaymentbuttonenable(true);
              else {
                setAcceptpaymentbuttonenable(false);
              }
            }
          } else {
            const addchange =
              parseFloat(change) +
              parseFloat(reduceremaingamount.toString()) * -1;
            setchange(addchange);
            setremainingamountdue(0);
            let chargeCardAmount = 0;
            let swipeCardAmount = 0;
            for (let i = 0; i < tendertypeglobalarray.length; i++) {
              if (
                tendertypeglobalarray[i].tendertype
                  .split(" - ")[0]
                  .includes("Card Ending with")
              ) {
                chargeCardAmount =
                  chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
              }
            }
            for (let i = 0; i < tendertypeglobalarray.length; i++) {
              if (
                tendertypeglobalarray[i].tendertype
                  .split(" - ")[0]
                  .includes("Card Swipe ending")
              ) {
                swipeCardAmount =
                  swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
              }
            }
            // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
            let cardamount = Number(
              multipleCard.length == 0
                ? 0.0
                : multipleCard
                    .map((bill) => bill.Amount)
                    .reduce((acc, bill) =>
                      (Number(bill) + Number(acc)).toFixed(2)
                    )
            );
            cardamount =
              Number(cardamount) +
              Number(chargeCardAmount) +
              Number(swipeCardAmount);
            if (
              cardamount > 0 &&
              cvvTextValue.length < 3 &&
              swipeFlag == false
            ) {
              setAcceptpaymentbuttonenable(true);
            } else {
              setAcceptpaymentbuttonenable(false);
            }
          }
        }
      }
     
    }
    if (checkmonvar === "2") {
      let flag = 0;
      if (adddata.checkno === "") {
        setchecknovalid(true);
        flag = 1;
      }

      if (adddata.Written_To === "") {
        flag = 1;
        setwrittento(true);
      }
      if (adddata.Written_by === "") {
        flag = 1;
        setwritenby(true);
      }
      if (
        adddata.Amount === "" ||
        Number(adddata.Amount) <= 0.0 ||
        Number(adddata.Amount) <= 0 ||
        Number.isNaN(Number(adddata.Amount))
      ) {
        flag = 1;
        setAmount(true);
      }
      if (
        adddata.Written_To !== "" &&
        adddata.Written_by !== "" &&
        adddata.Amount !== "" &&
        adddata.checkno !== "" &&
        flag === 0
      ) {
        if (Number(Number(adddata.Amount).toFixed(2)) > Number(Number(remainingamountdue).toFixed(2))) {
          setoverpayMoneyord(true);
        } else {
          setaddmoneyorder(false);
          setaddcheck(false);
          addrowarray.push(arr);
          setaddataanother(addrowarray);
          const reduceremaingamount =
            Number(Number(remainingamountdue).toFixed(2)) -
            Number(Number(adddata.Amount).toFixed(2));

          if (reduceremaingamount >= 0) {
            setremainingamountdue(reduceremaingamount);

            if (reduceremaingamount === 0) {
              let chargeCardAmount = 0;
              let swipeCardAmount = 0;
              for (let i = 0; i < tendertypeglobalarray.length; i++) {
                if (
                  tendertypeglobalarray[i].tendertype
                    .split(" - ")[0]
                    .includes("Card Ending with")
                ) {
                  chargeCardAmount =
                    chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
                }
              }
              for (let i = 0; i < tendertypeglobalarray.length; i++) {
                if (
                  tendertypeglobalarray[i].tendertype
                    .split(" - ")[0]
                    .includes("Card Swipe ending")
                ) {
                  swipeCardAmount =
                    swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
                }
              }
              // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
              let cardamount = Number(
                multipleCard.length == 0
                  ? 0.0
                  : multipleCard
                      .map((bill) => bill.Amount)
                      .reduce((acc, bill) =>
                        (Number(bill) + Number(acc)).toFixed(2)
                      )
              );
              cardamount =
                Number(cardamount) +
                Number(chargeCardAmount) +
                Number(swipeCardAmount);
              if (
                cardamount > 0 &&
                cvvTextValue.length < 3 &&
                swipeFlag == false
              ) {
                setAcceptpaymentbuttonenable(true);
              } else {
                setAcceptpaymentbuttonenable(false);
              }
            }
          } else {
            const addchange = change + reduceremaingamount * -1;
            setchange(addchange);
            setremainingamountdue(0);
            let chargeCardAmount = 0;
            let swipeCardAmount = 0;
            for (let i = 0; i < tendertypeglobalarray.length; i++) {
              if (
                tendertypeglobalarray[i].tendertype
                  .split(" - ")[0]
                  .includes("Card Ending with")
              ) {
                chargeCardAmount =
                  chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
              }
            }
            for (let i = 0; i < tendertypeglobalarray.length; i++) {
              if (
                tendertypeglobalarray[i].tendertype
                  .split(" - ")[0]
                  .includes("Card Swipe ending")
              ) {
                swipeCardAmount =
                  swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
              }
            }
            // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
            let cardamount = Number(
              multipleCard.length == 0
                ? 0.0
                : multipleCard
                    .map((bill) => bill.Amount)
                    .reduce((acc, bill) =>
                      (Number(bill) + Number(acc)).toFixed(2)
                    )
            );
            cardamount =
              Number(cardamount) +
              Number(chargeCardAmount) +
              Number(swipeCardAmount);
            if (
              cardamount > 0 &&
              cvvTextValue.length < 3 &&
              swipeFlag == false
            ) {
              setAcceptpaymentbuttonenable(true);
            } else {
              setAcceptpaymentbuttonenable(false);
            }
          }
        }
      }
    }

    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type: checkmonvar=="1"?"Add Check":"Add Money Order"
    }
    setUserTraceData([...userTraceData,traceObj])
  };

  const useuspensecancelbtn = () => {
    setsuspensePopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
    setusesuspense(false);
  };
  const addsuspensecancelbtn = () => {
    setsuspensePopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
    setaddsuspense(false);
  };
  let addfilterzero: any[];
  const addSuspenseBtn = (e: any) => {
    const addfilterzero = masteraddsuspensearray.filter(function (e) {
      return e.sustype != "type" && Number(e.newsuspense) > 0;
    });
    const usefilterzero = masterusesuspensearray.filter(function (e) {
      return e.sustype != "type" && Number(e.newsuspense) > 0;
    });
    if (totalsuspensemaster)
      settotalsuspensemaster([...addfilterzero, ...usefilterzero]);


    for (let i = 0; i < addfilterzero.length; i++) {
      let index = paygridvalues.findIndex(
        (x) =>
          String(x.Agreementid) === String(addfilterzero[i].agreementnumber)
      );
      paygridvalues[index].suspenseAdd = Number(addfilterzero[i].newsuspense);
      // paygridvalues[index].Amount=Number(paygridvalues[index].actualAmount)+Number(addfilterzero[i].newsuspense)
      let receiptObj = ReceiptCategoriesRequestParam.filter(
        (e) => e.agreementId == Number(addfilterzero[i].agreementnumber)
      );
      let receiptObjaccept = acceptPaymentParam.filter(
        (e) => e.agreementId == Number(addfilterzero[i].agreementnumber)
      );
      if (receiptObj[0] != undefined) {
        receiptObj[0].suspenseAdd = Number(addfilterzero[i].newsuspense);
      }
      if (
        String(addfilterzero[i].agreementnumber) ==
        String(receiptObjaccept[0].agreementId)
      ) {
        acceptPaymentParam[i].addTransferSusFlag = 1;
      }
    }

    setReceiptCategory(ReceiptCategoriesRequestParam);

    let totalsusamount: number;
    totalsusamount = 0;

    for (let i = 0; i < addfilterzero.length; i++) {
      totalsusamount = totalsusamount + Number(addfilterzero[i].newsuspense);
    }

    setsuspensePopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
    setaddsuspense(false);

    let tenderedAmount = 0;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      tenderedAmount = tenderedAmount + Number(tendertypeglobalarray[i].Amount);
    }
    let resAmount = 0.0;
    //  let resTaxamount=0.00
    for (let i = 0; i < checkedAgr.length; i++) {
      resAmount = Number(checkedAgr[i].Amount) + Number(resAmount);
      // resTaxamount=Number(checkedAgr[i].Taxamount)
    }
    //SuspenseTender-1
    // debugger;
    const addSuspenseAmount = addfilterzero.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = usefilterzero.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    let resTotalAmount = 0.0;
    if (paymentOrigin == "Pay By Phone") {
      resAmount = Number(resAmount) + Number(convAmtWTax);
      // resTaxamount=Number(resTaxamount)+Number(convtax)
    }
    resAmount = Number(resAmount) - Number(carryRentAmount);
    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (Number(resTotalAmount) > Number(resAmount)) {
      resetPayment(undefined);
      setremainingamountdue(
        Number(
          (
            Number(resAmount) -
            Number(availableCreds)+
            Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ).toFixed(2)
        )
      );
      setchange("0.00");
      setAcceptpaymentbuttonenable(true);
    } else {
      if (
        Number(resTotalAmount) >=
        Number(
          (
            Number(resAmount) -
            Number(availableCreds)+
            Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ).toFixed(2)
        )
      ) {
        setchange(
          Number(resTotalAmount) -
            Number(
              (
                Number(resAmount) -
                Number(availableCreds) +
                Number(donationAmountAdd)+
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
              ).toFixed(2)
            )
        );
        setremainingamountdue("0.00");
        setRemaniningAmtStatic("0.00");
        setAcceptpaymentbuttonenable(false);
      } else {
        setremainingamountdue(
          Number(
            (
              Number(resAmount) -
              Number(availableCreds)+
              Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setRemaniningAmtStatic(
          Number(
            (
              Number(resAmount) -
              Number(availableCreds)+
              Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setchange("0.00");
        setAcceptpaymentbuttonenable(true);
      }
    }

    settotaldue(resAmount);
    // settotaltax(Number(resTaxamount))

    setopenAdjustDueDate(false);
    setopenPromoFreeDays(false);
    seteditSchedulePopUp(false);
    // if (isRevenueRecogActivated) {
    //   multipleAdjustRevenue();
    // } else {
      multipleAdjust();
      // editAdjustRes({resArray:checkedAgr})
      setTenderUseEffectCalculation(true)
      let traceObj={
        iD:Number(userTraceData?.length)+1,
        type:"SuspenseAdd"
      }
      setUserTraceData([...userTraceData,traceObj])
    // }
  };

  useEffect(()=>{
  if(tenderUseEffectCalculation==true){
    tenderAmountCalculation()
    setTenderUseEffectCalculation(false)
  }
  },[tenderUseEffectCalculation])
  let usefilterzero: any[];

  const useSuspenseBtn = () => {

    const usefilterzero = masterusesuspensearray.filter(function (e) {
      return e.sustype != "type" && Number(e.newsuspense) > 0;
    });
    const addfilterzero = masteraddsuspensearray.filter(function (e) {
      return e.sustype != "type" && Number(e.newsuspense) > 0;
    });

    setsuspensePopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
    setusesuspense(false);

 
    settotalsuspensemaster([...addfilterzero, ...usefilterzero]);

    for (let i = 0; i < usefilterzero.length; i++) {
      let index = paygridvalues.findIndex(
        (x) =>
          String(x.Agreementid) === String(usefilterzero[i].agreementnumber)
      );
      paygridvalues[index].suspenseSub = Number(usefilterzero[i].newsuspense);
      if (
        usefilterzero[i].agreementnumber ==
        ReceiptCategoriesRequestParam[i].agreementId
      ) {
        ReceiptCategoriesRequestParam[i].suspenseSub = Number(
          usefilterzero[i].newsuspense
        );
      }
    }


    setReceiptCategory(ReceiptCategoriesRequestParam);

    let totalsusamountuse: number;
    totalsusamountuse = 0;

    for (let i = 0; i < usefilterzero.length; i++) {
      totalsusamountuse =
        totalsusamountuse + Number(usefilterzero[i].newsuspense);
    }

    let resAmount = 0.0;
    //  let resTaxamount=0.00
    for (let i = 0; i < checkedAgr.length; i++) {
      resAmount = Number(checkedAgr[i].Amount) + Number(resAmount);
      // resTaxamount=Number(checkedAgr[i].Taxamount)
    }
    //SuspenseTender-1
    // debugger;
    const addSuspenseAmount = addfilterzero.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = usefilterzero.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    let resTotalAmount = 0.0;
    if (paymentOrigin == "Pay By Phone") {
      resAmount = Number(resAmount) + Number(convAmtWTax);
      // resTaxamount=Number(resTaxamount)+Number(convtax)
    }
    resAmount = Number(resAmount) - Number(carryRentAmount);
    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (Number(resTotalAmount) > Number(resAmount)) {
      resetPayment(undefined);
      setremainingamountdue(
        Number(
          (
            Number(resAmount) -
            Number(availableCreds)+
            Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ).toFixed(2)
        )
      );
      setchange("0.00");
      setAcceptpaymentbuttonenable(true);
    } else {
      if (
        Number(resTotalAmount) >=
        Number(
          (
            Number(resAmount) -
            Number(availableCreds) +
            Number(donationAmountAdd)+
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ).toFixed(2)
        )
      ) {
        setchange(
          Number(resTotalAmount) -
            Number(
              (
                Number(resAmount) -
                Number(availableCreds) +
                Number(donationAmountAdd)+
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
              ).toFixed(2)
            )
        );
        setremainingamountdue("0.00");
        setRemaniningAmtStatic("0.00");
        setAcceptpaymentbuttonenable(false);
      } else {
        setremainingamountdue(
          Number(
            (
              Number(resAmount) -
              Number(availableCreds)+
              Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setRemaniningAmtStatic(
          Number(
            (
              Number(resAmount) -
              Number(availableCreds)+
              Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setchange("0.00");
        setAcceptpaymentbuttonenable(true);
      }
    }

    settotaldue(resAmount);
    setopenAdjustDueDate(false);
    setopenPromoFreeDays(false);
    seteditSchedulePopUp(false);
    // if (isRevenueRecogActivated) {
    //   multipleAdjustRevenue();
    // } else {
      multipleAdjust();
      setTenderUseEffectCalculation(true)
      // editAdjustRes({resArray:checkedAgr})
      let traceObj={
        iD:Number(userTraceData?.length)+1,
        type:"SuspenseUse"
      }
      setUserTraceData([...userTraceData,traceObj])
    // }
  };

  const transSuspenseBtn = () => {
    let returnSusAmount = 0;
    if (
      mastertranssuspensearray.length === 1 &&
      Number(mastertranssuspensearray[0].suspenseamount) > 0
    ) {
      setOpenTransferSuspense(true);
      settransuspense(false);
    } else {
      let insertArray: any = [];

      for (let i = 0; i < mastertranssuspensearray.length; i++) {
        let indexValue: any = paygridvalues.findIndex(
          (x) =>
            String(x.Agreementid) ===
            String(mastertranssuspensearray[i].agreementnumber)
        );
        if (
          mastertranssuspensearray[i].sustype == "TransferAdd" ||
          "TransferUsed"
        ) {
          if (
            Number(mastertranssuspensearray[i].newsuspense) -
              Number(paygridvalues[indexValue].transferOldSuspense) >
            0
          ) {
            insertArray.push({
              sustype: "TransferAdd",
              agreementnumber: mastertranssuspensearray[i].agreementnumber,
              agreementid: mastertranssuspensearray[i].agreementid,
              schedure: mastertranssuspensearray[i].schedure,
              paymentamount: mastertranssuspensearray[i].paymentamount,
              epoamount: Number(mastertranssuspensearray[i].epoamount),
              suspenseamount: mastertranssuspensearray[i].suspenseamount,
              newsuspense:
                Number(mastertranssuspensearray[i].newsuspense) -
                Number(paygridvalues[indexValue].transferOldSuspense),
              agreementType: mastertranssuspensearray[i].agreementType,
            });
            // let indexValue:any= checkedAgr.findIndex( x => String(x.Agreementid)  ===  String(mastertranssuspensearray[i].agreementnumber))
            // checkedAgr[indexValue].transferNewSuspense = Number(mastertranssuspensearray[i].newsuspense)
            paygridvalues[indexValue].SuspenseAmount = Number(
              mastertranssuspensearray[i].newsuspense
            );
          } else if (
            Number(mastertranssuspensearray[i].newsuspense) -
              Number(paygridvalues[indexValue].transferOldSuspense) <
            0
          ) {
            insertArray.push({
              sustype: "TransferUsed",
              agreementnumber: mastertranssuspensearray[i].agreementnumber,
              agreementid: mastertranssuspensearray[i].agreementid,
              schedure: mastertranssuspensearray[i].schedure,
              paymentamount: mastertranssuspensearray[i].paymentamount,
              epoamount: Number(mastertranssuspensearray[i].epoamount),
              suspenseamount: mastertranssuspensearray[i].suspenseamount,
              newsuspense:
                Number(paygridvalues[indexValue].transferOldSuspense) -
                Number(mastertranssuspensearray[i].newsuspense),
              agreementType: mastertranssuspensearray[i].agreementType,
            });
            // let indexValue:any= checkedAgr.findIndex( x => String(x.Agreementid)  ===  String(mastertranssuspensearray[i].agreementnumber))
            // checkedAgr[indexValue].transferNewSuspense = Number(mastertranssuspensearray[i].newsuspense)
            // debugger;
            paygridvalues[indexValue].SuspenseAmount = Number(
              mastertranssuspensearray[i].newsuspense
            );
            const useFilterTransferBack = masterusesuspensearray.filter(
              function (e) {
                return (
                  e.sustype != "type" &&
                  Number(e.newsuspense) > 0 &&
                  String(e.agreementid) ==
                    String(mastertranssuspensearray[i].agreementid)
                );
              }
            );
            if (useFilterTransferBack?.length > 0) {
              if (
                Number(mastertranssuspensearray[i].newsuspense) <
                Number(useFilterTransferBack[0].newsuspense)
              ) {
                masterusesuspensearray = masterusesuspensearray.filter(
                  function (e) {
                    return (
                      String(e.agreementid) !=
                      String(useFilterTransferBack[0].agreementid)
                    );
                  }
                );
                for (let i = 0; i < useFilterTransferBack.length; i++) {
                  let index = paygridvalues.findIndex(
                    (x) =>
                      String(x.Agreementid) ===
                      String(useFilterTransferBack[0].agreementnumber)
                  );
                  paygridvalues[index].suspenseSub = 0;
                  // paygridvalues[index].Amount=Number(paygridvalues[index].actualAmount)+Number(paygridvalues[index].suspenseAdd)-Number(usefilterzero[i].newsuspense)
                  let receiptObj = ReceiptCategoriesRequestParam.filter(
                    (e) =>
                      String(e.agreementId) ==
                      String(useFilterTransferBack[0].agreementnumber)
                  );
                  if (
                    String(useFilterTransferBack[0].agreementnumber) ==
                    String(receiptObj[0].agreementId)
                  ) {
                    receiptObj[0].suspenseSub = 0;
                  }
                }
              }
            }
          }
        }
        if (paygridvalues[indexValue].isReturnAgrFlag == 1) {
          paygridvalues[indexValue].suspenseSub =
            paygridvalues[indexValue].SuspenseAmount;
          returnSusAmount = Number(paygridvalues[indexValue].SuspenseAmount);
        }
      }
      const usefilterzero = masterusesuspensearray.filter(function (e) {
        return e.sustype != "type" && Number(e.newsuspense) > 0;
      });
      const addfilterzero = masteraddsuspensearray.filter(function (e) {
        return e.sustype != "type" && Number(e.newsuspense) > 0;
      });
      setmastertranssuspensearray(insertArray);
      settotalsuspensemaster([
        ...usefilterzero,
        ...addfilterzero,
        ...insertArray,
      ]);
      settransuspense(false);
      setUsesusText(false);
      let addfilter = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Add";
      });

      let usefilter = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Used";
      });

      let resAmount = 0.0;
      //  let resTaxamount=0.00
      for (let i = 0; i < checkedAgr.length; i++) {
        resAmount = Number(checkedAgr[i].Amount) + Number(resAmount);
        // resTaxamount=Number(checkedAgr[i].Taxamount)
      }
      //SuspenseTender-1
      // debugger;
      const addSuspenseAmount = addfilterzero.filter(function (e) {
        return e.sustype == "Add" && Number(e.newsuspense) > 0;
      });
      const useSuspenseAmount = usefilterzero.filter(function (e) {
        return e.sustype == "Used" && Number(e.newsuspense) > 0;
      });
      let addSusTotalAmount =
        addSuspenseAmount.length == 0
          ? 0.0
          : addSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let usedSusTotalAmount =
        useSuspenseAmount.length == 0
          ? 0.0
          : useSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

      usedSusTotalAmount = usedSusTotalAmount + returnSusAmount;

      let resTotalAmount = 0.0;
      if (paymentOrigin == "Pay By Phone") {
        resAmount = Number(resAmount) + Number(convAmtWTax);
        // resTaxamount=Number(resTaxamount)+Number(convtax)
      }
      resTotalAmount =
        totalduetendertype.length == 0
          ? 0.0
          : totalduetendertype
              .map((bill) => bill.Amount)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      if (Number(resTotalAmount) > Number(resAmount)) {
        resetPayment(undefined);
        setremainingamountdue(
          Number(
            (
              Number(resAmount) -
              Number(availableCreds)+
              Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
            ).toFixed(2)
          )
        );
        setchange("0.00");
        setAcceptpaymentbuttonenable(true);
      } else {
        if (
          Number(resTotalAmount) >=
          Number(
            (
              Number(resAmount) -
              Number(availableCreds) +
              Number(donationAmountAdd)+
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
            ).toFixed(2)
          )
        ) {
          setchange(
            Number(resTotalAmount) -
              Number(
                (
                  Number(resAmount) -
                  Number(availableCreds)+
                  Number(donationAmountAdd) +
                  Number(addSusTotalAmount) -
                  Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                ).toFixed(2)
              )
          );
          setremainingamountdue("0.00");
          setRemaniningAmtStatic("0.00");
          setAcceptpaymentbuttonenable(false);
        } else {
          setremainingamountdue(
            Number(
              (
                Number(resAmount) -
                Number(availableCreds)+
                Number(donationAmountAdd) +
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
              ).toFixed(2)
            ) - Number(resTotalAmount)
          );
          setRemaniningAmtStatic(
            Number(
              (
                Number(resAmount) -
                Number(availableCreds) +
                Number(donationAmountAdd)+
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
              ).toFixed(2)
            ) - Number(resTotalAmount)
          );
          setchange("0.00");
          setAcceptpaymentbuttonenable(true);
        }
      }
      // if (isRevenueRecogActivated) {
      //   multipleAdjustRevenue();
      // } else {
        multipleAdjust();
        // editAdjustRes({resArray:checkedAgr})
        setTenderUseEffectCalculation(true)
        let traceObj={
          iD:Number(userTraceData?.length)+1,
          type:"SuspenseTransfer"
        }
        setUserTraceData([...userTraceData,traceObj])
      // }
    }
  };

  useEffect(()=>{

    if(userTraceData?.length>0){
      const invokeAcceptPayAPISingleStore = async () => {
        let cardData: any = [];
    
        cardData = acceptPayment?.CardInfo?.filter(
          (e) => Number(e.Amount) > 0 && e.cvvEncrypt.length >= 3
        );
    
        if (chargeCardArray.length > 0) {
          cardData = [...cardData, ...chargeCardArray];
        }
    
        let processOrderData: any = undefined;
        if (
          props.isCoPrelim &&
          (props.provideEAFlag == null || props.provideEAFlag == 'No')
        ) {
          processOrderData = {
            eaFlag: props.provideEAFlag,
            agreementNumber: props.provideAgrNumberPO,
          };
        } else if (props.isCoPrelim && props.provideEAFlag == 'Yes') {
          const updatedPlaceOrderAddressData = props.providePOAddressData;
          updatedPlaceOrderAddressData.items = props.providePlaceOrderItemData;
          processOrderData = {
            eaFlag: props.provideEAFlag,
            agreementNumber: props.provideAgrNumberPO,
            shippingAddress: [updatedPlaceOrderAddressData],
          };
        }
        const entireAcceptPayAPIArray: any = [];
        let creditOnAccount: any = [];
        let IPSuspense: any = [];
        let webleadAmount: any = [];
        let COAAmount = 0;
        let overPaymentSuspenseAmount = 0;
        let changeNum = Number(Number(change).toFixed(2));
        let clubData: any = [];
        let nonClubData: any = [];
        let miscellaneousData: any = [];
        const clubPayload: any = [];
        const transferSuspensePayload: any = [];
        const agreementPaymentPayload: any = [];
        const miscPaymentPayload: any = [];
        const tenderDetailsPayload: any = [];
        const cardDetailsPayload: any = [];
        const customerAccountPayload: any = [];
        const changeAmountPayload: any =
        radioBtn == '0' ? Number(Number(fixedChange(change)).toFixed(2)) : 0.0;
    
        clubData = checkedAgr.filter(
          (el) => el.Agreementtype === 'Club'
        );
        nonClubData = checkedAgr.filter(
          (el) =>
            el.Agreementtype !== 'Club' &&
            !el.Agreementtype.includes('Cell phone') &&
            !el.Agreementtype.includes('Restitution') &&
            el.otherIncomeType != 'Rental Item Sale' &&
            el.otherIncomeType != 'NSF Check' &&
            el.otherIncomeType != 'Credit Card Charge Back' &&
            el.otherIncomeType != 'Back Rent' &&
            el.otherIncomeType != 'Charge-off' &&
            el.otherIncomeType != 'Xbox Gift Card' &&
            el.otherIncomeType != 'Xbox Gift Membership' &&
            el.otherIncomeType != 'Non Inventory Sale'
        );
        miscellaneousData =checkedAgr.filter(
          (el) =>
            el.Agreementtype.includes('Cell phone') ||
            el.Agreementtype.includes('Restitution') ||
            el.otherIncomeType == 'Rental Item Sale' ||
            el.otherIncomeType == 'NSF Check' ||
            el.otherIncomeType == 'Credit Card Charge Back' ||
            el.otherIncomeType == 'Back Rent' ||
            el.otherIncomeType == 'Charge-off' || 
            el.otherIncomeType == 'Xbox Gift Card' || 
            el.otherIncomeType == 'Xbox Gift Membership' || 
            el.otherIncomeType == 'Non Inventory Sale'
        );
        console.log('clubData logger', clubData);
        console.log('nonClubData logger', nonClubData);
        console.log('miscellaneousData logger', miscellaneousData);
    
        if (availableCredsremaining.length > 0) {
          webleadAmount = availableCredsremaining.filter(
            (x) => x.balanceType === 'WEBLEAD'
          );
          creditOnAccount = availableCredsremaining.filter(
            (x) => x.balanceType === 'COA'
          );
          IPSuspense = availableCredsremaining.filter(
            (x) => x.balanceType === 'IP Suspence'
          );
        }
        
        if (
          radioBtn == '1' &&
          Number(change) > 0 &&
          suspensehide== false &&
          CreditRadio== true
        ) {
          COAAmount = radioBtn == '1' && changeNum >= 0 ? changeNum : 0;
        } else if (
          radioBtn == '1' &&
          Number(change) > 0 &&
          suspensehide == true
        ) {
          overPaymentSuspenseAmount =
          radioBtn == '1' && changeNum >= 0 ? changeNum : 0;
        }
    
        if (
          creditOnAccount.length > 0 &&
          Number(creditOnAccount[0].accountBalance) > 0
        ) {
          customerAccountPayload.push({
            actionType: 'D',
            accountType: 'COA',
            storeNumber: String(window.sessionStorage.getItem('storeNumber')),
            amount: Number(creditOnAccount[0].accountBalance).toFixed(2),
          });
        }
    
        if (
          webleadAmount.length > 0 &&
          1 * Number(webleadAmount[0].accountBalance) > 0
        ) {
          customerAccountPayload.push({
            actionType: 'D',
            accountType: 'WEBLEAD',
            storeNumber: String(window.sessionStorage.getItem('storeNumber')),
            amount: (1 * Number(webleadAmount[0].accountBalance)).toFixed(2),
          });
        }
    
        if (IPSuspense.length > 0 && 1 * Number(IPSuspense[0].accountBalance) > 0) {
          customerAccountPayload.push({
            actionType: "D",
            accountType: "SIPS",
            storeNumber: String(window.sessionStorage.getItem("storeNumber")),
            amount: (1 * Number(IPSuspense[0].accountBalance)).toFixed(2),
          });
        }
    
        if (Number(COAAmount) > 0) {
          customerAccountPayload.push({
            actionType: 'C',
            accountType: 'COA',
            storeNumber: String(window.sessionStorage.getItem('storeNumber')),
            amount: Number(COAAmount).toFixed(2),
          });
        }
    
        if (Number(overPaymentSuspenseAmount) > 0) {
          customerAccountPayload.push({
            actionType: 'C',
            accountType: 'SUSP',
            storeNumber: String(window.sessionStorage.getItem('storeNumber')),
            amount: Number(overPaymentSuspenseAmount).toFixed(2),
          });
        }
    
        if (Number(carryRentAmount) > 0) {
          customerAccountPayload.push({
            actionType: 'C',
            accountType: 'CR',
            storeNumber: String(window.sessionStorage.getItem('storeNumber')),
            amount: Number(carryRentAmount).toFixed(2),
          });
        }
    
        if (cardData.length) {
          for (let i = 0; i < cardData.length; i++) {
            const cardtoken: any =
              cardToken.length == 0
                ? ''
                : cardToken.filter(
                    (e) => String(cardData[i].lastFour) == String(e.lastFour)
                  );
            cardDetailsPayload.push({
              amount: Number(cardData[i].Amount).toFixed(2),
              authNumber: '',
              cardLastFour: cardData[i].lastFour,
              cardType: cardData[i].cardRef,
              cardTransactionType:
                cardData[i].chargeCard == false ? 'PAYTOK' : 'MNL',
              externalTransactionId: '',
              paymentId: cardData[i].paymentId,
              expirationDate: cardData[i].expirationDate,
              paymentToken:cardData[i].cardToken,
              billingAddress: billingAddressValidation(cardData[i].billingAddress),
            });
          }
        }
        if (cardDetails.length) {
          cardDetails.map((el) => {
            cardDetailsPayload.push({
              amount: Number(el.cardAmount).toFixed(2),
              authNumber: String(el.cardAuthNumber),
              cardLastFour: el.cardLastFour,
              cardType: el.cardType == undefined ? 'U' : el.cardType,
              cardTransactionType: 'SWP',
              externalTransactionId: String(el.cardSequence),
              paymentToken: el.cardToken !== undefined ? el.cardToken : '',
            });
          });
        }
    
        if (Number(acceptPayment.CashAmount) > 0) {
          tenderDetailsPayload.push({
            type: 'CASH',
            amount: acceptPayment.CashAmount.toFixed(2),
          });
        }
        if (adddataanother.length) {
          adddataanother.map((el) => {
            if (el.Type_info == 'Check') {
              tenderDetailsPayload.push({
                type: 'CHK',
                amount: el.Amount_rac,
                tenderNumber: el.check_no,
                checkType: el.check_type,
                checkWrittenBy: el.Written_by,
                checkWrittenTo: el.Written_too,
              });
            } else if (el.Type_info == 'Money Order') {
              tenderDetailsPayload.push({
                type: 'MO',
                amount: el.Amount_rac,
                tenderNumber: el.check_no,
                purchaser: el.Written_by,
                issuer: el.Written_too,
              });
            }
          });
          
        }
        if (Number(changeAmountPayload) > 0) {
          tenderDetailsPayload.push({
            type: 'CHG',
            amount: Number(changeAmountPayload).toFixed(2),
          });
        }
        if (clubData.length) {
          clubData.map((el) => {
            clubPayload.push({
              customerClubId: String(el.customerClubId),
              isZeroPayment: el.isZeroPayment == true || el?.isReturnAgrFlag == 1 ? 'Y' : undefined,
              editedSchedule: el.editSchedule.length ? el.editSchedule : undefined,
              adjustedDueDate: el.dueDateModified
                ? moment(el.Nextduedate).format('YYYY-MM-DD')
                : undefined,
            });
          });
        }
        if (donationAmount != undefined) {
          if (donationAmount?.respFlag && donationAmount?.amount != "0.00"&&donationBtnFlag) {
            let reason: any = ""
            switch (donationAmount?.type) {
              case 'BGC':
                reason = "Boys and Girls Club";
                break;
              case 'STON':
                reason = "Fill The Fridge";
                break;
              case 'BBS':
                reason = "Big Brother/Big Sister";
                break;
            }
            if (donationType?.donationDesc.length > 250 && reason=="") {
              reason = donationType?.donationDesc.slice(0, 250)
            }
            miscPaymentPayload.push({
              type: "DONATION" + String(donationAmount?.type),
              amount: Number(donationAmount?.amount).toFixed(2),
              receiptReason: reason == "" ? donationType?.donationDesc : reason
            })
          }
        }
    
        if (nonClubData.length) {
          let commonTransferElements = paygridvalues.filter(
            (x) =>
              x.store_number == String(window.sessionStorage.getItem('storeNumber'))
          );
          console.log('commonTransferElements', commonTransferElements);
    
          for (let i = 0; i < commonTransferElements.length; i++) {
            if (
              Number(commonTransferElements[i].SuspenseAmount) !=
              Number(commonTransferElements[i].transferOldSuspense) &&
              commonTransferElements[i].otherIncomeType == "" &&
              commonTransferElements[i].Agreementtype != "Restitution"
            ) {
              transferSuspensePayload.push({
                balance: Number(commonTransferElements[i].SuspenseAmount).toFixed(
                  2
                ),
                agreementId: String(commonTransferElements[i].Agreementid),
                isTransferToCoa: commonTransferElements[i].isCOATransfered
                  ? 'Y'
                  : undefined,
              });
            }
          }
    
          nonClubData.map((el) => {
            let isLDWActionPerformed: any = [];
            let isPolicyActionPerformed: any = [];
            let actLDWArrPayload=[...actLDWArr]
            let actPolicyArrPayload=[...actPolicyArr]
            isLDWActionPerformed = actLDWArrPayload.filter(
                    (x) => x.agreementId == el.Agreementid
                  );
            isPolicyActionPerformed = actPolicyArrPayload.filter(
                    (x) => x.agreementId == el.Agreementid
                  );
            // if(configFlags?.commonModuleFlag==1){
            //   if (actLDWArrPayload.length) {
            //     isLDWActionPerformed = actLDWArrPayload.filter(
            //       (x) => x.agreementId == el.Agreementid
            //     );
            //     if (isLDWActionPerformed.length) {
            //         if(isLDWActionPerformed[0].action == 1 && el.coverageStatus!='Y'){
            //           isLDWActionPerformed[0].action ='A'
            //         }else if(isLDWActionPerformed[0].action == 2 && el.coverageStatus!='N'){
            //           isLDWActionPerformed[0].action ='D'
            //         }
            //     }
            //   }
            //   if (actPolicyArrPayload.length) {
            //     isPolicyActionPerformed = actPolicyArrPayload.filter(
            //       (x) => x.agreementId == el.Agreementid
            //     );
            //     if (isPolicyActionPerformed.length) {
            //       if(isPolicyActionPerformed[0].action == 1 && el.coverageStatus!='Y'){
            //         isPolicyActionPerformed[0].action ='A'
            //       }else if(isPolicyActionPerformed[0].action == 2 && el.coverageStatus!='N'){
            //         isPolicyActionPerformed[0].action ='D'
            //       }
                    
            //     }
            //   }
            // }else{
            //   if (actLDWArrPayload.length) {
            //     isLDWActionPerformed = actLDWArrPayload.filter(
            //       (x) => x.agreementId == el.Agreementid
            //     );
            //     if (isLDWActionPerformed.length) {
            //       isLDWActionPerformed[0].action =
            //         isLDWActionPerformed[0].action == 1 ? 'A' : 'D';
            //     }
            //   }
            //   if (actPolicyArrPayload.length) {
            //     isPolicyActionPerformed = actPolicyArrPayload.filter(
            //       (x) => x.agreementId == el.Agreementid
            //     );
            //     if (isPolicyActionPerformed.length) {
            //       isPolicyActionPerformed[0].action =
            //         isPolicyActionPerformed[0].action == 1 ? 'A' : 'D';
            //     }
            //   }
            // }
    
            if (Number(el.suspenseAdd) > 0) {
              customerAccountPayload.push({
                actionType: 'C',
                accountType: 'SUSP',
                storeNumber: el.store_number,
                amount: Number(el.suspenseAdd).toFixed(2),
                agreementId: el.Agreementid,
              });
            }
    
            if (Number(el.suspenseSub) > 0) {
              customerAccountPayload.push({
                actionType: 'D',
                accountType: 'SUSP',
                storeNumber: el.store_number,
                amount: Number(el.suspenseSub).toFixed(2),
                agreementId: el.Agreementid,
              });
            }
    
            agreementPaymentPayload.push({
              agreementId: el.Agreementid,
              editedSchedule: el.editSchedule.length ? el.editSchedule : undefined,
              adjustedDueDate: el.dueDateModified && el.Nextduedate !== 'PIF' && el.Nextduedate !== 'SAC' && el.Nextduedate !== 'EPO' && el.Nextduedate && el.Nextduedate !=""
                  ? moment(el.Nextduedate).format('YYYY-MM-DD')
                  : undefined,
              freeTime:
                el.FreeTime == null
                  ? undefined
                  : {
                      type: el.FreeTime.freeTimeType,
                      freeDays: el.FreeTime.freeTimeDays,
                      waiveOptionalService: el.FreeTime.isOptServiceWaived
                        ? 'Y'
                        : 'N',
                    },
              isZeroPayment: el.isZeroPayment == true || el?.isReturnAgrFlag == 1 ? 'Y' : undefined,
              activatePolicy: isPolicyActionPerformed.length
                ? isPolicyActionPerformed[0]?.action == 1 && el.coverageStatus!='Y'?'A':isPolicyActionPerformed[0]?.action == 2 && el.coverageStatus!='N'?'D':undefined
                : undefined,
              activateLdw: isLDWActionPerformed.length
                ?isLDWActionPerformed[0]?.action == 1 && el.coverageStatus!='Y'?'A':isLDWActionPerformed[0]?.action == 2 && el.coverageStatus!='N'?'D':undefined
                : undefined,
              epoPayment: el.acceptEpoFlag == 1 ||(el.dueDateModified && (el.Nextduedate == 'SAC' || el.Nextduedate == 'EPO' ))|| el.isFinalTransferSusUseApplied && el.Agreementtype != "PIF Rent" ? 'Y': undefined,
              carryLateFeeAmount:
                Number(el.carriedLateFee) > 0
                  ? Number(el.carriedLateFee).toFixed(2)
                  : undefined,
              waiveLateFeeAmount:
                Number(el.waivedLateFee) > 0
                  ? Number(el.waivedLateFee).toFixed(2)
                  : undefined,
              epoDiscount:
                el.acceptEpoFlag == 1
                  ? {
                      epoDiscount:el.isAcceptEPODiscounts?.epoDiscount,
                      pastDueDiscount:el.isAcceptEPODiscounts?.pastDueDiscount,
                      noteDiscount:el.isAcceptEPODiscounts?.noteDiscount
                    }
                  : undefined,
            });
          });
        }
    
        if (miscellaneousData.length) {
          miscellaneousData.map((el) => {
            let misItemData: any;
            let otherFee: any = [];
            let type: any;
            let receiptText: any;
            let inventoryId: any;
            let amount: any = '0.00';
            let tax: any = '0.00';
            let receiptReason:any="";
            let agreementId:any="";
            let receiptTransactionID: any;
            let customerAccountID: any;
            // let deliveryFee:any=[];
            if (el.Agreementtype.includes('Cell phone')) {
              type = 'WIREACT';
              tax = Number(el.Taxamount).toFixed(2);
              amount = (Number(el.Amount) - Number(el.Taxamount)).toFixed(2);
              misItemData = {
                terminalId: el.miscellaneousItemInfo.terminalId,
    
                sessionId: el.miscellaneousItemInfo.sessionId,
    
                actionId: el.miscellaneousItemInfo.actionId,
    
                saleId: el.miscellaneousItemInfo.saleId,
    
                requestId: el.miscellaneousItemInfo.requestId,
    
                sequenceNumber: el.miscellaneousItemInfo.apiCalc,
    
                txnId: el.miscellaneousItemInfo.txnId,
    
                productId: el.miscellaneousItemInfo.productId,
    
                amount: el.miscellaneousItemInfo.amount,
    
                shopId: el.miscellaneousItemInfo.shopId,
    
                cashier: el.miscellaneousItemInfo.cashier,
    
                consumerDiscount:
                  el.miscellaneousItemInfo.consumerDiscount == undefined
                    ? '0.00'
                    : el.miscellaneousItemInfo.consumerDiscount,
    
                serviceFee:
                  el.miscellaneousItemInfo.serviceFee == undefined
                    ? '0.00'
                    : el.miscellaneousItemInfo.serviceFee,
    
                productName: String(el.miscellaneousItemInfo.type),
    
                saleAmount: String(el.miscellaneousItemInfo.salesPrice),
    
                mobileNumber: String(el.miscellaneousItemInfo.phoneNumber),
    
                carrier: String(el.miscellaneousItemInfo.carrierName),
    
                pricePlan: String(el.miscellaneousItemInfo.description),
    
                totalSale: String(el.miscellaneousItemInfo.totalSales),
    
                simNumber: String(el.miscellaneousItemInfo.simNumber),
    
                customData: el.miscellaneousItemInfo.data,
              };
              if (Number(el.miscellaneousItemInfo.nineOneOneFee) > 0) {
                otherFee.push({
                  type:'CELL911',
                  amount:Number(el.miscellaneousItemInfo.nineOneOneFee).toFixed(2)
                })
                amount = (Number(amount) - Number(el.miscellaneousItemInfo.nineOneOneFee)).toFixed(2)
              }
              
            }
            if(el.otherIncomeType=='Rental Item Sale'){
              type='CASHSALE'
              amount= (Number(el.Amount)-Number(el.Taxamount)-Number(el.deliveryAmount)).toFixed(2)
              inventoryId=String(el.Agreementid)
              tax=(Number(el.Taxamount)-Number(el.risDeliveryfeeTax)).toFixed(2)
              if(Number(el?.deliveryAmount)>0){
                otherFee.push({
                  type:'DELIVFEE',
                  tax:Number(el.risDeliveryfeeTax)>0 ? Number(el.risDeliveryfeeTax).toFixed(2):undefined,
                  amount:Number(el.deliveryAmount).toFixed(2)
                })
              }
            }
            if (el.otherIncomeType == 'NSF Check') {
              type = 'NSF';
              amount = (
                Number(el.Amount) -
                Number(el.Taxamount) -
                Number(el.nsfFee)
              ).toFixed(2);
             
              receiptTransactionID = String(el.nsfData.receiptTransactionId);
              customerAccountID = String(el.nsfData.customerAccountId);
              otherFee.push({
                amount: Number(el.nsfFee).toFixed(2),
                type: 'NSFFEE',
                tax : Number(el.Taxamount).toFixed(2)
              });
            }
            if (el.otherIncomeType == 'Credit Card Charge Back') {
              type = 'CCCB';
              amount = (Number(el.Amount) - Number(el.Taxamount) - Number(el?.cccbFee??0)).toFixed(2);
              // tax = Number(el.Taxamount).toFixed(2);
              receiptTransactionID = String(el.cccbData.receiptTransactionId);
              customerAccountID = String(el.cccbData.customerAccountId);
              otherFee.push({
                amount: Number(el.cccbFee).toFixed(2),
                type: 'CCCBFEE',
                tax : Number(el.Taxamount).toFixed(2)
              });
            }
            if (el.otherIncomeType == 'Back Rent') {
              type = 'BR';
              amount = (Number(el.Amount) - Number(el.Taxamount)).toFixed(2);
              tax = Number(el.Taxamount).toFixed(2);
            }
            if(el.otherIncomeType=='Charge-off'){
              receiptReason=el.ReasonRefcode
              type='CO'
              amount=(Number(el.Amount)-Number(el.Taxamount)).toFixed(2)
              tax=Number(el.Taxamount).toFixed(2)
              agreementId=String(el.Agreementid)
            }
            if(el.otherIncomeType=='Xbox Gift Card') {
              type = 'XBGFTCRD'
              amount = (Number(el.Amount)- Number(el.Taxamount)).toFixed(2)
              tax = Number(el.Taxamount).toFixed(2)
              receiptReason = el.Agreementdesc
            }
            if(el.otherIncomeType=='Xbox Gift Membership') {
              type = 'XBMEMSHIP'
              amount = (Number(el.Amount)- Number(el.Taxamount)).toFixed(2)
              tax = Number(el.Taxamount).toFixed(2)
              receiptReason = el.Agreementdesc
            }
            if(el.otherIncomeType=='Non Inventory Sale') {
              type = el.Agreementdesc.includes('Acima Recovery Settlement') ? 'ACIMARECOVERY' : 'NONINVCASH'
              amount = (Number(el.Amount) - Number(el.Taxamount) - Number(el.deliveryAmount)).toFixed(2)
              tax = (Number(el.Taxamount) - Number(el.deliveryAmountTax)).toFixed(2)
              receiptReason= el.Agreementdesc
              otherFee.push({
                type:'DELIVFEE',
                amount:Number(el.deliveryAmount).toFixed(2),
                tax:Number(el.deliveryAmountTax).toFixed(2)
              })
            }
    
            miscPaymentPayload.push({
              type: type,
              amount: amount,
              tax: tax,
              receiptReason:receiptReason==""?undefined:receiptReason,
              agreementId:agreementId==""?undefined:agreementId,
              receiptTransactionId: receiptTransactionID,
              customerAccountId: customerAccountID,
              inventoryId: inventoryId,
              otherFee: otherFee?.length == 0 ? undefined : otherFee,
              itemData: misItemData,
              receiptText: receiptText
            });
          });
        }
    
        // Payload Build
        entireAcceptPayAPIArray.push({
          userTraceData:userTraceData[Number(userTraceData?.length)-1],
          raftApiFlag: String(configFlags.raftApiFlag),
          paymentInfo: [
            {
              remainginAmountDue:remainingamountdue,
              changeOrCredit:change,
              radioFlag:CreditRadio,
              totalDue:totaldue,
              globalCustomerId:customerGCID==""?undefined:customerGCID,
              FirstName: getpayment?.paymentInfo?.customerInfo[0]?.firstName,
              LastName: getpayment?.paymentInfo?.customerInfo[0]?.lastName,
              storeMerchantId:storeMerchantId || null,
              placeOrderDetails: processOrderData,
              coWorkerId: String(empId),
              storeNumber: String(window.sessionStorage.getItem('storeNumber')),
              customerId: String(customerId),
              paymentOrigin: paymentOrigin == 'Store' ? 'S' : 'H',
              clubPayment: clubPayload.length && !(clubActivate == 0) ? clubPayload : undefined,
              agreementPayment: agreementPaymentPayload.length
                ? agreementPaymentPayload
                : undefined,
              transferSuspense: transferSuspensePayload.length
                ? transferSuspensePayload
                : undefined,
              miscPayment: miscPaymentPayload.length
                ? miscPaymentPayload
                : undefined,
              paymentMethod: {
                tenderDetails: tenderDetailsPayload.length
                  ? tenderDetailsPayload
                  : undefined,
                cardDetails: cardDetailsPayload.length
                  ? cardDetailsPayload
                  : undefined,
                customerAccount: customerAccountPayload.length
                  ? customerAccountPayload
                  : undefined,
              },
              deactivateClub:
                clubActivate == 0 ? [String(customerId)] : undefined,   
              },
          ],
        });
        console.log("userTracePayload",userTracePayload)
        if(Number(userTracePayload[userTracePayload?.length-1]?.userTraceData?.iD)==Number(userTraceData[userTraceData?.length-1]?.iD)&&Number(userTracePayload[userTracePayload?.length-1]?.userTraceData?.iD)>=0&&
        Number(userTraceData[userTraceData?.length-1]?.iD)>=0){
          let tracePayloadFormationExisting = userTracePayload
          tracePayloadFormationExisting[tracePayloadFormationExisting?.length-1]=entireAcceptPayAPIArray[0]
          setUserTracePayload(tracePayloadFormationExisting)
        }else{
          setUserTracePayload([...userTracePayload,entireAcceptPayAPIArray[0]])
        }
       
      };
      invokeAcceptPayAPISingleStore()
    }
  },[userTraceData,acceptPayment,multipleCard,chargeCardArray,change,checkedAgr,availableCredsremaining,radioBtn,suspensehide,CreditRadio,cardDetails,acceptPayment,adddataanother,donationAmount,donationType,paygridvalues,remainingamountdue,totaldue,carryRentAmount])



  const acceptSuspenseTransfer = () => {
    setOpenTransferSuspense(false);
    setNewCOATotal("0.00");
    let insertArray: any = [];

    for (let i = 0; i < mastertranssuspensearray.length; i++) {
      let indexValue: any = paygridvalues.findIndex(
        (x) =>
          String(x.Agreementid) ===
          String(mastertranssuspensearray[i].agreementnumber)
      );
      if (
        mastertranssuspensearray[i].sustype == "TransferAdd" ||
        "TransferUsed"
      ) {
        if (
          Number(mastertranssuspensearray[i].newsuspense) -
            Number(paygridvalues[indexValue].transferOldSuspense) >
          0
        ) {
          insertArray.push({
            sustype: "TransferAdd",
            agreementnumber: mastertranssuspensearray[i].agreementnumber,
            agreementid: mastertranssuspensearray[i].agreementid,
            schedure: mastertranssuspensearray[i].schedure,
            paymentamount: mastertranssuspensearray[i].paymentamount,
            epoamount: Number(mastertranssuspensearray[i].epoamount),
            suspenseamount: mastertranssuspensearray[i].suspenseamount,
            newsuspense:
              Number(mastertranssuspensearray[i].newsuspense) -
              Number(paygridvalues[indexValue].transferOldSuspense),
            agreementType: mastertranssuspensearray[i].agreementType,
          });
          // let indexValue:any= checkedAgr.findIndex( x => String(x.Agreementid)  ===  String(mastertranssuspensearray[i].agreementnumber))
          // checkedAgr[indexValue].transferNewSuspense = Number(mastertranssuspensearray[i].newsuspense)
          paygridvalues[indexValue].SuspenseAmount = Number(
            mastertranssuspensearray[i].newsuspense
          );
        } else if (
          Number(mastertranssuspensearray[i].newsuspense) -
            Number(paygridvalues[indexValue].transferOldSuspense) <
          0
        ) {
          insertArray.push({
            sustype: "TransferUsed",
            agreementnumber: mastertranssuspensearray[i].agreementnumber,
            agreementid: mastertranssuspensearray[i].agreementid,
            schedure: mastertranssuspensearray[i].schedure,
            paymentamount: mastertranssuspensearray[i].paymentamount,
            epoamount: Number(mastertranssuspensearray[i].epoamount),
            suspenseamount: mastertranssuspensearray[i].suspenseamount,
            newsuspense:
              Number(paygridvalues[indexValue].transferOldSuspense) -
              Number(mastertranssuspensearray[i].newsuspense),
            agreementType: mastertranssuspensearray[i].agreementType,
          });
          // let indexValue:any= checkedAgr.findIndex( x => String(x.Agreementid)  ===  String(mastertranssuspensearray[i].agreementnumber))
          // checkedAgr[indexValue].transferNewSuspense = Number(mastertranssuspensearray[i].newsuspense)
          paygridvalues[indexValue].SuspenseAmount = Number(
            mastertranssuspensearray[i].newsuspense
          );
          paygridvalues[indexValue].suspenseSub = 0;
          let receiptObj = ReceiptCategoriesRequestParam.filter(
            (e) =>
              String(e.agreementId) ==
              String(paygridvalues[indexValue].Agreementid)
          );
          if (
            String(paygridvalues[indexValue].Agreementid) ==
            String(receiptObj[0].agreementId)
          ) {
            receiptObj[0].suspenseSub = 0;
          }
          paygridvalues[indexValue].isCOATransfered = true;
          const useFilterTransferBack = masterusesuspensearray.filter(function (
            e
          ) {
            return (
              e.sustype != "type" &&
              Number(e.newsuspense) > 0 &&
              String(e.agreementid) ==
                String(mastertranssuspensearray[i].agreementid)
            );
          });
          if (useFilterTransferBack?.length > 0) {
            if (
              Number(mastertranssuspensearray[i].newsuspense) <
              Number(useFilterTransferBack[0].newsuspense)
            ) {
              masterusesuspensearray = masterusesuspensearray.filter(function (
                e
              ) {
                return (
                  String(e.agreementid) !=
                  String(useFilterTransferBack[0].agreementid)
                );
              });
              for (let i = 0; i < useFilterTransferBack.length; i++) {
                let index = paygridvalues.findIndex(
                  (x) =>
                    String(x.Agreementid) ===
                    String(useFilterTransferBack[0].agreementnumber)
                );
                paygridvalues[index].suspenseSub = 0;
                // paygridvalues[index].Amount=Number(paygridvalues[index].actualAmount)+Number(paygridvalues[index].suspenseAdd)-Number(usefilterzero[i].newsuspense)
                let receiptObj = ReceiptCategoriesRequestParam.filter(
                  (e) =>
                    e.agreementId ==
                    Number(useFilterTransferBack[0].agreementnumber)
                );
                if (
                  useFilterTransferBack[0].agreementnumber ==
                  receiptObj[0].agreementId
                ) {
                  receiptObj[0].suspenseSub = 0;
                }
              }
            }
          }
        }
      }
    }
    const usefilterzero = masterusesuspensearray.filter(function (e) {
      return e.sustype != "type" && Number(e.newsuspense) > 0;
    });
    const addfilterzero = masteraddsuspensearray.filter(function (e) {
      return e.sustype != "type" && Number(e.newsuspense) > 0;
    });
    setmastertranssuspensearray(insertArray);
    settotalsuspensemaster([
      ...usefilterzero,
      ...addfilterzero,
      ...insertArray,
    ]);
    settransuspense(false);
    setUsesusText(false);
    let addfilter = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add";
    });

    let usefilter = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used";
    });

    let resAmount = 0.0;
    //  let resTaxamount=0.00
    for (let i = 0; i < checkedAgr.length; i++) {
      resAmount = Number(checkedAgr[i].Amount) + Number(resAmount);
      // resTaxamount=Number(checkedAgr[i].Taxamount)
    }
    //SuspenseTender-1
    const addSuspenseAmount = addfilterzero.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = usefilterzero.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    let resTotalAmount = 0.0;
    if (paymentOrigin == "Pay By Phone") {
      resAmount = Number(resAmount) + Number(convAmtWTax);
      // resTaxamount=Number(resTaxamount)+Number(convtax)
    }
    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (Number(resTotalAmount) > Number(resAmount)) {
      resetPayment(undefined);
      setremainingamountdue(
        Number(
          (
            Number(resAmount) -
            Number(availableCreds)+
            Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ).toFixed(2)
        )
      );
      setchange("0.00");
      setAcceptpaymentbuttonenable(true);
    } else {
      if (
        Number(resTotalAmount) >=
        Number(
          (
            Number(resAmount) -
            Number(availableCreds)+
            Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ).toFixed(2)
        )
      ) {
        setchange(
          Number(resTotalAmount) -
            Number(
              (
                Number(resAmount) -
                Number(availableCreds)+
                Number(donationAmountAdd) +
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
              ).toFixed(2)
            )
        );
        setremainingamountdue("0.00");
        setRemaniningAmtStatic("0.00");
        setAcceptpaymentbuttonenable(false);
      } else {
        setremainingamountdue(
          Number(
            (
              Number(resAmount) -
              Number(availableCreds)+
              Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setRemaniningAmtStatic(
          Number(
            (
              Number(resAmount) -
              Number(availableCreds)+
              Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setchange("0.00");
        setAcceptpaymentbuttonenable(true);
      }
    }
    // if (isRevenueRecogActivated) {
    //   multipleAdjustRevenue();
    // } else {
      multipleAdjust();
    // }
  };

  const refundSuspenseBtn = () => {

    setrefundsuspense(false);
    setSecondLevelAuthenOn("Modify Customer Route");
  };

  /*This function is used to render the tender type based on the TenderTypeoptinchange*/
  const TenderTypeOptinchange = (e: any) => {
    const Selectedoption = e.target.id;
    let isCardClicked = true;
    if (Selectedoption === "Cash-tab" && paymentOrigin !== "Pay By Phone"&&(isRevenueRecogActivated?!diffStoreErrLbl:true) && !enableCashPayment) {
      setcashselected(0);
      setcashtabCss(
        `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`
      );
      setcardtabCss(`${navlinkClass.navLink} ${classes.p3}`);
      setcheckMoneyOrdertabCss(`${navlinkClass.navLink} ${classes.p3}`);
    } else if (Selectedoption === "Card-tab") {
      setcashselected(1);
      setcashtabCss(`${navlinkClass.navLink} ${classes.p3}`);
      setcardtabCss(
        `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`
      );
      setcheckMoneyOrdertabCss(`${navlinkClass.navLink} ${classes.p3}`);
      isCardClicked = true;
    } else if (
      Selectedoption === "CheckMoney-Order-tab" &&
      paymentOrigin !== "Pay By Phone"&&(isRevenueRecogActivated?!diffStoreErrLbl:true)
    ) {
      setcashselected(2);
      setcashtabCss(`${navlinkClass.navLink} ${classes.p3}`);
      setcardtabCss(`${navlinkClass.navLink} ${classes.p3}`);
      setcheckMoneyOrdertabCss(
        `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`
      );
    }

    if (isCardClicked && multipleCard === undefined) {
      const TempCard_Array: any = new Array(CardRadiArr.length).fill(false);
      setCardRadiArr(TempCard_Array);
    }
  };

  const TenderTypeOptinchangeRevenue = (e: any) => {
    const Selectedoption = e.target.id;
    let isCardClicked = true;
    if (
      Selectedoption === "Cash-tab" &&
      paymentOrigin !== "Pay By Phone" &&
      !diffStoreErrLbl
    ) {
      setcashselected(0);
      setcashtabCss(
        `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`
      );
      setcardtabCss(`${navlinkClass.navLink} ${classes.p3}`);
      setcheckMoneyOrdertabCss(`${navlinkClass.navLink} ${classes.p3}`);
    } else if (Selectedoption === "Card-tab") {
      setcashselected(1);
      setcashtabCss(`${navlinkClass.navLink} ${classes.p3}`);
      setcardtabCss(
        `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`
      );
      setcheckMoneyOrdertabCss(`${navlinkClass.navLink} ${classes.p3}`);
      isCardClicked = true;
    } else if (
      Selectedoption === "CheckMoney-Order-tab" &&
      paymentOrigin !== "Pay By Phone" &&
      !diffStoreErrLbl
    ) {
      setcashselected(2);
      setcashtabCss(`${navlinkClass.navLink} ${classes.p3}`);
      setcardtabCss(`${navlinkClass.navLink} ${classes.p3}`);
      setcheckMoneyOrdertabCss(
        `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`
      );
    }

    if (isCardClicked && multipleCard === undefined) {
      const TempCard_Array: any = new Array(CardRadiArr.length).fill(false);
      setCardRadiArr(TempCard_Array);
    }
  };

  const addmoneyord = () => {
    setcheckmonvar("2");
    setadddata(temp);
    setaddmoneyorder(true);
    setbutton(false);
    setchecknovalid(false);
    setType(false);
    setwrittento(false);
    setwritenby(false);
    setAmount(false);
  };
  const addcheckormoneyorder = () => {
    setcheckmonvar("1");
    setadddata(temp);
    setaddcheck(true);
    setbutton(false);
    setchecknovalid(false);
    setType(false);
    setwrittento(false);
    setwritenby(false);
    setAmount(false);
  };
  useEffect(() => {
    if (!addmoneyorder && addcheck && ConrolUpdate === "0") {
      setadddata(temp);
    }
    if (!addcheck && addmoneyorder && ConrolUpdate === "0") {
      setadddata(temp);
    }
    LoadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addcheck, addmoneyorder]);
  const LoadData = () => {
    BindGridcheck();
    let objTotalAmount = 0;
    if (adddataanother != undefined && adddataanother.length > 0) {
      objTotalAmount = adddataanother.reduce(
        (sumValue, a) => sumValue + parseFloat(a.Amount_rac),
        0
      );
    }

    let isCMOExists = false;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (tendertypeglobalarray[i].tendertype === "Check & Money Order") {
        isCMOExists = true;
        tendertypeglobalarray[i].Amount = fixedChange(String(objTotalAmount));
        break;
      }
    }

    if (!isCMOExists && adddataanother.length > 0) {
      let tendertypedetailsch = {
        tendertype: "Check & Money Order",
        Amount: fixedChange(String(objTotalAmount)),
      };
      tendertypeglobalarray.push(tendertypedetailsch);
    }
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        isCMOExists === true &&
        tendertypeglobalarray[i].tendertype === "Check & Money Order" &&
        tendertypeglobalarray[i].Amount === "0.00"
      ) {
        tendertypeglobalarray.splice(i, 1);
        break;
      }
    }
    settotalduetendertype(tendertypeglobalarray);
    settotalamountcheck(objTotalAmount);
    let chargeCardAmount = 0;
    let swipeCardAmount = 0;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Ending with")
      ) {
        chargeCardAmount =
          chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Swipe ending")
      ) {
        swipeCardAmount =
          swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
    const cardamount = Number(
      multipleCard.length == 0
        ? 0.0
        : multipleCard
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
    );
    let objPayAmount =
      Number(RemaniningAmtStatic) -
      objTotalAmount -
      Number(addcash) -
      cardamount -
      Number(chargeCardAmount) -
      Number(swipeCardAmount);
    objPayAmount = Number(objPayAmount.toFixed(2));
    let isChecked = 0;
    for (let i = 0; i < document.getElementsByName("grid").length; i++) {
      if (
        (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
        true
      ) {
        isChecked = 1;
      }
    }
    if (objPayAmount <= 0 && isChecked == 1) {
      setchange(Math.abs(objPayAmount).toFixed(2));
      setremainingamountdue(0.0);
      let chargeCardAmount = 0;
      let swipeCardAmount = 0;
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Ending with")
        ) {
          chargeCardAmount =
            chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Swipe ending")
        ) {
          swipeCardAmount =
            swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }

      // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
      let cardamount = Number(
        multipleCard.length == 0
          ? 0.0
          : multipleCard
              .map((bill) => bill.Amount)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
      );
      cardamount =
        Number(cardamount) + Number(chargeCardAmount) + Number(swipeCardAmount);
      if (cardamount > 0 && cvvTextValue.length < 3 && swipeFlag == false) {
        setAcceptpaymentbuttonenable(true);
      } else {
        setAcceptpaymentbuttonenable(false);
      }
    } else {
      setremainingamountdue(objPayAmount.toFixed(2));
      setchange(0.0);
      setAcceptpaymentbuttonenable(true);
    }

    FormAcceptPaymentData(0, 0);
    tenderAmountCalculation()
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type: "Delete Check and Money Order"
    }
    setUserTraceData([...userTraceData,traceObj])

  };

  const checkormoneyordercancel = () => {
    setConrolUpdate("0");
    setaddcheck(false);
    setaddmoneyorder(false);
    setcancelindication(1);
  };

  const AutoLoadAct_btn = async () => {
    let pagGridFilters = paygridvalues.filter(
      (x) => x.Agreementid == Number(agreementId)
    );
    let otherAct_Prop;
    let custCoverageresp: any;
    custCoverageresp = await CustomerCoverage(
      pagGridFilters[0]?.Agreementid,
      customerId,
      paygridvalues[selectedGrid].dueDateModified == true ||
        paygridvalues[selectedGrid].editSchedule != ""
        ? paygridvalues[selectedGrid].Nextduedate
        : ""
    );
    const resp: custCoverage = {
      ldwAmount:
        pagGridFilters[0]?.agrLdwAmount !== undefined &&
        pagGridFilters[0]?.agrLdwAmount > 0
          ? Math.trunc(
              Number(pagGridFilters[0]?.agrLdwAmount) +
                Number(pagGridFilters[0]?.ldwTaxAmount) * 100
            ) / 100
          : custCoverageresp.data.ldwInfo === undefined ||
            custCoverageresp.data.ldwInfo === null
          ? ""
          : custCoverageresp.data.ldwInfo.ldwAmountWithTax,
      ldwTax: Number(
        pagGridFilters[0]?.agrLdwAmount !== undefined &&
          pagGridFilters[0]?.agrLdwAmount > 0
          ? Math.trunc(Number(paygridvalues[selectedGrid].ldwTaxAmount) * 100) /
              100
          : custCoverageresp.data.ldwInfo === undefined ||
            custCoverageresp.data.ldwInfo === null
          ? 0
          : custCoverageresp.data.ldwInfo.ldwAmountWithTax -
            custCoverageresp.data.ldwInfo.ldwAmount
      ),
      policyAmount:
        custCoverageresp.data.policyInfo === undefined ||
        custCoverageresp.data.policyInfo === null
          ? ""
          : custCoverageresp.data.policyInfo.policyAmountWithTax,
      policyTax:
        custCoverageresp.data.policyInfo === undefined ||
        custCoverageresp.data.policyInfo === null
          ? 0
          : custCoverageresp.data.policyInfo.policyAmountWithTax -
            custCoverageresp.data.policyInfo.policyAmount,
      isLDW: custCoverageresp.data.storeCoverageStatus.isLDWAllowed,
      isPolicy: custCoverageresp.data.storeCoverageStatus.isPolicyAllowed,
    };

    setcustomerCoverageObject(resp);
    otherAct_Prop = resp;
    if (
      custCoverageresp.status === 200 &&
      paygridvalues[0].Agreementtype !== "Club"
    ) {
      if (
        (otherAct_Prop.isPolicy === true || otherAct_Prop.isLDW == true) &&
        (custCoverageresp.data.ldwInfo !== null ||
          custCoverageresp.data.policyInfo !== null)
      ) {
        setOtherActionHidden(false);
        //setshowLDWPolicy(false);
      }
      if (custCoverageresp !== undefined) {
        if (actLDWArr.length == 0) {
          if (
            custCoverageresp.data.ldwInfo.ldwStatus === "A" &&
            paygridvalues[selectedGrid].number_of_paymentsmade > 0
          ) {
            setActionState(2);
          } else {

            setActionState(1);
          }
        } else {
          let objLdwAction = actLDWArr.filter(
            (x) =>
              x.agreementId == Number(paygridvalues[selectedGrid].Agreementid)
          );
          if (objLdwAction[0] != undefined) {
            if (objLdwAction[0].action === 1) {

              setActionState(1);
            } else {

              setActionState(2);
            }
          } else {
            if (
              custCoverageresp.data.ldwInfo.ldwStatus === "A" &&
              paygridvalues[selectedGrid].number_of_paymentsmade > 0
            ) {
              setActionState(2);
            } else {
              setActionState(1);
            }
          }
        }
        if (custCoverageresp.data.policyInfo.policyStatus === "A") {
          setpolicyService_state(2);
        } else {
          setpolicyService_state(1);
        }
      }
    } else if (paygridvalues[0].Nextduedate === "PIF Rent") {
      if (
        custCoverageresp.data.ldwInfo.ldwStatus === "A" &&
        paygridvalues[0].number_of_paymentsmade > 0
      ) {
        LDW_apiCall(2);
      }
      if (custCoverageresp.data.policyInfo.policyStatus === "A") {
        Policy_apiCall(2);
      }
    }
  };



  const loadOtherActions = async (agreementIndex?: number,configPermissions?:any) => {

    let count = 0;
    let otherAct_Prop;
    let custCoverageresp: any;
    let checkBoxArray = document.getElementsByName("grid");

    let objAmt = 0;

    if (agreementIndex != undefined) {
      count = 1;
      selectedGrid = agreementIndex;
    } else {
      for (let i = 0; i < document.getElementsByName("grid").length; i++) {

        if (
          (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
          true
        ) {
          selectedGrid = i;
          count++;
        }
      }
    }
    if (
      count == 1&&
      paygridvalues[selectedGrid]!=undefined&&
      paygridvalues[selectedGrid]?.number_of_paymentsmade > 0 &&
      paygridvalues[selectedGrid]?.otherIncomeType==''&&
      paygridvalues[selectedGrid]?.Agreementtype!="Club"&&
      paygridvalues[selectedGrid]?.Agreementtype != "PIF Rent" &&
        paygridvalues[selectedGrid]?.Agreementtype != "EPO Rent"
    ) {
      checkBoxArray.forEach(function (item, index) {
        let obj = item as HTMLInputElement;
        obj.disabled = true;
      });
      setotherActionSpinner(true);
      if(configFlags.commonModuleFlag==0||configPermissions?.commonModuleFlag==0){
        custCoverageresp = await CustomerCoverage(
          paygridvalues[selectedGrid].Agreementid,
          customerId,
          paygridvalues[selectedGrid].dueDateModified == true ||
            paygridvalues[selectedGrid].FreeTime != null ||
            paygridvalues[selectedGrid].editSchedule != ""
            ? paygridvalues[selectedGrid].Nextduedate
            : ""
        );
      }
      else if(configFlags.commonModuleFlag==1||configPermissions?.commonModuleFlag==1){
        paygridvalues[selectedGrid].toggleOptionalService=(paygridvalues[selectedGrid].isLdwCoverageAllowed=='Y'||paygridvalues[selectedGrid].isPolicyCoverageAllowed=='Y')?'Y':''
        let commonModulePayload = await calculationModulePayloadFormation( {
          customerId:customerId,
          storeNumber:window.sessionStorage.getItem("storeNumber"),
          checkedAgr:[paygridvalues[selectedGrid]]})
        let commonModuleServiceRes = await calculatePaymentModule(commonModulePayload)
        paygridvalues[selectedGrid].toggleOptionalService=''
        commonModuleServiceRes=commonModuleServiceRes.data
        let commonModuleAgrRes = commonModuleServiceRes?.paymentDetails[0]?.agreementPaymentDetails.filter(
          (x) =>
            String(x.agreementId) ===
            String(paygridvalues[selectedGrid].Agreementid)
        );
        let isLDWActionPerformed:any=[]
        // if (actLDWArr.length) {
        //   isLDWActionPerformed = actLDWArr.filter(
        //     (x) => String(x.agreementId) ==String(paygridvalues[selectedGrid].Agreementid)
        //   );
        //   if (isLDWActionPerformed.length) {
        //     isLDWActionPerformed[0].action =
        //       isLDWActionPerformed[0].action == 1 ? 'A' : 'D';
        //   }
        // }
        let isPolicyActionPerformed:any=[]
        // if (actPolicyArr.length) {
        //   isPolicyActionPerformed = actPolicyArr.filter(
        //     (x) => String(x.agreementId) ==String(paygridvalues[selectedGrid].Agreementid)
        //   );
        //   if (isPolicyActionPerformed.length) {
        //     isPolicyActionPerformed[0].action =
        //       isPolicyActionPerformed[0].action == 1 ? 'A' : 'D';
        //   }
        // }
        let ldw=commonModuleAgrRes[0]?.amountDetails?.filter((x)=>x.itemCategory == "LDW"||x.itemCategory == "LTLDW")
        let policyAmount =commonModuleAgrRes[0]?.amountDetails?.filter((x)=>x.itemCategory=='RACTIREPAY')
      //   custCoverageresp = {"status":200,"data":{
          
      //     "agreementId": paygridvalues[selectedGrid].Agreementid,
      //     "customerId":customerId,
      //     "ldwInfo":paygridvalues[selectedGrid].isLdwCoverageAllowed=='Y'? {
      //         "ldwStatus": paygridvalues[selectedGrid].coverageStatus=='Y'?'A':'D',
      //         "ldwAmount": isLDWActionPerformed?.length==0&&paygridvalues[selectedGrid].coverageStatus=='Y'?ldw[0]?.amount:(Number(paygridvalues[selectedGrid].agrLdwAmount)).toFixed(2),
      //         "ldwAmountWithTax": isLDWActionPerformed?.length==0&&paygridvalues[selectedGrid].coverageStatus=='Y'?(Number(ldw[0]?.amount)+Number(ldw[0].tax)).toFixed(2):(Number(paygridvalues[selectedGrid].agrLdwAmount)+Number(checkedAgr[0].ldwTaxAmount)).toFixed(2),
      //         "ldwTaxRate": ldw[0]?.taxRate,
      //         "scheduleRates": {
      //             "weeklyRate":commonModuleAgrRes[0]?.agreementRateDetails.weeklyLdwRate?Number(commonModuleAgrRes[0]?.agreementRateDetails.weeklyLdwRate):Number(paygridvalues[selectedGrid].agreementRateDetails.weeklyLdwRate),
      //             "biweeklyRate": commonModuleAgrRes[0]?.agreementRateDetails.biweeklyLdwRate?Number(commonModuleAgrRes[0]?.agreementRateDetails.biweeklyLdwRate):Number(paygridvalues[selectedGrid].agreementRateDetails.biweeklyLdwRate),
      //             "semimonthlyRate": commonModuleAgrRes[0]?.agreementRateDetails.semimonthlyLdwRate?Number(commonModuleAgrRes[0]?.agreementRateDetails.semimonthlyLdwRate):Number(paygridvalues[selectedGrid].agreementRateDetails.semimonthlyLdwRate),
      //             "monthlyRate": commonModuleAgrRes[0]?.agreementRateDetails.monthlyLdwRate?Number(commonModuleAgrRes[0]?.agreementRateDetails.monthlyLdwRate):Number(paygridvalues[selectedGrid].agreementRateDetails.monthlyLdwRate)
      //         }
      //     }:null,
      //     "storeCoverageStatus": {
      //         "isLDWAllowed": paygridvalues[selectedGrid].isLdwCoverageAllowed=='Y'?true:false,
      //         "isPolicyAllowed":paygridvalues[selectedGrid].isPolicyCoverageAllowed=='Y'?true:false,
      //     },
      //     "policyInfo":paygridvalues[selectedGrid].isPolicyCoverageAllowed=='Y'?
      //     {
      //       "policyStatus":  paygridvalues[selectedGrid].coverageStatus=='Y'?'A':'D',
      //       "policyAmount": isPolicyActionPerformed?.length==0&&paygridvalues[selectedGrid].coverageStatus=='Y'?policyAmount[0]?.amount:(Number(paygridvalues[selectedGrid].policyAmount)).toFixed(2),
      //       "policyAmountWithTax": isPolicyActionPerformed?.length==0&&paygridvalues[selectedGrid].coverageStatus=='Y'?(Number(policyAmount[0]?.amount)+Number(policyAmount[0].tax)).toFixed(2):(Number(paygridvalues[selectedGrid].policyAmount)+Number(paygridvalues[selectedGrid].policyTaxAmount)).toFixed(2),
      //       "policyTaxRate": policyAmount[0]?.taxRate,
      //       "scheduleRates": {
      //         "weeklyRate":commonModuleAgrRes[0]?.agreementRateDetails.weeklyPolicyRate?Number(commonModuleAgrRes[0]?.agreementRateDetails.weeklyPolicyRate):Number(paygridvalues[selectedGrid].agreementRateDetails.weeklyPolicyRate),
      //         "biweeklyRate": commonModuleAgrRes[0]?.agreementRateDetails.biweeklyPolicyRate?Number(commonModuleAgrRes[0]?.agreementRateDetails.biweeklyPolicyRate):Number(paygridvalues[selectedGrid].agreementRateDetails.biweeklyPolicyRate),
      //         "semimonthlyRate": commonModuleAgrRes[0]?.agreementRateDetails.semimonthlyPolicyRate?Number(commonModuleAgrRes[0]?.agreementRateDetails.semimonthlyPolicyRate):Number(paygridvalues[selectedGrid].agreementRateDetails.semimonthlyPolicyRate),
      //         "monthlyRate": commonModuleAgrRes[0]?.agreementRateDetails.monthlyPolicyRate?Number(commonModuleAgrRes[0]?.agreementRateDetails.monthlyPolicyRate):Number(paygridvalues[selectedGrid].agreementRateDetails.monthlyPolicyRate)
      //       }
      //   }
      //     : null
      // }
      // }}
       custCoverageresp = {"status":200,"data":{
          
        "agreementId": paygridvalues[selectedGrid].Agreementid,
        "customerId":customerId,
        "ldwInfo":paygridvalues[selectedGrid].isLdwCoverageAllowed=='Y'&&ldw?.length>0? {
            "ldwStatus": paygridvalues[selectedGrid].toggleOptionalServicePerformed==''?(paygridvalues[selectedGrid].coverageStatus=='Y'?'A':null): paygridvalues[selectedGrid].toggleOptionalServicePerformed=='Y'?'A':null,
            "ldwAmount": ldw[0]?.amount,
            "ldwAmountWithTax": (Number(ldw[0]?.amount)+Number(ldw[0].tax)).toFixed(2),
            "ldwTaxRate": ldw[0]?.taxRate,
            "scheduleRates": {
                "weeklyRate":commonModuleAgrRes[0]?.agreementRateDetails.weeklyLdw?Number(commonModuleAgrRes[0]?.agreementRateDetails.weeklyLdw):Number(paygridvalues[selectedGrid].agreementRateDetails.weeklyLdw),
                "biweeklyRate": commonModuleAgrRes[0]?.agreementRateDetails.biWeeklyLdw?Number(commonModuleAgrRes[0]?.agreementRateDetails.biWeeklyLdw):Number(paygridvalues[selectedGrid].agreementRateDetails.biWeeklyLdw),
                "semimonthlyRate": commonModuleAgrRes[0]?.agreementRateDetails.semiMonthlyLdw?Number(commonModuleAgrRes[0]?.agreementRateDetails.semiMonthlyLdw):Number(paygridvalues[selectedGrid].agreementRateDetails.semiMonthlyLdw),
                "monthlyRate": commonModuleAgrRes[0]?.agreementRateDetails.monthlyLdw?Number(commonModuleAgrRes[0]?.agreementRateDetails.monthlyLdw):Number(paygridvalues[selectedGrid].agreementRateDetails.monthlyLdw)
            }
        }:null,
        "storeCoverageStatus": {
            "isLDWAllowed": paygridvalues[selectedGrid].isLdwCoverageAllowed=='Y'?true:false,
            "isPolicyAllowed":paygridvalues[selectedGrid].isPolicyCoverageAllowed=='Y'?true:false,
        },
        "policyInfo":paygridvalues[selectedGrid].isPolicyCoverageAllowed=='Y'&&policyAmount?.length>0?
        {
          "policyStatus": paygridvalues[selectedGrid].toggleOptionalServicePerformed==''?(paygridvalues[selectedGrid].coverageStatus=='Y'?'A':null): paygridvalues[selectedGrid].toggleOptionalServicePerformed=='Y'?'A':null,
          "policyAmount": policyAmount[0]?.amount,
          "policyAmountWithTax": (Number(policyAmount[0]?.amount)+Number(policyAmount[0].tax)).toFixed(2),
          "policyTaxRate": policyAmount[0]?.taxRate,
          "scheduleRates": {
            "weeklyRate":commonModuleAgrRes[0]?.agreementRateDetails.weeklyPolicy?Number(commonModuleAgrRes[0]?.agreementRateDetails.weeklyPolicy):Number(paygridvalues[selectedGrid].agreementRateDetails.weeklyPolicy),
            "biweeklyRate": commonModuleAgrRes[0]?.agreementRateDetails.biWeeklyPolicy?Number(commonModuleAgrRes[0]?.agreementRateDetails.biWeeklyPolicy):Number(paygridvalues[selectedGrid].agreementRateDetails.biWeeklyPolicy),
            "semimonthlyRate": commonModuleAgrRes[0]?.agreementRateDetails.semiMonthlyPolicy?Number(commonModuleAgrRes[0]?.agreementRateDetails.semiMonthlyPolicy):Number(paygridvalues[selectedGrid].agreementRateDetails.semiMonthlyPolicy),
            "monthlyRate": commonModuleAgrRes[0]?.agreementRateDetails.monthlyPolicy?Number(commonModuleAgrRes[0]?.agreementRateDetails.monthlyPolicy):Number(paygridvalues[selectedGrid].agreementRateDetails.monthlyPolicy)
          }
      }
        : null
    }
    }}
      
      checkBoxArray.forEach(function (item, index) {
        let obj = item as HTMLInputElement;
        obj.disabled = false;
      });
      setotherActionSpinner(false);
      setotherActState(custCoverageresp);
      if (custCoverageresp.status === 200) {
        const resp: custCoverage = {
          ldwAmount:
            (paygridvalues[selectedGrid].agrLdwAmount !== undefined &&
              paygridvalues[selectedGrid].agrLdwAmount > 0) ||
            (paygridvalues[selectedGrid].agrLdwAmount == 0 &&
              custCoverageresp.data.ldwInfo?.ldwStatus === "A" &&
              ActionState === 2)
              ? Math.trunc(
                  (Number(paygridvalues[selectedGrid].agrLdwAmount) +
                    Number(paygridvalues[selectedGrid].ldwTaxAmount)) *
                    100
                ) / 100
              : custCoverageresp.data.ldwInfo === undefined ||
                custCoverageresp.data.ldwInfo === null
              ? ""
              : custCoverageresp.data.ldwInfo.ldwAmountWithTax,

          ldwTax: Number(
            (paygridvalues[selectedGrid].agrLdwAmount !== undefined &&
              paygridvalues[selectedGrid].agrLdwAmount > 0) ||
              (paygridvalues[selectedGrid].agrLdwAmount == 0 &&
                custCoverageresp.data.ldwInfo?.ldwStatus === "A" &&
                ActionState === 2)
              ? Math.trunc(
                  Number(paygridvalues[selectedGrid].ldwTaxAmount) * 100
                ) / 100
              : custCoverageresp.data.ldwInfo === undefined ||
                custCoverageresp.data.ldwInfo === null
              ? 0
              : custCoverageresp.data.ldwInfo.ldwAmountWithTax -
                custCoverageresp.data.ldwInfo.ldwAmount
          ),
          policyAmount:
            (paygridvalues[selectedGrid].policyAmount !== undefined &&
              paygridvalues[selectedGrid].policyAmount > 0) ||
            (paygridvalues[selectedGrid].policyAmount == 0 &&
              custCoverageresp.data.policyInfo?.policyStatus === "A" &&
              policyService_state === 2)
              ? Math.trunc(
                  (Number(paygridvalues[selectedGrid].policyAmount) +
                    Number(paygridvalues[selectedGrid].policyTaxAmount)) *
                    100
                ) / 100
              : custCoverageresp.data.policyInfo === undefined ||
                custCoverageresp.data.policyInfo === null
              ? ""
              : custCoverageresp.data.policyInfo.policyAmountWithTax,
          policyTax:
            (paygridvalues[selectedGrid].policyAmount !== undefined &&
              paygridvalues[selectedGrid].policyAmount > 0) ||
            (paygridvalues[selectedGrid].policyAmount == 0 &&
              custCoverageresp.data.policyInfo?.policyStatus === "A" &&
              policyService_state === 2)
              ? Math.trunc(
                  Number(paygridvalues[selectedGrid].policyTaxAmount) * 100
                ) / 100
              : custCoverageresp.data.policyInfo === undefined ||
                custCoverageresp.data.policyInfo === null
              ? 0
              : custCoverageresp.data.policyInfo.policyAmountWithTax -
                custCoverageresp.data.policyInfo.policyAmount,
          isLDW: custCoverageresp.data.storeCoverageStatus.isLDWAllowed,
          isPolicy: custCoverageresp.data.storeCoverageStatus.isPolicyAllowed,
          ldwInfo: custCoverageresp.data.ldwInfo,
        };
        setshowLDWPolicy(true);
        setcustomerCoverageObject(resp);
        let adjustedTerminateLdwAmount = 0;
        let adjustedTerminateLdwTaxAmount = 0;
        let totalAdjustedLdwAmount = "0";
        // if(paygridvalues[selectedGrid].dueDateModified==true||paygridvalues[selectedGrid].editSchedule!=""){
        //   adjustedTerminateLdwAmount=Number(paygridvalues[selectedGrid].agrLdwAmount)
        //   adjustedTerminateLdwTaxAmount=Number(paygridvalues[selectedGrid].ldwTaxAmount)
        //   totalAdjustedLdwAmount=(adjustedTerminateLdwAmount+adjustedTerminateLdwTaxAmount).toFixed(2)
        // }
        setadjustTerminateLdwAmount(totalAdjustedLdwAmount);
        otherAct_Prop = resp;
        if (
          paygridvalues[selectedGrid].Agreementtype !== "Club" &&
          paygridvalues[selectedGrid].Nextduedate !== "PIF Rent"
        ) {
          if (custCoverageresp !== undefined) {
            if (custCoverageresp.data.ldwInfo !== null) {
              if (actLDWArr.length == 0) {
                if (
                  custCoverageresp.data.ldwInfo.ldwStatus === "A" &&
                  paygridvalues[selectedGrid].number_of_paymentsmade > 0
                ) {
                  setActionState(2);
                } else {
                  setActionState(1);
                }
              } else {
                let objLdwAction = actLDWArr.filter(
                  (x) =>
                    x.agreementId ==
                    Number(paygridvalues[selectedGrid].Agreementid)
                );
                if (objLdwAction[0] != undefined) {
                  if (objLdwAction[0].action === 1) {
                    setActionState(2);
                  } else {
                    setActionState(1);
                  }
                } else {

                  if (
                    custCoverageresp.data.ldwInfo.ldwStatus === "A" &&
                    paygridvalues[selectedGrid].number_of_paymentsmade > 0
                  ) {
          
                    setActionState(2);
                  } else {
      
                    setActionState(1);
                  }
                }
              }
            }
            if (custCoverageresp.data.policyInfo !== null) {
              if (actPolicyArr.length == 0) {
                if (custCoverageresp.data.policyInfo.policyStatus === "A") {
                  setpolicyService_state(2);
                } else {
                  setpolicyService_state(1);
                }
              } else {
                let objPolicyAction = actPolicyArr.filter(
                  (x) =>
                    x.agreementId ==
                    Number(paygridvalues[selectedGrid].Agreementid)
                );
                if (objPolicyAction[0] != undefined) {
                  if (objPolicyAction[0].action === 1) {
                    setpolicyService_state(2);
                  } else {
                    setpolicyService_state(1);
                  }
                } else {
                  if (custCoverageresp.data.policyInfo.policyStatus === "A") {
                    setpolicyService_state(2);
                  } else {
                    setpolicyService_state(1);
                  }
                }
              }
            }
          }
        } else if (paygridvalues[selectedGrid].Nextduedate === "PIF Rent") {
          if (
            custCoverageresp.data.ldwInfo.ldwStatus === "A" &&
            paygridvalues[selectedGrid].number_of_paymentsmade > 0
          ) {
            LDW_apiCall(2);
          }
          if (custCoverageresp.data.policyInfo.policyStatus === "A") {
            Policy_apiCall(2);
          }
        }
      } else {
        setshowLDWPolicy(false);
      }
    } else {
      setOtherActionHidden(true);
      setshowLDWPolicy(false);
    }
  // }
  };
  const commitmentLoadAction = async (agreementIndex?: number,configPermissions?:any) => {
    let count = 0;
    let otherAct_Prop;
    let custCoverageresp: any;
    let checkBoxArray = document.getElementsByName("grid");

    //let objAmt = 0;

    if (agreementIndex != undefined) {
      count = 1;
      selectedGrid = agreementIndex;
    } else {
      for (let i = 0; i < document.getElementsByName("grid").length; i++) {

        if (
          (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
          true
        ) {
          selectedGrid = i;
          count++;
        }
      }
    }

    if (
      count == 1&&
      paygridvalues[selectedGrid]!=undefined&&
      paygridvalues[selectedGrid]?.number_of_paymentsmade > 0 &&
      paygridvalues[selectedGrid]?.otherIncomeType==''&&
      paygridvalues[selectedGrid]?.Agreementtype!="Club"&&
      (paygridvalues[selectedGrid]?.Agreementtype != "PIF Rent" ||
        paygridvalues[selectedGrid]?.Agreementtype != "EPO Rent")
    ) {
      checkBoxArray.forEach(function (item, index) {
        let obj = item as HTMLInputElement;
        obj.disabled = true;
      });
      setotherActionSpinner(true);
      if(configPermissions==undefined?configFlags.commonModuleFlag==0:configPermissions?.commonModuleFlag==0){
        custCoverageresp = await CustomerCoverage(
          paygridvalues[selectedGrid].Agreementid,
          customerId,
          paygridvalues[selectedGrid].dueDateModified == true ||
            paygridvalues[selectedGrid].FreeTime != null ||
            paygridvalues[selectedGrid].editSchedule != ""
            ? paygridvalues[selectedGrid].Nextduedate
            : ""
        );
      }
      else if(configPermissions==undefined?configFlags.commonModuleFlag==1:configPermissions?.commonModuleFlag==1){
        paygridvalues[selectedGrid].toggleOptionalService=(paygridvalues[selectedGrid].isLdwCoverageAllowed=='Y'||paygridvalues[selectedGrid].isPolicyCoverageAllowed=='Y')?'Y':''
        let commonModulePayload = await calculationModulePayloadFormation( {
          customerId:customerId,
          storeNumber:window.sessionStorage.getItem("storeNumber"),
          checkedAgr:[paygridvalues[selectedGrid]]})
        let commonModuleServiceRes = await calculatePaymentModule(commonModulePayload)
        paygridvalues[selectedGrid].toggleOptionalService=''
        commonModuleServiceRes=commonModuleServiceRes.data
        let commonModuleAgrRes = commonModuleServiceRes?.paymentDetails[0]?.agreementPaymentDetails.filter(
          (x) =>
            String(x.agreementId) ===
            String(paygridvalues[selectedGrid].Agreementid)
        );
        let isLDWActionPerformed:any=[]
        // if (actLDWArr.length) {
        //   isLDWActionPerformed = actLDWArr.filter(
        //     (x) => String(x.agreementId) ==String(paygridvalues[selectedGrid].Agreementid)
        //   );
        //   if (isLDWActionPerformed.length) {
        //     isLDWActionPerformed[0].action =
        //       isLDWActionPerformed[0].action == 1 ? 'A' : 'D';
        //   }
        // }
        let isPolicyActionPerformed:any=[]
        // if (actPolicyArr.length) {
        //   isPolicyActionPerformed = actPolicyArr.filter(
        //     (x) => String(x.agreementId) ==String(paygridvalues[selectedGrid].Agreementid)
        //   );
        //   if (isPolicyActionPerformed.length) {
        //     isPolicyActionPerformed[0].action =
        //       isPolicyActionPerformed[0].action == 1 ? 'A' : 'D';
        //   }
        // }
        let ldw=commonModuleAgrRes[0]?.amountDetails?.filter((x)=>x.itemCategory == "LDW"||x.itemCategory == "LTLDW")
        let policyAmount =commonModuleAgrRes[0]?.amountDetails?.filter((x)=>x.itemCategory=='RACTIREPAY')
      //   custCoverageresp = {"status":200,"data":{
          
      //     "agreementId": paygridvalues[selectedGrid].Agreementid,
      //     "customerId":customerId,
      //     "ldwInfo":paygridvalues[selectedGrid].isLdwCoverageAllowed=='Y'? {
      //         "ldwStatus": paygridvalues[selectedGrid].coverageStatus=='Y'?'A':'D',
      //         "ldwAmount": isLDWActionPerformed?.length==0&&paygridvalues[selectedGrid].coverageStatus=='Y'?ldw[0]?.amount:(Number(paygridvalues[selectedGrid].agrLdwAmount)).toFixed(2),
      //         "ldwAmountWithTax": isLDWActionPerformed?.length==0&&paygridvalues[selectedGrid].coverageStatus=='Y'?(Number(ldw[0]?.amount)+Number(ldw[0].tax)).toFixed(2):(Number(paygridvalues[selectedGrid].agrLdwAmount)+Number(checkedAgr[0].ldwTaxAmount)).toFixed(2),
      //         "ldwTaxRate": ldw[0]?.taxRate,
      //         "scheduleRates": {
      //             "weeklyRate":commonModuleAgrRes[0]?.agreementRateDetails.weeklyLdwRate?Number(commonModuleAgrRes[0]?.agreementRateDetails.weeklyLdwRate):Number(paygridvalues[selectedGrid].agreementRateDetails.weeklyLdwRate),
      //             "biweeklyRate": commonModuleAgrRes[0]?.agreementRateDetails.biweeklyLdwRate?Number(commonModuleAgrRes[0]?.agreementRateDetails.biweeklyLdwRate):Number(paygridvalues[selectedGrid].agreementRateDetails.biweeklyLdwRate),
      //             "semimonthlyRate": commonModuleAgrRes[0]?.agreementRateDetails.semimonthlyLdwRate?Number(commonModuleAgrRes[0]?.agreementRateDetails.semimonthlyLdwRate):Number(paygridvalues[selectedGrid].agreementRateDetails.semimonthlyLdwRate),
      //             "monthlyRate": commonModuleAgrRes[0]?.agreementRateDetails.monthlyLdwRate?Number(commonModuleAgrRes[0]?.agreementRateDetails.monthlyLdwRate):Number(paygridvalues[selectedGrid].agreementRateDetails.monthlyLdwRate)
      //         }
      //     }:null,
      //     "storeCoverageStatus": {
      //         "isLDWAllowed": paygridvalues[selectedGrid].isLdwCoverageAllowed=='Y'?true:false,
      //         "isPolicyAllowed":paygridvalues[selectedGrid].isPolicyCoverageAllowed=='Y'?true:false,
      //     },
      //     "policyInfo":paygridvalues[selectedGrid].isPolicyCoverageAllowed=='Y'?
      //     {
      //       "policyStatus":  paygridvalues[selectedGrid].coverageStatus=='Y'?'A':'D',
      //       "policyAmount": isPolicyActionPerformed?.length==0&&paygridvalues[selectedGrid].coverageStatus=='Y'?policyAmount[0]?.amount:(Number(paygridvalues[selectedGrid].policyAmount)).toFixed(2),
      //       "policyAmountWithTax": isPolicyActionPerformed?.length==0&&paygridvalues[selectedGrid].coverageStatus=='Y'?(Number(policyAmount[0]?.amount)+Number(policyAmount[0].tax)).toFixed(2):(Number(paygridvalues[selectedGrid].policyAmount)+Number(paygridvalues[selectedGrid].policyTaxAmount)).toFixed(2),
      //       "policyTaxRate": policyAmount[0]?.taxRate,
      //       "scheduleRates": {
      //         "weeklyRate":commonModuleAgrRes[0]?.agreementRateDetails.weeklyPolicyRate?Number(commonModuleAgrRes[0]?.agreementRateDetails.weeklyPolicyRate):Number(paygridvalues[selectedGrid].agreementRateDetails.weeklyPolicyRate),
      //         "biweeklyRate": commonModuleAgrRes[0]?.agreementRateDetails.biweeklyPolicyRate?Number(commonModuleAgrRes[0]?.agreementRateDetails.biweeklyPolicyRate):Number(paygridvalues[selectedGrid].agreementRateDetails.biweeklyPolicyRate),
      //         "semimonthlyRate": commonModuleAgrRes[0]?.agreementRateDetails.semimonthlyPolicyRate?Number(commonModuleAgrRes[0]?.agreementRateDetails.semimonthlyPolicyRate):Number(paygridvalues[selectedGrid].agreementRateDetails.semimonthlyPolicyRate),
      //         "monthlyRate": commonModuleAgrRes[0]?.agreementRateDetails.monthlyPolicyRate?Number(commonModuleAgrRes[0]?.agreementRateDetails.monthlyPolicyRate):Number(paygridvalues[selectedGrid].agreementRateDetails.monthlyPolicyRate)
      //       }
      //   }
      //     : null
      // }
      // }}
       custCoverageresp = {"status":200,"data":{
          
        "agreementId": paygridvalues[selectedGrid].Agreementid,
        "customerId":customerId,
        "ldwInfo":paygridvalues[selectedGrid].isLdwCoverageAllowed=='Y'&&ldw?.length>0? {
            "ldwStatus": paygridvalues[selectedGrid].toggleOptionalServicePerformed==''?(paygridvalues[selectedGrid].coverageStatus=='Y'?'A':null): paygridvalues[selectedGrid].toggleOptionalServicePerformed=='Y'?'A':null,
            "ldwAmount": ldw[0]?.amount,
            "ldwAmountWithTax": (Number(ldw[0]?.amount)+Number(ldw[0].tax)).toFixed(2),
            "ldwTaxRate": ldw[0]?.taxRate,
            "scheduleRates": {
                "weeklyRate":commonModuleAgrRes[0]?.agreementRateDetails.weeklyLdw?Number(commonModuleAgrRes[0]?.agreementRateDetails.weeklyLdw):Number(paygridvalues[selectedGrid].agreementRateDetails.weeklyLdw),
                "biweeklyRate": commonModuleAgrRes[0]?.agreementRateDetails.biWeeklyLdw?Number(commonModuleAgrRes[0]?.agreementRateDetails.biWeeklyLdw):Number(paygridvalues[selectedGrid].agreementRateDetails.biWeeklyLdw),
                "semimonthlyRate": commonModuleAgrRes[0]?.agreementRateDetails.semiMonthlyLdw?Number(commonModuleAgrRes[0]?.agreementRateDetails.semiMonthlyLdw):Number(paygridvalues[selectedGrid].agreementRateDetails.semiMonthlyLdw),
                "monthlyRate": commonModuleAgrRes[0]?.agreementRateDetails.monthlyLdw?Number(commonModuleAgrRes[0]?.agreementRateDetails.monthlyLdw):Number(paygridvalues[selectedGrid].agreementRateDetails.monthlyLdw)
            }
        }:null,
        "storeCoverageStatus": {
            "isLDWAllowed": paygridvalues[selectedGrid].isLdwCoverageAllowed=='Y'?true:false,
            "isPolicyAllowed":paygridvalues[selectedGrid].isPolicyCoverageAllowed=='Y'?true:false,
        },
        "policyInfo":paygridvalues[selectedGrid].isPolicyCoverageAllowed=='Y'&&policyAmount?.length>0?
        {
          "policyStatus": paygridvalues[selectedGrid].toggleOptionalServicePerformed==''?(paygridvalues[selectedGrid].coverageStatus=='Y'?'A':null): paygridvalues[selectedGrid].toggleOptionalServicePerformed=='Y'?'A':null,
          "policyAmount": policyAmount[0]?.amount,
          "policyAmountWithTax": (Number(policyAmount[0]?.amount)+Number(policyAmount[0].tax)).toFixed(2),
          "policyTaxRate": policyAmount[0]?.taxRate,
          "scheduleRates": {
            "weeklyRate":commonModuleAgrRes[0]?.agreementRateDetails.weeklyPolicy?Number(commonModuleAgrRes[0]?.agreementRateDetails.weeklyPolicy):Number(paygridvalues[selectedGrid].agreementRateDetails.weeklyPolicy),
            "biweeklyRate": commonModuleAgrRes[0]?.agreementRateDetails.biWeeklyPolicy?Number(commonModuleAgrRes[0]?.agreementRateDetails.biWeeklyPolicy):Number(paygridvalues[selectedGrid].agreementRateDetails.biWeeklyPolicy),
            "semimonthlyRate": commonModuleAgrRes[0]?.agreementRateDetails.semiMonthlyPolicy?Number(commonModuleAgrRes[0]?.agreementRateDetails.semiMonthlyPolicy):Number(paygridvalues[selectedGrid].agreementRateDetails.semiMonthlyPolicy),
            "monthlyRate": commonModuleAgrRes[0]?.agreementRateDetails.monthlyPolicy?Number(commonModuleAgrRes[0]?.agreementRateDetails.monthlyPolicy):Number(paygridvalues[selectedGrid].agreementRateDetails.monthlyPolicy)
          }
      }
        : null
    }
    }}
      setotherActState(custCoverageresp);
      checkBoxArray.forEach(function (item, index) {
        let obj = item as HTMLInputElement;
        obj.disabled = false;
      });
      setotherActionSpinner(false);
      if (custCoverageresp.status === 200) {

        const resp: custCoverage = {
          ldwAmount:
            (paygridvalues[selectedGrid].agrLdwAmount !== undefined &&
              paygridvalues[selectedGrid].agrLdwAmount > 0) ||
            (paygridvalues[selectedGrid].agrLdwAmount == 0 &&
              custCoverageresp.data.ldwInfo?.ldwStatus === "A" &&
              ActionState === 2)
              ? Math.trunc(
                  (Number(paygridvalues[selectedGrid].agrLdwAmount) +
                    Number(paygridvalues[selectedGrid].ldwTaxAmount)) *
                    100
                ) / 100
              : custCoverageresp.data.ldwInfo === undefined ||
                custCoverageresp.data.ldwInfo === null
              ? ""
              : custCoverageresp.data.ldwInfo.ldwAmountWithTax,

          ldwTax: Number(
            (paygridvalues[selectedGrid].agrLdwAmount !== undefined &&
              paygridvalues[selectedGrid].agrLdwAmount > 0) ||
              (paygridvalues[selectedGrid].agrLdwAmount == 0 &&
                custCoverageresp.data.ldwInfo?.ldwStatus === "A" &&
                ActionState === 2)
              ? Math.trunc(
                  Number(paygridvalues[selectedGrid].ldwTaxAmount) * 100
                ) / 100
              : custCoverageresp.data.ldwInfo === undefined ||
                custCoverageresp.data.ldwInfo === null
              ? 0
              : custCoverageresp.data.ldwInfo.ldwAmountWithTax -
                custCoverageresp.data.ldwInfo.ldwAmount
          ),
          policyAmount:
            (paygridvalues[selectedGrid].policyAmount !== undefined &&
              paygridvalues[selectedGrid].policyAmount > 0) ||
            (paygridvalues[selectedGrid].policyAmount == 0 &&
              custCoverageresp.data.policyInfo?.policyStatus === "A" &&
              policyService_state === 2)
              ? Math.trunc(
                  (Number(paygridvalues[selectedGrid].policyAmount) +
                    Number(paygridvalues[selectedGrid].policyTaxAmount)) *
                    100
                ) / 100
              : custCoverageresp.data.policyInfo === undefined ||
                custCoverageresp.data.policyInfo === null
              ? ""
              : custCoverageresp.data.policyInfo.policyAmountWithTax,
          policyTax:
            (paygridvalues[selectedGrid].policyAmount !== undefined &&
              paygridvalues[selectedGrid].policyAmount > 0) ||
            (paygridvalues[selectedGrid].policyAmount == 0 &&
              custCoverageresp.data.policyInfo?.policyStatus === "A" &&
              policyService_state === 2)
              ? Math.trunc(
                  Number(paygridvalues[selectedGrid].policyTaxAmount) * 100
                ) / 100
              : custCoverageresp.data.policyInfo === undefined ||
                custCoverageresp.data.policyInfo === null
              ? 0
              : custCoverageresp.data.policyInfo.policyAmountWithTax -
                custCoverageresp.data.policyInfo.policyAmount,
          isLDW: custCoverageresp.data.storeCoverageStatus.isLDWAllowed,
          isPolicy: custCoverageresp.data.storeCoverageStatus.isPolicyAllowed,
          ldwInfo: custCoverageresp.data.ldwInfo,
        };
        setshowLDWPolicy(true);
        setcustomerCoverageObject(resp);
        let adjustedTerminateLdwAmount = 0;
        let adjustedTerminateLdwTaxAmount = 0;
        let totalAdjustedLdwAmount = "0";
        // alert("Reached totalAdjustedLdwAmount");
        // if(paygridvalues[selectedGrid].dueDateModified==true||paygridvalues[selectedGrid].editSchedule!=""){
        //   adjustedTerminateLdwAmount=Number(paygridvalues[selectedGrid].agrLdwAmount)
        //   adjustedTerminateLdwTaxAmount=Number(paygridvalues[selectedGrid].ldwTaxAmount)
        //   totalAdjustedLdwAmount=(adjustedTerminateLdwAmount+adjustedTerminateLdwTaxAmount).toFixed(2)
        // }
        setadjustTerminateLdwAmount(totalAdjustedLdwAmount);
        otherAct_Prop = resp;
        if (
          paygridvalues[selectedGrid].Agreementtype !== "Club" &&
          paygridvalues[selectedGrid].Nextduedate !== "PIF Rent"
        ) {
          if (custCoverageresp !== undefined) {
            if (custCoverageresp.data.ldwInfo !== null) {
              if (actLDWArr.length == 0) {
                if (
                  custCoverageresp.data.ldwInfo.ldwStatus === "A" &&
                  paygridvalues[selectedGrid].number_of_paymentsmade > 0
                ) {
                 
                  setActionState(2);
                } else {
                
                  setActionState(1);
                }
              } else {
             
                let objLdwAction = actLDWArr.filter(
                  (x) =>
                    x.agreementId ==
                    Number(paygridvalues[selectedGrid].Agreementid)
                );
                if (objLdwAction[0] != undefined) {
                  if (objLdwAction[0].action === 1) {
                   
                    setActionState(2);
                  } else {
                    
                    setActionState(1);
                  }
                } else {
              
                  if (
                    custCoverageresp.data.ldwInfo.ldwStatus === "A" &&
                    paygridvalues[selectedGrid].number_of_paymentsmade > 0
                  ) {
                   
                    setActionState(2);
                  } else {
                 
                    setActionState(1);
                  }
                }
              }
            }
            if (custCoverageresp.data.policyInfo !== null) {
              if (actPolicyArr.length == 0) {
           
                if (custCoverageresp.data.policyInfo.policyStatus === "A") {
            
                  setpolicyService_state(2);
                } else {
                 
                  setpolicyService_state(1);
                }
              } else {
         
                let objPolicyAction = actPolicyArr.filter(
                  (x) =>
                    x.agreementId ==
                    Number(paygridvalues[selectedGrid].Agreementid)
                );
                if (objPolicyAction[0] != undefined) {
                  if (objPolicyAction[0].action === 1) {
                    setpolicyService_state(2);
                  } else {
                    setpolicyService_state(1);
                  }
                } else {
                  if (custCoverageresp.data.policyInfo.policyStatus === "A") {
                    setpolicyService_state(2);
                  } else {
                    setpolicyService_state(1);
                  }
                }
              }
            }
          }
        } else if (paygridvalues[selectedGrid].Nextduedate === "PIF Rent") {
          if (
            custCoverageresp.data.ldwInfo.ldwStatus === "A" &&
            paygridvalues[selectedGrid].number_of_paymentsmade > 0
          ) {
            LDW_apiCall(2);
          }
          if (custCoverageresp.data.policyInfo.policyStatus === "A") {
            Policy_apiCall(2);
          }
        }
        // return custCoverageresp;
        const returnData = {
          custCoverageresp: custCoverageresp,
          resp: resp,
        };
        return returnData;
      } else {
        setshowLDWPolicy(false);
      }
    } else {
      setOtherActionHidden(true);
      setshowLDWPolicy(false);
    }
  };

  const AddtotallatefeeRetention = (filterRetentionAgr: any) => {
    let addedlatevalue = 0;
    for (let i = 0; i < paygridvalues.length; i++) {
      if (
        paygridvalues[i].AgreementNumber ==
        filterRetentionAgr[0].AgreementNumber
      ) {
        addedlatevalue = Number(paygridvalues[i].lateFee) + addedlatevalue;
      }
    }
    return addedlatevalue;
  };

  //code added by the Mano Ranjith for calclating the late fee
  const Addtotallatefee = () => {
    let addedlatevalue = 0;
    for (let i = 0; i < paygridvalues.length; i++) {
      addedlatevalue = Number(paygridvalues[i].lateFee) + addedlatevalue;
    }
    return addedlatevalue;
  };

  const AddtotallatefeeSpecific = (choosenAgreements: any) => {
    let addedlatevalue = 0;
    for (let i = 0; i < choosenAgreements.length; i++) {
      addedlatevalue = Number(choosenAgreements[i].lateFee) + addedlatevalue;
    }
    return addedlatevalue;
  };

  const Subtracttotallatefee = () => {
    let subractedlatevalue = Latelast10value;
    for (let i = 0; i < paygridvalues.length; i++) {
      subractedlatevalue =
        subractedlatevalue - Number(paygridvalues[i].lateFee);
    }
    return subractedlatevalue;
  };

  const retentionSelection = (
    check?: boolean,
    e?,
    filterRetentionAgr?: any
  ) => {
    let sortableData: any = paygridvalues;
    let currentstorenumber = window.sessionStorage.getItem("storeNumber");
    let Clubagreementinsertion = sortableData.filter(
      (x) => x.Agreementtype === "Club"
    );

    let curStoreData = sortableData.filter(
      (x) =>
        x.store_number.toString() === currentstorenumber &&
        x.Agreementtype !== "Club"
    );
    Clubagreementinsertion.push(...curStoreData);
    let otherStoreData = sortableData.filter(
      (x) =>
        x.store_number.toString() !== currentstorenumber &&
        x.Agreementtype !== "Club"
    );
    Clubagreementinsertion.push(...otherStoreData);

    let isChecked;
    if (e != undefined) {
      isChecked = e.target.checked;
    } else {
      isChecked = check;
    }
    if (isChecked == true) {
      for (let i = 0; i < Clubagreementinsertion.length; i++) {
        if (
          Clubagreementinsertion[i].AgreementNumber ==
          filterRetentionAgr[0].AgreementNumber
        ) {
          selectedGrid = i;
          adjustDueDateSelectedAgre.push(Clubagreementinsertion[i].Agreementid);
          adjustDueDateSelectedAgre = Array.from(
            new Set(adjustDueDateSelectedAgre)
          );
          setadjustDueDateSelectedAgre(adjustDueDateSelectedAgre);
        }
      }
      multipleAdjust();
    }
    totalduereceiptidsupport = [];
    let noOfZeroPaidAgreement = 0;
    for (let i = 0; i < Clubagreementinsertion.length; i++) {
      if (
        Clubagreementinsertion[i].AgreementNumber ==
        filterRetentionAgr[0].AgreementNumber
      ) {
        if (Clubagreementinsertion[i].number_of_paymentsmade >= 3) {
          if (Clubagreementinsertion[i].sign_status === "") {
            settooltipdisplay(
              Clubagreementinsertion[i].Agreementid +
                "Please sign the agreement"
            );
          }
          if (Clubagreementinsertion[i].confirmeddeliversystatus === false) {
            settooltipdisplay(
              Clubagreementinsertion[i].Agreementid +
                "Please update the confirmed delivery status"
            );
          }
          if (
            Clubagreementinsertion[i].sign_status === "" &&
            Clubagreementinsertion[i].confirmeddeliversystatus === false
          ) {
            settooltipdisplay(
              Clubagreementinsertion[i].Agreementid +
                "Please sign the agreement and update the confirmed delivery status"
            );
          }
        }
        if (Clubagreementinsertion[i].number_of_paymentsmade === 0) {
          noOfZeroPaidAgreement = noOfZeroPaidAgreement + 1;
        }
      }
    }

    //......Rental Item Sale.........

    for (let i = 0; i < Clubagreementinsertion.length; i++) {
      if (
        Clubagreementinsertion[i].AgreementNumber ==
        filterRetentionAgr[0].AgreementNumber
      ) {
        if (
          isChecked != true &&
          Clubagreementinsertion[i].Agreementtype == "Rental Item Sale"
        ) {
          setadjustRisbtnFlag(false);
          setRisRemovepaymentdisableFlag(true);
        } else if (
          isChecked == true &&
          Clubagreementinsertion[i].Agreementtype == "Rental Item Sale"
        ) {
          setRisRemovepaymentdisableFlag(false);
        }
      }
    }
    if (isChecked === true) {
      setaddsuslist(false);
      setDisableTransfer(false);
      settransfersuslist(false);
      setOverAll_Chk(paygridvalues.length == 1 ? true : false);
      setSelectedCheckBoxCount(1);
      let checkedCount = 0;
      ReceiptCategoriesRequestParam = [];
      acceptPaymentParam = [];
      AcceptclubPaymentParam = undefined;
      for (let i = 0; i < Clubagreementinsertion.length; i++) {
        if (
          Clubagreementinsertion[i].AgreementNumber ==
          filterRetentionAgr[0].AgreementNumber
        ) {

          (document.getElementsByName("grid")[i] as HTMLInputElement).checked =
            true;
          adjustDueDateSelectedAgre.push(Clubagreementinsertion[i].Agreementid);
          adjustDueDateSelectedAgre = Array.from(
            new Set(adjustDueDateSelectedAgre)
          );
          checkedCount = i;
          const agreementDetails = {
            agreement_id:
              Clubagreementinsertion[i].AgreementNumber == "Adjust"
                ? "Miscellaneous Item " +
                  Clubagreementinsertion[i].miscellaneousItemsNum
                : Clubagreementinsertion[i].AgreementNumber,
            agreement_totalamount: fixedChange(
              String(Clubagreementinsertion[i].Amount)
            ),
          };
          if (!Clubagreementinsertion[i]?.isExchanged) {
            totalduereceiptidsupport.push(agreementDetails);
          }
          settotalduereceiptid(totalduereceiptidsupport);
          chkboxArr.push(agreementDetails);
          let pifAmt = 0;
          let pifTax = 0;
          let sacTax = 0;
          let epoAmt = 0;
          let epoTax = 0;
          let pifFlag = 0;
          let sacAmt = 0;
          let PaygridObj = Clubagreementinsertion[i];
          let agreementAmount = Number(
            (
              PaygridObj.Amount -
              Number(PaygridObj.lateFee) +
              Number(PaygridObj.suspenseSub)
            ).toFixed(2)
          );
          if (PaygridObj.Agreementtype == "EPO Rent") {
            if (PaygridObj.Nextduedate == "EPO") {
              epoAmt =
                PaygridObj.acceptEpoFlag == 0
                  ? Number((agreementAmount - PaygridObj.Taxamount).toFixed(2))
                  : Number(PaygridObj.EPOAmount) +
                    Number(PaygridObj.suspenseSub) -
                    Number(PaygridObj.EPOTax) -
                    Number(PaygridObj.lateFee) -
                    Number(PaygridObj.EPOTax);
              epoTax =
                PaygridObj.acceptEpoFlag == 0
                  ? Number(PaygridObj.rpayTaxAmount)
                  : Number(PaygridObj.EPOTax) -
                    Number(PaygridObj.lFeeTaxAmount);
            } else {
              sacAmt =
                PaygridObj.acceptEpoFlag == 0
                  ? Number((agreementAmount - PaygridObj.Taxamount).toFixed(2))
                  : Number(PaygridObj.EPOAmount) +
                    Number(PaygridObj.suspenseSub) -
                    Number(PaygridObj.EPOTax) -
                    Number(PaygridObj.lateFee) -
                    Number(PaygridObj.EPOTax);
              sacTax =
                PaygridObj.acceptEpoFlag == 0
                  ? Number(PaygridObj.rpayTaxAmount)
                  : Number(PaygridObj.EPOTax) -
                    Number(PaygridObj.lFeeTaxAmount);
            }
            setfinalagr(Number(PaygridObj.Agreementid));
            pifFlag = 1;
          } else if (PaygridObj.Agreementtype == "PIF Rent") {
            pifAmt =
              PaygridObj.acceptEpoFlag == 0
                ? Number((agreementAmount - PaygridObj.Taxamount).toFixed(2))
                : Number(PaygridObj.EPOAmount) +
                  Number(PaygridObj.suspenseSub) -
                  Number(PaygridObj.EPOTax) -
                  Number(PaygridObj.lateFee) -
                  Number(PaygridObj.EPOTax);
            pifTax =
              PaygridObj.acceptEpoFlag == 0
                ? Number(PaygridObj.rpayTaxAmount)
                : Number(PaygridObj.EPOTax) - Number(PaygridObj.lFeeTaxAmount);
            setfinalagr(Number(PaygridObj.Agreementid));
            pifFlag = 1;
          }
          if (
            ((pifFlag == 1 && Number(PaygridObj.SuspenseAmount) > 0) ||
              (Number(PaygridObj.SuspenseAmount >= PaygridObj.EPOAmount) &&
                Number(PaygridObj.Agreementtype !== "Club"))) &&
            PaygridObj.miscellaneousItemsNum == 0 &&
            Number(PaygridObj.suspenseSub) == 0
          ) {
            setsuspErrLbl(true);
            suspenseError.push(PaygridObj.Agreementid);
          }
          if (
            Number(PaygridObj.EPOAmount < PaygridObj.Amount) &&
            pifFlag != 1 &&
            PaygridObj.Agreementtype !== "Club" &&
            PaygridObj.miscellaneousItemsNum == 0
          ) {
            // setepoErrLbl(true);
            epoError.push(PaygridObj.Agreementid);
          }

          if (PaygridObj.miscellaneousItemsNum == 0) {
            let ReceiptArray = {
              customerId: Number(customerId),
              schedule: PaygridObj.Schedule + "|" + PaygridObj.AgreementNumber,
              amountDue: Number(
                PaygridObj.Agreementtype !== "Club" && pifFlag == 0
                  ? (PaygridObj.Amount - Number(PaygridObj.lateFee)).toFixed(2)
                  : 0
              ),
              ldwFeeAmount: Number(PaygridObj.agrLdwAmount), // Added By Me
              clubPayment: Number(
                PaygridObj.Agreementtype === "Club"
                  ? Number(PaygridObj.Amount) - Number(PaygridObj.Taxamount)
                  : 0
              ),
              clubPaymentTax: Number(
                PaygridObj.Agreementtype === "Club" ? PaygridObj.Taxamount : 0
              ),
              creditOnAccountAdd: 0,
              creditOnAccountSub: -1 * Number(PaygridObj.COA),
              integratedPaymentSuspense: -1 * Number(PaygridObj.ipSuspense),
              initialPayment: Number(
                PaygridObj.Agreementtype !== "Club"
                  ? PaygridObj.number_of_paymentsmade === 0
                    ? 1
                    : 0
                  : 0
              ),
              netLateFee: 0,
              carriedLateFee: 0, //Number(PaygridObj[0].carriedLateFee),
              wlDeposit: -1 * Number(PaygridObj.wlDeposit),
              htSuspense: 0, //Number(PaygridObj[0].htSuspense),
              suspenseAdd: Number(PaygridObj.suspenseAdd), //Number(PaygridObj[0].suspense),
              suspenseSub: Number(PaygridObj.suspenseSub), //Number(PaygridObj[0].suspense),
              racTirePay: Number(PaygridObj.policyAmount), //Number(PaygridObj[0].racTirePay),
              agreementId:
                PaygridObj.Agreementtype !== "Club"
                  ? Number(PaygridObj.Agreementid)
                  : undefined,
              change: 0,
              totalTax: PaygridObj.Taxamount,
              sac: Number(sacAmt),
              sacTax: Number(sacTax),
              epo: Number(epoAmt),
              epoTax: Number(epoTax),
              epoDiscount:
                PaygridObj.acceptEpoFlag == 0
                  ? 0.0
                  : Number(PaygridObj.actualEpoAmount) -
                    Number(PaygridObj.editedEPOAmount),
              pastDueDiscount:
                PaygridObj.acceptEpoFlag == 0
                  ? 0.0
                  : Number(PaygridObj.actualPastDueRent) -
                    Number(PaygridObj.pastDueRent),
              pastDueAmount:
                PaygridObj.acceptEpoFlag == 0
                  ? undefined
                  : Number(PaygridObj.actualPastDueRent),
              nextDueDate:
                PaygridObj.acceptEpoFlag == 0
                  ? undefined
                  : PaygridObj.Nextduedate,
              ldwFeeAmountTax: Number(PaygridObj.ldwTaxAmount),
              rentalPaymentTax: Number(
                pifFlag == 0 && PaygridObj.Agreementtype !== "Club"
                  ? PaygridObj.rpayTaxAmount
                  : 0
              ),
              lateRentalPaymentfeeTax: Number(PaygridObj.lFeeTaxAmount),
              lateRentalPaymentfee: Number(PaygridObj.lateFee),
              racTirePayTax: Number(PaygridObj.policyTaxAmount),
              deliveryFee: Number(PaygridObj.deliveryAmount),
              deliveryFeeTax: Number(PaygridObj.deliveryAmountTax),
              processingFee: Number(PaygridObj.processingFee),
              processingFeeTax: Number(PaygridObj.processingFeeTax),
              carryRentAmountUsed:
                PaygridObj.FreeTime == null
                  ? Number(PaygridObj.carryRentAmountUsed)
                  : 0,
              carryRentTaxUsed:
                PaygridObj.FreeTime == null
                  ? Number(PaygridObj.carryRentTaxUsed)
                  : 0,
              pif: Number(pifAmt),
              pifTax: Number(pifTax),
              extensionAmount: Number(PaygridObj.extensionAmount),
              convenienceFee: 0,
              convenienceFeeTax: 0,
              mattressFee: Number(PaygridObj.mattressFee),
            };
            ReceiptCategoriesRequestParam.push(ReceiptArray);
          }

          if (
            PaygridObj.Agreementtype !== "Club" &&
            PaygridObj.miscellaneousItemsNum == 0
          ) {
            let acceptPaymentArray = {
              extensionAmount: Number(PaygridObj.extensionAmount),
              noOfDaysFree: Number(PaygridObj.noOfDaysFree),
              initialPaymentFlag:
                PaygridObj.number_of_paymentsmade == 0 ? 1 : 0,
              addTransferSusFlag: 0,
              schedule: PaygridObj.Schedule,
              agreementId: Number(PaygridObj.Agreementid),
              ldwFeeAmount: Number(PaygridObj.agrLdwAmount), // Added By Me
              agreementAmountDue: Number(
                fixedChange(
                  String(
                    Number(PaygridObj.Amount) +
                      (Number(PaygridObj.acceptEpoFlag) == 1
                        ? Number(PaygridObj.suspenseSub)
                        : 0)
                  )
                )
              ),
              remainingEpoAmount: Number(
                PaygridObj.EPOAmount == null
                  ? 0.0
                  : (Number(PaygridObj.EPOAmount - PaygridObj.EPOTax) < 0
                      ? 0
                      : Number(PaygridObj.EPOAmount - PaygridObj.EPOTax)
                    ).toFixed(2)
              ),
              promoFreeDays: Number(PaygridObj.PromoFreeDaysApplied),
              netLateFee: Number(PaygridObj.lateFee),
              carriedLateFee: 0, //Number(PaygridObj[0].carriedLateFee),
              numberOfDaysLate: PaygridObj.CurrentDaysLate,
              originalDueDate: PaygridObj.CurrentDueDate,
              nextDueDate: PaygridObj.Nextduedate,
              totalTax: Number(PaygridObj.Taxamount.toFixed(2)),
              policyAmount: Number(Number(PaygridObj.policyAmount)),
              deliveryFeeAmount: Number(PaygridObj.deliveryAmount),
              processingFeeAmount: Number(PaygridObj.processingFee),
              otherFeeAmount: Number(PaygridObj.mattressFee),
              editSchedule: PaygridObj.editSchedule,
              freeTime: PaygridObj.FreeTime, // Added By Me
              ldwActivate: undefined,
            };
            acceptPaymentParam.push(acceptPaymentArray);
          }

          if (
            PaygridObj.Agreementtype === "Club" &&
            PaygridObj.miscellaneousItemsNum == 0
          ) {
            AcceptclubPaymentParam = {
              paymentSchedule: PaygridObj.Schedule,
              originalDueDate: PaygridObj.CurrentDueDate,
              nextDueDate: PaygridObj.Nextduedate,
              numberOfDaysLate: PaygridObj.CurrentDaysLate,
              clubTax: String(PaygridObj.Taxamount.toFixed(2)),
              clubAmountDue: Number(Number(PaygridObj.Amount).toFixed(2)),
              editSchedule: PaygridObj.editSchedule,
            };
          }
          let objAvailCreditsAmt =
            Number(PaygridObj.COA) +
            Number(PaygridObj.wlDeposit) +
            Number(PaygridObj.ipSuspense);
          FormAcceptPaymentData(0, objAvailCreditsAmt);

          let auPay: AutoPayType = {
            schedule: PaygridObj.Schedule.toString(),
            autoPay:
              PaygridObj.Autopay_enrollmentdate !== "" &&
              PaygridObj.Autopay_enrollmentdate !== undefined &&
              PaygridObj.Autopay_enrollmentdate !== null,
            itemIndex: i,
            agreementID: PaygridObj.Agreementid,
          };
          autoPayEnrollArray.push(auPay);
        } else {
          (document.getElementsByName("grid")[i] as HTMLInputElement).checked =
            false;
        }
      }
      setadjustDueDateSelectedAgre(adjustDueDateSelectedAgre);
      multipleAdjust();
      const resultSelecteAgres = Clubagreementinsertion.filter(function (e) {
        return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
      });
      let noOfPaymentsAllowedToggle = 0;
      for (let i = 0; i < resultSelecteAgres.length; i++) {
        if (
          resultSelecteAgres[i].number_of_paymentsmade == 0 ||
          String(resultSelecteAgres[i].store_number) !=
            String(window.sessionStorage.getItem("storeNumber"))
        ) {
          noOfPaymentsAllowedToggle = 1;
        }
      }
      if (noOfPaymentsAllowedToggle == 0) {
        /* Autopay enrollment logic starts here */
        const distintSchedule = Array.from(
          new Set(autoPayEnrollArray.map((item) => item.schedule))
        );
        const apEnroll = Array.from(
          new Set(autoPayEnrollArray.map((item) => item.autoPay))
        );

        if (distintSchedule.length === 1 && apEnroll.length === 1) {
          if (apEnroll[0]) {
            setAPUPStates({ ...APUPStates, IsAP: false, IsUP: true });
          } else {
            setAPUPStates({ ...APUPStates, IsAP: true, IsUP: false });
          }
        } else {
          setAPUPStates({ ...APUPStates, IsAP: false, IsUP: false });
        }
        /* Autopay enrollment logic ends here */
      } else {
        setAPUPStates({ ...APUPStates, IsAP: false, IsUP: false });
      }
      /* Autopay enrollment logic ends here */
      calculatetotalamountRetention(filterRetentionAgr);
      //code for adding the late fee added by Mano Ranjith
      let lateamount = AddtotallatefeeRetention(filterRetentionAgr);
      setLatelast10value(lateamount);
      //code for carry rent
      setselectallChecked(true);
      setcarryRentCheck(false);
      if (carryRentWidget) {
        carryOnchange();
      }

      //Code for other options
      if (checkedCount == 0) loadOtherActions();
      else {
        setOtherActionHidden(true);
        setshowLDWPolicy(false);
      }
      // multipleAdjust()
    }
    // for (let i = 0; i < document.getElementsByName("grid").length; i++) {
    //   (document.getElementsByName("grid")[i] as HTMLInputElement).checked =
    //     isChecked;
    // }

    setcoaamt(Clubagreementinsertion[0].COA);
    setwldepositamount(Clubagreementinsertion[0].wlDeposit);
    setipsuspense(Clubagreementinsertion[0].ipSuspense);
    // multipleAdjust()
  };

  const selectallcheckbox = (check?: boolean, e?) => {
    let isChecked;
    if (e != undefined) {
      isChecked = e.target.checked;
    } else {
      isChecked = check;
    }
    // const filteredPaygridValues: any = paygridvalues.filter((ele) => !ele.isExchanged);
    const filteredPaygridValues = paygridvalues;
    if (isChecked == true) {
      for (let i = 0; i < filteredPaygridValues.length; i++) {
        if (!filteredPaygridValues[i]?.isExchanged) {
          adjustDueDateSelectedAgre.push(filteredPaygridValues[i].Agreementid);
        }
        adjustDueDateSelectedAgre = Array.from(
          new Set(adjustDueDateSelectedAgre)
        );
        console.log('adjustDueDateSelectedAgre', adjustDueDateSelectedAgre);
        setadjustDueDateSelectedAgre(adjustDueDateSelectedAgre);
      }
      multipleAdjust();
    } else {
      setadjustDueDateSelectedAgre([]), setdeliveryUnconfiremd(false);
    }
    totalduereceiptidsupport = [];
    let noOfZeroPaidAgreement = 0;
    for (let i = 0; i < filteredPaygridValues.length; i++) {
      if (filteredPaygridValues[i].number_of_paymentsmade >= 3) {
        if (filteredPaygridValues[i].sign_status === "") {
          settooltipdisplay(
            filteredPaygridValues[i].Agreementid + "Please sign the agreement"
          );
        }
        if (filteredPaygridValues[i].confirmeddeliversystatus === false) {
          settooltipdisplay(
            filteredPaygridValues[i].Agreementid +
              "Please update the confirmed delivery status"
          );
        }
        if (
          filteredPaygridValues[i].sign_status === "" &&
          filteredPaygridValues[i].confirmeddeliversystatus === false
        ) {
          settooltipdisplay(
            filteredPaygridValues[i].Agreementid +
              "Please sign the agreement and update the confirmed delivery status"
          );
        }
      }
      if (filteredPaygridValues[i].number_of_paymentsmade === 0) {
        noOfZeroPaidAgreement = noOfZeroPaidAgreement + 1;
      }
    }
    //charge-off
    for (let i = 0; i < filteredPaygridValues.length; i++) {
      if (
        isChecked != true &&
        filteredPaygridValues[i].Agreementtype == "Restitution"
      ) {
        setadjustChargeoffFlag(false);
        // setchargeoffadjustFlag(true)
      } else if (
        isChecked == true &&
        filteredPaygridValues[i].Agreementtype == "Restitution"
      ) {
        setadjustChargeoffFlag(true);
        setchargeoffadjustFlag(false);
      }
    }

    //......Rental Item Sale.........

    for (let i = 0; i < filteredPaygridValues.length; i++) {
      if (
        isChecked != true &&
        filteredPaygridValues[i].Agreementtype == "Rental Item Sale"
      ) {
        setadjustRisbtnFlag(false);
        setRisRemovepaymentdisableFlag(true);
        // setchargeoffadjustFlag(true)
      } else if (
        isChecked == true &&
        filteredPaygridValues[i].Agreementtype == "Rental Item Sale"
      ) {
        setRisRemovepaymentdisableFlag(false);
        //  setadjustRisbtnFlag(true);
        //  setAdjustdisableFlag(false);
      }
    }
    if (isChecked === true) {
      /**
       * syed azim
       * suspense select all change
       */

      setaddsuslist(false);
      setDisableTransfer(false);
      settransfersuslist(false);

      /*
          If the data does not have value against any of entollautopaydate then "Enroll AutoPay"
          button should be displayed when "Select All" check box is clicked

          1. First get the total records count
          2. Get the count of records that has auto pay enrollment date
          3. Get the distinct schedules from the array

          If the total records matches the total auto pay enrollment date records and if the
          distinct schedule length is 1 then show the respective enrollment button.
        */
      setOverAll_Chk(true);
      setSelectedCheckBoxCount(filteredPaygridValues.length);
      let checkedCount = 0;
      ReceiptCategoriesRequestParam = [];
      acceptPaymentParam = [];
      AcceptclubPaymentParam = undefined;
      for (let i = 0; i < filteredPaygridValues.length; i++) {
        if (!filteredPaygridValues?.isExchanged) {
          adjustDueDateSelectedAgre.push(filteredPaygridValues[i].Agreementid);
        }
        adjustDueDateSelectedAgre = Array.from(
          new Set(adjustDueDateSelectedAgre)
        );
        checkedCount = i;
        const agreementDetails = {
          agreement_id:
            filteredPaygridValues[i].AgreementNumber == "Adjust"
              ? "Miscellaneous Item " + filteredPaygridValues[i].miscellaneousItemsNum
              : filteredPaygridValues[i].AgreementNumber,
          agreement_totalamount: fixedChange(String(filteredPaygridValues[i].Amount)),
        };
        if (!filteredPaygridValues[i]?.isExchanged) {
          totalduereceiptidsupport.push(agreementDetails);
        }
        settotalduereceiptid(totalduereceiptidsupport);
        chkboxArr.push(agreementDetails);
        let pifAmt = 0;
        let pifTax = 0;
        let sacTax = 0;
        let epoAmt = 0;
        let epoTax = 0;
        let pifFlag = 0;
        let sacAmt = 0;
        let PaygridObj = filteredPaygridValues[i];
        let agreementAmount = Number(
          (
            PaygridObj.Amount -
            Number(PaygridObj.lateFee) +
            Number(PaygridObj.suspenseSub)
          ).toFixed(2)
        );
        //let epoAmount = Number(PaygridObj.EPOAmount.toFixed(2));
        // let currentDate = new Date();
        // let SACDate = new Date(PaygridObj.SAC_Date);
        if (PaygridObj.Agreementtype == "EPO Rent") {
          if (PaygridObj.Nextduedate == "EPO") {
            epoAmt =
              PaygridObj.acceptEpoFlag == 0
                ? Number((agreementAmount - PaygridObj.Taxamount).toFixed(2))
                : Number(PaygridObj.EPOAmount) +
                  Number(PaygridObj.suspenseSub) -
                  Number(PaygridObj.EPOTax) -
                  Number(PaygridObj.lateFee) -
                  Number(PaygridObj.EPOTax);
            epoTax =
              PaygridObj.acceptEpoFlag == 0
                ? Number(PaygridObj.rpayTaxAmount)
                : Number(PaygridObj.EPOTax) - Number(PaygridObj.lFeeTaxAmount);
          } else {
            sacAmt =
              PaygridObj.acceptEpoFlag == 0
                ? Number((agreementAmount - PaygridObj.Taxamount).toFixed(2))
                : Number(PaygridObj.EPOAmount) +
                  Number(PaygridObj.suspenseSub) -
                  Number(PaygridObj.EPOTax) -
                  Number(PaygridObj.lateFee) -
                  Number(PaygridObj.EPOTax);
            sacTax =
              PaygridObj.acceptEpoFlag == 0
                ? Number(PaygridObj.rpayTaxAmount)
                : Number(PaygridObj.EPOTax) - Number(PaygridObj.lFeeTaxAmount);
          }
          setfinalagr(Number(PaygridObj.Agreementid));
          pifFlag = 1;
        } else if (PaygridObj.Agreementtype == "PIF Rent") {
          pifAmt =
            PaygridObj.acceptEpoFlag == 0
              ? Number((agreementAmount - PaygridObj.Taxamount).toFixed(2))
              : Number(PaygridObj.EPOAmount) +
                Number(PaygridObj.suspenseSub) -
                Number(PaygridObj.EPOTax) -
                Number(PaygridObj.lateFee) -
                Number(PaygridObj.EPOTax);
          pifTax =
            PaygridObj.acceptEpoFlag == 0
              ? Number(PaygridObj.rpayTaxAmount)
              : Number(PaygridObj.EPOTax) - Number(PaygridObj.lFeeTaxAmount);
          setfinalagr(Number(PaygridObj.Agreementid));
          pifFlag = 1;
        }
        if (
          ((pifFlag == 1 && Number(PaygridObj.SuspenseAmount) > 0) ||
            (Number(PaygridObj.SuspenseAmount >= PaygridObj.EPOAmount) &&
              Number(PaygridObj.Agreementtype !== "Club"))) &&
          PaygridObj.miscellaneousItemsNum == 0 &&
          Number(PaygridObj.suspenseSub) == 0 &&
          PaygridObj.Agreementtype !== "Rental Item Sale" &&
          PaygridObj.Agreementtype !== "NSF Check" &&
          PaygridObj.Agreementtype !== "Back Rent" &&
          PaygridObj.Agreementtype !== "Credit Card Charge Back" &&
          PaygridObj.Agreementtype !== "Restitution"
        ) {
          setsuspErrLbl(true);
          suspenseError.push(PaygridObj.Agreementid);
        }
        // if(PaygridObj.store_number != window.sessionStorage.getItem("storeNumber") && PaygridObj.Agreementtype !== "Club"){
        //   setdiffStoreErrLbl(true);
        //   diffStoreError.push(PaygridObj.Agreementid);
        // }

        if (
          Number(PaygridObj.EPOAmount < PaygridObj.Amount) &&
          pifFlag != 1 &&
          PaygridObj.Agreementtype !== "Club" &&
          PaygridObj.miscellaneousItemsNum == 0 &&
          PaygridObj.Agreementtype !== "Rental Item Sale" &&
          PaygridObj.Agreementtype !== "NSF Check" &&
          PaygridObj.Agreementtype !== "Back Rent" &&
          PaygridObj.Agreementtype !== "Credit Card Charge Back" &&
          PaygridObj.Agreementtype !== "Restitution"
        ) {
          // setepoErrLbl(true);
          epoError.push(PaygridObj.Agreementid);
        }
        // if (
        //   PaygridObj.store_number !=
        //   window.sessionStorage.getItem("storeNumber")
        // ) {
        //   setdiffStoreErrLbl(true);
        //   diffStoreError.push(PaygridObj.Agreementid);
        // }
        if (PaygridObj.miscellaneousItemsNum == 0) {
          let ReceiptArray = {
            customerId: Number(customerId),
            schedule: PaygridObj.Schedule + "|" + PaygridObj.AgreementNumber,
            amountDue: Number(
              PaygridObj.Agreementtype !== "Club" && pifFlag == 0
                ? (PaygridObj.Amount - Number(PaygridObj.lateFee)).toFixed(2)
                : 0
            ),
            ldwFeeAmount: Number(PaygridObj.agrLdwAmount), // Added By Me
            // Number(PaygridObj.PromoFreeDaysApplied) == 0
            //   ? PaygridObj.agrLdwAmount
            //   : 0.0,
            clubPayment: Number(
              PaygridObj.Agreementtype === "Club"
                ? Number(PaygridObj.Amount) - Number(PaygridObj.Taxamount)
                : 0
            ),
            clubPaymentTax: Number(
              PaygridObj.Agreementtype === "Club" ? PaygridObj.Taxamount : 0
            ),
            creditOnAccountAdd: 0,
            creditOnAccountSub: -1 * Number(PaygridObj.COA),
            integratedPaymentSuspense: -1 * Number(PaygridObj.ipSuspense),
            initialPayment: Number(
              PaygridObj.Agreementtype !== "Club"
                ? PaygridObj.number_of_paymentsmade === 0
                  ? 1
                  : 0
                : 0
            ),
            netLateFee: 0,
            carriedLateFee: 0, //Number(PaygridObj[0].carriedLateFee),
            wlDeposit: -1 * Number(PaygridObj.wlDeposit),
            htSuspense: 0, //Number(PaygridObj[0].htSuspense),
            suspenseAdd: Number(PaygridObj.suspenseAdd), //Number(PaygridObj[0].suspense),
            suspenseSub: Number(PaygridObj.suspenseSub), //Number(PaygridObj[0].suspense),
            racTirePay: Number(PaygridObj.policyAmount), //Number(PaygridObj[0].racTirePay),
            agreementId:
              PaygridObj.Agreementtype !== "Club"
                ? Number(PaygridObj.Agreementid)
                : undefined,
            change: 0,
            totalTax: PaygridObj.Taxamount,
            sac: Number(sacAmt),
            sacTax: Number(sacTax),
            epo: Number(epoAmt),
            epoTax: Number(epoTax),
            epoDiscount:
              PaygridObj.acceptEpoFlag == 0
                ? 0.0
                : Number(PaygridObj.actualEpoAmount) -
                  Number(PaygridObj.editedEPOAmount),
            pastDueDiscount:
              PaygridObj.acceptEpoFlag == 0
                ? 0.0
                : Number(PaygridObj.actualPastDueRent) -
                  Number(PaygridObj.pastDueRent),
            pastDueAmount:
              PaygridObj.acceptEpoFlag == 0
                ? undefined
                : Number(PaygridObj.actualPastDueRent),
            nextDueDate:
              PaygridObj.acceptEpoFlag == 0
                ? undefined
                : PaygridObj.Nextduedate,
            ldwFeeAmountTax: Number(PaygridObj.ldwTaxAmount),
            rentalPaymentTax: Number(
              pifFlag == 0 && PaygridObj.Agreementtype !== "Club"
                ? PaygridObj.rpayTaxAmount
                : 0
            ),
            lateRentalPaymentfeeTax: Number(PaygridObj.lFeeTaxAmount),
            lateRentalPaymentfee: Number(PaygridObj.lateFee),
            racTirePayTax: Number(PaygridObj.policyTaxAmount),
            deliveryFee: Number(PaygridObj.deliveryAmount),
            deliveryFeeTax: Number(PaygridObj.deliveryAmountTax),
            processingFee: Number(PaygridObj.processingFee),
            processingFeeTax: Number(PaygridObj.processingFeeTax),
            carryRentAmountUsed:
              PaygridObj.FreeTime == null
                ? Number(PaygridObj.carryRentAmountUsed)
                : 0,
            carryRentTaxUsed:
              PaygridObj.FreeTime == null
                ? Number(PaygridObj.carryRentTaxUsed)
                : 0,
            //otherFee: Number(PaygridObj.mattressFee),
            //otherFeeTax: Number(PaygridObj.mattressFeeTax),
            pif: Number(pifAmt),
            pifTax: Number(pifTax),
            extensionAmount: Number(PaygridObj.extensionAmount),
            convenienceFee: 0,
            convenienceFeeTax: 0,
            mattressFee: Number(PaygridObj.mattressFee),
          };
          ReceiptCategoriesRequestParam.push(ReceiptArray);
        }

        if (
          PaygridObj.Agreementtype !== "Club" &&
          PaygridObj.miscellaneousItemsNum == 0
        ) {
          console.log('Adjusted Object 23266', PaygridObj.FreeTime);
          let acceptPaymentArray = {
            extensionAmount: Number(PaygridObj.extensionAmount),
            noOfDaysFree: Number(PaygridObj.noOfDaysFree),
            initialPaymentFlag: PaygridObj.number_of_paymentsmade == 0 ? 1 : 0,
            addTransferSusFlag: 0,
            schedule: PaygridObj.Schedule,
            agreementId: Number(PaygridObj.Agreementid),
            ldwFeeAmount: Number(PaygridObj.agrLdwAmount), // Added By Me
            // Number(PaygridObj.PromoFreeDaysApplied) == 0
            //   ? PaygridObj.agrLdwAmount
            //   : 0.0,
            agreementAmountDue: Number(
              fixedChange(
                String(
                  Number(PaygridObj.Amount) +
                    (Number(PaygridObj.acceptEpoFlag) == 1
                      ? Number(PaygridObj.suspenseSub)
                      : 0)
                )
              )
            ),
            remainingEpoAmount: Number(
              PaygridObj.EPOAmount == null
                ? 0.0
                : (Number(PaygridObj.EPOAmount - PaygridObj.EPOTax) < 0
                    ? 0
                    : Number(PaygridObj.EPOAmount - PaygridObj.EPOTax)
                  ).toFixed(2)
            ),
            promoFreeDays: Number(PaygridObj.PromoFreeDaysApplied),
            netLateFee: Number(PaygridObj.lateFee),
            carriedLateFee: 0, //Number(PaygridObj[0].carriedLateFee),
            numberOfDaysLate: PaygridObj.CurrentDaysLate,
            originalDueDate: PaygridObj.CurrentDueDate,
            nextDueDate: PaygridObj.Nextduedate,
            totalTax: Number(PaygridObj.Taxamount.toFixed(2)),
            policyAmount: Number(Number(PaygridObj.policyAmount)),
            deliveryFeeAmount: Number(PaygridObj.deliveryAmount),
            processingFeeAmount: Number(PaygridObj.processingFee),
            otherFeeAmount: Number(PaygridObj.mattressFee),
            editSchedule: PaygridObj.editSchedule,
            freeTime: PaygridObj.FreeTime, // Added By Me
            ldwActivate: undefined,
          };
          acceptPaymentParam.push(acceptPaymentArray);
        }

        if (
          PaygridObj.Agreementtype === "Club" &&
          PaygridObj.miscellaneousItemsNum == 0
        ) {
          AcceptclubPaymentParam = {
            paymentSchedule: PaygridObj.Schedule,
            originalDueDate: PaygridObj.CurrentDueDate,
            nextDueDate: PaygridObj.Nextduedate,
            numberOfDaysLate: PaygridObj.CurrentDaysLate,
            clubTax: String(PaygridObj.Taxamount.toFixed(2)),
            clubAmountDue: Number(Number(PaygridObj.Amount).toFixed(2)),
            editSchedule: PaygridObj.editSchedule,
          };
        }
        let objAvailCreditsAmt =
          Number(PaygridObj.COA) +
          Number(PaygridObj.wlDeposit) +
          Number(PaygridObj.ipSuspense);
        FormAcceptPaymentData(0, objAvailCreditsAmt);

        let auPay: AutoPayType = {
          schedule: PaygridObj.Schedule.toString(),
          autoPay:
            PaygridObj.Autopay_enrollmentdate !== "" &&
            PaygridObj.Autopay_enrollmentdate !== undefined &&
            PaygridObj.Autopay_enrollmentdate !== null,
          itemIndex: i,
          agreementID: PaygridObj.Agreementid,
        };
        autoPayEnrollArray.push(auPay);
      }
      setadjustDueDateSelectedAgre(adjustDueDateSelectedAgre);
      multipleAdjust();
      const resultSelecteAgres = filteredPaygridValues.filter(function (e) {
        return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
      });
      let noOfPaymentsAllowedToggle = 0;
      for (let i = 0; i < resultSelecteAgres.length; i++) {
        if (
          resultSelecteAgres[i].number_of_paymentsmade == 0 ||
          String(resultSelecteAgres[i].store_number) !=
            String(window.sessionStorage.getItem("storeNumber"))
        ) {
          noOfPaymentsAllowedToggle = 1;
        }
      }
      if (noOfPaymentsAllowedToggle == 0) {
        /* Autopay enrollment logic starts here */
        const distintSchedule = Array.from(
          new Set(autoPayEnrollArray.map((item) => item.schedule))
        );
        const apEnroll = Array.from(
          new Set(autoPayEnrollArray.map((item) => item.autoPay))
        );

        if (distintSchedule.length === 1 && apEnroll.length === 1) {
          if (apEnroll[0]) {
            setAPUPStates({ ...APUPStates, IsAP: false, IsUP: true });
          } else {
            setAPUPStates({ ...APUPStates, IsAP: true, IsUP: false });
          }
        } else {
          setAPUPStates({ ...APUPStates, IsAP: false, IsUP: false });
        }
        /* Autopay enrollment logic ends here */
      } else {
        setAPUPStates({ ...APUPStates, IsAP: false, IsUP: false });
      }
      /* Autopay enrollment logic ends here */
      calculatetotalamount();
      //code for adding the late fee added by Mano Ranjith
      let lateamount = Addtotallatefee();
      setLatelast10value(lateamount);
      //code for carry rent
      setselectallChecked(true);
      setcarryRentCheck(false);
      if (carryRentWidget) {
        carryOnchange();
      }

      //Code for other options
      if (checkedCount == 0) loadOtherActions();
      else {
        setOtherActionHidden(true);
        setshowLDWPolicy(false);
      }
      // multipleAdjust()
    } else {
      // setmastersuspensearray([]);
      // setmasteraddsuspensearray([]);
      // setmasterusesuspensearray([]);
      // setmastertranssuspensearray([]);
      // setmasterrefundsuspensearray([]);

      setaddsuslist(true);
      setDisableTransfer(true);
      setrefundsuslist(true);
      settransfersuslist(true);

      setadjustDueDateSelectedAgre([]);
      setdeliveryUnconfiremd(false);
      setAPUPStates({ ...APUPStates, IsAP: false, IsUP: false });
      totalduereceiptidsupport = [];
      settotalduereceiptid([]);
      subtractamount();
      //code for subtracting the late fee added by Mano Ranjith
      let lateamount = Subtracttotallatefee();
      setLatelast10value(lateamount);

      ReceiptCategoriesRequestParam = [];
      acceptPaymentParam = [];
      AcceptclubPaymentParam = undefined;
      addrowarray = [];
      let objChkMo: CheqAndMO[] = [];
      setaddataanother(objChkMo);
      tendertypeglobalarray = [];
      settotalduetendertype(tendertypeglobalarray);
      setAcceptClub(undefined);
      setAcceptpaymentbuttonenable(true);
      setSelectedCheckBoxCount(0);
      setOverAll_Chk(false);
      const TempCard_Array: any = new Array(CardRadiArr.length).fill(false);
      setCardRadiArr(TempCard_Array);
      for (let i = 0; i < multipleCard.length; i++) {
        multipleCard[i].Amount = "0.00";
      }
      setmultipleCard(multipleCard);
      setCardErrorLbl(false);
      setCardGridData(CardGrid);
      setcvvTextValue("");
      setCardAmt([{ amount: 0.0 }]);
      swipeCardAmt = [];
      setcardDetails([]);
      setcardtxtDisable(false);
      setswipeamt("0.00");
      swipeCardDetails = [];
      setswipeFlag(false);
      setcvvTextValue("");
      setchange(0.0);
      setSecurityDepositData([]);
      suspenseError = [];
      epoError = [];
      diffStoreError = [];
      // setepoErrLbl(false);
      setsuspErrLbl(false);
      diffStoreErrLbl = false;
      // setdiffStoreErrLbl(false);
      /**
       * changes:Carry Rent
       * Developer:Muzzamil
       * Desc:Handle select all check box
       */
      setselectallChecked(false);
      // setcarryRentWidget(false)
      setcarryMessage("No agreements are Selected");
      if (carryRentWidget == true) {
        carryOnchange();
      }
      if (paymentOrigin == "Pay By Phone") {
        settotaldue(convAmtWTax);
        setremainingamountdue(convAmtWTax);
      } else {
        settotaldue("0.00");
        setremainingamountdue("0.00");
      }
      setagreecarryUsed(0.0);
      setagreecarryLateUsed(0.0);
    }

    for (let i = 0; i < document.getElementsByName("grid").length; i++) {
      (document.getElementsByName("grid")[i] as HTMLInputElement).checked =
        isChecked;
      //setOverAll_Chk(e.target.checked);
      //setchkboxselect(e.target.checked);
    }

    setcoaamt(filteredPaygridValues[0].COA);
    setwldepositamount(filteredPaygridValues[0].wlDeposit);
    setipsuspense(filteredPaygridValues[0].ipSuspense);
    // multipleAdjust()

    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:isChecked?`SelectAllCheckBox`:`UnSelectedAllCheckBox`
    }
    setUserTraceData([...userTraceData,traceObj])
  };

  const selectallcheckboxRevenue = (
    check?: boolean,
    e?,
    isCalledInitially?,
    Clubagreementinsertion?
  ) => {
    // debugger;
    let isChecked;
    if (e != undefined) {
      isChecked = e.target.checked;
    } else {
      isChecked = check;
    }
    // const filteredPayGridValues: any = paygridvalues.filter((ele) => !ele?.isExchanged);
    const filteredPayGridValues = paygridvalues;
    if (isChecked == true) {
      for (let i = 0; i < filteredPayGridValues.length; i++) {
        if(!filteredPayGridValues[i].isExchanged){
          adjustDueDateSelectedAgre.push(filteredPayGridValues[i].Agreementid);
        }
        adjustDueDateSelectedAgre = Array.from(
          new Set(adjustDueDateSelectedAgre)
        );
        console.log('adjustDueDateSelectedAgre', adjustDueDateSelectedAgre);
        setadjustDueDateSelectedAgre(adjustDueDateSelectedAgre);
      }
      // multipleAdjustRevenue();
      multipleAdjust();
    } else if (isChecked == false && isCalledInitially) {
      adjustDueDateSelectedAgre = [];
      let adjDueSelectedAgr: any = [];
      console.log('Clubagreementinsertion', Clubagreementinsertion);
      // Clubagreementinsertion = Clubagreementinsertion?.filter((ele) => !ele?.isExchanged)
      console.log('Clubagreementinsertion After', Clubagreementinsertion);
      Clubagreementinsertion && Clubagreementinsertion?.map((obj, index) => {
        if (obj.store_number == sessionStorage.getItem("storeNumber")) {
          if(!obj?.isExchanged){
            adjDueSelectedAgr.push(obj.Agreementid);
          }
          adjDueSelectedAgr = Array.from(new Set(adjDueSelectedAgr));
          adjustDueDateSelectedAgre = adjDueSelectedAgr;
          setadjustDueDateSelectedAgre(adjDueSelectedAgr);
        }
      });
      // multipleAdjustRevenue();
      multipleAdjust();
    } else {
      setadjustDueDateSelectedAgre([]), setdeliveryUnconfiremd(false);
    }
    totalduereceiptidsupport = [];
    let noOfZeroPaidAgreement = 0;
    for (let i = 0; i < filteredPayGridValues.length; i++) {
      if (filteredPayGridValues[i].number_of_paymentsmade >= 3) {
        if (filteredPayGridValues[i].sign_status === "") {
          settooltipdisplay(
            filteredPayGridValues[i].Agreementid + "Please sign the agreement"
          );
        }
        if (filteredPayGridValues[i].confirmeddeliversystatus === false) {
          settooltipdisplay(
            filteredPayGridValues[i].Agreementid +
              "Please update the confirmed delivery status"
          );
        }
        if (
          filteredPayGridValues[i].sign_status === "" &&
          filteredPayGridValues[i].confirmeddeliversystatus === false
        ) {
          settooltipdisplay(
            filteredPayGridValues[i].Agreementid +
              "Please sign the agreement and update the confirmed delivery status"
          );
        }
      }
      if (filteredPayGridValues[i].number_of_paymentsmade === 0) {
        noOfZeroPaidAgreement = noOfZeroPaidAgreement + 1;
      }
    }
    for (let i = 0; i < filteredPayGridValues.length; i++) {
      if (
        isChecked != true &&
        filteredPayGridValues[i].Agreementtype == "Restitution"
      ) {
        setadjustChargeoffFlag(false);
        // setchargeoffadjustFlag(true)
      } else if (
        isChecked == true &&
        filteredPayGridValues[i].Agreementtype == "Restitution"
      ) {
        setadjustChargeoffFlag(true);
        setchargeoffadjustFlag(false);
      }
    }
    if (isChecked === true) {
      /**
       * syed azim
       * suspense select all change
       */

      setaddsuslist(false);
      setDisableTransfer(false);
      settransfersuslist(false);

      /*
          If the data does not have value against any of entollautopaydate then "Enroll AutoPay"
          button should be displayed when "Select All" check box is clicked

          1. First get the total records count
          2. Get the count of records that has auto pay enrollment date
          3. Get the distinct schedules from the array

          If the total records matches the total auto pay enrollment date records and if the
          distinct schedule length is 1 then show the respective enrollment button.
        */
      setOverAll_Chk(true);
      setSelectedCheckBoxCount(filteredPayGridValues.length);
      let checkedCount = 0;
      ReceiptCategoriesRequestParam = [];
      acceptPaymentParam = [];
      AcceptclubPaymentParam = undefined;
      for (let i = 0; i < filteredPayGridValues.length; i++) {
        if(!filteredPayGridValues[i]?.isExchanged){
          adjustDueDateSelectedAgre.push(filteredPayGridValues[i].Agreementid);
        }
        adjustDueDateSelectedAgre = Array.from(
          new Set(adjustDueDateSelectedAgre)
        );
        checkedCount = i;
        const agreementDetails = {
          agreement_id:
            filteredPayGridValues[i].AgreementNumber == "Adjust"
              ? "Miscellaneous Item " + filteredPayGridValues[i].miscellaneousItemsNum
              : filteredPayGridValues[i].AgreementNumber,
          agreement_totalamount: fixedChange(String(filteredPayGridValues[i].Amount)),
        };
        if(!filteredPayGridValues[i]?.isExchanged){
          totalduereceiptidsupport.push(agreementDetails);
        }
        settotalduereceiptid(totalduereceiptidsupport);
        chkboxArr.push(agreementDetails);
        let pifAmt = 0;
        let pifTax = 0;
        let sacTax = 0;
        let epoAmt = 0;
        let epoTax = 0;
        let pifFlag = 0;
        let sacAmt = 0;
        let PaygridObj = filteredPayGridValues[i];
        let agreementAmount = Number(
          (
            PaygridObj.Amount -
            Number(PaygridObj.lateFee) +
            Number(PaygridObj.suspenseSub)
          ).toFixed(2)
        );
        //let epoAmount = Number(PaygridObj.EPOAmount.toFixed(2));
        // let currentDate = new Date();
        // let SACDate = new Date(PaygridObj.SAC_Date);
        if (PaygridObj.Agreementtype == "EPO Rent") {
          if (PaygridObj.Nextduedate == "EPO") {
            epoAmt =
              PaygridObj.acceptEpoFlag == 0
                ? Number((agreementAmount - PaygridObj.Taxamount).toFixed(2))
                : Number(PaygridObj.EPOAmount) +
                  Number(PaygridObj.suspenseSub) -
                  Number(PaygridObj.EPOTax) -
                  Number(PaygridObj.lateFee) -
                  Number(PaygridObj.EPOTax);
            epoTax =
              PaygridObj.acceptEpoFlag == 0
                ? Number(PaygridObj.rpayTaxAmount)
                : Number(PaygridObj.EPOTax) - Number(PaygridObj.lFeeTaxAmount);
          } else {
            sacAmt =
              PaygridObj.acceptEpoFlag == 0
                ? Number((agreementAmount - PaygridObj.Taxamount).toFixed(2))
                : Number(PaygridObj.EPOAmount) +
                  Number(PaygridObj.suspenseSub) -
                  Number(PaygridObj.EPOTax) -
                  Number(PaygridObj.lateFee) -
                  Number(PaygridObj.EPOTax);
            sacTax =
              PaygridObj.acceptEpoFlag == 0
                ? Number(PaygridObj.rpayTaxAmount)
                : Number(PaygridObj.EPOTax) - Number(PaygridObj.lFeeTaxAmount);
          }
          setfinalagr(Number(PaygridObj.Agreementid));
          pifFlag = 1;
        } else if (PaygridObj.Agreementtype == "PIF Rent") {
          pifAmt =
            PaygridObj.acceptEpoFlag == 0
              ? Number((agreementAmount - PaygridObj.Taxamount).toFixed(2))
              : Number(PaygridObj.EPOAmount) +
                Number(PaygridObj.suspenseSub) -
                Number(PaygridObj.EPOTax) -
                Number(PaygridObj.lateFee) -
                Number(PaygridObj.EPOTax);
          pifTax =
            PaygridObj.acceptEpoFlag == 0
              ? Number(PaygridObj.rpayTaxAmount)
              : Number(PaygridObj.EPOTax) - Number(PaygridObj.lFeeTaxAmount);
          setfinalagr(Number(PaygridObj.Agreementid));
          pifFlag = 1;
        }
        if (
          ((pifFlag == 1 && Number(PaygridObj.SuspenseAmount) > 0) ||
            (Number(PaygridObj.SuspenseAmount >= PaygridObj.EPOAmount) &&
              Number(PaygridObj.Agreementtype !== "Club"))) &&
          PaygridObj.miscellaneousItemsNum == 0 &&
          Number(PaygridObj.suspenseSub) == 0 &&
          PaygridObj.Agreementtype !== "Rental Item Sale" &&
          PaygridObj.Agreementtype !== "NSF Check" &&
          PaygridObj.Agreementtype !== "Back Rent" &&
          PaygridObj.Agreementtype !== "Credit Card Charge Back" &&
          PaygridObj.Agreementtype !== "Restitution"
        ) {
          setsuspErrLbl(true);
          suspenseError.push(PaygridObj.Agreementid);
        }
        // if(PaygridObj.store_number != window.sessionStorage.getItem("storeNumber") && PaygridObj.Agreementtype !== "Club"){
        //   setdiffStoreErrLbl(true);
        //   diffStoreError.push(PaygridObj.Agreementid);
        // }

        if (
          Number(PaygridObj.EPOAmount < PaygridObj.Amount) &&
          pifFlag != 1 &&
          PaygridObj.Agreementtype !== "Club" &&
          PaygridObj.miscellaneousItemsNum == 0 &&
          PaygridObj.Agreementtype !== "Rental Item Sale" &&
          PaygridObj.Agreementtype !== "NSF Check" &&
          PaygridObj.Agreementtype !== "Back Rent" &&
          PaygridObj.Agreementtype !== "Credit Card Charge Back" &&
          PaygridObj.Agreementtype !== "Restitution"
        ) {
          // setepoErrLbl(true);
          epoError.push(PaygridObj.Agreementid);
        }
        // if (
        //   PaygridObj.store_number !=
        //   window.sessionStorage.getItem("storeNumber")
        // ) {
        //   setdiffStoreErrLbl(true);
        //   diffStoreError.push(PaygridObj.Agreementid);
        // }
        if (PaygridObj.miscellaneousItemsNum == 0) {
          let ReceiptArray = {
            customerId: Number(customerId),
            schedule: PaygridObj.Schedule + "|" + PaygridObj.AgreementNumber,
            amountDue: Number(
              PaygridObj.Agreementtype !== "Club" && pifFlag == 0
                ? (PaygridObj.Amount - Number(PaygridObj.lateFee)).toFixed(2)
                : 0
            ),
            ldwFeeAmount: Number(PaygridObj.agrLdwAmount), // Added By Me
            // Number(PaygridObj.PromoFreeDaysApplied) == 0
            //   ? PaygridObj.agrLdwAmount
            //   : 0.0,
            clubPayment: Number(
              PaygridObj.Agreementtype === "Club"
                ? Number(PaygridObj.Amount) - Number(PaygridObj.Taxamount)
                : 0
            ),
            clubPaymentTax: Number(
              PaygridObj.Agreementtype === "Club" ? PaygridObj.Taxamount : 0
            ),
            creditOnAccountAdd: 0,
            creditOnAccountSub: -1 * Number(PaygridObj.COA),
            integratedPaymentSuspense: -1 * Number(PaygridObj.ipSuspense),
            initialPayment: Number(
              PaygridObj.Agreementtype !== "Club"
                ? PaygridObj.number_of_paymentsmade === 0
                  ? 1
                  : 0
                : 0
            ),
            netLateFee: 0,
            carriedLateFee: 0, //Number(PaygridObj[0].carriedLateFee),
            wlDeposit: -1 * Number(PaygridObj.wlDeposit),
            htSuspense: 0, //Number(PaygridObj[0].htSuspense),
            suspenseAdd: Number(PaygridObj.suspenseAdd), //Number(PaygridObj[0].suspense),
            suspenseSub: Number(PaygridObj.suspenseSub), //Number(PaygridObj[0].suspense),
            racTirePay: Number(PaygridObj.policyAmount), //Number(PaygridObj[0].racTirePay),
            agreementId:
              PaygridObj.Agreementtype !== "Club"
                ? Number(PaygridObj.Agreementid)
                : undefined,
            change: 0,
            totalTax: PaygridObj.Taxamount,
            sac: Number(sacAmt),
            sacTax: Number(sacTax),
            epo: Number(epoAmt),
            epoTax: Number(epoTax),
            epoDiscount:
              PaygridObj.acceptEpoFlag == 0
                ? 0.0
                : Number(PaygridObj.actualEpoAmount) -
                  Number(PaygridObj.editedEPOAmount),
            pastDueDiscount:
              PaygridObj.acceptEpoFlag == 0
                ? 0.0
                : Number(PaygridObj.actualPastDueRent) -
                  Number(PaygridObj.pastDueRent),
            pastDueAmount:
              PaygridObj.acceptEpoFlag == 0
                ? undefined
                : Number(PaygridObj.actualPastDueRent),
            nextDueDate:
              PaygridObj.acceptEpoFlag == 0
                ? undefined
                : PaygridObj.Nextduedate,
            ldwFeeAmountTax: Number(PaygridObj.ldwTaxAmount),
            rentalPaymentTax: Number(
              pifFlag == 0 && PaygridObj.Agreementtype !== "Club"
                ? PaygridObj.rpayTaxAmount
                : 0
            ),
            lateRentalPaymentfeeTax: Number(PaygridObj.lFeeTaxAmount),
            lateRentalPaymentfee: Number(PaygridObj.lateFee),
            racTirePayTax: Number(PaygridObj.policyTaxAmount),
            deliveryFee: Number(PaygridObj.deliveryAmount),
            deliveryFeeTax: Number(PaygridObj.deliveryAmountTax),
            processingFee: Number(PaygridObj.processingFee),
            processingFeeTax: Number(PaygridObj.processingFeeTax),
            carryRentAmountUsed:
              PaygridObj.FreeTime == null
                ? Number(PaygridObj.carryRentAmountUsed)
                : 0,
            carryRentTaxUsed:
              PaygridObj.FreeTime == null
                ? Number(PaygridObj.carryRentTaxUsed)
                : 0,
            //otherFee: Number(PaygridObj.mattressFee),
            //otherFeeTax: Number(PaygridObj.mattressFeeTax),
            pif: Number(pifAmt),
            pifTax: Number(pifTax),
            extensionAmount: Number(PaygridObj.extensionAmount),
            convenienceFee: 0,
            convenienceFeeTax: 0,
            mattressFee: Number(PaygridObj.mattressFee),
          };
          ReceiptCategoriesRequestParam.push(ReceiptArray);
        }

        if (
          PaygridObj.Agreementtype !== "Club" &&
          PaygridObj.miscellaneousItemsNum == 0
        ) {
          let acceptPaymentArray = {
            extensionAmount: Number(PaygridObj.extensionAmount),
            noOfDaysFree: Number(PaygridObj.noOfDaysFree),
            initialPaymentFlag: PaygridObj.number_of_paymentsmade == 0 ? 1 : 0,
            addTransferSusFlag: 0,
            schedule: PaygridObj.Schedule,
            agreementId: Number(PaygridObj.Agreementid),
            ldwFeeAmount: Number(PaygridObj.agrLdwAmount), // Added By Me
            // Number(PaygridObj.PromoFreeDaysApplied) == 0
            //   ? PaygridObj.agrLdwAmount
            //   : 0.0,
            agreementAmountDue: Number(
              fixedChange(
                String(
                  Number(PaygridObj.Amount) +
                    (Number(PaygridObj.acceptEpoFlag) == 1
                      ? Number(PaygridObj.suspenseSub)
                      : 0)
                )
              )
            ),
            remainingEpoAmount: Number(
              PaygridObj.EPOAmount == null
                ? 0.0
                : (Number(PaygridObj.EPOAmount - PaygridObj.EPOTax) < 0
                    ? 0
                    : Number(PaygridObj.EPOAmount - PaygridObj.EPOTax)
                  ).toFixed(2)
            ),
            promoFreeDays: Number(PaygridObj.PromoFreeDaysApplied),
            netLateFee: Number(PaygridObj.lateFee),
            carriedLateFee: 0, //Number(PaygridObj[0].carriedLateFee),
            numberOfDaysLate: PaygridObj.CurrentDaysLate,
            originalDueDate: PaygridObj.CurrentDueDate,
            nextDueDate: PaygridObj.Nextduedate,
            totalTax: Number(PaygridObj.Taxamount.toFixed(2)),
            policyAmount: Number(Number(PaygridObj.policyAmount)),
            deliveryFeeAmount: Number(PaygridObj.deliveryAmount),
            processingFeeAmount: Number(PaygridObj.processingFee),
            otherFeeAmount: Number(PaygridObj.mattressFee),
            editSchedule: PaygridObj.editSchedule,
            freeTime: PaygridObj.FreeTime, // Added By Me
            ldwActivate: undefined,
          };
          acceptPaymentParam.push(acceptPaymentArray);
        }

        if (
          PaygridObj.Agreementtype === "Club" &&
          PaygridObj.miscellaneousItemsNum == 0
        ) {
          AcceptclubPaymentParam = {
            paymentSchedule: PaygridObj.Schedule,
            originalDueDate: PaygridObj.CurrentDueDate,
            nextDueDate: PaygridObj.Nextduedate,
            numberOfDaysLate: PaygridObj.CurrentDaysLate,
            clubTax: String(PaygridObj.Taxamount.toFixed(2)),
            clubAmountDue: Number(Number(PaygridObj.Amount).toFixed(2)),
            editSchedule: PaygridObj.editSchedule,
          };
        }
        let objAvailCreditsAmt =
          Number(PaygridObj.COA) +
          Number(PaygridObj.wlDeposit) +
          Number(PaygridObj.ipSuspense);
        FormAcceptPaymentData(0, objAvailCreditsAmt);

        let auPay: AutoPayType = {
          schedule: PaygridObj.Schedule.toString(),
          autoPay:
            PaygridObj.Autopay_enrollmentdate !== "" &&
            PaygridObj.Autopay_enrollmentdate !== undefined &&
            PaygridObj.Autopay_enrollmentdate !== null,
          itemIndex: i,
          agreementID: PaygridObj.Agreementid,
        };
        autoPayEnrollArray.push(auPay);
      }
      setadjustDueDateSelectedAgre(adjustDueDateSelectedAgre);
      multipleAdjust();
      const resultSelecteAgres = filteredPayGridValues.filter(function (e) {
        return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
      });
      let noOfPaymentsAllowedToggle = 0;
      for (let i = 0; i < resultSelecteAgres.length; i++) {
        if (
          resultSelecteAgres[i].number_of_paymentsmade == 0 ||
          String(resultSelecteAgres[i].store_number) !=
            String(window.sessionStorage.getItem("storeNumber"))
        ) {
          noOfPaymentsAllowedToggle = 1;
        }
      }
      if (noOfPaymentsAllowedToggle == 0) {
        /* Autopay enrollment logic starts here */
        const distintSchedule = Array.from(
          new Set(autoPayEnrollArray.map((item) => item.schedule))
        );
        const apEnroll = Array.from(
          new Set(autoPayEnrollArray.map((item) => item.autoPay))
        );

        if (distintSchedule.length === 1 && apEnroll.length === 1) {
          if (apEnroll[0]) {
            setAPUPStates({ ...APUPStates, IsAP: false, IsUP: true });
          } else {
            setAPUPStates({ ...APUPStates, IsAP: true, IsUP: false });
          }
        } else {
          setAPUPStates({ ...APUPStates, IsAP: false, IsUP: false });
        }
        /* Autopay enrollment logic ends here */
      } else {
        setAPUPStates({ ...APUPStates, IsAP: false, IsUP: false });
      }
      /* Autopay enrollment logic ends here */
      calculatetotalamount();
      //code for adding the late fee added by Mano Ranjith
      let lateamount = Addtotallatefee();
      setLatelast10value(lateamount);
      //code for carry rent
      setselectallChecked(true);
      setcarryRentCheck(false);
      if (carryRentWidget) {
        carryOnchange();
      }

      //Code for other options
      if (checkedCount == 0) loadOtherActions();
      else {
        setOtherActionHidden(true);
        setshowLDWPolicy(false);
      }
      // multipleAdjust()
    } else if (isChecked === false && isCalledInitially === undefined) {
      // setmastersuspensearray([]);
      // setmasteraddsuspensearray([]);
      // setmasterusesuspensearray([]);
      // setmastertranssuspensearray([]);
      // setmasterrefundsuspensearray([]);

      setaddsuslist(true);
      setDisableTransfer(true);
      setrefundsuslist(true);
      settransfersuslist(true);

      setadjustDueDateSelectedAgre([]);
      setdeliveryUnconfiremd(false);
      setAPUPStates({ ...APUPStates, IsAP: false, IsUP: false });
      totalduereceiptidsupport = [];
      settotalduereceiptid([]);
      subtractamount();
      //code for subtracting the late fee added by Mano Ranjith
      let lateamount = Subtracttotallatefee();
      setLatelast10value(lateamount);

      ReceiptCategoriesRequestParam = [];
      acceptPaymentParam = [];
      AcceptclubPaymentParam = undefined;
      addrowarray = [];
      let objChkMo: CheqAndMO[] = [];
      setaddataanother(objChkMo);
      tendertypeglobalarray = [];
      settotalduetendertype(tendertypeglobalarray);
      setAcceptClub(undefined);
      setAcceptpaymentbuttonenable(true);
      setSelectedCheckBoxCount(0);
      setOverAll_Chk(false);
      const TempCard_Array: any = new Array(CardRadiArr.length).fill(false);
      setCardRadiArr(TempCard_Array);
      for (let i = 0; i < multipleCard.length; i++) {
        multipleCard[i].Amount = "0.00";
      }
      setmultipleCard(multipleCard);
      setCardErrorLbl(false);
      setCardGridData(CardGrid);
      setcvvTextValue("");
      setCardAmt([{ amount: 0.0 }]);
      swipeCardAmt = [];
      setcardDetails([]);
      setcardtxtDisable(false);
      setswipeamt("0.00");
      swipeCardDetails = [];
      setswipeFlag(false);
      setcvvTextValue("");
      setchange(0.0);
      setSecurityDepositData([]);
      suspenseError = [];
      epoError = [];
      diffStoreError = [];
      // setepoErrLbl(false);
      setsuspErrLbl(false);
      diffStoreErrLbl = false;
      // setdiffStoreErrLbl(false);
      /**
       * changes:Carry Rent
       * Developer:Muzzamil
       * Desc:Handle select all check box
       */
      setselectallChecked(false);
      // setcarryRentWidget(false)
      setcarryMessage("No agreements are Selected");
      if (carryRentWidget == true) {
        carryOnchange();
      }
      if (paymentOrigin == "Pay By Phone") {
        settotaldue(convAmtWTax);
        setremainingamountdue(convAmtWTax);
      } else {
        settotaldue("0.00");
        setremainingamountdue("0.00");
      }
      setagreecarryUsed(0.0);
      setagreecarryLateUsed(0.0);
    }

    if (isCalledInitially && isChecked == false) {
      ReceiptCategoriesRequestParam = [];
      acceptPaymentParam = [];
      AcceptclubPaymentParam = undefined;
      let choosenAgreements: any = [];
      let selectedBox = 0;
      for (let i = 0; i < Clubagreementinsertion.length; i++) {
        let pifAmt = 0;
        let pifTax = 0;
        let sacTax = 0;
        let epoAmt = 0;
        let epoTax = 0;
        let pifFlag = 0;
        let sacAmt = 0;
        let PaygridObj = Clubagreementinsertion[i];
        let agreementAmount = Number(
          (
            PaygridObj.Amount -
            Number(PaygridObj.lateFee) +
            Number(PaygridObj.suspenseSub)
          ).toFixed(2)
        );
        if (PaygridObj.store_number == sessionStorage.getItem("storeNumber")) {
          (document.getElementsByName("grid")[i] as HTMLInputElement).checked =
            true;
          choosenAgreements.push(PaygridObj);
          selectedBox = selectedBox + 1;

          const agreementDetails = {
            agreement_id:
              Clubagreementinsertion[i].AgreementNumber == "Adjust"
                ? "Miscellaneous Item " +
                  Clubagreementinsertion[i].miscellaneousItemsNum
                : Clubagreementinsertion[i].AgreementNumber,
            agreement_totalamount: fixedChange(
              String(Clubagreementinsertion[i].Amount)
            ),
          };
          if(!Clubagreementinsertion?.isExchanged){
            totalduereceiptidsupport.push(agreementDetails);
          }
          settotalduereceiptid(totalduereceiptidsupport);
          setaddsuslist(false);
          setDisableTransfer(false);
          chkboxArr.push(agreementDetails);

          if (PaygridObj.Agreementtype == "EPO Rent") {
            if (PaygridObj.Nextduedate == "EPO") {
              epoAmt =
                PaygridObj.acceptEpoFlag == 0
                  ? Number((agreementAmount - PaygridObj.Taxamount).toFixed(2))
                  : Number(PaygridObj.EPOAmount) +
                    Number(PaygridObj.suspenseSub) -
                    Number(PaygridObj.EPOTax) -
                    Number(PaygridObj.lateFee) -
                    Number(PaygridObj.EPOTax);
              epoTax =
                PaygridObj.acceptEpoFlag == 0
                  ? Number(PaygridObj.rpayTaxAmount)
                  : Number(PaygridObj.EPOTax) -
                    Number(PaygridObj.lFeeTaxAmount);
            } else {
              sacAmt =
                PaygridObj.acceptEpoFlag == 0
                  ? Number((agreementAmount - PaygridObj.Taxamount).toFixed(2))
                  : Number(PaygridObj.EPOAmount) +
                    Number(PaygridObj.suspenseSub) -
                    Number(PaygridObj.EPOTax) -
                    Number(PaygridObj.lateFee) -
                    Number(PaygridObj.EPOTax);
              sacTax =
                PaygridObj.acceptEpoFlag == 0
                  ? Number(PaygridObj.rpayTaxAmount)
                  : Number(PaygridObj.EPOTax) -
                    Number(PaygridObj.lFeeTaxAmount);
            }
            setfinalagr(Number(PaygridObj.Agreementid));
            pifFlag = 1;
          } else if (PaygridObj.Agreementtype == "PIF Rent") {
            pifAmt =
              PaygridObj.acceptEpoFlag == 0
                ? Number((agreementAmount - PaygridObj.Taxamount).toFixed(2))
                : Number(PaygridObj.EPOAmount) +
                  Number(PaygridObj.suspenseSub) -
                  Number(PaygridObj.EPOTax) -
                  Number(PaygridObj.lateFee) -
                  Number(PaygridObj.EPOTax);
            pifTax =
              PaygridObj.acceptEpoFlag == 0
                ? Number(PaygridObj.rpayTaxAmount)
                : Number(PaygridObj.EPOTax) - Number(PaygridObj.lFeeTaxAmount);
            setfinalagr(Number(PaygridObj.Agreementid));
            pifFlag = 1;
          }
          if (
            ((pifFlag == 1 && Number(PaygridObj.SuspenseAmount) > 0) ||
              (Number(PaygridObj.SuspenseAmount >= PaygridObj.EPOAmount) &&
                Number(PaygridObj.Agreementtype !== "Club"))) &&
            PaygridObj.miscellaneousItemsNum == 0 &&
            Number(PaygridObj.suspenseSub) == 0 &&
            PaygridObj.Agreementtype !== "Rental Item Sale" &&
            PaygridObj.Agreementtype !== "NSF Check" &&
            PaygridObj.Agreementtype !== "Credit Card Charge Back" &&
            PaygridObj.Agreementtype !== "Restitution"
          ) {
            setsuspErrLbl(true);
            suspenseError.push(PaygridObj.Agreementid);
          }
          // if(PaygridObj.store_number != window.sessionStorage.getItem("storeNumber") && PaygridObj.Agreementtype !== "Club"){
          //   setdiffStoreErrLbl(true);
          //   diffStoreError.push(PaygridObj.Agreementid);
          // }

          if (
            Number(PaygridObj.EPOAmount < PaygridObj.Amount) &&
            pifFlag != 1 &&
            PaygridObj.Agreementtype !== "Club" &&
            PaygridObj.miscellaneousItemsNum == 0 &&
            PaygridObj.Agreementtype !== "Rental Item Sale" &&
            PaygridObj.Agreementtype !== "NSF Check" &&
            PaygridObj.Agreementtype !== "Credit Card Charge Back" &&
            PaygridObj.Agreementtype !== "Restitution"
          ) {
            // setepoErrLbl(true);
            epoError.push(PaygridObj.Agreementid);
          }
          // if (
          //   PaygridObj.store_number !=
          //   window.sessionStorage.getItem("storeNumber")
          // ) {
          //   setdiffStoreErrLbl(true);
          //   diffStoreError.push(PaygridObj.Agreementid);
          // }
          if (PaygridObj.miscellaneousItemsNum == 0) {
            let ReceiptArray = {
              customerId: Number(customerId),
              schedule: PaygridObj.Schedule + "|" + PaygridObj.AgreementNumber,
              amountDue: Number(
                PaygridObj.Agreementtype !== "Club" && pifFlag == 0
                  ? (PaygridObj.Amount - Number(PaygridObj.lateFee)).toFixed(2)
                  : 0
              ),
              ldwFeeAmount: Number(PaygridObj.agrLdwAmount), // Added By Me
              // Number(PaygridObj.PromoFreeDaysApplied) == 0
              //   ? PaygridObj.agrLdwAmount
              //   : 0.0,
              clubPayment: Number(
                PaygridObj.Agreementtype === "Club"
                  ? Number(PaygridObj.Amount) - Number(PaygridObj.Taxamount)
                  : 0
              ),
              clubPaymentTax: Number(
                PaygridObj.Agreementtype === "Club" ? PaygridObj.Taxamount : 0
              ),
              creditOnAccountAdd: 0,
              creditOnAccountSub: -1 * Number(PaygridObj.COA),
              integratedPaymentSuspense: -1 * Number(PaygridObj.ipSuspense),
              initialPayment: Number(
                PaygridObj.Agreementtype !== "Club"
                  ? PaygridObj.number_of_paymentsmade === 0
                    ? 1
                    : 0
                  : 0
              ),
              netLateFee: 0,
              carriedLateFee: 0, //Number(PaygridObj[0].carriedLateFee),
              wlDeposit: -1 * Number(PaygridObj.wlDeposit),
              htSuspense: 0, //Number(PaygridObj[0].htSuspense),
              suspenseAdd: Number(PaygridObj.suspenseAdd), //Number(PaygridObj[0].suspense),
              suspenseSub: Number(PaygridObj.suspenseSub), //Number(PaygridObj[0].suspense),
              racTirePay: Number(PaygridObj.policyAmount), //Number(PaygridObj[0].racTirePay),
              agreementId:
                PaygridObj.Agreementtype !== "Club"
                  ? Number(PaygridObj.Agreementid)
                  : undefined,
              change: 0,
              totalTax: PaygridObj.Taxamount,
              sac: Number(sacAmt),
              sacTax: Number(sacTax),
              epo: Number(epoAmt),
              epoTax: Number(epoTax),
              epoDiscount:
                PaygridObj.acceptEpoFlag == 0
                  ? 0.0
                  : Number(PaygridObj.actualEpoAmount) -
                    Number(PaygridObj.editedEPOAmount),
              pastDueDiscount:
                PaygridObj.acceptEpoFlag == 0
                  ? 0.0
                  : Number(PaygridObj.actualPastDueRent) -
                    Number(PaygridObj.pastDueRent),
              pastDueAmount:
                PaygridObj.acceptEpoFlag == 0
                  ? undefined
                  : Number(PaygridObj.actualPastDueRent),
              nextDueDate:
                PaygridObj.acceptEpoFlag == 0
                  ? undefined
                  : PaygridObj.Nextduedate,
              ldwFeeAmountTax: Number(PaygridObj.ldwTaxAmount),
              rentalPaymentTax: Number(
                pifFlag == 0 && PaygridObj.Agreementtype !== "Club"
                  ? PaygridObj.rpayTaxAmount
                  : 0
              ),
              lateRentalPaymentfeeTax: Number(PaygridObj.lFeeTaxAmount),
              lateRentalPaymentfee: Number(PaygridObj.lateFee),
              racTirePayTax: Number(PaygridObj.policyTaxAmount),
              deliveryFee: Number(PaygridObj.deliveryAmount),
              deliveryFeeTax: Number(PaygridObj.deliveryAmountTax),
              processingFee: Number(PaygridObj.processingFee),
              processingFeeTax: Number(PaygridObj.processingFeeTax),
              carryRentAmountUsed:
                PaygridObj.FreeTime == null
                  ? Number(PaygridObj.carryRentAmountUsed)
                  : 0,
              carryRentTaxUsed:
                PaygridObj.FreeTime == null
                  ? Number(PaygridObj.carryRentTaxUsed)
                  : 0,
              //otherFee: Number(PaygridObj.mattressFee),
              //otherFeeTax: Number(PaygridObj.mattressFeeTax),
              pif: Number(pifAmt),
              pifTax: Number(pifTax),
              extensionAmount: Number(PaygridObj.extensionAmount),
              convenienceFee: 0,
              convenienceFeeTax: 0,
              mattressFee: Number(PaygridObj.mattressFee),
            };
            ReceiptCategoriesRequestParam.push(ReceiptArray);
          }

          if (
            PaygridObj.Agreementtype !== "Club" &&
            PaygridObj.miscellaneousItemsNum == 0
          ) {
            let acceptPaymentArray = {
              extensionAmount: Number(PaygridObj.extensionAmount),
              noOfDaysFree: Number(PaygridObj.noOfDaysFree),
              initialPaymentFlag:
                PaygridObj.number_of_paymentsmade == 0 ? 1 : 0,
              addTransferSusFlag: 0,
              schedule: PaygridObj.Schedule,
              agreementId: Number(PaygridObj.Agreementid),
              ldwFeeAmount: Number(PaygridObj.agrLdwAmount), // Added By Me
              // Number(PaygridObj.PromoFreeDaysApplied) == 0
              //   ? PaygridObj.agrLdwAmount
              //   : 0.0,
              agreementAmountDue: Number(
                fixedChange(
                  String(
                    Number(PaygridObj.Amount) +
                      (Number(PaygridObj.acceptEpoFlag) == 1
                        ? Number(PaygridObj.suspenseSub)
                        : 0)
                  )
                )
              ),
              remainingEpoAmount: Number(
                PaygridObj.EPOAmount == null
                  ? 0.0
                  : (Number(PaygridObj.EPOAmount - PaygridObj.EPOTax) < 0
                      ? 0
                      : Number(PaygridObj.EPOAmount - PaygridObj.EPOTax)
                    ).toFixed(2)
              ),
              promoFreeDays: Number(PaygridObj.PromoFreeDaysApplied),
              netLateFee: Number(PaygridObj.lateFee),
              carriedLateFee: 0, //Number(PaygridObj[0].carriedLateFee),
              numberOfDaysLate: PaygridObj.CurrentDaysLate,
              originalDueDate: PaygridObj.CurrentDueDate,
              nextDueDate: PaygridObj.Nextduedate,
              totalTax: Number(PaygridObj.Taxamount.toFixed(2)),
              policyAmount: Number(Number(PaygridObj.policyAmount)),
              deliveryFeeAmount: Number(PaygridObj.deliveryAmount),
              processingFeeAmount: Number(PaygridObj.processingFee),
              otherFeeAmount: Number(PaygridObj.mattressFee),
              editSchedule: PaygridObj.editSchedule,
              freeTime: PaygridObj.FreeTime, // Added By Me
              ldwActivate: undefined,
            };
            acceptPaymentParam.push(acceptPaymentArray);
          }

          if (
            PaygridObj.Agreementtype === "Club" &&
            PaygridObj.miscellaneousItemsNum == 0
          ) {
            AcceptclubPaymentParam = {
              paymentSchedule: PaygridObj.Schedule,
              originalDueDate: PaygridObj.CurrentDueDate,
              nextDueDate: PaygridObj.Nextduedate,
              numberOfDaysLate: PaygridObj.CurrentDaysLate,
              clubTax: String(PaygridObj.Taxamount.toFixed(2)),
              clubAmountDue: Number(Number(PaygridObj.Amount).toFixed(2)),
              editSchedule: PaygridObj.editSchedule,
            };
          }
          let objAvailCreditsAmt =
            Number(PaygridObj.COA) +
            Number(PaygridObj.wlDeposit) +
            Number(PaygridObj.ipSuspense);
          FormAcceptPaymentData(0, objAvailCreditsAmt);

          let auPay: AutoPayType = {
            schedule: PaygridObj.Schedule.toString(),
            autoPay:
              PaygridObj.Autopay_enrollmentdate !== "" &&
              PaygridObj.Autopay_enrollmentdate !== undefined &&
              PaygridObj.Autopay_enrollmentdate !== null,
            itemIndex: i,
            agreementID: PaygridObj.Agreementid,
          };
          autoPayEnrollArray.push(auPay);
        } else {
          settotalduereceiptid(totalduereceiptidsupport);
          (document.getElementsByName("grid")[i] as HTMLInputElement).checked =
            false;
        }
      }
      // multipleAdjustRevenue()
      multipleAdjust();
      const resultSelecteAgres = Clubagreementinsertion.filter(function (e) {
        return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
      });
      let noOfPaymentsAllowedToggle = 0;
      for (let i = 0; i < resultSelecteAgres.length; i++) {
        if (
          resultSelecteAgres[i].number_of_paymentsmade == 0 ||
          String(resultSelecteAgres[i].store_number) !=
            String(window.sessionStorage.getItem("storeNumber"))
        ) {
          noOfPaymentsAllowedToggle = 1;
        }
      }
      if (noOfPaymentsAllowedToggle == 0) {
        /* Autopay enrollment logic starts here */
        const distintSchedule = Array.from(
          new Set(autoPayEnrollArray.map((item) => item.schedule))
        );
        const apEnroll = Array.from(
          new Set(autoPayEnrollArray.map((item) => item.autoPay))
        );

        if (distintSchedule.length === 1 && apEnroll.length === 1) {
          if (apEnroll[0]) {
            setAPUPStates({ ...APUPStates, IsAP: false, IsUP: true });
          } else {
            setAPUPStates({ ...APUPStates, IsAP: true, IsUP: false });
          }
        } else {
          setAPUPStates({ ...APUPStates, IsAP: false, IsUP: false });
        }
        /* Autopay enrollment logic ends here */
      } else {
        setAPUPStates({ ...APUPStates, IsAP: false, IsUP: false });
      }

      let lateamount = AddtotallatefeeSpecific(choosenAgreements);
      setLatelast10value(lateamount);
      setSelectedCheckBoxCount(selectedBox);
      calculatetotalamountPartial(choosenAgreements);
    } else {
      for (let i = 0; i < document.getElementsByName("grid").length; i++) {
        (document.getElementsByName("grid")[i] as HTMLInputElement).checked =
          isChecked;
      }
    }

    setcoaamt(filteredPayGridValues[0].COA);
    setwldepositamount(filteredPayGridValues[0].wlDeposit);
    setipsuspense(filteredPayGridValues[0].ipSuspense);
    // multipleAdjust()
  };

  const addchecktypetooltip = (index: any) => {
    if (index === "1") {
      settooltipdisplaygrid("Personal Check");
    } else if (index === "2") {
      settooltipdisplaygrid("Travellers Check");
    } else if (index === "3") {
      settooltipdisplaygrid("Payroll Check");
    } else if (index === "4") {
      settooltipdisplaygrid("Government Check");
    } else if (index === "5") {
      settooltipdisplaygrid("Cashiers Check");
    }
  };

  const BindGridcheck = () => (
    <>
      {adddataanother != undefined && adddataanother.length > 0
        ? adddataanother.map((value: any, index: any) => {
            return (
              // eslint-disable-next-line prettier/prettier
              <RACTableRow
                key={"dynamicgridrow" + index}
                data-testid={"dynamicgridrow" + index}
                className={classes.hiddenRow}
              >
                {value.Type_info === "Check" ? (
                  <RACTableCell
                    className={classes.textleft}
                    title={tooltipdispalygrid}
                    data-testid={"tooltip" + index}
                    onMouseOver={() => {
                      addchecktypetooltip(value.check_type);
                    }}
                    //className={classes.textcenter}
                  >
                    {value.Type_info}
                    <InfoiconGrey className={classes.infoIcon} />
                  </RACTableCell>
                ) : (
                  <RACTableCell className={classes.textleft}>
                    {value.Type_info}
                  </RACTableCell>
                )}
                <RACTableCell className={classes.textleft}>
                  {value.check_no}
                </RACTableCell>
                <RACTableCell className={classes.textleft}>
                  {value.Written_by}
                </RACTableCell>
                {value.Amount_rac.split(".").length < 2 ? (
                  <RACTableCell className={classes.textright}>
                    <span>$</span>
                    {value.Amount_rac}
                    <span>.00</span>
                  </RACTableCell>
                ) : (
                  <RACTableCell className={classes.textright}>
                    <span>$</span>
                    {value.Amount_rac}
                  </RACTableCell>
                )}
                <RACTableCell className={classes.textcenter}>
                  <RACButton
                    //type="button"
                    className={`${classes.paymentSmallBtn} ${classes.mx2} ${classes.updatebutton}`}
                    //value={index}
                    onClick={() => update(index)}
                    data-testid={index + "update"}
                  >
                    Update
                  </RACButton>
                  <RACButton
                    //type="button"
                    className={`${classes.paymentSmallBtn} ${classes.deletebutton}`}
                    //value={index}
                    onClick={() => Delete(index)}
                    data-testid={"deletebuttongrid" + index}
                  >
                    Delete
                  </RACButton>
                </RACTableCell>
              </RACTableRow>
            );
          })
        : null}
    </>
  );
  const CheckBoxCheckedUncheckedFun = () => {
    let checkBoxArray = document.getElementsByName("grid");
    checkBoxArray.forEach(function (item, index) {
      let obj = item as HTMLInputElement;
      let checkBox = obj;
      if (paygridvalues[index].Agreementtype == "Club") {
        if (checkBox.checked) {
          checkBox.checked = false;
          checkBox.disabled = true;
          document
            .getElementById("maincheckbox")
            ?.setAttribute("disabled", "true");
          // document.getElementById("maincheckbox")?.removeAttribute("disabled");
          // setOverAll_Chk(true);
          settotaldueamount(
            "chk" + index,
            index,
            paygridvalues[index].Amount,
            paygridvalues[index].Agreementid,
            undefined
          );
        } else {
          if (clubStatus == "A") {
            document
              .getElementById("maincheckbox")
              ?.removeAttribute("disabled");
            checkBox.checked = true;
            checkBox.disabled = false;
            setClubActivate(undefined)
            let allCheckBox = Array.from(checkBoxArray).filter(
              (el: any) => !el.checked
            );
            if (allCheckBox.length == 0) {
              document
                .getElementById("maincheckbox")
                ?.setAttribute("checked", "true");
            }
            settotaldueamount(
              "chk" + index,
              index,
              paygridvalues[index].Amount,
              paygridvalues[index].Agreementid,
              undefined
            );
          }
        }
      }

    });
  };
  const clubStatusHandleChange = async (status: any) => {
    const currentStoreActiveAgr= paygridvalues.filter((x)=>x.store_number==String(window.sessionStorage.getItem("storeNumber")) && x.Agreementtype != 'Club');
    if (status == "P") {
      const clubStatus: any = paygridvalues.filter(
        (el: any) => el?.Agreementtype == "Club"
      );

      if (clubStatus.length > 0) {
        if (
          (paygridvalues.length == 1 || currentStoreActiveAgr?.length==0) &&
          paygridvalues[0].Agreementtype == "Club"
        ) {
          setdeactivateClubCustomerRedirection(true);
        } else {
          paygridvalues.map((obj) => {
            if (obj.Agreementtype == "Club") {
              obj.isClubDeactivateTriggered = false;
            }
          });
          // setpaygridvalues(paygridvalues);
          if (clubStatus[0].number_of_paymentsmade > 0) {
            CheckBoxCheckedUncheckedFun();
            setclubStatus("D");
            setclubCheckDisabled(false);
          } else if (clubStatus[0].number_of_paymentsmade == 0) {
            CheckBoxCheckedUncheckedFun();
            setclubStatus("P");
            setclubCheckDisabled(false);
          }
        }
      } else {
        setmasterloader(true);
        const response: any = await GetCustomerCoverage(customerId);
        if (response?.data?.clubInfo?.clubAmountwithTax) {
          let totalAmountAdded = Number(
            response?.data?.clubInfo?.clubAmountwithTax
          );
          for (
            let i = 0;
            i < response?.data?.agreementsWithoutLDW?.length;
            i++
          ) {
            let finalPaymentFlag = 0;
            const clubInformation = paygridvalues.filter(
              (x: any) =>
                x.Nextduedate != "SAC" &&
                x.Nextduedate != "EPO" &&
                x.Nextduedate != "PIF" &&
                x.Agreementid ==
                  String(response?.data?.agreementsWithoutLDW[i]?.agreementId)
            );
            if (clubInformation.length > 0) {
              totalAmountAdded =
                Number(totalAmountAdded) +
                Number(
                  response?.data?.agreementsWithoutLDW[i]?.ldwAmountWithTax
                );
            }
          }
          paygridvalues.map((obj) => {
            if (obj.Agreementtype == "Club") {
              obj.isClubDeactivateTriggered = false;
            }
          });
          // setpaygridvalues(paygridvalues);
          setclubAmount(Number(totalAmountAdded).toFixed(2));
          setclubStatusPopup({ ...clubStatusPopup, activateClub: true });
        } else {
          seterrorPopup(true);
        }
        setmasterloader(false);
      }
    } else {
      if (
        (paygridvalues.length == 1 || currentStoreActiveAgr?.length==0) &&
        paygridvalues[0].Agreementtype == "Club"
      ) {
        setdeactivateClubCustomerRedirection(true);
      } else {
        setclubStatusPopup({ ...clubStatusPopup, deactivateClub: true });
      }
      // activateDeactivateClubApiCall('C', 0, 'deactivateClub')
    }
  };
  const deactivateClubCustomerRedirectionChange = () => {
    redirectionToCustomer();
  };
  const deActivatePopupYesHandleChange = () => {
    let checkBoxArray = document.getElementsByName("grid");
    let sortedPayGridValue: any = [];
    let filteredClubData: any = [];
    let filteredNormalData: any = [];
    paygridvalues.map((payGridObj, index) => {
      if (payGridObj.Agreementtype == "Club") {
        filteredClubData.push(payGridObj);
      } else {
        filteredNormalData.push(payGridObj);
      }
    });
    sortedPayGridValue = [...filteredClubData, ...filteredNormalData];

    checkBoxArray.forEach(function (item, index) {
      let obj = item as HTMLInputElement;
      let checkBox = obj;
      if (sortedPayGridValue[index].Agreementtype == "Club") {
        setClubActivate(0);
        if (checkBox.checked) {
          checkBox.checked = false;
          checkBox.disabled = true;
          settotaldueamount(
            "chk" + index,
            index,
            sortedPayGridValue[index].Amount,
            sortedPayGridValue[index].Agreementid,
            undefined
          );
          document
            .getElementById("maincheckbox")
            ?.setAttribute("disabled", "true");
          setclubCheckDisabled(true);
          AcceptclubPaymentParam = undefined;
        } else {
          checkBox.checked = false;
          checkBox.disabled = true;
          document
            .getElementById("maincheckbox")
            ?.setAttribute("disabled", "true");
        }
      }
    });
    paygridvalues.map((obj) => {
      if (obj.Agreementtype == "Club") {
        obj.isClubDeactivateTriggered = true;
      }
    });
    setclubStatusPopup({ ...clubStatusPopup, deactivateClub: false });
    setclubStatus("A");
    setclubBtnStatus("P");
    setclubCheckDisabled(true);
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:"Deactivate Club Yes"
    }
    setUserTraceData([...userTraceData,traceObj])
  };
  const activateDeactivateClubApiCall = async (
    isCreate,
    clubStatusRefCode,
    popup
  ) => {
    try {
      setclubStatusPopup({ ...clubStatusPopup, [popup]: false });
      setmasterloader(true);
      let payload: any = {
        paymentSchedule: null,
        customerId: Number(customerId),
        isCreate: isCreate,
        agreementId: null,
        clubStatusRefCode: clubStatusRefCode,
      };
      const response: any = await ActiveClub(payload);
      if (response?.customerClubId) {
        setclubStatus("Club is pending");
      }
      setmasterloader(false);
      window.location.reload();
    } catch (e: any) {
      setmasterloader(false);
      seterrorPopup(true);
    }
  };

  const pageLoadSomeThingWentWrong = ()=>{
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <AlertIcon></AlertIcon>
          <Typography variant={"body2"} className={classes.RACPOPMsg}>
            {`Something went wrong !. Please use ‘Report an Issue’ quick link under help, or Self Service portal to report this issue, and someone from the support team will promptly assist you with this request`}
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifycontentcenter} ${classes.spacerMT4}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => someThingWentWrongOk()}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  }
  const ErrorMsgPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <AlertIcon></AlertIcon>
          <Typography variant={"body2"} className={classes.RACPOPMsg}>
            {`Something went wrong.`}
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifycontentcenter} ${classes.spacerMT4}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => seterrorPopup(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const primAddressErrorMsgPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <AlertIcon></AlertIcon>
          <Typography variant={"body2"} className={classes.RACPOPMsg}>
            {`Customer does not have an active primary address. Please update `}
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifycontentcenter} ${classes.spacerMT4}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => redirectionToCustomer()}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  
  const postalCodeZip5ErrorMsgPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          <Typography variant={"body2"} className={classes.RACPOPMsg} style={{marginTop:"5px"}} >
            {postalCodeZip5ErrorFlag==1?
             `Invalid Customer Zip Code. Please Update`
            :postalCodeZip5ErrorFlag==2?
             `Invalid Customer Phone Number. Please update `:""}
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifycontentcenter} ${classes.spacerMT4}`}
          style={{marginLeft:"3px"}}
        >
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            className={classes.me2}
            onClick={() => redirectionToCustomer()}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };


  const update = (index: any) => {
    setConrolUpdate("1");
    setbutton(true);
    setuploadindex(index);
    setchecknovalid(false);
    setType(false);
    setwrittento(false);
    setwritenby(false);
    setAmount(false);

    if (adddataanother[parseInt(index)]["Type_info"] === "Check") {
      setcheckmonvar("1");
      setaddcheck(true);
      setadddata({
        ...adddata,
        checktype: adddataanother[parseInt(index)]["check_type"],
        Type: adddataanother[parseInt(index)]["Type_info"],
        Written_To: adddataanother[parseInt(index)]["Written_too"],
        checkno: adddataanother[parseInt(index)]["check_no"],
        Written_by: adddataanother[parseInt(index)]["Written_by"],
        Amount: adddataanother[parseInt(index)]["Amount_rac"],
      });
    } else {
      setcheckmonvar("2");
      setaddmoneyorder(true);
      setadddata({
        ...adddata,
        Type: adddataanother[parseInt(index)]["Type_info"],
        Written_To: adddataanother[parseInt(index)]["Written_too"],
        checkno: adddataanother[parseInt(index)]["check_no"],
        Written_by: adddataanother[parseInt(index)]["Written_by"],
        Amount: adddataanother[parseInt(index)]["Amount_rac"],
      });
    }
    let temp = adddataanother[parseInt(index)]["Amount_rac"];
    setuploadtotal(temp);

    // if (parseFloat(remainingamountdue) > 0) {
    //   let objRemAmt = parseFloat(remainingamountdue) + parseFloat(temp);
    //   setremainingamountdue(objRemAmt);
    // }
  };

  const upload = (e: any) => {
    setcancelindication(0);
    settotalcontrol(1);
    let arr = {
      check_type: adddata.checktype,
      Type_info: adddata.Type,
      check_no: adddata.checkno,
      Written_too: adddata.Written_To,
      Written_by: adddata.Written_by,
      Amount_rac: adddata.Amount,
    };
    setchecknovalid(false);
    setType(false);
    setwrittento(false);
    setwritenby(false);
    setAmount(false);
    let chargeCardAmount = 0;
    let swipeCardAmount = 0;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Ending with")
      ) {
        chargeCardAmount =
          chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Swipe ending")
      ) {
        swipeCardAmount =
          swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }

    // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
    let cardamount = Number(
      multipleCard.length == 0
        ? 0.0
        : multipleCard
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
    );
    cardamount =
      Number(cardamount) + Number(chargeCardAmount) + Number(swipeCardAmount);
    let totalcheck =
      Number(Number(totalamountcheck).toFixed(2)) -
      Number(Number(uploadtotal).toFixed(2)) +
      Number(Number(adddata.Amount).toFixed(2));

    let FloatInputTxttofixed = Number(totalcheck).toFixed(2);
    let tendertypedetailsch = {
      tendertype: "Check & Money Order",
      Amount: FloatInputTxttofixed,
    };
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (tendertypeglobalarray[i].tendertype === "Check & Money Order") {
        tendertypeglobalarray.splice(i, 1);
        break;
      }
    }
    if (totalcheck !== 0) {
      tendertypeglobalarray.push(tendertypedetailsch);
      settotalduetendertype(tendertypeglobalarray);
    }
    // if (remainamount > 0 && totalamountcheck <= RemaniningAmtStatic) {
    //   setremainingamountdue(remainamount);
    // } else {
    //   let changeamount =
    //     change - parseFloat(uploadtotal) + parseFloat(adddata.Amount);
    //   setchange(changeamount);
    //   setremainingamountdue(0);
    // }

    let objCheqAmt = Number(adddata.Amount).toFixed(2);
    let objRAmtStatic = Number(RemaniningAmtStatic).toFixed(2);
    //let objrmAmt = Number(remainingamountdue).toFixed(2);
    let sumOfOtherTenderAmount = Number(
      Number(addcash) + +Number(cardamount)
    ).toFixed(2);

    if (checkmonvar === "1") {
      if (adddata.checkno === "") {
        setchecknovalid(true);
      }
      if (adddata.checktype === "0") {
        setType(true);
      }
      if (adddata.Written_To === "") {
        setwrittento(true);
      }
      if (adddata.Written_by === "") {
        setwritenby(true);
      }
      if (
        adddata.Amount === "" ||
        Number(adddata.Amount) <= 0.0 ||
        Number(adddata.Amount) <= 0 ||
        Number.isNaN(Number(adddata.Amount))
      ) {
        setAmount(true);
      }
      if (
        adddata.checktype !== "0" &&
        adddata.Written_To !== "" &&
        adddata.Written_by !== "" &&
        adddata.Amount !== "" &&
        adddata.checkno !== "" &&
        Number(adddata.Amount) > 0 &&
        !Number.isNaN(Number(adddata.Amount))
      ) {
       
        if (
          adddata.checktype === "1" &&
          Number(FloatInputTxttofixed) >
            Number(
              (Number(objRAmtStatic) - Number(sumOfOtherTenderAmount)).toFixed(
                2
              )
            )
        ) {
          setpersonalcheckalert(true);
        } else if (
          adddata.checktype === "2" &&
          Number(FloatInputTxttofixed) >
            Number(
              (Number(objRAmtStatic) - Number(sumOfOtherTenderAmount)).toFixed(
                2
              )
            )
        ) {
          settravellercheckalert(true);
        } else {
          setaddcheck(false);
          setaddmoneyorder(false);
          const reduceremaingamount =
            Number(Number(objRAmtStatic).toFixed(2)) -
            Number(Number(FloatInputTxttofixed).toFixed(2)) -
            Number(Number(sumOfOtherTenderAmount).toFixed(2));
          let chargeCardAmount = 0;
          let swipeCardAmount = 0;
          for (let i = 0; i < tendertypeglobalarray.length; i++) {
            if (
              tendertypeglobalarray[i].tendertype
                .split(" - ")[0]
                .includes("Card Ending with")
            ) {
              chargeCardAmount =
                chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
            }
          }
          for (let i = 0; i < tendertypeglobalarray.length; i++) {
            if (
              tendertypeglobalarray[i].tendertype
                .split(" - ")[0]
                .includes("Card Swipe ending")
            ) {
              swipeCardAmount =
                swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
            }
          }
          // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
          let cardamount = Number(
            multipleCard.length == 0
              ? 0.0
              : multipleCard
                  .map((bill) => bill.Amount)
                  .reduce((acc, bill) =>
                    (Number(bill) + Number(acc)).toFixed(2)
                  )
          );
          cardamount =
            Number(cardamount) +
            Number(chargeCardAmount) +
            Number(swipeCardAmount);
          if (reduceremaingamount >= 0) {
            setremainingamountdue(reduceremaingamount);
            let isChecked = 0;
            for (
              let i = 0;
              i < document.getElementsByName("grid").length;
              i++
            ) {
              if (
                (document.getElementsByName("grid")[i] as HTMLInputElement)
                  .checked == true
              ) {
                isChecked = 1;
              }
            }
            if (reduceremaingamount === 0 && isChecked == 1) {
              if (cardamount > 0 && cvvTextValue.length >= 3)
                setAcceptpaymentbuttonenable(false);
              else if (cardamount > 0 && swipeFlag == false)
                setAcceptpaymentbuttonenable(true);
              else setAcceptpaymentbuttonenable(false);
            } else {
              setAcceptpaymentbuttonenable(true);
            }
          }
          let newARR = adddataanother;
          newARR.splice(uploadindex, 1, arr);
          setaddataanother(newARR);

          settotalamountcheck(totalcheck);
        }
      }
    }
    if (checkmonvar === "2") {
      if (
        adddata.Written_To !== "" &&
        adddata.Written_by !== "" &&
        adddata.Amount !== "" &&
        adddata.checkno !== "" &&
        Number(adddata.Amount) > 0 &&
        !Number.isNaN(Number(adddata.Amount))
      ) {
        if (
          Number(FloatInputTxttofixed) >
          Number(
            (Number(objRAmtStatic) - Number(sumOfOtherTenderAmount)).toFixed(2)
          )
        ) {
          setoverpayMoneyord(true);
        } else {
          setaddmoneyorder(false);
          setaddcheck(false);
          let isChecked = 0;
          for (let i = 0; i < document.getElementsByName("grid").length; i++) {
            if (
              (document.getElementsByName("grid")[i] as HTMLInputElement)
                .checked == true
            ) {
              isChecked = 1;
            }
          }
          const reduceremaingamount =
            Number(Number(objRAmtStatic).toFixed(2)) -
            Number(Number(FloatInputTxttofixed).toFixed(2)) -
            Number(Number(sumOfOtherTenderAmount).toFixed(2));
          if (reduceremaingamount >= 0 && isChecked == 1) {
            setremainingamountdue(reduceremaingamount);
            if (reduceremaingamount === 0) {
              let chargeCardAmount = 0;
              let swipeCardAmount = 0;
              for (let i = 0; i < tendertypeglobalarray.length; i++) {
                if (
                  tendertypeglobalarray[i].tendertype
                    .split(" - ")[0]
                    .includes("Card Ending with")
                ) {
                  chargeCardAmount =
                    chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
                }
              }
              for (let i = 0; i < tendertypeglobalarray.length; i++) {
                if (
                  tendertypeglobalarray[i].tendertype
                    .split(" - ")[0]
                    .includes("Card Swipe ending")
                ) {
                  swipeCardAmount =
                    swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
                }
              }
              // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
              let cardamount = Number(
                multipleCard.length == 0
                  ? 0.0
                  : multipleCard
                      .map((bill) => bill.Amount)
                      .reduce((acc, bill) =>
                        (Number(bill) + Number(acc)).toFixed(2)
                      )
              );
              cardamount =
                Number(cardamount) +
                Number(chargeCardAmount) +
                Number(swipeCardAmount);
              if (
                cardamount > 0 &&
                cvvTextValue.length < 3 &&
                swipeFlag == false
              ) {
                setAcceptpaymentbuttonenable(true);
              } else {
                setAcceptpaymentbuttonenable(false);
              }
            } else {
              setAcceptpaymentbuttonenable(true);
            }
          }
          let newARR = adddataanother;
          newARR.splice(uploadindex, 1, arr);
          setaddataanother(newARR);

          settotalamountcheck(totalcheck);
        }
      }
      if (adddata.checkno === "") {
        setchecknovalid(true);
      }
      if (adddata.Written_To === "") {
        setwrittento(true);
      }
      if (adddata.Written_by === "") {
        setwritenby(true);
      }
      if (
        adddata.Amount === "" ||
        Number(adddata.Amount) <= 0.0 ||
        Number(adddata.Amount) <= 0 ||
        Number.isNaN(Number(adddata.Amount))
      ) {
        //flag1 = 1;
        setAmount(true);
      }
    }
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type: checkmonvar=="1"?"update Check":"update Money Order"
    }
    setUserTraceData([...userTraceData,traceObj])
  };

  /*Here the delete functionality is used to delete the data in the binded grid.*/
  const Delete = (param: any) => {
    setchecking("1");
    adddataanother.splice(param, 1);
    LoadData();
  };
  const resetCardAmountEntered = () => {
    const cardamount = Number(
      multipleCard.length == 0
        ? 0.0
        : multipleCard
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
    );
    let objRemainAmount = 0;
    let totalOtherTenderAmt =
      Number(addcash) + Number(totalamountcheck) + Number(cardamount);
    objRemainAmount =
      Number(Number(RemaniningAmtStatic).toFixed(2)) -
      Number(Number(totalOtherTenderAmt).toFixed(2));
    setCardrequire({ ...Cardrequire, chkcvv: "0.00" });
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (tendertypeglobalarray[i].tendertype.includes("Card ending")) {
        tendertypeglobalarray.splice(i, 1);
        break;
      }
    }
    settotalduetendertype(tendertypeglobalarray);
    setCardGridData(CardGrid);
    setcardDetails([]);
    if (objRemainAmount > RemaniningAmtStatic) {
      setremainingamountdue(RemaniningAmtStatic);
      setAcceptpaymentbuttonenable(true);
    } else if (objRemainAmount < 0) {
      let newRemainAmt =
        Number(Number(RemaniningAmtStatic).toFixed(2)) -
        (Number(addcash) + Number(totalamountcheck));
      if (newRemainAmt > 0) {
        setchange(0.0);
        setremainingamountdue(newRemainAmt);
        setAcceptpaymentbuttonenable(true);
      } else if (newRemainAmt == 0) {
        setchange(0.0);
        setremainingamountdue(0.0);
        setAcceptpaymentbuttonenable(false);
      } else if (newRemainAmt < 0) {
        setchange(-1 * newRemainAmt);
        setremainingamountdue(0.0);
        setAcceptpaymentbuttonenable(false);
      }
    } else if (objRemainAmount == 0) {
      // setchange(0.00);
      // setremainingamountdue(0.00);
      let isChecked = 0;
      for (let i = 0; i < document.getElementsByName("grid").length; i++) {
        if (
          (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
          true
        ) {
          isChecked = 1;
        }
      }
      if (isChecked == 1) {
        let newRemainAmt =
          Number(Number(RemaniningAmtStatic).toFixed(2)) -
          (Number(addcash) + Number(totalamountcheck));
        if (newRemainAmt > 0) {
          setchange(0.0);
          setremainingamountdue(newRemainAmt);
          setAcceptpaymentbuttonenable(true);
        } else if (newRemainAmt == 0) {
          setchange(0.0);
          setremainingamountdue(0.0);
          setAcceptpaymentbuttonenable(false);
        } else if (newRemainAmt < 0) {
          setchange(-1 * newRemainAmt);
          setremainingamountdue(0.0);
          setAcceptpaymentbuttonenable(false);
        }
      } else {
        setchange(0.0);
        setremainingamountdue(0.0);
        setAcceptpaymentbuttonenable(true);
      }
    } else {
      let newRemainAmt =
        Number(Number(RemaniningAmtStatic).toFixed(2)) -
        (Number(addcash) + Number(totalamountcheck));
      if (newRemainAmt > 0) {
        setchange(0.0);
        setremainingamountdue(newRemainAmt);
        setAcceptpaymentbuttonenable(true);
      } else if (newRemainAmt == 0) {
        setchange(0.0);
        setremainingamountdue(0.0);
        setAcceptpaymentbuttonenable(false);
      } else if (newRemainAmt < 0) {
        setchange(-1 * newRemainAmt);
        setremainingamountdue(0.0);
        setAcceptpaymentbuttonenable(false);
      }
    }
    setcvvTextValue("");
    setCvv("");
  };
  // Card tab function starts here
  // Card tab's 1st checkbox function defenition
  let TempCard_Array: any = [];
  const SelectCardOptionChange = (
    index: any,
    expiredate: any,
    cardToken: any,
    cardlastFour: any,
    customerPaymentMethodId: string
  ) => {
   
    CardRadiArr.map((value: any, index: any) => {
      TempCard_Array.push(CardRadiArr[index]);
    });
    if (CardRadiArr[index] == true) {
      TempCard_Array.splice(index, 1, false);
    } else {
      TempCard_Array.splice(index, 1, true);
    }
    setCardRadiArr(TempCard_Array);
    let objRemainAmount = 0;
    if (
      cardGridData != undefined &&
      swipeFlag != true &&
      cardGridData.BillingAddress?.cardToken !== cardToken
    ) {
      objRemainAmount =
        parseFloat(remainingamountdue) + parseFloat(cardGridData.Amount);
      setCardrequire({ ...Cardrequire, chkcvv: "0.00" });
      let CardCheckObj: CardChk = {
        CardLastFour: String(cardGridData.CardLastFour),
        Amount: "0.00",
        BillingAddress: cardGridData.BillingAddress,
        cardExpiration: expiredate,
        customerPaymentMethodId: customerPaymentMethodId,
      };
      if (CardCheckObj.BillingAddress != undefined) {
        CardCheckObj.BillingAddress.cardToken = cardToken;
      }
      setCardGridData(CardCheckObj);
      if (objRemainAmount > RemaniningAmtStatic) {
        objRemainAmount = RemaniningAmtStatic;
      }
      const FloatInputTxttofixed = cardGridData.Amount;
      const tendertypedetails = {
        tendertype: cardlastFour,
        Amount: FloatInputTxttofixed,
        paymentMethodID: String(customerPaymentMethodId),
      };

      // setremainingamountdue(objRemainAmount);
      // setcvvTextValue("");
      // setCvv("");
    } else if (
      cardGridData != undefined &&
      cardGridData.BillingAddress?.cardToken === cardToken
    ) {
      let CardCheckObj: CardChk = {
        CardLastFour: String(cardGridData.CardLastFour),
        Amount: cardGridData.Amount,
        BillingAddress: cardGridData.BillingAddress,
        cardExpiration: expiredate,
        customerPaymentMethodId: customerPaymentMethodId,
      };
      CardCheckObj.BillingAddress != undefined
        ? (CardCheckObj.BillingAddress.cardToken = cardToken)
        : cardToken;
      setCardGridData(CardCheckObj);
    } else if (swipeFlag == true) {
      let CardCheckObj: CardChk = {
        CardLastFour: undefined,
        Amount: cardGridData.Amount,
        BillingAddress: undefined,
        cardExpiration: undefined,
      };
      setCardGridData(CardCheckObj);
    }

    // const TempCard_Array: any = new Array(CardRadiArr.length).fill(false);
    // TempCard_Array[index] = true;
    // setCardRadiArr(TempCard_Array);

    // for (let i = 0; i < document.getElementsByName("report").length; i++) {
    //   let objRdo = document.getElementsByName("report")[i] as HTMLInputElement;
    //   let divData = document.getElementById("div-" + i);
    //   setCardErrorLbl(false);
    //   if (divData != undefined) {
    //     if (index === i && objRdo.checked) {
    //       divData.style.display = "block";
    //     } else {
    //       divData.style.display = "none";
    //     }
    //   }
    // }
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    let objRdo = document.getElementsByName("Card CheckBox")[
      index
    ] as HTMLInputElement;
    let divData = document.getElementById("div-" + index);

    let cardAmount = 0.0;
    let nonCardAmount = 0.0;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].paymentMethodID ===
        String(customerPaymentMethodId)
      ) {
        tendertypeglobalarray.splice(i, 1);
        // cardArray.splice(i,1)
        break;
      } else if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card ending")
      ) {
        cardAmount = cardAmount + Number(tendertypeglobalarray[i].Amount);
      } else {
        nonCardAmount = nonCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    // nonCardAmount = nonCardAmount + Number(chargeCardAmt);
    multipleCard[index].Amount = "0.00";
    multipleCard[index].cvv = "";
    multipleCard[index].cvvEncrypt = "";
    settotalduetendertype(tendertypeglobalarray);
    // setcardDetails(cardArray)
    // debugger;
    let resTotalAmount = 0.0;
    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    // resTotalAmount = Number(
    //   (Number(resTotalAmount) + Number(chargeCardAmt)).toFixed(2)
    // );
    if (divData != undefined) {
      if (objRdo.checked) {
        divData.style.display = "block";
        if (
          Number(Number(cardAmount).toFixed(2)) >
          Number(totaldue) +
          Number(donationAmountAdd)+
            Number(addSusTotalAmount) -
            Number(availableCreds) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
        ) {
          setremainingamountdue(
            Number(
              (
                Number(totaldue)+
                Number(donationAmountAdd) +
                Number(addSusTotalAmount) -
                Number(availableCreds) -
                Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
              ).toFixed(2)
            ) - Number(nonCardAmount)
          );
          if (
            Number(totaldue)+
            Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(availableCreds) -
              Number(usedSusTotalAmount) -
              Number(nonCardAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount) <
            0
          ) {
            let change =
              -1 *
              (Number(totaldue)+
              Number(donationAmountAdd) +
                Number(addSusTotalAmount) -
                Number(availableCreds) -
                Number(usedSusTotalAmount) -
                Number(nonCardAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount));
            setchange(Number(change).toFixed(2));
          } else {
            setchange("0.00");
          }
          // setCardErrorLbl(true);
        } else {
          setremainingamountdue(
            Number(
              (
                Number(totaldue)+
                Number(donationAmountAdd) +
                Number(addSusTotalAmount) -
                Number(availableCreds) -
                Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
              ).toFixed(2)
            ) - Number(resTotalAmount)
          );
          if (
            Number(totaldue)+
            Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(availableCreds) -
              Number(usedSusTotalAmount) -
              Number(resTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount) <
            0
          ) {
            let change =
              -1 *
              (Number(totaldue)+
              Number(donationAmountAdd) +
                Number(addSusTotalAmount) -
                Number(availableCreds) -
                Number(usedSusTotalAmount) -
                Number(resTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount));
            setchange(Number(change).toFixed(2));
          } else {
            setchange("0.00");
          }
        }
      } else {
        divData.style.display = "none";
        //
        if (
          Number(
            (
              Number(totaldue) +
              Number(donationAmountAdd)+
              Number(addSusTotalAmount) -
              Number(availableCreds) -
              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
            ).toFixed(2)
          ) -
            Number(resTotalAmount) >
          0
        ) {
          // debugger;
          setremainingamountdue(
            Number(
              (
                Number(totaldue) +
                Number(donationAmountAdd)+
                Number(addSusTotalAmount) -
                Number(availableCreds) -
                Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
              ).toFixed(2)
            ) - Number(resTotalAmount)
          );
          setchange("0.00");
        } else if (
          Number(Number(cardAmount).toFixed(2)) <=
          Number(totaldue)+
          Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(availableCreds) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
        ) {
          setremainingamountdue("0.00");
          if (
            Number(totaldue)+
            Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(availableCreds) -
              Number(usedSusTotalAmount) -
              Number(resTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount) <
            0
          ) {
            let change =
              -1 *
              (Number(totaldue) +
              Number(donationAmountAdd)+
                Number(addSusTotalAmount) -
                Number(availableCreds) -
                Number(usedSusTotalAmount) -
                Number(resTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount));
            setchange(Number(change).toFixed(2));
          } else {
            setchange("0.00");
          }
        } else if (
          Number(Number(nonCardAmount).toFixed(2)) >=
          Number(totaldue)+
          Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(availableCreds) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
        ) {
          //  if(nonCardAmount>){
          setremainingamountdue("0.00");
          if (
            Number(totaldue)+
            Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(availableCreds) -
              Number(usedSusTotalAmount) -
              Number(nonCardAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount) <
            0
          ) {
            let change =
              -1 *
              (Number(totaldue) +
              Number(donationAmountAdd)+
                Number(addSusTotalAmount) -
                Number(availableCreds) -
                Number(usedSusTotalAmount) -
                Number(nonCardAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount));
            setchange(Number(change).toFixed(2));
          } else {
            setchange("0.00");
          }
          //  }
        }
        // setremainingamountdue(Number(Number(remainingamountdue)+Number(multipleCard[index].Amount)).toFixed(2))
      }
    }

    // if(Number(remainingamountdue)!=0.00){
    //   setAcceptpaymentbuttonenable(true)
    // }
    let enableAcc = true;
    let amountChk = 0;
    for (let i = 0; i < multipleCard.length; i++) {
      amountChk = amountChk + Number(multipleCard[i].Amount);
    }
    if (
      (Number(Number(amountChk).toFixed(2)) >
      Number(totaldue) -
        Number(availableCreds) +
        Number(donationAmountAdd)+
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount))&&Number(change)==0
    ) {
      setCardErrorLbl(true);
    } else {
      enableAcc = false;
      setCardErrorLbl(false);
    }
    let enablepayment = true;
    for (let i = 0; i < multipleCard.length; i++) {
      if (
        Number(multipleCard[i].Amount > 0) &&
        multipleCard[i].cvvEncrypt.length < 3
      ) {
        enablepayment = false;
      }
    }
    if (
      Number(totaldue) -
        Number(availableCreds) +
        Number(donationAmountAdd)+
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount) -
        Number(resTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount) <=
        0 &&
      selectedCheckBoxCount != 0 &&
      enableAcc == false &&
      enablepayment &&
      Number(chargeCardAmt) == 0
    ) {
      setAcceptpaymentbuttonenable(false);
    } else {
      setAcceptpaymentbuttonenable(true);
    }

    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:CardRadiArr[index] ==false?"Card Select":"Card Unselect"
    }
    setUserTraceData([...userTraceData,traceObj])
    // debugger;
    // if(Number(remainingamountdue)!=0.00){
    //   setAcceptpaymentbuttonenable(true)
    // }

  };

  const SelectCardOptionChangeRevenue = (
    index: any,
    expiredate: any,
    cardToken: any,
    cardlastFour: any,
    customerPaymentMethodId: string
  ) => {
    let actingCardCollector: any = [];
    CardRadiArr.map((value: any, index: any) => {
      actingCardCollector.push(CardRadiArr[index]);
    });
    if (CardRadiArr[index] == true) {
      actingCardCollector.splice(index, 1, false);
    } else {
      actingCardCollector.splice(index, 1, true);
    }
    const isMoreThanOneSelected = actingCardCollector.filter((x) => x === true);

    if (diffStoreErrLbl && isMoreThanOneSelected.length > 1) {
      setRestrictMultiCard(true);
    } else {
      setRestrictMultiCard(false);

      CardRadiArr.map((value: any, index: any) => {
        TempCard_Array.push(CardRadiArr[index]);
      });
      if (CardRadiArr[index] == true) {
        TempCard_Array.splice(index, 1, false);
      } else {
        TempCard_Array.splice(index, 1, true);
      }
      setCardRadiArr(TempCard_Array);
      let objRemainAmount = 0;
      if (
        cardGridData != undefined &&
        swipeFlag != true &&
        cardGridData.BillingAddress?.cardToken !== cardToken
      ) {
        objRemainAmount =
          parseFloat(remainingamountdue) + parseFloat(cardGridData.Amount);
        setCardrequire({ ...Cardrequire, chkcvv: "0.00" });
        let CardCheckObj: CardChk = {
          CardLastFour: String(cardGridData.CardLastFour),
          Amount: "0.00",
          BillingAddress: cardGridData.BillingAddress,
          cardExpiration: expiredate,
          customerPaymentMethodId: customerPaymentMethodId,
        };
        if (CardCheckObj.BillingAddress != undefined) {
          CardCheckObj.BillingAddress.cardToken = cardToken;
        }
        setCardGridData(CardCheckObj);
        if (objRemainAmount > RemaniningAmtStatic) {
          objRemainAmount = RemaniningAmtStatic;
        }
        const FloatInputTxttofixed = cardGridData.Amount;
        const tendertypedetails = {
          tendertype: cardlastFour,
          Amount: FloatInputTxttofixed,
          paymentMethodID: String(customerPaymentMethodId),
        };

        // setremainingamountdue(objRemainAmount);
        // setcvvTextValue("");
        // setCvv("");
      } else if (
        cardGridData != undefined &&
        cardGridData.BillingAddress?.cardToken === cardToken
      ) {
        let CardCheckObj: CardChk = {
          CardLastFour: String(cardGridData.CardLastFour),
          Amount: cardGridData.Amount,
          BillingAddress: cardGridData.BillingAddress,
          cardExpiration: expiredate,
          customerPaymentMethodId: customerPaymentMethodId,
        };
        CardCheckObj.BillingAddress != undefined
          ? (CardCheckObj.BillingAddress.cardToken = cardToken)
          : cardToken;
        setCardGridData(CardCheckObj);
      } else if (swipeFlag == true) {
        let CardCheckObj: CardChk = {
          CardLastFour: undefined,
          Amount: cardGridData.Amount,
          BillingAddress: undefined,
          cardExpiration: undefined,
        };
        setCardGridData(CardCheckObj);
      }

      // const TempCard_Array: any = new Array(CardRadiArr.length).fill(false);
      // TempCard_Array[index] = true;
      // setCardRadiArr(TempCard_Array);

      // for (let i = 0; i < document.getElementsByName("report").length; i++) {
      //   let objRdo = document.getElementsByName("report")[i] as HTMLInputElement;
      //   let divData = document.getElementById("div-" + i);
      //   setCardErrorLbl(false);
      //   if (divData != undefined) {
      //     if (index === i && objRdo.checked) {
      //       divData.style.display = "block";
      //     } else {
      //       divData.style.display = "none";
      //     }
      //   }
      // }
      const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Add" && Number(e.newsuspense) > 0;
      });
      const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Used" && Number(e.newsuspense) > 0;
      });
      let addSusTotalAmount =
        addSuspenseAmount.length == 0
          ? 0.0
          : addSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let usedSusTotalAmount =
        useSuspenseAmount.length == 0
          ? 0.0
          : useSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

      let objRdo = document.getElementsByName("Card CheckBox")[
        index
      ] as HTMLInputElement;
      let divData = document.getElementById("div-" + index);

      let cardAmount = 0.0;
      let nonCardAmount = 0.0;
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].paymentMethodID ===
          String(customerPaymentMethodId)
        ) {
          tendertypeglobalarray.splice(i, 1);
          // cardArray.splice(i,1)
          break;
        } else if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card ending")
        ) {
          cardAmount = cardAmount + Number(tendertypeglobalarray[i].Amount);
        } else {
          nonCardAmount =
            nonCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }
      // nonCardAmount = nonCardAmount + Number(chargeCardAmt);
      multipleCard[index].Amount = "0.00";
      multipleCard[index].cvv = "";
      multipleCard[index].cvvEncrypt = "";
      settotalduetendertype(tendertypeglobalarray);
      // setcardDetails(cardArray)
      // debugger;
      let resTotalAmount = 0.0;
      resTotalAmount =
        totalduetendertype.length == 0
          ? 0.0
          : totalduetendertype
              .map((bill) => bill.Amount)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      // resTotalAmount = Number(
      //   (Number(resTotalAmount) + Number(chargeCardAmt)).toFixed(2)
      // );
      if (divData != undefined) {
        if (objRdo.checked) {
          divData.style.display = "block";
          if (
            Number(Number(cardAmount).toFixed(2)) >
            Number(totaldue) +
            Number(donationAmountAdd)+
              Number(addSusTotalAmount) -
              Number(availableCreds) -
              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ) {
            setremainingamountdue(
              Number(
                (
                  Number(totaldue)+
                  Number(donationAmountAdd) +
                  Number(addSusTotalAmount) -
                  Number(availableCreds) -
                  Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                ).toFixed(2)
              ) - Number(nonCardAmount)
            );
            if (
              Number(totaldue)+
              Number(donationAmountAdd) +
                Number(addSusTotalAmount) -
                Number(availableCreds) -
                Number(usedSusTotalAmount) -
                Number(nonCardAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount) <
              0
            ) {
              let change =
                -1 *
                (Number(totaldue) +
                Number(donationAmountAdd)+
                  Number(addSusTotalAmount) -
                  Number(availableCreds) -
                  Number(usedSusTotalAmount) -
                  Number(nonCardAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount));
              setchange(Number(change).toFixed(2));
            } else {
              setchange("0.00");
            }
            setCardErrorLbl(true);
          } else {
            setremainingamountdue(
              Number(
                (
                  Number(totaldue) +
                  Number(donationAmountAdd)+
                  Number(addSusTotalAmount) -
                  Number(availableCreds) -
                  Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                ).toFixed(2)
              ) - Number(resTotalAmount)
            );
            if (
              Number(totaldue) +
                Number(donationAmountAdd)+
                Number(addSusTotalAmount) -
                Number(availableCreds) -
                Number(usedSusTotalAmount) -
                Number(resTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)<
              0
            ) {
              let change =
                -1 *
                (Number(totaldue)+
                  Number(donationAmountAdd) +
                  Number(addSusTotalAmount) -
                  Number(availableCreds) -
                  Number(usedSusTotalAmount) -
                  Number(resTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount));
              setchange(Number(change).toFixed(2));
            } else {
              setchange("0.00");
            }
          }
        } else {
          divData.style.display = "none";
          //
          if (
            Number(
              (
                Number(totaldue) +
                Number(donationAmountAdd)+
                Number(addSusTotalAmount) -
                Number(availableCreds) -
                Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
              ).toFixed(2)
            ) -
              Number(resTotalAmount) >
            0
          ) {
            // debugger;
            setremainingamountdue(
              Number(
                (
                  Number(totaldue)+
                  Number(donationAmountAdd) +
                  Number(addSusTotalAmount) -
                  Number(availableCreds) -
                  Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
                ).toFixed(2)
              ) - Number(resTotalAmount)
            );
            setchange("0.00");
          } else if (
            Number(Number(cardAmount).toFixed(2)) <=
            Number(totaldue)+
            Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(availableCreds) -
              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ) {
            setremainingamountdue("0.00");
            if (
              Number(totaldue) +
              Number(donationAmountAdd)+
                Number(addSusTotalAmount) -
                Number(availableCreds) -
                Number(usedSusTotalAmount) -
                Number(resTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount) <
              0
            ) {
              let change =
                -1 *
                (Number(totaldue) +
                Number(donationAmountAdd)+
                  Number(addSusTotalAmount) -
                  Number(availableCreds) -
                  Number(usedSusTotalAmount) -
                  Number(resTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount));
              setchange(Number(change).toFixed(2));
            } else {
              setchange("0.00");
            }
          } else if (
            Number(Number(nonCardAmount).toFixed(2)) >=
            Number(totaldue) +
            Number(donationAmountAdd)+
              Number(addSusTotalAmount) -
              Number(availableCreds) -
              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ) {
            //  if(nonCardAmount>){
            setremainingamountdue("0.00");
            if (
              Number(totaldue) +
              Number(donationAmountAdd)+
                Number(addSusTotalAmount) -
                Number(availableCreds) -
                Number(usedSusTotalAmount) -
                Number(nonCardAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount) <
              0
            ) {
              let change =
                -1 *
                (Number(totaldue)+
                Number(donationAmountAdd) +
                  Number(addSusTotalAmount) -
                  Number(availableCreds) -
                  Number(usedSusTotalAmount) -
                  Number(nonCardAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount));
              setchange(Number(change).toFixed(2));
            } else {
              setchange("0.00");
            }
            //  }
          }
          // setremainingamountdue(Number(Number(remainingamountdue)+Number(multipleCard[index].Amount)).toFixed(2))
        }
      }

      // if(Number(remainingamountdue)!=0.00){
      //   setAcceptpaymentbuttonenable(true)
      // }
      let enableAcc = true;
      let amountChk = 0;
      for (let i = 0; i < multipleCard.length; i++) {
        amountChk = amountChk + Number(multipleCard[i].Amount);
      }
      if (
        Number(Number(amountChk).toFixed(2)) >
        Number(totaldue) -
          Number(availableCreds) +
          Number(donationAmountAdd)+
          Number(addSusTotalAmount) -
          Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
      ) {
        setCardErrorLbl(true);
      } else {
        enableAcc = false;
        setCardErrorLbl(false);
      }
      let enablepayment = true;
      for (let i = 0; i < multipleCard.length; i++) {
        if (
          Number(multipleCard[i].Amount > 0) &&
          multipleCard[i].cvvEncrypt.length < 3
        ) {
          enablepayment = false;
        }
      }
      if (
        Number(totaldue) -
          Number(availableCreds) +
          Number(donationAmountAdd)+
          Number(addSusTotalAmount) -
          Number(usedSusTotalAmount) -
          Number(resTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount) <=
          0 &&
        selectedCheckBoxCount != 0 &&
        enableAcc == false &&
        enablepayment &&
        Number(chargeCardAmt) == 0
      ) {
        setAcceptpaymentbuttonenable(false);
      } else {
        setAcceptpaymentbuttonenable(true);
      }
    }
    // debugger;
    // if(Number(remainingamountdue)!=0.00){
    //   setAcceptpaymentbuttonenable(true)
    // }
  };
  // Swipecard Model function defines here
  const Swipecard = () => {
    let isChecked = 0;
    for (let i = 0; i < document.getElementsByName("grid").length; i++) {
      if (
        (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
        true
      ) {
        isChecked = 1;
      }
    }
    if (isChecked == 1) {
      setCardrequire({ ...Cardrequire, swipcard: true, Proceedbtn: true });
      setswipeDeviceValue("0.00");
      setValidator({ ...Validator, SwipeAmtValidationtxtbox: false });
      setgettotamt("0.00");
    }
  };
  const manageCard = () => {
    if (paygridvalues[0].Phonenumber == "") {
      setphoneNumberRequired(true);
    } else {
      setManageCardsla(true);
      fromManageCard = true;
      setFromManageCard(fromManageCard);
    }
  };

  //vantiv func
  const callVantivIframe = (iframeType: number) => {
    if (iframeType == 1) {
      setVantivIframe(
        loadVantivIFrame("01190090", "ccframe", handleVantivCallback)
      );
    } else if (iframeType == 2) {
      //const temp=loadVantivIFrame('01190090', 'ccframe', handleVantivCallback);
      setVantivIframe(
        loadVantivIFrame("01190090", "chargeframe", handleVantivChargeCallback)
      );
    }
    return 1;
  };
  const handleVantivCallback = (response: any) => {
    if (response.response === "870") {
      //setIsSubmitting(true);
      const card = new CreditCard(response);
      //const formValues: FormValues = getValues();
      const request: ProcessPaymentLogRequest = {
        //paymentLogId: paymentLog.paymentLogId.toString(),
        paymentToken: response.paypageRegistrationId,
        cardType: card.type,
        expirationDate: card.cardExpiration,
        lastFour: card.lastDigits,
        savePaymentMethod: "Y",
      };
      vantivSubmit(request);
      close();
    } else {
      handleVantivErrorResponse(response.response);
    }
  };
  const handleVantivChargeCallback = (response: any) => {
    if (response.response === "870") {
      //setIsSubmitting(true);
      const card = new CreditCard(response);
      //const formValues: FormValues = getValues();
      const request: ProcessPaymentLogRequest = {
        //paymentLogId: paymentLog.paymentLogId.toString(),
        paymentToken: response.paypageRegistrationId,
        cardType: card.type,
        expirationDate: card.cardExpiration,
        lastFour: card.lastDigits,
        savePaymentMethod: "Y",
      };
      vantivChargeSubmit(request);
      close();
    } else {
      chargeCardContinueButtonFlag=false;
      setChargeCardContinueButtonFlag(chargeCardContinueButtonFlag);
      handleVantivErrorResponse(response.response);
    }
  };

  const cardSuccessfullySaved = () => {
    return (
      <Grid
        item
        data-testid="transcomppart"
        id="transatraction"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        {saveCardSuccess ? (
          <Grid item md={12} className={classes.textcenter}>
            <SuccessIcon></SuccessIcon>
            <Typography
              className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
            >
              Card Saved Successfully
            </Typography>
          </Grid>
        ) : saveCardFail ? (
          <Grid item md={12} className={classes.textcenter}>
            <AlertIcon className={classes.racErrorIcon}></AlertIcon>
            <Typography
              className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
            >
              {failedToSaveCardError == ""
                ? "Something went wrong - Unable to save card. Please try with different card/retry"
                : failedToSaveCardError}
            </Typography>
          </Grid>
        ) : (
          ""
        )}

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => savedCardOk()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const cardSuccessfullyDeleted = () => {
    return (
      <Grid
        item
        data-testid="transcomppart"
        id="transatraction"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        {deleteCardSuccess ? (
          <Grid item md={12} className={classes.textcenter}>
            <SuccessIcon></SuccessIcon>
            <Typography
              className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
            >
              Card Deleted Successfully
            </Typography>
          </Grid>
        ) : deleteCardFail ? (
          <Grid item md={12} className={classes.textcenter}>
            <AlertIcon className={classes.racErrorIcon}></AlertIcon>
            <Typography
              className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
            >
              {failedToDeleteCardError == ""
                ? "Something went wrong - Unable to delete card. Please retry"
                : failedToDeleteCardError}
            </Typography>
          </Grid>
        ) : (
          ""
        )}
        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => deleteCardOk()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };


  const cardFailedVantivError = () => {
    return (
      <Grid
        item
        data-testid="transcomppart"
        id="transatraction"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        {vantivError != "" ? (
          <Grid item md={12} className={classes.textcenter}>
            <AlertIcon className={classes.racErrorIcon}></AlertIcon>
            <Typography
              className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
            >
              {vantivError}
            </Typography>
          </Grid>
        ) : (
          ""
        )}

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => setVantivError("")}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const savedCardOk = () => {
    setsaveCardFail(false);
    setsaveCardSuccess(false);
    setsaveCardPopUp(false);
    setFailedToSaveCardError("");
  };

  const deleteCardOk = () =>{
    setdeleteCardFail(false);
    setdeleteCardSuccess(false);
    setdeleteCardPopUp(false);
    setFailedToDeleteCardError("");
  }

  const spinnerpopupSaveCard = () => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textcenter}>
          <ClipLoader color="blue" loading={saveCardPopUp} size={40} />
          <Typography className="row justify-content-center popup-text">
            Saving card
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.textcenter}>
          <Typography variant="h6" className={classes.mt3}>
            Please Wait...
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const spinnerpopupDeleteCard = () => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textcenter}>
          <ClipLoader color="blue" loading={deleteCardPopUp} size={40} />
          <Typography className="row justify-content-center popup-text">
            Deleting card
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.textcenter}>
          <Typography variant="h6" className={classes.mt3}>
            Please Wait...
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const EditCardfunc = async () => {
    if (
      EditCard.Cardname !== "" &&
      EditCard.address_1 !== "" &&
      EditCard.city !== "" &&
      EditCard.zip !== "" &&
      EditCard.state !== "0"
    ) {
      setCardrequire({ ...Cardrequire, editcrd: false });
      const customerInfoParam: CustomerInfo = {
        FirstName: String(getpayment?.paymentInfo.customerInfo[0].firstName),
        LastName: String(getpayment?.paymentInfo.customerInfo[0].lastName),
      };
      const BillInfo: SaveBillinginfo = {
        Address1: EditCard.address_1,
        Address2: EditCard.address_2 == null ? "" : EditCard.address_2,
        City: EditCard.city,
        StateProvince: EditCard.state,
        PostalCode: EditCard.zip,
        Country: "USA",
        BillShipSameAddressFlag: "0",
      };
      const NewCardReq: SaveCardRequestDTO = {
        raftApiFlag: Number(configFlags.raftApiFlag),
        storeMerchantId: storeMerchantId || null,
        paymentFlag: 0,
        clubFlag: 0,
        updateFlag: 1,
        cardType: getCardService_state.cardResp[editcardindex].cardType,
        cardToken: String(
          getCardService_state.cardResp[editcardindex].cardToken
        ),
        cardHolderName: String(EditCard.Cardname),
        cardExpiration: String(EditCard.cardExpDate),
        storeId: String(window.sessionStorage.getItem("storeNumber")),
        transactionAmount: "0.00",
        cardNumberLastFour:
          getCardService_state.cardResp[editcardindex].cardLastFour,
        customerId: Number(customerId),
        PaymentID: "",
        Amount: 0,
        SalesTax: 0,
        CustomerInfo: customerInfoParam,
        BillToAddress: BillInfo,
        agreementIds: [],
      };
      setsaveCardPopUp(true);
      let savecardres = await Savecustomercard(NewCardReq);
      if (
        savecardres?.status === 200 &&
        savecardres?.data !== "" &&
        savecardres?.data?.customerId != undefined
      ) {
        getCardAfterUpdate();
        setsaveCardPopUp(false);
        setsaveCardSuccess(true);
      } else {
        setsaveCardPopUp(false);
        setsaveCardFail(true);
      }
    } else {
      if (EditCard.Cardname === "") {
        setnameVal(true);
      }
      if (EditCard.address_1 === "") {
        setaddressval1(true);
      }
      if (EditCard.city === "") {
        setcityVal(true);
      }
      if (EditCard.state === "0") {
        setstateVal(true);
      }
      if (EditCard.zip === "") {
        setZipVal(true);
      }
    }
  };

  const vantivSubFunc = (src?: any) => {
    if (
      Popupfields.name !== "" &&
      Popupfields.address_line_1 !== "" &&
      Popupfields.zip_code !== "" &&
      Popupfields.city !== "" &&
      defaultVal !== "0"
    ) {
      chargeCardContinueButtonFlag=true;
      setChargeCardContinueButtonFlag(chargeCardContinueButtonFlag);
      getRegistrationId(vantivIframe, null);

      // if(src == 'Charge Card') {
      //   const cardAmount =
      //   totalduetendertype.length == 0
      //     ? 0.0
      //     : totalduetendertype
      //         .map((bill) => bill.Amount)
      //         .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      //   const objRemainAmt =
      //     RemaniningAmtStatic - parseFloat(String(chargeCardValue)) - Number(cardAmount);  
      //   setremainingamountdue(Number(objRemainAmt.toFixed(2)));
      //   setchange("0.00");
      // }
      
    } else {
      if (Popupfields.address_line_1 === "") {
        setAddress_line1_validation(true);
      }
      if (Popupfields.name === "") {
        setName_validation(true);
      }
      if (defaultVal === "0") {
        setState_validation(true);
      }
      if (Popupfields.zip_code === "") {
        setZip_code_validation(true);
      }
      if (Popupfields.city === "") {
        setCityValidation(true);
      }
    }
    //ChargeCard details pre-fill
    setChargeCardFlag(false);
  };

  const vantivSubmit = async (request: ProcessPaymentLogRequest) => {
    let isCardClicked = true;
    setshowAddCard(false);
    const customerInfoParam: CustomerInfo = {
      FirstName: String(getpayment?.paymentInfo.customerInfo[0].firstName),
      LastName: String(getpayment?.paymentInfo.customerInfo[0].lastName),
    };
    const BillInfo: SaveBillinginfo = {
      Address1: String(window.sessionStorage.getItem("billAdd1")),
      Address2: String(window.sessionStorage.getItem("billAdd2")),
      City: String(window.sessionStorage.getItem("billCity")),
      StateProvince: String(window.sessionStorage.getItem("billState")),
      PostalCode: String(window.sessionStorage.getItem("billZip")),
      Country: "USA",
      BillShipSameAddressFlag: "0",
    };
    const NewCardReq: SaveCardRequestDTO = {
      raftApiFlag: Number(configFlags.raftApiFlag),
      storeMerchantId: storeMerchantId || null,
      paymentFlag: 0,
      clubFlag: 0,
      updateFlag: 0,
      cardType: String(request.cardType),
      cardToken: request.paymentToken,
      cardHolderName: String(window.sessionStorage.getItem("billName")),
      cardExpiration: String(request.expirationDate),
      storeId: String(window.sessionStorage.getItem("storeNumber")),
      transactionAmount: "0.00",
      cardNumberLastFour: String(request.lastFour),
      customerId: Number(customerId),
      PaymentID: String(request.paymentToken),
      Amount: 0,
      SalesTax: 0,
      CustomerInfo: customerInfoParam,
      BillToAddress: BillInfo,
      agreementIds: [],
    };

    setsaveCardPopUp(true);
    let savecardres = await Savecustomercard(NewCardReq);
    if (
      savecardres.status === 200 &&
      savecardres.data !== "" &&
      savecardres?.data?.customerId != undefined
    ) {

      await getCardAfterUpdate();
      setsaveCardPopUp(false);
      setsaveCardSuccess(true);
      let traceObj={
        iD:Number(userTraceData?.length)+1,
        type:"Add Card Save"
      }
      setUserTraceData([...userTraceData,traceObj])
      if (isCardClicked && multipleCard === undefined) {
        const TempCard_Array: any = new Array(CardRadiArr.length).fill(false);
        setCardRadiArr(TempCard_Array);
      }
    } else {
      setsaveCardPopUp(false);
      setsaveCardFail(true);
      if (
        savecardres?.status === 400 &&
        savecardres?.data?.errors[0]?.error?.serviceResponse?.Body?.StatusInfo
          ?.StatusCodeDetail
      ) {
        let declineCode =
          savecardres?.data?.errors[0]?.error?.serviceResponse?.Body
            ?.declineCode;
        if (
          savecardres?.data?.errors[0]?.error?.serviceResponse?.Body?.StatusInfo
            ?.statusCode
        ) {
          let internalStatusCode =
            savecardres?.data?.errors[0]?.error?.serviceResponse?.Body
              ?.StatusInfo?.statusCode;
          switch (internalStatusCode) {
            case "DP":
              declineCode = 712;
              break;
          }
        }
        let cardDeclineMessage =
          "Card Saving Declined. Please try a different card.";
        switch (Number(declineCode)) {
          case 39:
            cardDeclineMessage =
              "Card Saving Declined. Insufficient Fund. Please try a different card";
            break;
          case 127:
            cardDeclineMessage =
              "Card Saving Declined. Exceeds Approval Amount Limit";
            break;
          case 327:
            cardDeclineMessage =
              "Card Saving Declined. Cardholder transaction not permitted";
            break;
          case 322:
            cardDeclineMessage = "Card Saving Declined. Invalid Transaction";
            break;
          case 330:
            cardDeclineMessage = "Card Saving Declined. Invalid Payment Type";
            break;
          case 321:
            cardDeclineMessage = "Card Saving Declined. Invalid Merchant.";
            break;
          case 217:
            cardDeclineMessage = "Card Saving Declined. Card Already Active.";
            break;
          case 822:
            cardDeclineMessage = "Card Saving Declined. Card token not found.";
            break;
          case 340:
            cardDeclineMessage = "Card Saving Declined. Invalid Amount.";
            break;
          case 11:
            cardDeclineMessage =
              "Card Saving Declined. Transaction not permitted.";
            break;
          case 110:
            cardDeclineMessage =
              "Card Saving Declined. Insufficient Fund. Please try a different card";
            break;
          case 301:
            cardDeclineMessage =
              "Card Saving Declined. Invalid Account Number. Please try with different card.";
            break;
          case 326:
            cardDeclineMessage =
              "Card Saving Declined exceeded number of Pin entries. Please try with different card.";
            break;
          case 324:
            cardDeclineMessage =
              "Card Saving Declined. Invalid Pin. Please try with valid Pin Number.";
            break;
          case 9:
            cardDeclineMessage =
              "Card Saving Declined. Invalid Card Number. Please try with different card.";
            break;
          case 4:
            cardDeclineMessage =
              "Card Saving Declined. Expired Card. Please try with different card.";
            break;
          case 13:
            cardDeclineMessage =
              "Card Saving Declined. Please try a different card.";
            break;
          case 26:
            cardDeclineMessage =
              "Card Saving Declined. Exceeds withdrawal limit please try a different card";
            break;
        }
        setFailedToSaveCardError(cardDeclineMessage);
      }
    }
    setPopupfields(newcardinformation);
    setdefaultVal("0");
  };

  const vantivChargeSubmit = async (request: ProcessPaymentLogRequest) => {
    setshowChargeCard(false);
    // setcardtxtDisable(true);
    // setchargeCardDisable(true);
    //setshowChargeCard(true);
    setshowAddCard(false);
    setaddNewCard(true);

    setpaymentOriginDisable(false);
    let cardType = "Unknown";
    let cardRef = "U";
    switch (request.cardType) {
      case "1":
        cardType = "Master";
        cardRef = "M";
        break;
      case "2":
        cardType = "Visa";
        cardRef = "V";
        break;
      case "3":
        cardType = "American Express";
        cardRef = "A";
        break;
      case "4":
        cardType = "Discover";
        cardRef = "D";
        break;
      case "5":
        cardType = "Diners Club";
        cardRef = "DC";
        break;
      case "6":
        cardType = "JCB";
        cardRef = "J";
        break;
      case "7":
        cardType = "PayPal";
        cardRef = "PP";
        break;
      case "10":
        cardType = "Card";
        cardRef = "C";
        break;
      case "11":
        cardType = "Unknown";
        cardRef = "U";
        break;
    }
    let cardDetail = {
      cardAmount: Number(chargeCardValue),
      cardAuthNumber: "",
      cardLastFour: request.lastFour,
      cardType: cardType,
      cardToken: request.paymentToken,
      cardTransactionType: "MNL",
      cardRef: cardRef,
    };
    // swipeCardDetails.push(cardDetail);
    // setcardDetails(swipeCardDetails);

    let b = "/";
    let position = 2;
    let cardExpirationParse = [
      String(request.expirationDate).slice(0, position),
      b,
      String(request.expirationDate).slice(position),
    ].join("");
    const BillInfo: SaveBillinginfo = {
      Address1: String(window.sessionStorage.getItem("billAdd1")),
      Address2: "",
      City: String(window.sessionStorage.getItem("billCity")),
      StateProvince: String(window.sessionStorage.getItem("billState")),
      PostalCode: String(window.sessionStorage.getItem("billZip")),
      Country: "USA",
      BillShipSameAddressFlag: "0",
    };
    setChargeBillInfo(BillInfo);
    let CardCheckObj: CardChk = {
      CardLastFour: String(request.lastFour),
      Amount: Number(String(chargeCardValue)).toFixed(2),
      BillingAddress: undefined,
      cardExpiration: String(cardExpirationParse),
      customerPaymentMethodId: String(request.paymentToken),
      cardType: String(cardType),
    };
    setCardGridData(CardCheckObj);
    FormAcceptPaymentData(0, 0, CardCheckObj);
    const CardDetail = "Card Ending with - " + request.lastFour;
    const tendertypedetails = {
      tendertype: CardDetail,
      Amount: String(chargeCardValue),
    };

    let chargeBillInfo = {
      addressLine1: String(window.sessionStorage.getItem("billAdd1")),
      addressLine2: "",
      city: String(window.sessionStorage.getItem("billCity")),
      state: String(window.sessionStorage.getItem("billState")),
      zip: String(window.sessionStorage.getItem("billZip")),
    };

    //  debugger;

    let multipleCardPush = {
      lastFour: String(request.lastFour),
      Amount: Number(String(chargeCardValue)).toFixed(2),
      billingAddress: chargeBillInfo,
      expirationDate: String(cardExpirationParse),
      paymentId: String(request.paymentToken),
      cardType: String(cardType),
      cvv: "",
      cvvEncrypt: "***",
      chargeCard: true,
      cardToken: String(request.paymentToken),
      cardRef: cardRef,
    };
    tendertypeglobalarray.push(tendertypedetails);
    settotalduetendertype(tendertypeglobalarray);
    // multipleCard=multipleCard.push(multipleCardPush)
    // setmultipleCard(multipleCard)
    // setchargeCardAmt("0.00")
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    setchargeCardAmt("0.00");
    chargeCardArray = [...chargeCardArray, multipleCardPush];
    setchargeCardArray(chargeCardArray);
    setcardToken([
      ...cardToken,
      {
        lastFour: String(request.lastFour),
        cardToken: String(request.paymentToken),
      },
    ]);
    let payButton = 0;
    for (let i = 0; i < multipleCard.length; i++) {
      if (
        Number(multipleCard[i].Amount) > 0 &&
        multipleCard[i].cvvEncrypt.length < 3
      ) {
        payButton++;
      }
    }
    const totalamount =
      tendertypeglobalarray.length == 0
        ? 0.0
        : tendertypeglobalarray
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (
      Number(
        (
          Number(totaldue) -
          Number(availableCreds)+
          Number(donationAmountAdd) +
          Number(addSusTotalAmount) -
          Number(usedSusTotalAmount) -
          Number(totalamount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
        ).toFixed(2)
      ) <= 0 &&
      payButton == 0
    ) {
      setAcceptpaymentbuttonenable(false);
    } else {
      setAcceptpaymentbuttonenable(true);
    }
    setPopupfields(newcardinformation);
    setdefaultVal("0");
    setTenderUseEffectCalculation(true);
    chargeCardContinueButtonFlag=false;
    setChargeCardContinueButtonFlag(chargeCardContinueButtonFlag);
    // multipleAdjust()
    // editAdjustRes({resArray:checkedAgr})
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:"Charge Card Save"
    }
    setUserTraceData([...userTraceData,traceObj])
  };

  const handleVantivErrorResponse = (responseCode: string | number) => {
    const isCreditCardNumberError = ["871", "872", "873", "874", "876"].some(
      (errorCode) => errorCode === responseCode
    );
    const isCVVError = ["881", "882", 883].some(
      (errorCode) => errorCode === responseCode
    );
    if (isCreditCardNumberError) {
      setVantivError(
        "Please check and re-enter your credit card number and try again."
      );
    } else if (isCVVError) {
      setVantivError(
        "Please check and re-enter your card validation number and try again."
      );
    } else if (responseCode === "884") {
      setVantivError("884");
    } else {
      setVantivError(
        "We are experiencing technical difficulties. Please try again"
      );
    }
  };
  //vantiv func end
  const editcardOnchange = (e: any) => {
    // debugger;
    let TxtValue = e.value;
    let TxtName = e.name;
    let fieldtype;

    if (TxtName === "city") {
      fieldtype = /^[\sa-zA-Z]*$/;
    }
    if (TxtName === "name") {
      //eslint-disable-next-line
      fieldtype = /^[a-zA-Z ]*$/;
    }
    if (TxtName === "zip_code") {
      fieldtype = new RegExp("^[0-9 ]*$");
    }
    if (TxtName === "address_line_1") {
      setEditCard({ ...EditCard, address_1: TxtValue });
      if (EditCard.address_1 !== "") {
        setaddressval1(false);
      }
    } else if (TxtName === "address_line2") {
      setEditCard({ ...EditCard, address_2: TxtValue });
    } else if (TxtName === "State") {
      setEditCard({ ...EditCard, state: TxtValue });
      if (EditCard.state !== "0") {
        setstateVal(false);
      }
    }
    const temp = fieldtype.test(TxtValue);
    if (temp) {
      if (TxtName === "zip_code") {
        setEditCard({ ...EditCard, zip: TxtValue });
        if (EditCard.zip !== "") {
          setZipVal(false);
        }
      } else if (TxtName === "name") {
        let str = TxtValue;
        str = str.replace(/^\s+/, "");
        setEditCard({ ...EditCard, Cardname: str });
        if (EditCard.Cardname !== "") {
          setnameVal(false);
        }
      } else if (TxtName === "city") {
        setEditCard({ ...EditCard, city: TxtValue });
        if (EditCard.city !== "") {
          setcityVal(false);
        }
      }
    }
  };

  const stateOnchange = (e: any) => {
    let stateVal = e.value;
    setEditCard({ ...EditCard, state: stateVal });
    setstateVal(false);
  };

  const newcard = () => {
    // setCardrequire({ ...Cardrequire, newCard: true });

    setshowChargeCard(false);
    setshowAddCard(true);
    setPopupfields(newcardinformation);
    setdefaultVal("0");
    setChargeCardFlag(true); //AddNew Card pre-fill
    window.scrollTo(0, document.body.scrollHeight);
  };

  const chargeCard = () => {
    // setCardrequire({ ...Cardrequire, newCard: true });
    // setcardtxtDisable(true);
    setshowChargeCard(true);
    setshowAddCard(false);
    setaddNewCard(true);
    setpaymentOriginDisable(true);
    setPopupfields(newcardinformation);
    setdefaultVal("0");
    setChargeCardFlag(true); //ChargeCard Details pre-fill
    window.scrollTo(0, document.body.scrollHeight);
  };

  useEffect(() => {
    if (showChargeCard == true || showAddCard == true) {
      window.scrollTo(0, document.body.scrollHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChargeCard, showAddCard]);

  const edit = (index: any) => {
    setCardrequire({ ...Cardrequire, editcrd: true });
    seteditcardindex(index);
    setEditCard({
      ...EditCard,
      address_1:
        getCardService_state.cardResp[index].billingAddressDetails.addressLine1,
      address_2:
        getCardService_state.cardResp[index].billingAddressDetails.addressLine2,
      Cardname: getCardService_state.cardResp[index].cardHolderName,
      city: getCardService_state.cardResp[index].billingAddressDetails.city,
      zip: getCardService_state.cardResp[index].billingAddressDetails.zip,
      state: getCardService_state.cardResp[index].billingAddressDetails.state,
      cardExpDate: getCardService_state.cardResp[index].cardExpiration,
    });
  };

  //syed azim code for payment history and club payment

  const paymentHistoryClose = (res: any) => {
    setPaymentHistory(false);
    setClubPaymentHistory(true);
  };

  const clubpaymentHistoryClose = (res: any) => {
    setClubPaymentHistory(false);
    setPaymentHistory(true);
  };

  //Close functions of the all card module popup defines here
  const close = () => {
    setCardrequire({
      ...Cardrequire,
      swipcard: false,
      newCard: false,
      editcrd: false,
      delt: false,
      tran: false,
      txt2pay: false,
      drpdown: false,
      APDeletePopup: false,
    });

    // setActivateClub(false);
    // setDeActivateClub(false);
    setTerminateLDW(false);
    setActiveLDW(false);
    setActivepolicy(false);
    setDeActivepolicy(false);
    setshowAddCard(false);
    setnameVal(false);
    setaddressval1(false);
    setcityVal(false);
    setstateVal(false);
    setZipVal(false);
  };

  const AddCardClose = () => {
    //ChargeCard details pre-fill flag
    setChargeCardFlag(false);
    setshowAddCard(false);
    setdefaultVal("0");
    setPopupfields(newcardinformation);
    setAddress_line1_validation(false);
    setName_validation(false);
    setVantivIframe(undefined);
    setState_validation(false);
    setZip_code_validation(false);
    setCityValidation(false);
  };
  const ChargeCardClose = () => {
    // setcardtxtDisable(false);
    //ChargeCard details pre-fill flag
    setChargeCardFlag(false);
    setshowChargeCard(false);
    setaddNewCard(false);
    let resTotalAmount = 0.0;
    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    setremainingamountdue(
      (Number(RemaniningAmtStatic) - Number(resTotalAmount)).toFixed(2)
    );
    setchargeCardAmt("0.00");
    setVantivIframe(undefined);
    setCardGridData(CardGrid);
    setpaymentOriginDisable(false);
    setcvvTextValue("");
    setswipeFlag(false);
  };
  const del = () => {
    setCardrequire({ ...Cardrequire, delt: true, editcrd: false });
    seteditcardnumberbind(
      getCardService_state.cardResp[editcardindex].cardLastFour
    );
  };

  //this useeffect id for autocheck
  useEffect(() => {
    if (acceptPaymentParam.length > 0) {
      SetAcceptPayments(acceptPaymentParam);
    }
    if (
      AcceptclubPaymentParam !== undefined &&
      AcceptclubPaymentParam !== null
    ) {
      setAcceptClub(AcceptclubPaymentParam);
    } else {
      setAcceptClub(undefined);
    }

    if (ReceiptCategoriesRequestParam.length > 0) {
      setReceiptCategory(ReceiptCategoriesRequestParam);
    }
  });

  const YesforDelete = async () => {
    let cardReset = false;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (tendertypeglobalarray[i].tendertype.includes("Card ending")) {
        cardReset = true;
        break;
      }
    }
    if (cardReset) {
      let objRemainAmount = 0;
      let chargeCardAmount = 0;
      let swipeCardAmount = 0;
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Ending with")
        ) {
          chargeCardAmount =
            chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Swipe ending")
        ) {
          swipeCardAmount =
            swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }
      // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
      let cardamount = Number(
        multipleCard.length == 0
          ? 0.0
          : multipleCard
              .map((bill) => bill.Amount)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
      );
      cardamount =
        Number(cardamount) + Number(chargeCardAmount) + Number(swipeCardAmount);
      let totalOtherTenderAmt =
        Number(addcash) + Number(totalamountcheck) + Number(cardamount);
      objRemainAmount =
        Number(Number(RemaniningAmtStatic).toFixed(2)) -
        Number(Number(totalOtherTenderAmt).toFixed(2));
      setCardrequire({ ...Cardrequire, chkcvv: "0.00" });
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (tendertypeglobalarray[i].tendertype.includes("Card ending")) {
          tendertypeglobalarray.splice(i, 1);
          break;
        }
      }
      settotalduetendertype(tendertypeglobalarray);
      let CardCheckObj: CardChk = {
        CardLastFour: String(cardGridData.CardLastFour),
        Amount: "0.00",
        BillingAddress: cardGridData.BillingAddress,
        cardExpiration: undefined,
        customerPaymentMethodId: undefined,
      };
      setCardGridData(CardCheckObj);
      if (objRemainAmount > RemaniningAmtStatic) {
        setremainingamountdue(RemaniningAmtStatic);
        setAcceptpaymentbuttonenable(true);
      } else if (objRemainAmount < 0) {
        let newRemainAmt =
          Number(Number(RemaniningAmtStatic).toFixed(2)) -
          (Number(addcash) + Number(totalamountcheck));
        if (newRemainAmt > 0) {
          setchange(0.0);
          setremainingamountdue(newRemainAmt);
          setAcceptpaymentbuttonenable(true);
        } else if (newRemainAmt == 0) {
          setchange(0.0);
          setremainingamountdue(0.0);
          setAcceptpaymentbuttonenable(false);
        } else if (newRemainAmt < 0) {
          setchange(-1 * newRemainAmt);
          setremainingamountdue(0.0);
          setAcceptpaymentbuttonenable(false);
        }
      } else if (objRemainAmount == 0) {
        // setchange(0.00);
        // setremainingamountdue(0.00);
        let isChecked = 0;
        for (let i = 0; i < document.getElementsByName("grid").length; i++) {
          if (
            (document.getElementsByName("grid")[i] as HTMLInputElement)
              .checked == true
          ) {
            isChecked = 1;
          }
        }
        if (isChecked == 1) {
          let newRemainAmt =
            Number(Number(RemaniningAmtStatic).toFixed(2)) -
            (Number(addcash) + Number(totalamountcheck));
          if (newRemainAmt > 0) {
            setchange(0.0);
            setremainingamountdue(newRemainAmt);
            setAcceptpaymentbuttonenable(true);
          } else if (newRemainAmt == 0) {
            setchange(0.0);
            setremainingamountdue(0.0);
            setAcceptpaymentbuttonenable(false);
          } else if (newRemainAmt < 0) {
            setchange(-1 * newRemainAmt);
            setremainingamountdue(0.0);
            setAcceptpaymentbuttonenable(false);
          }
        } else {
          setchange(0.0);
          setremainingamountdue(0.0);
          setAcceptpaymentbuttonenable(true);
        }
      } else {
        let newRemainAmt =
          Number(Number(RemaniningAmtStatic).toFixed(2)) -
          (Number(addcash) + Number(totalamountcheck));
        if (newRemainAmt > 0) {
          setchange(0.0);
          setremainingamountdue(newRemainAmt);
        } else if (newRemainAmt == 0) {
          setchange(0.0);
          setremainingamountdue(0.0);
          setAcceptpaymentbuttonenable(false);
        } else if (newRemainAmt < 0) {
          setchange(-1 * newRemainAmt);
          setremainingamountdue(0.0);
          setAcceptpaymentbuttonenable(false);
        }
      }
      //const FloatInputTxttofixed = cardGridData.Amount;
      // const tendertypedetails = {
      //   tendertype: cardlastFour,
      //   Amount: FloatInputTxttofixed,
      // };

      //setremainingamountdue(objRemainAmount);
      setcvvTextValue("");
      setCvv("");
    }
    if (getCardService_state.cardResp[editcardindex].apFlag === 1) {
      setCardrequire({
        ...Cardrequire,
        delt: false,
        editcrd: false,
        APDeletePopup: true,
      });
    } else {
      setCardrequire({ ...Cardrequire, delt: false, editcrd: false });
      setdeleteCardPopUp(true)
      const DeleteResp = await Deletecustomercard(
        getCardService_state.cardResp[editcardindex].customerPaymentMethodId,
        customerId
      );
      if (DeleteResp?.status === 200 && DeleteResp!= undefined && DeleteResp?.data?.customerId === String(customerId)) {
        getCardAfterUpdate();
        setdeleteCardPopUp(false)
        setdeleteCardSuccess(true)
      } else{
        setdeleteCardPopUp(false)
        setdeleteCardFail(true)
      }
    }
  };

  const Enroll_AP_Card = async () => {
    setCardrequire({ ...Cardrequire, APDeletePopup: false });
 
    const DeleteResp = await Deletecustomercard(
      getCardService_state.cardResp[editcardindex].customerPaymentMethodId,
      customerId
    );
    if (DeleteResp.customerId === String(customerId)) {
      getCardAfterUpdate();
    }
  };

  const Amtonblurclick = (e: any) => {
    let regexValidate: string = String(e.target.value).replace(",", "");
    let onlyinputtext: string = String(regexValidate).replace(".", "");
    let regexExp = new RegExp("[0-9]");
    if (!onlyinputtext.match(regexExp) && onlyinputtext != "") {
      setswipeDeviceValue(swipeDeviceValue);
    } else {
      let InputTxt = e.target.value;
      let txtArr = InputTxt.split("");
      if (txtArr.includes(",") === true) {
        txtArr = txtArr.filter((element: any) => element !== ",");
        let Tempvar = "";
        for (let i = 0; i <= txtArr.length - 1; i++) {
          Tempvar = Tempvar + txtArr[i];
        }
        const FloatVar = parseFloat(Tempvar);
        const DecimalVal = fixedChange(String(FloatVar));
        setswipeDeviceValue(DecimalVal);
      } else if (InputTxt === "" || InputTxt === ".") {
        setswipeDeviceValue("0.00");
      } else {
        InputTxt = String(InputTxt).replace(",", "");
        const DecimalInputTxt = parseFloat(InputTxt);
        const FloatTxt = fixedChange(String(DecimalInputTxt));
        setswipeDeviceValue(FloatTxt);
      }
    }
  };

  const totalamount = (value: any) => {
    // let regexValidate: string = String(value).replace(",", "");
    // let onlyinputtext: string = String(regexValidate).replace(".", "");
    // let regexExp = new RegExp("[0-9]");
    // if (!onlyinputtext.match(regexExp) && onlyinputtext != "") {
    //   setswipeDeviceValue(swipeDeviceValue);
    // }
    // else {
    value = value.replace(",", "");
    if (validateCashInput(value)) {
      setswipeDeviceValue(String(value));

      setValidator({ ...Validator, SwipeAmtValidationtxtbox: false });
      if (
        value === "0" ||
        value === undefined ||
        value === null ||
        value === ""
      ) {
        setCardrequire({ ...Cardrequire, Proceedbtn: true });
      } else {
        setCardrequire({ ...Cardrequire, Proceedbtn: false });
      }
    }
    //}
  };

  const totalChargeAmount = (value: any) => {
    // let regexValidate: string = String(value).replace(",", "");
    // let onlyinputtext: string = String(regexValidate).replace(".", "");
    // let regexExp = new RegExp("[0-9]");
    // if (!onlyinputtext.match(regexExp) && onlyinputtext != "") {
    //   setswipeDeviceValue(swipeDeviceValue);
    // }
    // else {
    const cardAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    value = value.replace(",", "");
    if (validateCashInput(value)) {
      // let CardCheckObj: CardChk = {
      //   CardLastFour: undefined,
      //   Amount: chargeCardAmt,
      //   BillingAddress: undefined,
      //   cardExpiration: undefined,
      //   chargeCard: true,
      //   customerPaymentMethodId: undefined
      // };
      // setCardGridData(CardCheckObj);
      const objRemainAmt =
        RemaniningAmtStatic - parseFloat(String(value)) - Number(cardAmount);
      let isChecked = 0;
      for (let i = 0; i < document.getElementsByName("grid").length; i++) {
        setValidator({ ...Validator, chargeCardValidation: false });
        if (
          (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
          true
        ) {
          isChecked = 1;
        }
      }
      if (Number(Number(objRemainAmt).toFixed(2)) == 0 && isChecked == 1) {
        //setremainingamountdue(Number(objRemainAmt.toFixed(2)));
        setchargeCardContinue(false); //change this
      } else if (Number(Number(objRemainAmt).toFixed(2)) > 0) {
        setValidator({ ...Validator, chargeCardValidation: false });
        if (Validator.chargeCardValidation) {
          setchargeCardContinue(true);
        } else {
          setchargeCardContinue(false);
        }

        //setremainingamountdue(Number(objRemainAmt.toFixed(2))); //change this
      }
      if (Number(Number(objRemainAmt).toFixed(2)) < 0) {
        setValidator({ ...Validator, chargeCardValidation: true });
        setchargeCardContinue(true); //change this
      }
      setchargeCardAmt(Number(value).toFixed(2));
      chargeCardValue = Number(Number(value).toFixed(2));
      //setchange("0.00");
    }
    //}
  };

  const validateCashInput = (inputText: string) => {
    let cashvalue = inputText.split(".");
    let validData = false;
    if (
      cashvalue.length === 2 &&
      cashvalue[0].length < 6 &&
      cashvalue[1].length <= 2
    ) {
      validData = true;
    } else if (cashvalue.length === 1 && cashvalue[0].length < 6) {
      validData = true;
    }

    return validData;
  };
  const Spinner = async (e: any) => {
    //let dummy;
    if (
      Number(Number(swipeDeviceValue).toFixed(2)) >
      Number(Number(remainingamountdue).toFixed(2))
    ) {
      setValidator({ ...Validator, SwipeAmtValidationtxtbox: true });
    } else {
      const currentDate = new Date()
        .toJSON()
        .replace("T", "-")
        .replace("Z", "");
      const PaymentInfo: PaymentInfo = {
        TerminalID: String(TerminalId),
        Amount: String(swipeDeviceValue),
        PaymentDate:
          currentDate.split(".")[0] + ":000" + currentDate.split(".")[1],
        PostalCode: String(getpayment?.paymentInfo.customerInfo[0].zip),
        PaymentType: "2",
        TransactionType: "2",
        RegisterNumber: registerNumber
      };
      const SwipeCardRequestDTO = {
        PaymentInfo: PaymentInfo,
        storeNumber: window.sessionStorage.getItem("storeNumber"),
        userId:String(empId),
        clientId:"RACPAD",
        customerId: Number(customerId),
        name: customerName,
        CloudSwipeCustomerCard:
          configFlags?.isCloudSwipeCustomerCard == 1 ? "Y" : "N",
      };
      setCardrequire({ ...Cardrequire, swipcard: false });
      setload(true);
      swiperes = await Swipecustomercard(SwipeCardRequestDTO);
      setload(false);
        if (
          swiperes === undefined ||
          (swiperes.status !== 200 && swiperes !== 403)
        ) {
          settxtChange(false);
          setTranFail(true);
          setswipeamt("0.00");
        } else if (swiperes.status === 200) {
          let swipeStoredAmt = 0.0;
          for (let i = 0; i <= cardAmt.length - 1; i++) {
            swipeStoredAmt = swipeStoredAmt + cardAmt[i].amount;
          }
          swipeAmt = Number(swipeStoredAmt) + Number(swipeDeviceValue);
          let swipeArray = {
            amount: Number(swipeDeviceValue),
          };
          swipeCardAmt.push(swipeArray);
          setCardAmt(swipeCardAmt);
          setswipeamt(String(Number(swipeAmt)));
          let lastfourNum =
            "Card Swipe ending - " + String(swiperes.data.cardLastFour);
          setcardtxtDisable(true);

          setcardAuthNo(swiperes.data.cardAuthNumber);
          window.sessionStorage.setItem(
            "cardAuthNumber",
            swiperes.data.cardAuthNumber
          );
          setcardLastFour(swiperes.data.cardLastFour);
          setCardrequire({ ...Cardrequire, swipcard: false, tran: true }); //transaction completed

          //multi-swipe card changes
          let cardDetail = {
            cardAmount: Number(swipeDeviceValue),
            cardAuthNumber: swiperes.data.cardAuthNumber,
            cardLastFour: swiperes.data.cardLastFour,
            cardToken: swiperes.data.cardToken,
            cardSequence: swiperes.data.cardRefNumber,
            cardType:swiperes?.data?.cardType,
            cardTransactionType: "SWP",
          };
          swipeCardDetails.push(cardDetail);
          setcardDetails(swipeCardDetails);
          if (
            String(swipeDeviceValue) !== "" &&
            parseFloat(String(swipeDeviceValue)) !== 0.0
          ) {
            const FloatInputTxttofixed = fixedChange(swipeDeviceValue);
            const tendertypedetails = {
              tendertype: lastfourNum,
              Amount: FloatInputTxttofixed,
              paymentMethodID: String(swiperes.data.cardToken),
            };

            tendertypeglobalarray.push(tendertypedetails);
            settotalduetendertype(tendertypeglobalarray);
          } else {
            for (let i = 0; i < tendertypeglobalarray.length; i++) {
              if (
                tendertypeglobalarray[i].paymentMethodID ===
                String(swiperes.data.cardToken)
              ) {
                tendertypeglobalarray.splice(i, 1);
                break;
              }
            }
            settotalduetendertype(tendertypeglobalarray);
          }
          const resTotalAmount =
            tendertypeglobalarray.length == 0
              ? 0.0
              : tendertypeglobalarray
                  .map((bill) => bill.Amount)
                  .reduce((acc, bill) =>
                    (Number(bill) + Number(acc)).toFixed(2)
                  );
          if (
            Number(Number(swipeDeviceValue).toFixed(2)) ==
            Number(Number(remainingamountdue).toFixed(2))
          ) {
            const objRemainAmt =
              Number(totaldue) -
              Number(resTotalAmount) -
              Number(availableCreds);
            // RemaniningAmtStatic -
            // (parseFloat(String(swipeAmt)) +
            //   parseFloat(addcash) +
            //   totalamountcheck);
         
            setremainingamountdue(Number(objRemainAmt.toFixed(2)));
            // setRemaniningAmtStatic(Number(objRemainAmt.toFixed(2)));
            setAcceptpaymentbuttonenable(false);
          } else if (
            Number(Number(swipeDeviceValue).toFixed(2)) <
            Number(Number(remainingamountdue).toFixed(2))
          ) {
            const objRemainAmt =
              Number(totaldue) -
              Number(resTotalAmount) -
              Number(availableCreds);
            // RemaniningAmtStatic -
            // (parseFloat(String(swipeAmt)) +
            //   parseFloat(addcash) +
            //   totalamountcheck);
            setremainingamountdue(Number(objRemainAmt.toFixed(2)));
            // setRemaniningAmtStatic(Number(objRemainAmt.toFixed(2)));
          }

          let CardCheckObj: CardChk = {
            CardLastFour: undefined,
            Amount: Number(String(swipeAmt)).toFixed(2),
            BillingAddress: undefined,
            cardExpiration: undefined,
          };
          setswipeFlag(true);

          // CardCheckObj.BillingAddress != undefined ? CardCheckObj.BillingAddress.cardToken = cardToken : cardToken;
          setCardGridData(CardCheckObj);
          FormAcceptPaymentData(0, 0);
          // multipleAdjust()
          // editAdjustRes({resArray:checkedAgr})
          let traceObj={
            iD:Number(userTraceData?.length)+1,
            type:"Swipe Card"
          }
          setUserTraceData([...userTraceData,traceObj])
        } else if (swiperes.status === 403) {
          setTranFail(true);
          settxtChange(true);
          setswipeamt("0.00");
        }
    }
  };

  const okClickUnavailable = () => {
    setTranFail(false);
    setCardrequire({ ...Cardrequire, swipcard: false });
  };

  const acceptApplyRetention = () => {
    if (retentionType == "FREETIME") {
      setOpenFreeTimeRetention(true);
    } else if (retentionType == "EPO") {
      setShowAcceptEpoPopup(true);
    }
    setOpenRetentionAlert(false);
  };

  const renderRetentionAlert = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <Typography className={classes.racErrorFunction}>
            {retentionAlertMessage}
          </Typography>
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={acceptApplyRetention}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const renderOtherIncomeModal = () => {
    return (
      <Grid item>
        <Typography
          className={`${classes.floatLeft} ${classes.modalTitle} ${classes.bold} ${classes.ps3}`}
        >
          Sales
        </Typography>
        <Grid container spacing={2} className={classes.px2}>
          <Grid item md={4} className={classes.mb2}>
            <RACButton
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
              color="primary"
              variant="contained"
              disabled={
                Number(configFlags?.isRentalItemSaleFlag) == 1 ? false : true
              }
              onClick={() => {
                setOpenOtherIncome(false);
                history.push({
                  pathname: "/payment1/rentalitemsale",
                  state: {
                    Tax: 0.0825,
                    customerID:
                      customerdata.data?.GetCustomer?.value?.customerId,
                    customerName: `${customerdata.data?.GetCustomer?.value?.firstName} ${customerdata.data?.GetCustomer?.value?.lastName}`,
                    editNo: 0,
                  },
                });
              }}
            >
              {"Rental Item Sale"}
            </RACButton>
          </Grid>
          <Grid item md={6} className={classes.mb2}>
            <RACButton
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
              color="primary"
              variant="contained"
              disabled={Number(configFlags?.isCashSaleFeatureFlag) == 1 || Number(configFlags?.isAcimaRecoveryAllowed) == 1 || Number(configFlags?.isOtherSaleAllowed) == 1 || Number(configFlags?.isXboxGiftCardAllowed) == 1 || Number(configFlags?.isXboxMembershipAllowed) == 1 ? false : true}
              onClick={() => {
                setOpenOtherIncome(false);
                setnonInventoryPopUp(true);
              }}
            >
              {"Non-Inventory Sale"}
            </RACButton>
          </Grid>
        </Grid>
        <Typography
          className={`${classes.floatLeft} ${classes.modalTitle} ${classes.bold} ${classes.ps3}`}
        >
          Payments
        </Typography>
        <Grid container spacing={2} className={classes.px2}>
          <Grid item md={3} className={`${classes.mb2} ${classes.pd6}`}>
            <RACButton
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
              color="primary"
              variant="contained"
              disabled={
                Number(configFlags?.isBackRentFlag) == 1 &&
                Number(backRentInfo.amount) !== 0
                  ? false
                  : true
              }
              onClick={() => {
                setOpenOtherIncome(false);
                setBackRentPopUp(true);
              }}
            >
              {"Back Rent"}
            </RACButton>
          </Grid>
          <Grid item md={7} className={`${classes.mb2} ${classes.pd6}`}>
            <RACButton
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
              color="primary"
              variant="contained"
              disabled={
                Number(configFlags?.isChargeOffSkipStolen) == 1 &&
                chargeoffInfo?.length !== 0
                  ? false
                  : true
              }
              onClick={() => {
                setOpenOtherIncome(false);
                setchargeoffPopupFlag(true);
                setEditChargeoffFlag(false);
              }}
            >
              {"Charge-Off Skip/Stolen Payment"}
            </RACButton>
          </Grid>
          <Grid item md={6} className={`${classes.mb2} ${classes.pd6}`}>
            <RACButton
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
              color="primary"
              variant="contained"
              disabled={Number(configFlags.allowRefundSecurityDeposit) == 1 && checkedAgr.length > 0 && masterRSDData.length > 1 ? false : true}
              onClick={() => {
                setOpenRDS(true);
                setOpenOtherIncome(false);
              }}
            >
              {"Refund Security Deposit"}
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const unAvailable = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          {txtChange === false ? (
            <Typography className={classes.racErrorFunction}>
              Swipe Transaction has Failed
            </Typography>
          ) : (
            <Typography className={classes.racErrorFunction}>
              Swipe Transaction has Timeout
            </Typography>
          )}
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={okClickUnavailable}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const spinnerpopup = () => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textcenter}>
          <ClipLoader color="blue" loading={load} size={40} />
          <Typography variant="h4" className={classes.mt3}>
            Transaction In Progress
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.textcenter}>
          <Typography variant="h6" className={classes.mt3}>
            Please Wait...
          </Typography>
        </Grid>
      </Grid>
    );
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const initialnewcardstate = (e: any) => {
    let fieldtype;
    if (e.name === "City") {
      //eslint-disable-next-line
      fieldtype = /^[\sa-zA-Z]*$/;
    }
    if (e.name === "name") {
      //eslint-disable-next-line
      fieldtype = /^[a-zA-Z ]*$/;
    } else if (e.name === "zip_code") {
      fieldtype = new RegExp("^[0-9 ]*$");
    }

    //if (fieldtype.test(e.value)) {
    let inputtxt = e.value;
    let FieldName = e.name;
    let temp = false;
    if (FieldName === "name" || FieldName === "City")
      temp = fieldtype.test(inputtxt);
    if (temp && FieldName === "name") {
      if (inputtxt !== "") {
        setName_validation(false);
      }
      let str = inputtxt;
      str = str.replace(/^\s+/, "");
      setPopupfields({ ...Popupfields, name: str });
      cardSaveDetail = Popupfields;
      window.sessionStorage.setItem("billName", String(inputtxt));
    } else if (FieldName === "state") {
      if (inputtxt === "0") {
        setState_validation(false);
      }
      setPopupfields({ ...Popupfields, state: inputtxt });
      cardSaveDetail = Popupfields;
      window.sessionStorage.setItem("billState", String(inputtxt));
    } else if (FieldName === "zip_code") {
      if (inputtxt !== "") {
        setZip_code_validation(false);
      }
      setPopupfields({ ...Popupfields, zip_code: inputtxt });
      window.sessionStorage.setItem("billZip", String(inputtxt));
      cardSaveDetail = Popupfields;
    } else if (temp && FieldName === "City") {
      if (inputtxt !== "") {
        setCityValidation(false);
      }
      let str = inputtxt;
      str = str.replace(/^\s+/, "");
      setPopupfields({ ...Popupfields, city: str });
      window.sessionStorage.setItem("billCity", String(inputtxt));
      cardSaveDetail = Popupfields;
    }

    if (e.value == "" && e.name == "name") {
      setPopupfields({ ...Popupfields, name: "" });
      window.sessionStorage.setItem("billName", "");
    } else if (e.value == "" && e.name == "City") {
      setPopupfields({ ...Popupfields, city: "" });
      window.sessionStorage.setItem("billCity", "");
    }

    if (FieldName === "address_line_1") {
      if (inputtxt !== "") {
        setAddress_line1_validation(false);
      }
      let str = inputtxt;
      str = str.replace(/^\s+/, "");
      setPopupfields({ ...Popupfields, address_line_1: str });
      cardSaveDetail = Popupfields;
      window.sessionStorage.setItem("billAdd1", String(inputtxt));
    } else if (FieldName === "address_line2") {
      window.sessionStorage.setItem("billAdd2", String(inputtxt));
      let str = inputtxt;
      str = str.replace(/^\s+/, "");
      setPopupfields({ ...Popupfields, address_line2: str });
      cardSaveDetail = Popupfields;
    }
  };

  const chkfunc = (
    index: any,
    value: any,
    name: any,
    lastFour: any,
    billingDetails: any,
    expireDate: any,
    cardToken: any,
    customerPaymentMethodId: any
  ) => {
    if (name === "chkAmt") {
      let CardCheckObj: CardChk = {
        CardLastFour: String(lastFour),
        Amount: value,
        BillingAddress: billingDetails,
        cardExpiration: expireDate,
        customerPaymentMethodId: customerPaymentMethodId,
      };

      CardCheckObj.BillingAddress != undefined
        ? (CardCheckObj.BillingAddress.cardToken = cardToken)
        : cardToken;

      multipleCard[index].Amount = value == undefined ? "0.00" : value;
      setCardGridData(CardCheckObj);
      let enablepayment = true;
      for (let i = 0; i < multipleCard.length; i++) {
        if (
          Number(multipleCard[i].Amount > 0) &&
          multipleCard[i].cvvEncrypt.length < 3
        ) {
          enablepayment = false;
        }
      }
      if (
        Number(remainingamountdue) <= 0 &&
        selectedCheckBoxCount != 0 &&
        CardErrorLbl == false &&
        enablepayment
      ) {
        setAcceptpaymentbuttonenable(false);
      }
    } else {
      if (name === "chkcvv") {
        setCardrequire({ ...Cardrequire, chkcvv: value });
      }
    }
  };

  const cardTextonblur = (e: any, index: any) => {
    // debugger;
    multipleCard[index].Amount = Number(multipleCard[index].Amount).toFixed(2);
  };
  let cardArray: any = [];
  const Amtonclickbtn2ndbox = (
    e: any,
    lastFour: any,
    billingDetails: any,
    index: any,
    expireDate: any,
    cardToken: any,
    cardType: any,
    customerPaymentMethodId: string
  ) => {
    // debugger;
    // if(isNaN(e.target.value)==false && (e.target.value).split(".")[1].split(/(?!$)/u).length==2){
    //   // if(){
    //     multipleCard[index].Amount= e.target.value.trim()==""?"0.00":e.target.value
    //   // }

    // }

    let lastfourNum = "Card ending - " + String(lastFour);
    let InputTxt = e.target.value;
    let txtArr = InputTxt.split("");
    let CashAmt =
      cashamountcheckbox !== undefined && cashamountcheckbox !== null
        ? cashamountcheckbox
        : 0;
    let objChqMOAmt = 0;
    if (adddataanother != undefined && adddataanother.length > 0) {
      objChqMOAmt = adddataanother.reduce(
        (sumVal, a) => sumVal + parseFloat(a.Amount_rac),
        0
      );
    }
    let enableAcc = true;
    let FloatVar: any;
    let DecimalVal: any;
    if (txtArr.includes(",") === true) {
      txtArr = txtArr.filter((element: any) => element !== ",");
      let Tempvar = "";
      for (let i = 0; i <= txtArr.length - 1; i++) {
        Tempvar = Tempvar + txtArr[i];
      }
      FloatVar = parseFloat(Tempvar);
      DecimalVal = fixedChange(FloatVar);
      setgettotamt(DecimalVal);
    } else if (
      InputTxt === "" ||
      InputTxt === "." ||
      InputTxt === "0" ||
      InputTxt === undefined ||
      InputTxt === null
    ) {
      setgettotamt("0.00");
    } else {
      FloatVar = parseFloat(InputTxt);
      DecimalVal = fixedChange(FloatVar);
      setgettotamt(DecimalVal);
    }

    const FloatInputTxttofixed = DecimalVal;
    const tendertypedetails = {
      tendertype: lastfourNum,
      Amount: FloatInputTxttofixed,
      paymentMethodID: String(customerPaymentMethodId),
    };
    //debugger;
    let chargeCardAmount = 0;
    let swipeCardAmount = 0;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Ending with")
      ) {
        chargeCardAmount =
          chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Swipe ending")
      ) {
        swipeCardAmount =
          swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
    let cardamount = multipleCard
      .map((bill) => bill.Amount)
      .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let sumOfOtherTenderAmount =
      Number(addcash) +
      Number(totalamountcheck) +
      Number(cardamount) +
      Number(availableCreds) +
      Number(chargeCardAmount) +
      Number(swipeCardAmount)-
      Number(donationAmountAdd) -
      Number(addSusTotalAmount) +
      Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount);
    let totalOtherTenderAmt =
      Number(addcash) +
      Number(totalamountcheck) +
      Number(chargeCardAmount) +
      Number(swipeCardAmount);
    let remAmtDueWithOutTenders =
      Number(Number(totaldue).toFixed(2)) -
      Number(sumOfOtherTenderAmount.toFixed(2));
    //let objRemainAmt = Number(Number(RemaniningAmtStatic).toFixed(2)) - (parseFloat(InputTxt) + parseFloat(CashAmt) + objChqMOAmt);
    let isChecked = 0;
    for (let i = 0; i < document.getElementsByName("grid").length; i++) {
      if (
        (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
        true
      ) {
        isChecked = 1;
      }
    }
    if (
      parseFloat(InputTxt) === 0 ||
      InputTxt === undefined ||
      InputTxt === null ||
      InputTxt === "" ||
      InputTxt === "NaN"
    ) {
      if (paymentOrigin !== "Pay By Phone") {
        setswipeDisable(false);
      }
      setremainingamountdue(Number(Number(remAmtDueWithOutTenders).toFixed(2)));
      // setchange(Number(resTotalAmount)-Number(totaldue)-Number(availableCreds))
      // );
      let amountDueWhenNoCard = Number(
        (Number(RemaniningAmtStatic) - Number(totalOtherTenderAmt)).toFixed(2)
      );
      if (
        amountDueWhenNoCard == 0 &&
        isChecked == 1 &&
        totalOtherTenderAmt > 0
      ) {
        setAcceptpaymentbuttonenable(false);
      } else {
        setAcceptpaymentbuttonenable(true);
      }
      enableAcc = false;
      setCardErrorLbl(false);
    } else if (remAmtDueWithOutTenders > 0) {
      enableAcc = false;
      setCardErrorLbl(false);
      setremainingamountdue(Number(Number(remAmtDueWithOutTenders).toFixed(2)));
      // setchange(Number(resTotalAmount)-Number(totaldue)-Number(availableCreds))
      if (Number(Number(remAmtDueWithOutTenders).toFixed(2)) > 0) {
        setAcceptpaymentbuttonenable(true);
      }
      // if (paymentOrigin !== "Pay By Phone") {
      //   setswipeDisable(true);
      // }
    } else if (remAmtDueWithOutTenders < 0) {
      enableAcc = true;
      setCardErrorLbl(true);
      setAcceptpaymentbuttonenable(true);
      // if (paymentOrigin !== "Pay By Phone") {
      //   setswipeDisable(true);
      // }
    } else if (remAmtDueWithOutTenders == 0 && isChecked == 1) {
      setremainingamountdue(Number(Number(remAmtDueWithOutTenders).toFixed(2)));

      if (cvvTextValue !== "" && cvvTextValue.length >= 3) {
        setAcceptpaymentbuttonenable(false);
      }
      enableAcc = false;
      setCardErrorLbl(false);
      // if (paymentOrigin !== "Pay By Phone") {
      //   setswipeDisable(true);
      // }
    }
    let cardDetail = {
      cardAmount: Number(DecimalVal),
      cardAuthNumber: "",
      cardLastFour: lastFour,
      cardType: cardType,
      cardToken: cardToken,
      cardTransactionType: "PAYTOK",
    };

    // debugger;

    if (
      e.target.value !== "" &&
      parseFloat(e.target.value) !== 0.0 &&
      enableAcc != true
    ) {
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].paymentMethodID ===
          String(customerPaymentMethodId)
        ) {
          tendertypeglobalarray.splice(i, 1);
          cardArray.splice(i, 1);
          break;
        }
      }
      // cardArray.push(cardDetail);
      tendertypeglobalarray.push(tendertypedetails);
      settotalduetendertype(tendertypeglobalarray);
      // setcardDetails(cardArray);
    } else {
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].paymentMethodID ===
          String(customerPaymentMethodId)
        ) {
          tendertypeglobalarray.splice(i, 1);
          cardArray.splice(i, 1);
          break;
        }
      }
      settotalduetendertype(tendertypeglobalarray);
      // setcardDetails(cardArray);
    }
    // let resTotalAmount = 0.0;
    let resTotalAmount:any =
    tendertypeglobalarray.length == 0
        ? 0.0
        : tendertypeglobalarray
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    // resTotalAmount = resTotalAmount + Number(chargeCardAmt);

    let CardCheckObj: CardChk = {
      CardLastFour: String(lastFour),
      Amount: DecimalVal,
      BillingAddress: billingDetails,
      cardExpiration: expireDate,
      customerPaymentMethodId: customerPaymentMethodId,
    };
    CardCheckObj.BillingAddress != undefined
      ? (CardCheckObj.BillingAddress.cardToken = cardToken)
      : cardToken;
    setCardGridData(CardCheckObj);

    setgettotamt("0.00");
    previousAmt = Number(DecimalVal);
    FormAcceptPaymentData(0, 0);
    let enablepayment = true;
    let cvvCount = 0;
    for (let i = 0; i < multipleCard.length; i++) {
      if (
        Number(multipleCard[i].Amount > 0) &&
        multipleCard[i].cvvEncrypt.length < 3
      ) {
        enablepayment = false;
        // setcvvTextValue("")
        cvvCount++;
      }
    }
    if (cvvCount > 0) {
      setcvvTextValue("");
    } else {
      setcvvTextValue("***");
    }
    if (
      Number(remAmtDueWithOutTenders) <= 0 &&
      selectedCheckBoxCount != 0 &&
      enableAcc == false &&
      enablepayment &&
      Number(chargeCardAmt) == 0
    ) {
      setAcceptpaymentbuttonenable(false);
    } else {
      setAcceptpaymentbuttonenable(true);
    }
    cardDetail;
    
    if((Number(totaldue)+Number(addSusTotalAmount)-Number(usedSusTotalAmount)-Number(resTotalAmount)-Number(availableCreds) - Number(totalSurrenderAmount) + Number(totalDepositAmount)+Number(donationAmountAdd))<0){
      setchange(-1*((Number(totaldue)+Number(addSusTotalAmount)-Number(usedSusTotalAmount)-Number(resTotalAmount)-Number(availableCreds)- Number(totalSurrenderAmount) + Number(totalDepositAmount)+Number(donationAmountAdd))))
      // setremainingamountdue(0.00)
    }else{
      setchange(0.00)
      // setremainingamountdue((Number(totaldue)+Number(addSusTotalAmount)-Number(usedSusTotalAmount)-Number(resTotalAmount)-Number(availableCreds)))
    }
    // setchange(0.0);
    cardTextonblur(e, index);

    // setTenderUseEffectCalculation(true)

    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:"Card Amount OnBlur"
    }
    setUserTraceData([...userTraceData,traceObj])
  };

  //text2pay function defines here
  const txt2paybtn = () => {
    if (paygridvalues[0].Phonenumber == "") {
      setphoneNumberRequired(true);
    } else {
      if(configFlags.isTxt2PayCardOnFile==1){
        setCardrequire({ ...Cardrequire, txt2payCardOnFile: true })
      }
      else{
      setCardrequire({ ...Cardrequire, txt2pay: true });
    } 
 }
  };
  let transactionDate = new Date();

  let amount: any =
    acceptPayment?.CardInfo == undefined || acceptPayment?.CardInfo == null
      ? 0
      : acceptPayment?.CardInfo.Amount;
  let cardFlag = "0";
  if (amount > 0) {
    cardFlag = "1";
  }

  const yesforTxt2pay = async () => {
    setCardrequire({ ...Cardrequire, txt2pay: false,txt2payCardOnFile: false });
    if (PaymentAmt >= epoAmt) {
      SacAmt = PaymentAmt;
    }
    let count;
    for (let i = 0; i < document.getElementsByName("grid").length; i++) {
      if (
        (document.getElementsByName("grid")[i] as HTMLInputElement).checked ===
        true
      ) {
        selectedGrid = i;
        count++;
      }
    }
    let transferSuspense: any = [];
    for (let i = 0; i < paygridvalues?.length; i++) {
      if (
        Number(paygridvalues[i].SuspenseAmount) !=
        Number(paygridvalues[i].transferOldSuspense)
      ) {
        transferSuspense.push({
          currentBalance: Number(paygridvalues[i].transferOldSuspense).toFixed(
            2
          ),
          newSuspenseBalance: Number(paygridvalues[i].SuspenseAmount).toFixed(
            2
          ),
          agreementId: String(paygridvalues[i].Agreementid),
          isTransferToCoa: paygridvalues[i].isCOATransfered,
        });
      }
    }
    let webleadAmount: any = [];
    let creditOnAccount: any = [];
    let IPSuspense: any = [];

    if (availableCredsremaining.length > 0) {
      webleadAmount = availableCredsremaining.filter(
        (x) => x.balanceType === "WEBLEAD"
      );
      creditOnAccount = availableCredsremaining.filter(
        (x) => x.balanceType === "COA"
      );
      IPSuspense = availableCredsremaining.filter(
        (x) => x.balanceType === "IP Suspence"
      );
    }

    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    // let totalDueAmount = Number((Number(totaldue)+Number(addSusTotalAmount)+Number(usedSusTotalAmount)).toFixed(2))
    const postPaymentTextTopay = await invokeAcceptPayAPISingleStore(selectedTxt2PayCard?.length>0 && configFlags?.isTxt2PayCardOnFile == 1?true:false);
    const txt2payReqparam: any = {
      transferSuspense: transferSuspense,
      storeMerchantId: storeMerchantId,
      optimizedPayload:
        Number(configFlags.optimizedPayment) == 1
          ? { postPayment: postPaymentTextTopay }
          : undefined,
      miscellaneousArray: [],
      recieptCategoryReqDetails: ReceiptCategory,
      storeNumber: String(window.sessionStorage.getItem("storeNumber")),
      customerId: String(customerId),
      storeCoWorkerId: String(empId),
      carryAmount: Number(carryRentAmount),
      taxRate: Number(paygridvalues[0].taxRate),
      paymentOriginName: "Store",
      totalAmountCollected: Number(
        (Number(totaldue) + Number(carryRentAmount) < 0
          ? 0
          : Number(totaldue) + Number(carryRentAmount)
        ).toFixed(2)
      ),
      totalTaxCollected: Number(Number(totaltax).toFixed(2)),
      paymentId: "",
      cardAmount: Number(Number(remainingamountdue).toFixed(2)),
      cashAmount: 0,
      moneyOrderAmount: 0,
      checkAmount: 0,
      phoneNumber: String(getpayment?.paymentInfo.customerInfo[0].phoneNumber),
      transactionDate: String(transactionDate),
      customerZip:
        getpayment?.paymentInfo.customerInfo[0].zip +
        "-" +
        getpayment?.paymentInfo.customerInfo[0].plus4,
      customerState: String(getpayment?.paymentInfo.customerInfo[0].state),
      customerDOB: String(getpayment?.paymentInfo.customerInfo[0].dob),
      //paymentAmount: Number(Number(totalDueamt).toFixed(2)),
      cardDetails: [],
      documentDetails: [],
      paymentAmount: Number(
        (Number(totaldue) +
          Number(donationAmountAdd)+
          Number(addSusTotalAmount) -
          Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount) <
        0
          ? 0
          : Number(totaldue) +
            Number(donationAmountAdd)+
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
        ).toFixed(2)
      ),
      cardFlag: cardFlag,
      emailAddress: String(
        getpayment?.paymentInfo.customerInfo[0].emailAddress
      ),
      originFee: "0.00",
      totalAmountDue: Number(
        (Number(totaldue) +
          Number(donationAmountAdd)+
          Number(addSusTotalAmount) -
          Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount) <
        0
          ? 0
          : Number(totaldue) +
            Number(donationAmountAdd)+
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
        ).toFixed(2)
      ),
      wlDeposit:
        webleadAmount.length > 0
          ? Number(webleadAmount[0].accountBalance)
          : 0.0,
      itSuspence:
        IPSuspense.length > 0 ? Number(IPSuspense[0].accountBalance) : 0.0,
      suspense: 0,
      customerInfo: {
        FirstName: String(getpayment?.paymentInfo.customerInfo[0].firstName),
        LastName: String(getpayment?.paymentInfo.customerInfo[0].lastName),
      },
      creditOnAccount:
        creditOnAccount.length > 0
          ? Number(creditOnAccount[0].accountBalance)
          : 0.0,
      firstName: String(getpayment?.paymentInfo.customerInfo[0].firstName),
      lastName: String(getpayment?.paymentInfo.customerInfo[0].lastName),
      address1: String(getpayment?.paymentInfo.customerInfo[0].addressLine1),
      // change: Number( getcardResponse.CashAmount+
      //   getcardResponse.MOAmount+
      //   getcardResponse.ChequeAmount +
      //   0.00) - Number(Number(totalDueamt).toFixed(2)),
      change: 0.0,
      acceptPayment: acceptPaymentParam,
      stateProvince: String(getpayment?.paymentInfo.customerInfo[0].state),
      postalCode: String(getpayment?.paymentInfo.customerInfo[0].zip),
      city: String(getpayment?.paymentInfo.customerInfo[0].city),
      acceptClubPayment: AcceptClub,
    };

    if(selectedTxt2PayCard?.length>0 && configFlags?.isTxt2PayCardOnFile == 1){
      txt2payReqparam.customerPaymentInfo={
        address2:String(getpayment?.paymentInfo.customerInfo[0].addressLine2)?String(getpayment?.paymentInfo.customerInfo[0].addressLine2):"",
        cardType:selectedTxt2PayCard[0]?.cardRef,
        cardLastFour:selectedTxt2PayCard[0]?.cardLastFour,
        cardExpiration:selectedTxt2PayCard[0]?.cardExpiration,
        globalCustomerId:customerGCID==""?undefined:customerGCID,
        paymentMethodId:selectedTxt2PayCard[0]?.customerPaymentMethodId,
        storeId:Number(window.sessionStorage.getItem("storeNumber"))

      }
    }
    const Txt2Payresp = await Sendtxt2pay(txt2payReqparam);
    if(configFlags?.isTxt2PayCardOnFile == 1)
      {
        handleReset();
      }
    
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:"Text 2 Pay"
    }
    setUserTraceData([...userTraceData,traceObj])
  };

  const handleRadioChange =(index,item)=>{
    let radioBtnArr:any=txt2PayRadioArr.map((x:any,indexNew:any)=>{
      return index==indexNew ? !x : false
    });
    if(radioBtnArr[index]){
      setTxt2PayRadioArr([...radioBtnArr]);
      setSelectedTxt2PayCard([item]);
      console.log("item_Txt2pay",item);
    }
    

  }

  const handleReset =()=>{
    if (getCardService_state.cardResp !== undefined && getCardService_state?.cardResp?.length > 0) {
      const Card_FalseArray: any = new Array(getCardService_state.cardResp.length).fill(
        false
      );
      setTxt2PayRadioArr(Card_FalseArray);
      setSelectedTxt2PayCard([]);
  }}

  const invokeAcceptPayAPISingleStore = async (cardOnFileFlag) => {
    const entireAcceptPayAPIArray: any = [];
    let creditOnAccount: any = [];
    let IPSuspense: any = [];
    let webleadAmount: any = [];
    let COAAmount = 0;
    let overPaymentSuspenseAmount = 0;
    let changeNum = Number(Number(change).toFixed(2));
    let clubData: any = [];
    let nonClubData: any = [];
    const clubPayload: any = [];
    const transferSuspensePayload: any = [];
    const agreementPaymentPayload: any = [];
    const miscPaymentPayload: any = [];
    const tenderDetailsPayload: any = [];
    const cardDetailsPayload: any = [];
    const customerAccountPayload: any = [];
    const changeAmountPayload: any =
      radioBtn == "0" ? Number(Number(fixedChange(change)).toFixed(2)) : 0.0;

    clubData = checkedAgr.filter((el) => el.Agreementtype === "Club");
    nonClubData = checkedAgr.filter((el) => el.Agreementtype !== "Club");

    if (availableCredsremaining.length > 0) {
      webleadAmount = availableCredsremaining.filter(
        (x) => x.balanceType === "WEBLEAD"
      );
      creditOnAccount = availableCredsremaining.filter(
        (x) => x.balanceType === "COA"
      );
      IPSuspense = availableCredsremaining.filter(
        (x) => x.balanceType === "IP Suspence"
      );
    }

    if (
      radioBtn == "1" &&
      Number(change) > 0 &&
      suspensehide == false &&
      CreditRadio == true
    ) {
      COAAmount = radioBtn == "1" && changeNum >= 0 ? changeNum : 0;
    } else if (radioBtn == "1" && Number(change) > 0 && suspensehide == true) {
      overPaymentSuspenseAmount =
        radioBtn == "1" && changeNum >= 0 ? changeNum : 0;
    }

    if (
      creditOnAccount.length > 0 &&
      Number(creditOnAccount[0].accountBalance) > 0
    ) {
      customerAccountPayload.push({
        actionType: "D",
        accountType: "COA",
        storeNumber: String(window.sessionStorage.getItem("storeNumber")),
        amount: Number(creditOnAccount[0].accountBalance).toFixed(2),
      });
    }

    if (
      webleadAmount.length > 0 &&
      1 * Number(webleadAmount[0].accountBalance) > 0
    ) {
      customerAccountPayload.push({
        actionType: "D",
        accountType: "WEBLEAD",
        storeNumber: String(window.sessionStorage.getItem("storeNumber")),
        amount: (1 * Number(webleadAmount[0].accountBalance)).toFixed(2),
      });
    }

    if (
      IPSuspense.length > 0 &&
      1 * Number(IPSuspense[0].accountBalance) > 0
    ) {
      customerAccountPayload.push({
        actionType: "D",
        accountType: "SIPS",
        storeNumber: String(window.sessionStorage.getItem("storeNumber")),
        amount: (1 * Number(IPSuspense[0].accountBalance)).toFixed(2),
      });
    }

    if (Number(COAAmount) > 0) {
      customerAccountPayload.push({
        actionType: "C",
        accountType: "COA",
        storeNumber: String(window.sessionStorage.getItem("storeNumber")),
        amount: Number(COAAmount).toFixed(2),
      });
    }

    if (Number(overPaymentSuspenseAmount) > 0) {
      customerAccountPayload.push({
        actionType: "C",
        accountType: "SUSP",
        storeNumber: String(window.sessionStorage.getItem("storeNumber")),
        amount: Number(overPaymentSuspenseAmount).toFixed(2),
      });
    }

    if (Number(carryRentAmount) > 0) {
      customerAccountPayload.push({
        actionType: "C",
        accountType: "CR",
        storeNumber: String(window.sessionStorage.getItem("storeNumber")),
        amount: Number(carryRentAmount).toFixed(2),
      });
    }

    if (Number(acceptPayment?.CashAmount) > 0) {
      tenderDetailsPayload.push({
        type: "CASH",
        amount: acceptPayment?.CashAmount.toFixed(2),
      });
    }

    if (Number(changeAmountPayload) > 0) {
      tenderDetailsPayload.push({
        type: "CHG",
        amount: Number(changeAmountPayload).toFixed(2),
      });
    }
    if (clubData.length) {
      clubData.map((el) => {
        clubPayload.push({
          customerClubId: String(el.customerClubId),
          editedSchedule: el.editSchedule.length ? el.editSchedule : undefined,
          adjustedDueDate: el.dueDateModified
            ? moment(el.Nextduedate).format("YYYY-MM-DD")
            : undefined,
        });
      });
    }

    if (nonClubData.length) {
      let commonTransferElements = paygridvalues.filter(
        (x) =>
          x.store_number == String(window.sessionStorage.getItem("storeNumber"))
      );
      

      for (let i = 0; i < commonTransferElements.length; i++) {
        if (
          Number(commonTransferElements[i].SuspenseAmount) !=
          Number(commonTransferElements[i].transferOldSuspense)
        ) {
          transferSuspensePayload.push({
            balance: Number(commonTransferElements[i].SuspenseAmount).toFixed(
              2
            ),
            agreementId: String(commonTransferElements[i].Agreementid),
            isTransferToCoa: commonTransferElements[i].isCOATransfered
              ? "Y"
              : undefined,
          });
        }
      }

      nonClubData.map((el) => {
        let isLDWActionPerformed: any = [];
        let isPolicyActionPerformed: any = [];
        if(configFlags?.commonModuleFlag==1){
          if (actLDWArr.length) {
            isLDWActionPerformed =actLDWArr.filter(
              (x) => x.agreementId == el.Agreementid
            )?.map((item: any) => ({...item}));
            if (isLDWActionPerformed.length) {
                if(isLDWActionPerformed[0].action == 1 && el.coverageStatus!='Y'){
                  isLDWActionPerformed[0].action ='A'
                }else if(isLDWActionPerformed[0].action == 2 && el.coverageStatus!='N'){
                  isLDWActionPerformed[0].action ='D'
                }else{
                  isLDWActionPerformed[0].action =undefined
                }
            }
          }
          if (actPolicyArr.length) {
            isPolicyActionPerformed = actPolicyArr.filter(
              (x) => x.agreementId == el.Agreementid
            )?.map((item: any) => ({...item}));
            if (isPolicyActionPerformed.length) {
              if(isPolicyActionPerformed[0].action == 1 && el.coverageStatus!='Y'){
                isPolicyActionPerformed[0].action ='A'
              }else if(isPolicyActionPerformed[0].action == 2 && el.coverageStatus!='N'){
                isPolicyActionPerformed[0].action ='D'
              }else{
                isPolicyActionPerformed[0].action =undefined
              }
                
            }
          }
        }else{
          if (actLDWArr.length) {
            isLDWActionPerformed = actLDWArr.filter(
              (x) => x.agreementId == el.Agreementid
            );
            if (isLDWActionPerformed.length) {
              isLDWActionPerformed[0].action =
                isLDWActionPerformed[0].action == 1 ? 'A' : 'D';
            }
          }
          if (actPolicyArr.length) {
            isPolicyActionPerformed = actPolicyArr.filter(
              (x) => x.agreementId == el.Agreementid
            );
            if (isPolicyActionPerformed.length) {
              isPolicyActionPerformed[0].action =
                isPolicyActionPerformed[0].action == 1 ? 'A' : 'D';
            }
          }
        }
        
        if (Number(el.suspenseAdd) > 0) {
          customerAccountPayload.push({
            actionType: "C",
            accountType: "SUSP",
            storeNumber: el.store_number,
            amount: Number(el.suspenseAdd).toFixed(2),
            agreementId: el.Agreementid,
          });
        }

        if (Number(el.suspenseSub) > 0) {
          customerAccountPayload.push({
            actionType: "D",
            accountType: "SUSP",
            storeNumber: el.store_number,
            amount: Number(el.suspenseSub).toFixed(2),
            agreementId: el.Agreementid,
          });
        }

        agreementPaymentPayload.push({
          agreementId: el.Agreementid,
          editedSchedule: el.editSchedule.length ? el.editSchedule : undefined,
          adjustedDueDate: el.dueDateModified
            ? moment(el.Nextduedate).format("YYYY-MM-DD")
            : undefined,
          freeTime:
            el.FreeTime == null
              ? undefined
              : {
                  type: el.FreeTime.freeTimeType,
                  freeDays: el.FreeTime.freeTimeDays,
                  waiveOptionalService: el.FreeTime.isOptServiceWaived
                    ? "Y"
                    : "N",
                },
          isZeroPayment: el.isZeroPayment == true ? "Y" : undefined,
          activatePolicy: isPolicyActionPerformed.length
          ? isPolicyActionPerformed[0].action
          : undefined,
        activateLdw: isLDWActionPerformed.length
          ? isLDWActionPerformed[0].action
          : undefined,
          epoPayment: el.acceptEpoFlag == 1 ? "Y" : undefined,
          carryLateFeeAmount:
            Number(el.carriedLateFee) > 0
              ? Number(el.carriedLateFee).toFixed(2)
              : undefined,
          waiveLateFeeAmount:
            Number(el.waivedLateFee) > 0
              ? Number(el.waivedLateFee).toFixed(2)
              : undefined,
          epoDiscount:
            el.acceptEpoFlag == 1
              ? {
                  epoDiscount: (Number(el.actualEpoAmount) -
                    Number(el.editedEPOAmount) >
                  0
                    ? Number(el.actualEpoAmount) - Number(el.editedEPOAmount)
                    : 0
                  ).toFixed(2),
                  pastDueDiscount: (Number(el.actualPastDueRent) -
                    Number(el.pastDueRent) >
                  0
                    ? Number(el.actualPastDueRent) - Number(el.pastDueRent)
                    : 0
                  ).toFixed(2),
                }
              : undefined,
        });
      });
    }
    // Payload Build
    if(!cardOnFileFlag)
      {entireAcceptPayAPIArray.push({
      paymentInfo: [
        {
          isEmailNotify: "Y",
          amount: Number(remainingamountdue).toFixed(2),
          optimizedPayment:
            String(configFlags.optimizedPayment) == "1" ? "Y" : "N",
          FirstName: getpayment.paymentInfo.customerInfo[0].firstName,
          LastName: getpayment.paymentInfo.customerInfo[0].lastName,
          storeMerchantId: storeMerchantId || null,
          coWorkerId: String(empId),
          storeNumber: String(window.sessionStorage.getItem("storeNumber")),
          customerId: String(customerId),
          paymentOrigin: paymentOrigin == "Store" ? "S" : "H",
          clubPayment: clubPayload.length ? clubPayload : undefined,
          agreementPayment: agreementPaymentPayload.length
            ? agreementPaymentPayload
            : undefined,
          transferSuspense: transferSuspensePayload.length
            ? transferSuspensePayload
            : undefined,
          miscPayment: miscPaymentPayload.length
            ? miscPaymentPayload
            : undefined,
          paymentMethod: {
            tenderDetails: tenderDetailsPayload.length
              ? tenderDetailsPayload
              : undefined,
            cardDetails: cardDetailsPayload.length
              ? cardDetailsPayload
              : undefined,
            customerAccount: customerAccountPayload.length
              ? customerAccountPayload
              : undefined,
          },
          deactivateClub: clubActivate == 0 ? [String(customerId)] : undefined,
        },
      ],
    });
  }else{
      entireAcceptPayAPIArray.push({
        paymentInfo: [
          {
            isEmailNotify: "Y",
            storeNumber: String(window.sessionStorage.getItem("storeNumber")),
            customerId: String(customerId),
            paymentOrigin: PAYMENT_ORIGIN_TYPE.get('TXT2PAY_STORE_ORIGIN') ?? IN_STORE_T2P_ORIGIN,
            clubPayment: clubPayload.length ? clubPayload : undefined,
            agreementPayment: agreementPaymentPayload.length
              ? agreementPaymentPayload
              : undefined,
            transferSuspense: transferSuspensePayload.length
              ? transferSuspensePayload
              : undefined,
            miscPayment: miscPaymentPayload.length
              ? miscPaymentPayload
              : undefined,
            paymentMethod: {
              cardDetails: cardDetailsPayload.length
                ? cardDetailsPayload
                : undefined,
              customerAccount: customerAccountPayload.length
                ? customerAccountPayload
                : undefined,
            },
            isTextNotify: "N",
            deactivateClub: clubActivate == 0 ? [String(customerId)] : undefined,
            ...(paymentOrigin !== 'Store' && { includeConvenienceFee: 'Y' })
          },
        ],
      });
    }
    return entireAcceptPayAPIArray;
  };

  /**
   * This function gets triggered when Payment Origin drop down gets changed.
   * When the drop down value is changed from store to Phone the following should happen
   *  1. A convenience fee of $ 1.99 gets added to the todal due.
   *  2. Selected tenders should be removed.
   *  3. A popup should be shown about the changed total due amount.
   *  4. On clicking "yes" in popup the total due and remaining amount due will be added with the
   *     convenience fee.
   *  5. On clicking "no" the popup should be closed.
   * When the drop down value is change from Phone to Store the following should happen
   *  1. Convenience fee of $ 1.99 should be subtracted from the total due
   *  2. Remaining amount due should be subtracted with $1.99
   * @param e - The drop down control is passed as parameter to this function
   */
  const phone = (e: any) => {
    const SelectedOption = e.target.value;
    const value = parseFloat(convAmtWTax);
    if (SelectedOption === "Pay By Phone") {
      let NewTotalDue = Number(totaldue) + value;
      setconvtax(
        Number(
          paygridvalues[0].convTax == undefined ? 0 : paygridvalues[0].convTax
        )
      );
      let objChkMo: CheqAndMO[] = [];
      setaddataanother(objChkMo);
      setcashamountcheckbox(0.0);
      setaddcash(0.0);
      //setAcceptpaymentbuttonenable(false);
      setCardrequire({
        ...Cardrequire,
        drpdown: true,
        PaymentOrgin: SelectedOption,
        Newtotal: NewTotalDue.toFixed(2),
      });

      if (tendertypeglobalarray.length > 0) {
        let cashTenderIndex = tendertypeglobalarray.findIndex(
          (x) => x.tendertype === "Cash"
        );
        if (cashTenderIndex > -1) {
          tendertypeglobalarray.splice(cashTenderIndex, 1);
        }

        let chqTenderIndex = tendertypeglobalarray.findIndex(
          (x) => x.tendertype === "Check & Money Order"
        );
        if (chqTenderIndex > -1) {
          tendertypeglobalarray.splice(chqTenderIndex, 1);
        }
      }
      setchargeCardDisable(false);
      settotalduetendertype(tendertypeglobalarray);
      let traceObj={
        iD:Number(userTraceData?.length)+1,
        type:"PaymentOriginInPhone"
      }
      setUserTraceData([...userTraceData,traceObj])
    } else {
      //setaddNewCard(false);
      const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Add" && Number(e.newsuspense) > 0;
      });
      const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Used" && Number(e.newsuspense) > 0;
      });
      let addSusTotalAmount =
        addSuspenseAmount.length == 0
          ? 0.0
          : addSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let usedSusTotalAmount =
        useSuspenseAmount.length == 0
          ? 0.0
          : useSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

      setswipeDisable(previousState);
      setCardrequire({ ...Cardrequire, PaymentOrgin: SelectedOption });
      setmobtn(false);
      setcashbtn(false);
      setconvtax(0);
      let TotalRemAmt = Number(totaldue) - value;
      let remAmtStat =
        TotalRemAmt -
        availableCreds +
        Number(donationAmountAdd)+
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount);
      let RemAmt = 0;
      if (parseFloat(remainingamountdue) > 0.0) {
        RemAmt = parseFloat(remainingamountdue) - value;
        if (RemAmt < 0) {
          RemAmt = RemAmt * -1;
        }
      }
      
      setcreditDisable(false);
      setchangeRadio(true);
      setradioBtn("0");
      setCreditRadio(false);
      //setremainingamountdue(RemAmt);
      //setRemaniningAmtStatic(Number(remAmtStat.toFixed(2)));
      // setdefaultTotaldue(TotalRemAmt)
      totaldue=TotalRemAmt;
      settotaldue(totaldue);
      ConvenienceFee(SelectedOption);
      paymentOrigin=SelectedOption;
      setpaymentOrigin(paymentOrigin);
      tendertypeglobalarray = [];
      settotalduetendertype(tendertypeglobalarray);
      const TempCard_Array: any = new Array(CardRadiArr.length).fill(false);
      setCardRadiArr(TempCard_Array);
      for (let i = 0; i < multipleCard.length; i++) {
        multipleCard[i].Amount = "0.00";
      }
      setmultipleCard(multipleCard);
      setCardErrorLbl(false);
      setCardGridData(CardGrid);
      setcvvTextValue("");
      swipeCardAmt = [];
      setcardDetails([]);
      setcardtxtDisable(false);
      setCardAmt([{ amount: 0.0 }]);
      setswipeamt("0.00");
      swipeCardDetails = [];
      setswipeFlag(false);
      setcvvTextValue("");
      setRemaniningAmtStatic(String(remAmtStat));
      setremainingamountdue(String(remAmtStat));
      setAcceptpaymentbuttonenable(true);
      setchargeCardDisable(true);
      setchange(0.0);
      setchargeCardArray([]);
      if (diffStoreErrLbl && isRevenueRecogActivated) {
        setcashselected(1);
        setswipeDisable(true);
        setmobtn(true);
        setcashbtn(true);
        setCardrequire({
          ...Cardrequire,
          drpdown: false,
          CardCss: `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`,
          PaymentOrgin: SelectedOption
        });
        setcashtabCss(`${navlinkClass.navLink} ${classes.p3}`);
        setcheckMoneyOrdertabCss(`${navlinkClass.navLink} ${classes.p3}`);
      }
      let traceObj={
        iD:Number(userTraceData?.length)+1,
        type:"PaymentOriginInStore"
      }
      setUserTraceData([...userTraceData,traceObj])
    }
  };

  // payment origin popup
  const PaymentOriginPopUp = () => {
    setconvtax(
      Number(
        paygridvalues[0]?.convTax == undefined ? 0 : paygridvalues[0]?.convTax
      )
    );
    let objChkMo: CheqAndMO[] = [];
    setaddataanother(objChkMo);
    setcashamountcheckbox(0.0);
    setaddcash(0.0);

    if (tendertypeglobalarray.length > 0) {
      let cashTenderIndex = tendertypeglobalarray.findIndex(
        (x) => x.tendertype === "Cash"
      );
      if (cashTenderIndex > -1) {
        tendertypeglobalarray.splice(cashTenderIndex, 1);
      }

      let chqTenderIndex = tendertypeglobalarray.findIndex(
        (x) => x.tendertype === "Check & Money Order"
      );
      if (chqTenderIndex > -1) {
        tendertypeglobalarray.splice(chqTenderIndex, 1);
      }
    }
    setchargeCardDisable(false);
    settotalduetendertype(tendertypeglobalarray);
    NewtotalAmt();
    let traceObj = {
      iD: Number(userTraceData?.length) + 1,
      type: "PaymentOriginInPhone"
    }
    setUserTraceData([...userTraceData, traceObj]);
  }

  const phoneRevenue = (e: any) => {
    const SelectedOption = e.target.value;
    const value = parseFloat(convAmtWTax);
    if (SelectedOption === "Pay By Phone") {
      let NewTotalDue = Number(totaldue) + value;
      setconvtax(
        Number(
          paygridvalues[0].convTax == undefined ? 0 : paygridvalues[0].convTax
        )
      );
      let objChkMo: CheqAndMO[] = [];
      setaddataanother(objChkMo);
      setcashamountcheckbox(0.0);
      setaddcash(0.0);
      //setAcceptpaymentbuttonenable(false);
      setCardrequire({
        ...Cardrequire,
        drpdown: true,
        PaymentOrgin: SelectedOption,
        Newtotal: NewTotalDue.toFixed(2),
      });

      if (tendertypeglobalarray.length > 0) {
        let cashTenderIndex = tendertypeglobalarray.findIndex(
          (x) => x.tendertype === "Cash"
        );
        if (cashTenderIndex > -1) {
          tendertypeglobalarray.splice(cashTenderIndex, 1);
        }

        let chqTenderIndex = tendertypeglobalarray.findIndex(
          (x) => x.tendertype === "Check & Money Order"
        );
        if (chqTenderIndex > -1) {
          tendertypeglobalarray.splice(chqTenderIndex, 1);
        }
      }
      setchargeCardDisable(false);
      settotalduetendertype(tendertypeglobalarray);
    } else {
      //setaddNewCard(false);
      const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Add" && Number(e.newsuspense) > 0;
      });
      const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Used" && Number(e.newsuspense) > 0;
      });
      let addSusTotalAmount =
        addSuspenseAmount.length == 0
          ? 0.0
          : addSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let usedSusTotalAmount =
        useSuspenseAmount.length == 0
          ? 0.0
          : useSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

      setswipeDisable(previousState);
      setCardrequire({ ...Cardrequire, PaymentOrgin: SelectedOption });
      setmobtn(false);
      setcashbtn(false);
      setconvtax(0);
      let TotalRemAmt = Number(totaldue) - value;
      let remAmtStat =
        TotalRemAmt -
        availableCreds +
        Number(donationAmountAdd)+
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount);
      let RemAmt = 0;
      if (parseFloat(remainingamountdue) > 0.0) {
        RemAmt = parseFloat(remainingamountdue) - value;
        if (RemAmt < 0) {
          RemAmt = RemAmt * -1;
        }
      }
      setcreditDisable(false);
      setchangeRadio(true);
      setradioBtn("0");
      setCreditRadio(false);
      //setremainingamountdue(RemAmt);
      //setRemaniningAmtStatic(Number(remAmtStat.toFixed(2)));
      // setdefaultTotaldue(TotalRemAmt)
      settotaldue(TotalRemAmt);
      ConvenienceFee(SelectedOption);
      paymentOrigin=SelectedOption;
      setpaymentOrigin(paymentOrigin);
      tendertypeglobalarray = [];
      settotalduetendertype(tendertypeglobalarray);
      const TempCard_Array: any = new Array(CardRadiArr.length).fill(false);
      setCardRadiArr(TempCard_Array);
      for (let i = 0; i < multipleCard.length; i++) {
        multipleCard[i].Amount = "0.00";
      }
      setmultipleCard(multipleCard);
      setCardErrorLbl(false);
      setCardGridData(CardGrid);
      setcvvTextValue("");
      swipeCardAmt = [];
      setcardDetails([]);
      setcardtxtDisable(false);
      setCardAmt([{ amount: 0.0 }]);
      setswipeamt("0.00");
      swipeCardDetails = [];
      setswipeFlag(false);
      setcvvTextValue("");
      setRemaniningAmtStatic(String(remAmtStat));
      setremainingamountdue(String(remAmtStat));
      setAcceptpaymentbuttonenable(true);
      setchargeCardDisable(true);
      setchange(0.0);
      setchargeCardArray([]);
      if (diffStoreErrLbl && isRevenueRecogActivated) {
        setcashselected(1);
        setswipeDisable(true);
        setmobtn(true);
        setcashbtn(true);
        setCardrequire({
          ...Cardrequire,
          drpdown: false,
          CardCss: `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`,
        });
        setcashtabCss(`${navlinkClass.navLink} ${classes.p3}`);
        setcheckMoneyOrdertabCss(`${navlinkClass.navLink} ${classes.p3}`);
      }
    }
  };

  /***
   * This function gets called when "yes" button is clicked from the
   * convenience fee popup. Add the convenience fee of $1.99 to the total due and
   * remaining amount due.
   */
  const NewtotalAmt = () => {
    //setaddNewCard(true);
    setCardrequire({
      ...Cardrequire,
      drpdown: false,
      CardCss: `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`,
    });
   if(paymentOrigin!="Pay By Phone"){
    previousState = swipeDisable;
    setswipeDisable(true);
    const value = parseFloat(convAmtWTax);
    let chargeCardAmount = 0;
    let swipeCardAmount = 0;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Ending with")
      ) {
        chargeCardAmount =
          chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Swipe ending")
      ) {
        swipeCardAmount =
          swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
    let cardAmt = Number(
      multipleCard.length == 0
        ? 0.0
        : multipleCard
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
    );
    cardAmt =
      Number(cardAmt) + Number(chargeCardAmount) + Number(swipeCardAmount);
    //let objCashAndCheqAmt = Number(addcash) + Number(totalamountcheck);
    let objRemainAmt = 0.0;
    let objChange = 0.0;
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    objRemainAmt =
      Number(totaldue) +
      value -
      cardAmt -
      Number(availableCreds)+
      Number(donationAmountAdd) +
      Number(addSusTotalAmount) -
      Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount);
    if (objRemainAmt > 0) {
      setAcceptpaymentbuttonenable(true);
    }
    if (objRemainAmt <= 0) {
      objChange = Math.abs(Number(objRemainAmt.toFixed(2)));
      objRemainAmt = 0.0;
      setAcceptpaymentbuttonenable(false);
    }
    setcashselected(1);
    setmobtn(true);
    setcashbtn(true);
   
    setcashtabCss(`${navlinkClass.navLink} ${classes.p3}`);
    setcheckMoneyOrdertabCss(`${navlinkClass.navLink} ${classes.p3}`);
    setremainingamountdue(Number(objRemainAmt.toFixed(2)));
    setRemaniningAmtStatic(
      (Number(RemaniningAmtStatic) + Number(value)).toFixed(2)
    );
    settotaldue((Number(totaldue) + Number(value)).toFixed(2));
    setchange(objChange);
    setpaymentOrigin("Pay By Phone");
    paymentOrigin="Pay By Phone"
    setaddcash(0);
    setaddataanother(chqAndMo);
    let PaygridObj = paygridvalues.filter((x) => x.Agreementtype != "Club");
    setcreditDisable(true);
    setchangeRadio(false);
    setCreditRadio(true);
    setradioBtn("1");
    settotalamountcheck(0);
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:"PaymentOriginInPhoneYes"
    }
    setUserTraceData([...userTraceData,traceObj])
  }
  };

  /**
   * This function is called when "No" button is clicked in the
   * convenience fee popup.
   */
  const closephonepopup = () => {
    setCardrequire({
      ...Cardrequire,
      drpdown: false,
      PaymentOrgin: "Store",
    });
    ConvenienceFee(Cardrequire.PaymentOrgin);
    setcreditDisable(false);
  };

  const AvailCredits_Binding = () => {
    if (
      availableCredsremaining !== undefined &&
      availableCredsremaining !== undefined &&
      availableCredsremaining.length > 0
    ) {
      return availableCredsremaining.map((item: any, index: any) => {
        if (
          (item.balanceType === "COA" || item.balanceType === "IP Suspense") &&
          parseFloat(item.accountBalance) > 0.0
        ) {
          return (
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
            >
              <Grid
                item
                key={index}
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                <Typography
                  variant="caption"
                  className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
                >
                  {item.balanceType}
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
                >
                  ($<span>{item.accountBalance})</span>
                </Typography>
              </Grid>
            </Grid>
          );
        }
      });
    }
  };

  const WLDeposit_Bind = () => {
    if (
      availableCredsremaining !== undefined &&
      availableCredsremaining.length > 0
    ) {
      return availableCredsremaining.map((item: any, index: any) => {
        if (
          item.balanceType === "WEBLEAD" &&
          parseFloat(item.accountBalance) > 0.0
        ) {
          return (
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
            >
              <Grid
                item
                key={index}
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                <Typography
                  variant="caption"
                  className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
                >
                  WL Deposit
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
                >
                  ($<span>{item.accountBalance})</span>
                </Typography>
              </Grid>
            </Grid>
          );
        }
      });
    }
  };

  //footer functionalities starts here
  const popupButtonclick = () => {
    setCardrequire({
      ...Cardrequire,
      Ldwhide: customerCoverageObject.isLDW === true ? false : true,
      Policyhide:
        customerCoverageObject.isPolicy === true &&
        customerCoverageObject.policyAmount !== ""
          ? false
          : true,
    });
    if (
      PopupClass ===
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    ) {
      setPopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`
      );
    }
    if (
      PopupClass === `${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`
    ) {
      setPopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
      );
    }
  };

  //accept epo changes
  const epopopupButtonclick = () => {

    if (
      epoPopupClass ===
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    ) {
      setepoPopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`
      );
    }
    if (
      // const [suspensePopupClass, setsuspensePopupClass] = useState<string>(
      epoPopupClass ===
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`
    ) {
      setepoPopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
      );
    }
  };

  const epoOnblur = () => {
    //setaddsuspense(true)
    setepoPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };

  const epoSheduleOpen = () => {
    // alert("Hello");
    setepoPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
    setEpoShedulePopup(true);
    // const agr:any = paygridvalues[selectedGrid].Agreementid
    // history.push(`/payment1/eposhedule/${agr}`)
  };

  const acceptEpoButton = async (
    epoFieldValues: any,
    closeState: any,
    invokeSource?: any
  ) => {
    if (paygridvalues[selectedGrid] != undefined) {
      setShowAcceptEpoPopup(false);
      setepoPopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
      );
      let payGridObj = paygridvalues[selectedGrid];
      let prevAmt;
      let prevAgrType = paygridvalues[selectedGrid].Agreementtype;
      //let resAmount=0
      //0
      totalduereceiptidsupport = [];
      acceptPaymentParam = [];
      ReceiptCategoriesRequestParam = [];
      //let res=response.resArray;
      let resAmount = Number(epoFieldValues.total);
      let resTaxamount = Number(epoFieldValues.taxAmt);
      //for(let i =0 ; i<adjustDueDateSelectedAgre.length;i++){

      //payGridObj = paygridvalues.filter((x) => x.Agreementid === res[i].Agreementid );
      // debugger;
      prevAmt = paygridvalues[selectedGrid].Amount;
      paygridvalues[selectedGrid].Amount = Number((Number(epoFieldValues.total)+Number(paygridvalues[selectedGrid].SuspenseAmount)).toFixed(2));
      paygridvalues[selectedGrid].Taxamount = Number(epoFieldValues.taxAmt);
      paygridvalues[selectedGrid].Agreementtype =
        prevAgrType == "Note Payment" ||
        prevAgrType == "EPO Note" ||
        prevAgrType == "PIF Note"
          ? "EPO Note"
          : "EPO Rent";
        paygridvalues[selectedGrid].isFinalTransferSusUseApplied = true;
        paygridvalues[selectedGrid].isFinalTransferSusUseCancel = true;
      paygridvalues[selectedGrid].Nextduedate =
        new Date(paygridvalues[selectedGrid].SAC_Date) >= new Date()
          ? "SAC"
          : "EPO";
      paygridvalues[selectedGrid].rpayTaxAmount = Number(epoFieldValues.taxAmt);
      paygridvalues[selectedGrid].policyAmount = 0.0;
      paygridvalues[selectedGrid].policyTaxAmount = 0.0;
      paygridvalues[selectedGrid].suspenseSub =
        paygridvalues[selectedGrid].SuspenseAmount;
      paygridvalues[selectedGrid].pastDueRent = Number(
        epoFieldValues.pastDueRent
      );
      paygridvalues[selectedGrid].suspenseAdd = 0;
      paygridvalues[selectedGrid].acceptEpoFlag = 1;
      paygridvalues[selectedGrid].agreementNoteDetails.editedNoteAmount =
        Number(epoFieldValues?.editedNoteAmount).toFixed(2);
      // let editedEpoAmunt =agr[0] !== undefined &&
      // agr[0] !== null &&
      // agr[0] !== "" &&
      // agr[0].EPOAmount !== undefined &&
      // agr[0].EPOAmount !== null &&
      // agr[0].EPOAmount !== ""
      //   ? Number(agr[0].EPOAmount) - Number(agr[0].EPOTax) +Number(agr[0].suspenseSub) - Number(agr[0].pastDueRent !== undefined &&
      //     agr[0].pastDueRent !== null &&
      //     agr[0].pastDueRent !== "" ? agr[0].pastDueRent : 0.00) - Number(agr[0].lateFee !== undefined &&
      //       agr[0].lateFee !== null &&
      //       agr[0].lateFee !== ""
      //         ? agr[0].lateFee : 0.00)
      //   : ""
      //   let editedamount = Number(editedEpoAmunt)<0?0.00:Number(editedEpoAmunt)
      // seteditedEPOAmount(editedamount)
      // paygridvalues[selectedGrid].SuspenseAmount = 0.00;
      if(invokeSource == 'CommitmentEPO') {
        let calculatedNoteAmount: any = String(Number(paygridvalues[selectedGrid]?.agreementNoteDetails?.editedNoteAmount)==0?paygridvalues[selectedGrid]?.agreementNoteDetails?.remainingNoteCostAmount:paygridvalues[selectedGrid]?.agreementNoteDetails?.editedNoteAmount);
        console.log('cvgrewswerfdr3', calculatedNoteAmount);
        if(calculatedNoteAmount == 'NaN') {
          calculatedNoteAmount = 0;
        }
        let noteAgr=paygridvalues[selectedGrid].agreementNoteDetails.isNoteAgreement=="1"?true:false;
        if(noteAgr){
          let agrAmount=(Number( epoFieldValues.epoAmt)-Number(paygridvalues[selectedGrid].agreementNoteDetails.remainingNoteCostAmount))<=0?"0.00":(Number( epoFieldValues.epoAmt)-Number(paygridvalues[selectedGrid].agreementNoteDetails?.remainingNoteCostAmount)).toFixed(2)
          let noteAmount="";
          if(agrAmount=="0.00"){
            noteAmount = ( (Number( epoFieldValues.epoAmt))).toFixed(2)
          }
          noteAmount= noteAmount==""?paygridvalues[selectedGrid]?.agreementNoteDetails.remainingNoteCostAmount:noteAmount
          paygridvalues[selectedGrid].isAcceptEPODiscounts.noteDiscount=Number(Number(paygridvalues[selectedGrid]?.agreementNoteDetails.remainingNoteCostAmount)-Number(noteAmount)).toFixed(2) <="0.00"?"0.00": Number(Number(paygridvalues[selectedGrid]?.agreementNoteDetails.remainingNoteCostAmount)-Number(noteAmount)).toFixed(2);
          paygridvalues[selectedGrid].isAcceptEPODiscounts.epoDiscount =Number(Number(paygridvalues[selectedGrid]?.actualEpoAmount)-Number(paygridvalues[selectedGrid]?.agreementNoteDetails.remainingNoteCostAmount)-Number(agrAmount)<0?(Number(paygridvalues[selectedGrid]?.actualEpoAmount)-Number(paygridvalues[selectedGrid].agreementNoteDetails.remainingNoteCostAmount)):
          Number(paygridvalues[selectedGrid]?.actualEpoAmount)-Number(paygridvalues[selectedGrid]?.agreementNoteDetails.remainingNoteCostAmount)-Number(agrAmount)).toFixed(2);
          paygridvalues[selectedGrid].isAcceptEPODiscounts.pastDueDiscount=Number(Number(paygridvalues[selectedGrid]?.actualPastDueRent)-Number(epoFieldValues.pastDueRent)).toFixed(2)<="0.00"?"0.00":Number(Number(paygridvalues[selectedGrid]?.actualPastDueRent)-Number(epoFieldValues.pastDueRent)).toFixed(2);
        }else{
          paygridvalues[selectedGrid].isAcceptEPODiscounts.epoDiscount=Number(Number(paygridvalues[selectedGrid]?.actualEpoAmount)-Number(epoFieldValues.epoAmt)).toFixed(2)<="0.00"?"0.00":Number(Number(paygridvalues[selectedGrid]?.actualEpoAmount)-Number(epoFieldValues.epoAmt)).toFixed(2);
          paygridvalues[selectedGrid].isAcceptEPODiscounts.pastDueDiscount=Number(Number(paygridvalues[selectedGrid]?.actualPastDueRent)-Number(epoFieldValues.pastDueRent)).toFixed(2)<="0.00"?"0.00":Number(Number(paygridvalues[selectedGrid]?.actualPastDueRent)-Number(epoFieldValues.pastDueRent)).toFixed(2);
        }
        // paygridvalues[selectedGrid].isAcceptEPODiscounts.noteDiscount=(Number(paygridvalues[selectedGrid]?.agreementNoteDetails?.remainingNoteCostAmount)-Number(calculatedNoteAmount)).toFixed(2);
    
        // paygridvalues[selectedGrid].isAcceptEPODiscounts.epoDiscount = Number(Math.abs(Number(Number((Number(paygridvalues[selectedGrid]?.actualEpoAmount)-Number(paygridvalues[selectedGrid]?.agreementNoteDetails?.remainingNoteCostAmount)-Number(epoFieldValues?.epoAmt))).toFixed(2)))).toFixed(2) 
        
        // Number((Number(paygridvalues[selectedGrid]?.actualEpoAmount)-Number(paygridvalues[selectedGrid]?.agreementNoteDetails?.remainingNoteCostAmount)-Number(epoFieldValues?.total)<0?(Number(paygridvalues[selectedGrid]?.actualEpoAmount)-Number(paygridvalues[selectedGrid]?.agreementNoteDetails?.remainingNoteCostAmount)):
        // Number(paygridvalues[selectedGrid]?.actualEpoAmount)-Number(paygridvalues[selectedGrid]?.agreementNoteDetails?.remainingNoteCostAmount)-Number(epoFieldValues?.total)).toFixed(2))<=0?'0.00':(Number(paygridvalues[selectedGrid]?.actualEpoAmount)-Number(paygridvalues[selectedGrid]?.agreementNoteDetails?.remainingNoteCostAmount)-Number(epoFieldValues?.total)<0?(Number(paygridvalues[selectedGrid]?.actualEpoAmount)-Number(paygridvalues[selectedGrid]?.agreementNoteDetails?.remainingNoteCostAmount)):
        // Number(paygridvalues[selectedGrid]?.actualEpoAmount)-Number(paygridvalues[selectedGrid]?.agreementNoteDetails?.remainingNoteCostAmount)-Number(epoFieldValues?.total)).toFixed(2)

        // paygridvalues[selectedGrid].isAcceptEPODiscounts.pastDueDiscount=(Number(paygridvalues[selectedGrid]?.actualPastDueRent)-Number(epoFieldValues?.pastDueRent)).toFixed(2);
      }
      paygridvalues[selectedGrid].EPOAmount =Number((Number(epoFieldValues.total)+Number(paygridvalues[selectedGrid].SuspenseAmount)).toFixed(2));
      paygridvalues[selectedGrid].EPOTax = Number(epoFieldValues.taxAmt);
      paygridvalues[selectedGrid].editedEPOAmount =  Number(Number( epoFieldValues.epoAmt) - Number(paygridvalues[selectedGrid]?.isAcceptEPODiscounts?.epoDiscount)-Number(paygridvalues[selectedGrid]?.isAcceptEPODiscounts?.noteDiscount)).toFixed(2);

      //to set the receipt item values
      const agreementDetails = {
        agreement_id: paygridvalues[selectedGrid].AgreementNumber,
        agreement_totalamount: fixedChange(String(epoFieldValues.total)),
      };
      totalduereceiptidsupport.push(agreementDetails);
      settotalduereceiptid(totalduereceiptidsupport);
      //accept payment array push starts here
      let ldwamountcalculation = 0.0;
      let objCarriedLateFee = 0;
      let objAmount = "0.00";
      let clubFlag = 0;
      let ldwFeeTax = 0.0;
      let pifAmt = 0;
      let pifTax = 0;
      let sacTax = 0;
      let pifFlag = 0;
      let sacAmt = 0;
      let epoAmt = 0;
      let epoTax = 0;
      let getPayData =
        localGetPayData !== undefined
          ? localGetPayData
          : getpayment !== undefined
          ? getpayment
          : undefined;
      if (getPayData !== undefined && getPayData !== null) {
        //let objLdwInfo = getpayment.paymentInfo.ldwInformation.filter(x => x.agreementId ===  payGridObj[0].Agreementid);
        let objAmtDue;
        if (payGridObj.Agreementtype === "Club") {
          objAmtDue = getPayData.amountDue.amountDueResponse.filter(
            (x) => x.clubAmountDue !== undefined
          );
        } else {
          objAmtDue = getPayData.amountDue.amountDueResponse.filter(
            (x) => x.agreementId === payGridObj.Agreementid
          );
        }

        if (objAmtDue[0].isLdwAllowed != "1") {
          paygridvalues[selectedGrid].agrLdwAmount = 0;
          paygridvalues[selectedGrid].ldwTaxAmount = 0;
        }

        //if (objLdwInfo.length > 0 && objLdwInfo[0].ldwFlag == true &&  payGridObj[0].Agreementtype !== "Club") {
        if (
          objAmtDue[0].ldwLateRent !== undefined &&
          objAmtDue[0].ldwLateRent !== null &&
          objAmtDue[0].ldwLateRent !== "" &&
          objAmtDue[0].ldwLateRent !== "0" &&
          objAmtDue[0].isLdwAllowed == "1"
        ) {
          ldwamountcalculation = objAmtDue[0].ldwLateRent;
          ldwFeeTax = Number(objAmtDue[0].ldwTaxAmount);
          resTaxamount = resTaxamount + Number(objAmtDue[0].ldwTaxAmount);
          paygridvalues[selectedGrid].Taxamount =
            paygridvalues[selectedGrid].Taxamount +
            Number(objAmtDue[0].ldwTaxAmount);
        } else if (
          objAmtDue[0].ldwAmount !== undefined &&
          objAmtDue[0].ldwAmount !== null &&
          objAmtDue[0].ldwAmount !== "" &&
          objAmtDue[0].isLdwAllowed == "1"
        ) {
          ldwamountcalculation = objAmtDue[0].ldwAmount;
          ldwFeeTax = Number(objAmtDue[0].ldwTaxAmount);
          resTaxamount = resTaxamount + Number(objAmtDue[0].ldwTaxAmount);
          paygridvalues[selectedGrid].Taxamount =
            paygridvalues[selectedGrid].Taxamount +
            Number(objAmtDue[0].ldwTaxAmount);
        }
        //}
        if (
          objAmtDue[0].amountDueLateRent !== undefined &&
          objAmtDue[0].amountDueLateRent !== null &&
          objAmtDue[0].amountDueLateRent !== "" &&
          objAmtDue[0].amountDueLateRent !== "0"
        ) {
          objAmount = objAmtDue[0].amountDueLateRent;
        } else if (objAmtDue[0].amountDue !== undefined) {
          objAmount = String(objAmtDue[0].amountDue);
        } else if (
          objAmtDue[0].clubAmountLateRent !== undefined &&
          objAmtDue[0].clubAmountLateRent !== null &&
          objAmtDue[0].clubAmountLateRent !== ""
        ) {
          clubFlag = 1;
          objAmount = objAmtDue[0].clubAmountLateRent;
        } else {
          clubFlag = 1;
          objAmount = String(objAmtDue[0].clubAmountDue);
        }
        let objPayHistory = getPayData.paymentInfo.paymentHistoryInfo.filter(
          (x) => x.agreementId === payGridObj.Agreementid
        );
        if (objPayHistory !== undefined && objPayHistory.length > 0) {
          objCarriedLateFee = objPayHistory[0].carriedLateFee;
        }
        let agreementAmount = Number(
          (
            payGridObj.Amount -
            Number(payGridObj.lateFee) +
            Number(payGridObj.suspenseSub)
          ).toFixed(2)
        );
        //let epoAmount = Number( payGridObj[0].EPOAmount.toFixed(2));
        // let currentDate = new Date();
        // let SACDate = new Date( payGridObj[0].SAC_Date);
        if (paygridvalues[selectedGrid].Agreementtype == "EPO Rent") {
          if (paygridvalues[selectedGrid].Nextduedate == "EPO") {
            epoAmt =
              paygridvalues[selectedGrid].acceptEpoFlag == 0
                ? Number(
                    (
                      agreementAmount - paygridvalues[selectedGrid].Taxamount
                    ).toFixed(2)
                  )
                : Number(paygridvalues[selectedGrid].EPOAmount) +
                  Number(paygridvalues[selectedGrid].suspenseSub) -
                  Number(paygridvalues[selectedGrid].lateFee) -
                  Number(paygridvalues[selectedGrid].EPOTax);
            epoTax =
              paygridvalues[selectedGrid].acceptEpoFlag == 0
                ? Number(paygridvalues[selectedGrid].rpayTaxAmount)
                : Number(paygridvalues[selectedGrid].EPOTax) -
                  Number(paygridvalues[selectedGrid].lFeeTaxAmount);
          } else {
            sacAmt =
              paygridvalues[selectedGrid].acceptEpoFlag == 0
                ? Number(
                    (
                      agreementAmount - paygridvalues[selectedGrid].Taxamount
                    ).toFixed(2)
                  )
                : Number(paygridvalues[selectedGrid].EPOAmount) +
                  Number(paygridvalues[selectedGrid].suspenseSub) -
                  Number(paygridvalues[selectedGrid].lateFee) -
                  Number(paygridvalues[selectedGrid].EPOTax);
            sacTax =
              paygridvalues[selectedGrid].acceptEpoFlag == 0
                ? Number(paygridvalues[selectedGrid].rpayTaxAmount)
                : Number(paygridvalues[selectedGrid].EPOTax) -
                  Number(paygridvalues[selectedGrid].lFeeTaxAmount);
          }
          setfinalagr(Number(paygridvalues[selectedGrid].Agreementid));
          pifFlag = 1;
        } else if (paygridvalues[selectedGrid].Agreementtype == "PIF Rent") {
          pifAmt =
            paygridvalues[selectedGrid].acceptEpoFlag == 0
              ? Number(
                  (
                    agreementAmount - paygridvalues[selectedGrid].Taxamount
                  ).toFixed(2)
                )
              : Number(paygridvalues[selectedGrid].EPOAmount) +
                Number(paygridvalues[selectedGrid].suspenseSub) -
                Number(paygridvalues[selectedGrid].lateFee) -
                Number(paygridvalues[selectedGrid].EPOTax);
          pifTax =
            paygridvalues[selectedGrid].acceptEpoFlag == 0
              ? Number(paygridvalues[selectedGrid].rpayTaxAmount)
              : Number(paygridvalues[selectedGrid].EPOTax) -
                Number(paygridvalues[selectedGrid].lFeeTaxAmount);
          setfinalagr(Number(paygridvalues[selectedGrid].Agreementid));
          pifFlag = 1;
        }
        let ChangeAmt = change;
        let ReceiptArray = {
          customerId: Number(customerId),
          schedule: payGridObj.Schedule + "|" + payGridObj.AgreementNumber,
          amountDue: Number(
            clubFlag !== 1 && pifFlag == 0
              ? (payGridObj.Amount - Number(payGridObj.lateFee)).toFixed(2)
              : 0
          ),
          ldwFeeAmount: Number(payGridObj.agrLdwAmount), // Added By Me
          // Number(payGridObj.PromoFreeDaysApplied) == 0
          //   ? payGridObj.agrLdwAmount
          //   : 0.0,
          clubPayment: Number(
            clubFlag === 1
              ? Number(payGridObj.Amount.toFixed(2)) -
                  Number(payGridObj.Taxamount)
              : 0
          ),
          clubPaymentTax: Number(clubFlag === 1 ? payGridObj.Taxamount : 0),
          creditOnAccountAdd: 0,
          creditOnAccountSub: Number(payGridObj.COA),
          integratedPaymentSuspense: -1 * Number(payGridObj.ipSuspense),
          initialPayment:
            clubFlag === 1
              ? 0
              : payGridObj.number_of_paymentsmade === 0
              ? 1
              : 0,
          netLateFee: 0,
          carriedLateFee: 0,
          wlDeposit: -1 * Number(payGridObj.wlDeposit),
          htSuspense: 0,
          suspenseAdd: Number(0),
          suspenseSub: Number(payGridObj.suspenseSub), //Number( payGridObj[0].suspense),
          racTirePay: Number(payGridObj.policyAmount),
          agreementId:
            clubFlag !== 1 ? Number(payGridObj.Agreementid) : undefined,
          change: Number(fixedChange(ChangeAmt)),
          totalTax: payGridObj.Taxamount,
          sac: Number(sacAmt),
          sacTax: Number(sacTax),
          epo: Number(epoAmt),
          epoTax: Number(epoTax),
          epoDiscount:
            payGridObj.acceptEpoFlag == 0
              ? 0.0
              : Number(payGridObj.actualEpoAmount) -
                Number(payGridObj.editedEPOAmount),
          pastDueDiscount:
            payGridObj.acceptEpoFlag == 0
              ? 0.0
              : Number(payGridObj.actualPastDueRent) -
                Number(payGridObj.pastDueRent),
          pastDueAmount:
            payGridObj.acceptEpoFlag == 0
              ? undefined
              : Number(payGridObj.actualPastDueRent),
          nextDueDate:
            payGridObj.acceptEpoFlag == 0 ? undefined : payGridObj.Nextduedate,
          // ldwFeeAmountTax: Number(payGridObj.ldwTaxAmount),
          ldwFeeAmountTax: Number(payGridObj.ldwTaxAmount),
          rentalPaymentTax: pifFlag == 0 ? Number(payGridObj.rpayTaxAmount) : 0,
          lateRentalPaymentfeeTax: Number(payGridObj.lFeeTaxAmount),
          lateRentalPaymentfee: Number(payGridObj.lateFee),
          racTirePayTax: Number(payGridObj.policyTaxAmount),
          deliveryFee: Number(payGridObj.deliveryAmount),
          deliveryFeeTax: Number(payGridObj.deliveryAmountTax),
          processingFee: Number(payGridObj.processingFee),
          processingFeeTax: Number(payGridObj.processingFeeTax),
          //otherFee: Number( payGridObj[0].mattressFee),
          //otherFeeTax: Number( payGridObj[0].mattressFeeTax),
          pif: Number(pifAmt),
          pifTax: Number(pifTax),
          extensionAmount: Number(payGridObj.extensionAmount),
          convenienceFee: 0,
          convenienceFeeTax: 0,
          mattressFee: Number(payGridObj.mattressFee),
        };
        ReceiptCategoriesRequestParam.push(ReceiptArray);
        if (payGridObj.Agreementtype !== "Club") {
          let acceptPaymentArray = {
            extensionAmount: Number(payGridObj.extensionAmount),
            noOfDaysFree: Number(payGridObj.noOfDaysFree),
            initialPaymentFlag: payGridObj.number_of_paymentsmade == 0 ? 1 : 0,
            addTransferSusFlag: 0,
            schedule: payGridObj.Schedule,
            agreementId: Number(payGridObj.Agreementid),
            ldwFeeAmount: Number(payGridObj.agrLdwAmount.toFixed(2)), // Added By Me
            // Number(
            //   objAmtDue[0].isLdwAllowed == "1" ||
            //     Number(payGridObj.PromoFreeDaysApplied) == 0
            //     ? payGridObj.agrLdwAmount.toFixed(2)
            //     : 0.0
            // ),
            agreementAmountDue: Number(
              fixedChange(
                String(
                  Number(payGridObj.Amount) +
                    (Number(payGridObj.acceptEpoFlag) == 1
                      ? Number(payGridObj.suspenseSub)
                      : 0)
                )
              )
            ),
            remainingEpoAmount: Number(payGridObj.EPOAmount.toFixed(2)),
            promoFreeDays: Number(payGridObj.PromoFreeDaysApplied),
            netLateFee: Number(payGridObj.lateFee),
            carriedLateFee: Number(
              Number(payGridObj.carriedLateFee).toFixed(2)
            ),
            numberOfDaysLate: payGridObj.CurrentDaysLate,
            originalDueDate: payGridObj.CurrentDueDate,
            nextDueDate: payGridObj.Nextduedate,
            totalTax: Number(payGridObj.Taxamount.toFixed(2)),
            policyAmount: Number(0),
            editSchedule: payGridObj.editSchedule,
            freeTime: payGridObj.FreeTime, // Added By Me
            ldwActivate: undefined,
          };
          acceptPaymentParam.push(acceptPaymentArray);
          setAcceptClub(undefined);
        }
          await multipleAdjust();
        
          if(invokeSource == 'CommitmentEPO') {
            console.log('checkedAgr in acpepo', checkedAgr, paygridvalues);
            
            editAdjustRes({ resArray: checkedAgr });
          } else {
            editAdjustRes({ resArray: [paygridvalues[selectedGrid]] });
          }
        
      }
      
    }
  };

  const acceptEpoCloseButton = () => {
    setepoPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
    setShowAcceptEpoPopup(false);
    setEpoShedulePopup(false);
  };

  const acceptEpoPopupOpen = () => {
    if(!selectedGrid){
      selectedGrid=paygridvalues?.findIndex((x)=>x.Agreementid==checkedAgr[0].Agreementid) == -1 ? selectedGrid:paygridvalues?.findIndex((x)=>x.Agreementid==checkedAgr[0].Agreementid);
      }

    if(
      checkedAgr?.some(
        (obj) => obj?.agreementRateDetails?.isItemInService == "Y"
      )){
        setShowAcceptEpoPopup(false);
      }else{
        setShowAcceptEpoPopup(true);
      }
    
  };

  //syed change 3

  const suspensepopupButtonclick = () => {
    if (
      suspensePopupClass ===
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    ) {
      setsuspensePopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`
      );
    }
    if (
      // const [suspensePopupClass, setsuspensePopupClass] = useState<string>(
      suspensePopupClass ===
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`
    ) {
      setsuspensePopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
      );
    }
  };

  const suspenseOnblur = () => {
    //setaddsuspense(true)
    setsuspensePopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };

  const otherActOnblur = () => {
    setPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };
  const epoActOnblur = () => {
    setepoPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };

  const TerminateLDWbtnPopup = () => {
    setTerminateLDW(true);
    setPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };

  //onclick change for payment history popup

  const HistorybtnPopup = () => {
    setPaymentHistory(true);
  };

  //syed change 6
  const addSuspensePopup = () => {

    let insertArray: any[] = [];

    for (let i = 0; i < checkedAgr.length; i++) {
      if (
        checkedAgr[i].Agreementtype != "Club" &&
        checkedAgr[i].Nextduedate != "PIF" &&
        checkedAgr[i].Nextduedate != "SAC" &&
        checkedAgr[i].Nextduedate != "EPO" &&
        checkedAgr[i].number_of_paymentsmade > 0 &&
        String(checkedAgr[i].store_number) ==
          String(window.sessionStorage.getItem("storeNumber"))
      ) {
        insertArray.push({
          sustype: Number(checkedAgr[i].suspenseAdd) > 0 ? "Add" : "type",
          agreementnumber: checkedAgr[i].Agreementid,
          agreementid: checkedAgr[i].AgreementNumber,
          schedure: checkedAgr[i].Schedule,
          paymentamount: checkedAgr[i].Amount,
          epoamount: checkedAgr[i].EPOAmount,
          suspenseamount: Number(checkedAgr[i].SuspenseAmount).toFixed(2),
          newsuspense: Number(checkedAgr[i].suspenseAdd).toFixed(2),
          agreementType: checkedAgr[i].Agreementtype,
        });
      }
    }
    setmasteraddsuspensearray([...insertArray]);

    setAddsusText(true);
    setaddsuspense(true);
  };

  const useSuspensePopup = () => {
 

    let insertArray: any[] = [];

    for (let i = 0; i < checkedAgr.length; i++) {
      if (
        checkedAgr[i].Agreementtype != "Club" &&
        checkedAgr[i].Nextduedate != "PIF" &&
        checkedAgr[i].Nextduedate != "SAC" &&
        checkedAgr[i].Nextduedate != "EPO" &&
        checkedAgr[i].number_of_paymentsmade > 0 &&
        String(checkedAgr[i].store_number) ==
          String(window.sessionStorage.getItem("storeNumber"))
      ) {
        insertArray.push({
          sustype: Number(checkedAgr[i].suspenseSub) > 0 ? "Used" : "type",
          agreementnumber: checkedAgr[i].Agreementid,
          agreementid: checkedAgr[i].AgreementNumber,
          schedure: checkedAgr[i].Schedule,
          paymentamount: checkedAgr[i].Amount,
          epoamount: checkedAgr[i].EPOAmount,
          suspenseamount: Number(checkedAgr[i].SuspenseAmount).toFixed(2),
          newsuspense: Number(checkedAgr[i].suspenseSub).toFixed(2),
          agreementType: checkedAgr[i].Agreementtype,
        });
      }
    }
    setmasterusesuspensearray([...insertArray]);

    setUsesusText(true);
    setusesuspense(true);
  };

  const transferSuspensePopup = () => {
    let insertArray: any[] = [];
    let totalsusamount = 0;
    let transferedSupenseAmount = 0;

    for (let i = 0; i < paygridvalues?.length; i++) {
      if (
        paygridvalues[i].Agreementtype != "Club" &&
        paygridvalues[i].Nextduedate != "PIF" &&
        paygridvalues[i].Nextduedate != "SAC" &&
        paygridvalues[i].Nextduedate != "EPO" &&
        paygridvalues[i].number_of_paymentsmade > 0 &&
        String(paygridvalues[i].store_number) ==
          String(window.sessionStorage.getItem("storeNumber")) &&
        !paygridvalues[i].isExchanged
      ) {
        totalsusamount =
          Number(totalsusamount) + Number(paygridvalues[i].SuspenseAmount);
        transferedSupenseAmount =
          Number(transferedSupenseAmount) +
          Number(paygridvalues[i].transferOldSuspense);
        insertArray.push({
          sustype: "type",
          agreementnumber: paygridvalues[i].Agreementid,
          agreementid: paygridvalues[i].AgreementNumber,
          schedure: paygridvalues[i].Schedule,
          paymentamount: paygridvalues[i].Amount,
          epoamount: paygridvalues[i].EPOAmount,
          suspenseamount: Number(paygridvalues[i].SuspenseAmount).toFixed(2),
          newsuspense: Number(paygridvalues[i].SuspenseAmount).toFixed(2),
          agreementType: paygridvalues[i].Agreementtype,
        });
        settotolsuspenseamount(Number(totalsusamount).toFixed(2));
        setdifferenceamount(
          Number(
            (Number(transferedSupenseAmount) - Number(totalsusamount)).toFixed(
              2
            )
          )
        );
        if (Number(totalsusamount) - Number(transferedSupenseAmount) == 0) {
          settransferDifferenceZero(false);
        } else {
          settransferDifferenceZero(true);
        }
      }
    }

    setmastertranssuspensearray([...insertArray]);
    setUsesusText(true);
    settransuspense(true);
  };

  const refundSuspensePopup = () => {

    let insertArray: any[] = [];

    for (let i = 0; i < checkedAgr.length; i++) {
      if (
        checkedAgr[i].Agreementtype != "Club" &&
        checkedAgr[i].Nextduedate != "PIF" &&
        checkedAgr[i].Nextduedate != "SAC" &&
        checkedAgr[i].Nextduedate != "EPO" &&
        checkedAgr[i].number_of_paymentsmade > 0
      ) {
        insertArray.push({
          sustype: "type",
          agreementnumber: checkedAgr[i].Agreementid,
          agreementid: checkedAgr[i].AgreementNumber,
          schedure: checkedAgr[i].Schedule,
          paymentamount: checkedAgr[i].Amount,
          epoamount: checkedAgr[i].EPOAmount,
          suspenseamount: checkedAgr[i].SuspenseAmount,
          newsuspense: 0.0,
          agreementType: checkedAgr[i].Agreementtype,
        });
      }
    }

    setmasterrefundsuspensearray([...insertArray]);

    setrefundsuspense(true);
  };

  const ActivateLDWbtnPopup = () => {
    setActiveLDW(true);
    setPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };

  const Activepolicypopup = () => {
    setActivepolicy(true);
    setPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };

  const DeActivepolicypopup = () => {
    setDeActivepolicy(true);
    setPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };

  const ContinueTerminateLDW = async () => {
    setTerminateLDW(false);
    const terminateLdw_Resp: any = await LDW_apiCall(2);
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:"TerminateLDW"
    }
    setUserTraceData([...userTraceData,traceObj])
  };

  const continueActivateLDW = async () => {
    setActiveLDW(false);
    const ActiveLdw_Resp: any = await LDW_apiCall(1);
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:"ActivateLDW"
    }
    setUserTraceData([...userTraceData,traceObj])
  };

  const ContinueActivatePolicy = async () => {
    setActivepolicy(false);
    const activatePolicy_ResPonse: any = await Policy_apiCall(1);
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:"ActivatePolicy"
    }
    setUserTraceData([...userTraceData,traceObj])
  };

  const ContinueDeActivatePolicy = async () => {
    setDeActivepolicy(false);
    const TerminatePolicy_Res: any = await Policy_apiCall(2);
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:"DeactivatePolicy"
    }
    setUserTraceData([...userTraceData,traceObj])
  };
  const changeDateformat = (datearg: string) => {
    if (datearg !== "" || datearg !== null || datearg !== undefined) {
      let day = new Date(datearg).getDay();
      if (day == 0) {
        return datearg + "," + "Sun";
      } else if (day == 1) {
        return datearg + "," + "Mon";
      } else if (day == 2) {
        return datearg + "," + "Tue";
      } else if (day == 3) {
        return datearg + "," + "Wed";
      } else if (day == 4) {
        return datearg + "," + "Thu";
      } else if (day == 5) {
        return datearg + "," + "Fri";
      } else if (day == 6) {
        return datearg + "," + "Sat";
      }
    }
  };

  const FormAcceptPaymentData = (
    paramamount: any,
    paramAvailCredits: number,
    paramCardObject?: CardChk
  ) => {
    let objCheckArray = adddataanother.filter((x) => x.Type_info === "Check");
    let objCheckAmt = objCheckArray.reduce(
      (sumVal, a) => sumVal + parseFloat(a.Amount_rac),
      0
    );

    let objMoArray = adddataanother.filter(
      (x) => x.Type_info === "Money Order"
    );
    let objMoAmt = objMoArray.reduce(
      (sumVal, a) => sumVal + parseFloat(a.Amount_rac),
      0
    );

    let objAP: AcceptPaymentInfo = {
      CashAmount: paramamount === 0 ? parseFloat(addcash) : paramamount,
      ChequeAmount: Number(fixedChange(String(objCheckAmt))),
      MOAmount: Number(fixedChange(String(objMoAmt))),
      CardInfo: multipleCard,
      CheqAndMO: adddataanother.length > 0 ? adddataanother : null,
      AvailableCredits: paramAvailCredits,
    };
    setAcceptPayment(objAP);
  };

  const Bindgridcheckbox = () => {
    //   ReceiptCategoriesRequestParam = [];
    //   acceptPaymentParam = [];
    //   AcceptclubPaymentParam = undefined;
    if (paygridvalues !== undefined) {
      let checkBoxArray = document.getElementsByName("grid");
      //let objAmt = 0;
      checkBoxArray.forEach(function (item, index) {
        let obj = item as HTMLInputElement;
        let dataVal = obj.value.split("|");

        if (ReceiptCategoriesRequestParam.length === 0 && agreementId !== "0") {
          for (let i = 0; i < paygridvalues.length; i++) {
            if (paygridvalues[index].Agreementid == agreementId) {
              selectedGrid = i;
            }
          }
          let PaygridObj = paygridvalues.filter(
            (x) => x.Agreementid == Number(agreementId)
          );
          let epoAmt = 0;
          let epoTax = 0;
          let agrAmount = Number(PaygridObj[0].Amount.toFixed(2));
          let curDate = new Date();
          let sacDate = new Date(PaygridObj[index].SAC_Date);
          let sacTax = 0;
          let pifAmt = 0;
          let pifTax = 0;
          let pifFlag = 0;

          let agreementAmount = Number(
            (
              PaygridObj[0].Amount -
              Number(PaygridObj[0].lateFee) +
              Number(PaygridObj[0].suspenseSub)
            ).toFixed(2)
          );
          //let epoAmount = Number(PaygridObj[0].EPOAmount.toFixed(2));

          let sacAmt = 0;
          if (PaygridObj[0].Agreementtype == "EPO Rent") {
            if (PaygridObj[0].Nextduedate == "EPO") {
              epoAmt =
                PaygridObj[0].acceptEpoFlag == 0
                  ? Number(
                      (agreementAmount - PaygridObj[0].Taxamount).toFixed(2)
                    )
                  : Number(PaygridObj[0].EPOAmount) +
                    Number(PaygridObj[0].suspenseSub) -
                    Number(PaygridObj[0].lateFee) -
                    Number(PaygridObj[0].EPOTax);
              epoTax =
                PaygridObj[0].acceptEpoFlag == 0
                  ? Number(PaygridObj[0].rpayTaxAmount)
                  : Number(PaygridObj[0].EPOTax) -
                    Number(PaygridObj[0].lFeeTaxAmount);
            } else {
              sacAmt =
                PaygridObj[0].acceptEpoFlag == 0
                  ? Number(
                      (agreementAmount - PaygridObj[0].Taxamount).toFixed(2)
                    )
                  : Number(PaygridObj[0].EPOAmount) +
                    Number(PaygridObj[0].suspenseSub) -
                    Number(PaygridObj[0].lateFee) -
                    Number(PaygridObj[0].EPOTax);
              sacTax =
                PaygridObj[0].acceptEpoFlag == 0
                  ? Number(PaygridObj[0].rpayTaxAmount)
                  : Number(PaygridObj[0].EPOTax) -
                    Number(PaygridObj[0].lFeeTaxAmount);
            }
            pifFlag = 1;
          } else if (PaygridObj[0].Agreementtype == "PIF Rent") {
            pifAmt =
              PaygridObj[0].acceptEpoFlag == 0
                ? Number((agreementAmount - PaygridObj[0].Taxamount).toFixed(2))
                : Number(PaygridObj[0].EPOAmount) +
                  Number(PaygridObj[0].suspenseSub) -
                  Number(PaygridObj[0].lateFee) -
                  Number(PaygridObj[0].EPOTax);
            pifTax =
              PaygridObj[0].acceptEpoFlag == 0
                ? Number(PaygridObj[0].rpayTaxAmount)
                : Number(PaygridObj[0].EPOTax) -
                  Number(PaygridObj[0].lFeeTaxAmount);
            pifFlag = 1;
          }
          if (PaygridObj[0].miscellaneousItemsNum == 0) {
            let ReceiptArray = {
              customerId: Number(customerId),
              schedule:
                PaygridObj[0].Schedule + "|" + PaygridObj[0].AgreementNumber,
              amountDue: Number(
                PaygridObj[0].Agreementtype !== "Club" && pifFlag == 0
                  ? (
                      PaygridObj[0].Amount - Number(PaygridObj[0].lateFee)
                    ).toFixed(2)
                  : 0
              ),
              ldwFeeAmount: PaygridObj[0].agrLdwAmount,
              clubPayment: Number(
                PaygridObj[0].Agreementtype === "Club"
                  ? Number(PaygridObj[0].Amount) -
                      Number(PaygridObj[0].Taxamount)
                  : 0
              ),
              clubPaymentTax: Number(
                PaygridObj[0].Agreementtype === "Club"
                  ? PaygridObj[0].Taxamount
                  : 0
              ),
              creditOnAccountAdd: 0,
              creditOnAccountSub: Number(PaygridObj[0].COA),
              integratedPaymentSuspense: -1 * Number(PaygridObj[0].ipSuspense),
              initialPayment: Number(
                PaygridObj[0].Agreementtype !== "Club"
                  ? PaygridObj[0].number_of_paymentsmade === 0
                    ? 1
                    : 0
                  : 0
              ),
              netLateFee: 0,
              carriedLateFee: 0, //Number(PaygridObj[0].carriedLateFee),
              wlDeposit: -1 * Number(PaygridObj[0].wlDeposit),
              htSuspense: 0, //Number(PaygridObj[0].htSuspense),
              suspenseAdd: Number(PaygridObj[0].suspenseAdd),
              suspenseSub: Number(PaygridObj[0].suspenseSub), //Number(PaygridObj[0].suspense),
              racTirePay: Number(PaygridObj[0].policyAmount), //Number(PaygridObj[0].racTirePay),
              agreementId:
                PaygridObj[0].Agreementtype !== "Club"
                  ? Number(PaygridObj[0].Agreementid)
                  : undefined,
              change: 0,
              totalTax: PaygridObj[0].Taxamount,
              sac: Number(sacAmt),
              sacTax: Number(sacTax),
              epo: Number(epoAmt),
              epoTax: Number(epoTax),
              epoDiscount:
                PaygridObj[0].acceptEpoFlag == 0
                  ? 0.0
                  : Number(PaygridObj[0].actualEpoAmount) -
                    Number(PaygridObj[0].editedEPOAmount),
              pastDueDiscount:
                PaygridObj[0].acceptEpoFlag == 0
                  ? 0.0
                  : Number(PaygridObj[0].actualPastDueRent) -
                    Number(PaygridObj[0].pastDueRent),
              pastDueAmount:
                PaygridObj[0].acceptEpoFlag == 0
                  ? undefined
                  : Number(PaygridObj[0].actualPastDueRent),
              nextDueDate:
                PaygridObj[0].acceptEpoFlag == 0
                  ? undefined
                  : PaygridObj[0].Nextduedate,
              ldwFeeAmountTax: Number(PaygridObj[0].ldwTaxAmount),
              rentalPaymentTax: Number(
                pifFlag == 0 && PaygridObj[0].Agreementtype !== "Club"
                  ? PaygridObj[0].rpayTaxAmount
                  : 0
              ),
              lateRentalPaymentfeeTax: Number(PaygridObj[0].lFeeTaxAmount),
              lateRentalPaymentfee: Number(PaygridObj[0].lateFee),
              racTirePayTax: Number(PaygridObj[0].policyTaxAmount),
              deliveryFee: Number(PaygridObj[0].deliveryAmount),
              deliveryFeeTax: Number(PaygridObj[0].deliveryAmountTax),
              processingFee: Number(PaygridObj[0].processingFee),
              processingFeeTax: Number(PaygridObj[0].processingFeeTax),
              carryRentAmountUsed:
                PaygridObj[0].FreeTime == null
                  ? Number(PaygridObj[0].carryRentAmountUsed)
                  : 0,
              carryRentTaxUsed:
                PaygridObj[0].FreeTime == null
                  ? Number(PaygridObj[0].carryRentTaxUsed)
                  : 0,
              //otherFee: Number(PaygridObj[0].mattressFee),
              //otherFeeTax: Number(PaygridObj[0].mattressFeeTax),
              extensionAmount: Number(PaygridObj[0].extensionAmount),
              pif: Number(pifAmt),
              pifTax: Number(pifTax),
              convenienceFee: 0,
              convenienceFeeTax: 0,
              mattressFee: Number(PaygridObj[0].mattressFee),
            };
            ReceiptCategoriesRequestParam.push(ReceiptArray);
          }
        }
        let clubObj = paygridvalues.filter((x) => x.Agreementtype == "Club");
        let objAmtDue;
        let objAmount = "0.00";
        if (clubObj[0] != undefined && clubObj[0].Agreementtype === "Club") {
          objAmtDue = getpayment?.amountDue.amountDueResponse.filter(
            (x) => x.clubAmountDue !== undefined
          );
        }
        if (
          AcceptclubPaymentParam == undefined &&
          clubObj[0] != undefined &&
          agreementId !== "0" &&
          objAmtDue[0].initialPaymentFlag == 1 &&
          clubInitialCheckFlag
        ) {
          clubInitialCheckFlag = false;
          if (clubObj[0].miscellaneousItemsNum == 0) {
            let ReceiptArray = {
              customerId: Number(customerId),
              schedule: clubObj[0].Schedule + "|" + clubObj[0].AgreementNumber,
              amountDue: Number(
                clubObj[0].Agreementtype !== "Club"
                  ? (clubObj[0].Amount - Number(clubObj[0].lateFee)).toFixed(2)
                  : 0
              ),
              ldwFeeAmount: clubObj[0].agrLdwAmount,
              clubPayment: Number(
                clubObj[0].Agreementtype === "Club"
                  ? Number(clubObj[0].Amount) - Number(clubObj[0].Taxamount)
                  : 0
              ),
              clubPaymentTax: Number(
                clubObj[0].Agreementtype === "Club" ? clubObj[0].Taxamount : 0
              ),
              creditOnAccountAdd: 0,
              creditOnAccountSub: Number(clubObj[0].COA),
              integratedPaymentSuspense: -1 * Number(clubObj[0].ipSuspense),
              initialPayment: Number(
                clubObj[0].Agreementtype !== "Club"
                  ? clubObj[0].number_of_paymentsmade === 0
                    ? 1
                    : 0
                  : 0
              ),
              netLateFee: 0,
              carriedLateFee: 0, //Number(PaygridObj[0].carriedLateFee),
              wlDeposit: -1 * Number(clubObj[0].wlDeposit),
              htSuspense: 0, //Number(PaygridObj[0].htSuspense),
              suspenseAdd: Number(clubObj[0].suspenseAdd),
              suspenseSub: Number(clubObj[0].suspenseSub), //Number(PaygridObj[0].suspense),
              racTirePay: Number(clubObj[0].policyAmount), //Number(PaygridObj[0].racTirePay),
              agreementId:
                clubObj[0].Agreementtype !== "Club"
                  ? Number(clubObj[0].Agreementid)
                  : undefined,
              change: 0,
              totalTax: clubObj[0].Taxamount,
              sac: Number(0),
              sacTax: Number(0),
              ldwFeeAmountTax: Number(clubObj[0].ldwTaxAmount),
              rentalPaymentTax: Number(
                clubObj[0].Agreementtype !== "Club"
                  ? clubObj[0].rpayTaxAmount
                  : 0
              ),
              lateRentalPaymentfeeTax: Number(clubObj[0].lFeeTaxAmount),
              lateRentalPaymentfee: Number(clubObj[0].lateFee),
              racTirePayTax: Number(clubObj[0].policyTaxAmount),
              deliveryFee: Number(clubObj[0].deliveryAmount),
              deliveryFeeTax: Number(clubObj[0].deliveryAmountTax),
              processingFee: Number(clubObj[0].processingFee),
              processingFeeTax: Number(clubObj[0].processingFeeTax),
              carryRentAmountUsed:
                clubObj[0].FreeTime == null
                  ? Number(clubObj[0].carryRentAmountUsed)
                  : 0,
              carryRentTaxUsed:
                clubObj[0].FreeTime == null
                  ? Number(clubObj[0].carryRentTaxUsed)
                  : 0,
              //otherFee: Number(PaygridObj[0].mattressFee),
              //otherFeeTax: Number(PaygridObj[0].mattressFeeTax),
              extensionAmount: Number(clubObj[0].extensionAmount),
              pif: Number(0),
              pifTax: Number(0),
              convenienceFee: 0,
              convenienceFeeTax: 0,
              mattressFee: Number(clubObj[0].mattressFee),
            };
            ReceiptCategoriesRequestParam.push(ReceiptArray);
          }

          if (
            objAmtDue[0].clubAmountLateRent !== undefined &&
            objAmtDue[0].clubAmountLateRent !== null &&
            objAmtDue[0].clubAmountLateRent !== ""
          ) {
            objAmount = objAmtDue[0].clubAmountLateRent;
          } else {
            objAmount = String(objAmtDue[0].clubAmountDue);
          }
          AcceptclubPaymentParam = {
            paymentSchedule: clubObj[0].Schedule,
            originalDueDate: clubObj[0].CurrentDueDate,
            nextDueDate: clubObj[0].Nextduedate,
            numberOfDaysLate: clubObj[0].CurrentDaysLate,
            clubTax: String(clubObj[0].Taxamount.toFixed(2)),
            clubAmountDue: Number(Number(clubObj[0].Amount).toFixed(2)),
            editSchedule: clubObj[0].editSchedule,
          };
          if (
            dataVal.length > 0 &&
            dataVal[0] === String(clubObj[0].Agreementid)
          ) {
            let checkBox = obj;
            checkBox.checked = true;
          }
        }

        if (dataVal.length > 0 && dataVal[0] === agreementId && paygridvalues.length) {
          let checkBox = obj;
          checkBox.checked = true;
          let PaygridObj = paygridvalues.filter(
            (x) => x.Agreementid == Number(agreementId)
          );
          if (
            PaygridObj[0].Agreementtype !== "Club" &&
            acceptPaymentParam.length === 0 &&
            PaygridObj[0].miscellaneousItemsNum == 0
          ) {
            let acceptPaymentArray = {
              extensionAmount: Number(PaygridObj[0].extensionAmount),
              noOfDaysFree: Number(PaygridObj[0].noOfDaysFree),
              initialPaymentFlag: Number(
                PaygridObj[0].number_of_paymentsmade === 0 ? 1 : 0
              ),
              addTransferSusFlag: 0,
              schedule: PaygridObj[0].Schedule,
              agreementId: Number(PaygridObj[0].Agreementid),
              ldwFeeAmount: Number(PaygridObj[0].agrLdwAmount), // Added By Me
              agreementAmountDue: Number(
                fixedChange(
                  String(
                    Number(PaygridObj[0].Amount) +
                      (Number(PaygridObj[0].acceptEpoFlag) == 1
                        ? Number(PaygridObj[0].suspenseSub)
                        : 0)
                  )
                )
              ),
              remainingEpoAmount: Number(
                PaygridObj[0].EPOAmount == null
                  ? 0.0
                  : (Number(PaygridObj[0].EPOAmount - PaygridObj[0].EPOTax) < 0
                      ? 0
                      : PaygridObj[0].EPOAmount - PaygridObj[0].EPOTax
                    ).toFixed(2)
              ),
              promoFreeDays: Number(PaygridObj[0].PromoFreeDaysApplied),
              netLateFee: Number(PaygridObj[0].lFeeTaxAmount),
              carriedLateFee: 0, //Number(PaygridObj[0].carriedLateFee),
              numberOfDaysLate: PaygridObj[0].CurrentDaysLate,
              originalDueDate: PaygridObj[0].CurrentDueDate,
              nextDueDate: PaygridObj[0].Nextduedate,
              totalTax: Number(PaygridObj[0].Taxamount.toFixed(2)),
              policyAmount: Number(Number(PaygridObj[0].policyAmount)),
              deliveryFeeAmount: Number(PaygridObj[0].deliveryAmount),
              processingFeeAmount: Number(PaygridObj[0].processingFee),
              otherFeeAmount: Number(PaygridObj[0].mattressFee),
              editSchedule: PaygridObj[0].editSchedule,
              freeTime: PaygridObj[0].FreeTime, // Added By Me
              ldwActivate: undefined,
            };
            acceptPaymentParam.push(acceptPaymentArray);
          }
          checkBox.disabled = true;

          let objAPEnrollDate = PaygridObj[0].Autopay_enrollmentdate;
          let APUP = {
            IsAP: false,
            IsUP: false,
          };
          if (
            objAPEnrollDate !== "" &&
            objAPEnrollDate !== undefined &&
            objAPEnrollDate !== null
          ) {
            APUP.IsAP = false;
            APUP.IsUP = true;
          } else {
            APUP.IsAP = true;
            APUP.IsUP = false;
          }
          // else {
          //   obj.disabled = false;
          // }
        }

        //  else {
        //   obj.disabled = false;
        // }
        if (paygridvalues != undefined && paygridvalues.length > 0) {
          // if(paygridvalues.l)
          if (
            clubCheckDisabled &&
            dataVal[0] === paygridvalues[0].AgreementNumber
          ) {
            let checkBox = obj;
            checkBox.disabled = true;
          } else if (
            clubCheckDisabled != true &&
            dataVal[0] === paygridvalues[0].AgreementNumber
          ) {
            let checkBox = obj;
            checkBox.disabled = false;
          }
          if(paygridvalues[index]?.isExchanged){
            let checkBox = obj;
            checkBox.disabled = true;
            checkBox.checked = false;
          }
        }
      });
    }
  };

  const Bindremainingamount = () => {
    if (paygridvalues !== undefined && paygridvalues.length > 0) {
      if (agreementId !== "0") {
        let pagGridFilters = paygridvalues.filter(
          (x) => x.Agreementid == Number(agreementId)
        );
        console.log('Paygrid Values ====>', pagGridFilters, paygridvalues, 'Here is Agreement Id ====>',agreementId);
        const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
          return e.sustype == "Add" && Number(e.newsuspense) > 0;
        });
        const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
          return e.sustype == "Used" && Number(e.newsuspense) > 0;
        });
        let addSusTotalAmount =
          addSuspenseAmount.length == 0
            ? 0.0
            : addSuspenseAmount
                .map((bill) => bill.newsuspense)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
        let usedSusTotalAmount =
          useSuspenseAmount.length == 0
            ? 0.0
            : useSuspenseAmount
                .map((bill) => bill.newsuspense)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

        let initialAgree = [];
        let initialClub:any = [];
        let resultSelecteAgre: any = [];
        if (Number(agreementId) != 0) {
          initialAgree = paygridvalues.filter(function (e) {
            return String(e.Agreementid) == String(agreementId);
          });
          initialClub = paygridvalues.filter(function (e) {
            return (
              String(e.Agreementtype) == "Club" && e.number_of_paymentsmade == 0
            );
          });
          resultSelecteAgre = [...initialAgree, ...initialClub];
        } else {
          resultSelecteAgre = paygridvalues.filter(function (e) {
            return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
          });
        }

        let amount = 0;
        for (let i = 0; i < resultSelecteAgre.length; i++) {
          amount = amount + Number(resultSelecteAgre[i].Amount);
        }
        let objAvailCreds = 0;
        if (Number(amount) <= Number(paygridvalues[0].wlDeposit)) {
          objAvailCreds = amount;
          objAvailCreds =
            diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreds;
          setAvailableCreds(objAvailCreds);
          if (diffStoreErrLbl && isRevenueRecogActivated) {
            setavailableCredsremaining([]);
          } else {
            setavailableCredsremaining([
              {
                balanceType: "WEBLEAD",
                accountBalance: Number(amount).toFixed(2),
              },
            ]);
          }
        } else if (Number(amount) >= Number(paygridvalues[0].wlDeposit)) {
          objAvailCreds =
            Number(paygridvalues[0].COA) +
            Number(paygridvalues[0].wlDeposit) +
            Number(
              (Number(amount) - Number (paygridvalues[0].wlDeposit) 
              > Number(paygridvalues[0].ipSuspense) 
              ? Number(paygridvalues[0].ipSuspense) : 
              (Number(amount) - Number (paygridvalues[0].wlDeposit))));
          objAvailCreds =
            diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreds;
          setAvailableCreds(objAvailCreds);
          if (diffStoreErrLbl && isRevenueRecogActivated) {
            setavailableCredsremaining([]);
          } else {
            setavailableCredsremaining([
              {
                balanceType: "WEBLEAD",
                accountBalance: Number(paygridvalues[0].wlDeposit).toFixed(2),
              },
              {
                balanceType: "COA",
                accountBalance: Number(paygridvalues[0].COA).toFixed(2),
              },
              {
                balanceType: "IP Suspence",
                accountBalance: Number((Number(amount) - Number (paygridvalues[0].wlDeposit) 
                > Number(paygridvalues[0].ipSuspense) 
                ? Number(paygridvalues[0].ipSuspense) : 
                (Number(amount) - Number (paygridvalues[0].wlDeposit)))).toFixed(2),
              },
            ]);
          }
        } else if (Number(amount) == 0) {
          objAvailCreds =
            Number(paygridvalues[0].COA) 
            // + Number(paygridvalues[0].ipSuspense);
          objAvailCreds =
            diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreds;
          setAvailableCreds(objAvailCreds);
          if (diffStoreErrLbl && isRevenueRecogActivated) {
            setavailableCredsremaining([]);
          } else {
            setavailableCredsremaining([
              {
                balanceType: "COA",
                accountBalance: Number(paygridvalues[0].COA).toFixed(2),
              },
              // {
              //   balanceType: "IP Suspence",
              //   accountBalance: Number(paygridvalues[0].ipSuspense).toFixed(2),
              // },
            ]);
          }
        }
        setRemaniningAmtStatic(
          Number((pagGridFilters[0]?.Amount - objAvailCreds).toFixed(2))
        );
        setcoaamt(pagGridFilters[0]?.COA);
        setwldepositamount(pagGridFilters[0]?.wlDeposit);
        setipsuspense(pagGridFilters[0]?.ipSuspense);
        let chargeCardAmount = 0;
        let swipeCardAmount = 0;
        for (let i = 0; i < tendertypeglobalarray.length; i++) {
          if (
            tendertypeglobalarray[i].tendertype
              .split(" - ")[0]
              .includes("Card Ending with")
          ) {
            chargeCardAmount =
              chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
          }
        }
        for (let i = 0; i < tendertypeglobalarray.length; i++) {
          if (
            tendertypeglobalarray[i].tendertype
              .split(" - ")[0]
              .includes("Card Swipe ending")
          ) {
            swipeCardAmount =
              swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
          }
        }
        // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
        let CardAmount = Number(
          multipleCard.length == 0
            ? 0.0
            : multipleCard
                .map((bill) => bill.Amount)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
        );
        CardAmount =
          Number(CardAmount) +
          Number(chargeCardAmount) +
          Number(swipeCardAmount);
        let objTenderAmount =
          CardAmount + Number(addcash) + Number(totalamountcheck);
        let objpgGridAmount =
          Number(pagGridFilters[0]?.Amount) -
          objAvailCreds +
          Number(donationAmountAdd)+
          Number(addSusTotalAmount) -
          Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount);
        if (pagGridFilters !== undefined && pagGridFilters?.length > 0) {

          if (Number(remainingamountdue) === 0 && objTenderAmount === 0) {
            setremainingamountdue(objpgGridAmount.toFixed(2));
            if (Number(objpgGridAmount.toFixed(2)) <= 0) {
              setAcceptpaymentbuttonenable(false);
              if (Number(objpgGridAmount.toFixed(2)) < 0) {
                setchange(-1 * Number(objpgGridAmount.toFixed(2)));
              } else {
                setchange("0.00");
              }
            } else {
              setchange("0.00");
              setAcceptpaymentbuttonenable(true);
            }
          } else if (objTenderAmount >= objpgGridAmount) {
            setremainingamountdue("0.00");
          } else if (objTenderAmount < objpgGridAmount) {
            setremainingamountdue(
              (objpgGridAmount - objTenderAmount).toFixed(2)
            );
          }
        } else {
          setremainingamountdue("0.00");
        }
        settotaldue(parseFloat(String(pagGridFilters[0]?.Amount)));
        settotaltax(parseFloat(String(pagGridFilters[0]?.Taxamount)));
        setinitagreementnumber(pagGridFilters[0]?.AgreementNumber);
        //AutoLoadAct_btn();
        if (totalduereceiptidsupport.length == 0) {
          let agreementDetails = {
            agreement_id:
              pagGridFilters[0]?.AgreementNumber == "Adjust"
                ? "Miscellaneous Item "
                : pagGridFilters[0]?.AgreementNumber,
            agreement_totalamount: parseFloat(
              fixedChange(String(objpgGridAmount))
            ),
          };
          totalduereceiptidsupport.push(agreementDetails);
          if(initialClub?.length>0){
            let clubDetails = {
              agreement_id:
              initialClub[0].AgreementNumber,
              agreement_totalamount: parseFloat(
                fixedChange(String(initialClub[0].Amount))
              ),
            };
            totalduereceiptidsupport.push(clubDetails);
          }

          settotalduereceiptid(totalduereceiptidsupport);
        }
      }
      let pagGridFilters:any = [];      
      if(pageLoadedInitial) {
        pagGridFilters = paygridvalues.filter(
          (x) => x.Agreementtype == "Club"
        );
      } else {
        pagGridFilters = checkedAgr.filter(
          (x) => x.Agreementtype == "Club"
        );
      }
      let objAmtDue;
      if (
        pagGridFilters[0] != undefined &&
        pagGridFilters[0]?.Agreementtype === "Club"
      ) {
        objAmtDue = getpayment?.amountDue.amountDueResponse.filter(
          (x) => x.clubAmountDue !== undefined
        );
      }
      if (
        pagGridFilters[0] != undefined &&
        objAmtDue[0].initialPaymentFlag == 1 &&
        agreementId != "0"
      ) {
        let agreementFilterAmt = paygridvalues.filter(
          (x) => x.Agreementid == Number(agreementId)
        );
        const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
          return e.sustype == "Add" && Number(e.newsuspense) > 0;
        });
        const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
          return e.sustype == "Used" && Number(e.newsuspense) > 0;
        });
        let addSusTotalAmount =
          addSuspenseAmount.length == 0
            ? 0.0
            : addSuspenseAmount
                .map((bill) => bill.newsuspense)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
        let usedSusTotalAmount =
          useSuspenseAmount.length == 0
            ? 0.0
            : useSuspenseAmount
                .map((bill) => bill.newsuspense)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

        let initialAgree = [];
        let initialClub = [];
        let resultSelecteAgre: any = [];
        if (Number(agreementId) != 0) {
          initialAgree = paygridvalues.filter(function (e) {
            return String(e.Agreementid) == String(agreementId);
          });
          initialClub = paygridvalues.filter(function (e) {
            return (
              String(e.Agreementtype) == "Club" && e.number_of_paymentsmade == 0
            );
          });
          resultSelecteAgre = [...initialAgree, ...initialClub];
        } else {
          resultSelecteAgre = paygridvalues.filter(function (e) {
            return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
          });
        }
        let amount = 0;
        for (let i = 0; i < resultSelecteAgre.length; i++) {
          amount = amount + Number(resultSelecteAgre[i].Amount);
        }
        let objAvailCreds = 0;
        if (Number(amount) <= Number(paygridvalues[0].wlDeposit)) {
          objAvailCreds = amount;
          objAvailCreds =
            diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreds;
          setAvailableCreds(objAvailCreds);
          if (diffStoreErrLbl && isRevenueRecogActivated) {
            setavailableCredsremaining([]);
          } else {
            setavailableCredsremaining([
              {
                balanceType: "WEBLEAD",
                accountBalance: Number(amount).toFixed(2),
              },
            ]);
          }
        } else if (Number(amount) > Number(paygridvalues[0].wlDeposit)) {
          objAvailCreds =
            Number(paygridvalues[0].COA) +
            Number(paygridvalues[0].wlDeposit) +
            Number(
              (Number(amount) - Number (paygridvalues[0].wlDeposit) 
              > Number(paygridvalues[0].ipSuspense) 
              ? Number(paygridvalues[0].ipSuspense) : 
              (Number(amount) - Number (paygridvalues[0].wlDeposit))));
          objAvailCreds =
            diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreds;
          setAvailableCreds(objAvailCreds);
          if (diffStoreErrLbl && isRevenueRecogActivated) {
            setavailableCredsremaining([]);
          } else {
            setavailableCredsremaining([
              {
                balanceType: "WEBLEAD",
                accountBalance: Number(paygridvalues[0].wlDeposit).toFixed(2),
              },
              {
                balanceType: "COA",
                accountBalance: Number(paygridvalues[0].COA).toFixed(2),
              },
              {
                balanceType: "IP Suspence",
                accountBalance: Number((Number(amount) - Number (paygridvalues[0].wlDeposit) 
                > Number(paygridvalues[0].ipSuspense) 
                ? Number(paygridvalues[0].ipSuspense) : 
                (Number(amount) - Number (paygridvalues[0].wlDeposit)))).toFixed(2),
              },
            ]);
          }
        } else if (Number(amount) == 0) {
          objAvailCreds =
            Number(paygridvalues[0].COA)
            //  + Number(paygridvalues[0].ipSuspense);
          objAvailCreds =
            diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreds;
          setAvailableCreds(objAvailCreds);
          if (diffStoreErrLbl && isRevenueRecogActivated) {
            setavailableCredsremaining([]);
          } else {
            setavailableCredsremaining([
              {
                balanceType: "COA",
                accountBalance: Number(paygridvalues[0].COA).toFixed(2),
              },
              // {
              //   balanceType: "IP Suspence",
              //   accountBalance: Number(paygridvalues[0].ipSuspense).toFixed(2),
              // },
            ]);
          }
        }
        setRemaniningAmtStatic(
          Number(
            (
              pagGridFilters[0]?.Amount +
              agreementFilterAmt[0].Amount -
              objAvailCreds+
              Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
            ).toFixed(2)
          )
        );
        setcoaamt(agreementFilterAmt[0].COA);
        setwldepositamount(agreementFilterAmt[0].wlDeposit);
        setipsuspense(agreementFilterAmt[0].ipSuspense);
        let chargeCardAmount = 0;
        let swipeCardAmount = 0;
        for (let i = 0; i < tendertypeglobalarray.length; i++) {
          if (
            tendertypeglobalarray[i].tendertype
              .split(" - ")[0]
              .includes("Card Ending with")
          ) {
            chargeCardAmount =
              chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
          }
        }
        for (let i = 0; i < tendertypeglobalarray.length; i++) {
          if (
            tendertypeglobalarray[i].tendertype
              .split(" - ")[0]
              .includes("Card Swipe ending")
          ) {
            swipeCardAmount =
              swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
          }
        }
        // chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
        let CardAmount = Number(
          multipleCard.length == 0
            ? 0.0
            : multipleCard
                .map((bill) => bill.Amount)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
        );
        CardAmount =
          Number(CardAmount) +
          Number(chargeCardAmount) +
          Number(swipeCardAmount);
        let objTenderAmount =
          CardAmount + Number(addcash) + Number(totalamountcheck);
        let objpgGridAmount =
          Number(pagGridFilters[0]?.Amount) +
          Number(agreementFilterAmt[0].Amount) -
          objAvailCreds+
          Number(donationAmountAdd) +
          Number(addSusTotalAmount) -
          Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount);
        let overallAmtForBind =
          Number(pagGridFilters[0]?.Amount) +
          Number(agreementFilterAmt[0].Amount);
        let overallTaxAmtForBind =
          Number(pagGridFilters[0]?.Taxamount) +
          Number(agreementFilterAmt[0].Taxamount);
        if (pagGridFilters !== undefined && pagGridFilters.length > 0) {
          if (Number(remainingamountdue) === 0 && objTenderAmount === 0) {
            setremainingamountdue(objpgGridAmount.toFixed(2));
            if (Number(objpgGridAmount.toFixed(2)) <= 0) {
              setAcceptpaymentbuttonenable(false);
              if (Number(objpgGridAmount.toFixed(2)) < 0) {
                setchange(-1 * Number(objpgGridAmount.toFixed(2)));
              } else {
                setchange("0.00");
              }
            } else {
              setchange("0.00");
              setAcceptpaymentbuttonenable(true);
            }
          } else if (objTenderAmount >= objpgGridAmount) {
            setremainingamountdue("0.00");
          } else if (objTenderAmount < objpgGridAmount) {
            setremainingamountdue(
              (objpgGridAmount - objTenderAmount).toFixed(2)
            );
          }
        } else {
          setremainingamountdue("0.00");
        }
        settotaldue(parseFloat(String(overallAmtForBind)));
        settotaltax(parseFloat(String(overallTaxAmtForBind)));
        //AutoLoadAct_btn();
        // let agreementDetails = {
        //   agreement_id:
        //     pagGridFilters[0].AgreementNumber == "Adjust"
        //       ? "Miscellaneous Item " + pagGridFilters[0].miscellaneousItemsNum
        //       : pagGridFilters[0].AgreementNumber,
        //   agreement_totalamount: parseFloat(
        //     fixedChange(String(pagGridFilters[0].Amount))
        //   ),
        // };
        // totalduereceiptidsupport.push(agreementDetails);
        settotalduereceiptid(totalduereceiptidsupport);
        if (paygridvalues.length == 2) {
          setOverAll_Chk(true);
        }
      }
    } else {
      setremainingamountdue("0.00");
    }
  };
  const handleOnClick = () => {};
  const Agreementtypebinding = (agrtype: any) => {
    if (agrtype === "Rental Agreement (RTO)") {
      return "Rent";
    } else {
      return agrtype;
    }
  };
  /*
  {
    BindGrid() method is used to bind the Agreements grid.
    Here the map function is used for the paygrid values array which consist of
    array objects of objects is used. gridhide state value is used to hide or unhide the
    data according to the accordion click.
  }
  */

  const BindGrid = () => (
    <>
      {paygridvalues != undefined && paygridvalues.length > 0 ? (
        paygridvalues.map((value, index: any) => {
          if (
            storeNumber !== value.store_number &&
            value.Agreementtype !== "Club" &&
            String(value.store_number) != "0"
          ) {
            storeNumber = value.store_number;
            newStore = true;
          } else {
            newStore = false;
          }
          const isAPflagPresent: any = paygridvalues.findIndex(
            (el: any, i: any) => {
              if (
                el.Autopay_enrollmentdate !== "" &&
                el.Autopay_enrollmentdate !== undefined &&
                el.Autopay_enrollmentdate !== "null" &&
                el.Autopay_enrollmentdate !== null
              ) {
                return i;
              }
            }
          );
          return (
            <React.Fragment key={index}>
              {
                /*value.Agreementtype!=='Club' This condition is added because the club agreement should not come under any store-Mano Ranjith */
                newStore === true &&
                Number(configFlags.isPaymentFunctionsFeatureFlag) == 1 ? (
                  <RACTableRow
                    className={` ${classes.rowColor} ${classes.font12}`}
                  >
                    <RACTableCell className={` ${classes.paymentStore}`}>
                      {value.store_number}
                    </RACTableCell>
                    <RACTableCell
                      colSpan={9}
                      className={` ${classes.paymentStore}`}
                    >
                      {"- " +
                        value.storeCityName +
                        ", " +
                        value.stateProvinceName}
                    </RACTableCell>
                  </RACTableRow>
                ) : null
              }
              <RACTableRow className={` ${classes.rowColor} ${classes.font12}`}>
                <RACTableCell
                  className={` ${classes.checkboxwidth} ${classes.font12}`}
                >
                  <input
                    style={{ marginTop: "6px", marginLeft: "19px" }}
                    type="checkbox"
                    name="grid"
                    id={"chk" + index}
                    onChange={(e) => {
                      settotaldueamount(
                        "chk" + index,
                        index,
                        value.Amount,
                        value.Agreementid,
                        e
                      );
                    }}
                    value={value.Agreementid + "|" + value.Amount}
                    data-testid={"subcheckbox" + index}
                  />
                  {/* {clubCheckDisabled == true && index==0 ?
                      <input
                        style={{ marginTop: '6px', marginLeft: '19px' }}
                        type="checkbox"
                        name="grid"
                        disabled
                        id={"chk" + index}
                        onChange={(e) => {
                          settotaldueamount(
                            "chk" + index,
                            index,
                            value.Amount,
                            value.Agreementid,
                            e
                          );
                        }}
                        value={value.Agreementid + "|" + value.Amount}
                        data-testid={"subcheckbox" + index}
                      />
                    : } */}
                </RACTableCell>
                <RACTableCell className={`${classes.font12}`}>
                  {value.Agreementtype !== "Club"&& value.Agreementtype!=="NSF Check" &&value.Agreementtype!=="Credit Card Charge Back" && value.Agreementtype!=="Restitution" ? (
                    <span
                      onClick={() => {
                        value.miscellaneousItemsNum == 0
                          ? value.Agreementtype == "Back Rent"
                            ? redirectionToBackRent()
                            :value.Agreementtype == "Rental Item Sale"?
                             risRedirectionToInventory(String(value.AgreementNumber),value)
                             : redirectionToAgreement(
                                String(value.Agreementid),
                                value
                              )
                          : redirectionToCashSale(value.miscellaneousItemsNum);
                      }}
                      className={
                        value.CurrentDaysLate === 0
                          ? `${classes.fontchange} ${classes.racpadlink} ${classes.racpadlink} ${classes.racpadpaymentsuccess} ${classes.racpadagrlink} ${classes.Aghover}`
                          : `${classes.fontchange} ${classes.racpadlink} ${classes.racpadagrlink} ${classes.racpadpaymentfails} ${classes.Aghover}`
                      }
                    >
                      {value.AgreementNumber}
                      {value.Autopay_enrollmentdate !== "" &&
                      value.Autopay_enrollmentdate !== undefined &&
                      value.Autopay_enrollmentdate !== "null" &&
                      value.Autopay_enrollmentdate !== null ? (
                          <span className={classes.badgeContainer} title={value.Autopay == "null" || value.Autopay == null
                            ? ""
                            : "Card ending with " + value.Autopay}>AP</span>
                      ) : null}
                    </span>
                  ) : (
                    <span
                      className={
                        value.CurrentDaysLate === 0
                          ? `${classes.fontchange} ${classes.racpadlink} ${classes.racpadlink} ${classes.racpadpaymentsuccess} ${classes.racpadagrlink} ${classes.nonLinkText}`
                          : `${classes.fontchange} ${classes.racpadlink} ${classes.racpadagrlink} ${classes.racpadpaymentfails} ${classes.nonLinkText}`
                      }
                    >
                      {value.AgreementNumber}
                      {value.Autopay_enrollmentdate !== "" &&
                      value.Autopay_enrollmentdate !== undefined &&
                      value.Autopay_enrollmentdate !== "null" &&
                      value.Autopay_enrollmentdate !== null ? (
                            <span className={classes.badgeContainer} title={value.Autopay == "null" || value.Autopay == null
                              ? ""
                              : "Card ending with " + value.Autopay}>AP</span>
                      ) : null}
                    </span>
                  )}
                </RACTableCell>
                <RACTableCell className={`${classes.font12}`}>
                  {Agreementtypebinding(value.Agreementtype)}
                </RACTableCell>
                <RACTableCell
                  className={`${classes.font12}`}
                  title={tooltipdesdisplay}
                  onMouseOver={() => {
                    descriptiontooltip(value.Agreementdesc);
                  }}
                  data-testid={"maingridtooltipdisplay" + index}
                >
                  {isAPflagPresent !== -1
                    ? value.Agreementdesc.substring(0, 10) + "..."
                    : value.Agreementtype !== "Club"
                    ? value.Agreementdesc.substring(0, 25) + "..."
                    : value.Agreementdesc}
                  {/* {value.Autopay_enrollmentdate !== "" &&
                      value.Autopay_enrollmentdate !== undefined &&
                      value.Autopay_enrollmentdate !== "null" &&
                      value.Autopay_enrollmentdate !== null && value.Agreementtype !== "Club"  ? value.Agreementdesc.substring(0, 10)+"...":value.Agreementtype !== "Club"
                      ? value.Agreementdesc.substring(0, 25) + "..."
                      : value.Agreementdesc} */}
                  {/* {value.Agreementtype !== "Club"
                    ? value.Agreementdesc.substring(0, 25) + "..."
                    : value.Agreementdesc} */}
                </RACTableCell>
                <RACTableCell className={`${classes.font12}`}>
                  {value.Schedule}
                </RACTableCell>
                <RACTableCell className={`${classes.font12}`}>
                  {changeDateformat(value.CurrentDueDate)}
                </RACTableCell>
                <RACTableCell
                  className={
                    value.dueDateModified == true
                      ? `${classes.modifiedGrid} ${classes.font12}`
                      : `${classes.font12}`
                  }
                >
                  {
                  value?.isExchanged == 1 ?
                  <span title='Exchange In Progress'style={{color: '#fcd209'}}>Exchange In Pr...</span>
                  : value.Nextduedate !== "PIF" &&
                  value.Nextduedate !== "SAC" &&
                  value.Nextduedate !== "EPO"
                    ? changeDateformat(value.Nextduedate)
                    : value.Nextduedate}
                </RACTableCell>
                <RACTableCell
                  className={`${classes.textend} ${classes.font12}`}
                >
                  {value.CurrentDaysLate}
                </RACTableCell>
                <RACTableCell
                  className={`${classes.textend} ${classes.font12} ${classes.amounttxtstyle}`}
                >
                  <span>$</span>
                  {Number.isNaN(Number(value.Amount))
                    ? 0.0
                    : Number(value.Amount) +
                        Number(value.suspenseAdd) -
                        (value.suspenseSub ? Number(value.suspenseSub) : 0) <
                      0
                    ? "(" +
                      (
                        -1 *
                        (Number(value.Amount) +
                          Number(value.suspenseAdd) -
                          (value.suspenseSub ? Number(value.suspenseSub) : 0))
                      ).toFixed(2) +
                      ")"
                    : (
                        Number(value.Amount) +
                        Number(value.suspenseAdd) -
                        (value.suspenseSub ? Number(value.suspenseSub) : 0)
                      ).toFixed(2)}
                </RACTableCell>

                {/* Exchange Indication */}
                {isRetentionEnabled || isExchangeEnabled ? (
                  <RACTableCell className={isExchangedAgreements.includes(value.Agreementid) && isExchangeFuncEnabled ? `${classesForExchange.textAlignCenter} ${classesForExchange.noWrap} ${classesForExchange.wrapContentSA} ${classesForExchange.positionRelative} ${classesForExchange.pointerEvents}` : `${classesForExchange.textAlignCenter} ${classesForExchange.noWrap} ${classesForExchange.wrapContentSA} ${classesForExchange.positionRelative}`}>
                    {
                      isExchangeFuncEnabled && isExchangedAgreements.includes(value.Agreementid) ?
                        <Grid className={isExchangedAgreements.includes(value.Agreementid) && isExchangeFuncEnabled ? `${classesForExchange.overlay}` : ''}>

                        </Grid>
                        : null
                    }
                    {
                      (validateForExchange(isExchangeEnabled, value?.agreementStatus, null, value?.CurrentDueDate ? moment(value.CurrentDueDate, 'MM/DD/YYYY').format('YYYY-MM-DD') : null, value?.openDate ? value?.openDate : null, value?.employeePurchasePlan, value?.Agreementtype, exchangePeriod, returnPeriod) && value.store_number == String(window.sessionStorage.getItem("storeNumber"))) ? (
                        <>
                          <RACTooltip placement={!value?.confirmeddeliversystatus ? 'top' : 'top-start'} title={<span className={`${classesForExchange.fontWidth}`}>{!value?.confirmeddeliversystatus ? `Please confirm delivery to initiate an exchange` : `Exchange`}</span>} classes={{ tooltip: classesForExchange.tooltipcss1, arrow: classesForExchange.tooltipStyling1 }}>
                            <Grid className={!value?.confirmeddeliversystatus ? `${classesForExchange.positionRelative}` : ''}>
                              {
                                !value?.confirmeddeliversystatus ?
                                  <Grid className={!value?.confirmeddeliversystatus ? `${classesForExchange.overlay2}` : ''}>
                                  </Grid> : null
                              }
                              <ExchangeIcon className={isRetentionEnabled &&
                                value.Agreementtype !== "Club" &&
                                value.Agreementtype !== 'NSF Check' &&
                                value.Agreementtype !== 'Credit Card Charge Back' &&
                                value.Agreementtype !== 'Restitution' &&
                                value.Agreementtype !== 'Back Rent' &&
                                value.Agreementtype !== 'Promo' &&
                                value.Agreementtype !== 'Xbox Gift Card' &&
                                value.Agreementtype !== 'Xbox Gift Membership' &&
                                value.Agreementtype !== 'Non Inventory Sale' &&
                                value.Agreementtype !== 'Rental Item Sale' &&
                                value.agreementRateDetails?.employeePurchasePlan !== 'Y' &&
                                value.initialPaymentFlag !== 1 &&
                                value.store_number ==
                                String(window.sessionStorage.getItem("storeNumber")) ? `${classesForExchange.iconWidth1} ${classesForExchange.pointer} ${classesForExchange.mr5}` : `${classesForExchange.iconWidth1} ${classesForExchange.pointer}`}
                                onClick={() => {//RAC2
                                  if (isExchangeFuncEnabled) {
                                    setExchangeSelected(true);
                                    setOpenSLA(true);
                                    setChoosenAgreementID(value.Agreementid);
                                    setRentPaid(value?.agreementRateDetails?.rentPaid);
                                    setExchangeSuspenseAmount(value?.SuspenseAmount);
                                    setCurrentExchangeAgreementNumber(value?.AgreementNumber);
                                  }
                                }}
                              />
                            </Grid>
                          </RACTooltip>
                        </>
                      ) : null
                    }
                    {isRetentionEnabled &&
                    value.Agreementtype !== "Club" &&
                    value.Agreementtype !== 'NSF Check' &&
                    value.Agreementtype !== 'Credit Card Charge Back' &&
                    value.Agreementtype !== 'Restitution' &&
                    value.Agreementtype !== 'Back Rent' &&
                    value.Agreementtype !== 'Promo' &&
                    value.Agreementtype !== 'Xbox Gift Card' &&
                    value.Agreementtype !== 'Xbox Gift Membership' &&
                    value.Agreementtype !== 'Non Inventory Sale' &&
                    value.Agreementtype !== 'Rental Item Sale' &&
                    value.agreementRateDetails?.employeePurchasePlan !== 'Y' &&
                    value.initialPaymentFlag !== 1 &&
                    value.store_number ==
                      String(window.sessionStorage.getItem("storeNumber")) ? (
                        <>
                          <RACTooltip placement={'top-start'} title={<span className={`${classesForExchange.fontWidth}`}>Retention</span>} classes={{ tooltip: classesForExchange.tooltipcss1, arrow: classesForExchange.tooltipStyling1 }}>
                            <RetentionIcon className={`${classesForExchange.iconWidth2} ${classesForExchange.pointer}`} onClick={() => {
                              setOpenSLA(true);
                              setChoosenAgreementNumber(value.AgreementNumber);
                              setChoosenAgreementID(value.Agreementid);
                            }} />
                          </RACTooltip>
                        </>
                    ) : null}
                    {
                      !(isRetentionEnabled &&
                      value.Agreementtype !== "Club" &&
                      value.Agreementtype !== 'NSF Check' &&
                      value.Agreementtype !== 'Credit Card Charge Back' &&
                      value.Agreementtype !== 'Restitution' &&
                      value.Agreementtype !== 'Back Rent' &&
                      value.Agreementtype !== 'Promo' &&
                      value.Agreementtype !== 'Xbox Gift Card' &&
                      value.Agreementtype !== 'Xbox Gift Membership' &&
                      value.Agreementtype !== 'Non Inventory Sale' &&
                      value.Agreementtype !== 'Rental Item Sale' &&
                      value.agreementRateDetails?.employeePurchasePlan !== 'Y' &&
                      value.initialPaymentFlag !== 1 &&
                      value.store_number ==
                        String(window.sessionStorage.getItem("storeNumber"))) && 
                      !(validateForExchange(isExchangeEnabled, value?.agreementStatus, null, value?.CurrentDueDate ? moment(value.CurrentDueDate, 'MM/DD/YYYY').format('YYYY-MM-DD') : null, value?.openDate ? value?.openDate : null, value?.employeePurchasePlan, value?.Agreementtype, exchangePeriod, returnPeriod) && 
                      value.store_number == String(window.sessionStorage.getItem("storeNumber"))) ? 
                      <span style={{display:"flex",justifyContent:'center', width: '100%'}}>-</span> : null
                    }
                  </RACTableCell>
                ) : null}

                <RACTableCell
                  data-bs-toggle="collapse"
                  data-bs-target="#row-1"
                  //className={`${classes.accordiontoggle} ${classes.font12}`}
                  aria-expanded={gridhide == index ? true : false}
                >
                  {gridhide === index ? (
                    <img
                      className={`${classes.accordionIconGrid} ${classes.me2}`}
                      id={index + "t1"}
                      src={AccordianOpenIcon}
                      onClick={() => Accordionclick(index)}
                    />
                  ) : (
                    <img
                      className={`${classes.accordionIconGrid} ${classes.me2}`}
                      id={index + "t1"}
                      src={AccordianCloseIcon}
                      onClick={() => Accordionclick(index)}
                    />
                  )}
                </RACTableCell>
              </RACTableRow>
              {BindHiddenRow(value, index)}
            </React.Fragment>
          );
        })
      ) : (
        
        payGridPayloadLoad==false? <RACTableRow className={classes.hiddenRow} key="0">
          <RACTableCell colSpan={9} className={classes.textcenter}>
            No Records Found
          </RACTableCell>
        </RACTableRow>:<RACTableCell colSpan={9} className={classes.textcenter}></RACTableCell>
      )}
    </>
  );

  const BindHiddenRow = (value, index: any) => {
    if (gridhide === index) {
      return (
        <RACTableRow data-testid="gridhidediv">
          <RACTableCell
            colSpan={10}
            className={`${classes.hiddenRow} ${classes.p0}`}
          >
            <Grid
              item
              className={`${classes.backgroundcol} ${classes.floatLeft} ${classes.pb0} ${classes.px5} ${classes.py2} ${classes.w100}`}
              id="row-2"
            >
              <Grid
                item
                className={`${classes.raccollg2} ${classes.floatLeft}`}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  SAC Date
                </Box>
                <Box
                  className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                >
                  {value.SAC_Date == "-" || value.Agreementtype == 'Promo'
                    ? "-"
                    : changeDateformat(value.SAC_Date)}
                </Box>
              </Grid>
              <Grid
                item
                className={`${classes.raccollg2} ${classes.floatLeft}`}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Suspense Amount
                </Box>
                <Box
                  component="span"
                  className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                >
                  ${value.SuspenseAmount}
                </Box>
              </Grid>
              <Grid
                item
                className={`${classes.raccollg2} ${classes.floatLeft}`}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  EPO Amount
                </Box>
                {value.agreementRateDetails?.employeePurchasePlan === "Y" ? (
                  <Box
                    component="span"
                    className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                  >
                    -
                  </Box>
                ) : (
                  <Box
                    component="span"
                    className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                  >
                    ${Number(fixedChange(String(Number(value.EPOAmount))))}
                  </Box>
                )}
              </Grid>
              <Grid
                item
                className={`${classes.raccollg2} ${classes.floatLeft}`}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  #Late last 10
                </Box>
                <Box
                  component="span"
                  className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                >
                  {value.Latelast10}
                </Box>
              </Grid>
              <Grid
                item
                className={`${classes.raccollg2} ${classes.floatLeft}`}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Promo Free Days
                </Box>
                <Box
                  component="span"
                  className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                >
                  {value.PromoFreeDays}
                </Box>
              </Grid>
              <Grid
                item
                className={`${classes.raccollg2} ${classes.floatLeft}`}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Autopay
                </Box>
                {value.Autopay_enrollmentdate !== "" &&
                value.Autopay_enrollmentdate !== undefined &&
                value.Autopay_enrollmentdate !== "null" &&
                value.Autopay_enrollmentdate !== null &&
                value.Autopay !== undefined &&
                value.Autopay !== 0 &&
                value.Autopay !== null ? (
                  <Box
                    component="span"
                    className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                  >
                    {value.Autopay == "null" || value.Autopay == null
                      ? "-"
                      : "Card ending with " + value.Autopay}
                  </Box>
                ) : (
                  <Box
                    component="span"
                    className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                  >
                    -
                  </Box>
                )}
              </Grid>
              {/* RAC2 */}
              <Grid
                item
                className={`${classes.raccollg2} ${classes.floatLeft}`}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Rent Paid
                </Box>
                <Box
                  component="span"
                  className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                >
                  {value?.agreementRateDetails?.rentPaid ? `$${value?.agreementRateDetails?.rentPaid}` : '$0.00'}
                </Box>
              </Grid>
            </Grid>
          </RACTableCell>
        </RACTableRow>
      );
    } else {
      return null;
    }
  };
  const continueAMClicked = () => {
    let filteredNonClubData = checkedAgr.filter(
      (obj) => obj.Agreementtype !== "Club"
    );
    let filteredClubData = checkedAgr.filter(
      (obj) => obj.Agreementtype == "Club"
    );

    let agreementPayload: any = [];
    filteredNonClubData.map((obj, index) => {
      for (const [key, value] of Object.entries(obj)) {
        const isAgreementThere = agreementPayload.findIndex(
          (el) => Number(el.agreementId) === Number(obj.Agreementid)
        );
        if (key === "dueDateModified" && value === true&&obj.Nextduedate!='SAC'&&obj.Nextduedate!='EPO'&&obj.Nextduedate!='PIF') {
          if (isAgreementThere >= 0) {
            agreementPayload[isAgreementThere].transactions.push({
              type: "DDA",
              value: moment(obj.Nextduedate).format("YYYY-MM-DD"),
            });
          } else {
            agreementPayload.push({
              agreementId: obj.Agreementid,
              nextDueDate:
                obj.Nextduedate !== "EPO" &&
                obj.Nextduedate !== "PIF" &&
                obj.Nextduedate !== "SAC"
                  ? String(moment(obj.Nextduedate).format("YYYY-MM-DD"))
                  : null,
              currentDueDate: String(
                moment(obj.CurrentDueDate).format("YYYY-MM-DD")
              ),
              amount: (Number(obj.Amount) +
                Number(obj.suspenseAdd) -
                Number(obj.suspenseSub) <
              0
                ? 0.0
                : Number(obj.Amount) +
                  Number(obj.suspenseAdd) -
                  Number(obj.suspenseSub)
              ).toFixed(2),
              transactions: [
                {
                  type: "DDA",
                  value: moment(obj.Nextduedate).format("YYYY-MM-DD"),
                },
              ],
            });
          }
        } else if (key === "editSchedule" && value !== "") {
          if (isAgreementThere >= 0) {
            agreementPayload[isAgreementThere].transactions.push({
              type: "ES",
              value: obj.editSchedule,
            });
          } else {
            agreementPayload.push({
              agreementId: obj.Agreementid,
              nextDueDate:
                obj.Nextduedate !== "EPO" &&
                obj.Nextduedate !== "PIF" &&
                obj.Nextduedate !== "SAC"
                  ? String(moment(obj.Nextduedate).format("YYYY-MM-DD"))
                  : null,
              currentDueDate: String(
                moment(obj.CurrentDueDate).format("YYYY-MM-DD")
              ),
              amount: (Number(obj.Amount) +
                Number(obj.suspenseAdd) -
                Number(obj.suspenseSub) <
              0
                ? 0.0
                : Number(obj.Amount) +
                  Number(obj.suspenseAdd) -
                  Number(obj.suspenseSub)
              ).toFixed(2),
              transactions: [
                {
                  type: "ES",
                  value: obj.editSchedule,
                },
              ],
            });
          }
        } else if (key === "FreeTime" && value !== null) {
          if (isAgreementThere >= 0) {
            agreementPayload[isAgreementThere].transactions.push(
              {
                type: "FTT",
                value: obj.FreeTime.freeTimeType,
              },
              {
                type: "WOS",
                value:
                  obj.FreeTime.isOptServiceWaived == true ? "True" : "False",
              },
              {
                type: "DDA",
                value: moment(obj.Nextduedate).format("YYYY-MM-DD"),
              }
            );
          } else {
            agreementPayload.push({
              agreementId: obj.Agreementid,
              nextDueDate:
                obj.Nextduedate !== "EPO" &&
                obj.Nextduedate !== "PIF" &&
                obj.Nextduedate !== "SAC"
                  ? String(moment(obj.Nextduedate).format("YYYY-MM-DD"))
                  : null,
              currentDueDate: String(
                moment(obj.CurrentDueDate).format("YYYY-MM-DD")
              ),
              amount: (Number(obj.Amount) +
                Number(obj.suspenseAdd) -
                Number(obj.suspenseSub) <
              0
                ? 0.0
                : Number(obj.Amount) +
                  Number(obj.suspenseAdd) -
                  Number(obj.suspenseSub)
              ).toFixed(2),
              transactions: [
                {
                  type: "FTT",
                  value: obj.FreeTime.freeTimeType,
                },
                {
                  type: "WOS",
                  value:
                    obj.FreeTime.isOptServiceWaived == true ? "True" : "False",
                },
                {
                  type: "DDA",
                  value:
                    obj.Nextduedate !== "EPO" &&
                    obj.Nextduedate !== "PIF" &&
                    obj.Nextduedate !== "SAC"
                      ? String(moment(obj.Nextduedate).format("YYYY-MM-DD"))
                      : null,
                },
              ],
            });
          }
        } else if (key === "acceptEpoFlag" && value === 1||(key === "dueDateModified" && value === true&&(obj.Nextduedate=='SAC'||obj.Nextduedate=='EPO'))) {
          if((key === "dueDateModified" && value === true&&(obj.Nextduedate=='SAC'||obj.Nextduedate=='EPO'))){
            obj.editedEPOAmount= obj?.editedEPOAmount!=undefined && obj?.editedEPOAmount!="0.00"?obj?.editedEPOAmount: obj!== undefined &&
            obj !== null &&
            obj !== "" &&
            obj.EPOAmount !== undefined &&
            obj.EPOAmount !== null &&
            obj.EPOAmount !== ""
              ? Number(obj.EPOAmount) -
                Number(obj.EPOTax)-
                Number(
                  obj.pastDueRent !== undefined &&
                  obj.pastDueRent !== null &&
                  obj.pastDueRent !== ""
                    ? obj.pastDueRent
                    : 0.0
                ) -
                Number(
                  obj.lateFee !== undefined &&
                  obj.lateFee !== null &&
                  obj.lateFee !== ""
                    ? obj.lateFee
                    : 0.0
                ) +
                Number(obj.carriedLateFee)
              : "0.00"
            obj.pastDueRent=obj!== undefined &&
            obj !== null &&
            obj!== "" &&
            obj.pastDueRent !== undefined &&
            obj.pastDueRent !== null &&
            obj.pastDueRent !== ""
              ? obj.pastDueRent
              : "0.00"
          }
          if (isAgreementThere >= 0) {
            agreementPayload[isAgreementThere].transactions.push(
              {
                type: "AEF",
                value: "True",
              },
              {
                type: "AEPDA",
                value: String(obj?.pastDueRent?.toFixed(2)),
              },
              {
                type: "AEA",
                value: Number(obj?.editedEPOAmount)>=0?String(Number(obj?.editedEPOAmount).toFixed(2)):'0.00',
              }
            );
          } else {
            agreementPayload.push({
              agreementId: obj.Agreementid,
              nextDueDate:
                obj.Nextduedate !== "EPO" &&
                obj.Nextduedate !== "PIF" &&
                obj.Nextduedate !== "SAC"
                  ? String(moment(obj.Nextduedate).format("YYYY-MM-DD"))
                  : null,
              currentDueDate: String(
                moment(obj.CurrentDueDate).format("YYYY-MM-DD")
              ),
              amount: (Number(obj.Amount) +
                Number(obj.suspenseAdd) -
                Number(obj.suspenseSub) <
              0
                ? 0.0
                : Number(obj.Amount) +
                  Number(obj.suspenseAdd) -
                  Number(obj.suspenseSub)
              ).toFixed(2),
              transactions: [
                {
                  type: "AEF",
                  value: "True",
                },
                {
                  type: "AEPDA",
                  value: String(obj?.pastDueRent?.toFixed(2)),
                },
                {
                  type: "AEA",
                  value:Number(obj?.editedEPOAmount)>=0?String(Number(obj?.editedEPOAmount).toFixed(2)):'0.00',
                },
              ],
            });
          }
        } else if (key === "suspenseAdd" && Number(value) > 0) {
          if (isAgreementThere >= 0) {
            agreementPayload[isAgreementThere].transactions.push({
              type: "AS",
              value: String(obj.suspenseAdd),
            });
          } else {
            agreementPayload.push({
              agreementId: obj.Agreementid,
              nextDueDate:
                obj.Nextduedate !== "EPO" &&
                obj.Nextduedate !== "PIF" &&
                obj.Nextduedate !== "SAC"
                  ? String(moment(obj.Nextduedate).format("YYYY-MM-DD"))
                  : null,
              currentDueDate: String(
                moment(obj.CurrentDueDate).format("YYYY-MM-DD")
              ),
              amount: (Number(obj.Amount) +
                Number(obj.suspenseAdd) -
                Number(obj.suspenseSub) <
              0
                ? 0.0
                : Number(obj.Amount) +
                  Number(obj.suspenseAdd) -
                  Number(obj.suspenseSub)
              ).toFixed(2),
              transactions: [
                {
                  type: "AS",
                  value: String(obj.suspenseAdd),
                },
              ],
            });
          }
        } else if (key === "suspenseSub" && Number(value) > 0) {
          if (isAgreementThere >= 0) {
            agreementPayload[isAgreementThere].transactions.push({
              type: "US",
              value: String(obj.suspenseSub),
            });
          } else {
            agreementPayload.push({
              agreementId: obj.Agreementid,
              nextDueDate:
                obj.Nextduedate !== "EPO" &&
                obj.Nextduedate !== "PIF" &&
                obj.Nextduedate !== "SAC"
                  ? String(moment(obj.Nextduedate).format("YYYY-MM-DD"))
                  : null,
              currentDueDate: String(
                moment(obj.CurrentDueDate).format("YYYY-MM-DD")
              ),
              amount: (Number(obj.Amount) +
                Number(obj.suspenseAdd) -
                Number(obj.suspenseSub) <
              0
                ? 0.0
                : Number(obj.Amount) +
                  Number(obj.suspenseAdd) -
                  Number(obj.suspenseSub)
              ).toFixed(2),
              transactions: [
                {
                  type: "US",
                  value: String(obj.suspenseSub),
                },
              ],
            });
          }
        } else if (key === "carriedLateFee" && Number(value) > 0) {
          if (isAgreementThere >= 0) {
            agreementPayload[isAgreementThere].transactions.push(
              {
                type: "LFC",
                value: Number(obj.carriedLateFee).toFixed(2),
              }
            );
          } else {
            agreementPayload.push({
              agreementId: obj.Agreementid,
              nextDueDate:
                obj.Nextduedate !== "EPO" &&
                obj.Nextduedate !== "PIF" &&
                obj.Nextduedate !== "SAC"
                  ? String(moment(obj.Nextduedate).format("YYYY-MM-DD"))
                  : null,
              currentDueDate: String(
                moment(obj.CurrentDueDate).format("YYYY-MM-DD")
              ),
              amount: (Number(obj.Amount) +
                Number(obj.suspenseAdd) -
                Number(obj.suspenseSub) <
              0
                ? 0.0
                : Number(obj.Amount) +
                  Number(obj.suspenseAdd) -
                  Number(obj.suspenseSub)
              ).toFixed(2),
              transactions: [
                {
                  type: "LFC",
                  value: Number(obj.carriedLateFee).toFixed(2),
                }
              ],
            });
          }

        } else if (key === "waivedLateFee" && Number(value) > 0) {
          if (isAgreementThere >= 0) {
            agreementPayload[isAgreementThere].transactions.push(
              {
                type: "LFW",
                value: Number(obj.waivedLateFee).toFixed(2),
              }
            );
          } else {
            agreementPayload.push({
              agreementId: obj.Agreementid,
              nextDueDate:
                obj.Nextduedate !== "EPO" &&
                obj.Nextduedate !== "PIF" &&
                obj.Nextduedate !== "SAC"
                  ? String(moment(obj.Nextduedate).format("YYYY-MM-DD"))
                  : null,
              currentDueDate: String(
                moment(obj.CurrentDueDate).format("YYYY-MM-DD")
              ),
              amount: (Number(obj.Amount) +
                Number(obj.suspenseAdd) -
                Number(obj.suspenseSub) <
              0
                ? 0.0
                : Number(obj.Amount) +
                  Number(obj.suspenseAdd) -
                  Number(obj.suspenseSub)
              ).toFixed(2),
              transactions: [
                {
                  type: "LFW",
                  value: Number(obj.waivedLateFee).toFixed(2),
                }
              ],
            });
          }

        }
      }
    });
    if (actLDWArr.length) {
      actLDWArr.map((ldwObj, ldwIndex) => {
        const isAgreementThere = agreementPayload.findIndex(
          (el) => Number(el.agreementId) === Number(ldwObj.agreementId)
        );
        let payGridData = paygridvalues.filter(
          (obj) => String(obj.Agreementid) == String(ldwObj.agreementId)
        );
        if (isAgreementThere >= 0) {
          agreementPayload[isAgreementThere].transactions.push({
            type: ldwObj.action === 1 ? "AOS" : "TOS",
            value: "LDW",
          });
        } else {
          agreementPayload.push({
            agreementId: String(ldwObj.agreementId),
            nextDueDate:
              payGridData[0].Nextduedate !== "EPO" &&
              payGridData[0].Nextduedate !== "PIF" &&
              payGridData[0].Nextduedate !== "SAC"
                ? String(
                    moment(payGridData[0].Nextduedate).format("YYYY-MM-DD")
                  )
                : null,
            currentDueDate: String(
              moment(payGridData[0].CurrentDueDate).format("YYYY-MM-DD")
            ),
            amount: (Number(payGridData[0].Amount) +
              Number(payGridData[0].suspenseAdd) -
              Number(payGridData[0].suspenseSub) <
            0
              ? 0.0
              : Number(payGridData[0].Amount) +
                Number(payGridData[0].suspenseAdd) -
                Number(payGridData[0].suspenseSub)
            ).toFixed(2),
            transactions: [
              {
                type: ldwObj.action === 1 ? "AOS" : "TOS",
                value: "LDW",
              },
            ],
          });
        }
      });
    }
    if (actPolicyArr.length) {
      actPolicyArr.map((policyObj) => {
        const isAgreementThere = agreementPayload.findIndex(
          (el) => Number(el.agreementId) === Number(policyObj.agreementId)
        );
        let payGridData = paygridvalues.filter(
          (obj) => String(obj.Agreementid) == String(policyObj.agreementId)
        );
        if (isAgreementThere >= 0) {
          agreementPayload[isAgreementThere].transactions.push({
            type: policyObj.action === 1 ? "AOS" : "TOS",
            value: "Policy",
          });
        } else {
          agreementPayload.push({
            agreementId: String(policyObj.agreementId),
            nextDueDate:
              payGridData[0].Nextduedate !== "EPO" &&
              payGridData[0].Nextduedate !== "PIF" &&
              payGridData[0].Nextduedate !== "SAC"
                ? String(
                    moment(payGridData[0].Nextduedate).format("YYYY-MM-DD")
                  )
                : null,
            currentDueDate: String(
              moment(payGridData[0].CurrentDueDate).format("YYYY-MM-DD")
            ),
            amount: (Number(payGridData[0].Amount) +
              Number(payGridData[0].suspenseAdd) -
              Number(payGridData[0].suspenseSub) <
            0
              ? 0.0
              : Number(payGridData[0].Amount) +
                Number(payGridData[0].suspenseAdd) -
                Number(payGridData[0].suspenseSub)
            ).toFixed(2),
            transactions: [
              {
                type: policyObj.action === 1 ? "AOS" : "TOS",
                value: "Policy",
              },
            ],
          });
        }
      });
    }
    if (totalsuspensemaster.length) {
      let filteredTransferData = totalsuspensemaster.filter(
        (obj) => obj.sustype == "TransferAdd" || obj.sustype == "TransferUsed"
      );
      if (filteredTransferData.length) {
        filteredTransferData.map((transferObject) => {
          const isAgreementThere = agreementPayload.findIndex(
            (el) =>
              Number(el.agreementId) === Number(transferObject.agreementnumber)
          );
          if (isAgreementThere >= 0) {
            if (transferObject.sustype === "TransferUsed") {
              agreementPayload[isAgreementThere].transactions.push({
                type: "TUS",
                value: String(transferObject.newsuspense),
              });
            } else if (transferObject.sustype === "TransferAdd") {
              agreementPayload[isAgreementThere].transactions.push({
                type: "TAS",
                value: String(transferObject.newsuspense),
              });
            }
          } else {
            let transactionsData: any = [];
            if (transferObject.sustype === "TransferUsed") {
              transactionsData.push({
                type: "TUS",
                value: String(transferObject.newsuspense),
              });
            } else if (transferObject.sustype === "TransferAdd") {
              transactionsData.push({
                type: "TAS",
                value: String(transferObject.newsuspense),
              });
            }
            let payGridData = paygridvalues.filter(
              (obj) =>
                String(obj.Agreementid) == String(transferObject.agreementId)
            );
            agreementPayload.push({
              agreementId: String(transferObject.agreementnumber),
              nextDueDate:
                payGridData[0].Nextduedate !== "EPO" &&
                payGridData[0].Nextduedate !== "PIF" &&
                payGridData[0].Nextduedate !== "SAC"
                  ? String(
                      moment(payGridData[0].Nextduedate).format("YYYY-MM-DD")
                    )
                  : null,
              currentDueDate: String(
                moment(payGridData[0].CurrentDueDate).format("YYYY-MM-DD")
              ),
              amount: (Number(payGridData[0].Amount) +
                Number(payGridData[0].suspenseAdd) -
                Number(payGridData[0].suspenseSub) <
              0
                ? 0.0
                : Number(payGridData[0].Amount) +
                  Number(payGridData[0].suspenseAdd) -
                  Number(payGridData[0].suspenseSub)
              ).toFixed(2),
              transactions: transactionsData,
            });
          }
        });
      }
    }
    let clubPayLoad: any = [];
    filteredClubData.map((obj, index) => {
      for (const [key, value] of Object.entries(obj)) {
        const isClubAgreementThere = clubPayLoad.findIndex(
          (el) => Number(el.clubId) === Number(obj.customerClubId)
        );
        if (key === "dueDateModified" && value === true) {
          if (isClubAgreementThere >= 0) {
            clubPayLoad[isClubAgreementThere].transactions.push({
              type: "DDA",
              value: moment(obj.Nextduedate).format("YYYY-MM-DD"),
            });
          } else {
            clubPayLoad.push({
              clubId: String(obj.customerClubId),
              nextDueDate: String(moment(obj.Nextduedate).format("YYYY-MM-DD")),
              currentDueDate: String(
                moment(obj.CurrentDueDate).format("YYYY-MM-DD")
              ),
              amount: (Number(obj.Amount) < 0
                ? 0.0
                : Number(obj.Amount)
              ).toFixed(2),
              transactions: [
                {
                  type: "DDA",
                  value: moment(obj.Nextduedate).format("YYYY-MM-DD"),
                },
              ],
            });
          }
        } else if (key === "editSchedule" && value !== "") {
          if (isClubAgreementThere >= 0) {
            clubPayLoad[isClubAgreementThere].transactions.push({
              type: "ES",
              value: obj.editSchedule,
            });
          } else {
            clubPayLoad.push({
              clubId: String(obj.customerClubId),
              nextDueDate: String(moment(obj.Nextduedate).format("YYYY-MM-DD")),
              currentDueDate: String(
                moment(obj.CurrentDueDate).format("YYYY-MM-DD")
              ),
              amount: (Number(obj.Amount) < 0
                ? 0.0
                : Number(obj.Amount)
              ).toFixed(2),
              transactions: [
                {
                  type: "ES",
                  value: obj.editSchedule,
                },
              ],
            });
          }
        } else if (key === "isClubDeactivateTriggered" && value == true) {
          if (isClubAgreementThere >= 0) {
            clubPayLoad[isClubAgreementThere].transactions.push({
              type: "DC",
              value: "True",
            });
          } else {
            clubPayLoad.push({
              clubId: String(obj.customerClubId),
              nextDueDate: String(moment(obj.Nextduedate).format("YYYY-MM-DD")),
              currentDueDate: String(
                moment(obj.CurrentDueDate).format("YYYY-MM-DD")
              ),
              amount: (Number(obj.Amount) < 0
                ? 0.0
                : Number(obj.Amount)
              ).toFixed(2),
              transactions: [
                {
                  type: "DC",
                  value: "True",
                },
              ],
            });
          }
        }
      }
    });
    const nonAdjustedAgreements: any = [];
    const nonAdjustedClub: any = [];
    let filteredAgreementArray = filteredNonClubData.filter(
      (item1) =>
        !agreementPayload.some(
          (item2) => item2.agreementId === item1.Agreementid
        )
    );
    filteredAgreementArray.map((el) => {
      nonAdjustedAgreements.push({
        agreementId: el.Agreementid,
        nextDueDate:
          el.Nextduedate !== "EPO" &&
          el.Nextduedate !== "PIF" &&
          el.Nextduedate !== "SAC"
            ? String(moment(el.Nextduedate).format("YYYY-MM-DD"))
            : null,
        currentDueDate: String(moment(el.CurrentDueDate).format("YYYY-MM-DD")),
        amount: Number(el.Amount).toFixed(2),
      });
    });
    let updatedAgreementPayload = agreementPayload.concat(
      nonAdjustedAgreements
    );

    let filteredClubArrayValues = filteredClubData.filter(
      (item1) =>
        !clubPayLoad.some((item2) => item2.clubId === item1.customerClubId)
    );
    filteredClubArrayValues.map((el) => {
      nonAdjustedClub.push({
        clubId: String(el.customerClubId),
        nextDueDate: String(moment(el.Nextduedate).format("YYYY-MM-DD")),
        currentDueDate: String(moment(el.CurrentDueDate).format("YYYY-MM-DD")),
        amount: Number(el.Amount).toFixed(2),
      });
    });
    let updatedClubPayload = clubPayLoad.concat(nonAdjustedClub);
    history.push({
      pathname: `/am/customer/${customerId}`,
      state: {
        commitmentDetails: {
          commitmentType: location?.state?.commitmentData?.commitmentType,
          commitmentDate: location?.state?.commitmentData?.commitmentDate,
          commitmentTime: location?.state?.commitmentData?.commitmentTime,
          notes: location?.state?.commitmentData?.commitmentNotes,
          amount: String(
            ((Number(totaldue) + Number(carryRentAmount) + Number(totalDueSusAddAmount) - Number(totalDueSusUseAmount))<0?0:(Number(totaldue) + Number(carryRentAmount) + Number(totalDueSusAddAmount) - Number(totalDueSusUseAmount))).toFixed(2)
          ),
          agreements:
            updatedAgreementPayload.length > 0
              ? updatedAgreementPayload
              : undefined,
          club:
            updatedClubPayload.length > 0 ? updatedClubPayload[0] : undefined,
        },
      },
    });
  };

  // useEffect(() => {
  //   if (configFlags.isPaymentFunctionsFeatureFlag == 1) {
  //     getAlerts();
  //   }
  // }, [configFlags]);

  const getAlerts = async () => {
    buildActiveAlerts();
    const getAlerts = await GetAlert(customerId);
    setobjAlertdata(getAlerts.alert !== undefined ? getAlerts.alert : []);
  };

  const buildActiveAlerts = async () => {
    let getAlerts: any = [];
    //if (updatedAlert == undefined || updatedAlert == null) {
    getAlerts = await GetCustomerAlert(customerId);
    getAlerts =
      getAlerts?.customerAlertByIdDTO?.value?.customeralert !== undefined
        ? getAlerts?.customerAlertByIdDTO?.value?.customeralert
        : undefined;

    const activeAlerts: any =
      getAlerts !== undefined
        ? getAlerts.filter((val: any) => val.alertCleared == 0)
        : [];
    const popupAlerts: any =
      getAlerts !== undefined
        ? getAlerts.filter(
            (val: any) =>
              val.alertCleared == 0 &&
              val.alertTypeId !== "3" &&
              val.alertTypeId !== 3
          )
        : [];

    const alertLoad: any = [];
    let activeAlertcount = 0;
    if (getAlerts !== undefined && getAlerts.length > 0) {
      for (let i = 0; i < getAlerts.length; i++) {
        alertLoad.push({
          alertTypeId: getAlerts[i].alertTypeId,
          alertCleared: getAlerts[i].alertCleared,
        });
        if (
          getAlerts[i].alertCleared == 0 ||
          getAlerts[i].alertCleared == "0"
        ) {
          activeAlertcount = activeAlertcount + 1;
        }
      }
    }
    setAlertLoadCount(activeAlertcount);
    setAlertLoad(alertLoad);
    setAlertWizard(getAlerts !== undefined ? getAlerts : []);
    //active alert
    setActiveAlert(activeAlerts);
    //Alerts without custom alerts
    setAlertUpdateObj([...popupAlerts]);
  };

  const CustomAlertval = (e: any) => {
    // //debugger;
    const alertValue = formatTextChange(e.target.value);
    setCustomAlerttxt(alertValue);
    document.getElementById("CustomAlert")?.focus();
    if (alertValue == undefined || alertValue == null || alertValue == "") {
      setdisableAlert(true);
    } else {
      setdisableAlert(false);
    }
  };
  const formatTextChange = (value: any) => {
    return value !== undefined && value !== null && value.trim().length == 0
      ? value.trim()
      : value;
  };
  const Assignalertclear = (
    action: "add" | "remove",
    newAlert: any,
    alertTypeId?: any
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    // eslint-disable-next-line no-debugger
    ////debugger;
    // if (
    //   (alertTypeId == "2" || alertTypeId == "4" || alertTypeId == "5") &&
    //   action == "add"
    // ) {
    //   setAlertAuthenticationNeeded(
    //     action ? newAlert : { removeAlert: true, alertTypeId: alertTypeId }
    //   );
    //   setauthenType("ALERT");
    //   racCoWorkerFunc("Assign Alert");
    // }

    let activeAlertObj: any = AlertUpdateObj;
    if (
      alertTypeId !== undefined &&
      alertTypeId !== null &&
      alertTypeId == "3"
    ) {
      if (action == "remove") {
        setEnabletxt(true);
        setdisableAlert(false);
      } else {
        setEnabletxt(false);
        setdisableAlert(true);
      }
    }
    activeAlertObj = alterAlertValue(action, newAlert, alertTypeId);
    setAlertUpdateObj([...activeAlertObj]);
  };
  const alterAlertValue = (
    action: "add" | "remove",
    newAlert: any,
    alertTypeId?: any
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    let activeAlertObj: any = AlertUpdateObj;

    if (action == "remove") {
      if (alertTypeId != "3") {
        const editAlert = activeAlertObj.findIndex(
          (val: any) => val.alertTypeId == alertTypeId
        );
        if (editAlert !== -1) {
          activeAlertObj[editAlert].alertCleared = 1;
        }
      } else {
        activeAlertObj = activeAlertObj.filter(
          (val: any) => val.alertTypeId != "3"
        );
      }
    } else {
      // if (alertTypeId !== "2" && alertTypeId !== "4" && alertTypeId !== "5") {
      const editAlert = activeAlertObj.findIndex(
        (val: any) => val.alertTypeId == alertTypeId
      );
      if (editAlert !== -1) {
        activeAlertObj[editAlert].alertCleared = 0;
      } else {
        newAlert.alertCleared = 0;
        activeAlertObj.push(newAlert);
      }
      // }
    }
    return activeAlertObj;
  };
  const alterAlert = (
    action: "add" | "remove",
    newAlert: any,
    alertTypeId?: any
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    let activeAlertObj: any = AlertUpdateObj;
    if (
      alertTypeId !== undefined &&
      alertTypeId !== null &&
      alertTypeId == "3"
    ) {
      if (action == "remove") {
        setEnabletxt(true);
        setdisableAlert(false);
      } else {
        setEnabletxt(false);
        setdisableAlert(true);
      }
    }
    if (action == "remove") {
      if (alertTypeId != "3") {
        const editAlert = activeAlertObj.findIndex(
          (val: any) => val.alertTypeId == alertTypeId
        );
        if (editAlert !== -1) {
          activeAlertObj[editAlert].alertCleared = 1;
        }
      } else {
        activeAlertObj = activeAlertObj.filter(
          (val: any) => val.alertTypeId != "3"
        );
      }
    } else {
      if (alertTypeId !== "2" && alertTypeId !== "4" && alertTypeId !== "5") {
        newAlert.alertCleared = 0;
        activeAlertObj.push(newAlert);
      }
    }
    return activeAlertObj;
  };

  const RemoveAlert = async (removeAlert: any, index: any) => {
    try {
      // const ObjAlertWizard: any = AlertWizard;
      // ObjAlertWizard.splice(index, 1);
      const payload: any = {
        alertTypes: removeAlert,
        customerId: parseInt(customerId),
        userId: "191197",
      };
      setIsAlertLoading(true);
      const response: any = await UpdateAlert(payload, customerId);

      if (response !== undefined && response !== null) {
        buildActiveAlerts();
        //getAlerts();
      }
      setIsAlertLoading(false);
    } catch (e: any) {
      setIsAlertLoading(false);
    }
  };

  function openAlert() {
    setIsOpen(true);
  }

  function closeAlert() {
    setIsOpen(false);
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const Assignalert = () => {
    if (
      objAlertdata !== undefined &&
      objAlertdata !== null &&
      objAlertdata.length > 0
    ) {
      const objAlertdataL = objAlertdata.sort((a: any, b: any) =>
        a["alertTypeDescEn"] < b["alertTypeDescEn"] ? -1 : 1
      );
      return objAlertdataL.map((val: any, index: any) => {
        //const AlertWizardList: any = [];
        let inActiveAlert: any = [];
        const isAlertActive: boolean =
          AlertUpdateObj.filter(
            (val1: any) =>
              val1.alertTypeId == val.alertTypeId && val1.alertCleared == 0
          ).length > 0
            ? true
            : false;
        if (isAlertActive === false) {
          // inActiveAlert = inActiveAlerts.filter(
          //   (val1: any) => val1.alertTypeId == val.alertTypeId
          // );
          inActiveAlert = {
            alertText: "",
            alertTypeDescEn: val.alertTypeDescEn,
            alertTypeDescEs: val.alertTypeDescEs,
            alertCleared: 0,
            alertTypeId: val.alertTypeId,
            customerAlertId: "",
          };
          // inActiveAlert !== undefined &&
          // inActiveAlert !== null &&
          // inActiveAlert.length > 0
          //   ? {
          //       alertText: '',
          //       alertTypeDescEn: inActiveAlert[0].alertTypeDescEn,
          //       alertTypeDescEs: inActiveAlert[0].alertTypeDescEs,
          //       alertCleared: 0,
          //       alertTypeId: inActiveAlert[0].alertTypeId,
          //       customerAlertId: '',
          //     }
          //   : {
          //       alertText: '',
          //       alertTypeDescEn: val.alertTypeDescEn,
          //       alertTypeDescEs: val.alertTypeDescEs,
          //       alertCleared: 0,
          //       alertTypeId: val.alertTypeId,
          //       customerAlertId: '',
          //     };
        }
        //AlertWizard.filter((val1:any)=>val1.alertTypeId == val.alertTypeId && val.alertTypeId != "3")

        //let OtherAlert: any = [];
        let isOtherAlert = false;
        //let OtherAlertObj: any = [];
        if (index == objAlertdataL.length - 1) {
          // OtherAlert = objAlertdataL.filter(
          //   (val: any) => val.alertTypeId == '3'
          // );
          isOtherAlert =
            AlertUpdateObj.filter((val1: any) => val1.alertTypeId == "3")
              .length > 0
              ? true
              : false;
        }
        // const disableAlert =
        //   val.alertTypeId == '2' ||
        //   val.alertTypeId == '4' ||
        //   val.alertTypeId == '5'
        //     ? !isSecondAuthenValid
        //     : false;
        return index == objAlertdataL.length - 1 ? (
          <React.Fragment>
            {buildAlert(isAlertActive, index, inActiveAlert, val)}
            {buildAlert(
              isOtherAlert,
              index + 1,
              {
                alertCleared: 0,
                alertText: "",
                alertTypeDescEn: "Other",
                alertTypeDescEs: null,
                alertTypeId: "3",
                customerAlertId: "",
              },
              {
                alertTypeDescEn: "Other",
                alertTypeDescEs: null,
                alertTypeId: "3",
              }
            )}
          </React.Fragment>
        ) : val.alertTypeId !== "3" ? (
          buildAlert(isAlertActive, index, inActiveAlert, val)
        ) : null;
      });
    } else if (objAlertdata.length == 0) {
      return (
        <Grid item className={`${classes.racCol2} ${classes.spacerMR2}`}>
          <Grid
            item
            style={{ marginLeft: "160px" }}
            className={`${classes.customerNorecordsAlert} ${classes.ms2}`}
          >
            <CircularProgress className={classes.alertLoader} />
          </Grid>
        </Grid>
      );
    }
  };

  const buildAlert = (
    isAlertExist: boolean,
    index: any,
    addAlertObj: any,
    val: any
  ) => {
    return (
      <Grid className={`${classes.p0} ${classes.mb3}`} key={index}>
        <RACCheckBox
          name="assignAlertChecked"
          checked={isAlertExist}
          onChange={() =>
            Assignalertclear(
              isAlertExist === false ? "add" : "remove",
              addAlertObj,
              val.alertTypeId
            )
          }
        />
        {val.alertTypeDescEn}
      </Grid>
    );
  };

  const formatPhone = (input: any) => {
    const cleaned = ("" + input).replace(/\D/g, "");
    const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return cleaned.replace(PhoneRegex, "($1) $2-$3");
  };

  const AlertMessages = () => {
    if (activeAlert !== undefined && activeAlert !== null) {
      return activeAlert.map((val: any, index: any) => {
        const alertActive = AlertLoad.findIndex(
          (alert: any) =>
            alert.alertTypeId == val.alertTypeId && alert.alertCleared == 0
        );
        if (alertActive !== -1) {
          return (
            <span
              className={`${classes.alertWidgetStyle} ${classes.me2}`}
              key={index}
              id={index}
            >
              {
                /*customAlertSpan ? null : (*/
                val.alertTypeId !== "3" && val.alertTypeId !== 3 ? (
                  <a
                    // onClick={(e: any) => ScrollToPage(val.alertTypeId, e)}
                    onClick={() => redirectToCustScrollToPage(val.alertTypeId)}
                    className={classes.me2}
                  >
                    <ExpandAlert></ExpandAlert>
                  </a>
                ) : null
                /*)*/
              }
              {val.alertTypeDescEn == "Other"
                ? val.alertText
                : val.alertTypeDescEn}
              <a className={classes.ms2}>
                <ExpandCancel
                  onClick={() =>
                    RemoveAlert(
                      [
                        {
                          alertTypeId: parseInt(val.alertTypeId),
                          customerAlertId: parseInt(val.customerAlertId),
                          alertText: "",
                          alertClear: 1,
                        },
                      ],
                      index
                    )
                  }
                ></ExpandCancel>
              </a>
            </span>
          );
        }
      });
    }
  };

  const ScrollToPage = (Id: any, e: any) => {
    e.preventDefault();
    const elementId: string =
      Id == "6" ? "addressDiv" : Id == "9" ? "ParentContactInformation" : "";

    const Tab: any = Id == "7" ? 1 : Id == "11" ? 2 : "";
    if (elementId !== "") {
      const titleElement = document.getElementById(elementId);
      if (titleElement !== null) {
        titleElement.scrollIntoView({ behavior: "smooth" });
      }

      //Need to add redirection to Paymentschedule and capture agreement signture
    }
    // if (Tab !== '') {
    //   setactivetab(Tab);
    // }
  };
  /**
   * changes : set to default v2
   * Developer : Muzzamil
   * Desc : to set the state variables from the UI changed state variable to back to original with all
   * grid and Tender type changes
   */
  // let setToDefault:any=0
  const carryRes = (response: any) => {
    // debugger;
    let resTotalAmount = 0.0;
    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    const carryrentamount = Number(
      Number(
        response.carryRentAmount == undefined ? 0.0 : response.carryRentAmount
      ) - Number(carryRentAmount)
    );

    settotalCalciTextValue(response.maxCarry);

    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    const cardAmount =
    multipleCard.length == 0
      ? 0.0
      : multipleCard
          .map((bill) => bill.Amount)
          .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
  let cvvToggle = 0;
  if (
    (Number(cardAmount) > 0 && cvvTextValue.length < 3) ||
    CardErrorLbl == true
  ) {
    cvvToggle = 1;
  }
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let totalDuelocal=remainingamountdue
    if (
      Number(resTotalAmount) <
      Number(totaldue) -
        Number(availableCreds) +
        Number(donationAmountAdd)+
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount) +
        Number(carryRentAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
    ) {
      settotaldue(Number(totaldue) - Number(carryrentamount));
      totalDuelocal=  Number(
        (
          Number(totaldue) -
          Number(carryrentamount) -
          Number(availableCreds) +
          Number(donationAmountAdd)+
          Number(addSusTotalAmount) -
          Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
        ).toFixed(2)
      ) - Number(resTotalAmount)
      setremainingamountdue(
        Number(
          (
            Number(totaldue) -
            Number(carryrentamount) -
            Number(availableCreds) +
            Number(donationAmountAdd)+
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ).toFixed(2)
        ) - Number(resTotalAmount)
      );
      setRemaniningAmtStatic(
        Number(
          (
            Number(totaldue) -
            Number(carryrentamount) -
            Number(availableCreds)+
            Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ).toFixed(2)
        )
      );
      // - Number(resTotalAmount) Removed this from setRemaniningAmtStatic for check & MO issue
      setcarryRentAmount(Number(response.carryRentAmount));
      setchange(0.0);
      //  let epoCheckedAgree = checkedAgr.filter(x=>Number(x.EPOAmount)>0)
      //  setAcceptpaymentbuttonenable(false)
    } else {
      settotaldue(Number(totaldue) + Number(response.carryRentAmount));
      totalDuelocal=Number("0.00")
      setremainingamountdue("0.00");
      setcarryRentAmount(Number("0.00"));
      setchange(
        Number(resTotalAmount) -
      Number(
      (
        Number(totaldue) -
        Number(carryrentamount) -
        Number(availableCreds) +
        Number(donationAmountAdd)+
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
      ).toFixed(2)
    )
  );
      // setAcceptpaymentbuttonenable(true)
    }
    if (
      Number(totalDuelocal)<=0&&
      cvvToggle == 0
    ) {
      setAcceptpaymentbuttonenable(false);
    } else {
      setAcceptpaymentbuttonenable(true);
    }
  };

  const setTodefaultVal = async () => {
    // if(toDefaultActive!=true){
    //   settoDefaultActive(true)
    // }
    // debugger;

    // setOverAll_Chk(false);
    actLDWArr = [];
    actPolicyArr = [];
    adjustDueDateSelectedAgre=[];
    setUsedFreeTimeAmouts([]);
    setExceededAmount(0);
    if (
      paygridvalues.length == 1 &&
      paygridvalues[0].Agreementtype != "Club"
      // &&
      // paygridvalues[0].FreeTime == null // Added By Me
    ) {
      loadOtherActions(0);
    } else {
      setshowLDWPolicy(false);
    }
    let onlyMiscellineaousFlag = false;
    let onlyMiscellineaousCount = 0;
    for (let i = 0; i < paygridvalues.length; i++) {
      if (Number(paygridvalues[i].miscellaneousItemsNum) > 0) {
        onlyMiscellineaousCount++;
      }
    }
    if (onlyMiscellineaousCount == Number(paygridvalues.length)) {
      onlyMiscellineaousFlag = true;
    }

    donationBtnFlag = false
    setDonationBtnFlag(donationBtnFlag)
    setDonationAmount({	
      respFlag: false,	
      type: "",	
      amount: "0.00",	
      validateFlag:false	
    });
    setDonationtextBoxFlag(true)
    donationAmountAdd = 0
    setDonationAmountAdd(donationAmountAdd)

    if (agreementId != "0" || onlyMiscellineaousFlag == true) {
      if (isRevenueRecogActivated) {
        selectallcheckboxRevenue(false);
      } else {
        selectallcheckbox(false);
      }
    } else {
      document.getElementById("maincheckbox")?.removeAttribute("disabled");
    }
    if (carryRentWidget) {
      // await carryOnchange()
      setcarryRentWidget(false);
      setcarryRentAmount("0.00");
    }
    setclubCheckDisabled(false);
    resetPayment(undefined);
    setbackRentEditInfo(undefined);
    await getpaid("STD");

    if (paymentOrigin == "Pay By Phone" || showChargeCard == true) {
      if (showChargeCard) {
        ChargeCardClose();
      }
      // if (isRevenueRecogActivated) {
      //   await phoneRevenue({ target: { value: "Store" } });
      // } else {
        await phone({ target: { value: "Store" } });
      // }
    }
    //  debugger;
    let defaultAmount = 0;
    if (agreementId != "0") {
      let defaultinitialValue = paygridvalues.filter(
        (x) => Number(x.Agreementid) === Number(agreementId)
      );
      let clubvalue = paygridvalues.filter(
        (x) => x.Agreementtype == "Club" && x.number_of_paymentsmade == 0
      );
      for (let i = 0; i < defaultinitialValue.length; i++) {
        defaultAmount = defaultAmount + Number(defaultinitialValue[i].Amount);
      }
      for (let j = 0; j < clubvalue.length; j++) {
        defaultAmount = defaultAmount + Number(clubvalue[j].Amount);
      }
      //  setdefaultTotaldue(defaultAmount)
    } else {
      defaultAmount = paygridvalues.length == 0 ? 0.0 : defaultTotaldue;
    }
    setmasteraddsuspensearray([]);
    setmasterusesuspensearray([]);
    setmastertranssuspensearray([]);
    setchargeCardAmt("0.00");
    let choosenAgreements= paygridvalues.filter((x)=>x.store_number==String(window.sessionStorage.getItem("storeNumber")))
    let objAvailCreditsAmt = 0;
    const resultSelecteAgre = choosenAgreements.filter(function (e) {
      return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
    });
    let amount = 0;
    for (let i = 0; i < resultSelecteAgre.length; i++) {
      amount = amount + Number(resultSelecteAgre[i].Amount);
    }
    if (Number(amount) <= Number(choosenAgreements[0].wlDeposit)) {
      objAvailCreditsAmt = amount;
      objAvailCreditsAmt =
        diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
      //   availableCreds=objAvailCreditsAmt
      // setAvailableCreds(availableCreds);
      setAvailableCreds(objAvailCreditsAmt);
      // alert("objAvailCreditsAmt_10")
      if (diffStoreErrLbl && isRevenueRecogActivated) {
        setavailableCredsremaining([]);
      } else {
        setavailableCredsremaining([
          {
            balanceType: "WEBLEAD",
            accountBalance: Number(amount).toFixed(2),
          },
        ]);
      }
    } else if (Number(amount) > Number(choosenAgreements[0].wlDeposit)) {
      objAvailCreditsAmt =
        Number(choosenAgreements[0].COA) +
        Number(choosenAgreements[0].wlDeposit) +
        Number(
          (Number(amount) - Number (choosenAgreements[0].wlDeposit) 
          > Number(choosenAgreements[0].ipSuspense) 
          ? Number(choosenAgreements[0].ipSuspense) : 
          (Number(amount) - Number (choosenAgreements[0].wlDeposit))));
      objAvailCreditsAmt =
        diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
      //   availableCreds=objAvailCreditsAmt
      // setAvailableCreds(availableCreds);
      setAvailableCreds(objAvailCreditsAmt);
      // alert("objAvailCreditsAmt_11")
      if (diffStoreErrLbl && isRevenueRecogActivated) {
        setavailableCredsremaining([]);
      } else {
        setavailableCredsremaining([
          {
            balanceType: "WEBLEAD",
            accountBalance: Number(choosenAgreements[0].wlDeposit).toFixed(2),
          },
          {
            balanceType: "COA",
            accountBalance: Number(choosenAgreements[0].COA).toFixed(2),
          },
          {
            balanceType: "IP Suspence",
            accountBalance: Number((Number(amount) - Number (choosenAgreements[0].wlDeposit) 
            > Number(choosenAgreements[0].ipSuspense) 
            ? Number(choosenAgreements[0].ipSuspense) : 
            (Number(amount) - Number (choosenAgreements[0].wlDeposit)))).toFixed(2),
          },
        ]);
      }
    } else if (Number(amount) == 0) {
      objAvailCreditsAmt =
        Number(choosenAgreements[0].COA) 
        // +
        // Number(choosenAgreements[0].ipSuspense);
      objAvailCreditsAmt =
        diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
      //   availableCreds=objAvailCreditsAmt
      // setAvailableCreds(availableCreds);
      setAvailableCreds(objAvailCreditsAmt);
      // alert("objAvailCreditsAmt_12")
      if (diffStoreErrLbl && isRevenueRecogActivated) {
        setavailableCredsremaining([]);
      } else {
        setavailableCredsremaining([
          {
            balanceType: "COA",
            accountBalance: Number(choosenAgreements[0].COA).toFixed(2),
          },
          // {
          //   balanceType: "IP Suspence",
          //   accountBalance: Number(choosenAgreements[0].ipSuspense).toFixed(
          //     2
          //   ),
          // },
        ]);
      }
    }

    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (
      Number(
        (
          Number(defaultAmount) +
          Number(addSuspenseAmount) -
          Number(usedSusTotalAmount) -
          Number(objAvailCreditsAmt)
        ).toFixed(2)
      ) <= 0
    ) {
      setremainingamountdue("0.00");
      setRemaniningAmtStatic("0.00");
      settotaldue(defaultAmount);
      setchange(
        Number(objAvailCreditsAmt) -
          Number(addSuspenseAmount) +
          Number(usedSusTotalAmount) -
          Number(defaultAmount)
      );
      setAcceptpaymentbuttonenable(false);
    } else {
      setremainingamountdue(
        Number(defaultAmount) -
          Number(objAvailCreditsAmt) +
          Number(addSuspenseAmount) -
          Number(usedSusTotalAmount)
      );
      setRemaniningAmtStatic(
        Number(defaultAmount) -
          Number(objAvailCreditsAmt) +
          Number(addSuspenseAmount) -
          Number(usedSusTotalAmount)
      );
      setchange(0.0);
      settotaldue(defaultAmount);
      setAcceptpaymentbuttonenable(true);
    }
    //Activate club changes
    const clubStatus: any = paygridvalues.filter(
      (el: any) => el?.Agreementtype == "Club"
    );
    if (
      clubStatus.length > 0 &&
      !clubStatus[0].originAgreementId &&
      !clubStatus[0].clubDocumentSignStatus
    ) {
      setclubBtnStatus("A");
      setCustOrder("A");
    }
    if (clubStatus.length > 0) {
      if (clubStatus[0].number_of_paymentsmade > 0) {
        setclubStatus("D");
      } else if (clubStatus[0].number_of_paymentsmade == 0) {
        setclubStatus("P");
      }
      if (
        clubStatus[0]?.store_number == window.sessionStorage.getItem("storeNumber")
      ) {
        setisclubOnSameStore(false);
      }else{
        setisclubOnSameStore(true)
      }
    } else {
      setclubStatus("A");
      setisclubOnSameStore(false);
    }
    //

    //epo pop up hide
    setepoPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
    setchargeCardArray([]);
    setClubActivate(undefined);
    // if (isRevenueRecogActivated) {
    //   TenderTypeOptinchangeRevenue({ target: { id: "Cash-tab" } });
    // } else {
      TenderTypeOptinchange({ target: { id: "Cash-tab" } });
    // }
    setSecurityDepositData([]);
    // FormAcceptPaymentData(0, 0);
    let objAP: AcceptPaymentInfo = {
      CashAmount: 0,
      ChequeAmount:0,
      MOAmount:0,
      CardInfo: multipleCard,
      CheqAndMO: adddataanother.length > 0 ? adddataanother : null,
      AvailableCredits: availableCreds,
    };
    setAcceptPayment(objAP);
    // setMasterRSDData(RSDArrayValues);
    setadjustChargeoffFlag(false);
    setEditChargeoffFlag(false);
    setadjustRisbtnFlag(false);
    obj = [];
    setUpatedInventory([]);
    multipleAdjust();
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:"SetToDefault"
    }
    setUserTraceData([...userTraceData,traceObj])
  };
  //  useEffect(() => {
  //   // let resTotalAmount=0.00;
  //   // resTotalAmount =totalduetendertype.length==0?0.00:((totalduetendertype).map(bill => bill.Amount).reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2)));
  //   if(toDefaultActive==true){
  //     setTodefaultVal()
  //   }

  // }, [toDefaultActive==true]);
  const redirectionToCashSale = (Num: any) => {
    setnonInventoryPopUp(true);
    const editedItemValues = paygridvalues.filter(
      (e) => Number(e.miscellaneousItemsNum) == Number(Num)
    );
    const filterEditDataCollected = xBoxEditDataCollected.filter(
      (el) => Number(el.miscellaneousItemsNum) == Number(Num)
    );
    const filterEditDataCollectedAcima = acimaEditDataCollected.filter(
      (el) => Number(el.miscellaneousItemsNum) == Number(Num)
    );
    if (
      editedItemValues[0].Agreementtype == "Xbox Gift Card" ||
      editedItemValues[0].Agreementtype == "Xbox Gift Membership"
    ) {
      setXboxEditData({
        ...filterEditDataCollected[0],
        editItemId: editedItemValues[0].miscellaneousItemsNum,
      });
      setAcimaEditData(undefined);
      setEditNoninventoryData(undefined);
    } else if(editedItemValues[0].Agreementtype == "Non Inventory Sale") {
      setAcimaEditData({
        ...filterEditDataCollectedAcima[0],
        editItemId: editedItemValues[0].miscellaneousItemsNum,
      })
      setXboxEditData(undefined);
      setEditNoninventoryData(undefined);
    } else {
      setAcimaEditData(undefined);
      setXboxEditData(undefined);
      setEditNoninventoryData(editedItemValues);
    }
  };
  const nonInventoryOnClick = async (res: any) => {
    // debugger
    let selectallFlag = true;
    setReceiptCategory([]);
    ReceiptCategoriesRequestParam = [];
    acceptPaymentParam = [];
    AcceptclubPaymentParam = undefined;
    setAcceptClub(undefined);
    // setAcceptPayment([])
    setOtherIncomeHidden(true);
    setnonInventoryPopUp(false);
    otherIncomeOnblur();
    setEditNoninventoryData(undefined);
    setXboxEditData(undefined);
    setAcimaEditData(undefined);
    let misNumber = 0;
    for (let i = 0; i < paygridvalues.length; i++) {
      let addNum = paygridvalues[i].miscellaneousItemsNum > 0 ? 1 : 0;
      misNumber = misNumber + addNum;
    }
    if (res != undefined) {
      let coa = 0.0;
      let suspense = 0.0;
      let htSuspense = 0.0;
      let ipSuspense = 0.0;
      let wlDeposit = 0.0;

      if (
        getpayment.paymentInfo.remainingCredits !== undefined &&
        getpayment.paymentInfo.remainingCredits.length > 0
      ) {
        let rcInfo = getpayment.paymentInfo.remainingCredits;
        let rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "coa");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          COAamount = rcData[0].accountBalance;
          coa = Number(COAamount);
        }
        rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "suspense");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          suspense = Number(rcData[0].accountBalance);
        }

        rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "ht suspense");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          htSuspense = Number(rcData[0].accountBalance);
        }
        rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "ip suspense");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          ipSuspense = Number(rcData[0].accountBalance);
        }
      }
      let wlDepositDetail: any =
        getpayment.balance == undefined ? [] : getpayment.balance;
      let objBalanceDetails: any = wlDepositDetail.filter(
        (x: any) => x.balanceType == "WEBLEAD"
      );
      if (objBalanceDetails != undefined && objBalanceDetails.length > 0) {
        if (Number(objBalanceDetails[0].balanceAmount) > 0) {
          wlDeposit = Number(objBalanceDetails[0].balanceAmount);
        } else {
          wlDeposit = 0.0;
        }
      }

      const Description = res.description == undefined ? "" : res.description;
      const Amount =
        Number(res.salesPrice) +
        Number(res.salesTax) +
        Number(res.nineOneOneFee);
      //(res.data.filter((e) => e.key=="Sales Price"))[0].value
      const salesTax = Number(res.salesTax);
      //(res.data.filter((e) => e.key=="Sales Tax"))[0].value
      const nineoneoneFee = Number(res.nineOneOneFee);
      const type = res.type;
      //(res.data.filter((e) => e.key=="911 Fee"))[0].value
      // const
      if (Number(res.editItemId) > 0 && Number(res.removePayment) == 0) {
        const editedItemInfo = paygridvalues.filter(
          (e) => Number(e.miscellaneousItemsNum) == Number(res.editItemId)
        );
        editedItemInfo[0].Agreementdesc = Description;
        editedItemInfo[0].Amount = Number(Amount);
        editedItemInfo[0].Taxamount = Number(salesTax);
        editedItemInfo[0].actualAmountDue = Number(Amount);
        editedItemInfo[0].nineOneOneFee = nineoneoneFee;
        editedItemInfo[0].cellPhoneActivation = Number(res.salesPrice);
        editedItemInfo[0].cellPhoneActivationTax = Number(salesTax);
        editedItemInfo[0].agreementRate = res.Amount;
        editedItemInfo[0].miscellaneousItemInfo = res;
        // nineOneOneFee: nineoneoneFee,
        // cellPhoneActivation: Number(Amount),
        // cellPhoneActivationTax: Number(salesTax),
      } else if (res.editItemId > 0 && Number(res.removePayment) == 1) {
        if (paygridvalues.length == 1) {
          selectallFlag = false;
        }
        paygridvalues = paygridvalues.filter(
          (e) => Number(e.miscellaneousItemsNum) != Number(res.editItemId)
        );
      } else {
        const itempaygridvalue = {
          AgreementNumber: "Adjust",
          Agreementdesc: Description,
          Agreementid: "",
          Agreementtype: type,
          Amount: Number(Amount),
          Autopay: "null",
          Autopay_enrollmentdate: null,
          COA: coa,
          CurrentDaysLate: 0,
          CurrentDueDate: "-",
          EPOAmount: 0,
          EPOTax: 0,
          EmailAddress: "",
          Latelast10: 0,
          Nextduedate: "-",
          Phonenumber: "",
          PromoFreeDays: 0,
          SAC_Date: "",
          Schedule: "",
          SuspenseAmount: 0,
          Taxamount: Number(salesTax),
          actualAmountDue: Number(Amount),
          agrLdwAmount: 0,
          agreementRate: res.Amount,
          agreementRateDetails: null,
          cardLastFour: 0,
          carriedLateFee: 0,
          carryRentAmountAdd: 0,
          carryRentAmountUsed: 0,
          carryRentTaxUsed: 0,
          clubCoverageTermination: null,
          clubFlag: true,
          clubMembershipNumber: "",
          clubName: "",
          clubactive: false,
          clublastLate10: 0,
          confirmeddeliversystatus: false,
          convTax: configFeeOnlyTax,
          coverageEndDate: "",
          deliveryAmount: 0,
          deliveryAmountTax: 0,
          dueDateModified: false,
          editSchedule: "",
          extensionAmount: 0,
          htSuspense: 0,
          ipSuspense: 0,
          lFeeTaxAmount: 0,
          suspenseAdd: 0,
          suspenseSub: 0,
          lateFee: 0,
          ldwPercentage: NaN,
          ldwTaxAmount: 0,
          mattressFee: 0,
          mattressFeeTax: 0,
          noOfDaysFree: NaN,
          number_of_paymentsmade: 0,
          partyId: "",
          policyAmount: 0,
          policyTaxAmount: 0,
          processingFee: 0,
          processingFeeTax: 0,
          racTirePay: 0,
          rpayTaxAmount: 0,
          sign_status: "",
          isZeroPayment: false,
          stateProvinceName:
            stateProvinceName != undefined ? stateProvinceName : "",
          //  configFlags.isPaymentFunctionsFeatureFlag == 1
          //    ? getpayment?.paymentInfo?.storeInfo?.stateProvinceName
          //    : "",
          storeCityName: storeCityName != undefined ? storeCityName : "",
          //  configFlags.isPaymentFunctionsFeatureFlag == 1
          //    ? getpayment?.paymentInfo?.storeInfo?.city
          //    : "",
          store_number: window.sessionStorage.getItem("storeNumber"),
          suspense: 0,
          taxGeoCode: "",
          taxRate: "",
          wlDeposit: wlDeposit,
          zipPlus4UsedForTax: "",
          nineOneOneFee: nineoneoneFee,
          transferOldSuspense: 0,
          cellPhoneActivation: Number(res.salesPrice),
          cellPhoneActivationTax: Number(salesTax),
          miscellaneousItemsNum: misNumber + 1,
          miscellaneousItemInfo: res,
          agreementNoteDetails: {
            noteAmountDueIncTax: "0.00",
            remainingNoteCost: "0.00",
            remainingAgreementCost: "0.00",
            isNoteAgreement: "0",
            noteCost: "0",
            editedNoteAmount: "",
          },
          accepoEPoAgrnoteInfo: undefined,
        };

        paygridvalues = [...paygridvalues, itempaygridvalue];
      }
    }
    setpaygridvalues(paygridvalues);
    //  resetPayment(0)
    // resetPayment(0)

    setCardrequire({
      ...Cardrequire,
      ConvAmt: String(Number(configFeeOnlyTax) + Number(ConvFeeWithOutTax)),
    });
    setconvAmtWTax(
      (Number(ConvFeeWithOutTax) + Number(configFeeOnlyTax)).toFixed(2)
    );
    //}
    // await selectallcheckbox(false)
    // selectallfunc()
    if (agreementId == "0" && selectallFlag && paygridvalues?.length != 0) {
      if (isRevenueRecogActivated) {
        selectallcheckboxRevenue(true);
      } else {
        selectallcheckbox(true);
      }
    }
    if (paygridvalues?.length == 0) {
      if (isRevenueRecogActivated) {
        selectallcheckboxRevenue(false);
      } else {
        selectallcheckbox(false);
      }
      resetPayment(undefined);
    }

    // selectallcheckbox(false)
    // selectallcheckbox(true)
    //  await selectallcheckbox(true)
    //  setmisNumberValue(true)
    //  BindGridCheckFunc()
  };

  const xBoxSale = async (res: any, editData: any) => {
    let selectallFlag = true;
    setReceiptCategory([]);
    ReceiptCategoriesRequestParam = [];
    acceptPaymentParam = [];
    AcceptclubPaymentParam = undefined;
    setAcceptClub(undefined);
    setOtherIncomeHidden(true);
    setnonInventoryPopUp(false);
    otherIncomeOnblur();
    setEditNoninventoryData(undefined);
    setXboxEditData(undefined);
    setAcimaEditData(undefined);
    let misNumber = 0;
    for (let i = 0; i < paygridvalues.length; i++) {
      let addNum = paygridvalues[i].miscellaneousItemsNum > 0 ? 1 : 0;
      misNumber = misNumber + addNum;
    }
    if (res != undefined) {
      let coa = 0.0;
      let suspense = 0.0;
      let htSuspense = 0.0;
      let ipSuspense = 0.0;
      let wlDeposit = 0.0;

      if (
        getpayment.paymentInfo.remainingCredits !== undefined &&
        getpayment.paymentInfo.remainingCredits.length > 0
      ) {
        let rcInfo = getpayment.paymentInfo.remainingCredits;
        let rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "coa");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          COAamount = rcData[0].accountBalance;
          coa = Number(COAamount);
        }
        rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "suspense");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          suspense = Number(rcData[0].accountBalance);
        }

        rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "ht suspense");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          htSuspense = Number(rcData[0].accountBalance);
        }
        rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "ip suspense");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          ipSuspense = Number(rcData[0].accountBalance);
        }
      }

      let wlDepositDetail: any =
        getpayment.balance == undefined ? [] : getpayment.balance;
      let objBalanceDetails: any = wlDepositDetail.filter(
        (x: any) => x.balanceType == "WEBLEAD"
      );

      if (objBalanceDetails != undefined && objBalanceDetails.length > 0) {
        if (Number(objBalanceDetails[0].balanceAmount) > 0) {
          wlDeposit = Number(objBalanceDetails[0].balanceAmount);
        } else {
          wlDeposit = 0.0;
        }
      }

      if (Number(res.editItemId) > 0 && Number(res.removePayment) == 0) {
        const editedItemInfo = paygridvalues.filter(
          (e) => Number(e.miscellaneousItemsNum) == Number(res.editItemId)
        );
        editedItemInfo[0].Agreementdesc = res.description;
        editedItemInfo[0].Agreementtype = res.type;
        editedItemInfo[0].otherIncomeType = res.type;
        editedItemInfo[0].Amount = Number(res.totalSales);
        editedItemInfo[0].Taxamount = Number(res.taxPrice);
        editedItemInfo[0].actualAmountDue = Number(res.totalSales);
        // editedItemInfo[0].agreementRate = Number(res.Amount);
      } else if (Number(res.editItemId) > 0 && Number(res.removePayment) == 1) {
        if (paygridvalues.length == 1) {
          selectallFlag = false;
        }
        paygridvalues = paygridvalues.filter(
          (e) => Number(e.miscellaneousItemsNum) != Number(res.editItemId)
        );
      } else {
        const itempaygridvalue = {
          AgreementNumber: "Adjust",
          Agreementdesc: res.description,
          Agreementid: Number(misNumber + 1).toFixed(),
          Agreementtype: res.type,
          otherIncomeType: res.type,
          Amount: Number(res.totalSales),
          Autopay: "null",
          Autopay_enrollmentdate: null,
          COA: coa,
          CurrentDaysLate: 0,
          CurrentDueDate: "-",
          EPOAmount: 0,
          EPOTax: 0,
          EmailAddress: "",
          Latelast10: 0,
          Nextduedate: "-",
          Phonenumber: "",
          PromoFreeDays: 0,
          SAC_Date: "",
          Schedule: "",
          SuspenseAmount: 0,
          Taxamount: Number(res.taxPrice),
          actualAmountDue: Number(res.totalSales),
          agrLdwAmount: 0,
          agreementRate: "",
          agreementRateDetails: null,
          cardLastFour: 0,
          carriedLateFee: 0,
          carryRentAmountAdd: 0,
          carryRentAmountUsed: 0,
          carryRentTaxUsed: 0,
          clubCoverageTermination: null,
          clubFlag: true,
          clubMembershipNumber: "",
          clubName: "",
          clubactive: false,
          clublastLate10: 0,
          confirmeddeliversystatus: false,
          convTax: 0,
          coverageEndDate: "",
          deliveryAmount: 0,
          deliveryAmountTax: 0,
          dueDateModified: false,
          editSchedule: "",
          extensionAmount: 0,
          htSuspense: 0,
          ipSuspense: 0,
          lFeeTaxAmount: 0,
          suspenseAdd: 0,
          suspenseSub: 0,
          lateFee: 0,
          ldwPercentage: 0,
          ldwTaxAmount: 0,
          mattressFee: 0,
          mattressFeeTax: 0,
          noOfDaysFree: 0,
          number_of_paymentsmade: 0,
          partyId: "",
          policyAmount: 0,
          policyTaxAmount: 0,
          processingFee: 0,
          processingFeeTax: 0,
          racTirePay: 0,
          rpayTaxAmount: 0,
          sign_status: "",
          isZeroPayment: false,
          stateProvinceName:
            stateProvinceName != undefined ? stateProvinceName : "",
          //  configFlags.isPaymentFunctionsFeatureFlag == 1
          //    ? getpayment?.paymentInfo?.storeInfo?.stateProvinceName
          //    : "",
          storeCityName: storeCityName != undefined ? storeCityName : "",
          //  configFlags.isPaymentFunctionsFeatureFlag == 1
          //    ? getpayment?.paymentInfo?.storeInfo?.city
          //    : "",
          store_number: window.sessionStorage.getItem("storeNumber"),
          suspense: 0,
          taxGeoCode: "",
          taxRate: "",
          wlDeposit: wlDeposit,
          zipPlus4UsedForTax: "",
          nineOneOneFee: 0,
          transferOldSuspense: 0,
          cellPhoneActivation: Number(0),
          cellPhoneActivationTax: Number(0),
          miscellaneousItemsNum: misNumber + 1,
          miscellaneousItemInfo: res,
          agreementNoteDetails: {
            noteAmountDueIncTax: "0.00",
            remainingNoteCost: "0.00",
            remainingAgreementCost: "0.00",
            isNoteAgreement: "0",
            noteCost: "0",
            editedNoteAmount: "",
          },
          accepoEPoAgrnoteInfo: undefined,
        };
        paygridvalues = [...paygridvalues, itempaygridvalue];
        editData.miscellaneousItemsNum = misNumber + 1;
        xBoxEditDataCollected.push(editData);
        setXboxEditDataCollected(xBoxEditDataCollected);
      }
    }

    setpaygridvalues(paygridvalues);
    setCardrequire({
      ...Cardrequire,
      ConvAmt: String(Number(configFeeOnlyTax) + Number(ConvFeeWithOutTax)),
    });
    setconvAmtWTax(
      (Number(ConvFeeWithOutTax) + Number(configFeeOnlyTax)).toFixed(2)
    );

    if (agreementId == "0" && selectallFlag && paygridvalues?.length != 0) {
      if (isRevenueRecogActivated) {
        selectallcheckboxRevenue(true);
      } else {
        selectallcheckbox(true);
      }
    }

    if (paygridvalues?.length == 0) {
      if (isRevenueRecogActivated) {
        selectallcheckboxRevenue(false);
      } else {
        selectallcheckbox(false);
      }
      resetPayment(undefined);
    }
  };

  const acimaSale = async (res: any, editData: any) => {
    let selectallFlag = true;
    setReceiptCategory([]);
    ReceiptCategoriesRequestParam = [];
    acceptPaymentParam = [];
    AcceptclubPaymentParam = undefined;
    setAcceptClub(undefined);
    setOtherIncomeHidden(true);
    setnonInventoryPopUp(false);
    otherIncomeOnblur();
    setEditNoninventoryData(undefined);
    setXboxEditData(undefined);
    setAcimaEditData(undefined);
    let misNumber = 0;
    for (let i = 0; i < paygridvalues.length; i++) {
      let addNum = paygridvalues[i].miscellaneousItemsNum > 0 ? 1 : 0;
      misNumber = misNumber + addNum;
    }
    if (res != undefined) {
      let coa = 0.0;
      let suspense = 0.0;
      let htSuspense = 0.0;
      let ipSuspense = 0.0;
      let wlDeposit = 0.0;

      if (
        getpayment.paymentInfo.remainingCredits !== undefined &&
        getpayment.paymentInfo.remainingCredits.length > 0
      ) {
        let rcInfo = getpayment.paymentInfo.remainingCredits;
        let rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "coa");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          COAamount = rcData[0].accountBalance;
          coa = Number(COAamount);
        }
        rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "suspense");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          suspense = Number(rcData[0].accountBalance);
        }

        rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "ht suspense");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          htSuspense = Number(rcData[0].accountBalance);
        }
        rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "ip suspense");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          ipSuspense = Number(rcData[0].accountBalance);
        }
      }

      let wlDepositDetail: any =
        getpayment.balance == undefined ? [] : getpayment.balance;
      let objBalanceDetails: any = wlDepositDetail.filter(
        (x: any) => x.balanceType == "WEBLEAD"
      );

      if (objBalanceDetails != undefined && objBalanceDetails.length > 0) {
        if (Number(objBalanceDetails[0].balanceAmount) > 0) {
          wlDeposit = Number(objBalanceDetails[0].balanceAmount);
        } else {
          wlDeposit = 0.0;
        }
      }

      if (Number(res.editItemId) > 0 && Number(res.removePayment) == 0) {
        const editedItemInfo = paygridvalues.filter(
          (e) => Number(e.miscellaneousItemsNum) == Number(res.editItemId)
        );
        editedItemInfo[0].Agreementdesc = res.description;
        editedItemInfo[0].Agreementtype = res.type;
        editedItemInfo[0].otherIncomeType = res.type;
        editedItemInfo[0].Amount = Number(res.totalSales);
        editedItemInfo[0].Taxamount = Number(res.taxPrice);
        editedItemInfo[0].actualAmountDue = Number(res.totalSales);
        editedItemInfo[0].deliveryAmountTax = Number(res.deliveryFeeTax);
        editedItemInfo[0].deliveryAmount = Number(res.deliveryFee);
        // editedItemInfo[0].agreementRate = Number(res.Amount);
      } else if (Number(res.editItemId) > 0 && Number(res.removePayment) == 1) {
        if (paygridvalues.length == 1) {
          selectallFlag = false;
        }
        paygridvalues = paygridvalues.filter(
          (e) => Number(e.miscellaneousItemsNum) != Number(res.editItemId)
        );
      } else {
        const itempaygridvalue = {
          AgreementNumber: "Adjust",
          Agreementdesc: res.description,
          Agreementid: Number(misNumber + 1).toFixed(),
          Agreementtype: res.type,
          otherIncomeType: res.type,
          Amount: Number(res.totalSales),
          Autopay: "null",
          Autopay_enrollmentdate: null,
          COA: coa,
          CurrentDaysLate: 0,
          CurrentDueDate: "-",
          EPOAmount: 0,
          EPOTax: 0,
          EmailAddress: "",
          Latelast10: 0,
          Nextduedate: "-",
          Phonenumber: "",
          PromoFreeDays: 0,
          SAC_Date: "",
          Schedule: "",
          SuspenseAmount: 0,
          Taxamount: Number(res.taxPrice),
          actualAmountDue: Number(res.totalSales),
          agrLdwAmount: 0,
          agreementRate: "",
          agreementRateDetails: null,
          cardLastFour: 0,
          carriedLateFee: 0,
          carryRentAmountAdd: 0,
          carryRentAmountUsed: 0,
          carryRentTaxUsed: 0,
          clubCoverageTermination: null,
          clubFlag: true,
          clubMembershipNumber: "",
          clubName: "",
          clubactive: false,
          clublastLate10: 0,
          confirmeddeliversystatus: false,
          convTax: 0,
          coverageEndDate: "",
          deliveryAmount: Number(res.deliveryFee),
          deliveryAmountTax: Number(res.deliveryFeeTax),
          dueDateModified: false,
          editSchedule: "",
          extensionAmount: 0,
          htSuspense: 0,
          ipSuspense: 0,
          lFeeTaxAmount: 0,
          suspenseAdd: 0,
          suspenseSub: 0,
          lateFee: 0,
          ldwPercentage: 0,
          ldwTaxAmount: 0,
          mattressFee: 0,
          mattressFeeTax: 0,
          noOfDaysFree: 0,
          number_of_paymentsmade: 0,
          partyId: "",
          policyAmount: 0,
          policyTaxAmount: 0,
          processingFee: 0,
          processingFeeTax: 0,
          racTirePay: 0,
          rpayTaxAmount: 0,
          sign_status: "",
          isZeroPayment: false,
          stateProvinceName:
            stateProvinceName != undefined ? stateProvinceName : "",
          //  configFlags.isPaymentFunctionsFeatureFlag == 1
          //    ? getpayment?.paymentInfo?.storeInfo?.stateProvinceName
          //    : "",
          storeCityName: storeCityName != undefined ? storeCityName : "",
          //  configFlags.isPaymentFunctionsFeatureFlag == 1
          //    ? getpayment?.paymentInfo?.storeInfo?.city
          //    : "",
          store_number: window.sessionStorage.getItem("storeNumber"),
          suspense: 0,
          taxGeoCode: "",
          taxRate: "",
          wlDeposit: wlDeposit,
          zipPlus4UsedForTax: "",
          nineOneOneFee: 0,
          transferOldSuspense: 0,
          cellPhoneActivation: Number(0),
          cellPhoneActivationTax: Number(0),
          miscellaneousItemsNum: misNumber + 1,
          miscellaneousItemInfo: res,
          agreementNoteDetails: {
            noteAmountDueIncTax: "0.00",
            remainingNoteCost: "0.00",
            remainingAgreementCost: "0.00",
            isNoteAgreement: "0",
            noteCost: "0",
            editedNoteAmount: "",
          },
          accepoEPoAgrnoteInfo: undefined,
        };
        paygridvalues = [...paygridvalues, itempaygridvalue];
        editData.miscellaneousItemsNum = misNumber + 1;
        acimaEditDataCollected.push(editData);
        setAcimaEditDataCollected(acimaEditDataCollected);
      }
    }

    setpaygridvalues(paygridvalues);
    setCardrequire({
      ...Cardrequire,
      ConvAmt: String(Number(configFeeOnlyTax) + Number(ConvFeeWithOutTax)),
    });
    setconvAmtWTax(
      (Number(ConvFeeWithOutTax) + Number(configFeeOnlyTax)).toFixed(2)
    );

    if (agreementId == "0" && selectallFlag && paygridvalues?.length != 0) {
      if (isRevenueRecogActivated) {
        selectallcheckboxRevenue(true);
      } else {
        selectallcheckbox(true);
      }
    }

    if (paygridvalues?.length == 0) {
      if (isRevenueRecogActivated) {
        selectallcheckboxRevenue(false);
      } else {
        selectallcheckbox(false);
      }
      resetPayment(undefined);
    }
  };
  useEffect(() => {
    if (paygridvalues != undefined && paygridvalues.length > 0) {
      if (agreementId != "0") {
        // let remainingAmount =0.00
        if(nonInventoryPopUp==true){
          if (isRevenueRecogActivated) {
            selectallcheckboxRevenue(false);
          } else {
            selectallcheckbox(false);
          }
        }
          totalduereceiptidsupport=[]
          totalduereceiptid=[]
          settotalduereceiptid(totalduereceiptid)  
        // selectallcheckbox(false);
        let totalTax = 0.0;
        let totalAmountDue = 0.0;
        let count = 0;
        let clubChecked = false;
        // let receiptItemArray=[]
        adjustDueDateSelectedAgre.push(agreementId);
        let clubvalue = paygridvalues.filter(
          (x) => x.Agreementtype == "Club" && x.number_of_paymentsmade == 0
        );
        totalAmountDue =
          totalAmountDue +
          Number(
            paygridvalues.filter(
              (x) => String(x.Agreementid) == String(agreementId)
            )[0].Amount
          );
        totalTax =
          totalTax +
          Number(
            paygridvalues.filter(
              (x) => String(x.Agreementid) == String(agreementId)
            )[0].Taxamount
          );
        // setSelectedCheckBoxCount(1)
        count = count + 1;
        if (clubvalue.length > 0) {
          adjustDueDateSelectedAgre.push(clubvalue[0].Agreementid);
          // setSelectedCheckBoxCount(2)

          clubChecked = true;
          // count = count + 1;
          // totalAmountDue = totalAmountDue + Number(clubvalue[0].Amount);
          // totalTax = totalTax + Number(clubvalue[0].Taxamount);
        }
        // for (let i = 0; i < paygridvalues.length; i++) {
        //   if (paygridvalues[i].miscellaneousItemsNum > 0) {
        //     adjustDueDateSelectedAgre.push(paygridvalues[i].Agreementid);
        //     count = count + 1;
        //     totalAmountDue = totalAmountDue + Number(paygridvalues[i].Amount);
        //     totalTax = totalTax + Number(paygridvalues[i].Taxamount);
        //   }
        // }
        // const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
        //   return e.sustype == "Add" && Number(e.newsuspense) > 0;
        // });
        // const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
        //   return e.sustype == "Used" && Number(e.newsuspense) > 0;
        // });
        // let addSusTotalAmount =
        //   addSuspenseAmount.length == 0
        //     ? 0.0
        //     : addSuspenseAmount
        //         .map((bill) => bill.newsuspense)
        //         .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
        // let usedSusTotalAmount =
        //   useSuspenseAmount.length == 0
        //     ? 0.0
        //     : useSuspenseAmount
        //         .map((bill) => bill.newsuspense)
        //         .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

        // let resTotalAmount = 0.0;
        // resTotalAmount =
        //   totalduetendertype.length == 0
        //     ? 0.0
        //     : totalduetendertype
        //         .map((bill) => bill.Amount)
        //         .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
        // let remainingAmt =
        //   totalAmountDue -
        //   Number(availableCreds) +
        //   Number(donationAmountAdd) +
        //   Number(addSusTotalAmount) -
        //   Number(usedSusTotalAmount) -
        //   Number(resTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount);
        // settotaldue(totalAmountDue);
        // settotaltax(totalTax);
        // if (remainingAmt > 0) {
        //   setremainingamountdue(remainingAmt);
        //   setRemaniningAmtStatic(remainingAmt);
        //   setAcceptpaymentbuttonenable(true);
        // } else {
        //   setremainingamountdue(0.0);
        //   setRemaniningAmtStatic(0.0);
        //   setchange(Number(remainingAmt));
        //   setAcceptpaymentbuttonenable(false);
        // }

        let checkBoxArray = document.getElementsByName("grid");
        //let objAmt = 0;
        setSelectedCheckBoxCount(0);
        checkBoxArray.forEach(function (item, index) {
          let obj = item as HTMLInputElement;
          let checkBox = obj;

          if (
            paygridvalues[index].Agreementtype == "Club" &&
            clubChecked == true
          ) {
            // let agreementDetails = {
            //   agreement_id: paygridvalues[index].AgreementNumber=="Adjust"?"Miscellaneous Item "+paygridvalues[index].miscellaneousItemsNum:paygridvalues[index].AgreementNumber,
            //   agreement_totalamount: parseFloat(fixedChange(paygridvalues[index].Amount)),
            // };
            // totalduereceiptidsupport.push(agreementDetails)
            checkBox.checked = true;
            settotaldueamount(
              "chk" + index,
              index,
              paygridvalues[index].Amount,
              paygridvalues[index].Agreementid,
              undefined
            );
          } else if (paygridvalues[index].Agreementid == String(agreementId)) {
            // let agreementDetails = {
            //   agreement_id: paygridvalues[index].AgreementNumber=="Adjust"?"Miscellaneous Item "+paygridvalues[index].miscellaneousItemsNum:paygridvalues[index].AgreementNumber,
            //   agreement_totalamount: parseFloat(fixedChange(paygridvalues[index].Amount)),
            // };
            // totalduereceiptidsupport.push(agreementDetails)
            checkBox.checked = true;
            settotaldueamount(
              "chk" + index,
              index,
              paygridvalues[index].Amount,
              paygridvalues[index].Agreementid,
              undefined
            );
          } else if (paygridvalues[index].miscellaneousItemsNum > 0) {
            // let agreementDetails = {
            //   agreement_id: paygridvalues[index].AgreementNumber=="Adjust"?"Miscellaneous Item "+paygridvalues[index].miscellaneousItemsNum:paygridvalues[index].AgreementNumber,
            //   agreement_totalamount: parseFloat(fixedChange(paygridvalues[index].Amount)),
            // };
            // totalduereceiptidsupport.push(agreementDetails)
            checkBox.checked = true;
            settotaldueamount(
              "chk" + index,
              index,
              paygridvalues[index].Amount,
              paygridvalues[index].Agreementid,
              undefined
            );
          }
          // settotalduereceiptid(totalduereceiptidsupport);
          setSelectedCheckBoxCount(count);
          adjustDueDateSelectedAgre = Array.from(
            new Set(adjustDueDateSelectedAgre)
          );
          setadjustDueDateSelectedAgre(adjustDueDateSelectedAgre);
          // if (isRevenueRecogActivated) {
          //   multipleAdjustRevenue();
          // } else {
            multipleAdjust();
          // }
        });

        // nonInventoryPaymentRequest()
      } else {
        let checkBoxArray = document.getElementsByName("grid");
        //let objAmt = 0;
        checkBoxArray.forEach(function (item, index) {
          let obj = item as HTMLInputElement;
          let checkBox = obj;
          checkBox.checked = true;
        });
      }
    } else if (paygridvalues != undefined && paygridvalues.length == 0) {
      settotaldue(0.0);
      settotaltax(0.0);
    }
  }, [nonInventoryPopUp]);

  // const nonInventoryPaymentRequest =()=>{
  //   let clubvalue = paygridvalues.filter((x) => x.Agreementtype=="Club"&&x.number_of_paymentsmade==0 );
  //   totalAmountDue=totalAmountDue+Number( paygridvalues.filter((x) => String(x.Agreementid)==String(agreementId))[0].Amount)
  //   if(clubvalue.length>0){

  //   }

  // }

  /**PC_CO_18 onclick function for editing the amount in charge-off pop-up */
  //  val1:any,val2:any
  const redirectionChargeoff = () => {
    // debugger;
    setchargeoffPopupFlag(true);
    let datas: any = [];
    // checkedAgr.map((x,index)=>{
    //  let datasArray= paygridvalues.filter((val)=>val.Agreementid== checkedAgr[index].Agreementid && val.Agreementtype==checkedAgr[index].Agreementtype)
    //  datas=[...datas,...datasArray]
    // })
    let datasArray = checkedAgr.filter(
      (el) => el.Agreementtype == "Restitution"
    );

    const editItemvalues: any = [...chargeoffInfo];
    let edititemValueArray: any = [];
    for (let i = 0; i < datasArray.length; i++) {
      editItemvalues.map((x, index) => {
        if (x.AgreementID == datasArray[i].Agreementid) {
          editItemvalues[index].Amount = Number(datasArray[i].Amount).toFixed(
            2
          );
          editItemvalues[index].Reason = datasArray[i].Agreementdesc;
          editItemvalues[index].ReasonRefcode = datasArray[i].ReasonRefcode;
        }
      });
      let array = editItemvalues.filter(
        (x) => x.AgreementID == datasArray[i].Agreementid
      );
      edititemValueArray = [...edititemValueArray, ...array];
    }
    seteditchargeoffdatas(edititemValueArray);
    setEditChargeoffFlag(true);
  };

  /**PC_CO_16 The response received from the charge-off component is passed into
   * the chargeoffonclick() function
   * PC_CO_20 The edited response from charge-off component is passed into the chargeoffonclick() function
   */
  const chargeoffOnclick = async (res: any) => {
    // debugger;

    let selectallFlag = true;
    setOtherIncomeHidden(true);
    

    if (res) {
      setadjustChargeoffFlag(false);
    }

    if (res != undefined) {
      setadjustChargeoffFlag(true);
      let coa = 0.0;
      let suspense = 0.0;
      let htSuspense = 0.0;
      let ipSuspense = 0.0;
      let wlDeposit = 0.0;

      if (
        getpayment.paymentInfo.remainingCredits !== undefined &&
        getpayment.paymentInfo.remainingCredits.length > 0
      ) {
        let rcInfo = getpayment.paymentInfo.remainingCredits;
        let rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "coa");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          COAamount = rcData[0].accountBalance;
          coa = Number(COAamount);
        }
        rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "suspense");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          suspense = Number(rcData[0].accountBalance);
        }

        rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "ht suspense");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          htSuspense = Number(rcData[0].accountBalance);
        }
        rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "ip suspense");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          ipSuspense = Number(rcData[0].accountBalance);
        }
      }
      let wlDepositDetail: any =
        getpayment.balance == undefined ? [] : getpayment.balance;
      let objBalanceDetails: any = wlDepositDetail.filter(
        (x: any) => x.balanceType == "WEBLEAD"
      );
      if (objBalanceDetails != undefined && objBalanceDetails.length > 0) {
        if (Number(objBalanceDetails[0].balanceAmount) > 0) {
          wlDeposit = Number(objBalanceDetails[0].balanceAmount);
        } else {
          wlDeposit = 0.0;
        }
      }

      // const Description = res.description == undefined ? "" : res.description;
      const nineoneoneFee = 0;
      //  Number(res.nineoneFee);
      // const AgreementNumber=res.AgreementNumber;
      // const AgreementID=res.Agreementid;
      // const Amount=res.Amount;
      // const salesTax=Number(Amount)*0.0825;
      // const type=res.type;
      let lineItem: any = [];
      if (res.length > 0) {
        let chargeoffInfoData = [...chargeoffInfo];
        for (let i = 0; i < res.length; i++) {
          for (let index = 0; index < chargeoffInfoData.length; index++) {
            if (chargeoffInfoData[index].AgreementID == res[i].AgreementID) {
              chargeoffInfoData[index].Amount = res[i].Amount;
              chargeoffInfoData[index].Reason = res[i].Reason;
              chargeoffInfoData[index].ReasonRefcode = res[i].ReasonRefcode;
            }
          }
          if (res[i].editItemID > 0 && Number(res[i].removePayment) == 0) {
            let editItemInfo = paygridvalues.filter(
              (val) => val.Agreementid == res[i].AgreementID
            );
            editItemInfo[0].Agreementdesc = res[i].Reason;
            editItemInfo[0].Amount = Number(res[i].Amount);
            editItemInfo[0].Taxamount = Number(
              res[i].newTax != undefined ? res[i].newTax : 0
            );
            editItemInfo[0].actualAmountDue = Number(res[i].Amount);
            editItemInfo[0].nineOneOneFee = nineoneoneFee;
            editItemInfo[0].ReasonRefcode = res[i].ReasonRefcode;
            editItemInfo[0].agreementRate = res[i].Amount;
            editItemInfo[0].restitutionInfo = res;
            editItemInfo[0].Agreementtype = res[i].type;
          }
          // else if( res.editItemID > 0 && Number(res.removePayment)==1){
          //   if (paygridvalues.length == 1) {
          //     selectallFlag = false;
          //   }
          //   paygridvalues = paygridvalues.filter(
          //     (e) =>e.Agreementid != res.Agreementid
          //   );
          //   setadjustChargeoffFlag(false);
          // }
          else {
            // for(let i=0;i<res.length;i++)
            // {
            let index = paygridvalues.findIndex(
              (x) => x.Agreementid == res[i].AgreementID
            );
            if (index >= 0) {
              // let checkBoxArray = document.getElementsByName("grid");
              // let obj = checkBoxArray[index] as HTMLInputElement;
              // let checkBox = obj;
              // if(checkBox.checked == true )
              // {
              //   checkBox.checked = false
              // }
              let checkedIndex = checkedAgr.findIndex(
                (x) => x.Agreementid == paygridvalues[index].Agreementid
              );
              if (checkedIndex >= 0) {
                checkedAgr[checkedIndex].Amount = Number(res[i].Amount);
                checkedAgr[checkedIndex].Agreementdesc = res[i].Reason;
                checkedAgr[checkedIndex].ReasonRefcode = res[i].ReasonRefcode;
                checkedAgr[checkedIndex].Taxamount = Number(
                  res[i].newTax != undefined ? res[i].newTax : 0
                );
                checkedAgr[checkedIndex].actualAmountDue = Number(
                  res[i].Amount
                );
                checkedAgr[checkedIndex].agreementRate = res[i].Amount;
                checkedAgr[checkedIndex].restitutionInfo = res;
                checkedAgr[checkedIndex].Agreementtype = res[i].type;
              }
              paygridvalues[index].Amount = Number(res[i].Amount);
              paygridvalues[index].Agreementdesc = res[i].Reason;
              paygridvalues[index].ReasonRefcode = res[i].ReasonRefcode;
              paygridvalues[index].Taxamount = Number(
                res[i].newTax != undefined ? res[i].newTax : 0
              );
              paygridvalues[index].actualAmountDue = Number(res[i].Amount);
              paygridvalues[index].agreementRate = res[i].Amount;
              paygridvalues[index].restitutionInfo = res;
              paygridvalues[index].Agreementtype = res[i].type;

              // await settotaldueamount(
              //   "chk" + index,
              //   index,
              //   paygridvalues[index].Amount,
              //   paygridvalues[index].Agreementid,
              //   undefined
              // );
              // paygridvalues = [...paygridvalues];
              // setpaygridvalues(paygridvalues)
            } else {
              const chargeoffitempaygridvalue = {
                AgreementNumber: res[i].AgreementNo,
                Agreementdesc: res[i].Reason,
                ReasonRefcode: res[i].ReasonRefcode,
                Agreementid: res[i].AgreementID,
                Agreementtype: "Restitution",
                Amount: Number(res[i].Amount),
                Autopay: "null",
                Autopay_enrollmentdate: null,
                COA: coa,
                CurrentDaysLate: 0,
                CurrentDueDate: "-",
                EPOAmount: 0,
                EPOTax: 0,
                EmailAddress: "",
                Latelast10: 0,
                Nextduedate: "PIF",
                Phonenumber: "",
                PromoFreeDays: 0,
                SAC_Date: "",
                Schedule: "",
                SuspenseAmount: 0,
                Taxamount: Number(
                  res[i].newTax == undefined ? 0 : res[i].newTax
                ),
                // Number(salesTax),
                actualAmountDue: Number(res[i].Amount),
                agrLdwAmount: 0,
                agreementRate: res.Amount,
                agreementRateDetails: null,
                cardLastFour: 0,
                carriedLateFee: 0,
                carryRentAmountAdd: 0,
                carryRentAmountUsed: 0,
                carryRentTaxUsed: 0,
                clubCoverageTermination: null,
                clubFlag: true,
                clubMembershipNumber: "",
                clubName: "",
                clubactive: false,
                clublastLate10: 0,
                confirmeddeliversystatus: false,
                convTax: configFeeOnlyTax,
                coverageEndDate: "",
                deliveryAmount: 0,
                deliveryAmountTax: 0,
                dueDateModified: false,
                editSchedule: "",
                extensionAmount: 0,
                htSuspense: 0,
                ipSuspense: 0,
                lFeeTaxAmount: 0,
                suspenseAdd: 0,
                suspenseSub: 0,
                lateFee: 0,
                ldwPercentage: NaN,
                ldwTaxAmount: 0,
                mattressFee: 0,
                mattressFeeTax: 0,
                noOfDaysFree: NaN,
                number_of_paymentsmade: 0,
                partyId: "",
                policyAmount: 0,
                policyTaxAmount: 0,
                processingFee: 0,
                processingFeeTax: 0,
                racTirePay: 0,
                rpayTaxAmount: 0,
                sign_status: "",
                stateProvinceName:
                  stateProvinceName != undefined ? stateProvinceName : "",
                // configFlags.isPaymentFunctionsFeatureFlag == 1
                //   ? getpayment?.paymentInfo?.cashSaleInfo?.stateProvinceName
                //   : "",
                storeCityName: storeCityName != undefined ? storeCityName : "",
                // configFlags.isPaymentFunctionsFeatureFlag == 1
                //   ? getpayment?.paymentInfo?.cashSaleInfo?.city
                //   : "",
                store_number: String(
                  window.sessionStorage.getItem("storeNumber")
                ),
                suspense: 0,
                taxGeoCode: "",
                taxRate: "",
                wlDeposit: wlDeposit,
                zipPlus4UsedForTax: "",
                otherIncomeType: "Charge-off",
                nineOneOneFee: nineoneoneFee,
                // cellPhoneActivation: Number(res.salesPrice),
                cellPhoneActivationTax: 0,
                //  Number(salesTax),
                // miscellaneousItemsNum: misNumber + 1,
                restitutiontypeAgree: 1,
                restitutionInfo: res,
              };
              lineItem.push(chargeoffitempaygridvalue);

              // }
            }
          }
        }
        setchargeoffInfo(chargeoffInfoData);
        let currentstorenumber = window.sessionStorage.getItem("storeNumber");
        let Clubagreementinsertion = paygridvalues.filter(
          (x) => x.Agreementtype === "Club"
        );
        let curStoreData = paygridvalues.filter(
          (x) =>
            x.store_number.toString() === currentstorenumber &&
            x.Agreementtype !== "Club"
        );
        Clubagreementinsertion.push(...curStoreData, ...lineItem);
        let otherStoreData = paygridvalues.filter(
          (x) =>
            x.store_number.toString() !== currentstorenumber &&
            x.Agreementtype !== "Club"
        );
        Clubagreementinsertion.push(...otherStoreData);
        paygridvalues = Clubagreementinsertion;
        // paygridvalues = [...paygridvalues, ...lineItem];
      }
    }
    // else if(res==undefined)
    // {
    //   setadjustChargeoffFlag(false);
    // }
    setCardrequire({
      ...Cardrequire,
      ConvAmt: String(Number(configFeeOnlyTax) + Number(ConvFeeWithOutTax)),
    });
    setconvAmtWTax(
      (Number(ConvFeeWithOutTax) + Number(configFeeOnlyTax)).toFixed(2)
    );
    // if (agreementId == "0" && selectallFlag) {
    //   selectallcheckbox(true);
    // }
    otherIncomeOnblur();
    setcheckedAgr(checkedAgr);
    setpaygridvalues(paygridvalues);
    chargeoffPopupFlag=false
    setchargeoffPopupFlag(chargeoffPopupFlag);
    seteditchargeoffdatas(undefined);
  };

  useEffect(() => {
    // debugger;
    if (chargeoffPopupFlag == false) {
      let checkBoxArray = document.getElementsByName("grid");
      for (let index = 0; index < checkBoxArray.length; index++) {
        let obj = checkBoxArray[index] as HTMLInputElement;
        let checkBox = obj;
        let agreementType = paygridvalues.filter(
          (x) => x.Agreementtype == "Restitution"
        );
        let checkedAgreementtype = checkedAgr.filter(
          (x) => x.Agreementtype == "Restitution"
        );

        if (paygridvalues[index].Agreementtype == "Restitution") {
          if (
            checkBox.checked == false &&
            !editChargeoffFlag
            // &&agreementType.length==checkedAgreementtype.length
          ) {
            checkBox.checked = true;
            settotaldueamount(
              "chk" + index,
              index,
              paygridvalues[index].Amount,
              paygridvalues[index].Agreementid,
              undefined
            );
          } else if (checkBox.checked == true) {
            editAdjustRes({ resArray: checkedAgr });
          }
        }
      }
      // if (isRevenueRecogActivated) {
      //   multipleAdjustRevenue();
      // } else {
        multipleAdjust();
      // }
    }
  }, [chargeoffPopupFlag]);

  const redirectionToBackRent = () => {
    let data = paygridvalues.filter((x) => x.Agreementtype == "Back Rent");
    let Amount = Number(data[0].Amount) - Number(data[0].Taxamount);
    let obj = {
      backRentOwed: backRentInfo.backRentOwed,
      amount: Number(Amount).toFixed(2),
      salesTax: Number(data[0].Taxamount).toFixed(2),
      taxRate: backRentTax,
      total: Number(data[0].Amount).toFixed(2),
    };
    setbackRentEditInfo(obj);
    setBackRentPopUp(true);
  };

  const otherIncomeItems = (res: any) => {
    // debugger
    let selectallFlag = true;
    // setBackRentPopUp(false);
    let misNumber = 0;
    if (res != undefined) {
      let coa = 0.0;
      let suspense = 0.0;
      let htSuspense = 0.0;
      let ipSuspense = 0.0;
      let wlDeposit = 0.0;

      if (
        getpayment.paymentInfo.remainingCredits !== undefined &&
        getpayment.paymentInfo.remainingCredits.length > 0
      ) {
        let rcInfo = getpayment.paymentInfo.remainingCredits;
        let rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "coa");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          COAamount = rcData[0].accountBalance;
          coa = Number(COAamount);
        }
        rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "suspense");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          suspense = Number(rcData[0].accountBalance);
        }

        rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "ht suspense");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          htSuspense = Number(rcData[0].accountBalance);
        }
        rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "ip suspense");
        if (
          rcData != undefined &&
          rcData.length > 0 &&
          rcData[0].accountBalance > 0
        ) {
          ipSuspense = Number(rcData[0].accountBalance);
        }
      }
      let wlDepositDetail: any =
        getpayment.balance == undefined ? [] : getpayment.balance;
      let objBalanceDetails: any = wlDepositDetail.filter(
        (x: any) => x.balanceType == "WEBLEAD"
      );
      if (objBalanceDetails != undefined && objBalanceDetails.length > 0) {
        if (Number(objBalanceDetails[0].balanceAmount) > 0) {
          wlDeposit = Number(objBalanceDetails[0].balanceAmount);
        } else {
          wlDeposit = 0.0;
        }
      }
      // debugger;
      const Description = res.type == "Back Rent" ? "Back Rent Collected" : "";
      const Amount = Number(res.total);
      //(res.data.filter((e) => e.key=="Sales Price"))[0].value
      const salesTax = Number(res.salesTax);
      //(res.data.filter((e) => e.key=="Sales Tax"))[0].value
      const nineoneoneFee = 0;
      //  Number(res?.nineOneOneFee);
      const type = res.type;
      //(res.data.filter((e) => e.key=="911 Fee"))[0].value
      // const
      let backRentObject = paygridvalues.filter(
        (e) => e.Agreementtype == "Back Rent"
      );
      if (backRentObject?.length > 0) {
        const editedItemInfo = paygridvalues.filter(
          (e) => e.Agreementtype == "Back Rent"
        );
        editedItemInfo[0].Agreementdesc = Description;
        editedItemInfo[0].Amount = Number(Amount);
        editedItemInfo[0].Taxamount = Number(salesTax);
        editedItemInfo[0].actualAmountDue = Number(Amount);
        editedItemInfo[0].nineOneOneFee = nineoneoneFee;
        // editedItemInfo[0].cellPhoneActivation = Number(res.salesPrice);
        editedItemInfo[0].cellPhoneActivationTax = Number(salesTax);
        editedItemInfo[0].agreementRate = res.Amount;
        editedItemInfo[0].miscellaneousItemInfo = res;
      } else {
        const itempaygridvalue = {
          AgreementNumber: "Adjust Back Rent",
          Agreementdesc: Description,
          Agreementid: "",
          Agreementtype: type,
          Amount: Number(Amount),
          Autopay: "null",
          Autopay_enrollmentdate: null,
          COA: coa,
          CurrentDaysLate: 0,
          CurrentDueDate: "-",
          EPOAmount: 0,
          EPOTax: 0,
          EmailAddress: "",
          Latelast10: 0,
          Nextduedate: "-",
          Phonenumber: "",
          PromoFreeDays: 0,
          SAC_Date: "",
          Schedule: "",
          SuspenseAmount: 0,
          Taxamount: Number(salesTax),
          actualAmountDue: Number(Amount),
          agrLdwAmount: 0,
          agreementRate: res.Amount,
          agreementRateDetails: null,
          cardLastFour: 0,
          carriedLateFee: 0,
          carryRentAmountAdd: 0,
          carryRentAmountUsed: 0,
          carryRentTaxUsed: 0,
          clubCoverageTermination: null,
          clubFlag: true,
          clubMembershipNumber: "",
          clubName: "",
          clubactive: false,
          clublastLate10: 0,
          confirmeddeliversystatus: false,
          convTax: configFeeOnlyTax,
          coverageEndDate: "",
          deliveryAmount: 0,
          deliveryAmountTax: 0,
          dueDateModified: false,
          editSchedule: "",
          extensionAmount: 0,
          htSuspense: 0,
          ipSuspense: 0,
          lFeeTaxAmount: 0,
          suspenseAdd: 0,
          suspenseSub: 0,
          lateFee: 0,
          ldwPercentage: NaN,
          ldwTaxAmount: 0,
          mattressFee: 0,
          mattressFeeTax: 0,
          noOfDaysFree: NaN,
          number_of_paymentsmade: 0,
          partyId: "",
          policyAmount: 0,
          policyTaxAmount: 0,
          processingFee: 0,
          processingFeeTax: 0,
          racTirePay: 0,
          rpayTaxAmount: 0,
          sign_status: "",
          stateProvinceName:
            stateProvinceName != undefined ? stateProvinceName : "",
          // configFlags.isPaymentFunctionsFeatureFlag == 1
          //   ? getpayment?.paymentInfo?.cashSaleInfo?.stateProvinceName
          //   : "",
          storeCityName: storeCityName != undefined ? storeCityName : "",
          // configFlags.isPaymentFunctionsFeatureFlag == 1
          //   ? getpayment?.paymentInfo?.cashSaleInfo?.city
          //   : "",
          store_number: String(window.sessionStorage.getItem("storeNumber")),
          suspense: 0,
          taxGeoCode: "",
          taxRate: "",
          wlDeposit: wlDeposit,
          zipPlus4UsedForTax: "",
          otherIncomeType: "Back Rent",
          nineOneOneFee: nineoneoneFee,
          cellPhoneActivation: 0,
          cellPhoneActivationTax: 0,
          miscellaneousItemsNum: 0,
          miscellaneousItemInfo: res,
        };
        let currentstorenumber = window.sessionStorage.getItem("storeNumber");
        let Clubagreementinsertion = paygridvalues.filter(
          (x) => x.Agreementtype === "Club"
        );
        let curStoreData = paygridvalues.filter(
          (x) =>
            x.store_number.toString() === currentstorenumber &&
            x.Agreementtype !== "Club"
        );
        Clubagreementinsertion.push(...curStoreData, itempaygridvalue);
        let otherStoreData = paygridvalues.filter(
          (x) =>
            x.store_number.toString() !== currentstorenumber &&
            x.Agreementtype !== "Club"
        );
        Clubagreementinsertion.push(...otherStoreData);
        paygridvalues = Clubagreementinsertion;
      }
      setpaygridvalues(paygridvalues);
      backRentPopUp=false
      setBackRentPopUp(backRentPopUp);
    } else {
      backRentPopUp=false
      setBackRentPopUp(backRentPopUp);
    }
  };
  useEffect(() => {
    if (backRentPopUp == false) {
      let checkBoxArray = document.getElementsByName("grid");
      checkBoxArray.forEach(function (item, index) {
        let obj = item as HTMLInputElement;
        let checkBox = obj;

        if (paygridvalues[index].Agreementtype == "Back Rent") {
          if (checkBox.checked == false) {
            checkBox.checked = true;
            settotaldueamount(
              "chk" + index,
              index,
              paygridvalues[index].Amount,
              paygridvalues[index].Agreementid,
              undefined
            );
          } else if (checkBox.checked == true) {
            editAdjustRes({ resArray: checkedAgr });
          }
        }
      });
      multipleAdjust();
    }
  }, [backRentPopUp]);
    //Donation useEffect function which is trigered when the remainingamount and donationBtnFlag values are updated.	
    useEffect(() => {	
      if (donationBtnFlag == false && donationType.isDonationEnabled == true)		
      {	
        let initialTotaAmount: any = remainingamountdue	
        let wholeNumber: any = Number(String(initialTotaAmount).split(".")[0]) + 1	
        let differenceAmount = Number(Number(wholeNumber) - Number(initialTotaAmount)).toFixed(2)	
        setDonationAmount({ ...donationAmount, amount: differenceAmount })	
      }	
      // else if (donationBtnFlag == false && donationType.roundOffFlag == false) {	
    }, [remainingamountdue, donationBtnFlag])	
    //Donation toggle button onclick function	
    const donationOnclick = () => {	
      // debugger	
     
      if (donationBtnFlag == false) {	
        donationBtnFlag = true	
        setDonationBtnFlag(donationBtnFlag)	
        setDonationtextBoxFlag(false)	
        setDonationAmount({ ...donationAmount, type: donationType.donationType, respFlag: true })	
        // if (donationType.roundOffFlag == true) {	
          donationAmountAdd = Number(donationAmount.amount)	
          setDonationAmountAdd(donationAmountAdd)	
          editDonationRes({ resArray: checkedAgr })	
        // }	
        // setremainingamountdue(Number(remainingamountdue) +Number(donationAmount) )	
      }	
      else {	
        donationBtnFlag = false	
        setDonationBtnFlag(donationBtnFlag)	
        setDonationtextBoxFlag(true)	
        setDonationAmount({ ...donationAmount, respFlag: false })	
        donationAmountAdd=0	
        setDonationAmountAdd(donationAmountAdd)	
        editDonationRes({ resArray: checkedAgr })	
        // setremainingamountdue(Number(remainingamountdue) - Number(donationAmount) )	
      }	
      let traceObj={
        iD:Number(userTraceData?.length)+1,
        type:"Donation Toggle"
      }
      setUserTraceData([...userTraceData,traceObj])
    }	
     //Donation text box onblur function	
     const donationOnblur = (val) => {	
      // debugger;	
      let amount = String(val.target.value).replace(/,/g, "");	
      setDonationAmount({ ...donationAmount, amount: Number(amount).toFixed(2) });	
      // if (donationType.roundOffFlag == true) {	
        // if (Number(amount) != 0) {	
          donationAmountAdd = Number(amount);	
          setDonationAmountAdd(donationAmountAdd);	
          editDonationRes({ resArray: checkedAgr })	
        // }	
        // else {	
        //   //  setDonationAmount(Number(donationAmountAdd).toFixed(2))	
        //   setDonationAmount({ ...donationAmount, amount: Number(donationAmountAdd).toFixed(2) });	
        // }	
      // }	
      // else if (donationType.roundOffFlag == false) {	
      //   donationAmountAdd = Number(amount);	
      //   setDonationAmountAdd(donationAmountAdd);	
      //   editAdjustRes({ resArray: checkedAgr })	
      // }	
      let traceObj={
        iD:Number(userTraceData?.length)+1,
        type:"DonationOnBlur"
      }
      setUserTraceData([...userTraceData,traceObj])
    }	
    //Donation text box onchange function	
    const donationOnchange = (val) => {	
      let amount = String(val.target.value).replace(/,/g, "");	
      setDonationAmount({ ...donationAmount, amount: amount });	
      // if (donationType.roundOffFlag == false && amount == "0.00") {	
      //   setDonationAmount({ ...donationAmount, validateFlag: true })	
      // }	
      // else if (donationType.roundOffFlag == false && amount != "0.00") {	
      //   setDonationAmount({ ...donationAmount, validateFlag: false })	
      // }	
      
    }

  const otherIncomepopupButtonclick = () => {
    // setCardrequire({
    //   ...Cardrequire,
    //   Ldwhide: customerCoverageObject.isLDW === true ? false : true,
    //   Policyhide:
    //     customerCoverageObject.isPolicy === true &&
    //     customerCoverageObject.policyAmount !== ""
    //       ? false
    //       : true,
    // });
    if (
      otherIncomePopupClass ===
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    ) {
      setotherIncomePopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`
      );
    }
    if (
      otherIncomePopupClass ===
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`
    ) {
      setotherIncomePopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
      );
    }
  };
  const otherIncomeOnblur = () => {
    setotherIncomePopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };

  const nsfResponse = async (res: any) => {
    let SelectallFlag = true;
    //to get values
    setNsfPopUp(false);
    let misNumber = 0;
    if (res != undefined) {
      let cao = 0.0;
      let suspense = 0.0;
      let htsuspense = 0.0;
      let ipsuspense = 0.0;
      let val = cao + ipsuspense;
      const Amount = res.Amount;
      const salesTax = Number(res.salesTax);
      const nineoneoneFee = 0;
      const type = res.type;
      const Total = res.Total;
      const Description = "NSF Check Payment";

      let NsfObj = paygridvalues.filter(
        (e) => e.Agreementid == res.Agreementid && e.Agreementtype == res.type
      );
      if (NsfObj?.length > 0) {
        const editedItemInfo = paygridvalues.filter(
          (e) => e.Agreementid == res.Agreementid && e.Agreementtype == res.type
        );
        /* editedItemInfo[0].Agreementdesc = Description;
       editedItemInfo[0].Amount = Number(Total);
       editedItemInfo[0].Taxamount = Number(0);
       editedItemInfo[0].actualAmountDue = Number(Total);
       editedItemInfo[0].nineOneOneFee = nineoneoneFee;
       editedItemInfo[0].cellPhoneActivation = Number(0);
       editedItemInfo[0].cellPhoneActivationTax = Number(0);
       editedItemInfo[0].agreementRate = res.Amount;
       editedItemInfo[0].agreement_id = Number(res.Agreementid);
       editedItemInfo[0].miscellaneousItemInfo = res;*/
        editedItemInfo[0].nsfFee = res.Amount;
        editedItemInfo[0].Agreementdesc = Description;
        editedItemInfo[0].Amount = Number(Total);
         editedItemInfo[0].Taxamount = Number(salesTax);
        editedItemInfo[0].actualAmountDue = Number(Amount);
        editedItemInfo[0].nineOneOneFee = nineoneoneFee;
        editedItemInfo[0].cellPhoneActivation = Number(0);
        editedItemInfo[0].cellPhoneActivationTax = Number(0);
        //  editedItemInfo[0].agreementRate = res.Amount;
        editedItemInfo[0].agreement_id = Number(res.Agreementid);
        editedItemInfo[0].miscellaneousItemInfo = res;
      }
    }
    setpaygridvalues(paygridvalues);
    setNsfPopUp(false);
  };
  useEffect(() => {
    //debugger;
    if (nsfPopUp == false) {
      let checkBoxArray = document.getElementsByName("grid");
      checkBoxArray.forEach(function (item, index) {
        let obj = item as HTMLInputElement;
        let checkBox = obj;
        let agr = checkBox.checked;
        if (
          checkBox.checked == true &&
          paygridvalues[index].Agreementtype == "NSF Check"
        ) {
          editAdjustRes({ resArray: checkedAgr });
        }
      });
    }
  }, [nsfPopUp]);

  /**
   * changes : Adjust Due Date v2
   * Developer : Muzzamil
   * Desc : to set the state variables from the response of adjust due date component
   *        Amount Due and remianing amount amount Due , total amount Due , total tax , receipt items
   */
  const editAdjustRes = (response: any, RSDParam?: any) => {
    //debugger;
    if(carryRentWidget){
      carryOnchange()
    }
    let payGridObj;
    let prevAmt;
    let resAmount = 0;
    let resTaxamount = 0;
    totalduereceiptidsupport = [];
    acceptPaymentParam = [];
    ReceiptCategoriesRequestParam = [];
    let res = response.resArray;

    const collectAgrIDS: any = [];
    paygridvalues.map((payGridObj: any, index) => {
      if(payGridObj.Agreementtype !== 'Club') {
        collectAgrIDS.push(payGridObj.Agreementid);
      }
    });

    const lengthDecider = commitmentTOS ? collectAgrIDS.length : adjustDueDateSelectedAgre.length;
    console.log('lengthDecider', lengthDecider);

    for (let i = 0; i < lengthDecider; i++) {
      resAmount = Number(resAmount) + Number(res[i].Amount);
      resTaxamount = Number(resTaxamount) + Number(res[i].Taxamount);
      payGridObj = paygridvalues.filter(
        (x) => x.Agreementid === res[i].Agreementid
      );
      //  debugger;
      if (res?.length == 1) {
        setLatelast10value(payGridObj[0].lateFee);
      }
      prevAmt = payGridObj[0].Amount;
      payGridObj[0].Amount = res[i].Amount;
      payGridObj[0].Nextduedate =
        res[i].Nextduedate == undefined
          ? payGridObj[0].Nextduedate
          : res[i].Nextduedate;
      payGridObj[0].Taxamount = res[i].Taxamount;
      payGridObj[0].Schedule =
        res[i].Schedule == undefined ? payGridObj[0].Schedule : res[i].Schedule;
      payGridObj[0].editSchedule =
        res[i].editSchedule == undefined
          ? payGridObj[0].editSchedule
          : res[i].editSchedule;
      // payGridObj[0].agreementRateDetails.scheduleRefCode = res[i].editSchedule==undefined?payGridObj[0].agreementRateDetails.scheduleRefCode:res[i].editSchedule
      payGridObj[0].ldwTaxAmount =
        res[i].ldwTaxAmount == undefined
          ? payGridObj[0].ldwTaxAmount
          : res[i].ldwTaxAmount;
      payGridObj[0].agrLdwAmount =
        res[i].agrLdwAmount == undefined
          ? payGridObj[0].agrLdwAmount
          : res[i].agrLdwAmount;
      payGridObj[0].policyTaxAmount =
        res[i].policyTaxAmount == undefined
          ? payGridObj[0].policyTaxAmount
          : res[i].policyTaxAmount;
      payGridObj[0].policyAmount =
        res[i].policyAmount == undefined
          ? payGridObj[0].policyAmount
          : res[i].policyAmount;
      payGridObj[0].agreementRate =
        res[i].agreementRate == undefined
          ? payGridObj[0].agreementRate
          : res[i].agreementRate;
      payGridObj[0].PromoFreeDays =
        res[i].PromoFreeDaysApplied == undefined
          ? payGridObj[0].PromoFreeDays
          : Number(payGridObj[0].PromoFreeDays) -
            Number(res[i].PromoFreeDaysApplied);
      payGridObj[0].PromoFreeDaysApplied =
        res[i].PromoFreeDaysApplied == undefined
          ? payGridObj[0].PromoFreeDaysApplied
          : res[i].PromoFreeDaysApplied;

      // payGridObj[0].agreementRateTax=res[i].agreementRateTax==undefined?payGridObj[0].agreementRateTax:res[i].agreementRateTax
      payGridObj[0].rpayTaxAmount =
        res[i].rpayTaxAmount == undefined
          ? payGridObj[0].rpayTaxAmount
          : res[i].rpayTaxAmount;
      payGridObj[0].Agreementtype =
        res[i].Agreementtype == undefined
          ? payGridObj[0].Agreementtype
          : res[i].Agreementtype;
      if (response.adjustEditflag == 1) {
        payGridObj[0].dueDateModified = true;
      }

      //to set the receipt item values
      const agreementDetails = {
        agreement_id: payGridObj[0].AgreementNumber,
        agreement_totalamount: fixedChange(String(res[i].Amount)),
      };
      totalduereceiptidsupport.push(agreementDetails);
      settotalduereceiptid(totalduereceiptidsupport);
      //accept payment array push starts here
      let ldwamountcalculation = "0.00";
      let objCarriedLateFee = 0;
      let objAmount = "0.00";
      let clubFlag = 0;
      let ldwFeeTax;
      let pifAmt = 0;
      let pifTax = 0;
      let sacTax = 0;
      let epoAmt = 0;
      let epoTax = 0;
      let pifFlag = 0;
      let sacAmt = 0;
      if (getpayment !== undefined && getpayment !== null) {
        //let objLdwInfo = getpayment.paymentInfo.ldwInformation.filter(x => x.agreementId ===  payGridObj[0].Agreementid);
        let objAmtDue;

        if (payGridObj[0].Agreementtype === "Club") {
          objAmtDue = getpayment.amountDue.amountDueResponse.filter(
            (x) => x.clubAmountDue !== undefined
          );
        } else {
          objAmtDue = getpayment.amountDue.amountDueResponse.filter(
            (x) => x.agreementId === payGridObj[0].Agreementid
          );
        }

        if (objAmtDue.length > 0) {
          if (
            objAmtDue[0].ldwLateRent !== undefined &&
            objAmtDue[0].ldwLateRent !== null &&
            objAmtDue[0].ldwLateRent !== "" &&
            objAmtDue[0].ldwLateRent !== "0"
          ) {
            ldwamountcalculation = objAmtDue[0].ldwLateRent;
            ldwFeeTax = Number(objAmtDue[0].ldwTaxAmount);
          } else if (
            objAmtDue[0].ldwAmount !== undefined &&
            objAmtDue[0].ldwAmount !== null &&
            objAmtDue[0].ldwAmount !== ""
          ) {
            ldwamountcalculation = objAmtDue[0].ldwAmount;
            ldwFeeTax = Number(objAmtDue[0].ldwTaxAmount);
          }

          if (
            objAmtDue[0].amountDueLateRent !== undefined &&
            objAmtDue[0].amountDueLateRent !== null &&
            objAmtDue[0].amountDueLateRent !== "" &&
            objAmtDue[0].amountDueLateRent !== "0"
          ) {
            objAmount = objAmtDue[0].amountDueLateRent;
          } else if (objAmtDue[0].amountDue !== undefined) {
            objAmount = String(objAmtDue[0].amountDue);
          } else if (
            objAmtDue[0].clubAmountLateRent !== undefined &&
            objAmtDue[0].clubAmountLateRent !== null &&
            objAmtDue[0].clubAmountLateRent !== ""
          ) {
            clubFlag = 1;
            objAmount = objAmtDue[0].clubAmountLateRent;
          } else {
            clubFlag = 1;
            objAmount = String(objAmtDue[0].clubAmountDue);
          }
        }

        let objPayHistory = getpayment.paymentInfo.paymentHistoryInfo.filter(
          (x) => x.agreementId === payGridObj[0].Agreementid
        );
        if (objPayHistory !== undefined && objPayHistory.length > 0) {
          objCarriedLateFee = objPayHistory[0].carriedLateFee;
        }
        let agreementAmount = Number(
          (
            payGridObj[0].Amount -
            Number(payGridObj[0].lateFee) +
            Number(payGridObj[0].suspenseSub)
          ).toFixed(2)
        );
        //let epoAmount = Number( payGridObj[0].EPOAmount.toFixed(2));
        // let currentDate = new Date();
        // let SACDate = new Date( payGridObj[0].SAC_Date);
        if (payGridObj[0].Agreementtype == "EPO Rent") {
          if (payGridObj[0].Nextduedate == "EPO") {
            epoAmt =
              payGridObj[0].acceptEpoFlag == 0
                ? Number((agreementAmount - payGridObj[0].Taxamount).toFixed(2))
                : Number(payGridObj[0].EPOAmount) +
                  Number(payGridObj[0].suspenseSub) -
                  Number(payGridObj[0].lateFee) -
                  Number(payGridObj[0].EPOTax);
            epoTax =
              payGridObj[0].acceptEpoFlag == 0
                ? Number(payGridObj[0].rpayTaxAmount)
                : Number(payGridObj[0].EPOTax) -
                  Number(payGridObj[0].lFeeTaxAmount);
          } else {
            sacAmt =
              payGridObj[0].acceptEpoFlag == 0
                ? Number((agreementAmount - payGridObj[0].Taxamount).toFixed(2))
                : Number(payGridObj[0].EPOAmount) +
                  Number(payGridObj[0].suspenseSub) -
                  Number(payGridObj[0].lateFee) -
                  Number(payGridObj[0].EPOTax);
            sacTax =
              payGridObj[0].acceptEpoFlag == 0
                ? Number(payGridObj[0].rpayTaxAmount)
                : Number(payGridObj[0].EPOTax) -
                  Number(payGridObj[0].lFeeTaxAmount);
          }
          setfinalagr(Number(payGridObj[0].Agreementid));
          pifFlag = 1;
        } else if (payGridObj[0].Agreementtype == "PIF Rent") {
          pifAmt =
            payGridObj[0].acceptEpoFlag == 0
              ? Number((agreementAmount - payGridObj[0].Taxamount).toFixed(2))
              : Number(payGridObj[0].EPOAmount) +
                Number(payGridObj[0].suspenseSub) -
                Number(payGridObj[0].lateFee) -
                Number(payGridObj[0].EPOTax);
          pifTax =
            payGridObj[0].acceptEpoFlag == 0
              ? Number(payGridObj[0].rpayTaxAmount)
              : Number(payGridObj[0].EPOTax) -
                Number(payGridObj[0].lFeeTaxAmount);
          setfinalagr(Number(payGridObj[0].Agreementid));
          pifFlag = 1;
        }
      }

      let ChangeAmt = change;
      let ReceiptArray = {
        customerId: Number(customerId),
        schedule: payGridObj[0].Schedule + "|" + payGridObj[0].AgreementNumber,
        amountDue: Number(
          clubFlag !== 1 && pifFlag == 0
            ? (payGridObj[0].Amount - Number(payGridObj[0].lateFee)).toFixed(2)
            : 0
        ),
        ldwFeeAmount: Number(payGridObj[0].agrLdwAmount),
        clubPayment: Number(
          clubFlag === 1
            ? Number(payGridObj[0].Amount.toFixed(2)) -
                Number(payGridObj[0].Taxamount)
            : 0
        ),
        clubPaymentTax: Number(clubFlag === 1 ? payGridObj[0].Taxamount : 0),
        creditOnAccountAdd: 0,
        creditOnAccountSub: Number(payGridObj[0].COA),
        integratedPaymentSuspense: -1 * Number(payGridObj[0].ipSuspense),
        initialPayment:
          clubFlag === 1
            ? 0
            : payGridObj[0].number_of_paymentsmade === 0
            ? 1
            : 0,
        netLateFee: 0,
        carriedLateFee: 0,
        wlDeposit: -1 * Number(payGridObj[0].wlDeposit),
        htSuspense: 0,
        suspenseAdd: Number(payGridObj[0].suspenseAdd),
        suspenseSub: Number(payGridObj[0].suspenseSub), //Number( payGridObj[0].suspense),
        racTirePay: Number(payGridObj[0].policyAmount),
        agreementId:
          clubFlag !== 1 ? Number(payGridObj[0].Agreementid) : undefined,
        change: Number(fixedChange(ChangeAmt)),
        totalTax: payGridObj[0].Taxamount,
        sac: Number(sacAmt),
        sacTax: Number(sacTax),
        epoDiscount:
          payGridObj[0].acceptEpoFlag == 0
            ? 0.0
            : Number(payGridObj[0].actualEpoAmount) -
              Number(payGridObj[0].editedEPOAmount),
        pastDueDiscount:
          payGridObj[0].acceptEpoFlag == 0
            ? 0.0
            : Number(payGridObj[0].actualPastDueRent) -
              Number(payGridObj[0].pastDueRent),
        ldwFeeAmountTax: Number(payGridObj[0].ldwTaxAmount),
        pastDueAmount:
          payGridObj[0].acceptEpoFlag == 0
            ? undefined
            : Number(payGridObj[0].actualPastDueRent),
        nextDueDate:
          payGridObj[0].acceptEpoFlag == 0
            ? undefined
            : payGridObj[0].Nextduedate,
        rentalPaymentTax:
          pifFlag == 0 && payGridObj[0].Agreementtype !== "Club"
            ? Number(payGridObj[0].rpayTaxAmount)
            : 0,
        lateRentalPaymentfeeTax: Number(payGridObj[0].lFeeTaxAmount),
        lateRentalPaymentfee: Number(payGridObj[0].lateFee),
        racTirePayTax: Number(payGridObj[0].policyTaxAmount),
        deliveryFee: Number(payGridObj[0].deliveryAmount),
        deliveryFeeTax: Number(payGridObj[0].deliveryAmountTax),
        processingFee: Number(payGridObj[0].processingFee),
        processingFeeTax: Number(payGridObj[0].processingFeeTax),
        carryRentAmountUsed:
          payGridObj[0].FreeTime == null
            ? Number(payGridObj[0].carryRentAmountUsed)
            : 0,
        carryRentTaxUsed:
          payGridObj[0].FreeTime == null
            ? Number(payGridObj[0].carryRentTaxUsed)
            : 0,
        //otherFee: Number( payGridObj[0].mattressFee),
        //otherFeeTax: Number( payGridObj[0].mattressFeeTax),
        pif: Number(pifAmt),
        pifTax: Number(pifTax),
        extensionAmount: Number(payGridObj[0].extensionAmount),
        convenienceFee: 0,
        convenienceFeeTax: 0,
        mattressFee: Number(payGridObj[0].mattressFee),
      };

      ReceiptCategoriesRequestParam.push(ReceiptArray);

      if (
        payGridObj[0].Agreementtype !== "Club" &&
        payGridObj[0].miscellaneousItemsNum == 0
      ) {
        let acceptPaymentArray = {
          extensionAmount: Number(payGridObj[0].extensionAmount),
          noOfDaysFree: Number(payGridObj[0].noOfDaysFree),
          initialPaymentFlag: payGridObj[0].number_of_paymentsmade == 0 ? 1 : 0,
          addTransferSusFlag: 0,
          schedule: payGridObj[0].Schedule,
          agreementId: Number(payGridObj[0].Agreementid),
          ldwFeeAmount: Number(payGridObj[0].agrLdwAmount.toFixed(2)),
          agreementAmountDue: Number(
            fixedChange(
              String(
                Number(payGridObj[0].Amount) +
                  (Number(payGridObj[0].acceptEpoFlag) == 1
                    ? Number(payGridObj[0].suspenseSub)
                    : 0)
              )
            )
          ),
          remainingEpoAmount: Number(
            payGridObj[0].EPOAmount == null
              ? 0.0
              : (Number(payGridObj[0].EPOAmount - payGridObj[0].EPOTax) < 0
                  ? 0
                  : payGridObj[0].EPOAmount - payGridObj[0].EPOTax
                ).toFixed(2)
          ),
          promoFreeDays: Number(payGridObj[0].PromoFreeDaysApplied),
          netLateFee: Number(payGridObj[0].lateFee),
          carriedLateFee: Number(
            Number(payGridObj[0].carriedLateFee).toFixed(2)
          ),
          numberOfDaysLate: payGridObj[0].CurrentDaysLate,
          originalDueDate: payGridObj[0].CurrentDueDate,
          nextDueDate: payGridObj[0].Nextduedate,
          totalTax: Number(payGridObj[0].Taxamount.toFixed(2)),
          policyAmount: Number(Number(payGridObj[0].policyAmount)), // Change Made
          editSchedule: payGridObj[0].editSchedule,
          freeTime: payGridObj[0].FreeTime, // Added By Me
          ldwActivate: undefined,
        };
        acceptPaymentParam.push(acceptPaymentArray);
        setAcceptClub(undefined);
      } else if (payGridObj[0].miscellaneousItemsNum == 0) {
        AcceptclubPaymentParam = {
          paymentSchedule: payGridObj[0].Schedule,
          originalDueDate: payGridObj[0].CurrentDueDate,
          nextDueDate: payGridObj[0].Nextduedate,
          numberOfDaysLate: payGridObj[0].CurrentDaysLate,
          clubTax: String(payGridObj[0].Taxamount.toFixed(2)),
          clubAmountDue: Number(fixedChange(String(payGridObj[0].Amount))),
          editSchedule: payGridObj[0].editSchedule,
        };
        setAcceptClub(AcceptclubPaymentParam);
      }
      if (
        lengthDecider == 1 &&
        payGridObj[0].Agreementtype != "Club"
        // &&
        // payGridObj[0].FreeTime == null
      ) {
        let index = paygridvalues.findIndex(
          (x) => String(x.Agreementid) === String(adjustDueDateSelectedAgre[0])
        );
        if (index != null && index != undefined) {
          loadOtherActions(index);
        }
      }
    }

    //debugger;
    setpaygridvalues(paygridvalues);
    let resTotalAmount = 0.0;
    if (paymentOrigin == "Pay By Phone") {
      resAmount = Number(resAmount) + Number(convAmtWTax);
      resTaxamount = Number(resTaxamount) + Number(convtax);
    }
    if (Number(carryRentAmount) > 0) {
      resAmount = Number(resAmount) - Number(carryRentAmount);
    }
    let objAvailCreditsAmt = 0;
    if(payGridObj) {
      if (Number(resAmount) <= Number(payGridObj[0].wlDeposit)) {
        objAvailCreditsAmt = resAmount;
        objAvailCreditsAmt =
          diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
        setAvailableCreds(objAvailCreditsAmt);
        if (diffStoreErrLbl && isRevenueRecogActivated) {
          setavailableCredsremaining([]);
        } else {
          setavailableCredsremaining([
            {
              balanceType: "WEBLEAD",
              accountBalance: Number(resAmount).toFixed(2),
            },
          ]);
        }
      } else if (Number(resAmount) > Number(payGridObj[0].wlDeposit)) {
        objAvailCreditsAmt =
          Number(payGridObj[0].COA) +
          Number(payGridObj[0].wlDeposit) +
          Number(
            (Number(resAmount) - Number (payGridObj[0].wlDeposit) 
            > Number(payGridObj[0].ipSuspense) 
            ? Number(payGridObj[0].ipSuspense) : 
            (Number(resAmount) - Number (payGridObj[0].wlDeposit))));
        objAvailCreditsAmt =
          diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
        setAvailableCreds(objAvailCreditsAmt);
        if (diffStoreErrLbl && isRevenueRecogActivated) {
          setavailableCredsremaining([]);
        } else {
          setavailableCredsremaining([
            {
              balanceType: "WEBLEAD",
              accountBalance: Number(payGridObj[0].wlDeposit).toFixed(2),
            },
            {
              balanceType: "COA",
              accountBalance: Number(payGridObj[0].COA).toFixed(2),
            },
            {
              balanceType: "IP Suspence",
              accountBalance: Number((Number(resAmount) - Number (payGridObj[0].wlDeposit) 
              > Number(payGridObj[0].ipSuspense) 
              ? Number(payGridObj[0].ipSuspense) : 
              (Number(resAmount) - Number (payGridObj[0].wlDeposit)))).toFixed(2),
            },
          ]);
        }
      } else if (Number(resAmount) == 0) {
        objAvailCreditsAmt =
          Number(payGridObj[0].COA) 
          // + Number(payGridObj[0].ipSuspense);
        objAvailCreditsAmt =
          diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
        setAvailableCreds(objAvailCreditsAmt);
        if (diffStoreErrLbl && isRevenueRecogActivated) {
          setavailableCredsremaining([]);
        } else {
          setavailableCredsremaining([
            {
              balanceType: "COA",
              accountBalance: Number(payGridObj[0].COA).toFixed(2),
            },
            // {
            //   balanceType: "IP Suspence",
            //   accountBalance: Number(payGridObj[0].ipSuspense).toFixed(2),
            // },
          ]);
        }
      }
    }

     //Donation	
     if(resAmount==0 && donationBtnFlag)	
     {	
         
       donationAmountAdd=0;	
       setDonationAmountAdd(donationAmountAdd);	
       setDonationAmount({...donationAmount,amount:"0.00"})	
       setDonationtextBoxFlag(true);	
       setDonationBtnFlag(false);	
     }

    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });

    let localTotalSurrenderAmount = 0;
    let localTotalDepositAmount = 0;

    if (RSDParam) {
      let totalRDSToAdd = 0;
      let totalRDSToSubtract = 0;

      const mapperFilter = RSDParam.filter((el: any) => !(el.RSDLabel.includes('Collect')));
      mapperFilter.map((el: any) => {
        const filteredSurrenderPair: any = RSDParam.filter((elem: any) =>
          elem.RSDLabel.includes(`${el.securityDepositAgreementNumber} - Collect`)
        );
        if (
          filteredSurrenderPair.length &&
          Number(filteredSurrenderPair[0].RSDAmount) <= Number(el.RSDAmount)
        ) {
          totalRDSToSubtract =
            totalRDSToSubtract + (Number(el.RSDAmount) - Number(filteredSurrenderPair[0].RSDAmount))
        } else if (
          filteredSurrenderPair.length &&
          Number(filteredSurrenderPair[0].RSDAmount) > Number(el.RSDAmount)
        ) {
          totalRDSToAdd =
            totalRDSToAdd +
            (Number(filteredSurrenderPair[0].RSDAmount) - Number(el.RSDAmount));
        }
      });
      localTotalSurrenderAmount = totalRDSToSubtract;
      localTotalDepositAmount = totalRDSToAdd
    } else {
      localTotalSurrenderAmount = totalSurrenderAmount;
      localTotalDepositAmount = totalDepositAmount;
    }

    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (Number(resTotalAmount) > Number(resAmount)) {
      resetPayment(undefined);
      setremainingamountdue(
        Number(
          (
            Number(resAmount) -
            Number(objAvailCreditsAmt)+
            Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(localTotalSurrenderAmount) + Number(localTotalDepositAmount)
          ).toFixed(2)
        )
      );
      setRemaniningAmtStatic(
        Number(
          (
            Number(resAmount) -
            Number(objAvailCreditsAmt)+
            Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(localTotalSurrenderAmount) + Number(localTotalDepositAmount)
          ).toFixed(2)
        )
      );
      setchange("0.00");
      setAcceptpaymentbuttonenable(true);
    } else {
      if (
        Number(resTotalAmount) >=
        Number(
          (
            Number(resAmount) -
            Number(objAvailCreditsAmt)+
            Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(localTotalSurrenderAmount) + Number(localTotalDepositAmount)
          ).toFixed(2)
        )
      ) {
        setchange(
          Number(resTotalAmount) -
            Number(
              (
                Number(resAmount) -
                Number(objAvailCreditsAmt)+
                Number(donationAmountAdd) +
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount) - Number(localTotalSurrenderAmount) + Number(localTotalDepositAmount)
              ).toFixed(2)
            )
        );
        setremainingamountdue("0.00");
        setRemaniningAmtStatic("0.00");
        setAcceptpaymentbuttonenable(false);
      } else {
        setremainingamountdue(
          Number(
            (
              Number(resAmount) -
              Number(objAvailCreditsAmt)+
              Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount) - Number(localTotalSurrenderAmount) + Number(localTotalDepositAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setRemaniningAmtStatic(
          Number(
            (
              Number(resAmount) -
              Number(objAvailCreditsAmt)+
              Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount) - Number(localTotalSurrenderAmount) + Number(localTotalDepositAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setchange("0.00");
        setAcceptpaymentbuttonenable(true);
      }
    }
    let addedlatevalue = 0;
    for (let i = 0; i < checkedAgr?.length; i++) {
      addedlatevalue = Number(checkedAgr[i].lateFee) + addedlatevalue;
    }
    totaldue = resAmount;
    settotaldue(totaldue);
    totaltax = Number(resTaxamount);
    settotaltax(totaltax);
    // settotaldue(resAmount);
    // settotaltax(Number(resTaxamount));
    setPopUpCarryWaive(false);
    setPopupCarrywaiveLate(false);
    setopenAdjustDueDate(false);
    setopenPromoFreeDays(false);
    seteditSchedulePopUp(false);
    setLatelast10value(addedlatevalue);
    // if (isRevenueRecogActivated) {
    //   multipleAdjustRevenue();
    // } else {
      multipleAdjust();
    // }
  };

  const tenderAmountCalculation=()=>{
    let resAmount=0;
    let resTaxamount=0;
    let localTotalSurrenderAmount = totalSurrenderAmount;
    let localTotalDepositAmount = totalDepositAmount;
    if(checkedAgr?.length>0){
      for (let i = 0; i < checkedAgr.length; i++) {
        resAmount = Number(resAmount) + Number(checkedAgr[i].Amount);
        resTaxamount = Number(resTaxamount) + Number(checkedAgr[i].Taxamount);   
      }
      let resTotalAmount = 0.0;
      if (paymentOrigin == "Pay By Phone") {
        resAmount = Number(resAmount) + Number(convAmtWTax);
        resTaxamount = Number(resTaxamount) + Number(convtax);
      }
      if (Number(carryRentAmount) > 0) {
        resAmount = Number(resAmount) - Number(carryRentAmount);
      }
      let objAvailCreditsAmt = 0;
      if(checkedAgr) {
        if (Number(resAmount) <= Number(checkedAgr[0].wlDeposit)) {
          objAvailCreditsAmt = resAmount;
          objAvailCreditsAmt =
            diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
          setAvailableCreds(objAvailCreditsAmt);
          if (diffStoreErrLbl && isRevenueRecogActivated) {
            setavailableCredsremaining([]);
          } else {
            setavailableCredsremaining([
              {
                balanceType: "WEBLEAD",
                accountBalance: Number(resAmount).toFixed(2),
              },
            ]);
          }
        } else if (Number(resAmount) > Number(checkedAgr[0].wlDeposit)) {
          objAvailCreditsAmt =
            Number(checkedAgr[0].COA) +
            Number(checkedAgr[0].wlDeposit) +
            Number(
              (Number(resAmount) - Number (checkedAgr[0].wlDeposit) 
              > Number(checkedAgr[0].ipSuspense) 
              ? Number(checkedAgr[0].ipSuspense) : 
              (Number(resAmount) - Number (checkedAgr[0].wlDeposit))));
          objAvailCreditsAmt =
            diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
          setAvailableCreds(objAvailCreditsAmt);
          if (diffStoreErrLbl && isRevenueRecogActivated) {
            setavailableCredsremaining([]);
          } else {
            setavailableCredsremaining([
              {
                balanceType: "WEBLEAD",
                accountBalance: Number(checkedAgr[0].wlDeposit).toFixed(2),
              },
              {
                balanceType: "COA",
                accountBalance: Number(checkedAgr[0].COA).toFixed(2),
              },
              {
                balanceType: "IP Suspence",
                accountBalance: Number((Number(resAmount) - Number (checkedAgr[0].wlDeposit) 
                > Number(checkedAgr[0].ipSuspense) 
                ? Number(checkedAgr[0].ipSuspense) : 
                (Number(resAmount) - Number (checkedAgr[0].wlDeposit)))).toFixed(2),
              },
            ]);
          }
        } else if (Number(resAmount) == 0) {
          objAvailCreditsAmt =
            Number(checkedAgr[0].COA) 
            // + Number(checkedAgr[0].ipSuspense);
          objAvailCreditsAmt =
            diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
          setAvailableCreds(objAvailCreditsAmt);
          if (diffStoreErrLbl && isRevenueRecogActivated) {
            setavailableCredsremaining([]);
          } else {
            setavailableCredsremaining([
              {
                balanceType: "COA",
                accountBalance: Number(checkedAgr[0].COA).toFixed(2),
              },
              // {
              //   balanceType: "IP Suspence",
              //   accountBalance: Number(checkedAgr[0].ipSuspense).toFixed(2),
              // },
            ]);
          }
        }
      }
      const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Add" && Number(e.newsuspense) > 0;
      });
      const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Used" && Number(e.newsuspense) > 0;
      });
      const cardAmount =
      multipleCard.length == 0
        ? 0.0
        : multipleCard
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let cvvToggle = 0;
    if (
      (Number(cardAmount) > 0 && cvvTextValue.length < 3) ||
      CardErrorLbl == true
    ) {
      cvvToggle = 1;
    }

      let addSusTotalAmount =
        addSuspenseAmount.length == 0
          ? 0.0
          : addSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let usedSusTotalAmount =
        useSuspenseAmount.length == 0
          ? 0.0
          : useSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

      resTotalAmount =
        totalduetendertype.length == 0
          ? 0.0
          : totalduetendertype
              .map((bill) => bill.Amount)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      // if (Number(resTotalAmount) > Number(resAmount)) {
      //   resetPayment(undefined);
      //   setremainingamountdue(
      //     Number(
      //       (
      //         Number(resAmount) -
      //         Number(objAvailCreditsAmt)+
      //         Number(donationAmountAdd) +
      //         Number(addSusTotalAmount) -
      //         Number(usedSusTotalAmount) - Number(localTotalSurrenderAmount) + Number(localTotalDepositAmount)
      //       ).toFixed(2)
      //     )
      //   );
      //   setchange("0.00");
      //   setAcceptpaymentbuttonenable(true);
      // } else {
        if (
          Number(resTotalAmount) >=
          Number(
            (
              Number(resAmount) -
              Number(objAvailCreditsAmt)+
              Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount) - Number(localTotalSurrenderAmount) + Number(localTotalDepositAmount)
            ).toFixed(2)
          )
        ) {
          setchange(
            Number(resTotalAmount) -
              Number(
                (
                  Number(resAmount) -
                  Number(objAvailCreditsAmt)+
                  Number(donationAmountAdd) +
                  Number(addSusTotalAmount) -
                  Number(usedSusTotalAmount) - Number(localTotalSurrenderAmount) + Number(localTotalDepositAmount)
                ).toFixed(2)
              )
          );
          setremainingamountdue("0.00");
          // setRemaniningAmtStatic("0.00");
          if(cvvToggle==0){
            setAcceptpaymentbuttonenable(false);
          }else{
            setAcceptpaymentbuttonenable(true);
          }
         
        } else {
          setremainingamountdue(
            Number(
              (
                Number(resAmount) -
                Number(objAvailCreditsAmt)+
                Number(donationAmountAdd) +
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount) - Number(localTotalSurrenderAmount) + Number(localTotalDepositAmount)
              ).toFixed(2)
            ) - Number(resTotalAmount)
          );
          setchange("0.00");
          setAcceptpaymentbuttonenable(true);
        }
      // }
      let addedlatevalue = 0;
      for (let i = 0; i < checkedAgr?.length; i++) {
        addedlatevalue = Number(checkedAgr[i].lateFee) + addedlatevalue;
      }
      totaldue = resAmount;
      settotaldue(totaldue);
      totaltax = Number(resTaxamount);
      settotaltax(totaltax);
      // settotaldue(resAmount);
      // settotaltax(Number(resTaxamount));FIX
      setPopUpCarryWaive(false);
      setPopupCarrywaiveLate(false);
      setopenAdjustDueDate(false);
      setopenPromoFreeDays(false);
      seteditSchedulePopUp(false);
      setLatelast10value(addedlatevalue);
      // if (isRevenueRecogActivated) {
      //   multipleAdjustRevenue();
      // } else {
        // multipleAdjust();
      // }
    }
  }

  const editDonationRes = (response: any) => {
    // debugger;
    let payGridObj;
    let prevAmt;
    let resAmount = 0;
    let resTaxamount = 0;
    totalduereceiptidsupport = [];
    acceptPaymentParam = [];
    ReceiptCategoriesRequestParam = [];
    let res = response.resArray;

    for (let i = 0; i < adjustDueDateSelectedAgre.length; i++) {
      resAmount = Number(resAmount) + Number(res[i].Amount);
      resTaxamount = Number(resTaxamount) + Number(res[i].Taxamount);
      payGridObj = paygridvalues.filter(
        (x) => x.Agreementid === res[i].Agreementid
      );
      //  debugger;
      if (res?.length == 1) {
        setLatelast10value(payGridObj[0].lateFee);
      }
      prevAmt = payGridObj[0].Amount;
      payGridObj[0].Amount = res[i].Amount;
      payGridObj[0].Nextduedate =
        res[i].Nextduedate == undefined
          ? payGridObj[0].Nextduedate
          : res[i].Nextduedate;
      payGridObj[0].Taxamount = res[i].Taxamount;
      payGridObj[0].Schedule =
        res[i].Schedule == undefined ? payGridObj[0].Schedule : res[i].Schedule;
      payGridObj[0].editSchedule =
        res[i].editSchedule == undefined
          ? payGridObj[0].editSchedule
          : res[i].editSchedule;
      // payGridObj[0].agreementRateDetails.scheduleRefCode = res[i].editSchedule==undefined?payGridObj[0].agreementRateDetails.scheduleRefCode:res[i].editSchedule
      payGridObj[0].ldwTaxAmount =
        res[i].ldwTaxAmount == undefined
          ? payGridObj[0].ldwTaxAmount
          : res[i].ldwTaxAmount;
      payGridObj[0].agrLdwAmount =
        res[i].agrLdwAmount == undefined
          ? payGridObj[0].agrLdwAmount
          : res[i].agrLdwAmount;
      payGridObj[0].policyTaxAmount =
        res[i].policyTaxAmount == undefined
          ? payGridObj[0].policyTaxAmount
          : res[i].policyTaxAmount;
      payGridObj[0].policyAmount =
        res[i].policyAmount == undefined
          ? payGridObj[0].policyAmount
          : res[i].policyAmount;
      payGridObj[0].agreementRate =
        res[i].agreementRate == undefined
          ? payGridObj[0].agreementRate
          : res[i].agreementRate;
      payGridObj[0].PromoFreeDays =
        res[i].PromoFreeDaysApplied == undefined
          ? payGridObj[0].PromoFreeDays
          : Number(payGridObj[0].PromoFreeDays) -
          Number(res[i].PromoFreeDaysApplied);
      payGridObj[0].PromoFreeDaysApplied =
        res[i].PromoFreeDaysApplied == undefined
          ? payGridObj[0].PromoFreeDaysApplied
          : res[i].PromoFreeDaysApplied;

      // payGridObj[0].agreementRateTax=res[i].agreementRateTax==undefined?payGridObj[0].agreementRateTax:res[i].agreementRateTax
      payGridObj[0].rpayTaxAmount =
        res[i].rpayTaxAmount == undefined
          ? payGridObj[0].rpayTaxAmount
          : res[i].rpayTaxAmount;
      payGridObj[0].Agreementtype =
        res[i].Agreementtype == undefined
          ? payGridObj[0].Agreementtype
          : res[i].Agreementtype;
      if (response.adjustEditflag == 1) {
        payGridObj[0].dueDateModified = true;
      }

      //to set the receipt item values
      const agreementDetails = {
        agreement_id: payGridObj[0].AgreementNumber,
        agreement_totalamount: fixedChange(String(res[i].Amount)),
      };
      totalduereceiptidsupport.push(agreementDetails);
      settotalduereceiptid(totalduereceiptidsupport);
      //accept payment array push starts here
      let ldwamountcalculation = "0.00";
      let objCarriedLateFee = 0;
      let objAmount = "0.00";
      let clubFlag = 0;
      let ldwFeeTax;
      let pifAmt = 0;
      let pifTax = 0;
      let sacTax = 0;
      let epoAmt = 0;
      let epoTax = 0;
      let pifFlag = 0;
      let sacAmt = 0;
      if (getpayment !== undefined && getpayment !== null) {
        //let objLdwInfo = getpayment.paymentInfo.ldwInformation.filter(x => x.agreementId ===  payGridObj[0].Agreementid);
        let objAmtDue;

        if (payGridObj[0].Agreementtype === "Club") {
          objAmtDue = getpayment.amountDue.amountDueResponse.filter(
            (x) => x.clubAmountDue !== undefined
          );
        } else {
          objAmtDue = getpayment.amountDue.amountDueResponse.filter(
            (x) => x.agreementId === payGridObj[0].Agreementid
          );
        }

        if (objAmtDue.length > 0) {
          if (
            objAmtDue[0].ldwLateRent !== undefined &&
            objAmtDue[0].ldwLateRent !== null &&
            objAmtDue[0].ldwLateRent !== "" &&
            objAmtDue[0].ldwLateRent !== "0"
          ) {
            ldwamountcalculation = objAmtDue[0].ldwLateRent;
            ldwFeeTax = Number(objAmtDue[0].ldwTaxAmount);
          } else if (
            objAmtDue[0].ldwAmount !== undefined &&
            objAmtDue[0].ldwAmount !== null &&
            objAmtDue[0].ldwAmount !== ""
          ) {
            ldwamountcalculation = objAmtDue[0].ldwAmount;
            ldwFeeTax = Number(objAmtDue[0].ldwTaxAmount);
          }
          if (
            objAmtDue[0].amountDueLateRent !== undefined &&
            objAmtDue[0].amountDueLateRent !== null &&
            objAmtDue[0].amountDueLateRent !== "" &&
            objAmtDue[0].amountDueLateRent !== "0"
          ) {
            objAmount = objAmtDue[0].amountDueLateRent;
          } else if (objAmtDue[0].amountDue !== undefined) {
            objAmount = String(objAmtDue[0].amountDue);
          } else if (
            objAmtDue[0].clubAmountLateRent !== undefined &&
            objAmtDue[0].clubAmountLateRent !== null &&
            objAmtDue[0].clubAmountLateRent !== ""
          ) {
            clubFlag = 1;
            objAmount = objAmtDue[0].clubAmountLateRent;
          } else {
            clubFlag = 1;
            objAmount = String(objAmtDue[0].clubAmountDue);
          }
        }

        let objPayHistory = getpayment.paymentInfo.paymentHistoryInfo.filter(
          (x) => x.agreementId === payGridObj[0].Agreementid
        );
        if (objPayHistory !== undefined && objPayHistory.length > 0) {
          objCarriedLateFee = objPayHistory[0].carriedLateFee;
        }
        let agreementAmount = Number(
          (
            payGridObj[0].Amount -
            Number(payGridObj[0].lateFee) +
            Number(payGridObj[0].suspenseSub)
          ).toFixed(2)
        );
        //let epoAmount = Number( payGridObj[0].EPOAmount.toFixed(2));
        // let currentDate = new Date();
        // let SACDate = new Date( payGridObj[0].SAC_Date);
        if (payGridObj[0].Agreementtype == "EPO Rent") {
          if (payGridObj[0].Nextduedate == "EPO") {
            epoAmt =
              payGridObj[0].acceptEpoFlag == 0
                ? Number((agreementAmount - payGridObj[0].Taxamount).toFixed(2))
                : Number(payGridObj[0].EPOAmount) +
                Number(payGridObj[0].suspenseSub) -
                Number(payGridObj[0].lateFee) -
                Number(payGridObj[0].EPOTax);
            epoTax =
              payGridObj[0].acceptEpoFlag == 0
                ? Number(payGridObj[0].rpayTaxAmount)
                : Number(payGridObj[0].EPOTax) -
                Number(payGridObj[0].lFeeTaxAmount);
          } else {
            sacAmt =
              payGridObj[0].acceptEpoFlag == 0
                ? Number((agreementAmount - payGridObj[0].Taxamount).toFixed(2))
                : Number(payGridObj[0].EPOAmount) +
                Number(payGridObj[0].suspenseSub) -
                Number(payGridObj[0].lateFee) -
                Number(payGridObj[0].EPOTax);
            sacTax =
              payGridObj[0].acceptEpoFlag == 0
                ? Number(payGridObj[0].rpayTaxAmount)
                : Number(payGridObj[0].EPOTax) -
                Number(payGridObj[0].lFeeTaxAmount);
          }
          setfinalagr(Number(payGridObj[0].Agreementid));
          pifFlag = 1;
        } else if (payGridObj[0].Agreementtype == "PIF Rent") {
          pifAmt =
            payGridObj[0].acceptEpoFlag == 0
              ? Number((agreementAmount - payGridObj[0].Taxamount).toFixed(2))
              : Number(payGridObj[0].EPOAmount) +
              Number(payGridObj[0].suspenseSub) -
              Number(payGridObj[0].lateFee) -
              Number(payGridObj[0].EPOTax);
          pifTax =
            payGridObj[0].acceptEpoFlag == 0
              ? Number(payGridObj[0].rpayTaxAmount)
              : Number(payGridObj[0].EPOTax) -
              Number(payGridObj[0].lFeeTaxAmount);
          setfinalagr(Number(payGridObj[0].Agreementid));
          pifFlag = 1;
        }
      }

      let ChangeAmt = change;
      let ReceiptArray = {
        customerId: Number(customerId),
        schedule: payGridObj[0].Schedule + "|" + payGridObj[0].AgreementNumber,
        amountDue: Number(
          clubFlag !== 1 && pifFlag == 0
            ? (payGridObj[0].Amount - Number(payGridObj[0].lateFee)).toFixed(2)
            : 0
        ),
        ldwFeeAmount: Number(payGridObj[0].agrLdwAmount),
        clubPayment: Number(
          clubFlag === 1
            ? Number(payGridObj[0].Amount.toFixed(2)) -
            Number(payGridObj[0].Taxamount)
            : 0
        ),
        clubPaymentTax: Number(clubFlag === 1 ? payGridObj[0].Taxamount : 0),
        creditOnAccountAdd: 0,
        creditOnAccountSub: Number(payGridObj[0].COA),
        integratedPaymentSuspense: -1 * Number(payGridObj[0].ipSuspense),
        initialPayment:
          clubFlag === 1
            ? 0
            : payGridObj[0].number_of_paymentsmade === 0
              ? 1
              : 0,
        netLateFee: 0,
        carriedLateFee: 0,
        wlDeposit: -1 * Number(payGridObj[0].wlDeposit),
        htSuspense: 0,
        suspenseAdd: Number(payGridObj[0].suspenseAdd),
        suspenseSub: Number(payGridObj[0].suspenseSub), //Number( payGridObj[0].suspense),
        racTirePay: Number(payGridObj[0].policyAmount),
        agreementId:
          clubFlag !== 1 ? Number(payGridObj[0].Agreementid) : undefined,
        change: Number(fixedChange(ChangeAmt)),
        totalTax: payGridObj[0].Taxamount,
        sac: Number(sacAmt),
        sacTax: Number(sacTax),
        epoDiscount:
          payGridObj[0].acceptEpoFlag == 0
            ? 0.0
            : Number(payGridObj[0].actualEpoAmount) -
            Number(payGridObj[0].editedEPOAmount),
        pastDueDiscount:
          payGridObj[0].acceptEpoFlag == 0
            ? 0.0
            : Number(payGridObj[0].actualPastDueRent) -
            Number(payGridObj[0].pastDueRent),
        ldwFeeAmountTax: Number(payGridObj[0].ldwTaxAmount),
        pastDueAmount:
          payGridObj[0].acceptEpoFlag == 0
            ? undefined
            : Number(payGridObj[0].actualPastDueRent),
        nextDueDate:
          payGridObj[0].acceptEpoFlag == 0
            ? undefined
            : payGridObj[0].Nextduedate,
        rentalPaymentTax:
          pifFlag == 0 && payGridObj[0].Agreementtype !== "Club"
            ? Number(payGridObj[0].rpayTaxAmount)
            : 0,
        lateRentalPaymentfeeTax: Number(payGridObj[0].lFeeTaxAmount),
        lateRentalPaymentfee: Number(payGridObj[0].lateFee),
        racTirePayTax: Number(payGridObj[0].policyTaxAmount),
        deliveryFee: Number(payGridObj[0].deliveryAmount),
        deliveryFeeTax: Number(payGridObj[0].deliveryAmountTax),
        processingFee: Number(payGridObj[0].processingFee),
        processingFeeTax: Number(payGridObj[0].processingFeeTax),
        carryRentAmountUsed:
          payGridObj[0].FreeTime == null
            ? Number(payGridObj[0].carryRentAmountUsed)
            : 0,
        carryRentTaxUsed:
          payGridObj[0].FreeTime == null
            ? Number(payGridObj[0].carryRentTaxUsed)
            : 0,
        //otherFee: Number( payGridObj[0].mattressFee),
        //otherFeeTax: Number( payGridObj[0].mattressFeeTax),
        pif: Number(pifAmt),
        pifTax: Number(pifTax),
        extensionAmount: Number(payGridObj[0].extensionAmount),
        convenienceFee: 0,
        convenienceFeeTax: 0,
        mattressFee: Number(payGridObj[0].mattressFee),
      };

      ReceiptCategoriesRequestParam.push(ReceiptArray);

      if (
        payGridObj[0].Agreementtype !== "Club" &&
        payGridObj[0].miscellaneousItemsNum == 0
      ) {
        let acceptPaymentArray = {
          extensionAmount: Number(payGridObj[0].extensionAmount),
          noOfDaysFree: Number(payGridObj[0].noOfDaysFree),
          initialPaymentFlag: payGridObj[0].number_of_paymentsmade == 0 ? 1 : 0,
          addTransferSusFlag: 0,
          schedule: payGridObj[0].Schedule,
          agreementId: Number(payGridObj[0].Agreementid),
          ldwFeeAmount: Number(payGridObj[0].agrLdwAmount.toFixed(2)),
          agreementAmountDue: Number(
            fixedChange(
              String(
                Number(payGridObj[0].Amount) +
                (Number(payGridObj[0].acceptEpoFlag) == 1
                  ? Number(payGridObj[0].suspenseSub)
                  : 0)
              )
            )
          ),
          remainingEpoAmount: Number(
            payGridObj[0].EPOAmount == null
              ? 0.0
              : (Number(payGridObj[0].EPOAmount - payGridObj[0].EPOTax) < 0 ? 0 : payGridObj[0].EPOAmount - payGridObj[0].EPOTax).toFixed(2)
          ),
          promoFreeDays: Number(payGridObj[0].PromoFreeDaysApplied),
          netLateFee: Number(payGridObj[0].lateFee),
          carriedLateFee: Number(
            Number(payGridObj[0].carriedLateFee).toFixed(2)
          ),
          numberOfDaysLate: payGridObj[0].CurrentDaysLate,
          originalDueDate: payGridObj[0].CurrentDueDate,
          nextDueDate: payGridObj[0].Nextduedate,
          totalTax: Number(payGridObj[0].Taxamount.toFixed(2)),
          policyAmount: Number(Number(payGridObj[0].policyAmount)), // Change Made
          editSchedule: payGridObj[0].editSchedule,
          freeTime: payGridObj[0].FreeTime, // Added By Me
          ldwActivate: undefined,
        };
        acceptPaymentParam.push(acceptPaymentArray);
        setAcceptClub(undefined);
      } else if (payGridObj[0].miscellaneousItemsNum == 0) {
        AcceptclubPaymentParam = {
          paymentSchedule: payGridObj[0].Schedule,
          originalDueDate: payGridObj[0].CurrentDueDate,
          nextDueDate: payGridObj[0].Nextduedate,
          numberOfDaysLate: payGridObj[0].CurrentDaysLate,
          clubTax: String(payGridObj[0].Taxamount.toFixed(2)),
          clubAmountDue: Number(fixedChange(String(payGridObj[0].Amount))),
          editSchedule: payGridObj[0].editSchedule,
        };
        setAcceptClub(AcceptclubPaymentParam);
      }
      if (
        adjustDueDateSelectedAgre.length == 1 &&
        payGridObj[0].Agreementtype != "Club"
        // &&
        // payGridObj[0].FreeTime == null
      ) {
        let index = paygridvalues.findIndex(
          (x) => String(x.Agreementid) === String(adjustDueDateSelectedAgre[0])
        );
        if (index != null && index != undefined) {
          loadOtherActions(index);
        }
      }
    }

    //debugger;
    setpaygridvalues(paygridvalues);
    let resTotalAmount = 0.0;
    if (paymentOrigin == "Pay By Phone") {
      resAmount = Number(resAmount) + Number(convAmtWTax);
      resTaxamount = Number(resTaxamount) + Number(convtax);
    }
    if (Number(carryRentAmount) > 0) {
      resAmount = Number(resAmount) - Number(carryRentAmount);
    }
    // if (donationBtnFlag) {
    //   resAmount = Number(resAmount) + Number(donationAmountAdd)
    // }
    let objAvailCreditsAmt = 0;
    if (Number(resAmount) <= Number(payGridObj[0].wlDeposit)) {
      objAvailCreditsAmt = resAmount;
      objAvailCreditsAmt =
        diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
      setAvailableCreds(objAvailCreditsAmt);
      if (diffStoreErrLbl && isRevenueRecogActivated) {
        setavailableCredsremaining([])
      } else {
        setavailableCredsremaining([
          {
            balanceType: "WEBLEAD",
            accountBalance: Number(resAmount).toFixed(2),
          },
        ]);
      }
    } else if (Number(resAmount) > Number(payGridObj[0].wlDeposit)) {
      objAvailCreditsAmt =
        Number(payGridObj[0].COA) +
        Number(payGridObj[0].wlDeposit) +
        Number(
          (Number(resAmount) - Number (payGridObj[0].wlDeposit) 
          > Number(payGridObj[0].ipSuspense) 
          ? Number(payGridObj[0].ipSuspense) : 
          (Number(resAmount) - Number (payGridObj[0].wlDeposit))));
      objAvailCreditsAmt =
        diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
      setAvailableCreds(objAvailCreditsAmt);
      if (diffStoreErrLbl && isRevenueRecogActivated) {
        setavailableCredsremaining([])
      } else {
        setavailableCredsremaining([
          {
            balanceType: "WEBLEAD",
            accountBalance: Number(payGridObj[0].wlDeposit).toFixed(2),
          },
          {
            balanceType: "COA",
            accountBalance: Number(payGridObj[0].COA).toFixed(2),
          },
          {
            balanceType: "IP Suspence",
            accountBalance: Number((Number(resAmount) - Number (payGridObj[0].wlDeposit) 
            > Number(payGridObj[0].ipSuspense) 
            ? Number(payGridObj[0].ipSuspense) : 
            (Number(resAmount) - Number (payGridObj[0].wlDeposit)))).toFixed(2),
          },
        ]);
      }
    } else if (Number(resAmount) == 0) {
      objAvailCreditsAmt =
        Number(payGridObj[0].COA) 
        // + Number(payGridObj[0].ipSuspense);
      objAvailCreditsAmt =
        diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
      setAvailableCreds(objAvailCreditsAmt);
      if (diffStoreErrLbl && isRevenueRecogActivated) {
        setavailableCredsremaining([])
      } else {
        setavailableCredsremaining([
          {
            balanceType: "COA",
            accountBalance: Number(payGridObj[0].COA).toFixed(2),
          },
          // {
          //   balanceType: "IP Suspence",
          //   accountBalance: Number(payGridObj[0].ipSuspense).toFixed(2),
          // },
        ]);
      }
    }
 //Donation 
    // if(resAmount==0 && donationBtnFlag && Number(change) == 0 )
    // {
      
    //   donationAmountAdd=0;
    //   setDonationAmountAdd(donationAmountAdd);
    //   setDonationAmount({...donationAmount,amount:"0.00"})
    //   setDonationtextBoxFlag(true);
    //   setDonationBtnFlag(false);


    // }
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
          .map((bill) => bill.newsuspense)
          .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
          .map((bill) => bill.newsuspense)
          .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
          
    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
          .map((bill) => bill.Amount)
          .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    // if (Number(resTotalAmount) > Number(resAmount) && donationType.isDonationEnabled==false) {
    //   resetPayment(undefined);
    //   setremainingamountdue(
    //     Number(
    //       (
    //         Number(resAmount) -
    //         Number(objAvailCreditsAmt) +
    //         Number(donationAmountAdd) +
    //         Number(addSusTotalAmount) -
    //         Number(usedSusTotalAmount)
    //       ).toFixed(2)
    //     )
    //   );
    //   setRemaniningAmtStatic(
    //     Number(
    //       (
    //         Number(resAmount) -
    //         Number(objAvailCreditsAmt) +
    //         Number(donationAmountAdd)+
    //         Number(addSusTotalAmount) -
    //         Number(usedSusTotalAmount)
    //       ).toFixed(2)
    //     )
    //   );
    //   setchange("0.00");
    //   setAcceptpaymentbuttonenable(true);
    // } else {
      
      if (
        Number(resTotalAmount) >=
        Number(
          (
            Number(resAmount) -
            Number(objAvailCreditsAmt) +
            Number(donationAmountAdd)+
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          ).toFixed(2)
        )
      ) {
        setchange(
          Number(resTotalAmount) -
          Number(
            (
              Number(resAmount) -
              Number(objAvailCreditsAmt) +
              Number(donationAmountAdd)+
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount)
            ).toFixed(2)
          )
        );
        setremainingamountdue("0.00");
        setRemaniningAmtStatic("0.00");
        setAcceptpaymentbuttonenable(false);
      } else {
        setremainingamountdue(
          Number(
            (
              Number(resAmount) -
              Number(objAvailCreditsAmt) +
              Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setRemaniningAmtStatic(
          Number(
            (
              Number(resAmount) -
              Number(objAvailCreditsAmt) +
              Number(donationAmountAdd)+
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setchange("0.00");
        setAcceptpaymentbuttonenable(true);
      }
    // }
    let addedlatevalue = 0;
    for (let i = 0; i < checkedAgr?.length; i++) {
      addedlatevalue = Number(checkedAgr[i].lateFee) + addedlatevalue;
    }
    totaldue = resAmount;
    settotaldue(totaldue);
    totaltax = Number(resTaxamount)
    settotaltax(totaltax);
    // settotaldue(resAmount);
    // settotaltax(Number(resTaxamount));
    setPopUpCarryWaive(false);
    setPopupCarrywaiveLate(false);
    setopenAdjustDueDate(false);
    setopenPromoFreeDays(false);
    seteditSchedulePopUp(false);
    setLatelast10value(addedlatevalue);
    // if (isRevenueRecogActivated) {
    //   multipleAdjustRevenue();
    // } else {
      multipleAdjust();
    // }
  };

  const editCommitmentRes = (response: any) => {
    let payGridObj;
    let prevAmt;
    let resAmount = 0;
    let resTaxamount = 0;
    totalduereceiptidsupport = [];
    acceptPaymentParam = [];
    ReceiptCategoriesRequestParam = [];
    let res = response.resArray;
    for (let i = 0; i < response.selectedAgr.length; i++) {
      resAmount = Number(resAmount) + Number(res[i].Amount);
      resTaxamount = Number(resTaxamount) + Number(res[i].Taxamount);
      payGridObj = paygridvalues.filter(
        (x) => x.Agreementid === res[i].Agreementid
      );
      prevAmt = payGridObj[0].Amount;
      payGridObj[0].Amount = res[i].Amount;
      payGridObj[0].Nextduedate =
        res[i].Nextduedate == undefined
          ? payGridObj[0].Nextduedate
          : res[i].Nextduedate;
      payGridObj[0].Taxamount = res[i].Taxamount;
      payGridObj[0].Schedule =
        res[i].Schedule == undefined ? payGridObj[0].Schedule : res[i].Schedule;
      payGridObj[0].editSchedule =
        res[i].editSchedule == undefined
          ? payGridObj[0].editSchedule
          : res[i].editSchedule;
      // payGridObj[0].agreementRateDetails.scheduleRefCode = res[i].editSchedule==undefined?payGridObj[0].agreementRateDetails.scheduleRefCode:res[i].editSchedule
      payGridObj[0].ldwTaxAmount =
        res[i].ldwTaxAmount == undefined
          ? payGridObj[0].ldwTaxAmount
          : res[i].ldwTaxAmount;
      payGridObj[0].agrLdwAmount =
        res[i].agrLdwAmount == undefined
          ? payGridObj[0].agrLdwAmount
          : res[i].agrLdwAmount;
      payGridObj[0].policyTaxAmount =
        res[i].policyTaxAmount == undefined
          ? payGridObj[0].policyTaxAmount
          : res[i].policyTaxAmount;
      payGridObj[0].policyAmount =
        res[i].policyAmount == undefined
          ? payGridObj[0].policyAmount
          : res[i].policyAmount;
      payGridObj[0].agreementRate =
        res[i].agreementRate == undefined
          ? payGridObj[0].agreementRate
          : res[i].agreementRate;
      payGridObj[0].PromoFreeDays =
        res[i].PromoFreeDaysApplied == undefined
          ? payGridObj[0].PromoFreeDays
          : Number(payGridObj[0].PromoFreeDays) -
            Number(res[i].PromoFreeDaysApplied);
      payGridObj[0].PromoFreeDaysApplied =
        res[i].PromoFreeDaysApplied == undefined
          ? payGridObj[0].PromoFreeDaysApplied
          : res[i].PromoFreeDaysApplied;
      payGridObj[0].carriedLate =
        res[i].FreeTime !== null ? 0 : res[i].carriedLate;
      payGridObj[0].carriedLateTax =
        res[i].FreeTime !== null ? 0 : res[i].carriedLateTax;
      // payGridObj[0].agreementRateTax=res[i].agreementRateTax==undefined?payGridObj[0].agreementRateTax:res[i].agreementRateTax
      payGridObj[0].rpayTaxAmount =
        res[i].rpayTaxAmount == undefined
          ? payGridObj[0].rpayTaxAmount
          : res[i].rpayTaxAmount;
      payGridObj[0].Agreementtype =
        res[i].Agreementtype == undefined
          ? payGridObj[0].Agreementtype
          : res[i].Agreementtype;
      if (
        response.adjustEditflag !== undefined &&
        response.adjustEditflag === 1 &&
        response.adjustedAgreement !== undefined &&
        Number(response.adjustedAgreement) == Number(payGridObj[0].Agreementid)
      ) {
        payGridObj[0].dueDateModified = true;
      }
      // else {
      //   payGridObj[i].dueDateModified = false;
      // }

      //to set the receipt item values
      const agreementDetails = {
        agreement_id: payGridObj[0].AgreementNumber,
        agreement_totalamount: fixedChange(String(res[i].Amount)),
      };
      totalduereceiptidsupport.push(agreementDetails);
      settotalduereceiptid(totalduereceiptidsupport);
      //accept payment array push starts here
      let ldwamountcalculation = "0.00";
      let objCarriedLateFee = 0;
      let objAmount = "0.00";
      let clubFlag = 0;
      let ldwFeeTax;
      let pifAmt = 0;
      let pifTax = 0;
      let sacTax = 0;
      let epoAmt = 0;
      let epoTax = 0;
      let pifFlag = 0;
      let sacAmt = 0;
      if (localGetPayment !== undefined && localGetPayment !== null) {
        //let objLdwInfo = getpayment.paymentInfo.ldwInformation.filter(x => x.agreementId ===  payGridObj[0].Agreementid);
        let objAmtDue;

        if (payGridObj[0].Agreementtype === "Club") {
          objAmtDue = localGetPayment.amountDue.amountDueResponse.filter(
            (x) => x.clubAmountDue !== undefined
          );
        } else {
          objAmtDue = localGetPayment.amountDue.amountDueResponse.filter(
            (x) => x.agreementId === payGridObj[0].Agreementid
          );
        }

        if (objAmtDue.length > 0) {
          if (
            objAmtDue[0].ldwLateRent !== undefined &&
            objAmtDue[0].ldwLateRent !== null &&
            objAmtDue[0].ldwLateRent !== "" &&
            objAmtDue[0].ldwLateRent !== "0"
          ) {
            ldwamountcalculation = objAmtDue[0].ldwLateRent;
            ldwFeeTax = Number(objAmtDue[0].ldwTaxAmount);
          } else if (
            objAmtDue[0].ldwAmount !== undefined &&
            objAmtDue[0].ldwAmount !== null &&
            objAmtDue[0].ldwAmount !== ""
          ) {
            ldwamountcalculation = objAmtDue[0].ldwAmount;
            ldwFeeTax = Number(objAmtDue[0].ldwTaxAmount);
          }
          if (
            objAmtDue[0].amountDueLateRent !== undefined &&
            objAmtDue[0].amountDueLateRent !== null &&
            objAmtDue[0].amountDueLateRent !== "" &&
            objAmtDue[0].amountDueLateRent !== "0"
          ) {
            objAmount = objAmtDue[0].amountDueLateRent;
          } else if (objAmtDue[0].amountDue !== undefined) {
            objAmount = String(objAmtDue[0].amountDue);
          } else if (
            objAmtDue[0].clubAmountLateRent !== undefined &&
            objAmtDue[0].clubAmountLateRent !== null &&
            objAmtDue[0].clubAmountLateRent !== ""
          ) {
            clubFlag = 1;
            objAmount = objAmtDue[0].clubAmountLateRent;
          } else {
            clubFlag = 1;
            objAmount = String(objAmtDue[0].clubAmountDue);
          }
        }

        let objPayHistory =
          localGetPayment.paymentInfo.paymentHistoryInfo.filter(
            (x) => x.agreementId === payGridObj[0].Agreementid
          );
        if (objPayHistory !== undefined && objPayHistory.length > 0) {
          objCarriedLateFee = objPayHistory[0].carriedLateFee;
        }
        let agreementAmount = Number(
          (
            payGridObj[0].Amount -
            Number(payGridObj[0].lateFee) +
            Number(payGridObj[0].suspenseSub)
          ).toFixed(2)
        );
        if (payGridObj[0].Agreementtype == "EPO Rent") {
          if (payGridObj[0].Nextduedate == "EPO") {
            epoAmt =
              payGridObj[0].acceptEpoFlag == 0
                ? Number((agreementAmount - payGridObj[0].Taxamount).toFixed(2))
                : Number(payGridObj[0].EPOAmount) +
                  Number(payGridObj[0].suspenseSub) -
                  Number(payGridObj[0].lateFee) -
                  Number(payGridObj[0].EPOTax);
            epoTax =
              payGridObj[0].acceptEpoFlag == 0
                ? Number(payGridObj[0].rpayTaxAmount)
                : Number(payGridObj[0].EPOTax) -
                  Number(payGridObj[0].lFeeTaxAmount);
          } else {
            sacAmt =
              payGridObj[0].acceptEpoFlag == 0
                ? Number((agreementAmount - payGridObj[0].Taxamount).toFixed(2))
                : Number(payGridObj[0].EPOAmount) +
                  Number(payGridObj[0].suspenseSub) -
                  Number(payGridObj[0].lateFee) -
                  Number(payGridObj[0].EPOTax);
            sacTax =
              payGridObj[0].acceptEpoFlag == 0
                ? Number(payGridObj[0].rpayTaxAmount)
                : Number(payGridObj[0].EPOTax) -
                  Number(payGridObj[0].lFeeTaxAmount);
          }
          setfinalagr(Number(payGridObj[0].Agreementid));
          pifFlag = 1;
        } else if (payGridObj[0].Agreementtype == "PIF Rent") {
          pifAmt =
            payGridObj[0].acceptEpoFlag == 0
              ? Number((agreementAmount - payGridObj[0].Taxamount).toFixed(2))
              : Number(payGridObj[0].EPOAmount) +
                Number(payGridObj[0].suspenseSub) -
                Number(payGridObj[0].lateFee) -
                Number(payGridObj[0].EPOTax);
          pifTax =
            payGridObj[0].acceptEpoFlag == 0
              ? Number(payGridObj[0].rpayTaxAmount)
              : Number(payGridObj[0].EPOTax) -
                Number(payGridObj[0].lFeeTaxAmount);
          setfinalagr(Number(payGridObj[0].Agreementid));
          pifFlag = 1;
        }
      }

      let ChangeAmt = change;
      let ReceiptArray = {
        customerId: Number(customerId),
        schedule: payGridObj[0].Schedule + "|" + payGridObj[0].AgreementNumber,
        amountDue: Number(
          clubFlag !== 1 && pifFlag == 0
            ? (payGridObj[0].Amount - Number(payGridObj[0].lateFee)).toFixed(2)
            : 0
        ),
        ldwFeeAmount: Number(payGridObj[0].agrLdwAmount),
        clubPayment: Number(
          clubFlag === 1
            ? Number(payGridObj[0].Amount.toFixed(2)) -
                Number(payGridObj[0].Taxamount)
            : 0
        ),
        clubPaymentTax: Number(clubFlag === 1 ? payGridObj[0].Taxamount : 0),
        creditOnAccountAdd: 0,
        creditOnAccountSub: Number(payGridObj[0].COA),
        integratedPaymentSuspense: -1 * Number(payGridObj[0].ipSuspense),
        initialPayment:
          clubFlag === 1
            ? 0
            : payGridObj[0].number_of_paymentsmade === 0
            ? 1
            : 0,
        netLateFee: 0,
        carriedLateFee: 0,
        wlDeposit: -1 * Number(payGridObj[0].wlDeposit),
        htSuspense: 0,
        suspenseAdd: Number(payGridObj[0].suspenseAdd),
        suspenseSub: Number(payGridObj[0].suspenseSub), //Number( payGridObj[0].suspense),
        racTirePay: Number(payGridObj[0].policyAmount),
        agreementId:
          clubFlag !== 1 ? Number(payGridObj[0].Agreementid) : undefined,
        change: Number(fixedChange(ChangeAmt)),
        totalTax: payGridObj[0].Taxamount,
        sac: Number(sacAmt),
        sacTax: Number(sacTax),
        epoDiscount:
          payGridObj[0].acceptEpoFlag == 0
            ? 0.0
            : Number(payGridObj[0].actualEpoAmount) -
              Number(payGridObj[0].editedEPOAmount),
        pastDueDiscount:
          payGridObj[0].acceptEpoFlag == 0
            ? 0.0
            : Number(payGridObj[0].actualPastDueRent) -
              Number(payGridObj[0].pastDueRent),
        ldwFeeAmountTax: Number(payGridObj[0].ldwTaxAmount),
        pastDueAmount:
          payGridObj[0].acceptEpoFlag == 0
            ? undefined
            : Number(payGridObj[0].actualPastDueRent),
        nextDueDate:
          payGridObj[0].acceptEpoFlag == 0
            ? undefined
            : payGridObj[0].Nextduedate,
        rentalPaymentTax:
          pifFlag == 0 && payGridObj[0].Agreementtype !== "Club"
            ? Number(payGridObj[0].rpayTaxAmount)
            : 0,
        lateRentalPaymentfeeTax: Number(payGridObj[0].lFeeTaxAmount),
        lateRentalPaymentfee: Number(payGridObj[0].lateFee),
        racTirePayTax: Number(payGridObj[0].policyTaxAmount),
        deliveryFee: Number(payGridObj[0].deliveryAmount),
        deliveryFeeTax: Number(payGridObj[0].deliveryAmountTax),
        processingFee: Number(payGridObj[0].processingFee),
        processingFeeTax: Number(payGridObj[0].processingFeeTax),
        carryRentAmountUsed:
          payGridObj[0].FreeTime == null
            ? Number(payGridObj[0].carryRentAmountUsed)
            : 0,
        carryRentTaxUsed:
          payGridObj[0].FreeTime == null
            ? Number(payGridObj[0].carryRentTaxUsed)
            : 0,
        //otherFee: Number( payGridObj[0].mattressFee),
        //otherFeeTax: Number( payGridObj[0].mattressFeeTax),
        pif: Number(pifAmt),
        pifTax: Number(pifTax),
        extensionAmount: Number(payGridObj[0].extensionAmount),
        convenienceFee: 0,
        convenienceFeeTax: 0,
        mattressFee: Number(payGridObj[0].mattressFee),
      };

      ReceiptCategoriesRequestParam.push(ReceiptArray);

      if (
        payGridObj[0].Agreementtype !== "Club" &&
        payGridObj[0].miscellaneousItemsNum == 0
      ) {
        let acceptPaymentArray = {
          extensionAmount: Number(payGridObj[0].extensionAmount),
          noOfDaysFree: Number(payGridObj[0].noOfDaysFree),
          initialPaymentFlag: payGridObj[0].number_of_paymentsmade == 0 ? 1 : 0,
          addTransferSusFlag: 0,
          schedule: payGridObj[0].Schedule,
          agreementId: Number(payGridObj[0].Agreementid),
          ldwFeeAmount: Number(payGridObj[0].agrLdwAmount.toFixed(2)),
          agreementAmountDue: Number(
            fixedChange(
              String(
                Number(payGridObj[0].Amount) +
                  (Number(payGridObj[0].acceptEpoFlag) == 1
                    ? Number(payGridObj[0].suspenseSub)
                    : 0)
              )
            )
          ),
          remainingEpoAmount: Number(
            payGridObj[0].EPOAmount == null
              ? 0.0
              : (Number(payGridObj[0].EPOAmount - payGridObj[0].EPOTax) < 0
                  ? 0
                  : payGridObj[0].EPOAmount - payGridObj[0].EPOTax
                ).toFixed(2)
          ),
          promoFreeDays: Number(payGridObj[0].PromoFreeDaysApplied),
          netLateFee: Number(payGridObj[0].lateFee),
          carriedLateFee: Number(
            Number(payGridObj[0].carriedLateFee).toFixed(2)
          ),
          numberOfDaysLate: payGridObj[0].CurrentDaysLate,
          originalDueDate: payGridObj[0].CurrentDueDate,
          nextDueDate: payGridObj[0].Nextduedate,
          totalTax: Number(payGridObj[0].Taxamount.toFixed(2)),
          policyAmount: Number(Number(payGridObj[0].policyAmount)),
          editSchedule: payGridObj[0].editSchedule,
          freeTime: payGridObj[0].FreeTime, // Added By Me
          ldwActivate: undefined,
        };
        acceptPaymentParam.push(acceptPaymentArray);
        setAcceptClub(undefined);
      } else if (payGridObj[0].miscellaneousItemsNum == 0) {
        AcceptclubPaymentParam = {
          paymentSchedule: payGridObj[0].Schedule,
          originalDueDate: payGridObj[0].CurrentDueDate,
          nextDueDate: payGridObj[0].Nextduedate,
          numberOfDaysLate: payGridObj[0].CurrentDaysLate,
          clubTax: String(payGridObj[0].Taxamount.toFixed(2)),
          clubAmountDue: Number(fixedChange(String(payGridObj[0].Amount))),
          editSchedule: payGridObj[0].editSchedule,
        };
        setAcceptClub(AcceptclubPaymentParam);
      }
      if (
        adjustDueDateSelectedAgre.length == 1 &&
        payGridObj[0].Agreementtype != "Club"
        // &&
        // payGridObj[0].FreeTime == null
      ) {
        let index = paygridvalues.findIndex(
          (x) => String(x.Agreementid) === String(adjustDueDateSelectedAgre[0])
        );
        if (index != null && index != undefined) {
          loadOtherActions(index);
        }
      }
    }

    let currentstorenumber = window.sessionStorage.getItem("storeNumber");

    let sortableData: any = paygridvalues;
    paygridvalues = [];
    // let payAgrs = sortableData.sort((a: any, b: any) =>
    //   a["store_number"] < b["store_number"] ? -1 : 1
    // );
    let Clubagreementinsertion = sortableData.filter(
      (x) => x.Agreementtype === "Club"
    );
    let curStoreData = sortableData.filter(
      (x) =>
        x.store_number.toString() === currentstorenumber &&
        x.Agreementtype !== "Club"
    );
    Clubagreementinsertion.push(...curStoreData);

    let otherStoreData = sortableData.filter(
      (x) =>
        x.store_number.toString() !== currentstorenumber &&
        x.Agreementtype !== "Club"
    );
    Clubagreementinsertion.push(...otherStoreData);
    paygridvalues = Clubagreementinsertion;
    setpaygridvalues(Clubagreementinsertion);
    let resTotalAmount = 0.0;
    if (paymentOrigin == "Pay By Phone") {
      resAmount = Number(resAmount) + Number(convAmtWTax);
      resTaxamount = Number(resTaxamount) + Number(convtax);
    }
    let objAvailCreditsAmt = 0;
    if (Number(resAmount) <= Number(payGridObj[0].wlDeposit)) {
      objAvailCreditsAmt = resAmount;
      objAvailCreditsAmt =
        diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
      setAvailableCreds(objAvailCreditsAmt);
      if (diffStoreErrLbl && isRevenueRecogActivated) {
        setavailableCredsremaining([]);
      } else {
        setavailableCredsremaining([
          {
            balanceType: "WEBLEAD",
            accountBalance: Number(resAmount).toFixed(2),
          },
        ]);
      }
    } else if (Number(resAmount) > Number(payGridObj[0].wlDeposit)) {
      objAvailCreditsAmt =
        Number(payGridObj[0].COA) +
        Number(payGridObj[0].wlDeposit) +
        Number(
          (Number(resAmount) - Number (payGridObj[0].wlDeposit) 
          > Number(payGridObj[0].ipSuspense) 
          ? Number(payGridObj[0].ipSuspense) : 
          (Number(resAmount) - Number (payGridObj[0].wlDeposit))));
      objAvailCreditsAmt =
        diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
      setAvailableCreds(objAvailCreditsAmt);
      if (diffStoreErrLbl && isRevenueRecogActivated) {
        setavailableCredsremaining([]);
      } else {
        setavailableCredsremaining([
          {
            balanceType: "WEBLEAD",
            accountBalance: Number(payGridObj[0].wlDeposit).toFixed(2),
          },
          {
            balanceType: "COA",
            accountBalance: Number(payGridObj[0].COA).toFixed(2),
          },
          {
            balanceType: "IP Suspence",
            accountBalance: Number((Number(resAmount) - Number (payGridObj[0].wlDeposit) 
            > Number(payGridObj[0].ipSuspense) 
            ? Number(payGridObj[0].ipSuspense) : 
            (Number(resAmount) - Number (payGridObj[0].wlDeposit)))).toFixed(2),
          },
        ]);
      }
    } else if (Number(resAmount) == 0) {
      objAvailCreditsAmt =
        Number(payGridObj[0].COA) 
        // + Number(payGridObj[0].ipSuspense);
      objAvailCreditsAmt =
        diffStoreErrLbl && isRevenueRecogActivated ? 0 : objAvailCreditsAmt;
      setAvailableCreds(objAvailCreditsAmt);
      if (diffStoreErrLbl && isRevenueRecogActivated) {
        setavailableCredsremaining([]);
      } else {
        setavailableCredsremaining([
          {
            balanceType: "COA",
            accountBalance: Number(payGridObj[0].COA).toFixed(2),
          },
          // {
          //   balanceType: "IP Suspence",
          //   accountBalance: Number(payGridObj[0].ipSuspense).toFixed(2),
          // },
        ]);
      }
    }
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (Number(resTotalAmount) > Number(resAmount)) {
      resetPayment(undefined);
      setremainingamountdue(
        Number(
          (
            Number(resAmount) -
            Number(objAvailCreditsAmt)+
            Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ).toFixed(2)
        )
      );
      setRemaniningAmtStatic(
        Number(
          (
            Number(resAmount) -
            Number(objAvailCreditsAmt)+
            Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ).toFixed(2)
        )
      );
      setchange("0.00");
      setAcceptpaymentbuttonenable(true);
    } else {
      if (
        Number(resTotalAmount) >=
        Number(
          (
            Number(resAmount) -
            Number(objAvailCreditsAmt)+
            Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ).toFixed(2)
        )
      ) {
        setchange(
          Number(resTotalAmount) -
            Number(
              (
                Number(resAmount) -
                Number(objAvailCreditsAmt)+
                Number(donationAmountAdd) +
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
              ).toFixed(2)
            )
        );
        setremainingamountdue("0.00");
        setRemaniningAmtStatic("0.00");
        setAcceptpaymentbuttonenable(false);
      } else {
        setremainingamountdue(
          Number(
            (
              Number(resAmount) -
              Number(objAvailCreditsAmt) +
              Number(addSusTotalAmount)+
              Number(donationAmountAdd) -
              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setRemaniningAmtStatic(
          Number(
            (
              Number(resAmount) -
              Number(objAvailCreditsAmt)+
              Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setchange("0.00");
        setAcceptpaymentbuttonenable(true);
      }
    }

    settotaldue(resAmount);
    settotaltax(Number(resTaxamount));

    setopenAdjustDueDate(false);
    setopenPromoFreeDays(false);
    seteditSchedulePopUp(false);
    // if (isRevenueRecogActivated) {
    //   multipleAdjustRevenue();
    // } else {
      multipleAdjust();
    // }
  };
  const carryOnchange = () => {


    if (
      carryRentWidget == false &&
      Number(selectedCheckBoxCount) > 0 &&
      carryRentInitialAndFinal == false
    ) {
      setcarryRentWidget(true);
    } else if (carryRentWidget == true) {
      setcarryRentWidget(false);
      
      if(checkedAgr?.length>0)
        settotaldue(Number(totaldue) + Number(carryRentAmount));    
       
      carryRentAmount="0.00"
      setcarryRentAmount("0.00");
      tenderAmountCalculation()
    }
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:carryRentWidget?"Carry Toggle Off":"Carry Toggle on"
    }
    setUserTraceData([...userTraceData,traceObj])
  };
  const carryCheckChange = () => {
    // debugger;
    // if(Number(carrytotalCalci)>Number(carryRentAmount)){
    //   setremainingamountdue(Number((Number(remainingamountdue)-Number(carryRentAmount)).toFixed(2)))
    //   settotaldue(Number(Number(totaldue)-Number(carryRentAmount)).toFixed(2))
    //   // setcarryRentAmount("0.00")
    //   setcarryRentCheck(false)
    // }else{
    // if((carryRentCheck)==true){

    let ldwtax = 0;
    let ldwAmount = 0;
    let ldwtaxwithAmount = 0;

    let totalCalciText = 0;
    let reqAgreeArray = checkedAgr.filter(
      (x) =>
        x.Agreementtype != "Club" &&
        x.Nextduedate != "SAC" &&
        x.Nextduedate != "PIF" &&
        x.Nextduedate != "EPO" &&
        x.number_of_paymentsmade != 0 &&
        x.FreeTime == null // Added By Me
    );

    // if(ag)
    for (let i = 0; i < reqAgreeArray.length; i++) {
      let ldwTerminateToggle = 0;
      let coverageEndDateInfo: any = [];

      //  if(props.mxcarry[i].agreementRateDetails=="Weekly"){

      if (reqAgreeArray[i].agreementRateDetails != undefined) {
        let startDate = moment(reqAgreeArray[i].CurrentDueDate);
        let endDate = moment(reqAgreeArray[i].Nextduedate);
        let result = endDate.diff(startDate, "days");
        if (Number(result) != 1) {
          totalCalciText =
            totalCalciText +
            (Number(reqAgreeArray[i].agreementRateDetails.perDayRent) +
              Number(reqAgreeArray[i].agreementRateDetails.perDayRentTax));
          for (let j = 0; j < actLDWArr.length; j++) {
            // let coverageEndDate=""
            // let policyStatus=""
            if (actLDWArr.length > 0) {
              // props.ldwChangeArr
              coverageEndDateInfo = actLDWArr.filter(
                (x) =>
                  String(x.agreementId) === String(reqAgreeArray[i].Agreementid)
              );
              if (coverageEndDateInfo.length > 0) {
                if (Number(coverageEndDateInfo[0].action) == 2) {
                  // coverageEndDate =  moment().format('MM/DD/YYYY');
                  ldwTerminateToggle = 1;
                } else if (Number(coverageEndDateInfo[0].action) == 1) {
                  // coverageEndDate = "12/31/2099"
                  ldwTerminateToggle = 2;
                  if (
                    Number(reqAgreeArray[i].agreementRateDetails.perDayLdw) == 0
                  ) {
                    reqAgreeArray[i].agreementRateDetails.perDayLdw = (
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .scheduleRates.weeklyRate
                      ) / 7
                    ).toFixed(2);
                    reqAgreeArray[i].agreementRateDetails.perDayLdwTax = (
                      (Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .scheduleRates.weeklyRate
                      ) /
                        7) *
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .ldwTaxRate
                      )
                    ).toFixed(2);
                    reqAgreeArray[i].agreementRateDetails.weeklyLdw = Number(
                      coverageEndDateInfo[0].cusCoverageData.ldwInfo
                        .scheduleRates.weeklyRate
                    ).toFixed(2);
                    reqAgreeArray[i].agreementRateDetails.weeklyLdwTax = (
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .scheduleRates.weeklyRate
                      ) *
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .ldwTaxRate
                      )
                    ).toFixed(2);
                    reqAgreeArray[i].agreementRateDetails.biWeeklyLdw = Number(
                      coverageEndDateInfo[0].cusCoverageData.ldwInfo
                        .scheduleRates.biweeklyRate
                    ).toFixed(2);
                    reqAgreeArray[i].agreementRateDetails.biWeeklyLdwTax = (
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .scheduleRates.biweeklyRate
                      ) *
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .ldwTaxRate
                      )
                    ).toFixed(2);
                    reqAgreeArray[i].agreementRateDetails.monthlyLdw = Number(
                      coverageEndDateInfo[0].cusCoverageData.ldwInfo
                        .scheduleRates.monthlyRate
                    ).toFixed(2);
                    reqAgreeArray[i].agreementRateDetails.monthlyLdwTax = (
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .scheduleRates.monthlyRate
                      ) *
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .ldwTaxRate
                      )
                    ).toFixed(2);
                    reqAgreeArray[i].agreementRateDetails.semiMonthlyLdw =
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .scheduleRates.semimonthlyRate
                      ).toFixed(2);
                    reqAgreeArray[i].agreementRateDetails.semiMonthlyLdwTax = (
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .scheduleRates.semimonthlyRate
                      ) *
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .ldwTaxRate
                      )
                    ).toFixed(2);
                  }
                }
              }
            }
          }
          if (
            Number(reqAgreeArray[i].agreementRateDetails.perDayLdw) == 0 &&
            ldwTerminateToggle == 2
          ) {
            if (
              Number(
                coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                  .weeklyRate
              ) > 0
            ) {
              ldwtaxwithAmount =
                ldwtaxwithAmount +
                Number(
                  (
                    Number(
                      coverageEndDateInfo[0].cusCoverageData.ldwInfo
                        .scheduleRates.weeklyRate
                    ) / 7
                  ).toFixed(2)
                );
            }
          } else {
            ldwtax =
              ldwTerminateToggle == 1 ||
              Number(reqAgreeArray[i].agrLdwAmount) == 0
                ? 0
                : Number(reqAgreeArray[i].agreementRateDetails.perDayLdwTax);
            ldwAmount =
              ldwTerminateToggle == 1 ||
              Number(reqAgreeArray[i].agrLdwAmount) == 0
                ? 0
                : Number(reqAgreeArray[i].agreementRateDetails.perDayLdw);
            ldwtaxwithAmount =
              ldwtaxwithAmount + Number(ldwtax) + Number(ldwAmount);
          }
        }
      }
    }

    totalCalciText = Number(
      (Number(totalCalciText) + Number(ldwtaxwithAmount)).toFixed(2)
    );
    let resTotalAmount = 0.0;
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (selectallChecked && carryRentInitialAndFinal == false) {
      setremainingamountdue(
        Number(
          (
            Number(remainingamountdue)+
            Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) -
            Number(carryRentAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ).toFixed(2)
        )
      );
      setRemaniningAmtStatic(
        Number(
          (
            Number(remainingamountdue)+
            Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) -
            Number(carryRentAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ).toFixed(2)
        )
      );
      settotaldue(
        Number(Number(totaldue) - Number(carryRentAmount)).toFixed(2)
      );
      // setcarryRentAmount("0.00")
      setselectallChecked(false);
    } else if (
      Number(Number(totalCalciText).toFixed(2)) < Number(carryRentAmount) ||
      (carryRentInitialAndFinal && selectallChecked == false)
    ) {
      setremainingamountdue(
        Number(
          (
            Number(remainingamountdue)+
            Number(donationAmountAdd)+
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) +
            Number(carryRentAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ).toFixed(2)
        )
      );
      setRemaniningAmtStatic(
        Number(
          (
            Number(remainingamountdue)+
            Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) +
            Number(carryRentAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ).toFixed(2)
        )
      );
      settotaldue(
        Number(Number(totaldue) + Number(carryRentAmount)).toFixed(2)
      );
      setcarryRentAmount("0.00");
      if (paygridvalues != undefined) {
        for (let i = 0; i < paygridvalues.length; i++) {
          paygridvalues[i].carryRentAmountAdd = 0.0;
        }
      }
      if (carryRentInitialAndFinal) {
        setcarryRentWidget(false);
      }
    }
    if (carryRentInitialAndFinal && selectallChecked == true) {
      setcarryRentAmount("0.00");
      if (paygridvalues != undefined) {
        for (let i = 0; i < paygridvalues.length; i++) {
          paygridvalues[i].carryRentAmountAdd = 0.0;
        }
      }
    }
    const cardAmount =
      multipleCard.length == 0
        ? 0.0
        : multipleCard
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let cvvToggle = 0;
    if (
      (Number(cardAmount) > 0 && cvvTextValue.length < 3) ||
      CardErrorLbl == true
    ) {
      cvvToggle = 1;
    }
    if (
      (Number(Number(resTotalAmount)) >=
        Number(totaldue) +
          Number(carryRentAmount)+
          Number(donationAmountAdd) +
          Number(addSusTotalAmount) -
          Number(availableCreds) -
          Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount) &&
        selectedCheckBoxCount > 0) ||
      (Number(remainingamountdue) <= 0 &&
        cvvToggle == 0 &&
        selectedCheckBoxCount > 0)
    ) {
      setAcceptpaymentbuttonenable(false);
    } else {
      setAcceptpaymentbuttonenable(true);
    }
    setcarryRentCheck(false);
    if (carryRentWidget) {
      carryOnchange();
    }
    // }

    // }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const Assignsave_click = async () => {
    try {
      // eslint-disable-next-line no-debugger
      // //debugger;
      const removeAlert: any = [];
      const AlertWizardObj: any = AlertWizard;
      for (let i = 0; i < AlertUpdateObj.length; i++) {
        const alertExist = AlertWizardObj.filter(
          (val: any) => AlertUpdateObj[i].alertTypeId == val.alertTypeId
        );
        if (
          AlertUpdateObj[i].customerAlertId !== null &&
          AlertUpdateObj[i].alertCleared !== 1
          // eslint-disable-next-line sonarjs/no-duplicated-branches
        ) {
          removeAlert.push({
            alertTypeId: parseInt(AlertUpdateObj[i].alertTypeId),
            customerAlertId:
              AlertUpdateObj[i].customerAlertId !== undefined &&
              AlertUpdateObj[i].customerAlertId !== null &&
              AlertUpdateObj[i].customerAlertId !== ""
                ? parseInt(AlertUpdateObj[i].customerAlertId)
                : alertExist.length > 0 && AlertUpdateObj[i].alertTypeId != "3"
                ? parseInt(alertExist[0].customerAlertId)
                : null,
            alertText:
              AlertUpdateObj[i].alertTypeId == "3" ? CustomAlerttxt : "",
            alertClear: AlertUpdateObj[i].alertCleared,
          });
        } else if (
          AlertUpdateObj[i].customerAlertId !== null &&
          AlertUpdateObj[i].alertCleared == 1
          // eslint-disable-next-line sonarjs/no-duplicated-branches
        ) {
          removeAlert.push({
            alertTypeId: parseInt(AlertUpdateObj[i].alertTypeId),
            customerAlertId:
              AlertUpdateObj[i].customerAlertId !== undefined &&
              AlertUpdateObj[i].customerAlertId !== null &&
              AlertUpdateObj[i].customerAlertId !== ""
                ? parseInt(AlertUpdateObj[i].customerAlertId)
                : alertExist.length > 0 && AlertUpdateObj[i].alertTypeId != "3"
                ? parseInt(alertExist[0].customerAlertId)
                : null,
            alertText:
              AlertUpdateObj[i].alertTypeId == "3" ? CustomAlerttxt : "",
            alertClear: AlertUpdateObj[i].alertCleared,
          });
        }
      }
      // debugger;
      let removeAlertObj = removeAlert;
      removeAlertObj = removeAlertObj.filter(
        (val: any) =>
          (val.customerAlertId == undefined || val.customerAlertId == null) &&
          val.alertClear == 1
      );
      const AlertObj: any = [];
      for (let i = 0; i < removeAlert.length; i++) {
        const inValidAlert = removeAlertObj.filter(
          (val: any) => val.alertTypeId == removeAlert[i].alertTypeId
        );
        if (inValidAlert.length == 0) {
          AlertObj.push(removeAlert[i]);
        }
      }
      const payload: any = {
        alertTypes: AlertObj,
        customerId: parseInt(customerId),
        userId: "191197",
      };
      /* eslint-disable no-console */
      setIsAlertLoading(true);
      const response: any = await UpdateAlert(payload, customerId);

      // eslint-disable-next-line no-console
      setCustomAlerttxt("");
      setEnabletxt(true);
      if (response !== undefined && response !== null) {
        await buildActiveAlerts();
      }
      setIsAlertLoading(false);
      //getAlerts();
      closeAlert();
    } catch (e: any) {
      setIsAlertLoading(false);
      //getAlerts();
      closeAlert();
    }
  };

  const Cancelclick = () => {
    // debugger;
    const activeAlertObj = activeAlert;
    //added
    const actAlert: any = [];
    if (activeAlertObj.length > 0) {
      for (let i = 0; i < activeAlertObj.length; i++) {
        const alertActive = AlertLoad.findIndex(
          (alert: any) => alert.alertTypeId == activeAlertObj[i].alertTypeId
        );
        if (alertActive !== -1) {
          //added
          const al: any = activeAlertObj[i];
          // if (al.alertTypeId == '3') {
          //   al.alertCleared = 1;
          // } else {
          al.alertCleared = AlertLoad[alertActive].alertCleared;
          // }
          actAlert.push(al);
          //ends
          activeAlertObj[i].alertCleared = AlertLoad[alertActive].alertCleared;
        }
      }
    }
    const filterOtherCheckBox: any = actAlert.filter(
      (el: any) => el.alertTypeId !== 3 && el.alertTypeId !== "3"
    );
    setAlertUpdateObj([...filterOtherCheckBox]);
    setEnabletxt(true);
    setCustomAlerttxt("");
    setdisableAlert(false);
    closeAlert();
  };
  const racCoWorkerFunc = async (moduleName: string) => {
    //const d: any = document.getElementsByName('RAC_Co_worker');
    try {
      setSecondLevelAuthenOn(moduleName);
    } catch (e: any) {
    }
  };

  const popupSelect = (result: any) => {
    if (result.data.pin === 1 && result.data.secondFactor === 1) {
      setOpenPinModal(true);
      setpopupFlag(true);
    } else if (result.data.pin === 1) {
      setOpenPinModal(true);
      setpopupFlag(false);
    } else if (result.data.secondFactor === 1) {
      setOpenModal(true);
      setpopupFlag(false);
    }
  };

  const getlegalHold = async () => {
    let isLeaglHold = false;
    try {
      const getLegalHold = await GetLegalSatatus(customerId);
      setLegalHold(
        getLegalHold !== undefined &&
          getLegalHold !== null &&
          getLegalHold?.legalHoldStatusRes !== undefined &&
          getLegalHold?.legalHoldStatusRes !== null &&
          getLegalHold?.legalHoldStatusRes.length > 0
          ? getLegalHold.legalHoldStatusRes[0]
          : null
      );
      isLeaglHold =
        getLegalHold !== undefined &&
        getLegalHold !== null &&
        getLegalHold?.legalHoldStatusRes !== undefined &&
        getLegalHold?.legalHoldStatusRes !== null &&
        getLegalHold?.legalHoldStatusRes.length > 0
          ? getLegalHold.legalHoldStatusRes[0].legalHoldStatus == "true" ||
            getLegalHold.legalHoldStatusRes[0].legalHoldStatus === true
            ? true
            : false
          : false;
      setIsLegalHold(isLeaglHold);
    } catch (e: any) {
      setLegalHold(null);
    }
    return isLeaglHold;
  };

  
  const handleUserID = (event: any) => {
    // setErrorLine(false);
    setUserID(event.target.value);
  };

  const handlePassword = (event: any) => {
    // setErrorLine(false);
    setPassword(event.target.value);
  };

  const secondfactorEnter = (event: any, username: any, Password: any) => {
    /* eslint-enable no-console */
    if (event.charCode === 13 && userID !== "" && password !== "") {
      event.preventDefault();
      loginClick(username, Password);
    }
  };

  const loginClick = async (username: any, Password: any) => {
    setOpenModal(false);
    setloader(true);
    const payload = {
      userName: username,
      role: role,
      storeNumber: window.sessionStorage.getItem("storeNumber"),
      password: Password,
    };
    setErrorLine(false);
    const result = await getsecondfactor(payload);
    setloader(false);
    UserValidate(result);
  };

  const UserValidate = (result: any) => {
    if (result.status == 200) {
      if (
        result.data.LoginAuthenticationResult.value.StatusCode === "200" &&
        result.data.CoworkerAuthenticationResult.value.statusCode === 200
      ) {
        setOpenModal(false);
        // if (authenType == 'RACWORKER') {
        //   setcustomerInformation({ ...customerInformation, racCoworker: 'Y' });
        // }
        if (authenType == "ALERT") {
          const alertUpdateObj: any = AlertUpdateObj;
          alertUpdateObj.push(AlertAuthenticationNeeded);
          setAlertUpdateObj([...alertUpdateObj]);
          setAlertAuthenticationNeeded("");
        }
        // if (authenType == 'ROUTES') {
        //   setcustomerInformation({
        //     ...customerInformation,
        //     route: preferredRoute,
        //   });
        //   setPreferrefRoute('');
        //}
      } else {
        setOpenModal(false);
        setOpenSecondModal(true);
      }
    } else {
      setOpenModal(false);
      setOpenSecondModal(true);
    }
    setotp("");
    setUserID("");
    setPassword("");
    setauthenType("");
  };

  const openModelCancelClick = () => {
    setOpenModal(false);
    setErrorLine(false);
  };

  const okClick = () => {
    setOpenSecondModal(false);
    setOpenModal(true);
    setErrorLine(true);
    // setUserID('');
    // setPassword('');
  };

  const setChargeOffclose = () => {
    setOpenPinModal(false);
  };

  const pinEnter = (event: any) => {
    // eslint-disable-next-line sonarjs/no-redundant-boolean
    if (event.charCode === 13 && Continue == false) {
      event.preventDefault();
      continueClick();
    }
  };

  const otpchange = (event: any) => {
    setInvalidPin(200);
    setotp(event);
  };

  useEffect(() => {
    if (otp.length == 4) {
      setContinue(false);
    } else {
      setContinue(true);
    }
  }, [otp]);

  // payment origin popup
  useEffect(() => {
    setShowPayOrgPopup(true);
  }, []);

  // useEffect(()=>{
  //   if(configFlags.isPaymentTraingleFeatureFlag==0&&customerdata==''){
  //     getCustomerData()

  //   }

  // },[configFlags])
  // const getCustomerData =async ()=>{
  //   const customer_id: any = customerId
  //     const Storenumber: any = String(window.sessionStorage.getItem("storeNumber"))

  //     const result: any =  await GetCustomerByIdInfo(customer_id, Storenumber);
  //     setcustomerdata(result)

  // }

  // const pinValue = (event: any) => {
  //   otp = event;
  // };

  // const unenrollautopaysendtextservice = () => {
  //   let Schedule = '';
  //   let Amounttounenroll = 0;
  //   let agreementarray: any = [];
  //   for (let i = 0; i < updatedarray.length; i++) {
  //     if (updatedarray[i] === true) {
  //       Schedule = unenrollautopayfilterdata[i].Schedule;
  //       Amounttounenroll = Amounttounenroll + unenrollautopayfilterdata[i].Amount;
  //       agreementarray.push(unenrollautopayfilterdata[i].Agreementid)
  //     }
  //   }
  //   let payload1 = {
  //     language: 'E',
  //     phoneNumber: String(getpayment?.paymentInfo.customerInfo[0].phoneNumber),
  //     textMessage: 'TXT2ACU',
  //     messageType: 'TXT2ACU',
  //     messageTemplateKey: 'TXT2ACU-MESSAGE',
  //     messageSubstitutes: {
  //       schedule: Schedule,
  //       amount: Amounttounenroll
  //     },
  //     callBackParams: {
  //       lastName: getpayment?.paymentInfo.customerInfo[0].lastName,
  //       firstName: getpayment?.paymentInfo.customerInfo[0].firstName,
  //       partyId: getpayment?.paymentInfo.customerInfo[0].entPartyId !== null ? String(getpayment?.paymentInfo.customerInfo[0].entPartyId) : customerId,
  //       agreementIds: agreementarray,
  //       partyClubCoverageIds: [customerId]
  //     },
  //     storeNumber: String(window.sessionStorage.getItem('storeNumber')),
  //     transactionId: getpayment?.paymentInfo.customerInfo[0].entPartyId !== null ? String(getpayment?.paymentInfo.customerInfo[0].entPartyId) : customerId
  //   }
  //   sendMessage(payload1);
  //   setsendtext(true);
  // }
  const continueClick = async () => {
    setOpenPinModal(false);
    setloader(true);
    const userResponse = await getCurrentInfo();
    const id = userResponse.data.employeeId
      ? userResponse.data.employeeId
      : userResponse.data.email.toLowerCase();
    const pin = otp;
    const payload = {
      userId: id,
      encryptedPin: pin.toString(),
    };
    const result = await getPinDetails(payload);
    setloader(false);
    setOpenPinModal(true);
    setInvalidPin(result.status);
    if (result.data.statusCode == 200 && popupFlag) {
      setOpenPinModal(false);
      setOpenModal(true);
    }
    if (result.data.statusCode == 200 && !popupFlag) {
      setOpenPinModal(false);
      //setcustomerInformation({ ...customerInformation, racCoworker: 'Y' });
    }
  };

  const noClick = () => {
    setOpenPinModal(false);
  };

  const ConvenienceFee = (selectedOpt: any) => {
    if (selectedOpt === "Pay By Phone") {
      return (
        <Grid
          item
          className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
        >
          <Grid item>
            <Typography
              className={`${classes.floatLeft} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
            >
              Convenience Fee
            </Typography>
            <Typography
              className={` ${classes.floatRight} ${classes.totallabelstyle} ${classes.totalvaluestyle}`}
            >
              <Typography>${convAmtWTax}</Typography>
            </Typography>
          </Grid>
        </Grid>
      );
    } else {
      ("");
    }
  };

  const seconduthenResponses = (e: any) => {
    if (e === true && authenType == "ALERT") {
      let alertUpdateObj: any = AlertUpdateObj;
      if (AlertAuthenticationNeeded.removeAlert == undefined) {
        alertUpdateObj.push(AlertAuthenticationNeeded);
      } else {
        alertUpdateObj = alterAlert(
          "remove",
          null,
          AlertAuthenticationNeeded.alertTypeId
        );
      }
      setAlertUpdateObj([...alertUpdateObj]);
      setAlertAuthenticationNeeded("");
    }
    setSecondLevelAuthenOn(null);
    setauthenType("");
  };

  const alertPopUp = () => {
    return (
      <Grid container>
        <Grid item className={`${classes.w100} ${classes.floatLeft}`}>
          {Assignalert()}
          {Enabletxt !== true ? (
            <RACTextbox
              isCurrency={false}
              className={classes.mb4}
              id="CustomAlert"
              value={CustomAlerttxt}
              OnChange={(e) => CustomAlertval(e)}
            />
          ) : null}
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.textright} ${classes.modalfooter}`}
        >
          <RACButton
            size="small"
            data-bs-dismiss="modal"
            aria-label="Close"
            //style={{ textTransform: 'none', fontWeight: 'bolder', paddingLeft: '14px', paddingRight: '14px' }}
            className={`${classes.paymentSmallBtn1} ${classes.mx2} ${navlinkClass.paymentBtnHover}`}
            variant="outlined"
            color="primary"
            onClick={() => Cancelclick()}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            disabled={
              (activeAlert.length == 0 && AlertUpdateObj.length == 0) ||
              disableAlert
                ? true
                : false
            }
            className={`${classes.paymentSmallBtn1}`}
            onClick={() => {
              Assignsave_click();
              closeAlert();
            }}
          >
            Save
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const openModalFunc = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <RacLogo></RacLogo>
        </Grid>
        {errorline === true ? (
          <Grid
            className={`${classes.floatLeft} ${classes.my2} ${classes.mx1}`}
          >
            <Typography
              className={`${classes.racLoginAlert} ${classes.mandatoryfield}`}
            >
              The user ID and/or password you entered are not valid. Please try
              again.
            </Typography>
          </Grid>
        ) : null}
        <Grid container spacing={2}>
          <Grid item className={classes.w100}>
            <Typography
              variant="body2"
              component="label"
              className={classes.semibold}
            >
              User ID
            </Typography>
            <RACTextbox
              maxlength={30}
              className={classes.w100}
              isCurrency={false}
              type="text"
              value={userID}
              OnChange={(event) => handleUserID(event)}
            ></RACTextbox>
          </Grid>
          <Grid item className={classes.w100}>
            <Typography
              variant="body2"
              component="label"
              className={classes.semibold}
            >
              Password
            </Typography>
            <RACTextbox
              maxlength={15}
              onKeyPress={(event) => secondfactorEnter(event, userID, password)}
              isCurrency={false}
              className={classes.w100}
              type="password"
              value={password}
              OnChange={(event) => handlePassword(event)}
            ></RACTextbox>
          </Grid>
        </Grid>
        <Grid className={`${classes.floatRight} ${classes.mt5} ${classes.mb3}`}>
          <RACButton
            variant="outlined"
            color="primary"
            onClick={openModelCancelClick}
          >
            Cancel
          </RACButton>
          {userID !== "" && password !== "" ? (
            <RACButton
              className={classes.me2}
              color="primary"
              variant="contained"
              onClick={() => loginClick(userID, password)}
            >
              Login
            </RACButton>
          ) : (
            <RACButton
              className={classes.ms2}
              color="primary"
              variant="contained"
            >
              Login
            </RACButton>
          )}
        </Grid>
      </div>
    );
  };

  const openSecondModalFunc = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <img
            className={classes.racErrorIcon}
            src={NoRecordsFound}
            alt="Logo"
          />
          <Typography className={classes.racErrorFunction}>
            The user you entered can not authorize the use of this function.
          </Typography>
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            className={classes.me2}
            onClick={okClick}
            color="primary"
            variant="contained"
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const openPinModalFunc = () => {
    return (
      <div>
        <Grid container className={classes.racPinContainer}>
          <Grid item md={12}>
            <Typography className={classes.racPinLabel}>PIN</Typography>
          </Grid>
          <Grid onKeyPress={pinEnter}>
            {/* <PinField
                id="pininput"
                className="racPinField"
                length={4}
                validate="0123456789"
                type="password"
                autoFocus={true}
                onComplete={(event) => pinValue(event)}
                onChange={(event) => otpchange(event)}
              /> */}
            <PinField
              length={4}
              type="numeric"
              focus={true}
              secret={true}
              autoSelect={false}
              style={{ marginLeft: "0.3rem" }}
              inputStyle={{
                border: "1px solid #adb5bd",
                borderRadius: "0.6rem",
                marginRight: "0.9rem",
              }}
              //onComplete={(event) => pinValue(event)}
              onChange={(event) => otpchange(event)}
            />
          </Grid>
          {InvalidPin == 400 ? (
            <Grid>
              <Typography id="InvalidPin" className={classes.racPinError}>
                Invalid PIN{" "}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid className={`${classes.floatRight} ${classes.mt5} ${classes.mb3}`}>
          <RACButton
            className={classes.me2}
            variant="outlined"
            color="primary"
            onClick={noClick}
          >
            Cancel
          </RACButton>
          <RACButton
            className={classes.me2}
            disabled={Continue}
            color="primary"
            variant="contained"
            onClick={continueClick}
          >
            Continue
          </RACButton>
        </Grid>
      </div>
    );
  };
  const onCloseSecondfacClick = () => {
    setOpenModal(false);
  };

  const activityLoadingRenderer = () => {
    return (
      <>
        <Grid className={`${classes.textCenter}`}>
          <CircularProgress />
          <Typography
            className={`${classes.textCenter} ${classes.bold} ${classes.mt2} `}
          >
            Saving Information
          </Typography>
          <Typography className={`${classes.gridLabel} ${classes.mt2}`}>
            Please wait...
          </Typography>
        </Grid>
      </>
    );
  };

  const AutopayChkboxAction = (e: any) => {
    if (e.target.checked === true) {
      setAutopaybutton(false);
    } else {
      setAutopaybutton(true);
    }
  };
  const unenrollautopaysendtextservice = () => {
    let Schedule = "";
    let Amounttounenroll = 0;
    let clubselectedvar = false;
    let agreementarray: any = [];
    for (let i = 0; i < updatedarray.length; i++) {
      if (updatedarray[i] === true) {
        Schedule = unenrollautopayfilterdata[i].Schedule;
        Amounttounenroll =
          Amounttounenroll + unenrollautopayfilterdata[i].Amount;
        if (unenrollautopayfilterdata[i].Agreementtype === "Club") {
          clubselectedvar = true;
        } else {
          agreementarray.push(unenrollautopayfilterdata[i].Agreementid);
        }
      }
    }
    let number = String(getpayment?.paymentInfo.customerInfo[0].phoneNumber);
    number = number.replace(/[^\d]/g, "");
    let Payload2: AutopaySendMessageRequest = {
      sendTextFlag: 1,
      callTxtReq: {
        language: "E",
        phoneNumber: String(number),
        textMessage: "TXT2ACU",
        messageType: "TXT2ACU",
        messageTemplateKey: "TXT2ACU-MESSAGE",
        messageSubstitutes: {
          schedule: Schedule,
          amount: String(Number(Amounttounenroll).toFixed(2)),
        },
        callBackParams: {
          lastName: String(getpayment?.paymentInfo.customerInfo[0].lastName),
          firstName: String(getpayment?.paymentInfo.customerInfo[0].firstName),
          customerId: customerId,
          customerPaymentMethodId: "",
          agreementIds: agreementarray,
          clubId:
            clubselectedvar === true
              ? String(
                  getpayment?.paymentInfo.clubInformation[0].customerClubId
                )
              : "",
        },
        storeNumber: String(window.sessionStorage.getItem("storeNumber")),
        transactionId: customerId,
      },
    };
    // let payload1 = {
    //   language: 'E',
    //   phoneNumber: String(getpayment?.paymentInfo.customerInfo[0].phoneNumber),
    //   textMessage: 'TXT2ACU',
    //   messageType: 'TXT2ACU',
    //   messageTemplateKey: 'TXT2ACU-MESSAGE',
    //   messageSubstitutes: {
    //     schedule: Schedule,
    //     amount: Amounttounenroll
    //   },
    //   callBackParams: {
    //     lastName: getpayment?.paymentInfo.customerInfo[0].lastName,
    //     firstName: getpayment?.paymentInfo.customerInfo[0].firstName,
    //     partyId: getpayment?.paymentInfo.customerInfo[0].entPartyId !== null ? String(getpayment?.paymentInfo.customerInfo[0].entPartyId) : customerId,
    //     agreementIds: agreementarray,
    //     partyClubCoverageIds: clubselectedvar === true ? [customerId] : [],
    //   },
    //   storeNumber: String(window.sessionStorage.getItem('storeNumber')),
    //   transactionId: getpayment?.paymentInfo.customerInfo[0].entPartyId !== null ? String(getpayment?.paymentInfo.customerInfo[0].entPartyId) : customerId
    // }
    sendMessage(Payload2);
    setsendtext(true);
    setunenrollautopayopen(false);
  };

  const unEnrollAutoPay = () => {
    return (
      <>
        <Grid item className={classes.w100}>
          <Grid item className={classes.justifycontentcenter}>
            <Grid item className={classes.raccollg7}>
              <Typography
                variant="h4"
                className={`${classes.bold} ${classes.mb3} ${classes.floatLeft} ${classes.textcenter} ${classes.mb3}`}
              >
                Discuss the benefits of remaining on Autopay!
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            className={`${classes.mb3} ${classes.justifycontentcenter}`}
          >
            <Grid item className={classes.raccollg8}>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  className={`${classes.autopayValue} ${classes.me2}`}
                >
                  1
                </Typography>
              </Grid>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitle} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Convenience
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitleAccent} ${classes.w100} ${classes.floatLeft}`}
                >
                  Save Customer time
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            className={`${classes.mb3} ${classes.justifycontentcenter}`}
          >
            <Grid className={classes.raccollg8}>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  className={`${classes.autopayValue} ${classes.me2}`}
                >
                  2
                </Typography>
              </Grid>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitle} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Zero Fees
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitleAccent} ${classes.w100} ${classes.floatLeft}`}
                >
                  Avoid convenience/phone fees{" "}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Typography className={`${classes.font14} ${classes.mb3} `}>
            Please select agreement(s)/Club to un-enroll from Autopay.Autopay
            un-enrollment will be effective immediately.
          </Typography>
          <Grid item className={`${classes.raccollg12} ${classes.floatLeft}`}>
            <Grid
              item
              className={`${classes.w100} ${classes.mb3} ${classes.floatLeft}`}
            >
              <RACTable
                renderTableHead={renderBindGridHeader}
                renderTableContent={() => Gridbindingunenrollautopay()}
              />
            </Grid>

            {/* <Grid item className='autopay-card-height'>
                      <Grid item className="w-100">
                        <Grid item className="w-100 font-14 mb-3">
                        To enroll with a different schedule, use the 'Autopay Signup Text' button on the Accept Payment screen - 'Signup with Card on File' option.
                        </Grid>
                        <Grid item className="justify-content-center d-flex" style={{ width: '100%' }}>
                          <Grid item className='centerLayout rac-col-md-5 mb-3' >
                            <Box component="label" className="mb-2 floatLeft" >Please select one Card on File</Box>
                            {ListOfCardsBind(ListOfCards)}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid> */}
            <Typography
              className={`${classes.font14} ${classes.RACalertmsg} ${classes.mb3}`}
            >
              Note:Only the last text message sent will be used for Autopay
              un-enrollment
            </Typography>
          </Grid>
          <Grid
            item
            className={`${classes.mb3} ${classes.floatLeft} ${classes.w100}`}
          >
            <Grid
              item
              className={`${classes.formcheck} ${classes.mb3} ${classes.floatLeft}`}
            >
              <RACCheckBox
                className={`${classes.formcheckinput} ${classes.floatLeft} ${classes.checkboxStyle}`}
                onClick={(e) => {
                  verbalcheckbox(e);
                }}
                checked={verbalecheckbox}
                data-testid="AutopayCheckTest"
              />
              <Box component="label" className={classes.ms2}>
                Customer has verbally consented to receive this text message
              </Box>
            </Grid>
          </Grid>
          <Typography
            className={`${classes.font14} ${classes.mb3} ${classes.textcenter}`}
          >
            Autopay will be associated with the Primary Renter
          </Typography>
          <Grid
            item
            className={`${classes.justifycontentcenter} ${classes.mb3}`}
          >
            <Grid item className={classes.raccollg7}>
              <Grid
                item
                className={`${classes.w100} ${classes.w100} ${classes.mb2}`}
              >
                <Box
                  component="span"
                  className={`${classes.me3} ${classes.font14}`}
                >
                  {" "}
                  Customer Name :
                </Box>
                <Box
                  component="span"
                  className={`${classes.semibold} ${classes.font14}`}
                >
                  {" "}
                  {getpayment !== undefined
                    ? getpayment.paymentInfo.customerInfo[0].firstName +
                      " " +
                      getpayment.paymentInfo.customerInfo[0].lastName
                    : null}
                </Box>
              </Grid>
              <Grid item className={`${classes.w100} ${classes.floatLeft}`}>
                <Box
                  component="span"
                  className={`${classes.w100} ${classes.me3} ${classes.font14}`}
                >
                  Phone Number :
                </Box>
                <Box
                  component="span"
                  className={`${classes.semibold} ${classes.font14}`}
                >
                  {" "}
                  {getpayment?.paymentInfo.customerInfo[0].phoneNumber}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          className={`${classes.raccollg12} ${classes.py4} ${classes.textcenter}`}
        >
          {/* <RACButton
            // type="button"
            className={`${classes.mx0} ${classes.clearbtn} ${classes.px1}`}
            data-bs-dismiss="modal"
            onClick={() => AutoPayClearClick()}
            data-testid="AutoPayNotnowTest"
          >
            Clear
          </RACButton> */}

          <RACButton
            // type="button"
            className={classes.mx1}
            color="primary"
            variant="outlined"
            data-bs-dismiss="modal"
            onClick={unenrollAutoPayCloseClick}
            data-testid="AutoPayNotnowTest"
          >
            Not now
          </RACButton>

          <RACButton
            // type="button"
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#Customer-Signature"
            onClick={() => unenrollautopaysendtextservice()}
            disabled={sendtext}
            data-testid="SendTxtAsPaymentTest"
          >
            Send Text
          </RACButton>
        </Grid>
      </>
    );
  };

  const autoPayPopUp = () => {
    return (
      <>
        <Grid item className={classes.w100}>
          <Grid item className={classes.justifycontentcenter}>
            <Grid item className={classes.raccollg9}>
              <Typography
                variant="h4"
                className={`${classes.bold} ${classes.mb3} ${classes.floatLeft} ${classes.textcenter} ${classes.mb3}`}
              >
                Discuss the benefits of Autopay and enroll your customer today
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            style={{ marginLeft: "12px" }}
            className={`${classes.mb3} ${classes.justifycontentcenter}`}
          >
            <Grid item className={classes.raccollg10}>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  className={`${classes.autopayValue} ${classes.me2}`}
                >
                  1
                </Typography>
              </Grid>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitle} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Convenience
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitleAccent} ${classes.w100} ${classes.floatLeft}`}
                >
                  Save Customer Time
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            style={{ marginLeft: "12px" }}
            className={`${classes.mb3} ${classes.justifycontentcenter}`}
          >
            <Grid className={classes.raccollg10}>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  className={`${classes.autopayValue} ${classes.me2}`}
                >
                  2
                </Typography>
              </Grid>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitle} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Zero Fees
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitleAccent} ${classes.w100} ${classes.floatLeft}`}
                >
                  Avoid convenience/phone fees{" "}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            className={`${classes.mb3} ${classes.floatLeft} ${classes.w100}`}
          >
            <Grid
              item
              className={`${classes.formcheck} ${classes.mb3} ${classes.floatLeft}`}
            >
              <RACCheckBox
                // className={`${classes.formcheckinput} ${classes.floatLeft} ${classes.checkboxStyle}`}
                id="flexCheckDefault"
                onClick={(e) => {
                  verbalcheckbox(e);
                }}
                checked={verbalecheckbox}
                data-testid="AutopayCheckTest"
              />
              <Box component="label" className={classes.ms2}>
                Customer has verbally consented to receive this text message
              </Box>
            </Grid>
          </Grid>
          <Typography
            className={`${classes.font14} ${classes.mb3} ${classes.textcenter}`}
          >
            Autopay will be associated with the Primary Renter
          </Typography>
          <Grid
            item
            className={`${classes.justifycontentcenter} ${classes.mb3}`}
          >
            <Grid item className={classes.raccollg8}>
              <Grid
                item
                className={`${classes.w100} ${classes.w100} ${classes.mb2}`}
              >
                <Box
                  component="span"
                  className={`${classes.me3} ${classes.font14}`}
                >
                  {" "}
                  Customer Name
                </Box>
                <Box
                  component="span"
                  className={`${classes.semibold} ${classes.font14}`}
                >
                  {customerName}
                </Box>
              </Grid>
              <Grid item className={`${classes.w100} ${classes.floatLeft}`}>
                <Box
                  component="span"
                  className={`${classes.w100} ${classes.me3} ${classes.font14}`}
                >
                  Phone Number
                </Box>
                <Box
                  component="span"
                  className={`${classes.semibold} ${classes.font14}`}
                >
                  {" "}
                  {formatPhone(
                    getpayment?.paymentInfo.customerInfo == undefined
                      ? ""
                      : getpayment?.paymentInfo.customerInfo[0].phoneNumber
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            className={`${classes.raccollg12} ${classes.py4} ${classes.textcenter}`}
          >
            <RACButton
              className={classes.mx1}
              color="primary"
              variant="outlined"
              data-bs-dismiss="modal"
              onClick={() => AutoPayNotnowClick()}
              data-testid="AutoPayNotnowTest"
            >
              Not now
            </RACButton>

            <RACButton
              className={classes.mx1}
              color="primary"
              variant="contained"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              data-bs-target="#Customer-Signature"
              onClick={() => AutoPaySubmitClick()}
              disabled={sendtext}
              data-testid="SendTxtAsPaymentTest"
            >
              Send Autopay Signup Text
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };

  //syed change 4

  const addsuspensefields = [
    { display: "Agreement #" },
    { display: "Schedule" },
    { display: "Payment" },
    { display: "EPO /SAC(Without Suspense)" },
    { display: "Current Suspense" },
    { display: "New Suspense" },
  ];

  const suspenseBindGridHead = () => (
    <>
      {addsuspensefields.map((val, index) => {
        return val.display === null ? (
          <RACTableCell key={index} className={`${classes.font12}`}>
            <span style={{ float: "right" }}>{val.display}</span>
          </RACTableCell>
        ) : (
          <RACTableCell key={index} className={`${classes.font12}`}>
            {val.display}
          </RACTableCell>
        );
      })}
    </>
  );

  const gridheadfields = [
    { display: null },
    { display: "Agreement #" },
    { display: "Type" },
    { display: "Description" },
    { display: "Schedule" },
    { display: "Current Due Date" },
    { display: "Next Due Date" },
    { display: "Current Days Late" },
    { display: "Amount Due" },
    { display: "Action" },
    // { display: "" },
  ];

  const renderBindGridHead = () => (
    <>
      {gridheadfields.map((val, index) => {
        return val.display === null ? (
          <RACTableCell
            className={` ${classes.checkboxwidth} ${classes.font12}`}
          >
            <input
              //className="form-check-input"
              style={{ marginTop: "7px", marginLeft: "19px" }}
              type="checkbox"
              id="maincheckbox"
              data-testid="maincheckboxId"
              disabled={
                agreementId !== "0" || paygridvalues.length == 0 ? true : false
              }
              checked={OverAll_Chk}
              onClick={(e) => {
                if (isRevenueRecogActivated) {
                  selectallcheckboxRevenue(undefined, e);
                } else {
                  selectallcheckbox(undefined, e);
                }
              }}
            />
          </RACTableCell>
        ) : val.display === "Current Days Late" ||
          val.display === "Amount Due" ? (
          <RACTableCell key={index} className={`${classes.font12}`}>
            <span style={{ float: "right" }}>{val.display}</span>
          </RACTableCell>
        ) : val.display === "Action" ? (
            <RACTableCell
              key={index}
              className={`${classes.font12} ${classes.Pl25}`}
            >
              {val.display}
            </RACTableCell>
        ) : (
          <RACTableCell key={index} className={`${classes.font12}`}>
            {val.display}
          </RACTableCell>
        );
      })}
    </>
  );
  //activateClubModal
  const activateClubPopup = () => {
    return (
      <Grid
        item
        id="terminateldw"
        data-testid="ActivateClubPopupId"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="terminateldw"
        aria-hidden="true"
      >
        <Typography className={classes.ps2}>
          By activating Club, your regular payment with LDW will increase by
          <Typography
            variant="caption"
            className={`${classes.RAClabeltxtstyle} ${classes.font16} ${classes.bold} ${classes.ms1}`}
          >
            ${clubAmount}
          </Typography>
          . Do you want to activate Club?
        </Typography>

        <Grid
          item
          md={12}
          className={`${classes.textright} ${classes.modalfooter} ${classes.mt3}`}
        >
          <RACButton
            // type="button"
            className={classes.mx1}
            variant="outlined"
            color="primary"
            data-testid="ActivateLDWCancelId"
            onClick={() =>
              setclubStatusPopup({ ...clubStatusPopup, activateClub: false })
            }
            data-bs-dismiss="modal"
          >
            No
          </RACButton>
          <RACButton
            // type="button"
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-testid="continueActivateLDWId"
            onClick={() =>
              activateDeactivateClubApiCall(1, "P", "activateClub")
            }
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  //deactivateClubModal
  const deactivateClubPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textcenter}>
          <Typography className={classes.RACPOPMsg}>
            {"By deactivating club, your regular payment will decrease by $" +
              deactivateClubAmount +
              ".   Press continue to confirm"}
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.spacerMT4} ${classes.justifycontentcenter}`}
        >
          <RACButton
            color="primary"
            variant="outlined"
            className={`${classes.me2} ${classes.foc}`}
            onClick={() =>
              setclubStatusPopup({ ...clubStatusPopup, deactivateClub: false })
            }
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => deActivatePopupYesHandleChange()}
          >
            Continue
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const deactivateClubCustomerRedirectionPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textcenter}>
          <Typography className={classes.RACPOPMsg}>
            {
              "Customer has no active agreement Please deactivate Club in Customer Editor Screen Using Club button"
            }
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.spacerMT4} ${classes.justifycontentcenter}`}
        >
          <RACButton
            color="primary"
            variant="outlined"
            className={`${classes.me2} ${classes.foc}`}
            onClick={() => setdeactivateClubCustomerRedirection(false)}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => deactivateClubCustomerRedirectionChange()}
          >
            Continue
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const unEnrollautoPopUpHeadFields = [
    { display: "Document Type" },
    { display: "Document #" },
    { display: "Document From" },
    { display: "Amount" },
    { display: "Action" },
  ];

  const renderUnEnrollBindGridHead = () => (
    <>
      {unEnrollautoPopUpHeadFields.map((val, index) => {
        return val.display === "Document Type" ||
          val.display === "Document #" ||
          val.display === "Document From" ? (
          <RACTableCell className={classes.textleft} key={index}>
            {val.display}
          </RACTableCell>
        ) : val.display === "Amount" ? (
          <RACTableCell className={classes.textright} key={index}>
            {val.display}
          </RACTableCell>
        ) : val.display === "Action" ? (
          <RACTableCell className={classes.textcenter} key={index}>
            {val.display}
          </RACTableCell>
        ) : null;
      })}
    </>
  );

  const addcheckpopup = () => {
    return (
      <Grid
        item
        data-testid="addcheckbtnmode"
        id="check-info"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="carrywaive"
        aria-hidden="true"
      >
        <Grid container spacing={2} className={classes.px3}>
          <Grid item md={6} className={classes.mb2}>
            <RACSelect
              data-testid="dropmainId"
              options={CheckDropdownArr}
              defaultValue={adddata.checktype}
              inputLabel="Type of Check"
              required={true}
              onChange={(e) => setchecktype(e)}
            ></RACSelect>
            {Type === true ? (
              <label className={classes.mandatoryfield}>
                Please select the Check Type.
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <RACTextbox
              type="text"
              maxlength={20}
              id="Check#"
              name="Check"
              required={true}
              inputlabel=" Check #"
              value={adddata.checkno}
              OnChange={(e) => setcheckno(e)}
              data-testid="checkno"
              isCurrency={false}
            />
            {checknovalid === true ? (
              <label className={classes.mandatoryfield}>
                Please enter Check Number.
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <RACTextbox
              type="text"
              maxlength={30}
              required={true}
              inputlabel="Written To"
              id="WrittenTo"
              value={adddata.Written_To}
              OnChange={(e) => setcheckwrittento(e)}
              data-testid="writtento"
              isCurrency={false}
            />
            {Writtento === true ? (
              <label className={classes.mandatoryfield}>
                Please enter Written To.
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <RACTextbox
              isCurrency={false}
              maxlength={30}
              id="Writtenby"
              inputlabel="Written By"
              required={true}
              value={adddata.Written_by}
              OnChange={(e) => setcheckwrittentby(e)}
              data-testid="writtenby"
            />
            {writtenby === true ? (
              <label className={classes.mandatoryfield}>
                Please enter Written By.
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <label className={classes.labeltxtstyle}>Amount</label>
            <span className={classes.mandatoryfield}></span>
            <div className={classes.inputgroup}>
              <span className={classes.inputgrouptext}>$</span>
              <CurrencyInput
                type="text"
                name="checkAmount"
                className={classes.formcontrol3}
                value={adddata.Amount}
                onValueChange={(e: any) => {
                  setcheckamount(e);
                }}
                onBlur={(e: any) => {
                  setcheckamountblur(e);
                }}
                decimalsLimit={2}
                data-testid="amountbox"
              />
            </div>
            {Amount === true ? (
              <label className={classes.mandatoryfield}>
                Amount must be greater than 0.
              </label>
            ) : null}
          </Grid>
        </Grid>

        <Grid className={`${classes.textright} ${classes.modalfooter}`}>
          <RACButton
            // type="button"
            className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
            variant="outlined"
            color="primary"
            style={{
              backgroundColor: "white",
              textTransform: "none",
              fontWeight: "bolder",
              paddingLeft: "14px",
              paddingRight: "14px",
              color: "#2179fe",
            }}
            onClick={() => {
              checkormoneyordercancel();
            }}
            data-bs-toggle="modal"
          >
            Cancel
          </RACButton>

          {button === true ? (
            <RACButton
              className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
              color="primary"
              variant="contained"
              onClick={(e) => upload(e)}
              data-testId="checkupdate"
            >
              Update
            </RACButton>
          ) : (
            <RACButton
              className={`${classes.paymentSmallBtn1}`}
              color="primary"
              variant="contained"
              onClick={(e) => save(e)}
              data-testid="addchecksave"
            >
              Save
            </RACButton>
          )}
        </Grid>
      </Grid>
    );
  };

  const addsuspensepopup = () => {
    return (
      <Grid
        item
        data-testid="addcheckbtnmode"
        id="check-info"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="carrywaive"
        aria-hidden="true"
      >
        <Grid container spacing={2} className={classes.px3}>
          <RACTable
            className={classes.racGrid}
            renderTableHead={suspenseBindGridHead}
            //renderTableContent={BindGrid}
            // renderTableContent={BindGridCheckFunc}
            renderTableContent={suspensebindgrid}
          />
        </Grid>

        <Grid
          className={`${classes.floatRight} ${classes.modalfooter}`}
          style={{ marginTop: "20px" }}
        >
          <RACButton
            // type="button"
            className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
            variant="outlined"
            color="primary"
            style={{
              backgroundColor: "white",
              textTransform: "none",
              fontWeight: "bolder",
              paddingLeft: "14px",
              paddingRight: "14px",
              color: "#2179fe",
            }}
            onClick={() => addsuspensecancelbtn()}
            data-bs-toggle="modal"
          >
            Cancel
          </RACButton>

          <RACButton
            className={`${classes.paymentSmallBtn1}`}
            loading={suspenseAddLoader}
            color="primary"
            variant="contained"
            onClick={(e) => addSuspenseBtn(e)}
            disabled={susbtndisabled}
            data-testid="addchecksave"
          >
            Add
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const usesuspensepopup = () => {
    return (
      <Grid
        item
        data-testid="addcheckbtnmode"
        id="check-info"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="carrywaive"
        aria-hidden="true"
      >
        <Grid container spacing={2} className={classes.px3}>
          <RACTable
            className={classes.racGrid}
            renderTableHead={suspenseBindGridHead}
            renderTableContent={suspenseusebindgrid}
          />
        </Grid>

        {/* <Grid item xs={12} sm={12} md={6} lg={5}>
          <Grid className={` ${Payclasses.payHistbgLightBlue} ${Payclasses.payHistp3} ${Payclasses.payHistmt2} ${Payclasses.payHistborderRadius5}`}>
            <Grid container className={Payclasses.payHistjustifySpaceBetween}>
              <Grid item xs={12} sm={12} md={6} lg={7} className={Payclasses.payHistmb2}>
                <Grid container>
                  <Grid item xs="auto">
                    <Typography className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}> Current Suspense Total: </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}>{totolsuspenseamount}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={5} className={Payclasses.payHistmb2}>
                <Grid container>
                  <Grid item xs="auto">
                    <Typography className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}> New suspense total : </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}>{totalnewsuspenseamount}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={5} className={Payclasses.payHistmb2}>
                <Grid container>
                  <Grid item xs="auto">
                    <Typography className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}> Difference : </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}>${differenceamount}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}

        <Grid
          className={`${classes.floatRight} ${classes.modalfooter}`}
          style={{ marginTop: "20px" }}
        >
          <RACButton
            // type="button"
            className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
            variant="outlined"
            color="primary"
            style={{
              backgroundColor: "white",
              textTransform: "none",
              fontWeight: "bolder",
              paddingLeft: "14px",
              paddingRight: "14px",
              color: "#2179fe",
            }}
            onClick={() => useuspensecancelbtn()}
            data-bs-toggle="modal"
          >
            Cancel
          </RACButton>
          <RACButton
            className={`${classes.paymentSmallBtn1}`}
            color="primary"
            variant="contained"
            // eslint-disable-next-line react-hooks/rules-of-hooks
            onClick={() => useSuspenseBtn()}
            data-testid="addchecksave"
            disabled={susbtndisabled}
          >
            Use
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const transsuspensepopup = () => {
    return (
      <Grid
        item
        data-testid="addcheckbtnmode"
        id="check-info"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="carrywaive"
        aria-hidden="true"
      >
        <Grid container spacing={2} className={classes.px3}>
          <RACTable
            className={classes.racGrid}
            renderTableHead={suspenseBindGridHead}
            renderTableContent={suspensetransbindgrid}
          />
        </Grid>
        <Grid>
          <RACCheckBox
            disabled={
              mastertranssuspensearray.length === 1 &&
              Number(mastertranssuspensearray[0].suspenseamount) > 0
                ? false
                : true
            }
            //value={mastertranssuspensearray[0]?.suspenseamount}
            onChange={(e) => {
              if (e.target.checked) {
                checkBoxController(
                  mastertranssuspensearray[0]?.suspenseamount,
                  "TransferUsed"
                );
                setNewCOATotal(mastertranssuspensearray[0]?.suspenseamount);
                setsusbtndisabled(false);
              } else {
                checkBoxController(0.0, "TransferUsed");
                setNewCOATotal("0.00");
                setsusbtndisabled(true);
              }
            }}
            label={"Transfer Return Agreement Suspense to COA"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={7}>
          <Grid
            className={` ${Payclasses.payHistbgLightBlue} ${Payclasses.payHistp3} ${Payclasses.payHistmt2} ${Payclasses.payHistborderRadius5}`}
          >
            <Grid container className={Payclasses.payHistjustifySpaceBetween}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={7}
                className={Payclasses.payHistmb2}
              >
                <Grid container>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                    >
                      {" "}
                      Current Suspense Total:{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                    >
                      {"  " + "$" + totolsuspenseamount}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={6}
                lg={5}
                className={Payclasses.payHistmb2}
              >
                <Grid container>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                    >
                      {" "}
                      New Suspense Total :{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                    >
                      {"$" + Number(totalnewsuspenseamount).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {mastertranssuspensearray.length === 1 &&
              Number(mastertranssuspensearray[0].suspenseamount) > 0 ? (
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={5}
                  className={Payclasses.payHistmb2}
                >
                  <Grid container>
                    <Grid item xs="auto">
                      <Typography
                        className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                      >
                        {" "}
                        New COA Total :{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <Typography
                        className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                      >
                        {"$" + newCOATotal}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              <Grid
                xs={12}
                sm={12}
                md={6}
                lg={5}
                className={Payclasses.payHistmb2}
              >
                <Grid container>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                    >
                      {" "}
                      Difference :{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                    >
                      ${Number(differenceamount).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid className={`${classes.floatRight} ${classes.modalfooter}`}>
          <RACButton
            // type="button"
            className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
            variant="outlined"
            color="primary"
            style={{
              backgroundColor: "white",
              textTransform: "none",
              fontWeight: "bolder",
              paddingLeft: "14px",
              paddingRight: "14px",
              color: "#2179fe",
            }}
            onClick={() => settransuspense(false)}
            data-bs-toggle="modal"
          >
            Cancel
          </RACButton>
          <RACButton
            className={`${classes.paymentSmallBtn1}`}
            color="primary"
            variant="contained"
            disabled={susbtndisabled || transferDifferenceZero}
            // eslint-disable-next-line react-hooks/rules-of-hooks
            onClick={() => transSuspenseBtn()}
            data-testid="addchecksave"
          >
            Transfer
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const checkBoxController = (valueFromCheck, type) => {
    settranssus("0.00");
    mastertranssuspensearray[0].newsuspense = "0.00";
    mastertranssuspensearray[0].sustype = type;
    setmastertranssuspensearray(mastertranssuspensearray);
  }; // SQ-2 This method will be assigning the suspense type and new suspense amount.

  const refundpopup = () => {
    if (masterrefundsuspensearray.length > 0) {
      return (
        <Grid
          item
          data-testid="addcheckbtnmode"
          id="check-info"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="carrywaive"
          aria-hidden="true"
        >
          <Grid container spacing={2} className={classes.px3}>
            <RACTable
              className={classes.racGrid}
              renderTableHead={suspenseBindGridHead}
              renderTableContent={suspenserefundbindgrid}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={5}>
            <Grid
              className={` ${Payclasses.payHistbgLightBlue} ${Payclasses.payHistp3} ${Payclasses.payHistmt2} ${Payclasses.payHistborderRadius5}`}
            >
              <Grid container className={Payclasses.payHistjustifySpaceBetween}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={7}
                  className={Payclasses.payHistmb2}
                >
                  <Grid container>
                    <Grid item xs="auto">
                      <Typography
                        className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                      >
                        {" "}
                        Current Suspense Total:{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <Typography
                        className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                      >
                        {totolsuspenseamount}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={5}
                  className={Payclasses.payHistmb2}
                >
                  <Grid container>
                    <Grid item xs="auto">
                      <Typography
                        className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                      >
                        {" "}
                        New suspense total :{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <Typography
                        className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                      >
                        {totalnewsuspenseamount}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={5}
                  className={Payclasses.payHistmb2}
                >
                  <Grid container>
                    <Grid item xs="auto">
                      <Typography
                        className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                      >
                        {" "}
                        Difference :{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <Typography
                        className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                      >
                        ${differenceamount}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid className={`${classes.floatRight} ${classes.modalfooter}`}>
            <RACButton
              // type="button"
              className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
              variant="outlined"
              color="primary"
              style={{
                backgroundColor: "white",
                textTransform: "none",
                fontWeight: "bolder",
                paddingLeft: "14px",
                paddingRight: "14px",
                color: "#2179fe",
              }}
              onClick={() => {}}
              data-bs-toggle="modal"
            >
              Cancel
            </RACButton>

            <RACButton
              className={`${classes.paymentSmallBtn1}`}
              color="primary"
              variant="contained"
              onClick={() => refundSuspenseBtn()}
              data-testid="addchecksave"
            >
              Refund
            </RACButton>
          </Grid>
        </Grid>
      );
    }
  };

  const personalcheckalertpopup = () => {
    return (
      <Grid item data-testid="personal">
        <Grid
          item
          id="transaction"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="transaction"
          aria-hidden="true"
        >
          <Typography className={`${classes.textcenter} ${classes.mt3}`}>
            Personal check is not applicable for over payment
          </Typography>

          <Grid
            item
            md={12}
            className={`${classes.textcenter} ${classes.mt3} ${classes.modalfooter}`}
          >
            <RACButton
              className={classes.mx1}
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              color="primary"
              variant="contained"
              onClick={() => {
                setpersonalcheckalert(false);
              }}
              data-bs-target="#delivery-receipt"
              data-testid="PersonalOkId"
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const Moneyorderalertpopup = () => {
    return (
      <Grid item data-testid="travellercheck">
        <Grid
          item
          id="transaction"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="transaction"
          aria-hidden="true"
        >
          <Typography className={`${classes.textcenter} ${classes.mt3}`}>
            Money Order is not applicable for over payment
          </Typography>
          <Grid
            item
            md={12}
            className={`${classes.textcenter} ${classes.mt3} ${classes.modalfooter}`}
          >
            <RACButton
              className={classes.mx1}
              color="primary"
              variant="contained"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              data-testid="okbuttontravel"
              onClick={() => {
                setoverpayMoneyord(false);
              }}
              data-bs-target="#delivery-receipt"
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const travellercheckalertPopup = () => {
    return (
      <Grid item data-testid="travellercheck">
        <Grid
          item
          id="transaction"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="transaction"
          aria-hidden="true"
        >
          <Typography className={`${classes.textcenter} ${classes.mt3}`}>
            Traveller check is not applicable for over payment
          </Typography>

          <Grid
            item
            md={12}
            className={`${classes.textcenter} ${classes.mt3} ${classes.modalfooter}`}
          >
            <RACButton
              className={classes.mx1}
              color="primary"
              variant="contained"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              data-testid="okbuttontravel"
              onClick={() => {
                settravellercheckalert(false);
              }}
              data-bs-target="#delivery-receipt"
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  //DeviceDropdown onchange function defines here
  const deviceDropdown = (e: any) => {
    // debugger;
    selectedValue = e.target.value;
    if (configFlags?.isCloudStoreSwipeDevicelist == 1) {
      selectedLabel = DeveiceResp.data.devices.filter((el: any, index: any) => {
        const storeNumber = String(Number(el.storeNumber));
        const terminalId = String(el.termNumber)?.slice(-3);
        return terminalId === selectedValue;
      });
    } else {
      selectedLabel =
        DeveiceResp.data.StorePaymentDeviceResponse.devices.filter(
          (el: any, index: any) => {
            const storeNumber = String(Number(el.storeNumber));
            const terminalId = String(el.termNumber)?.slice(-3);
            return terminalId === selectedValue;
          }
        );
    }
    let label =
      selectedLabel !== undefined && selectedLabel.length > 0
        ? selectedLabel[0].name
        : undefined;

        let registerNumber =
      selectedLabel !== undefined && selectedLabel.length > 0
        ? selectedLabel[0].registerNumber
        : undefined;
    setRegisterNumber(registerNumber);
    setSessionstorage(selectedLabel);
    setTerminalId(selectedValue);
    window.sessionStorage.setItem("TerminalId", String(selectedValue));
    window.sessionStorage.setItem("registerNumber", String(registerNumber));
    window.sessionStorage.setItem("deviceName", String(label));
  };

  const dynamicState = (e: any) => {
    selectedValue = e.value;
    setdefaultVal(selectedValue);
    if (selectedValue !== "0") {
      setState_validation(false);
    }
    window.sessionStorage.setItem("billState", String(selectedValue));
  };

  const infoonclick = () => {
    swipepopupshow === false
      ? setswipepopupshow(true)
      : setswipepopupshow(false);
  };

  const deleteclick = () => {
    for (let i = 0; i < masteraddsuspensearray.length; i++) {
      const index = paygridvalues?.findIndex(
        (x: any) =>
          String(x.Agreementid) ==
          String(masteraddsuspensearray[i].agreementnumber)
      );
      paygridvalues[index].suspenseAdd = 0;
      paygridvalues[index].isCOATransfered = false;
      masteraddsuspensearray[i].newsuspense = "0.00";
    }

    for (let i = 0; i < masterusesuspensearray.length; i++) {
      const index = paygridvalues?.findIndex(
        (x: any) =>
          String(x.Agreementid) ==
          String(masterusesuspensearray[i].agreementnumber)
      );
      paygridvalues[index].suspenseSub = 0;
      paygridvalues[index].isCOATransfered = false;
      masterusesuspensearray[i].newsuspense = "0.00";
    }

    for (let i = 0; i < mastertranssuspensearray.length; i++) {
      const index = paygridvalues?.findIndex(
        (x: any) =>
          String(x.Agreementid) ==
          String(mastertranssuspensearray[i].agreementnumber)
      );
      paygridvalues[index].isCOATransfered = false;
      paygridvalues[index].suspenseAdd = 0;
      paygridvalues[index].SuspenseAmount =
        paygridvalues[index].transferOldSuspense;
      mastertranssuspensearray[i].newsuspense = "0.00";
    }
    setpaygridvalues(paygridvalues);
    mastertranssuspensearray = [];
    totalsuspensemaster = [];
    masteraddsuspensearray = [];
    masteraddsuspensearray = [];
    mastertranssuspensearray = [];
    settotalsuspensemaster([]);
    setmasteraddsuspensearray([]);
    setmasterusesuspensearray([]);
    setmastertranssuspensearray([]);


    suspenseitemsbind();
    BindGrid();

    let resAmount = 0.0;
    //  let resTaxamount=0.00
    for (let i = 0; i < checkedAgr.length; i++) {
      resAmount = Number(checkedAgr[i].Amount) + Number(resAmount);
      // resTaxamount=Number(checkedAgr[i].Taxamount)
    }
    //SuspenseTender-1
    // debugger;
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    let resTotalAmount = 0.0;
    if (paymentOrigin == "Pay By Phone") {
      resAmount = Number(resAmount) + Number(convAmtWTax);
      // resTaxamount=Number(resTaxamount)+Number(convtax)
    }
    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (Number(resTotalAmount) > Number(resAmount)) {
      resetPayment(undefined);
      setremainingamountdue(
        Number(
          (
            Number(resAmount) -
            Number(availableCreds)+
            Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ).toFixed(2)
        )
      );
      setchange("0.00");
      setAcceptpaymentbuttonenable(true);
    } else {
      if (
        Number(resTotalAmount) >=
        Number(
          (
            Number(resAmount) -
            Number(availableCreds)+
            Number(donationAmountAdd) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
          ).toFixed(2)
        )
      ) {
        setchange(
          Number(resTotalAmount) -
            Number(
              (
                Number(resAmount) -
                Number(availableCreds) +
                Number(donationAmountAdd)+
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
              ).toFixed(2)
            )
        );
        setremainingamountdue("0.00");
        setRemaniningAmtStatic("0.00");
        setAcceptpaymentbuttonenable(false);
      } else {
        setremainingamountdue(
          Number(
            (
              Number(resAmount) -
              Number(availableCreds)+
              Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setRemaniningAmtStatic(
          Number(
            (
              Number(resAmount) -
              Number(availableCreds)+
              Number(donationAmountAdd) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount) - Number(totalSurrenderAmount) + Number(totalDepositAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setchange("0.00");
        setAcceptpaymentbuttonenable(true);
      }
    }

    settotaldue(resAmount);
    // settotaltax(Number(resTaxamount))

    setopenAdjustDueDate(false);
    setopenPromoFreeDays(false);
    seteditSchedulePopUp(false);
    setsuspenseAllDelete(false);
    setSuspenseDeleteExpection(false);
    // if (isRevenueRecogActivated) {
    //   multipleAdjustRevenue();
    // } else {
      multipleAdjust();
    // }
  };

  const step1Next = () => {
    setnextBtn(true);
    setswipepopupshow(false);
  };

  const Previous = () => {
    setnextBtn(false);
    setswipepopupshow(true);
  };

  const skip = () => {
    setswipepopupshow(false);
    setnextBtn(false);
  };

  const MoneyOrderPopup = () => {
    return (
      <Grid
        item
        id="MoneyOrderInformation"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="carrywaive"
        aria-hidden="true"
      >
        <Grid container spacing={2} className={classes.px3}>
          <Grid item md={6} className={classes.mb2}>
            <RACTextbox
              type="text"
              isCurrency={false}
              id="TotalFees"
              inputlabel="Money Order#"
              name="Money Order"
              required={true}
              maxlength={20}
              value={adddata.checkno}
              OnChange={(e) => {
                setcheckno(e);
              }}
              data-testid="addmoneyorderno"
            />

            {checknovalid === true ? (
              <label className={classes.mandatoryfield}>
                Please enter Money Order Number.
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <RACTextbox
              type="text"
              isCurrency={false}
              id="Amount-collect"
              inputlabel="Issuer"
              required={true}
              maxlength={30}
              value={adddata.Written_by}
              OnChange={(e) => {
                setcheckwrittentby(e);
              }}
              data-testid="addmoneyorderwritten"
            />

            {writtenby === true ? (
              <label className={classes.mandatoryfield}>
                Please enter Money Order issuer.
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <RACTextbox
              type="text"
              isCurrency={false}
              inputlabel=" Purchaser"
              required={true}
              id="carrylate"
              value={adddata.Written_To}
              maxlength={30}
              OnChange={(e) => {
                setcheckwrittento(e);
              }}
              data-testid="addmoneyorderwrittento"
            />

            {Writtento === true ? (
              <label className={classes.mandatoryfield}>
                Please enter Money Order Purchaser.
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <label className={classes.labeltxtstyle}>
              Amount<span className={classes.mandatoryfield}></span>
            </label>
            <div className={`${classes.inputgroup} ${classes.mb3}`}>
              <span className={classes.inputgrouptext}>$</span>

              <CurrencyInput
                type="text"
                name="moneyorderAmount"
                className={classes.formcontrol3}
                value={adddata.Amount}
                onValueChange={(e) => {
                  setcheckamount(e);
                }}
                onBlur={(e) => {
                  setcheckamountblur(e);
                }}
                decimalsLimit={2}
                data-testid="addmoneyorderamount"
              />

              {Amount === true ? (
                <label className={classes.mandatoryfield}>
                  Please enter a positive Money Order Amount.
                </label>
              ) : null}
            </div>
          </Grid>
        </Grid>

        <Grid
          className={`${classes.textright} ${classes.modalfooter} ${classes.pb2}`}
        >
          <RACButton
            className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
            variant="outlined"
            color="primary"
            data-bs-dismiss="modal"
            onClick={() => {
              checkormoneyordercancel();
            }}
            data-testid="moneyordcanceltest"
          >
            Cancel
          </RACButton>

          {button === true ? (
            <RACButton
              className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
              color="primary"
              variant="contained"
              onClick={(e) => upload(e)}
              value="2"
            >
              Update
            </RACButton>
          ) : (
            <RACButton
              className={`${classes.paymentSmallBtn1}`}
              color="primary"
              variant="contained"
              value="2"
              onClick={(e) => save(e)}
              data-testid="addmoneysave"
            >
              Save
            </RACButton>
          )}
        </Grid>
      </Grid>
    );
  };

  const swipeCardpopup = () => {
    if (DeveiceResp !== undefined && DeveiceResp.data !== "") {
      return (
        <Grid
          item
          data-testid="swipecardmodal"
          id="Swipecard"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="transaction"
          aria-hidden="true"
        >
          <Grid container justifyContent={"center"}>
            <Grid item md={12} className={`${classes.mb3} ${classes.ps2}`}>
              <Typography
                variant="caption"
                className={`${classes.RAClabeltxtstyle} ${classes.semibold}`}
              >
                Remaining Amount Due:{" "}
              </Typography>
              <Typography
                variant="caption"
                className={`${classes.RACvaluetxtstyle} ${classes.bold}`}
              >
                $
                {fixedChange(
                  String(Number(remainingamountdue) - Number(swipeDeviceValue))
                )}
              </Typography>
            </Grid>
            <Grid item md={12} className={`${classes.mb3} ${classes.ps2}`}>
              <Grid
                item
                md={12}
                className={`${classes.formLabel} ${classes.w100} ${classes.floatLeft} ${classes.mt2}`}
              >
                Computer Name
              </Grid>
              <Grid
                item
                md={12}
                className={`${classes.mb3} ${classes.w100} ${classes.floatLeft}`}
              >
                <RACSelect
                  options={DeviceNameArrfunc()}
                  defaultValue={TerminalId}
                  onChange={(e) => deviceDropdown(e)}
                />
                <Grid
                  className={`${classes.w100} ${classes.floatLeft} ${classes.mt2}`}
                >
                  <Typography
                    className={`${classes.floatLeft}`}
                    variant="caption"
                  >
                    Please read the instruction to know the device name.
                  </Typography>
                  <Grid
                    className={`${classes.floatLeft} ${classes.racDeviceInfo}`}
                  >
                    <Info onClick={() => infoonclick()}></Info>
                    {swipepopupshow === true ? (
                      <Grid
                        id="swipepopup"
                        item
                        className={classes.racDeviceGuide}
                      >
                        <Card
                          className={`${classes.w100} ${classes.mb2} ${classes.card} ${classes.shadownone}`}
                        >
                          <CardContent>
                            <Typography
                              className={`${classes.semibold} ${classes.font14}`}
                            >
                              Step 1
                            </Typography>
                            <Typography className={classes.font14}>
                              Please go to &quot;My Details&quot; app in your
                              Desktop to know the system inforamtion.Refer the
                              screen shot given below.
                            </Typography>
                            <Grid className={classes.racDeviceImage}>
                              <Step1img></Step1img>
                            </Grid>
                            <Grid
                              className={`${classes.floatRight} ${classes.my3}`}
                            >
                              <RACButton
                                variant="outlined"
                                color="secondary"
                                className={classes.mx2}
                                onClick={() => skip()}
                              >
                                Skip
                              </RACButton>
                              <RACButton
                                variant="contained"
                                color="primary"
                                onClick={() => step1Next()}
                              >
                                Next
                              </RACButton>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    ) : null}
                    {nextBtn === true ? (
                      <Grid
                        id="swipepopup"
                        item
                        className={classes.racDeviceGuide}
                      >
                        <Card
                          className={`${classes.w100} ${classes.mb2} ${classes.card} ${classes.shadownone} `}
                        >
                          <CardContent>
                            <Typography
                              className={`${classes.semibold} ${classes.font14}`}
                            >
                              Step 2
                            </Typography>
                            <Typography className={classes.font14}>
                              You can find the computer name as highlighted in
                              red color.Refer the screenshot given below.
                            </Typography>
                            <Grid className={classes.racDeviceImage}>
                              <Step2img></Step2img>
                            </Grid>
                            <Grid
                              className={`${classes.floatRight} ${classes.my3}`}
                            >
                              <RACButton
                                variant="outlined"
                                color="secondary"
                                className={classes.mx2}
                                onClick={() => Previous()}
                              >
                                Previous
                              </RACButton>
                              <RACButton
                                variant="contained"
                                color="primary"
                                onClick={() => skip()}
                              >
                                Ok
                              </RACButton>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} className={`${classes.mb3} ${classes.ps2}`}>
              <Grid
                item
                md={12}
                className={`${classes.formLabel} ${classes.mt2}`}
              >
                Amount
              </Grid>
              <Grid item md={12} className={`${classes.mb3}`}>
                <RACTextbox
                  isCurrency={true}
                  className={`${classes.custDigit}`}
                  value={swipeDeviceValue}
                  name="total_amt"
                  id="dollor"
                  data-testid="amtTxtBoxId"
                  Onblur={(e: any) => Amtonblurclick(e)}
                  OnChange={(e: any) => totalamount(e.target.value)}
                  OnKeydown={(e: any) => {
                    if (e.key.toLowerCase() === "delete") {
                      totalamount("0.00");
                    }
                  }}
                  type={"number"}
                  digitFormat={"currency"}
                  maxlength={10}
                  dollarTextClassName={classes.currencyDollarField}
                />
                {Validator.SwipeAmtValidationtxtbox === true ? (
                  <label
                    className={classes.validationstyle}
                    style={{
                      visibility:
                        Validator.SwipeAmtValidationtxtbox === true
                          ? "visible"
                          : "hidden",
                    }}
                  >
                    Amount should not be entered more than the remaining amount
                  </label>
                ) : null}
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12} className={`${classes.p3} ${classes.textright}`}>
            <RACButton
              // type="button"
              className={`${classes.paymentSmallBtn1} ${classes.mx2} ${navlinkClass.paymentBtnHover}`}
              variant="outlined"
              color="primary"
              style={{
                backgroundColor: "#fafcff",
                textTransform: "none",
                fontWeight: "bolder",
                paddingLeft: "14px",
                paddingRight: "14px",
                color: "#5a5a5a",
                borderColor: "#cee0ff  !important",
              }}
              onClick={() => close()}
              data-bs-toggle="modal"
              data-testid="SwipeCardClose"
            >
              Cancel
            </RACButton>

            <RACButton
              // type="button"
              className={`${classes.paymentSmallBtn1}`}
              color="primary"
              variant="contained"
              style={{
                backgroundColor: "#2179fe",
                textTransform: "none",
                fontWeight: "bolder",
                paddingLeft: "14px",
                paddingRight: "14px",
                color: "white",
              }}
              onClick={(e) => Spinner(e)}
              data-bs-toggle="modal"
              disabled={
                Number(swipeDeviceValue) > 0 && String(TerminalId) != "null"
                  ? Cardrequire.Proceedbtn
                  : true
              }
              data-testid="SwipeCardClose"
            >
              Next
            </RACButton>
          </Grid>
        </Grid>
      );
    }
  };

  const AddNewCardPopup = () => {
    if (showAddCard && !showChargeCard) {
      return (
        <Grid
          style={{ float: "left", width: "100%" }}
          id="newcard"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="carrywaive"
          aria-hidden="true"
          data-testid="NewCardTestId"
        >
          <Grid style={{ float: "left", width: "100%" }}>
            <Grid style={{ float: "left", width: "40%" }}>
              <h5 style={{ marginBottom: 20, marginTop: "20px" }}>
                Add Card Information
              </h5>
              <Grid style={{ float: "left", width: "100%" }}>
                <Grid id="ccframe">{callVantivIframe(1)}</Grid>
              </Grid>
            </Grid>

            <Grid style={{ float: "left", width: "60%" }}>
              <h5 style={{ marginBottom: 20 }}>Billing Address</h5>

              <Grid style={{ float: "left", width: "100%" }}>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    isCurrency={false}
                    type="text"
                    name="name"
                    id="Name"
                    inputlabel="Name"
                    required={true}
                    data-testid="NameId"
                    value={Popupfields.name}
                    OnChange={(e) => initialnewcardstate(e.target)}
                  />
                  {Name_validation === true ? (
                    <Typography
                      className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                    >
                      Please enter Name
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    type="text"
                    name="address_line_1"
                    id="AddressLine1"
                    data-testid="AddressLine1Id"
                    value={Popupfields.address_line_1}
                    inputlabel="Address Line 1"
                    required={true}
                    OnChange={(e) => initialnewcardstate(e.target)}
                    isCurrency={false}
                  />
                  {Address_line1_validation === true ? (
                    <Typography
                      className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                    >
                      Please enter Address line1
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    type="text"
                    name="address_line2"
                    data-testid="AddressLine2Id"
                    id="AddressLine2"
                    inputlabel="Address Line 2"
                    value={Popupfields.address_line2}
                    OnChange={(e) => initialnewcardstate(e.target)}
                    isCurrency={false}
                  />
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    isCurrency={false}
                    type={"number"}
                    digitFormat={"normal"}
                    name="zip_code"
                    id="ZIP"
                    pattern="\d*"
                    maxlength={5}
                    value={Popupfields.zip_code}
                    data-testid="ZipCodeId"
                    inputlabel="ZIP Code"
                    required={true}
                    OnChange={(e) => initialnewcardstate(e.target)}
                  />
                  {Zip_code_validation === true ? (
                    <Typography
                      className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                    >
                      Please enter ZIP code
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    type="text"
                    name="City"
                    id="City"
                    required={true}
                    inputlabel="City"
                    value={Popupfields.city}
                    data-testid="StateId"
                    OnChange={(e) => initialnewcardstate(e.target)}
                    isCurrency={false}
                  />
                  {CityValidation === true ? (
                    <Typography
                      className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                    >
                      Please enter City
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACSelect
                    // className={classes.formcontrol} Need to update node module
                    data-testid="dropmainId"
                    options={DD}
                    defaultValue={defaultVal}
                    inputLabel="State"
                    required={true}
                    name="state"
                    onChange={(e) => dynamicState(e.target)}
                  ></RACSelect>
                  {State_validation === true ? (
                    <Typography className={classes.mandatoryfield}>
                      Please select state
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ float: "left", width: "100%" }}>
            <div style={{ float: "right" }}>
              <RACButton
                className={`${classes.paymentSmallBtn1} ${classes.mx2} ${navlinkClass.paymentBtnHover}`}
                variant="outlined"
                color="primary"
                name="De_ActivateClubbtn"
                style={{
                  backgroundColor: "white",
                  textTransform: "none",
                  fontWeight: "bolder",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  color: "#2179fe",
                }}
                onClick={() => AddCardClose()}
                data-bs-toggle="modal"
              >
                Cancel
              </RACButton>

              <RACButton
                className={`${classes.paymentSmallBtn1}`}
                color="primary"
                variant="contained"
                name="De_ActivateClubbtn"
                style={{
                  backgroundColor: "#2179fe",
                  textTransform: "none",
                  fontWeight: "bolder",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  color: "white",
                }}
                onClick={() => vantivSubFunc()}
                data-bs-toggle="modal"
              >
                Save
              </RACButton>
            </div>
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  };

  const chargeCardPopup = () => {
    if (showChargeCard && !showAddCard) {
      return (
        <Grid
          style={{ float: "left", width: "100%" }}
          id="newcard"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="carrywaive"
          aria-hidden="true"
          data-testid="NewCardTestId"
        >
          <Grid style={{ float: "left", width: "100%" }}>
            <Grid style={{ float: "left", width: "40%" }}>
              <h5 style={{ marginBottom: 20, marginTop: "20px" }}>
                Charge Card Information
              </h5>
              <Grid style={{ float: "left", width: "100%" }}>
                <Grid id="chargeframe">{callVantivIframe(2)}</Grid>
              </Grid>
            </Grid>

            <Grid style={{ float: "left", width: "60%" }}>
              <Grid item md={12} className={`${classes.mb3} ${classes.ps2}`}>
                <Grid
                  item
                  md={12}
                  className={`${classes.formLabel} ${classes.mt2}`}
                >
                  Amount
                </Grid>
                <Grid item md={12} className={`${classes.mb3}`}>
                  <RACTextbox
                    isCurrency={true}
                    className={`${classes.custDigit}`}
                    value={chargeCardAmt}
                    name="total_amt"
                    id="chargetxtbox"
                    data-testid="amtTxtBoxId"
                    Onblur={(e: any) => totalChargeAmount(e.target.value)}
                    OnChange={(e: any) => setchargeCardAmt(e.target.value)}
                    OnKeydown={(e: any) => {
                      if (e.key.toLowerCase() === "delete") {
                        setchargeCardAmt("0.00");
                      }
                    }}
                    type={"number"}
                    digitFormat={"currency"}
                    maxlength={10}
                    dollarTextClassName={classes.currencyDollarField}
                  />
                  {Validator.chargeCardValidation === true ? (
                    <label
                      className={classes.validationstyle}
                      style={{
                        visibility:
                          Validator.chargeCardValidation === true
                            ? "visible"
                            : "hidden",
                      }}
                    >
                      Amount should not be entered more than the remaining
                      amount
                    </label>
                  ) : null}
                </Grid>
              </Grid>
              <h5 style={{ marginBottom: 20 }}>Billing Address</h5>
              <Grid style={{ float: "left", width: "100%" }}>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    isCurrency={false}
                    type="text"
                    name="name"
                    id="Name"
                    inputlabel="Name"
                    required={true}
                    data-testid="NameId"
                    value={Popupfields.name}
                    OnChange={(e) => initialnewcardstate(e.target)}
                  />
                  {Name_validation === true ? (
                    <Typography
                      className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                    >
                      Please enter Name
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    type="text"
                    name="address_line_1"
                    id="AddressLine1"
                    data-testid="AddressLine1Id"
                    value={Popupfields.address_line_1}
                    inputlabel="Address Line 1"
                    required={true}
                    OnChange={(e) => initialnewcardstate(e.target)}
                    isCurrency={false}
                  />
                  {Address_line1_validation === true ? (
                    <Typography
                      className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                    >
                      Please enter Address line1
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    type="text"
                    name="address_line2"
                    data-testid="AddressLine2Id"
                    id="AddressLine2"
                    inputlabel="Address Line 2"
                    value={Popupfields.address_line2}
                    OnChange={(e) => initialnewcardstate(e.target)}
                    isCurrency={false}
                  />
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    isCurrency={false}
                    type={"number"}
                    digitFormat={"normal"}
                    name="zip_code"
                    id="ZIP"
                    pattern="\d*"
                    maxlength={5}
                    value={Popupfields.zip_code}
                    data-testid="ZipCodeId"
                    inputlabel="ZIP Code"
                    required={true}
                    OnChange={(e) => initialnewcardstate(e.target)}
                  />
                  {Zip_code_validation === true ? (
                    <Typography
                      className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                    >
                      Please enter ZIP code
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    type="text"
                    name="City"
                    id="City"
                    required={true}
                    inputlabel="City"
                    value={Popupfields.city}
                    data-testid="StateId"
                    OnChange={(e) => initialnewcardstate(e.target)}
                    isCurrency={false}
                  />
                  {CityValidation === true ? (
                    <Typography
                      className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                    >
                      Please enter City
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACSelect
                    // className={classes.formcontrol} Need to update node module
                    data-testid="dropmainId"
                    options={DD}
                    defaultValue={defaultVal}
                    inputLabel="State"
                    required={true}
                    name="state"
                    onChange={(e) => dynamicState(e.target)}
                  ></RACSelect>
                  {State_validation === true ? (
                    <Typography className={classes.mandatoryfield}>
                      Please select state
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ float: "left", width: "100%" }}>
            <div style={{ float: "right" }}>
              <RACButton
                className={`${classes.paymentSmallBtn1} ${classes.mx2} ${navlinkClass.paymentBtnHover}`}
                variant="outlined"
                color="primary"
                name="De_ActivateClubbtn"
                style={{
                  backgroundColor: "white",
                  textTransform: "none",
                  fontWeight: "bolder",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  color: "#2179fe",
                }}
                onClick={() => ChargeCardClose()}
                data-bs-toggle="modal"
              >
                Cancel
              </RACButton>

              <RACButton
                className={`${classes.paymentSmallBtn1}`}
                color="primary"
                variant="contained"
                name="De_ActivateClubbtn"
                disabled={chargeCardContinue}
                style={{
                  backgroundColor: "#2179fe",
                  textTransform: "none",
                  fontWeight: "bolder",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  color: "white",
                }}
                onClick={() => vantivSubFunc()}
                data-bs-toggle="modal"
              >
                Continue
              </RACButton>
            </div>
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  };

  const editCardPopup = () => {
    if (getCardService_state.length !== 0 && editcardindex !== undefined) {
      return (
        <Grid
          item
          id="editcard"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="carrywaive"
          aria-hidden="true"
          data-testid="EditCardPopupId"
        >
          <Grid container spacing={2}>
            <Grid item md={6} className={`${classes.mb3} ${classes.px2}`}>
              <RACTextbox
                type="text"
                id="Name0"
                name="name"
                required={true}
                value={EditCard.Cardname}
                isCurrency={false}
                inputlabel="Name"
                OnChange={(e: any) => editcardOnchange(e.target)}
              />
              {nameVal === true ? (
                <Typography
                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                >
                  Please enter Name
                </Typography>
              ) : null}
            </Grid>

            <Grid item md={6} className={`${classes.mb3} ${classes.px2}`}>
              <RACTextbox
                type="text"
                id="AddressLine10"
                name="address_line_1"
                OnChange={(e) => editcardOnchange(e.target)}
                value={EditCard.address_1}
                isCurrency={false}
                required={true}
                inputlabel="Address Line 1"
              />
              {addressval1 === true ? (
                <Typography
                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                >
                  Please enter Address line1
                </Typography>
              ) : null}
            </Grid>

            <Grid item md={6} className={`${classes.mb3} ${classes.px2}`}>
              <RACTextbox
                type="text"
                isCurrency={false}
                OnChange={(e) => editcardOnchange(e.target)}
                inputlabel="Address Line 2"
                name="address_line2"
                id="AddressLine20"
                value={EditCard.address_2}
              />
            </Grid>

            <Grid item md={6} className={`${classes.mb3} ${classes.px2}`}>
              <RACTextbox
                type="text"
                pattern="\d*"
                maxlength={5}
                OnChange={(e) => editcardOnchange(e.target)}
                name="zip_code"
                id="ZIP77"
                required={true}
                value={EditCard.zip}
                inputlabel="ZIP Code"
                isCurrency={false}
              />
              {ZipVal === true ? (
                <Typography
                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                >
                  Please enter ZIP code
                </Typography>
              ) : null}
            </Grid>

            <Grid item md={6} className={`${classes.mb3} ${classes.px2}`}>
              <RACTextbox
                type="text"
                name="city"
                id="city"
                required={true}
                isCurrency={false}
                OnChange={(e) => editcardOnchange(e.target)}
                inputlabel="City"
                value={EditCard.city}
              />
              {cityVal === true ? (
                <Typography
                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                >
                  Please enter City
                </Typography>
              ) : null}
            </Grid>

            <Grid item md={6} className={`${classes.mb3} ${classes.px2}`}>
              <RACSelect
                options={DD}
                defaultValue={EditCard.state}
                inputLabel="State"
                name="State"
                required={true}
                onChange={(e) => stateOnchange(e.target)}
              ></RACSelect>
              {stateVal === true ? (
                <Typography
                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                >
                  Please select State
                </Typography>
              ) : null}
            </Grid>
          </Grid>
          <Grid item md={12} className={`${classes.w100} ${classes.mt4}`}>
            <RACButton
              data-bs-dismiss="modal"
              onClick={() => del()}
              style={{
                backgroundColor: "#FFD1D1",
                textTransform: "none",
                fontWeight: "bolder",
                paddingLeft: "14px",
                paddingRight: "14px",
                color: "#B50000",
              }}
              data-testid="DeleteBtnId"
            >
              Delete Card
            </RACButton>

            <Grid item className={classes.floatRight}>
              <RACButton
                style={{
                  backgroundColor: "#fafcff",
                  textTransform: "none",
                  fontWeight: "bolder",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  color: " #5a5a5a",
                }}
                data-bs-dismiss="modal"
                onClick={() => close()}
                data-testid="EditsCrdCancelbtnIn"
              >
                Cancel
              </RACButton>

              <RACButton
                style={{
                  backgroundColor: "#2179fe",
                  textTransform: "none",
                  fontWeight: "bolder",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  color: "#FFFFFF",
                }}
                onClick={() => EditCardfunc()}
                data-testid="EditsCrdSavebtnIn"
              >
                {" "}
                Save
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };

  const DeletePopupForEnrolledCard = () => {
    if (getCardService_state != undefined) {
      return (
        <Grid
          item
          id="editcard"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="carrywaive"
          aria-hidden="true"
          data-testid="EditCardPopupId"
        >
          <Typography
            className={`${classes.textcenter} ${classes.mt3} ${classes.mb1}`}
          >
            The card you are about to delete is currently set up for Autopay by
            the customer.{" "}
          </Typography>
          <Typography className={`${classes.textcenter} ${classes.mb1}`}>
            You will need to set up another card to keep the customer on
            Autopay.
          </Typography>
          <Typography className={`${classes.textcenter} ${classes.mb1}`}>
            {" "}
            Are you sure want to continue ?
          </Typography>

          <Grid
            item
            md={12}
            className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
          >
            <RACButton
              className={classes.mx1}
              variant="outlined"
              color="primary"
              data-bs-dismiss="modal"
              onClick={() => close()}
              data-testid="EditsCrdCancelbtnIn"
            >
              No
            </RACButton>
            <RACButton
              className={classes.mx1}
              color="primary"
              variant="contained"
              onClick={() => Enroll_AP_Card()}
              data-testid="EditsCrdSavebtnIn"
            >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      );
    }
  };

  const DeletePopup = () => {
    return (
      <Grid
        item
        id="delete-auto"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        data-testid="DeleteCardPopupId"
        tabIndex={-1}
        aria-labelledby="delete-auto"
        aria-modal="true"
        role="dialog"
      >
        <Grid container className={classes.textcenter}>
          <Grid item md={12} className={classes.mb3}>
            <DeleteIcon></DeleteIcon>
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="h6"
              className={`${classes.customsure} ${classes.semibold}`}
            >
              Delete Card
            </Typography>
          </Grid>
          <Grid
            item
            md={12}
            className={`${classes.bgpopdelete} ${classes.mt4} ${classes.pb3}`}
          >
            <Typography
              className={`${classes.font14} ${classes.semibold} ${classes.textcenter} ${classes.mt3} ${classes.mb1}`}
            >
              The card you selected ending with
              <Typography
                variant="caption"
                className={`${classes.blackcolortext} ${classes.ps2} ${classes.font14} ${classes.semibold}`}
              >
                {editcardnumberbind}
              </Typography>{" "}
              will be{" "}
            </Typography>
            <Typography
              className={`${classes.font14} ${classes.semibold} ${classes.textcenter} ${classes.mb1}`}
            >
              removed from the customer&apos;s card on the file list.
            </Typography>
            <Typography
              className={`${classes.font14} ${classes.semibold} ${classes.graycolortext}`}
            >
              {" "}
              Are you sure you want to continue?
            </Typography>
            <Grid
              item
              md={12}
              className={`${classes.w100} ${classes.mt3} ${classes.textcenter}`}
            >
              <RACButton
                className={classes.mx1}
                variant="outlined"
                color="primary"
                onClick={() => close()}
                data-bs-dismiss="modal"
                data-testid="NoKeepItBtnId"
                data-bs-toggle="modal"
                data-bs-target="#delivery-receipt"
              >
                {" "}
                No,Keep It
              </RACButton>
              <RACButton
                onClick={() => YesforDelete()}
                className={`${classes.btnkeepdelete} ${classes.mx2} ${classes.px3}`}
                data-testid="yesDeleteCardId"
              >
                Yes,Delete Card
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const TransactionCompletePopup = () => {
    return (
      <Grid
        item
        id="transaction"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
        data-testid="TranId"
      >
        <Grid container className={classes.textcenter}>
          <Grid item md={12}>
            <SuccessIcon></SuccessIcon>
          </Grid>
          <Typography
            className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
          >
            Payment processed successfully, click "Accept Payment" to complete transaction.
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => close()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const GetTxt2PaycardMpping = () => {
      if(getCardService_state.cardResp && getCardService_state.cardResp.length >0)
        {return getCardService_state.cardResp.map((item: any, index: any) => {
      return (
        <Grid
          item
          key={index}
          className={`${classes.w100} ${classes.floatLeft} `}
        >
          <Grid
            item
            className={`  ${classes.floatLeft} ${classes.pe0} ${classes.w100}`}
          >
            <Grid
              item
              className={`${classes.formcheck} ${classes.mar6}  ${classes.ps0} ${classes.floatLeft} ${classes.alignContentCenterTxt2Pay} ${classes.w100}`}
            >
              <RACRadio
                style={{ float: "left" }}
                defaultValue=""
                id={"card-2" + index}
                value={index}
                name="Card CheckBox"
                checked={txt2PayRadioArr[index]==false?false:true}
                onChange={() => handleRadioChange(index,item)}
                data-testid="2ndCardChkboxId"
              />
              <Typography
                className={`${classes.font12} ${classes.alignContentCenter}`}
                htmlFor={"card-2" + index}
                id={"ce" + index}
                component={"label"}
              >
                {" "}
                {Bindcardsymbol(item.cardType)} Card ending with {item.cardLastFour}
              </Typography>
            </Grid>
          </Grid>
         
        </Grid>
      );
    });}
  };

const Txt2payCardOnFilepopup = () => {
    if (getpayment !== undefined || getpayment !== Error) {
      return (
        <Grid
          item
          id="ph-text-autopay"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="ph-text-autopay"
          aria-modal="true"
          data-testid="Txt2payPopup"
        >
           <Grid container >
                <Grid item md={12} className={classes.mb4}>
                  <Typography className={classes.customtextpayNew}>
                  Select a saved card on file or by making no selection customer will receive a link to manually enter card information.
                  </Typography>
                </Grid>
    
                <Grid
                  md={12}
                  className={` ${classes.floatLeft}`}
                >
                  <Grid container md={12} className={classes.justifycontentcenter}>
                    <Grid item md={6} className={classes.floatLeft}>
                      <Typography
                        variant="caption"
                        className={`${classes.RAClabeltxtstyle} ${classes.w100}  ${classes.floatLeft}`}
                      >
                         Customer Name
                      </Typography>
                      <Typography
                        className={`${classes.w100} ${classes.pb4} ${classes.semibold}`}
                      >
                        {customerName}
                      </Typography>
                    </Grid>
                    <Grid item md={6} className={classes.floatLeft}>
                      <Typography
                        variant="caption"
                        className={`${classes.RAClabeltxtstyle}  ${classes.w100}  ${classes.floatLeft}`}
                      >
                        Phone Number
                      </Typography>
                      <Typography
                        className={`${classes.w100} `}
                      > 
                          {formatPhone(
                          getpayment?.paymentInfo.customerInfo == undefined
                            ? ""
                            : getpayment?.paymentInfo.customerInfo[0].phoneNumber
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={`${classes.w100} ${classes.cardMapping}`}>
                  {GetTxt2PaycardMpping()}
                  </Grid>
                <Grid
                    item
                    md={12}
                    className={`${classes.pb2} ${classes.modalfooterNew}`}
                  >
                    <RACButton
                      className={classes.mx1}
                      variant="outlined"
                      color="primary"
                      data-bs-dismiss="modal"
                      onClick={() => {setCardrequire({ ...Cardrequire, txt2payCardOnFile: false }); handleReset()}}
                      data-testid="txt2payCancel"
                    >
                      Cancel
                    </RACButton>
                    <RACButton
                      className={classes.mx1}
                      color="primary"
                      variant="contained"
                      data-bs-dismiss="modal"
                      disabled={getCardService_state?.cardResp?.length>0&&selectedTxt2PayCard.length>0?false:true}
                      onClick={() => {handleReset()}}
                      data-testid="Yestxt2payId"
                    >
                      Reset
                    </RACButton>
                    <RACButton
                      className={classes.mx1}
                      color="primary"
                      variant="contained"
                      data-bs-dismiss="modal"
                      onClick={() => yesforTxt2pay()}
                      data-testid="Yestxt2payId"
                    >
                      Send
                    </RACButton>
                  </Grid>
              </Grid>
        </Grid>
      );
    }
  };

  const Txt2paypopup = () => {
    if (getpayment !== undefined || getpayment !== Error) {
      return (
        <Grid
          item
          id="ph-text-autopay"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="ph-text-autopay"
          aria-modal="true"
          data-testid="Txt2payPopup"
        >
          <Grid container className={classes.textcenter}>
            <Grid item md={12} className={classes.mb3}>
              <Txt2payimg></Txt2payimg>
            </Grid>
            <Grid item md={12} className={classes.mb4}>
              <Typography variant="h5" className={classes.customtextpay}>
                Phone to Text for Payment
              </Typography>
            </Grid>

            <Grid
              md={12}
              className={`${classes.bgpopdelete} ${classes.floatLeft}`}
            >
              <Grid container md={12} className={classes.justifycontentcenter}>
                <Grid item md={6} className={classes.floatLeft}>
                  <Typography
                    variant="caption"
                    className={`${classes.RAClabeltxtstyle} ${classes.w100} ${classes.pt4} ${classes.floatLeft}`}
                  >
                    Phone Number
                  </Typography>
                  <Typography
                    className={`${classes.w100} ${classes.pb4} ${classes.semibold}`}
                  >
                    {formatPhone(
                      getpayment?.paymentInfo.customerInfo == undefined
                        ? ""
                        : getpayment?.paymentInfo.customerInfo[0].phoneNumber
                    )}
                  </Typography>
                </Grid>
                <Grid item md={6} className={classes.floatLeft}>
                  <Typography
                    variant="caption"
                    className={`${classes.RAClabeltxtstyle} ${classes.semibold} ${classes.w100} ${classes.pt4} ${classes.floatLeft}`}
                  >
                    Customer Name
                  </Typography>
                  <Typography
                    className={`${classes.w100} ${classes.pb4} ${classes.semibold}`}
                  >
                    {customerName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                md={12}
                className={`${classes.pb2} ${classes.modalfooter}`}
              >
                <RACButton
                  className={classes.mx1}
                  variant="outlined"
                  color="primary"
                  data-bs-dismiss="modal"
                  onClick={() => close()}
                  data-testid="txt2payCancel"
                >
                  Cancel
                </RACButton>
                <RACButton
                  className={classes.mx1}
                  color="primary"
                  variant="contained"
                  data-bs-dismiss="modal"
                  onClick={() => yesforTxt2pay()}
                  data-testid="Yestxt2payId"
                >
                  Yes
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };


  const InphonePaymentOrigin = () => {
    return (
      <Grid
        item
        id="phonepay"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
        data-testid="InphonePopupid"
      >
        <Box
          component="span"
          className={`${classes.textleft} ${classes.mb1} ${classes.px3} ${classes.floatLeft}`}
        >
          A convenience fee of{" "}
          <Typography
            variant="caption"
            className={`${classes.RAClabeltxtstyle} ${classes.bold}`}
          >
            ${Number(ConvFeeWithOutTax).toFixed(2)} plus Tax{" "}
          </Typography>
          when applicable
          <Box
            component="span"
            className={`${classes.ps1} ${classes.textleft}`}
          >
            will be added.
          </Box>
        </Box>
        <List className={`${classes.mt3} ${classes.mx3} ${classes.floatLeft}`}>
          <ListItem className={classes.listgroupitem}>
            <Grid className={classes.raccolauto}>
              <Typography
                variant="caption"
                className={`${classes.font14} ${classes.semibold}`}
              >
                Current Total:{" "}
              </Typography>
              <Typography
                variant="caption"
                className={`${classes.font14} ${classes.semibold}`}
              >
                ${fixedChange(totaldue)}
              </Typography>
            </Grid>
          </ListItem>
          <ListItem className={classes.listgroupitem}>
            <Grid className={classes.raccolauto}>
              <Typography
                variant="caption"
                className={`${classes.font14} ${classes.semibold}`}
              >
                New Total:{" "}
              </Typography>
              <Typography
                variant="caption"
                className={`${classes.font14} ${classes.bold}`}
              >
                ${fixedChange(Cardrequire.Newtotal)}
              </Typography>
            </Grid>
          </ListItem>
        </List>
        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.mt3} ${classes.floatRight}`}
        >
          <RACButton
            // type="button"
            className={`${classes.paymentSmallBtn1} ${classes.mx2} ${navlinkClass.paymentBtnHover}`}
            variant="outlined"
            color="primary"
            data-bs-dismiss="modal"
            data-testid="NobtnId"
            onClick={() => closephonepopup()}
          >
            No
          </RACButton>
          <RACButton
            // type="button"
            className={`${classes.paymentSmallBtn1}`}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            onClick={() => NewtotalAmt()}
            data-bs-toggle="modal"
            data-testid="YesBtn"
            data-bs-target="#autopayconsent"
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const TerminateLDWpopup = () => {
    if (customerCoverageObject !== undefined) {
      return (
        <Grid
          item
          id="terminateldw"
          data-bs-backdrop="static"
          data-testid="TerminateLDWTestId"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="terminateldw"
          aria-hidden="true"
        >
          <Typography className={classes.ps2}>
            By deactivating LDW, your regular payment will decrease by
            <Typography
              variant="caption"
              className={`${classes.RAClabeltxtstyle} ${classes.bold} ${classes.ms1} ${classes.font16}`}
            >
              $
              {adjustTerminateLdwAmount != "0"
                ? adjustTerminateLdwAmount
                : customerCoverageObject.ldwAmount}
            </Typography>
            . If you have club it will be cancelled and decreased by that
            payment amount also.
          </Typography>

          <Grid
            item
            md={12}
            className={`${classes.modalfooter} ${classes.mt3} ${classes.textright}`}
          >
            <RACButton
              className={classes.mx1}
              variant="outlined"
              color="primary"
              data-testid="TerminateCancelbtn"
              data-bs-dismiss="modal"
              onClick={() => close()}
            >
              Cancel
            </RACButton>
            <RACButton
              className={classes.mx1}
              color="primary"
              variant="contained"
              data-testid="ActiveLDWContinueId"
              onClick={() => ContinueTerminateLDW()}
            >
              Continue
            </RACButton>
          </Grid>
        </Grid>
      );
    }
  };

  const ActiveLDWpopup = () => {
    if (customerCoverageObject !== undefined) {
      return (
        <Grid
          item
          id="terminateldw"
          data-testid="ActivateLdwPopupId"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="terminateldw"
          aria-hidden="true"
        >
          <Typography className={classes.ps2}>
            By activating LDW, your regular payment with LDW will increase by
            <Typography
              variant="caption"
              className={`${classes.RAClabeltxtstyle} ${classes.font16} ${classes.bold} ${classes.ms1}`}
            >
              ${customerCoverageObject.ldwAmount}
            </Typography>
            . Do you want to activate LDW?
          </Typography>

          <Grid
            item
            md={12}
            className={`${classes.textright} ${classes.modalfooter} ${classes.mt3}`}
          >
            <RACButton
              // type="button"
              className={classes.mx1}
              variant="outlined"
              color="primary"
              data-testid="ActivateLDWCancelId"
              onClick={() => close()}
              data-bs-dismiss="modal"
            >
              Cancel
            </RACButton>
            <RACButton
              // type="button"
              className={classes.mx1}
              color="primary"
              variant="contained"
              data-testid="continueActivateLDWId"
              onClick={() => continueActivateLDW()}
            >
              Continue
            </RACButton>
          </Grid>
        </Grid>
      );
    }
  };

  const ActivepolicypopupModal = () => {
    if (customerCoverageObject !== undefined) {
      return (
        <Grid
          item
          id="terminateldw"
          data-testid="ActivatePolicyPopId"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="terminateldw"
          aria-hidden="true"
        >
          <Typography className={classes.ps2}>
            Your regular payment with policy will increase by
            <Typography
              variant="caption"
              className={`${classes.RAClabeltxtstyle} ${classes.font16} ${classes.bold} ${classes.ms1}`}
            >
              ${customerCoverageObject.policyAmount}
            </Typography>
            . Do you want to active Policy?
          </Typography>
          <Grid
            item
            md={12}
            className={`${classes.modalfooter} ${classes.mt4} ${classes.textright}`}
          >
            <RACButton
              className={classes.mx1}
              variant="outlined"
              color="primary"
              data-testid="ActivatePolicyCancelId"
              data-bs-dismiss="modal"
              style={{
                backgroundColor: "white",
                textTransform: "none",
                fontWeight: "bolder",
                paddingLeft: "14px",
                paddingRight: "14px",
                color: "#2179fe",
              }}
              onClick={() => close()}
            >
              Cancel
            </RACButton>
            <RACButton
              className={classes.mx1}
              color="primary"
              variant="contained"
              data-testid="ContinueActivatePolicyId"
              style={{
                backgroundColor: "#2179fe",
                textTransform: "none",
                fontWeight: "bolder",
                paddingLeft: "14px",
                paddingRight: "14px",
                color: "white",
              }}
              onClick={() => ContinueActivatePolicy()}
            >
              Continue
            </RACButton>
          </Grid>
        </Grid>
      );
    }
  };

  const DeActivepolicypopupModal = () => {
    if (customerCoverageObject !== undefined) {
      return (
        <Grid
          item
          data-testid="TerminatePolicyPopupId"
          id="terminateldw"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="terminateldw"
          aria-hidden="true"
        >
          <Typography className={classes.px3}>
            Your regular payment with policy will decrease by
            <Typography
              variant="caption"
              className={`${classes.labeltxtstyle} ${classes.font16} ${classes.bold} ${classes.ms1}`}
            >
              ${customerCoverageObject.policyAmount}
            </Typography>
            . Do you want to DeActive Policy?
          </Typography>
          <Grid
            item
            md={12}
            className={`${classes.textright} ${classes.modalfooter} ${classes.mt3}`}
          >
            <RACButton
              // type="button"
              className={classes.mx1}
              variant="contained"
              color="primary"
              data-testid="ContinueDeActivatePolicyID"
              onClick={() => ContinueDeActivatePolicy()}
            >
              Continue
            </RACButton>
            <RACButton
              // type="button"
              className={classes.mx1}
              color="primary"
              variant="outlined"
              data-testid="DeActivateCancelId"
              data-bs-dismiss="modal"
              onClick={() => close()}
            >
              {" "}
              Cancel
            </RACButton>
          </Grid>
        </Grid>
      );
    }
  };

  const autoPopUpHeadFields = [
    { display: "" },
    { display: "Description" },
    { display: "Amount" },
    { display: "Schedule" },
    { display: "Due Date" },
    { display: "" },
  ];

  const renderBindGridHeader = () => (
    <>
      {autoPopUpHeadFields.map((val, index) => {
        return (
          <RACTableCell className={classes.racTable} key={index}>
            {val.display}
          </RACTableCell>
        );
      })}
    </>
  );
  const Addedloaderfunandgrid = () => {
    if (mainGridloader === false) {
      if (bindGridCheck === false) {
        return BindGrid();
      } else {
        return (
          payGridPayloadLoad==false? <RACTableRow className={classes.hiddenRow} key="0">
          <RACTableCell colSpan={9} className={classes.textcenter}>
            No Records Found
          </RACTableCell>
        </RACTableRow>:<RACTableCell colSpan={9} className={classes.textcenter}></RACTableCell>
        );
      }
    } else {
      return (
        <RACTableCell colSpan={9} className={classes.textcenter}></RACTableCell>
      );
    }
  };

  const Payclasses = PayHistory();
  const suspensebindgrid = () => {
    return masteraddsuspensearray.map((value, index: any) => {
      return (
        <RACTableRow key={index} className={Payclasses.payHistbgTransparent}>
          <RACTableCell>
            <Typography variant="caption" color="primary">
              {value.agreementid}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption"> {value.schedure} </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">{value.paymentamount}</Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">
              {" "}
              {value.epoamount.toFixed(2)}{" "}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">{value.suspenseamount}</Typography>
          </RACTableCell>
          <RACTableCell>
            {/* <CurrencyInput
                type="text"
                name="checkAmount"
                className={classes.formcontrol3}
                value={addsus}
                decimalsLimit={2}
                data-testid="amountbox"
              /> */}

            {/* {AddsusText ? ( */}
            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={value.newsuspense}
              data-testid="suspensetextbox"
              name={"suspenseTxtbox" + index}
              digitFormat={"currency"}
              maxlength={10}
              errorMessage={
                Number(value.newsuspense) >
                Number(value.epoamount) - Number(value.suspenseamount)
                  ? "Amount entered can not be greater than EPO"
                  : "" ||
                    Number(Number(value.newsuspense).toFixed(2)) ==
                      Number(
                        (
                          Number(value.epoamount) - Number(value.suspenseamount)
                        ).toFixed(2)
                      )
                  ? "Amount entered can not be equal to EPO. Please use EPO functionality to process the payment"
                  : ""
              }
              OnChange={(e: any) => {
                masterSusOnchange(
                  e,
                  "Add",
                  index,
                  Number(value.newsuspense) >
                    Number(
                      (
                        Number(value.epoamount) - Number(value.suspenseamount)
                      ).toFixed(2)
                    )
                );
              }}
              Onblur={(e: any) => {
                masterSusOnBlur(
                  e,
                  "Add",
                  index,
                  Number(value.newsuspense) >
                    Number(
                      (
                        Number(value.epoamount) - Number(value.suspenseamount)
                      ).toFixed(2)
                    )
                );
              }}
            />
            {/* ) :null } */}
            {Amount === true ? (
              <label className={classes.mandatoryfield}>
                Amount must be greater than 0.
              </label>
            ) : null}

            {/* {UsesusText ? (
              <RACTextbox
                isCurrency={true}
                className={`${classes.custDigit} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                value={value.newsuspense || "0.00"}
                data-testid="suspensetextbox"
                type={"number"}
                name={'suspenseTxtbox'+index}
                digitFormat={"currency"}
                maxlength={10}
                OnKeydown={(e: any) => {
                  if (e.key.toLowerCase() === "delete") {
                    cashamounttextbox("0.00");
                  }
                }}

                OnChange={(e: any) => {
                  masterSusOnchange(
                    e,
                    "Use",
                    index
                  );
                }}

                Onblur={(e: any) => {
                  masterSusOnBlur(
                    e,
                    "Use",
                    index
                  );
                }}
              />
              ) :null } */}
            {Amount === true ? (
              <label className={classes.mandatoryfield}>
                Amount must be greater than 0.
              </label>
            ) : null}
          </RACTableCell>
        </RACTableRow>
      );
    });
  };

  const suspenseusebindgrid = () => {
    return masterusesuspensearray.map((value, index: any) => {

      return (
        <RACTableRow key={index} className={Payclasses.payHistbgTransparent}>
          <RACTableCell>
            <Typography variant="caption" color="primary">
              {value.agreementid}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption"> {value.schedure} </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">{value.paymentamount}</Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">
              {" "}
              {value.epoamount.toFixed(2)}{" "}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">{value.suspenseamount}</Typography>
          </RACTableCell>
          <RACTableCell>
            {/* <CurrencyInput
                type="text"
                name="checkAmount"
                className={classes.formcontrol3}
                value={addsus}
                decimalsLimit={2}
                data-testid="amountbox"
              /> */}

            {/* {AddsusText ? (
              <RACTextbox
                isCurrency={true}
                className={`${classes.custDigit} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                value={value.newsuspense || "0.00"}
                data-testid="suspensetextbox"
                type={"number"}
                name={'suspenseTxtbox'+index}
                digitFormat={"currency"}
                maxlength={10}
                OnKeydown={(e: any) => {
                  if (e.key.toLowerCase() === "delete") {
                    cashamounttextbox("0.00");
                  }
                }}

                OnChange={(e: any) => {
                  masterSusOnchange(
                    e,
                    "Use",
                    index
                  );
                }}

                Onblur={(e: any) => {
                  masterSusOnBlur(
                    e,
                    "Use",
                    index
                  );
                }}
              />
              ) :null }
            {Amount === true ? (
              <label className={classes.mandatoryfield}>
                Amount must be greater than 0.
              </label>
            ) : null} */}

            {/* {UsesusText ? ( */}

            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={value.newsuspense}
              data-testid="suspensetextbox"
              name={"suspenseTxtbox" + index}
              digitFormat={"currency"}
              maxlength={10}
              errorMessage={
                Number(value.newsuspense) > Number(value.suspenseamount)
                  ? "Amount greater than available suspense"
                  : ""
              }
              OnChange={(e: any) => {
                masterSusOnchange(
                  e,
                  "Used",
                  index,
                  value.newsuspense > value.suspenseamount
                );
              }}
              Onblur={(e: any) => {
                masterSusOnBlur(
                  e,
                  "Used",
                  index,
                  value.newsuspense > value.suspenseamount
                );
              }}
            />
            {/* ) :null } */}
            {Amount === true ? (
              <label className={classes.mandatoryfield}>
                Amount must be greater than 0.
              </label>
            ) : null}
          </RACTableCell>
        </RACTableRow>
      );
    });
  };

  const suspensetransbindgrid = () => {
   
    return mastertranssuspensearray.map((value, index: any) => {
      return (
        <RACTableRow key={index} className={Payclasses.payHistbgTransparent}>
          <RACTableCell>
            <Typography variant="caption" color="primary">
              {value.agreementid}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption"> {value.schedure} </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">{value.paymentamount}</Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">
              {" "}
              {value.epoamount.toFixed(2)}{" "}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">{value.suspenseamount}</Typography>
          </RACTableCell>
          <RACTableCell>
            {/* <CurrencyInput
                type="text"
                name="checkAmount"
                className={classes.formcontrol3}
                value={addsus}
                decimalsLimit={2}
                data-testid="amountbox"
              /> */}

            {/* {AddsusText ? (
              <RACTextbox
                isCurrency={true}
                className={`${classes.custDigit} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                value={value.newsuspense || "0.00"}
                data-testid="suspensetextbox"
                type={"number"}
                name={'suspenseTxtbox'+index}
                digitFormat={"currency"}
                maxlength={10}
                OnKeydown={(e: any) => {
                  if (e.key.toLowerCase() === "delete") {
                    cashamounttextbox("0.00");
                  }
                }}

                OnChange={(e: any) => {
                  masterSusOnchange(
                    e,
                    "Use",
                    index
                  );
                }}

                Onblur={(e: any) => {
                  masterSusOnBlur(
                    e,
                    "Use",
                    index
                  );
                }}
              />
              ) :null }
            {Amount === true ? (
              <label className={classes.mandatoryfield}>
                Amount must be greater than 0.
              </label>
            ) : null} */}

            {/* {UsesusText ? ( */}

            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={value.newsuspense}
              disabled={
                mastertranssuspensearray.length === 1 &&
                Number(mastertranssuspensearray[0].suspenseamount) > 0
                  ? true
                  : false
              }
              data-testid="suspensetextbox"
              // default={"0.00"}
              // type={"number"}
              name={"suspenseTxtbox" + index}
              // digitFormat={"currency"}
              maxlength={10}
              errorMessage={
                Number(value.newsuspense) > Number(value.epoamount)
                  ? "Amount greater than epo"
                  : ""
              }
              OnChange={(e: any) => {
                masterSusOnchange(
                  e,
                  "Trans",
                  index,
                  Number(value.newsuspense) > Number(value.epoamount)
                );
              }}
              Onblur={(e: any) => {
                masterSusOnBlur(
                  e,
                  "Trans",
                  index,
                  Number(value.newsuspense) >= Number(value.epoamount)
                );
              }}
            />
            {/* ) :null } */}
            {Amount === true ? (
              <label className={classes.mandatoryfield}>
                Amount must be greater than 0.
              </label>
            ) : null}
          </RACTableCell>
        </RACTableRow>
      );
    });
  };

  const confirmTransferSuspenseBind = () => {
    return (
      <Grid item id="transatraction" aria-hidden="true">
        <Grid item md={12} className={classes.textcenter}>
          <Typography
            className={`${classes.bold} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
          >
            You are attempting to move ${newCOATotal} from suspense to
            customer's credit on account. Would you like to continue ?
          </Typography>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="secondary"
            variant="outlined"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => {
              setOpenTransferSuspense(false);
              setNewCOATotal("0.00");
              checkBoxController(0.0, "TransferUsed");
            }}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => acceptSuspenseTransfer()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  }; // SQ-4 This method will be rendering the confirmation popup before moving the suspense to COA

  const suspenserefundbindgrid = () => {
    
    return masterrefundsuspensearray.map((value, index: any) => {


      return (
        <RACTableRow key={index} className={Payclasses.payHistbgTransparent}>
          <RACTableCell>
            <Typography variant="caption" color="primary">
              {value.agreementid}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption"> {value.schedure} </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">{value.paymentamount}</Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">
              {" "}
              {value.epoamount.toFixed(2)}{" "}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption" color="primary">
              {value.suspenseamount}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            {/* <CurrencyInput
                type="text"
                name="checkAmount"
                className={classes.formcontrol3}
                value={addsus}
                decimalsLimit={2}
                data-testid="amountbox"
              /> */}

            {/* {AddsusText ? (
              <RACTextbox
                isCurrency={true}
                className={`${classes.custDigit} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                value={value.newsuspense || "0.00"}
                data-testid="suspensetextbox"
                type={"number"}
                name={'suspenseTxtbox'+index}
                digitFormat={"currency"}
                maxlength={10}
                OnKeydown={(e: any) => {
                  if (e.key.toLowerCase() === "delete") {
                    cashamounttextbox("0.00");
                  }
                }}

                OnChange={(e: any) => {
                  masterSusOnchange(
                    e,
                    "Use",
                    index
                  );
                }}

                Onblur={(e: any) => {
                  masterSusOnBlur(
                    e,
                    "Use",
                    index
                  );
                }}
              />
              ) :null }
            {Amount === true ? (
              <label className={classes.mandatoryfield}>
                Amount must be greater than 0.
              </label>
            ) : null} */}

            {/* {UsesusText ? ( */}

            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={value.newsuspense || "0.00"}
              data-testid="suspensetextbox"
              // default={"0.00"}
              // type={"number"}
              name={"suspenseTxtbox" + index}
              // digitFormat={"currency"}
              maxlength={10}
              errorMessage={
                value.newsuspense > value.suspenseamount
                  ? "Amount greater than available suspense"
                  : ""
              }
              OnChange={(e: any) => {
                masterSusOnchange(
                  e,
                  "Refund",
                  index,
                  value.newsuspense > value.suspenseamount
                );
              }}
              Onblur={(e: any) => {
                masterSusOnBlur(
                  e,
                  "Refund",
                  index,
                  value.newsuspense > value.suspenseamount
                );
              }}
            />
            {/* ) :null } */}
            {Amount === true ? (
              <label className={classes.mandatoryfield}>
                Amount must be greater than 0.
              </label>
            ) : null}
          </RACTableCell>
        </RACTableRow>
      );
    });
  };

  const BindGridCheckFunc = () => (
    <>
      {Addedloaderfunandgrid()}
      {Bindgridcheckbox()}
    </>
  );

  const findObjectByValue = (valueToFind, array) => {
    for (const object of array) {
      if (object.RSDLabel.includes(valueToFind)) {
        return object;
      }
    }
    return null;
  };

  const acceptRefundDeposit = (RDSData: any) => {
    setOpenRDS(false);
    let availableRSD: any = [...securityDepositData];
    const agreementToFind = RDSData.securityDepositAgreementNumber;
    const isAgreementAvailable = findObjectByValue(
      agreementToFind,
      availableRSD
    );
    if (isAgreementAvailable) {
      const filteredRSDData = availableRSD.filter(
        (item) => !item.RSDLabel.includes(RDSData.securityDepositAgreementNumber)
      );
      filteredRSDData.push(
        {
          RSDLabel: `${RDSData.securityDepositAgreementNumber} - Refund`,
          RSDAmount: RDSData.depositAmount,
          securityDepositAgreement: RDSData.securityDepositAgreement,
          securityDepositAgreementNumber: RDSData.securityDepositAgreementNumber,
        },
        {
          RSDLabel: `${RDSData.securityDepositAgreementNumber} - Collect`,
          RSDAmount: RDSData.amountCollected,
          securityDepositAgreement: RDSData.securityDepositAgreement,
          securityDepositAgreementNumber: RDSData.securityDepositAgreementNumber,
        }
      );
      availableRSD = filteredRSDData;
      setSecurityDepositData(filteredRSDData);
    } else {
      availableRSD.push(
        {
          RSDLabel: `${RDSData.securityDepositAgreementNumber} - Refund`,
          RSDAmount: RDSData.depositAmount,
          securityDepositAgreement: RDSData.securityDepositAgreement,
          securityDepositAgreementNumber: RDSData.securityDepositAgreementNumber,
        },
        {
          RSDLabel: `${RDSData.securityDepositAgreementNumber} - Collect`,
          RSDAmount: RDSData.amountCollected,
          securityDepositAgreement: RDSData.securityDepositAgreement,
          securityDepositAgreementNumber: RDSData.securityDepositAgreementNumber,
        }
      );
      setSecurityDepositData(availableRSD);
    }
    const copyOfMasterRSDData = [...masterRSDData];
    const masterIndex = copyOfMasterRSDData.findIndex(
      (el) => el.securityDepositAgreement === RDSData.securityDepositAgreement
    );
    copyOfMasterRSDData[masterIndex].amountCollected = RDSData.amountCollected;
    copyOfMasterRSDData[masterIndex].totalToRefund = RDSData.totalToRefund;
    copyOfMasterRSDData[masterIndex].reasonDescription =
      RDSData.reasonDescription;
    setMasterRSDData(copyOfMasterRSDData);
    editAdjustRes({ resArray: checkedAgr }, availableRSD);
  };

  //Retention Changes

  const SLACanceled = () => {
    setExchangeSelected(false);//RAC2
    setOpenSLA(false);
  };

  const SLACompleted = async () => {
    setOpenSLA(false);
    if (exchangeSelected) {//RAC2
      setExchangeSelected(false);
      setExchangeNavigationPopup(true);
    }
    else {
      setOpenOfferLoading(true);
      try {
        const retentionOffersResult = await getRetentionOffers({
          agreementNumbers: [String(choosenAgreementNumber)],
        });
        const collectedLabels: any = [];
        const constantLabels: any = [
          "Product",
          "No Longer Needs",
          "Service",
          "Moving",
        ];
        retentionOffersResult.data?.returnReasons.map((el: any) => {
          collectedLabels.push(el.reason);
        });

        if (retentionOffersResult?.data?.agreementOffers[0]?.exchangeOfferDescription && isExchangeFuncEnabled) {//RAC2
          collectedLabels.push('RAC Exchange');
        }

        setAvailableOffers([...constantLabels, ...collectedLabels]);
        if (retentionOffersResult.data) {
          setOfferResponse(retentionOffersResult.data);
        }
        setOpenRetention(true);
      } catch {
        setOfferResponse(undefined);
        setAvailableOffers(availableOffers);
        setOpenRetention(true);
      }
      setOpenOfferLoading(false);
    }
  };

  const closeRetentionPopup = async () => {
    setOpenRetention(false);
    setChoosenIndex(undefined);
    setChoosenRetentionOptions(undefined);
    setChoosenOfferReason(undefined);

    const today = new Date();
    const date = today.toISOString().split("T")[0];
    const payloadAgreementActivities: any = [];
    if (choosenOfferReason == "Total RTO/Term too high") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `TRTO high Canceled: sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRTRT",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `TRTO high Canceled: sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRTRT",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `TRTO high Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRTRTO",
        });
      }
    } else if (choosenOfferReason == "SAC too High") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `SAC high Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRSACT",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `SAC high Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRSACT",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `SAC high Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRSACTH",
        });
      }
    } else if (choosenOfferReason == "EPO too High") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `EPO high Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCREPOT",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `EPO high Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCREPOT",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `EPO high Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCREPOTH",
        });
      }
    } else if (choosenOfferReason == "Renewal rate too high") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Rate high Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRRTH",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Rate high Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRRTH",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Rate high Canceled: Not Sel Customer does not accept any offer`,
          agreementActivityType: "CANCRRTH",
        });
      }
    } else if (choosenOfferReason == "Cannot make current payment") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `make paymnt Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRCMCP",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `make paymnt Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRCMCP",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `make paymnt Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCCRCMCP",
        });
      }
    } else if (choosenOfferReason == "Service") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Service: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRSERV",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Service: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRSERV",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Service: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRSERV",
        });
      }
    } else if (choosenOfferReason == "Product") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Product: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRPROD",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Product: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRPROD",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Product: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRPROD",
        });
      }
    } else if (choosenOfferReason == "Moving") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Moving: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRMOVI",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Moving: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRMOVI",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Moving: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRMOVI",
        });
      }
    } else if (choosenOfferReason == "No Longer Needs") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `No Longer Needs: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRNLN",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `No Longer Needs: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRNLN",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `No Longer Needs: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRNLN",
        });
      }
    } else if (choosenOfferReason == 'RAC Exchange') {//RAC2
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `No Longer Needs: Sel ${el.displayLabel}`,
            agreementActivityType: "AGMXNGCAN",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `No Longer Needs: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSXNG",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `No Longer Needs: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANXNG",
        });
      }
    }
    if (payloadAgreementActivities.length) {
      try {
        const agreementActivityPayloadObj = {
          agreementActivities: payloadAgreementActivities,
        };
        await postAgreementActivity(agreementActivityPayloadObj);
      } catch {
        // No Catch
      }
    }
  };

  const recordRedirection = async () => {
    setOpenActivityLoading(true);
    const today = new Date();
    const date = today.toISOString().split("T")[0];
    const agreementActivityPayload: any = [];
    if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Payment" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "EPO"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agreement EPO Selected",
        agreementActivityType: "AEPOS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Payment" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "FREETIME"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agreement Extension Selected",
        agreementActivityType: "AEXTNS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "RR"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agreement Rate Adjustment Selected",
        agreementActivityType: "ARAS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "TR"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agreement Term Adjustment Selected",
        agreementActivityType: "ATAS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "SW"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agr Retention Switchout Selected",
        agreementActivityType: "AGMRETSWS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "S"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agr Retention Service Selected",
        agreementActivityType: "AGMRETSS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage ==
        "StoreManagement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "MOVE"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Moving Selected",
        agreementActivityType: "MS",
      });
    } else if (
      choosenRetentionOptions.displayLabel ==
      "Customer does not accept any offer"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agr Retention No Offer Selected",
        agreementActivityType: "AGMRETNONE",
      });
    } else if (
      choosenRetentionOptions.displayLabel ==
      "No retention offer option available"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agr Retention No Offer Available",
        agreementActivityType: "RETNOAVAIL",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage ==
      "Inventory"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agreement Exchange Selected",
        agreementActivityType: "AGMXNGS",
      });
    }
    const agreementActivityPayloadObj = {
      agreementActivities: agreementActivityPayload,
    };
    try {
      await postAgreementActivity(agreementActivityPayloadObj);
    } catch {
      // Exception Handle
    }
    if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Payment" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "EPO"
    ) {
      const epoDiscountAmount = choosenRetentionOptions?.offerValue?.epoDiscount
        .trim()
        .replace(/\$/g, "");
      history.push({
        pathname: `/payment1/paymentinformation/${customerId}/0`,
        search: `?CRTYPE=EPO&SRC=PAYMENT`,
        state: {
          retentionData: {
            newEpo: choosenRetentionOptions?.offerValue?.newEpo,
            epoDiscount: epoDiscountAmount,
            currentEpo: choosenRetentionOptions?.offerValue?.currentEpo,
            agreementNumber: choosenAgreementNumber,
            agreementId: choosenAgreementID,
            retentionEPOMessage: choosenRetentionOptions.displayLabel,
          },
        },
      });
      window.location.reload();
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Payment" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "FREETIME"
    ) {
      history.push({
        pathname: `/payment1/paymentinformation/${customerId}/0`,
        search: `?CRTYPE=FREETIME&SRC=PAYMENT`,
        state: {
          retentionData: {
            extentionDays: choosenRetentionOptions?.offerValue?.extension,
            agreementNumber: choosenAgreementNumber,
            agreementId: choosenAgreementID,
          },
        },
      });
      window.location.reload();
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "RR"
    ) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=RR`,
        state: {
          retentionData: {
            retentionData: offerResponse,
          },
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "TR"
    ) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=TR`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "SW"
    ) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=SW`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "R"
    ) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=R`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "S"
    ) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=S`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage ==
        "StoreManagement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "MOVE"
    ) {
      history.push({
        pathname: `/storemgmt/lookupstore`,
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage ==
        "Inventory"
    ) {
      //RAC2
      if (isExchangeFuncEnabled) {
        history.push(`/agreement/rental/itemsearch/${customerId}?isExchange=1&agreementId=${choosenAgreementID}`)
      }
      else {
        setExchangeNavigationPopup(true);
      }
    }
    setChoosenOfferReason(undefined);
    setChoosenIndex(undefined);
    setChoosenRetentionOptions(undefined);
    setOpenActivityLoading(false);
  };

  const renderRetentionModal = () => {
    return (
      <>
        <Grid container className={classes.mt2}>
          <Typography
            variant="h6"
            className={`${classes.title2} ${classes.mb1}`}
          >
            Please select why the customer wishes to return to see Customer
            Retention Offer
          </Typography>
          <Grid container spacing={2}>
            {availableRetentionOffers?.map((el: any, index: any) => {
              return (
                <Grid
                  item
                  sm={6}
                  md={3}
                  className={`${classes.mb2}`}
                  key={index}
                >
                  <Card
                    className={
                      el == choosenOfferReason
                        ? `${classes.cardTile} ${classes.payScheduleHighlight} ${classes.cursorPointer} ${classes.cardTileStyle}`
                        : `${classes.cardTile} ${classes.payScheduleNotHighlight} ${classes.cursorPointer} ${classes.cardTileStyle}`
                    }
                    onClick={() => {
                      setChoosenOfferReason(el);
                      setChoosenRetentionOptions(undefined);
                      setChoosenIndex(undefined);
                    }}
                  >
                    <CardContent>
                      <Grid container className={classes.justifyLabel}>
                        <Typography
                          className={
                            el == choosenOfferReason
                              ? classes.paySchedulePeriodHighlight
                              : classes.paySchedulePeriodNotHighlight
                          }
                          variant="body1"
                        >
                          {el}
                        </Typography>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          {choosenOfferReason ? (
            <Grid container className={`${classes.mb2}`}>
              <Card
                className={`${classes.card} ${classes.w100} ${classes.alertwidget} ${classes.mb1p}`}
              >
                <Grid
                  item
                  className={`${classes.w100} ${classes.px0} ${classes.floatLeft}`}
                >
                  <Grid container className={`${classes.px2} ${classes.h65}`}>
                    <Grid
                      className={`${classes.w100} ${classes.paymentAlignCenter1} ${classes.floatLeft}`}
                    >
                      <Grid
                        item
                        className={`${classes.raccollg6_1}  ${classes.floatLeft}`}
                      >
                        <Typography
                          component="span"
                          className={`${classes.titletxtstyle1} ${classes.bold} ${classes.pe4} ${classes.floatLeft}`}
                        >
                          Retention Option
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className={`${classes.px2}`}>
                    {retentionOptions?.map((el: any, index: any) => {
                      return (
                        <Grid
                          className={`${classes.w100} ${classes.paymentAlignCenter1} ${classes.floatLeft}`}
                          key={index}
                        >
                          <RACCheckBox
                            size="medium"
                            key={index}
                            onChange={() => {
                              if (choosenOptionIndex != index) {
                                setChoosenIndex(index);
                                setChoosenRetentionOptions(el);
                              } else {
                                setChoosenIndex(undefined);
                                setChoosenRetentionOptions(undefined);
                              }
                              //onCheckBoxClicked(index, el);
                            }}
                            checked={choosenOptionIndex == index ? true : false}
                            label={el.displayLabel}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid
                  item
                  className={`${classes.w100} ${classes.px20} ${classes.floatRight} `}
                >
                  <RACButton
                    className={`${classes.floatRight} ${classes.ms2}`}
                    color="primary"
                    variant="contained"
                    disabled={choosenRetentionOptions ? false : true}
                    onClick={() => {
                      if (
                        choosenRetentionOptions?.displayLabel ==
                        "Explain lifetime reinstatement to the customer"
                      ) {
                        setOpenRetention(false);
                        setOpenReinstateAlert(true);
                      } else {
                        recordRedirection();
                        setOpenRetention(false);
                      }
                    }}
                  >
                    Record
                  </RACButton>
                  <RACButton
                    className={`${classes.floatRight} ${classes.ms2}`}
                    color="primary"
                    variant="outlined"
                    onClick={() => closeRetentionPopup()}
                  >
                    Cancel
                  </RACButton>
                </Grid>
              </Card>
            </Grid>
          ) : null}
        </Grid>
      </>
    );
  };

  const offerLoadingRenderer = () => {
    return (
      <>
        <Grid className={`${classes.textCenter}`}>
          <CircularProgress />
          <Typography
            className={`${classes.textCenter} ${classes.bold} ${classes.mt2} `}
          >
            Fetching Retention Offers
          </Typography>
          <Typography className={`${classes.gridLabel} ${classes.mt2}`}>
            Please wait...
          </Typography>
        </Grid>
      </>
    );
  };

  const acceptReinstate = async () => {
    setOpenActivityLoading(true);
    const today = new Date();
    const date = today.toISOString().split('T')[0];

    const agreementActivityPayloadObj = {
      agreementActivities: [
        {
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: 'No Longer Needs Selected',
          agreementActivityType: 'NLNS',
        },
      ],
    };

    try {
      await postAgreementActivity(agreementActivityPayloadObj);
    } catch {
      // Exception Handle
    }
    history.push({
      pathname: `/agreement/info/details/${customerId}/${choosenAgreementID}`,
      search: `?source=AMRETENTION&type=R`,
      state: {
        retentionData: offerResponse,
      },
    });
    setOpenActivityLoading(false);
  };

  const buildReinstateAlert = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          <Typography className={`${classes.bold}`}>
            At any time you can contact your store to schedule a return of your
            product or return merchandise in person and pause your payments.
            When you are ready, you can come back, reinstate your agreement to
            get the same or comparable item, and pick up your payments right
            where you left off.
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenReinstateAlert(false);
              acceptReinstate();
            }}
          >
            Return
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenReinstateAlert(false);
              setOpenRetention(true);
            }}
          >
            Cancel
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  if (redirectToAccountManagement) {
    return (
      <Redirect
        to={{
          pathname: AppRoute.AccountManagement.replace(
            ":customerId",
            location?.state?.accountManagementCustomerId || customerId|| ""
          ),
          state: {
            ...(location?.state || {}),
            redirectSource: "payment",
          },
        }}
      />
    );
  }
  return nonInventoryPopUp == false ? (

    <Grid container data-testid="paymentfullscreen">
      {mainGridloader ||commitmentMainGridLoader? (
        <Grid className={classes.masterLoader}>
          <Grid className={classes.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      {/* unenroll autopay popup starts here */}
      <RACModalCard
        isOpen={unenrollautopayopen}
        maxWidth="sm"
        onClose={unenrollAutoPayCloseClick}
        closeIcon={true}
        /* eslint-disable react/no-children-prop */
        children={unEnrollAutoPay()}
      />

      <RACModalCard
        isOpen={openReinstateAlert}
        maxWidth="sm"
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={buildReinstateAlert()}
      />

      <RACModalCard
        isOpen={opentransferSuspense}
        maxWidth="xs"
        onClose={() => setOpenTransferSuspense(false)}
        closeIcon={false}
        borderRadius={"25px !important"}
        // eslint-disable-next-line react/no-children-prop
        children={confirmTransferSuspenseBind()}
      />
      {/* unenroll autopay popup starts here */}
      <RACModalCard
        isOpen={phoneNumberRequired}
        maxWidth="sm"
        /* eslint-disable react/no-children-prop */
        children={phoneNumberRequiredPopUp()}
      />
      <RACModalCard
        isOpen={customerzipcodenull}
        maxWidth="sm"
        /* eslint-disable react/no-children-prop */
        children={zipCodeRequiredPopUp()}
      />
      <RACModalCard
        borderRadius="20px"
        isOpen={primAddPopUp}
        maxWidth="md"
        title=""
        closeIcon={true}
        onClose={() => redirectionTopaymentsearch()}
        // eslint-disable-next-line react/no-children-prop
        children={renderPrimaryAddress()}
      />

      <RACModalCard
        isOpen={openOfferLoading}
        maxWidth="xs"
        borderRadius={"25px !important"}
        //eslint-disable-next-line react/no-children-prop
        children={offerLoadingRenderer()}
      />

      <RACModalCard
        isOpen={openRetention}
        maxWidth="xl"
        title="Customer Retention"
        borderRadius={"25px !important"}
        closeIcon={true}
        onClose={() => closeRetentionPopup()}
        /* eslint-disable react/no-children-prop */
        children={renderRetentionModal()}
      />

      {/* Exchange agreement Return Popup */}
      {
        exchangedAgreementsNumberPopup && isExchangeFuncEnabled && !finalTranSuspense && !primAddPopUp && !primaryAddressUpdated && !makingEpoWithOverSusEPO && !makingEpoWithExactSusEPO && !makingEpoWithSusEPOAmountDue && !somethingWentWrong ? 
          <ExchangeAgreementsNavigate
          value={{
            exchangeAgreementIds : exchangeAgrMapper,
            exchangedAgreementsNumberPopup : exchangedAgreementsNumberPopup,
            setExchangedAgreementsNumberPopup : setExchangedAgreementsNumberPopup
          }}
          />
        :null
      }

      {
        exchangeNavigationPopup ?
          <RACExchange value={{
            customerId: customerId,
            agreementId: choosenAgreementID,
            rentPaid: rentPaid,
            isExchangeFuncEnabled: isExchangeFuncEnabled,
            suspenseAmount : exchangeSuspenseAmount,
            exchangeAgreementNumber: currentExchangeAgreementNumber
          }} /> : null
      }

      {openSLA === true ? (
        <SecondFactor
          setTwoFactorCancelClick={SLACanceled}
          setTwoFactorCompleted={SLACompleted}
          CurrentRole={coWorkerRoleCarryWaive}
          moduleName={exchangeSelected ? 'RACExchange' : "CustomerRetention"}
        />
      ) : null}

      <RACModalCard
        borderRadius="20px"
        isOpen={primAddSavePopup}
        maxWidth="xs"
        title=""
        onClose={() => {
          setPrimAddSavePopup(false);
        }}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={renderPrimarySaveAddress()}
      />
      <RACModalCard
        borderRadius="20px"
        isOpen={primaryAddressUpdated}
        maxWidth="xs"
        title=""
        onClose={() => {
          setPrimaryAddressUpdated(false);
        }}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={primaryAddressupdatedSuccessfully()}
      />
      <RACModalCard
        isOpen={suspenseAllDelete}
        maxWidth="sm"
        /* eslint-disable react/no-children-prop */
        children={suspenseOverAllDelete()}
      />

      <RACModalCard
        isOpen={suspenseDeleteExpection}
        maxWidth="sm"
        /* eslint-disable react/no-children-prop */
        children={finalsuspenseOverallDelete()}
      />

      {/* uneroll autopay popup ends here */}
      <RACModalCard
        isOpen={autopaypopup}
        maxWidth="xs"
        onClose={AutoPayCloseClick}
        closeIcon={true}
        /* eslint-disable react/no-children-prop */
        children={autoPayPopUp()}
      />
      <RACModalCard
        isOpen={saveCardPopUp}
        maxWidth="xs"
        onClose={() => setsaveCardPopUp(false)}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={spinnerpopupSaveCard()}
      />
      <RACModalCard
        isOpen={saveCardSuccess || saveCardFail}
        maxWidth="xs"
        onClose={() => savedCardOk()}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={cardSuccessfullySaved()}
      />
            <RACModalCard
        isOpen={deleteCardPopUp}
        maxWidth="xs"
        onClose={() => setdeleteCardPopUp(false)}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={spinnerpopupDeleteCard()}
      />
      <RACModalCard
        isOpen={deleteCardSuccess || deleteCardFail}
        maxWidth="xs"
        onClose={() => deleteCardOk()}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={cardSuccessfullyDeleted()}
      />

      <RACModalCard
        isOpen={vantivError != ""}
        maxWidth="xs"
        onClose={() => setVantivError("")}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={cardFailedVantivError()}
      />

      {!(paymentOriginDisable || instoreDisable || somethingWentWrong)
        && (postalCodeZip5ErrorFlag == 0 ? true : false)
        && !primAddPopUp && !primaryAddressUpdated && showPayOrgPopup && !(exchangedAgreementsNumberPopup && isExchangeFuncEnabled)
        && mainGridloader === false && bindGridCheck === false
        && !(paygridvalues && paygridvalues.some(item =>
          item.Agreementtype === "Credit Card Charge Back" || item.Agreementtype === "NSF Check"))
        && !isCommitmentOpen
        ? (
          <PaymentOriginModal
            onClose={(onclose) => setShowPayOrgPopup(onclose)}
            onYes={(item) => {
              setCardrequire({ ...Cardrequire, PaymentOrgin: item });
              PaymentOriginPopUp();
            }}
            convFeeWithOutTax={ConvFeeWithOutTax}
          />) : null}

      <Grid item md={12}>
        <List className={`${classes.breadcrumb} ${classes.py1}`}>
          <ListItem className={classes.breadcrumbItem}>
            <a
              className={classes.breadcrumbInActive}
              style={{ cursor: "pointer" }}
              onClick={() => history.push(`/dashboard`)}
            >
              Dashboard
            </a>
          </ListItem>
          <ListItem
            className={`${classes.breadcrumbItem1} ${classes.breadcrumbArrow} ${classes.breadcrumbInActive}`}
          >
            Payment
          </ListItem>
          <ListItem
            className={`${classes.breadcrumbItemActive} ${classes.breadcrumbArrow}`}
          >
            {/* {customerName} */}
            {currentServiceFlags.isCustomerRedirection == 1 ? (
              <a
                style={{ cursor: "pointer" }}
                onClick={() => redirectionToCustomer()}
              >
                {getpayment !== undefined &&
                getpayment.paymentInfo.customerInfo.length > 0
                  ? getpayment?.paymentInfo.customerInfo[0].firstName +
                    " " +
                    getpayment?.paymentInfo.customerInfo[0].lastName +
                    " " +
                    "-" +
                    " " +
                    customerId
                  : ""}
              </a>
            ) : getpayment !== undefined &&
              getpayment.paymentInfo.customerInfo.length > 0 ? (
              getpayment?.paymentInfo.customerInfo[0].firstName +
              " " +
              getpayment?.paymentInfo.customerInfo[0].lastName +
              " " +
              "-" +
              " " +
              customerId
            ) : (
              ""
            )}
          </ListItem>
        </List>
      </Grid>
      <Card
        className={`${classes.card} ${classes.w100} ${classes.floatLeft}  ${classes.alertwidget}`}
        id="accordionExample"
      >
        <Grid item className={classes.px0}>
          <Grid
            item
            className={`${classes.w100} ${classes.floatLeft}`}
            id="headingOne"
          >
            <Grid container className={classes.px2}>
              <Grid
                className={`${classes.raccollg5} ${classes.paymentAlignCenter} ${classes.floatLeft} ${classes.borderrightstyle}`}
              >
                <Grid
                  item
                  className={`${classes.customerPaymentPadding}  ${classes.floatLeft}`}
                >
                  <Typography
                    component="span"
                    className={`${classes.titletxtstyle} ${classes.bold} ${classes.pe4} ${classes.floatLeft}`}
                  >
                    Customer Payment
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={`${classes.customerNamePadding} ${classes.floatLeft}`}
                >
                  <Box
                    component="span"
                    className={`${classes.labeltxtstyle} ${classes.w100} ${classes.floatLeft}`}
                  >
                    Customer Name
                  </Box>
                  {currentServiceFlags.isCustomerRedirection == 1 ?
                    totalRentalRevenueAmount && getpayment && configFlags.customerRewards == 1 && Number(totalRentalRevenueAmount) >= Number(configFlags.rewardsEligibilityAmount) ?
                    <>
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      <span
                        onMouseOver={() => {
                          setShowTooltip(true);
                        }}
                        onMouseOut={() => {
                          setShowTooltip(false);
                        }}
                        style={{
                          backgroundColor: '#198754',
                          color: 'white',
                          padding: '1px 7px',
                          borderRadius: '11px',
                          fontSize: '12px',
                          cursor: 'pointer'
                        }}
                        onClick={() => { history.push(`/customer/update/${customerId}/customer`) }}
                      >
                        {getpayment !== undefined &&
                          getpayment.paymentInfo?.customerInfo?.length > 0
                          ? getpayment?.paymentInfo?.customerInfo[0]?.firstName +
                          " " +
                          getpayment?.paymentInfo?.customerInfo[0]?.lastName
                          : ""}
                      </span>
                      {showTooltip && (
                        <div style={{
                          position: 'absolute',
                          bottom: '100%',
                          left: '50%',
                          transform: 'translateX(-50%)',
                          marginBottom: '5px',
                          backgroundColor: '#ECF0F3',
                          color: 'black',
                          padding: '5px',
                          borderRadius: '10px',
                          fontSize: '12px',
                          whiteSpace: 'nowrap',
                          zIndex: 2000,
                          fontWeight:'bold'
                        }}>
                          Eligible for RAC Select Rewards
                        </div>
                      )}
                    </div>
                  </>


                      : <a
                        className={`${classes.labelbluetxtstyle} ${classes.w100} ${classes.floatLeft}`}
                        onClick={() => redirectionToCustomer()}
                      >
                        {getpayment !== undefined &&
                          getpayment.paymentInfo.customerInfo.length > 0
                          ? getpayment?.paymentInfo.customerInfo[0].firstName +
                          " " +
                          getpayment?.paymentInfo.customerInfo[0].lastName
                          : ""}
                      </a>
                    : getpayment !== undefined &&
                      getpayment.paymentInfo.customerInfo.length > 0 ? (
                      getpayment?.paymentInfo.customerInfo[0].firstName +
                      " " +
                      getpayment?.paymentInfo.customerInfo[0].lastName
                    ) : (
                      ""
                    )}
                </Grid>
                <Grid
                  className={`${classes.floatLeft} ${classes.schedulePadding} ${classes.px1}`}
                >
                  <Box
                    component="span"
                    className={`${classes.labeltxtstyle} ${classes.w100} ${classes.floatLeft} ${classes.p1}`}
                  >
                    Schedule :{" "}
                    <span className={`${classes.labelnotxtstyle}`}>
                      {" "}
                      {customerdata?.data?.GetCustomer?.value
                        ?.employerReferences !== undefined
                        ? customerdata.data?.GetCustomer?.value
                            ?.employerReferences[0].employerPayscheduleDesc
                        : "-"}{" "}
                    </span>
                  </Box>
                  <Box
                    component="span"
                    className={`${classes.labeltxtstyle} ${classes.w100} ${classes.floatLeft} ${classes.p1}`}
                  >
                    Day Paid :{" "}
                    <span className={`${classes.labelnotxtstyle}`}>
                      {" "}
                      {customerdata?.data?.GetCustomer?.value
                        ?.employerReferences !== undefined
                        ? customerdata.data?.GetCustomer?.value
                            ?.employerReferences[0].daysPaidDesc == null
                          ? "-"
                          : customerdata.data?.GetCustomer?.value
                              ?.employerReferences[0].daysPaidDesc
                        : "-"}{" "}
                    </span>
                  </Box>
                </Grid>
              </Grid>
              {configFlags.isPaymentFunctionsFeatureFlag == 1 ? (
                <Grid
                  item
                  className={
                    isPendingAgrTransfer
                      ? `${classes.raccollg7} ${classes.paymentAlignCenter} ${classes.floatLeft} ${classes.disablePage}`
                      : `${classes.raccollg7} ${classes.paymentAlignCenter} ${classes.floatLeft}`
                  }
                >
                  <Grid
                    item
                    className={`${classes.raccollg7} ${classes.floatLeft} ${classes.ps2}`}
                  >
                    <Grid item className={`${classes.w100} ${classes.mt2}`}>
                      {activeAlert !== undefined &&
                      activeAlert !== null &&
                      activeAlert.length > 0 ? (
                        <React.Fragment>
                          <Typography
                            variant="h4"
                            className={`${classes.font14} ${classes.semibold} ${classes.floatLeft} ${classes.me1}`}
                          >
                            Customer Alerts
                          </Typography>
                          <Typography
                            variant="h4"
                            className={`${classes.font14} ${classes.semibold} ${classes.floatLeft}  ${classes.me4} ${classes.alertcolor}`}
                          >
                            ({activeAlert.length})
                          </Typography>
                        </React.Fragment>
                      ) : (
                        <Typography
                          variant="h4"
                          className={`${classes.semibold} ${classes.font16} ${classes.floatLeft} ${classes.me4}`}
                        >
                          Customer Alerts
                        </Typography>
                      )}
                      <Grid item className={classes.alertwidgartcontainer}>
                        {AlertMessages()}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    className={`${classes.raccollg5} ${classes.floatLeft} ${classes.ps2}`}
                  >
                    <Grid container>
                      <Grid item className={classes.raccollg4}>
                        {isAlertLoading ? (
                          <Grid
                            item
                            className={`${classes.textright} ${classes.me2} ${classes.mt2}`}
                          >
                            <CircularProgress className={classes.loaderIcon} />
                          </Grid>
                        ) : null}
                      </Grid>
                      <Grid item className={classes.raccollg6}>
                        <RACButton
                          //type="button"
                          variant="contained"
                          color="primary"
                          isRounded={false}
                          data-testid="assignAlert"
                          onClick={openAlert}
                        >
                          Assign Alert
                        </RACButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <RACModalCard
                    isOpen={AlertIsOpen}
                    maxWidth="xs"
                    title="Customer Alerts"
                    onClose={() => {
                      Cancelclick();
                      closeAlert();
                    }}
                    closeIcon={true}
                    // eslint-disable-next-line react/no-children-prop
                    children={alertPopUp()}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Card>

      {/*triagle Agreement starts here*/}
      {configFlags.isPaymentTraingleFeatureFlag == 1 &&
      configFlags.isPaymentFunctionsFeatureFlag == 1 &&
      !isPendingAgrTransfer ? (
        <CustomerInformation
          agreementAddressDetails={agreementAddressDetails}
          configDetails ={configDetails}
          // isPaymentTraingleFeatureFlag=
        />
      ) : null}

      {/*triagle Agreement starts here*/}

      <Card
        className={`${classes.card} ${classes.mt3} ${classes.w100} ${classes.floatLeft} ${classes.mb3} ${classes.alertwidget}`}
        id="accordionExample"
      >
        {/* {(suspErrLbl &&
        diffStoreErrLbl == false &&
        deliveryUnconfiremd == false) || (suspErrLbl &&
          isRevenueRecogActivated &&
          deliveryUnconfiremd == false) ? (
          <RACAlert
            AlertMessage={
              " Selected agreement has suspense amount in excess of EPO, please use SIMS for PIF/EPO payment"
            }
            variant="outlined"
            severity="error"
            // className={`${classes.mandatoryfield} ${classes.susErrorLbl}`}
          />
        ) : // />
        null} */}
        {/* {(epoErrLbl &&
        deliveryUnconfiremd == false &&
        diffStoreErrLbl == false) || (
          epoErrLbl &&
        deliveryUnconfiremd == false &&
        isRevenueRecogActivated
        )? (
          <RACAlert
            AlertMessage={
              "Selected agreement epo amount less than the amount due, please use SIMS for PIF/EPO payment"
            }
            variant="outlined"
            severity="error"
            // className={`${classes.mandatoryfield} ${classes.susErrorLbl}`}
          />
        ) : null} */}
        {diffStoreErrLbl && !isRevenueRecogActivated ? (
          <RACAlert
            AlertMessage={
              " Selected agreement for a different store. Please take a payment on the agreement in your store only"
            }
            variant="outlined"
            severity="error"
            // className={`${classes.mandatoryfield} ${classes.susErrorLbl}`}
          />
        ) : // </Typography>
        null}
        {(deliveryUnconfiremd == false &&
          unverifiedCustomer &&
          // epoErrLbl == false &&
          diffStoreErrLbl == false) ||
        (deliveryUnconfiremd == false &&
          unverifiedCustomer &&
          // epoErrLbl == false &&
          isRevenueRecogActivated) ? (
          <RACAlert
            AlertMessage={" Please verify customer information"}
            variant="outlined"
            severity="warning"
            // className={`${classes.mandatoryfield} ${classes.susErrorLbl}`}
          />
        ) : null}

        {(deliveryUnconfiremd && diffStoreErrLbl == false) ||
        (deliveryUnconfiremd && isRevenueRecogActivated) ? (
          <RACAlert
            AlertMessage={`Selected Agreement# ${String(
              agreementDeliveryUncomfirmed
            )} delivery is not confirmed. Please confirm delivery to continue with payment`}
            variant="outlined"
            severity="error"
            // className={`${classes.mandatoryfield} ${classes.susErrorLbl}`}
          />
        ) : null}
        {isCommitmentInSIMS ? (
          <RACAlert
            AlertMessage={`Commitment is taken in SIMS. Please continue the commitment payment in SIMS`}
            variant="outlined"
            severity="error"
          />
        ) : null}

        {isCommitmentBroken ? (
          <RACAlert
            className={`${classes.cmtButtonStyle}`}
            AlertMessage={BrknCmtMessage}
            variant="outlined"
            severity="error"
            handleClose={() => {
              setIsCommitmentBroken(false);
            }}
          />
        ) : null}
      </Card>

      <Grid
        item
        className={`${classes.raccollg9} ${classes.floatLeft} ${classes.px2}`}
      >
        {/*Customer Agreement starts here*/}

        <Card className={`${classes.card} ${classes.floatLeft} ${classes.mb3}`}>
          <CardContent className={classes.p0}>
            <Grid
              container
              className={isPendingAgrTransfer ? `${classes.disablePage}` : ""}
            >
              <RACTable
                className={classes.racGrid}
                renderTableHead={renderBindGridHead}
                //renderTableContent={BindGrid}
                renderTableContent={BindGridCheckFunc}
              />
            </Grid>
            <Grid item md={12} className={classes.textcenter}>
              {/**
               * Changes : set to default V2
               * Developer : Muzzamil
               * Desc: UI for set to default button
               */}
              <Grid className={`${classes.w100}`}>
                <Grid item>
                  <Grid item className={`${classes.btngroup} `} role="group">
                    <OutsideClickHandler onOutsideClick={suspenseOnblur}>
                      <List
                        style={{
                          left: "250px",
                          marginBottom: "5px",
                          borderRadius: "5px",
                        }}
                        className={suspensePopupClass}
                        data-testid="UlButtonsId"
                        aria-labelledby="btnGroupDrop1"
                        onBlur={() => suspenseOnblur()}
                      >
                        <ListItem disabled={addsuslist}>
                          <a
                            className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                            data-bs-toggle="modal"
                            data-testid="addsusId"
                            onClick={() => {
                              addsuslist ? null : addSuspensePopup();
                            }}
                            data-bs-target="#addsus"
                          >
                            Add
                          </a>
                        </ListItem>
                        <ListItem disabled={addsuslist}>
                          <a
                            className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                            data-bs-toggle="modal"
                            data-testid="addsusId"
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            onClick={() => {
                              addsuslist ? null : useSuspensePopup();
                            }}
                            data-bs-target="#addsus"
                          >
                            Use
                          </a>
                        </ListItem>
                        <ListItem disabled={disableTransfer}>
                          <a
                            className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                            data-bs-toggle="modal"
                            data-testid="addsusId"
                            data-bs-target="#addsus"
                            onClick={() => {
                              disableTransfer ? null : transferSuspensePopup();
                            }}
                          >
                            Transfer
                          </a>
                        </ListItem>
                      </List>
                    </OutsideClickHandler>
                    <RACModalCard
                      isOpen={addsuspense}
                      // isOpen={true}
                      maxWidth="md"
                      title="Add Suspense"
                      // onClose={() => addsuspensecancel()}
                      onClose={() => setaddsuspense(false)}
                      closeIcon={true}
                      // className={classes.chargeOffModalWidth2}
                      // eslint-disable-next-line react/no-children-prop
                      children={addsuspensepopup()}
                    />

                    <RACModalCard
                      isOpen={usesuspense}
                      // isOpen={true}

                      maxWidth="md"
                      title="Use Suspense"
                      onClose={() => setusesuspense(false)}
                      closeIcon={true}
                      // className={classes.chargeOffModalWidth2}
                      // eslint-disable-next-line react/no-children-prop
                      children={usesuspensepopup()}
                    />

                    <RACModalCard
                      isOpen={transuspense}
                      // isOpen={true}
                      maxWidth="md"
                      title="Transfer Suspense"
                      // onClose={() => addsuspensecancel()}
                      onClose={() => settransuspense(false)}
                      closeIcon={true}
                      // className={classes.chargeOffModalWidth2}
                      // eslint-disable-next-line react/no-children-prop
                      children={transsuspensepopup()}
                    />

                     {/**Final Payment Transfer Suspense Pop Up RAC Modal Amount Due */}
                     <RACModalCard
                      isOpen={finalTranSuspense&&!primAddPopUp&&!primaryAddressUpdated}
                      // isOpen={true}
                      maxWidth="md"
                      title="Transfer Suspense"
                      // onClose={() => addsuspensecancel()}
                      onClose={() => setFinalTranSuspense(false)}
                      closeIcon={true}
                      // className={classes.chargeOffModalWidth2}
                      // eslint-disable-next-line react/no-children-prop
                      children={finalTransSuspensePopUp()}
                    />

                     <RACModalCard
                      isOpen={makingEpoWithSusEPOAmountDue&&!primAddPopUp&&!primaryAddressUpdated}
                      // isOpen={true}
                      maxWidth="xs"
                      // title="Transfer Suspense"
                      // onClose={() => addsuspensecancel()}
                      // className={classes.chargeOffModalWidth2}
                      // eslint-disable-next-line react/no-children-prop
                      children={MakingEpoWithSusEPOAmountDuePopUp()}
                    />

                     <RACModalCard
                      isOpen={makingEpoWithExactSusEPO&&!primAddPopUp&&!primaryAddressUpdated}
                      // isOpen={true}
                      maxWidth="xs"
                      // title="Transfer Suspense"
                      // onClose={() => addsuspensecancel()}
                      // onClose={() => setFinalTranSuspense(false)}
                      // closeIcon={true}
                      // className={classes.chargeOffModalWidth2}
                      // eslint-disable-next-line react/no-children-prop
                      children={MakingEpoWithExactSusEPOPopUp()}
                    />

                    <RACModalCard
                      isOpen={makingEpoWithOverSusEPO&&!primAddPopUp&&!primaryAddressUpdated}
                      // isOpen={true}
                      maxWidth="xs"
                      // title="Transfer Suspense"
                      // onClose={() => addsuspensecancel()}
                      // onClose={() => setFinalTranSuspense(false)}
                      // closeIcon={true}
                      // className={classes.chargeOffModalWidth2}
                      // eslint-disable-next-line react/no-children-prop
                      children={MakingEpoWithOverSusEPOPopUp()}
                    />

                    <RACModalCard
                      isOpen={refundsuspense}
                      maxWidth="md"
                      title="Refund suspense"
                      onClose={() => setrefundsuspense(false)}
                      closeIcon={true}
                      children={refundpopup()}
                    />

                    <RACModalCard
                      isOpen={false}
                      maxWidth="md"
                      title="Alert popup"
                      onClose={() => setrefundsuspense(false)}
                      closeIcon={true}
                      // children={Adderrorpopup()}
                    />
                  </Grid>
                </Grid>

                {paygridvalues.length > 0 &&
                configFlags.isPaymentFunctionsFeatureFlag == 1 &&
                (configFlags.isAdjustDueDateFeatureFlag == 1 ||
                  configFlags.isAcceptEpoFeatureFlag == 1 ||
                  configFlags.isEditScheduleFeatureFlag == 1) ? (
                  <RACButton
                    className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
                    color="primary"
                    variant="contained"
                    disabled={isPendingAgrTransfer}
                    onClick={() => setTodefaultVal()}
                    // {text('ContainedButton Label', 'hover over me')}
                  >
                    Set To Default
                  </RACButton>
                ) : null}
                {adjustChargeoffFlag ? (
                  <RACButton
                    className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
                    color="primary"
                    variant="contained"
                    disabled={chargeoffadjustFlag}
                    onClick={
                      () => redirectionChargeoff()
                      // redirectionChargeoff(chargeoffInfo[0].AgreementID,"Restitution")
                    }
                    // {text('ContainedButton Label', 'hover over me')}
                  >
                    Adjust Charge Off
                  </RACButton>
                ) : null}
                {adjustRisbtnFlag ? (
                  <RACButton
                    className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
                    color="primary"
                    variant="contained"
                    disabled={adjustdisableFlag}
                    onClick={() => {
                      adjustrentalitemsaleOnclick();
                    }}
                    // {text('ContainedButton Label', 'hover over me')}
                  >
                    Adjust Rental Item Sale
                  </RACButton>
                ) : null}
                {paygridvalues.length > 0 &&
                configFlags.isAddApplyTransferSuspenseFeatureFlag == 1 &&
                selectedCheckBoxCount != 0 &&
                adjustDueDateSelectedAgre.includes("") == false &&
                configFlags.isPaymentFunctionsFeatureFlag == 1 ? (
                  <RACButton
                    className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
                    color="primary"
                    variant="contained"
                    onClick={() => suspensepopupButtonclick()}
                    disabled={suspenseButton || isPendingAgrTransfer||checkedAgr?.some((obj) => obj?.Agreementtype=='Note Payment'||obj?.Agreementtype=='PIF Note')}
                  >
                    Suspense
                    <span className={navlinkClass.dropdowntoggle}></span>
                  </RACButton>
                ) : null}
                {/* {Number(configFlags.isPaymentFunctionsFeatureFlag) == 1 &&
                selectedCheckBoxCount != 0 ? (
                  <RACButton
                    className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
                    color="primary"
                    variant="contained"
                    disabled={
                      checkedAgr.length > 0
                        ? Number(checkedAgr[0].PromoFreeDays) == 0 ||
                          Number(checkedAgr[0].PromoFreeDaysApplied) > 0
                        : false || selectedCheckBoxCount == 1
                    }
                    onClick={() => setopenPromoFreeDays(true)}
                    // {text('ContainedButton Label', 'hover over me')}
                  >
                    {"Promo Free Time"}
                  </RACButton>
                ) : null} */}
                {Number(configFlags.isPaymentFunctionsFeatureFlag) == 1 &&
                selectedCheckBoxCount != 0 &&
                (configFlags.isRegularFreeTimeAllowed == 1 ||
                  configFlags.isServiceFreeTimeAllowed == 1 ||
                  configFlags.isRegExtFreeTimeAllowed == 1 ||
                  configFlags.isPromoFreeTimeallowed == 1) ? (
                  <FreeTime
                    configFlags={configFlags}
                    customerId={customerId}
                    storeNumber={String(
                      window.sessionStorage.getItem("storeNumber")
                    )}
                    customerInformation={customerInformation}
                    openFreeTimeForRetention={openFreeTimeRetention}
                    defaultPaygridvalues={defaultPaygridvalues}
                    taxExemption={taxExemption}
                    receivedCheckedAgr={checkedAgr}
                    disableFreeTimeForTransfer={
                      isPendingAgrTransfer ||
                      (checkedAgr?.some((obj) => obj?.isZeroPayment == true) ==
                      undefined
                        ? false
                        : checkedAgr?.some((obj) => obj?.isZeroPayment == true))
                    }
                    configData={configFlags}
                    resetExtention={() => setOpenFreeTimeRetention(false)}
                    ldwChangeArr={actLDWArr}
                    policyChangeArr={actPolicyArr}
                    response={(res: any) => {
                      editAdjustRes(res);
                    }}
                    cappedFreeTimeValues = {cappedFreeTimeValues}
                    cappedFreeTimeResponseValues = {cappedFreeTimeResponseValues}
                    exceededAmount = {{setExceededAmount : setExceededAmount, exceededAmount: exceededAmount}}
                    totallyUsedFTAmount = {{totallyUsedFTAmount : totallyUsedFTAmount, setTotallyUsedFTAmount : setTotallyUsedFTAmount}}
                    usedFreeTimeAmounts = {{usedFreeTimeAmounts : usedFreeTimeAmounts, setUsedFreeTimeAmouts : setUsedFreeTimeAmouts}}
                    currentFTAmount = {{currentFTAmount: currentFTAmount, setCurrentFTAmount: setCurrentFTAmount}}
                  />
                ) : null}
                {openRDS ? (
                  <RefundDeposit
                    throwOpenRDS={openRDS}
                    throwMasterRSDData={masterRSDData}
                    closeRDS={() => setOpenRDS(false)}
                    acceptRefund={(e) => acceptRefundDeposit(e)}
                  />
                ) : null}
                {/* Added By Me */}
                {/**
                 * Changes : Adjust Due Date V2
                 * Developer : Muzzamil
                 * Desc: UI for Adjust Due Date button
                 */}

                {selectedCheckBoxCount != 0 &&
                configFlags.isAdjustDueDateFeatureFlag == 1 &&
                configFlags.isPaymentFunctionsFeatureFlag == 1 ? (
                  <RACButton
                    className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
                    color="primary"
                    variant="contained"
                    disabled={
                      isPendingAgrTransfer ||
                      disableAdjustDueDateButton ||
                      !(adjustButton == true && selectedCheckBoxCount >= 1)
                    }
                    onClick={() => setopenAdjustDueDate(true)}
                    // {text('ContainedButton Label', 'hover over me')}
                  >
                    {selectedCheckBoxCount == 1
                      ? "Adjust Due Date"
                      : "Adjust All Due Date"}
                  </RACButton>
                ) : null}
                {Number(checkedAgr?.length) == 1 &&
                checkedAgr[0]?.Agreementtype == "NSF Check" ? (
                  <RACButton
                    className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
                    color="primary"
                    variant="contained"
                    onClick={() => setNeedNSFAuth(true)}
                    // disabled={adjustDisable}
                  >
                    Adjust NSF
                  </RACButton>
                ) : null}
                {Number(checkedAgr?.length) == 1 &&
                checkedAgr[0]?.Agreementtype == "Credit Card Charge Back" ? (
                  <RACButton
                    className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
                    color="primary"
                    variant="contained"
                    onClick={() => setNeedCCCBAuth(true)}
                  >
                    Adjust CCCB
                  </RACButton>
                ) : null}
                {selectedCheckBoxCount != 0 &&
                configFlags.isAcceptEpoFeatureFlag == 1 &&
                configFlags.isPaymentFunctionsFeatureFlag == 1 ? (
                  <RACButton
                    className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
                    color="primary"
                    variant="contained"
                    onClick={() => epopopupButtonclick()}
                    disabled={
                      !showEPOGridBtn ||
                      isPendingAgrTransfer ||
                      disableEPOByEPP ||
                      (diffStoreErrLbl && isRevenueRecogActivated) ||
                      checkedAgr?.some((obj) => obj?.isZeroPayment == true) ||
                      checkedAgr?.some(
                        (obj) => obj?.Agreementtype == "Rent to Rent - Loaner" || obj?.Agreementtype == "Promo"
                      )
                    }
                  >
                    EPO
                  </RACButton>
                ) : null}
                {showEPOGridBtn && selectedCheckBoxCount != 0 ? (
                  <Grid
                    item
                    className={`${classes.btngroup} ${classes.floatLeft} ${classes.listPosition}`}
                    role="group"
                  >
                    <OutsideClickHandler onOutsideClick={epoActOnblur}>
                      <List
                        style={{
                          right: "0px",
                          marginBottom: "55px",
                          borderRadius: "5px",
                        }}
                        className={epoPopupClass}
                        // `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
                        // className={`${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`}
                        data-testid="UlButtonsId"
                        aria-labelledby="btnGroupDrop1"
                        onBlur={() => epoActOnblur()}
                      >
                        {/* {ActionState === 2 && Cardrequire.Ldwhide === false ? ( */}
                        <ListItem disabled={
                              checkedAgr?.some(
                                (obj) => obj?.agreementRateDetails?.isItemInService == "Y"
                              )
                            }
                            title={
                              checkedAgr?.some(
                                (obj) => obj?.agreementRateDetails?.isItemInService == "Y"
                          ) ? "Item in service cannot Accept EPO" : ''}>
                          <a
                            className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                            data-bs-toggle="modal"
                            data-testid="TerminateLDWId"
                            onClick={() => acceptEpoPopupOpen()}
                            aria-disabled={true}
                            data-bs-target="#terminateldw"
                            
                          >
                            Accept EPO
                          </a>
                        </ListItem>
                        {/* ) : null} */}

                        <ListItem>
                          <a
                            className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                            style={{ background: "white", color: "" }}
                            data-testid="ActiveLDWId"
                            onClick={() => epoSheduleOpen()}
                            data-bs-toggle="modal"
                            data-bs-target="#terminateldw"
                          >
                            Schedule
                          </a>
                        </ListItem>
                      </List>
                    </OutsideClickHandler>
                  </Grid>
                ) : null}
                {APUPStates.IsUP === true &&
                adjustDueDateSelectedAgre.includes("") == false &&
                configFlags.isPaymentFunctionsFeatureFlag == 1 ? (
                  //change here
                  <RACButton
                    //type="button"
                    className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
                    data-bs-toggle="modal"
                    data-testid="UnEnrollAPId"
                    color="primary"
                    variant="contained"
                    data-bs-target="#autopayconsent"
                    onClick={() => {
                      unenrollautopay();
                    }}
                    disabled={
                      checkedAgr?.some(
                        (obj) => obj?.Agreementtype == "Promo"
                      )
                    }
                  >
                    Un-Enroll Autopay
                  </RACButton>
                ) : null}
                {configFlags.isPaymentFunctionsFeatureFlag == 1 &&
                selectedCheckBoxCount > 0 &&
                APUPStates.IsUP === false ? (
                  <div
                    style={{ display: "inline" }}
                    title={
                      !(
                        APUPStates.IsAP === true &&
                        adjustDueDateSelectedAgre.includes("") == false
                      )
                        ? "Please select valid agreements to Enroll-AutoPay/Un-Enroll Autopay"
                        : ""
                    }
                  >
                    <RACButton
                      className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
                      color="primary"
                      variant="contained"
                      data-bs-toggle="modal"
                      data-bs-target="#autopayconsent"
                      disabled={
                        !(
                          APUPStates.IsAP === true &&
                          adjustDueDateSelectedAgre.includes("") == false
                        ) || checkedAgr?.some(
                          (obj) => obj?.Agreementtype == "Promo"
                        )
                      }
                      onClick={(e: any) => {
                        EnrollAutoPayment();
                      }}
                    >
                      Enroll Autopay
                    </RACButton>
                  </div>
                ) : null}
                {selectedCheckBoxCount > 0 &&
                Number(configFlags?.isRemoveAmountDue) == 1 ? (
                  <RACButton
                    className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
                    color="primary"
                    variant="contained"
                    data-bs-toggle="modal"
                    data-bs-target="#autopayconsent"
                    disabled={
                      isZeroDollarPayment ||
                      !checkedAgr.every(
                        (x) =>
                          x.Agreementtype != "Back Rent" &&
                          x.Agreementtype != "Restitution" &&
                          x.Agreementtype != "Rental Item Sale"
                      )
                    }
                    onClick={(e: any) => {
                      onClickRemoveDueAmount();
                    }}
                  >
                    Remove Amount Due
                  </RACButton>
                ) : null}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/*Customer Agreement ends here*/}
        {/*Tender type starts here*/}
        <Grid
          item
          className={`${classes.raccollg8}  ${classes.raccolmd12} ${classes.floatLeft} ${classes.mb3} ${classes.pe1}`}
        >
          <Grid
            item
            className={`${classes.w100} ${classes.floatLeft} ${classes.mb2}`}
          >
            <Typography
              variant="h6"
              className={`${classes.floatLeft} ${classes.mt2} ${classes.raccollg8} ${classes.title}`}
            >
              Tender Type
            </Typography>
            <Grid item className={`${classes.floatRight} ${classes.raccollg4}`}>
              <label
                htmlFor="ally-payday"
                className={`${classes.formLabel} ${classes.floatLeft} ${classes.raccollg6} ${classes.mt2}`}
              >
                Payment Origin
              </label>
              <div className={`${classes.raccollg6} ${classes.floatLeft}`}>
                <RACSelect
                  data-testid="PaymentOriginId"
                  options={paymentOriginarr}
                  isDisabled={
                    paymentOriginDisable ||
                    instoreDisable ||
                    isPendingAgrTransfer
                  }
                  defaultValue={paymentOrigin}
                  onChange={(e) => {
                    // if (isRevenueRecogActivated) {
                    //   phoneRevenue(e);
                    // } else {
                      phone(e);
                    // }
                  }}
                ></RACSelect>
              </div>
            </Grid>
          </Grid>
          <Card
            className={`${classes.card} ${classes.p3} ${classes.pt0} ${classes.marginbottom}`}
          >
            <Grid item className={classes.tabborder}>
              <List
                className={`${classes.RACnav} ${classes.racpadtab} ${classes.py0}`}
                id="RACTab"
                role="tablist"
              >
                <ListItem
                  className={cashtabCss}
                  id="Cash-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Cash"
                  role="tab"
                  aria-controls="Cash"
                  aria-selected="true"
                  // name="Cash"
                  onClick={(e) => {
                    // if (isRevenueRecogActivated) {
                    //   TenderTypeOptinchangeRevenue(e);
                    // } else {
                      TenderTypeOptinchange(e);
                    // }
                  }}
                  data-testid="Cashtender"
                  disabled={cashbtn || enableCashPayment ? true : false}
                >
                  Cash
                </ListItem>
                <ListItem
                  className={cardtabCss}
                  id="Card-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Card"
                  role="tab"
                  aria-controls="Card"
                  aria-selected="false"
                  data-testid="Cardtender"
                  disabled={
                    checkedAgr?.some(
                      (obj) => obj?.otherIncomeType == "Credit Card Charge Back"
                    ) == undefined
                      ? false
                      : checkedAgr?.some(
                          (obj) =>
                            obj?.otherIncomeType == "Credit Card Charge Back"
                        )
                  }
                  // name="Card"
                  onClick={(e) => {
                    if (
                      !(checkedAgr?.some(
                        (obj) =>
                          obj?.otherIncomeType == "Credit Card Charge Back"
                      ) == undefined
                        ? false
                        : checkedAgr?.some(
                            (obj) =>
                              obj?.otherIncomeType == "Credit Card Charge Back"
                          ))
                    ) {
                      // if (isRevenueRecogActivated) {
                      //   TenderTypeOptinchangeRevenue(e);
                      // } else {
                        TenderTypeOptinchange(e);
                      // }
                    }
                  }}
                >
                  Card
                </ListItem>

                <ListItem
                  className={checkMoneyOrdertabCss}
                  id="CheckMoney-Order-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#CheckMoney-Order"
                  role="tab"
                  // name="money"
                  aria-controls="CheckMoney-Order"
                  aria-selected="false"
                  onClick={(e) => {
                    // if (isRevenueRecogActivated) {
                    //   TenderTypeOptinchangeRevenue(e);
                    // } else {
                      TenderTypeOptinchange(e);
                    // }
                  }}
                  data-testid="Checktender"
                  disabled={mobtn}
                >
                  Check/Money Order
                </ListItem>
              </List>
              <Grid className={`${classes.floatRight} ${classes.mt2}`}>
                <Typography
                  variant="body2"
                  className={`${classes.RAClabeltxtstyle} ${classes.floatLeft} ${classes.semibold}`}
                >
                  Remaining Amount Due :
                </Typography>
                <Typography
                  variant="body2"
                  className={`${classes.RACvaluetxtstyle} ${classes.bold} ${classes.floatLeft}`}
                >
                  ${fixedChange(remainingamountdue)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft}`}
              id="RACTabContent"
            >
              {/*Cash tab content starts here*/}
              {cashselected === 0 ? (
                <Grid
                  item
                  id="Cash"
                  role="tabpanel"
                  data-testid="cashtabtestid"
                  aria-labelledby="Cash-tab"
                >
                  <Grid
                    item
                    className={`${classes.w100} ${classes.floatLeft} ${classes.my3} ${classes.mt4}`}
                  >
                    <Grid
                      item
                      className={`${classes.w25} ${classes.mt2} ${classes.floatLeft}`}
                    >
                      <label
                        htmlFor="a11y_takehome"
                        className={`${classes.formLabel} ${classes.mb2} ${classes.floatLeft}`}
                      >
                        Amount
                      </label>
                      <Grid className={`${classes.inputgroup} ${classes.mb3}`}>
                        <RACTextbox
                          isCurrency={true}
                          disabled={isPendingAgrTransfer}
                          className={`${classes.custDigit} ${classes.cashtxtbox}`}
                          dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                          value={addcash}
                          data-testid="amounttextbox"
                          Onblur={(e: any) => {
                            cashamounttextboxblur(e);
                          }}
                          OnChange={(e: any) => {
                            cashamounttextbox(e.target.value);
                          }}
                          OnKeydown={(e: any) => {
                            if (e.key.toLowerCase() === "delete") {
                              cashamounttextbox("0.00");
                            }
                          }}
                          type={"number"}
                          digitFormat={"currency"}
                          maxlength={10}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      className={`${classes.floatLeft} ${classes.w75} ${classes.ps4} ${classes.mt4} `}
                    >
                      <RACButton
                        className={`${classes.ps3} ${classes.pe3} ${classes.py2} ${classes.bold}${navlinkClass.paymentBtnHover}${classes.cashbuttonpadding}`}
                        data-testid="amountval1"
                        variant="outlined"
                        disabled={isPendingAgrTransfer}
                        color="primary"
                        onClick={() => {
                          setcashamount(1);
                        }}
                      >
                        {" "}
                        $ 1
                      </RACButton>
                      <RACButton
                        className={`${classes.ps3} ${classes.pe3} ${classes.py2} ${classes.bold}  ${classes.ms2} ${navlinkClass.paymentBtnHover}${classes.cashbuttonpadding}`}
                        data-testid="amountval1"
                        variant="outlined"
                        disabled={isPendingAgrTransfer}
                        color="primary"
                        onClick={() => {
                          setcashamount(5);
                        }}
                      >
                        {" "}
                        $ 5
                      </RACButton>
                      <RACButton
                        className={`${classes.ps3} ${classes.pe3} ${classes.py2} ${classes.bold} ${classes.ms2} ${navlinkClass.paymentBtnHover}${classes.cashbuttonpadding}`}
                        data-testid="amountval1"
                        variant="outlined"
                        disabled={isPendingAgrTransfer}
                        color="primary"
                        onClick={() => {
                          setcashamount(10);
                        }}
                      >
                        {" "}
                        $ 10
                      </RACButton>
                      <RACButton
                        className={`${classes.ps3} ${classes.pe3} ${classes.py2} ${classes.bold} ${classes.ms2} ${navlinkClass.paymentBtnHover}${classes.cashbuttonpadding}`}
                        data-testid="amountval1"
                        variant="outlined"
                        disabled={isPendingAgrTransfer}
                        color="primary"
                        onClick={() => {
                          setcashamount(20);
                        }}
                      >
                        {" "}
                        $ 20
                      </RACButton>
                      <RACButton
                        className={`${classes.ps3} ${classes.pe3} ${classes.py2} ${classes.bold} ${classes.ms2} ${navlinkClass.paymentBtnHover}${classes.cashbuttonpadding}`}
                        data-testid="amountval1"
                        variant="outlined"
                        disabled={isPendingAgrTransfer}
                        color="primary"
                        onClick={() => {
                          setcashamount(50);
                        }}
                      >
                        {" "}
                        $ 50
                      </RACButton>
                      <RACButton
                        className={`${classes.ps3} ${classes.pe3} ${classes.py2} ${classes.bold} ${classes.ms2} ${navlinkClass.paymentBtnHover}${classes.cashbuttonpadding}`}
                        data-testid="amountval1"
                        variant="outlined"
                        disabled={isPendingAgrTransfer}
                        color="primary"
                        onClick={() => {
                          setcashamount(100);
                        }}
                      >
                        {" "}
                        $ 100
                      </RACButton>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {cashselected === 2 ? (
                <Grid
                  item
                  id="CheckMoney-Order"
                  role="tabpanel"
                  aria-labelledby="CheckMoney-Order-tab"
                  data-testid="checkdivshow"
                >
                  <Grid
                    item
                    className={`${classes.w100} ${classes.floatLeft} ${classes.mt2}`}
                  >
                    <Grid
                      item
                      className={`${classes.raccollg12} ${classes.mt4} ${classes.mb3} ${classes.floatLeft}`}
                    >
                      <Grid item className={classes.floatRight}>
                        <RACButton
                          variant="outlined"
                          color="primary"
                          data-testid="amountval1"
                          className={`${classes.paymentSmallBtn} ${classes.mx2} ${navlinkClass.paymentBtnHover} ${classes.outlinecustombtn}`}
                          //style={{ textTransform: 'none', fontWeight: 'bolder', paddingLeft: '14px', paddingRight: '14px' }}
                          onClick={() => {
                            addcheckormoneyorder();
                          }}
                          // disable={adjustDueDateSelectedAgre.includes("")}
                          disabled={
                            selectedOnlyCashSale ||
                            isPendingAgrTransfer ||
                            adjustDueDateSelectedAgre.includes("") ||
                            checkdisable || donationBtnFlag||
                            (checkedAgr?.some(
                              (obj) =>
                                obj?.otherIncomeType ==
                                  "Credit Card Charge Back" ||
                                obj?.otherIncomeType == "NSF Check"
                            ) == undefined
                              ? false
                              : checkedAgr?.some(
                                  (obj) =>
                                    obj?.otherIncomeType ==
                                      "Credit Card Charge Back" ||
                                    obj?.otherIncomeType == "NSF Check"
                                ))
                          }
                        >
                          Add Check
                        </RACButton>
                        <RACButton
                          variant="outlined"
                          color="primary"
                          data-testid="amountval1"
                          disabled={isPendingAgrTransfer || addMoneyOrderDisable}
                          className={`${classes.paymentSmallBtn} ${navlinkClass.paymentBtnHover} ${classes.outlinecustombtn}`}
                          //style={{ textTransform: 'none', fontWeight: 'bolder', paddingLeft: '14px', paddingRight: '14px' }}
                          onClick={() => {
                            addmoneyord();
                          }}
                        >
                          Add Money Order
                        </RACButton>
                        {/* <button
                            className="btn outlined-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#check-info"
                            onClick={() => {
                              addcheckormoneyorder();
                            }}
                            data-testid="addcheckbtn"
                          >
                            Add Check
                          </button> */}
                        {/* <button
                          className="btn outlined-btn ms-2"
                          data-bs-toggle="modal"
                          data-bs-target="#MoneyOrderInformation"
                          onClick={() => {
                            addmoneyord();
                          }}
                          data-testid="addmoneyorderbtn"
                        >
                          Add Money Order
                        </button> */}
                      </Grid>
                    </Grid>
                    <Grid
                      className={`${classes.raccollg12} ${classes.floatLeft}`}
                    >
                      {/* <table className="table table-borderless table-hover racpad-table button-wrap">
                                  <thead>
                                    <tr>
                                      <th>Document Type</th>
                                      <th>Document #</th>
                                      <th>Document From</th>
                                      <th>Amount</th>
                                      <th className="text-center">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody
                                    className="dynamicgridbodyclass"
                                    data-testid="dynamicgrid"
                                  >
                                    {BindGridcheck()}
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td colSpan={2} className="text-end">
                                        Total Amount{" "}
                                        <span className="ms-3">
                                          ${totalamountcheck.toFixed(2)}
                                        </span>
                                      </td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table> */}
                      <RACTable
                        className={classes.racGrid}
                        renderTableHead={renderUnEnrollBindGridHead}
                        renderTableContent={() => BindGridcheck()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {/*Check Money Order tab content ends here*/}

              {/* <!--Card Tab starts here> */}
              {cashselected === 1 ? (
                <>
                  <Grid
                    item
                    data-testid="carddivshow"
                    id="Card"
                    role="tabpanel"
                    aria-labelledby="Card-tab"
                  >
                    {diffStoreErrLbl && isRevenueRecogActivated ? (
                      <Typography
                        component={"label"}
                        className={classes.mandatoryfield}
                      >
                        Please inform the customer that they will see multiple
                        charges on their card for this payment.
                      </Typography>
                    ) : null}
                    <Grid
                      item
                      className={`${classes.w100} ${classes.floatLeft} ${classes.my3} ${classes.mt4}`}
                    >
                      <Grid
                        item
                        className={`${classes.font16} ${classes.bold} ${classes.floatLeft}`}
                      >
                        Select Card
                      </Grid>
                      <Grid item className={classes.floatRight}>
                      {configFlags.ManageCards == 1 ? <RACButton
                          color="primary"
                          variant="outlined"
                          className={`${classes.paymentSmallBtn} ${navlinkClass.paymentBtnHover} ${classes.outlinecustombtn}`}
                          onClick={() => manageCard()}
                          data-bs-toggle="modal"
                          disabled={!(isManageCardEnabled(getpayment?.paymentInfo?.paymentInfo))}
                          data-testid="swipeId"
                        >
                          Manage Card
                        </RACButton>: null }
                        {configFlags?.swipeCustomerCard == 1 && <RACButton
                          color="primary"
                          variant="outlined"
                          className={`${classes.paymentSmallBtn} ${classes.ms2} ${navlinkClass.paymentBtnHover} ${classes.outlinecustombtn}`}
                          onClick={() => Swipecard()}
                          data-bs-toggle="modal"
                          disabled={
                            !isRevenueRecogActivated
                              ? isPendingAgrTransfer ||
                                swipeDisable ||
                                diffStoreErrLbl ||
                                // epoErrLbl ||
                                deliveryUnconfiremd ||
                                isCommitmentInSIMS
                              : isPendingAgrTransfer ||
                                swipeDisable ||
                                // epoErrLbl ||
                                deliveryUnconfiremd ||
                                isCommitmentInSIMS
                          }
                          data-testid="swipeId"
                        >
                          Swipe Card
                        </RACButton>}
                        <RACButton
                          color="primary"
                          className={`${classes.paymentSmallBtn} ${classes.mx2} ${navlinkClass.paymentBtnHover} ${classes.outlinecustombtn}`}
                          variant="outlined"
                          disabled={
                            !isRevenueRecogActivated
                              ? isPendingAgrTransfer ||
                                diffStoreErrLbl ||
                                // epoErrLbl ||
                                deliveryUnconfiremd ||
                                isCommitmentInSIMS
                              : isPendingAgrTransfer ||
                                // epoErrLbl ||
                                deliveryUnconfiremd ||
                                isCommitmentInSIMS
                          }
                          onClick={() => chargeCard()}
                          data-bs-toggle="modal"
                          data-testid="swipeId"
                        >
                          Charge Card
                        </RACButton>
                        <RACButton
                          color="primary"
                          className={`${classes.paymentSmallBtn}  ${navlinkClass.paymentBtnHover} ${classes.outlinecustombtn}`}
                          variant="outlined"
                          disabled={addNewCard}
                          onClick={() => newcard()}
                          data-bs-toggle="modal"
                          data-testid="swipeId"
                        >
                          Add New Card
                        </RACButton>
                      </Grid>
                    </Grid>

                    {/* Card total checkbox starts here */}
                    <Grid
                      item
                      className={`${classes.w100} ${classes.floatLeft}`}
                    >
                      {CardErrorLbl === true ? (
                        <Typography
                          component={"label"}
                          // id={"lbl" + index}
                          className={classes.mandatoryfield}
                        >
                          Amount should not be entered more than the remaining
                          amount
                        </Typography>
                      ) : null}
                      {GetcardMpping()}
                      {/* <Grid item className="col-md-4 mt-3 floatLeft mb-4 pe-0"></Grid> */}
                      {/*Card total checkbox display ends here*/}
                    </Grid>
                  </Grid>
                  {diffStoreErrLbl &&
                  restrictMultiCard &&
                  isRevenueRecogActivated ? (
                    <Typography
                      component={"label"}
                      className={classes.mandatoryfield}
                    >
                      Only one card payment is allowed while making payment for
                      agreement created on other store.
                    </Typography>
                  ) : null}
                  {showAddCard && !showChargeCard ? (
                    <Grid
                      style={{ float: "left", width: "100%" }}
                      id="newcard"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabIndex={-1}
                      aria-labelledby="carrywaive"
                      aria-hidden="true"
                      data-testid="NewCardTestId"
                    >
                      <Grid style={{ float: "left", width: "100%" }}>
                        <Grid style={{ float: "left", width: "40%" }}>
                          <h5
                            style={{
                              marginBottom: 20,
                              marginTop: "20px",
                              fontSize: "14px",
                            }}
                          >
                            Add Card Information
                          </h5>
                          <Grid style={{ float: "left", width: "100%" }}>
                            <Grid id="ccframe"></Grid>
                          </Grid>
                        </Grid>

                        <Grid style={{ float: "left", width: "60%" }}>
                          <h5 style={{ marginBottom: "20", fontSize: "14px" }}>
                            Billing Address
                          </h5>
                          <Grid style={{ float: "left", width: "100%" }}>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                isCurrency={false}
                                type="text"
                                name="name"
                                id="Name"
                                inputlabel="Name"
                                required={true}
                                data-testid="NameId"
                                value={Popupfields.name}
                                OnChange={(e) => initialnewcardstate(e.target)}
                              />
                              {Name_validation === true ? (
                                <Typography
                                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                                >
                                  Please enter Name
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                type="text"
                                name="address_line_1"
                                id="AddressLine1"
                                data-testid="AddressLine1Id"
                                value={Popupfields.address_line_1}
                                inputlabel="Address Line 1"
                                required={true}
                                OnChange={(e) => initialnewcardstate(e.target)}
                                isCurrency={false}
                              />
                              {Address_line1_validation === true ? (
                                <Typography
                                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                                >
                                  Please enter Address line1
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                type="text"
                                name="address_line2"
                                data-testid="AddressLine2Id"
                                id="AddressLine2"
                                inputlabel="Address Line 2"
                                value={Popupfields.address_line2}
                                OnChange={(e) => initialnewcardstate(e.target)}
                                isCurrency={false}
                              />
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                isCurrency={false}
                                type={"number"}
                                digitFormat={"normal"}
                                name="zip_code"
                                id="ZIP"
                                pattern="\d*"
                                maxlength={5}
                                value={Popupfields.zip_code}
                                data-testid="ZipCodeId"
                                inputlabel="ZIP Code"
                                required={true}
                                OnChange={(e) => initialnewcardstate(e.target)}
                              />
                              {Zip_code_validation === true ? (
                                <Typography
                                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                                >
                                  Please enter ZIP code
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                type="text"
                                name="City"
                                id="City"
                                required={true}
                                inputlabel="City"
                                value={Popupfields.city}
                                data-testid="StateId"
                                OnChange={(e) => initialnewcardstate(e.target)}
                                isCurrency={false}
                              />
                              {CityValidation === true ? (
                                <Typography
                                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                                >
                                  Please enter City
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACSelect
                                // className={classes.formcontrol} Need to update node module
                                data-testid="dropmainId"
                                options={DD}
                                defaultValue={defaultVal}
                                inputLabel="State"
                                required={true}
                                name="state"
                                onChange={(e) => dynamicState(e.target)}
                              ></RACSelect>
                              {State_validation === true ? (
                                <Typography className={classes.mandatoryfield}>
                                  Please select state
                                </Typography>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid style={{ float: "left", width: "100%" }}>
                        <div style={{ float: "right" }}>
                          <RACButton
                            className={`${classes.paymentSmallBtn1} ${classes.mx2} ${navlinkClass.paymentBtnHover}`}
                            variant="outlined"
                            color="primary"
                            name="De_ActivateClubbtn"
                            style={{
                              backgroundColor: "white",
                              textTransform: "none",
                              fontWeight: "bolder",
                              paddingLeft: "14px",
                              paddingRight: "14px",
                              color: "#2179fe",
                            }}
                            onClick={() => AddCardClose()}
                            data-bs-toggle="modal"
                          >
                            Cancel
                          </RACButton>

                          <RACButton
                            className={`${classes.paymentSmallBtn1}`}
                            color="primary"
                            variant="contained"
                            name="De_ActivateClubbtn"
                            style={{
                              backgroundColor: "#2179fe",
                              textTransform: "none",
                              fontWeight: "bolder",
                              paddingLeft: "14px",
                              paddingRight: "14px",
                              color: "white",
                            }}
                            onClick={() => vantivSubFunc()}
                            data-bs-toggle="modal"
                          >
                            Save
                          </RACButton>
                        </div>
                      </Grid>
                    </Grid>
                  ) : null}
                  {showChargeCard && !showAddCard ? (
                    <Grid
                      style={{ float: "left", width: "100%" }}
                      id="newcard"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabIndex={-1}
                      aria-labelledby="carrywaive"
                      aria-hidden="true"
                      data-testid="NewCardTestId"
                    >
                      <Grid style={{ float: "left", width: "100%" }}>
                        <Grid style={{ float: "left", width: "40%" }}>
                          <h5 style={{ marginBottom: 20, marginTop: "20px" }}>
                            Charge Card Information
                          </h5>
                          <Grid style={{ float: "left", width: "100%" }}>
                            <Grid id="chargeframe"></Grid>
                          </Grid>
                        </Grid>

                        <Grid style={{ float: "left", width: "60%" }}>
                          <Grid
                            item
                            md={12}
                            className={`${classes.mb3} ${classes.ps2}`}
                          >
                            <Grid
                              item
                              md={12}
                              className={`${classes.formLabel} ${classes.mt2}`}
                            >
                              Amount
                            </Grid>
                            <Grid item md={12} className={`${classes.mb3}`}>
                              <RACTextbox
                                isCurrency={true}
                                className={`${classes.custDigit}`}
                                value={chargeCardAmt}
                                name="total_amt"
                                id="chargetxtbox"
                                data-testid="amtTxtBoxId"
                                Onblur={(e: any) =>
                                  totalChargeAmount(e.target.value)
                                }
                                OnChange={(e: any) =>
                                  setchargeCardAmt(e.target.value)
                                }
                                OnKeydown={(e: any) => {
                                  if (e.key.toLowerCase() === "delete") {
                                    setchargeCardAmt("0.00");
                                  }
                                }}
                                type={"number"}
                                digitFormat={"currency"}
                                maxlength={10}
                                dollarTextClassName={
                                  classes.currencyDollarField
                                }
                              />
                              {Validator.chargeCardValidation === true ? (
                                <label
                                  className={classes.validationstyle}
                                  style={{
                                    visibility:
                                      Validator.chargeCardValidation === true
                                        ? "visible"
                                        : "hidden",
                                  }}
                                >
                                  Amount should not be entered more than the
                                  remaining amount
                                </label>
                              ) : null}
                            </Grid>
                          </Grid>
                          <h5 style={{ marginBottom: 20 }}>Billing Address</h5>
                          <Grid style={{ float: "left", width: "100%" }}>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                isCurrency={false}
                                type="text"
                                name="name"
                                id="Name"
                                inputlabel="Name"
                                required={true}
                                data-testid="NameId"
                                value={Popupfields.name}
                                OnChange={(e) => initialnewcardstate(e.target)}
                              />
                              {Name_validation === true ? (
                                <Typography
                                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                                >
                                  Please enter Name
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                type="text"
                                name="address_line_1"
                                id="AddressLine1"
                                data-testid="AddressLine1Id"
                                value={Popupfields.address_line_1}
                                inputlabel="Address Line 1"
                                required={true}
                                OnChange={(e) => initialnewcardstate(e.target)}
                                isCurrency={false}
                              />
                              {Address_line1_validation === true ? (
                                <Typography
                                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                                >
                                  Please enter Address line1
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                type="text"
                                name="address_line2"
                                data-testid="AddressLine2Id"
                                id="AddressLine2"
                                inputlabel="Address Line 2"
                                value={Popupfields.address_line2}
                                OnChange={(e) => initialnewcardstate(e.target)}
                                isCurrency={false}
                              />
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                isCurrency={false}
                                type={"number"}
                                digitFormat={"normal"}
                                name="zip_code"
                                id="ZIP"
                                pattern="\d*"
                                maxlength={5}
                                value={Popupfields.zip_code}
                                data-testid="ZipCodeId"
                                inputlabel="ZIP Code"
                                required={true}
                                OnChange={(e) => initialnewcardstate(e.target)}
                              />
                              {Zip_code_validation === true ? (
                                <Typography
                                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                                >
                                  Please enter ZIP code
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                type="text"
                                name="City"
                                id="City"
                                required={true}
                                inputlabel="City"
                                value={Popupfields.city}
                                data-testid="StateId"
                                OnChange={(e) => initialnewcardstate(e.target)}
                                isCurrency={false}
                              />
                              {CityValidation === true ? (
                                <Typography
                                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                                >
                                  Please enter City
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACSelect
                                // className={classes.formcontrol} Need to update node module
                                data-testid="dropmainId"
                                options={DD}
                                defaultValue={defaultVal}
                                inputLabel="State"
                                required={true}
                                name="state"
                                onChange={(e) => dynamicState(e.target)}
                              ></RACSelect>
                              {State_validation === true ? (
                                <Typography className={classes.mandatoryfield}>
                                  Please select state
                                </Typography>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid style={{ float: "left", width: "100%" }}>
                        <div style={{ float: "right" }}>
                          <RACButton
                            className={`${classes.paymentSmallBtn1} ${classes.mx2} ${navlinkClass.paymentBtnHover}`}
                            variant="outlined"
                            color="primary"
                            name="De_ActivateClubbtn"
                            style={{
                              backgroundColor: "white",
                              textTransform: "none",
                              fontWeight: "bolder",
                              paddingLeft: "14px",
                              paddingRight: "14px",
                              color: "#2179fe",
                            }}
                            onClick={() => ChargeCardClose()}
                            data-bs-toggle="modal"
                          >
                            Cancel
                          </RACButton>

                          <RACButton
                            className={`${classes.paymentSmallBtn1}`}
                            color="primary"
                            variant="contained"
                            name="De_ActivateClubbtn"
                            disabled={
                              chargeCardContinue || Number(chargeCardAmt) == 0 || chargeCardContinueButtonFlag
                            }
                            style={{
                              backgroundColor: "#2179fe",
                              textTransform: "none",
                              fontWeight: "bolder",
                              paddingLeft: "14px",
                              paddingRight: "14px",
                              color: "white",
                            }}
                            onClick={() => vantivSubFunc('Charge Card')}
                            data-bs-toggle="modal"
                          >
                            Continue
                          </RACButton>
                        </div>
                      </Grid>
                    </Grid>
                  ) : null}
                </>
              ) : null}
              {/* <!--Card Tab Ends here> */}

              {/*Check Information starts*/}
              {/* <div className="w-100 float-start "></div> */}
              <RACModalCard
                isOpen={addcheck}
                maxWidth="xs"
                title="Check Information"
                onClose={() => checkormoneyordercancel()}
                closeIcon={true}
                // eslint-disable-next-line react/no-children-prop
                children={addcheckpopup()}
              />
              {/*Check Information ends*/}
            </Grid>
          </Card>
        </Grid>

        {/*Tender type ends here*/}
        {/*Available Credit starts here*/}
        <Grid
          item
          className={`${classes.raccollg4} ${classes.floatLeft} ${classes.mb3} ${classes.px1}  ${classes.responsivelayout}`}
        >
          <AvailableCredits
            disableCred={isPendingAgrTransfer}
            availableCredits={getpayment?.paymentInfo.remainingCredits}
            balance={getpayment?.balance}
            BucketValue={amount_feild}
            throwDiffStore={false}
          ></AvailableCredits>
          {/* {
        Changes:Carry Rent
        Developer:Muzzamil
        Desc:UI for Toggle and Widget
      } */}
          {/* <RACInfoIcon
        infoTitle={carryMessage}
        placement={'left'}
        className= {carryMessage==""?classes.carryInfocolorGreen:classes.carryInfocolorRed}
        /> */}
          {configFlags.isCarryRentFeatureFlag == 1 &&
          configFlags.isPaymentFunctionsFeatureFlag == 1 &&
          isFromAccountMgmt === false ? (
            <RACToggle
              name="Carry Rent"
              GridClassName={`${classes.gridClassToggleReverse} ${classes.carryRentBottom}`}
              labelGridClassName={classes.gridClassLabel}
              checked={
                carryRentWidget &&
                selectedCheckBoxCount > 0 &&
                carryRentInitialAndFinal == false
              }
              toggleColor="#71CA2F"
              leftText={true}
              disabled={
                isPendingAgrTransfer ||
                (diffStoreErrLbl && isRevenueRecogActivated)
              }
              labelClassName={classes.switchLabel}
              leftLabel="Carry Rent"
              OnClick={(e) => {
                carryOnchange();
              }}
            />
          ) : null}

          {carryRentWidget &&
          selectedCheckBoxCount > 0 &&
          carryRentInitialAndFinal == false ? (
            <CarryRent
              donationAmountAdd={donationAmountAdd}
              totalDepositAmount={totalDepositAmount}
              totalSurrenderAmount={totalSurrenderAmount}
              totalDueSusUseAmount={totalDueSusUseAmount}
              totalDueSusAddAmount={totalDueSusAddAmount}
              totalDue={Number(totaldue) + Number(carryRentAmount)}
              totalduetendertype={totalduetendertype}
              remainingDue={remainingamountdue}
              mxcarry={checkedAgr}
              response={(res: any) => {
                carryRes(res);
              }}
              carryAmount={carryRentAmount}
              carryRentCheck={carryRentCheck}
              availableCreds={availableCreds}
              carryMax={carryMax}
              ldwChangeArr={actLDWArr}
              totalCalciTextValue={totalCalciTextValue}
              // BucketValue={amount_feild}
            ></CarryRent>
          ) : null}
          {/* {carryRentCheck ? carryCheckChange() : null} */}
        </Grid>
        {/*Available Credit ends here*/}
      </Grid>
      {/*Available Credit ends here*/}
      {/*Total due starts here*/}
      <Grid
        item
        className={`${classes.raccollg3} ${classes.floatLeft} ${classes.px1} ${classes.mb3}`}
      >
        <Grid
          item
          className={`${classes.RACpaymenttotalcontainer} ${classes.p3}`}
        >
          <Grid
            item
            className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
          >
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              <Typography
                className={`${classes.floatLeft} ${classes.totallabelstyle} ${classes.bold} ${classes.mt2}`}
              >
                TOTAL DUE
              </Typography>
              <Grid item className={classes.floatRight}>
                <Typography
                  className={`${classes.floatLeft} ${classes.totalvaluestyle} ${classes.bold} ${classes.totallabelstyle}`}
                >
                  $
                </Typography>
                <Typography
                  className={`${classes.floatLeft} ${classes.totalvaluestyle} ${classes.bold} ${classes.totallabelstyle}`}
                >
                  {" "}
                  {AddTrailingZerosToAmount(
                    Math.abs(
                      Number(totaldue) +
                        Number(carryRentAmount)+
                        Number(donationAmountAdd) +
                        Number(totalDueSusAddAmount) -
                        Number(totalDueSusUseAmount) + Number(totalDepositAmount) - Number(totalSurrenderAmount) <
                        0
                        ? 0
                        : Number(totaldue) +
                            Number(carryRentAmount)+
                            Number(donationAmountAdd) +
                            Number(totalDueSusAddAmount) -
                            Number(totalDueSusUseAmount) + Number(totalDepositAmount) - Number(totalSurrenderAmount)
                    )
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              <Typography
                className={`${classes.floatLeft} ${classes.semibold} ${classes.totalsublabelstyle} ${classes.colorwhite}`}
              >
                Total Tax
              </Typography>
              <Grid
                item
                className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
              >
                <Typography
                  className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                >
                  $
                </Typography>
                <Typography
                  className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                >
                  {totaltax.toFixed(2) === -0.0 ||
                  totaltax.toFixed(2) === "-0.00" ||
                  Number(totaltax) === 0 ||
                  totaltax === "0"
                    ? (0.0 + convtax).toFixed(2)
                    : (Number(totaltax) + convtax).toFixed(2)}
                </Typography>
                {/* totaltax.toFixed(2) */}
              </Grid>
            </Grid>
            {/**
             * Changes : Carry Rent
             * Developer:Muzzamil
             * Desc: Line item in blue panel
             */}
            {carryRentWidget &&
            selectedCheckBoxCount > 0 &&
            carryRentInitialAndFinal == false ? (
              <Grid
                item
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                <Typography
                  className={`${classes.floatLeft} ${classes.semibold} ${classes.totalsublabelstyle} ${classes.colorwhite}`}
                >
                  Carry Rent
                </Typography>

                <Grid
                  item
                  className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
                >
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    ($
                  </Typography>
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    {Number(carryRentAmount).toFixed(2) + ")"}
                  </Typography>
                  {/* totaltax.toFixed(2) */}
                </Grid>
              </Grid>
            ) : null}
            {Number(agreecarryUsed) > 0 && selectedCheckBoxCount != 0 ? (
              <Grid
                item
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                <Typography
                  className={`${classes.floatLeft} ${classes.semibold} ${classes.totalsublabelstyle} ${classes.colorwhite}`}
                >
                  Carried Rent
                </Typography>

                <Grid
                  item
                  className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
                >
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    $
                  </Typography>
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    {Number(agreecarryUsed).toFixed(2)}
                  </Typography>
                  {/* totaltax.toFixed(2) */}
                </Grid>
              </Grid>
            ) : null}
            {Number(agreecarryLateUsed) > 0 && selectedCheckBoxCount != 0 ? (
              <Grid
                item
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                <Typography
                  className={`${classes.floatLeft} ${classes.semibold} ${classes.totalsublabelstyle} ${classes.colorwhite}`}
                >
                  Carried Late Rent
                </Typography>

                <Grid
                  item
                  className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
                >
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    $
                  </Typography>
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    {Number(agreecarryLateUsed).toFixed(2)}
                  </Typography>
                  {/* totaltax.toFixed(2) */}
                </Grid>
              </Grid>
            ) : null}
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              <Typography
                className={`${classes.floatLeft} ${classes.semibold} ${classes.totalsublabelstyle} ${classes.colorwhite}`}
              >
                Total Late Fee
              </Typography>
              <Grid
                item
                className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
              >
                <Typography
                  className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                >
                  $
                </Typography>
                <Typography
                  className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                >
                  {AddTrailingZerosToAmount(Latelast10value)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {AvailCredits_Binding()}
          {WLDeposit_Bind()}
          {ConvenienceFee(paymentOrigin)}

          <Grid
            item
            className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
          >
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              <Typography
                className={`${classes.floatLeft} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite} ${classes.mt2}`}
              >
                Suspense
                {totalsuspensemaster?.length > 0 &&
                !checkedAgr?.some((obj) => obj?.isFinalTransferSusUseCancel) ? (
                  <Deleteicon onClick={() => suspenseDeleteFunc()}></Deleteicon>
                ) : null}
              </Typography>
              {suspenseitemsbind()}
            </Grid>
            {/*Bindagreementamount()*/}
          </Grid>

          <Grid
            item
            className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
          >
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              <Typography
                className={`${classes.floatLeft} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite} ${classes.mt2} `}
                style={{ marginBottom: "20px" }}
              >
                Receipt Items
              </Typography>
              {receiptitemsbind()}
            </Grid>
            {/*Bindagreementamount()*/}
          </Grid>
          {securityDepositData.length ? (
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
            >
              <Typography
                className={`${classes.floatLeft} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite} ${classes.mt2}`}
                style={{ marginBottom: "20px" }}
              >
                Security Deposit
              </Typography>
              <Grid
                item
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                {renderRSDList()}
              </Grid>
            </Grid>
          ) : null}
          <Grid
            item
            className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
          >
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              <Typography
                className={`${classes.floatLeft} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite} ${classes.mt2}`}
              >
                Tender Type
              </Typography>
            </Grid>
            {tendertypebind()}
          </Grid>
          <Grid item className={`${classes.w100} ${classes.floatLeft}`}>
            <Grid
              item
              className={`${classes.formcheckinline} ${classes.floatLeft}`}
            >
              <Grid
                item
                className={`${classes.formcheck} ${classes.ps0} ${classes.floatLeft} ${classes.me2}`}
              >
                <RadioColor
                  className={classes.floatLeft}
                  name="changeorcredit"
                  onClick={(e) => RadiobtnChange(e)}
                  disabled={creditDisable || isPendingAgrTransfer}
                  defaultValue="change"
                  checked={changeRadio}
                  /*disabled={changeenable}*/ value="change"
                  IscolorVariant={true}
                  variantColor={"#2FB452"}
                  backgroundColor={"white"}
                />
                <Box
                  component="span"
                  className={`${classes.changecredit} ${classes.ps0}`}
                >
                  Change
                </Box>
              </Grid>
              <Grid
                item
                className={`${classes.formcheck} ${classes.ps0} ${classes.floatLeft}`}
              >
                <RadioColor
                  className={classes.floatLeft}
                  name="changeorcredit"
                  onClick={(e) => RadiobtnChange(e)}
                  defaultValue="change"
                  checked={CreditRadio}
                  disabled={selectedOnlyCashSale || isPendingAgrTransfer}
                  value="credit"
                  IscolorVariant={true}
                  variantColor={"#2FB452"}
                  backgroundColor={"white"}
                />
                {suspensehide === true ? (
                  <Box
                    component="span"
                    className={`${classes.changecredit} ${classes.ps0}`}
                  >
                    Suspense
                  </Box>
                ) : (
                  <Box
                    component="span"
                    className={`${classes.changecredit} ${classes.ps0}`}
                  >
                    Credit
                  </Box>
                )}
              </Grid>
            </Grid>
            <Box
              component="span"
              className={`${classes.changecredit} ${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.mtcustom}`}
            >
              $
              <Box component="span" className={classes.changecredit}>
                {fixedChange(change)}
              </Box>
            </Box>
          </Grid>
          {configFlags.isPaymentDonation == 1 &&
           isFromAccountMgmt==false?(	
            <>	
               {donationType.donationDesc.length>30?(<Grid
            item
            className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
          >
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              <Grid>
              <Typography
                className={`${classes.floatLeft} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite} ${classes.mt2}`}
              >
                Donation
              </Typography>
              </Grid>
               <Grid>
               <Typography style={{marginTop:"10%"}} className={` ${classes.changecredit} ${classes.ps0}`}><span className={classes.bold}>{donationDescType[donationType.donationType]||""}</span> {donationType.donationDesc}</Typography>
               </Grid>     
            </Grid>
          </Grid>):(<Grid
            item
            className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
          >
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              {/* <Grid> */}
              <Typography
                className={`${classes.floatLeft} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite} ${classes.mt2}`}
              >
                Donation
              </Typography>
              {/* </Grid> */}
               {/* <Grid>
               <Typography style={{marginTop:"10%"}} className={` ${classes.changecredit} ${classes.ps0}`}>{donationType.donationDesc}</Typography>
               </Grid>      */}
            </Grid>
          </Grid>)}
          <Grid item className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}>	
            <Grid	
              container	
              className={`${classes.formcheckinline} ${classes.floatLeft}`}	
            >	
              <Grid	
                item md={8}	
                className={`${classes.formcheck} ${classes.ps0} ${classes.floatLeft} ${classes.me2}`}	
              >	
                <RACToggle	
                  GridClassName={`${classes.gridClassToggleReverse} ${classes.carryRentBottom}`}	
                  labelGridClassName={classes.gridClassLabel}	
                  checked={donationBtnFlag}	
                  toggleColor="#71CA2F"	
                  leftText={true}	
                  disabled={selectedCheckBoxCount>0 && donationType.isDonationEnabled?false:true}	
                  OnClick={(e) => {	
                    donationOnclick();	
                  }}	
                />	
                {donationType.donationDesc.length<30 ? (<Box
                // title={donationType.donationDesc}
                  component="span"
                  className={`${classes.changecredit} ${classes.ps0}`}
                >
                  {donationType.donationDesc}
                </Box>):null }	
              </Grid>	
              <Grid	
                item md={4}	
                className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} `}	
                style={{marginRight:"-6%"}}	
              >	
                <RACTextbox	
                  className={classes.custDigitN1}	
                  dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}	
                  type="number"	
                  OnChange={(e) => { donationOnchange(e) }}	
                  Onblur={(e) => { donationOnblur(e) }}	
                  value={donationAmount.amount}	
                  isCurrency={true}	
                  maxlength={10}	
                  required={false}	
                  disabled={donationttxtBoxFlag}	
                />	
              </Grid>	
            </Grid>	
          </Grid>	
          </>	
          ):null}
        </Grid>
      </Grid>

      {/*Total due ends here*/}
      {/*Footer starts here*/}
      <Grid
        item
        className={`${classes.toolbar} ${classes.mx0} ${classes.raccollg12}`}
      >
        <Grid item className={classes.floatLeft}>
          <RACButton
            onClick={() => {
              if (location?.state?.redirectSource === "am"|| window.name=="am") {
                setRedirectToAccountManagement(true);
                return;
              }
              return redirectionTopaymentsearch();
            }}
            type="button"
            color="primary"
            variant="outlined"
          >
            Cancel
          </RACButton>
        </Grid>
        <Grid item className={classes.floatRight}>
          <Grid
            item
            className={`${classes.btngroup} ${classes.floatLeft}`}
            role="group"
          >
            <OutsideClickHandler onOutsideClick={otherActOnblur}>
              <List
                style={{ marginBottom: "5px", borderRadius: "5px" }}
                className={PopupClass}
                data-testid="UlButtonsId"
                aria-labelledby="btnGroupDrop1"
                onBlur={() => otherActOnblur()}
              >
                {ActionState === 2 &&
                showLDWPolicy == true &&
                Cardrequire.Ldwhide === false &&
                selectedCheckBoxCount == 1 &&
                checkedAgr[0].Nextduedate!="EPO"&&
                checkedAgr[0].Nextduedate!="SAC"&&
                checkedAgr[0].Agreementtype!="Promo"&&
                checkedAgr[0].Nextduedate!="PIF"? (
                  <ListItem>
                    <a
                      className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                      data-bs-toggle="modal"
                      data-testid="TerminateLDWId"
                      onClick={() => TerminateLDWbtnPopup()}
                      data-bs-target="#terminateldw"
                    >
                      Terminate LDW
                    </a>
                  </ListItem>
                ) : null}
                {configFlags.isPaymentHistoryFeatureFlag == 1 &&
                configFlags.isPaymentFunctionsFeatureFlag == 1 ? (
                  <ListItem disabled={payhisflag}>
                    <a
                      className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                      data-bs-toggle="modal"
                      data-testid="paymenthistory"
                      onClick={() => {
                        payhisflag ? null : HistorybtnPopup();
                      }}
                      data-bs-target="#terminateldw"
                    >
                      Payment History
                    </a>
                  </ListItem>
                ) : null}

                {ActionState === 1 &&
                showLDWPolicy == true &&
                Cardrequire.Ldwhide === false &&
                selectedCheckBoxCount == 1 &&
                checkedAgr[0].Nextduedate!="EPO"&&
                checkedAgr[0].Nextduedate!="SAC"&&
                checkedAgr[0].Agreementtype!="Promo"&&
                checkedAgr[0].Nextduedate!="PIF" ? (
                  <ListItem>
                    <a
                      className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                      style={{ background: "white", color: "" }}
                      data-testid="ActiveLDWId"
                      data-bs-toggle="modal"
                      onClick={() => ActivateLDWbtnPopup()}
                      data-bs-target="#terminateldw"
                    >
                      Activate LDW
                    </a>
                  </ListItem>
                ) : null}
                {/**
                 *  changes: Edit Schedule V2
                 *  Developer : Muzzamil
                 *  Desc : UI for edit schedule
                 */}
                {/* {? ( */}
                {configFlags.isEditScheduleFeatureFlag == 1 &&
                configFlags.isPaymentFunctionsFeatureFlag == 1 ? (
                  <ListItem
                    disabled={
                      !(
                        editButton == true &&
                        selectedCheckBoxCount >= 1 &&
                        disableAdjustDueDateButton == false
                      )
                    }
                  >
                    <a
                      className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                      data-bs-toggle="modal"
                      data-testid="TerminateLDWId"
                      onClick={() => editSchedule()}
                      data-bs-target="#terminateldw"
                      aria-disabled={true}
                    >
                      Edit Schedule
                    </a>
                  </ListItem>
                ) : null}

                {/* ) : null} */}
                {policyService_state === 1 &&
                showLDWPolicy == true &&
                selectedCheckBoxCount == 1 &&
                Cardrequire.Policyhide === false &&
                checkedAgr[0].Nextduedate!="EPO"&&
                checkedAgr[0].Nextduedate!="SAC"&&
                checkedAgr[0].Agreementtype!="Promo"&&
                checkedAgr[0].Nextduedate!="PIF" ? (
                  <ListItem>
                    <a
                      className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                      data-bs-toggle="modal"
                      data-testid="ActivatePolicyId"
                      onClick={() => Activepolicypopup()}
                      data-bs-target="#terminateldw"
                    >
                      Activate Policy
                    </a>
                  </ListItem>
                ) : null}

                {policyService_state !== 1 &&
                showLDWPolicy == true &&
                selectedCheckBoxCount == 1 &&
                Cardrequire.Policyhide === false &&
                checkedAgr[0].Nextduedate!="EPO"&&
                checkedAgr[0].Nextduedate!="SAC"&&
                checkedAgr[0].Agreementtype!="Promo"&&
                checkedAgr[0].Nextduedate!="PIF" ? (
                  <ListItem>
                    <a
                      className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                      data-testid="DeactivatePolicybtnId"
                      style={{ background: "white" }}
                      data-bs-toggle="modal"
                      onClick={() => DeActivepolicypopup()}
                      data-bs-target="#terminateldw"
                    >
                      Deactivate Policy
                    </a>
                  </ListItem>
                ) : null}
                {Number(configFlags?.carryWaveLateFee) == 1 &&
                Number(configFlags?.optimizedPayment) == 1 ? (
                  <ListItem
                    disabled={
                      checkedAgr.length == 1 &&
                      checkedAgr[0]?.constLateFee > 0 &&
                      !NOT_ALLOWED_AGREEMENT_TYPE.includes(checkedAgr[0]?.Agreementtype)
                      // !FINAL_PAYMENT_AGREEMENT.includes(checkedAgr[0]?.Nextduedate)
                      ? // (isRevenueRecogActivated && diffStoreErrLbl)
                      false
                      : true
                    }
                  >
                    <a
                      className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                      data-bs-toggle="modal"
                      data-testid="TerminateLDWId"
                      onClick={() =>
                        Number(checkedAgr[0].constLateFee > 0) &&
                        checkedAgr.length == 1 &&
                        !NOT_ALLOWED_AGREEMENT_TYPE.includes(checkedAgr[0]?.Agreementtype)
                        // !FINAL_PAYMENT_AGREEMENT.includes(checkedAgr[0]?.Nextduedate)
                        ? setNeedCarryWaveAuth(true)
                        : null
                      }
                      data-bs-target="#terminateldw"
                      //aria-disabled={true}
                    >
                      Carry/Waive Late Fee
                    </a>
                  </ListItem>
                ) : // <ListItem
                //   disabled={
                //     (checkedAgr.length == 1 &&
                //     checkedAgr[0]?.constLateFee > 0 &&
                //     checkedAgr[0]?.Nextduedate != "SAC" &&
                //     checkedAgr[0]?.Nextduedate != "EPO" &&
                //     checkedAgr[0]?.Nextduedate != "PIF" &&!diffStoreErrLbl)
                //       ? false
                //       : true
                //   }
                // >
                //   <a
                //     className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                //     data-bs-toggle="modal"
                //     data-testid="TerminateLDWId"
                //     onClick={() =>
                //       (Number(checkedAgr[0].constLateFee > 0) &&
                //       checkedAgr.length == 1 &&
                //       checkedAgr[0]?.Nextduedate != "SAC" &&
                //       checkedAgr[0]?.Nextduedate != "EPO" &&
                //       checkedAgr[0]?.Nextduedate != "PIF"&&!diffStoreErrLbl)
                //         ? setNeedCarryWaveAuth(true)
                //         : null
                //     }
                //     data-bs-target="#terminateldw"
                //     //aria-disabled={true}
                //   >
                //     Waive Late Fee
                //   </a>
                // </ListItem>
                null}
              </List>
            </OutsideClickHandler>

            {/* {otherActionSpinner === true ? (
              <CircularProgress
                className={classes.alertLoader}
              ></CircularProgress>
            ) : null} */}

            {/* {OtherActionHidden === false ? */}
            {Number(configFlags.isPaymentFunctionsFeatureFlag) == 1 ? (
              <RACButton
                //className={`${classes.ms2} ${classes.mt1}`}
                color="primary"
                variant="contained"
                disabled={isPendingAgrTransfer}
                onClick={() => popupButtonclick()}
                // onBlur={()=>otherActOnblur()}
                loading={otherActionSpinner}
                hidden={OtherActionHidden}
              >
                Other Actions
                <span className={navlinkClass.dropdowntoggle}></span>
              </RACButton>
            ) : null}
            {/* {risRemovepaymentbtnFlag==true?(
            <RACButton
            className={classes.ms2}
            color="primary"
            variant="contained"
            name="De_ActivateClubbtn"
            onClick={() =>removepaymentrisOnclick()}
            data-bs-toggle="modal"
            disabled={risRemovepaymentdisableFlag}
            
          >
            Remove Payment
          </RACButton>
          ):null} */}
            {/* : null} */}
          </Grid>
          {/**
           * Changes: Cash Sale
           * Developer : Muzzamil
           * Desc: UI for other Income
           */}
          <Grid
            item
            className={`${classes.btngroup} ${classes.floatLeft}`}
            role="group"
          >
            <OutsideClickHandler onOutsideClick={() => otherIncomeOnblur()}>
              <List
                style={{ marginBottom: "5px", borderRadius: "5px" }}
                className={otherIncomePopupClass}
                data-testid="UlButtonsId"
                aria-labelledby="btnGroupDrop1"
                onBlur={() => otherIncomeOnblur()}
              >
                {Number(configFlags?.isCashSaleFeatureFlag) == 1 ? (
                  <ListItem>
                    <a
                      className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                      data-testid="DeactivatePolicybtnId"
                      style={{ background: "white" }}
                      data-bs-toggle="modal"
                      // onClick={() => nonInventoryOnClick({
                      //   type:"Cell Phone Activarion",
                      //   Description:"Net10 $35 Plan",
                      //   Amount:"38.60"
                      // })}
                      onClick={() => setnonInventoryPopUp(true)}
                      data-bs-target="#terminateldw"
                    >
                      Non-Inventory Sale
                    </a>
                  </ListItem>
                ) : null}
                {Number(configFlags?.isChargeOffSkipStolen) == 1 ? (
                  <ListItem
                    disabled={chargeoffInfo?.length == 0 ? true : false}
                  >
                    <a
                      className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                      data-testid="DeactivatePolicybtnId"
                      style={{ background: "white" }}
                      data-bs-toggle="modal"
                      // disabled={chargeoffInfo.EPOamountOwed=="0.00"||chargeoffInfo.EPOamountOwed==""?true:false}
                      onClick={() =>
                        chargeoffInfo?.length == 0
                          ? setchargeoffPopupFlag(false)
                          : setchargeoffPopupFlag(true)
                      }
                      // hidden={chargeoffInfo.EPOamountOwed=="0.00"||chargeoffInfo.EPOamountOwed==""?true:false}
                      data-bs-target="#terminateldw"
                    >
                      Charge-Off Skip/Stolen Payment
                    </a>
                  </ListItem>
                ) : null}
                {Number(configFlags?.isRentalItemSaleFlag) == 1 ? (
                  <ListItem
                  // disabled={Number(paymentFunctionFlag.payFunc)==0?true:false}
                  >
                    <a
                      className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                      data-testid="DeactivatePolicybtnId"
                      style={{ background: "white" }}
                      data-bs-toggle="modal"
                      // disabled={chargeoffInfo.EPOamountOwed=="0.00"||chargeoffInfo.EPOamountOwed==""?true:false}
                      onClick={
                        () =>
                          // Number(paymentFunctionFlag.payFunc)!=0?
                          history.push({
                            pathname: "/payment1/rentalitemsale",
                            state: {
                              Tax: 0.0825,
                              customerID:
                                customerdata.data?.GetCustomer?.value
                                  ?.customerId,
                              customerName: `${customerdata.data?.GetCustomer?.value?.firstName} ${customerdata.data?.GetCustomer?.value?.lastName}`,
                              editNo: 0,
                            },
                          })
                        // :null
                      }
                      // hidden={chargeoffInfo.EPOamountOwed=="0.00"||chargeoffInfo.EPOamountOwed==""?true:false}
                      data-bs-target="#terminateldw"
                    >
                      Rental Item Sale
                    </a>
                  </ListItem>
                ) : null}
                {Number(configFlags?.isBackRentFlag) == 1 ? (
                  <ListItem disabled={Number(backRentInfo.amount) == 0}>
                    <a
                      className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                      data-testid="DeactivatePolicybtnId"
                      style={{ background: "white" }}
                      data-bs-toggle="modal"
                      id="BackRentId"
                      // onClick={() => nonInventoryOnClick({
                      //   type:"Cell Phone Activarion",
                      //   Description:"Net10 $35 Plan",
                      //   Amount:"38.60"
                      // })}
                      onClick={() =>
                        Number(backRentInfo.amount) != 0
                          ? setBackRentPopUp(true)
                          : null
                      }
                      data-bs-target="#terminateldw"
                      //
                      //backRentPopUpOpen()
                      //backRentInfo.amount == "0.00"? true:false
                      //disableBackRentBtn
                      //  aria-disabled={backRentInfo.amount == "0.00"}
                    >
                      Back Rent
                    </a>
                  </ListItem>
                ) : null}
              </List>
            </OutsideClickHandler>
            {/* {otherActionSpinner === true ? (
          <CircularProgress
            className={classes.alertLoader}
          ></CircularProgress>
        ) : null} */}
            {/* {OtherActionHidden === false ?  */}

            {/* {isFromAccountMgmt === false &&
            (Number(configFlags.isCashSaleFeatureFlag) == 1 ||
              Number(configFlags.isRentalItemSaleFlag) == 1) ? (
              <RACButton
                className={classes.ms2}
                color="primary"
                variant="contained"
                disabled={isPendingAgrTransfer}
                onClick={() => otherIncomepopupButtonclick()}
                // onBlur={()=>otherIncomeOnblur()}
                hidden={OtherIncomeHidden}
              >
                Other Income
                <span className={navlinkClass.dropdowntoggle}></span>
              </RACButton>
            ) : null} */}
            {isFromAccountMgmt === false ? (
              <RACButton
                onClick={() => {
                  setOpenOtherIncome(true);
                }}
                className={classes.ms2}
                type="button"
                color="primary"
                variant="contained"
              >
                Other Income
              </RACButton>
            ) : null}
            {configFlags.isActivateClub == 1 &&
            configFlags.isPaymentFunctionsFeatureFlag == 1 ? (
              <RACButton
                className={classes.ms2}
                color="primary"
                variant="contained"
                onClick={() =>
                  clubStatusHandleChange(clubStatus == "A" ? "P" : "C")
                }
                disabled={isclubOnSameStore || isPendingAgrTransfer}
              >
                {clubStatus == "P"
                  ? "Club is pending"
                  : clubStatus == "D"
                  ? "Deactivate club"
                  : "Activate club"}
              </RACButton>
            ) : null}
            {/* } */}
          </Grid>
          {isFromAccountMgmt === false ? (
            <RACButton
              className={classes.ms2}
              color="primary"
              variant="contained"
              name="De_ActivateClubbtn"
              onClick={() => txt2paybtn()}
              data-bs-toggle="modal"
              disabled={
                isPendingAgrTransfer ||
                (paygridvalues.length > 0 &&
                // epoErrLbl == false &&
                diffStoreErrLbl == false &&
                tendertypeglobalarray.length == 0
                  ? false
                  : true) ||
                deliveryUnconfiremd ||
                isCommitmentInSIMS ||
                selectedCheckBoxCount == 0 ||
                adjustDueDateSelectedAgre.includes("")
              }
            >
              Txt2Pay
            </RACButton>
          ) : null}

          {/*
           *Changes made for - Payment History popup
           *Changes made by  - syed azimudeen
           */}

          {PaymentHistory ? (
            <DeliverySlots
              name="test"
              agreementPayloadDecider={agreementPayloadDecider}
              hasMoreReceipt={hasMoreReceipt}
              scrollLoader={scrollLoader}
              getReceiptWiseAllData={() => getReceiptWiseAllData()}
              getAgreementWiseAllData={(el) => getAgreementWiseAllData(el)}
              ClubGridInfoResp={ClubGridInfoResp}
              clubActivityInfo={clubActivityInfo}
              clubInfo={clubinformation}
              disable={payclubflag}
              customerId={customerId}
              emailId={getpayment.paymentInfo.customerInfo[0].emailAddress}
              paymenthistorygrid={paymentHisGrid}
              agreementRentPaid={agreementRentPaid}
              uniqueAgreementID={uniqueAgreementID}
              commoninformation={commoninformation}
              openModal={PaymentHistory}
              closePaymentHistoryPopup={(onClose: any) => {
                setPaymentHistory(onClose);
              }}
              response={(res: any) => {
                paymentHistoryClose(res);
              }}
              redirect={(response: any) => {
                history.push(response);
              }}
            />
          ) : null}

          {/*
           *Changes made for - Payment Club payment history popup
           *Changes made by  - syed azimudeen
           */}

          {ClubPaymentHistory ? (
            <ClubPayment
              getClubWiseAllData={() => getClubWiseAllData()}
              customerData={customerdata}
              hasMoreClubRcp={hasMoreClubRcp}
              scrollLoader={scrollLoader}
              activateClubFeatureFlag={configFlags.isActivateClub}
              openModal={ClubPaymentHistory}
              paygridvalues={paygridvalues}
              ClubGridInfoResp={ClubGridInfoResp}
              clubActivityInfo={clubActivityInfo}
              clubinfo={clubinformation}
              paymenthistorygrid={paymentHisGrid}
              uniqueAgreementID={uniqueAgreementID}
              commoninformation={commoninformation}
              closeClubPaymentHistoryPopup={(onClose: any) => {
                setClubPaymentHistory(onClose);
              }}
              response={(res: any) => {
                clubpaymentHistoryClose(res);
              }}
              redirect={(response: any) => {
                history.push(response);
              }}
            />
          ) : null}
          {isFromAccountMgmt === false &&
          configFlags.optimizedPayment == "0" ? (
            <Payment
              totalsuspensemaster={totalsuspensemaster}
              configFlags={configFlags}
              changeRadio={changeRadio}
              CreditRadio={CreditRadio}
              suspensehide={suspensehide}
              storeMerchantId={storeMerchantId}
              availableCredsremaining={availableCredsremaining}
              activateClubFeatureFlag={configFlags.isActivateClub}
              clubStatus={clubStatus}
              customerData={customerdata}
              ConvFeeWithOutTax={ConvFeeWithOutTax}
              tenderTypeArray={totalduereceiptid}
              carryRent={carryRentAmount}
              clubCheckDisabled={clubCheckDisabled}
              chargeCard={chargeCardArray}
              cardToken={cardToken}
              taxGeoCode={
                paygridvalues[0] != undefined ? paygridvalues[0].taxGeoCode : ""
              }
              taxZip={
                paygridvalues[0] != undefined
                  ? paygridvalues[0].zipPlus4UsedForTax
                  : ""
              }
              convTax={paygridvalues[0] == undefined ? 0 : convtax}
              clubActivate={clubActivate}
              origin={Cardrequire.PaymentOrgin}
              profileAccResp={profileAccResp}
              checkDetail={adddataanother}
              cardDetail={cardDetails}
              lastFour={cardLastFour}
              ClubPayFlag={checkedAgr}
              cardAuthNumber={cardAuthNo}
              billingAddressCharge={chargeBillInfo}
              coworkerId={empId}
              actLDWArr={actLDWArr}
              actPolicyArr={actPolicyArr}
              swipeFlag={swipeFlag}
              finalagr={finalagr}
              paygridvalues={paygridvalues}
              disabled={
                isPendingAgrTransfer ||
                (diffStoreErrLbl ? true : Acceptpaymentbuttonenable) ||
                deliveryUnconfiremd ||
                CardErrorLbl ||
                isCommitmentInSIMS ||
                selectedCheckBoxCount == 0
              }
              setAcceptpaymentbuttonenable={setAcceptpaymentbuttonenable}
              cashamountcheckbox={cashamountcheckbox}
              getResponse={acceptPayment}
              TotalchkMoney={chkMoney}
              WidgetRadioBtn={radioBtn}
              Taxfield={getpayment}
              Totaldue={Number(totaldue) + Number(carryRentAmount)}
              GetPaymentVal={AgreementSelect}
              AcceptPay={AcceptPayments}
              ClubPay={AcceptclubPaymentParam}
              Reciept={ReceiptCategory}
              AgreementIdProp={agreementId}
              setpaymentMade={setpaymentMade}
              setpaymentMadeOptimized={(response:any)=>setpaymentMadeOptimized(response)}
              CustomerIdProp={customerId}
              Paymentchange={change}
              PayOrigin={paymentOrigin}
              avaliableCredits={availableCreds}
              getCardstate={getCardService_state}
              customerId={customerId}
              COAamountnum={coaamt}
              weblead={wldepositamount}
              Integratedsus={ipsuspense}
              Suspense={suspenseAmount}
              swipeCardAmt={swipeamt}
              isOtherStoreAgrSelected={diffStoreErrLbl}
              throwtakeCommitmentData={takeCommitmentData}
              checkedAgr={checkedAgr}
              securityDepositData={securityDepositData}
              convAmtWTax={convAmtWTax}
            />
          ) : isFromAccountMgmt === false &&
            configFlags.optimizedPayment == "1" &&
            configFlags.RevenueRecognition == "0" ? (
            <ProcessPayment
             donationBtnFlag={donationBtnFlag}
             convAmtWTax={convAmtWTax}
             tendertypeglobalarray={tendertypeglobalarray}
             userTracePayload={userTracePayload}
              totalsuspensemaster={totalsuspensemaster}
              customerGCID={customerGCID}
              configFlags={configFlags}
              changeRadio={changeRadio}
              CreditRadio={CreditRadio}
              suspensehide={suspensehide}
              storeMerchantId={storeMerchantId}
              availableCredsremaining={availableCredsremaining}
              activateClubFeatureFlag={configFlags.isActivateClub}
              clubStatus={clubStatus}
              customerData={customerdata}
              ConvFeeWithOutTax={ConvFeeWithOutTax}
              tenderTypeArray={totalduereceiptid}
              carryRent={carryRentAmount}
              clubCheckDisabled={clubCheckDisabled}
              chargeCard={chargeCardArray}
              cardToken={cardToken}
              taxGeoCode={
                paygridvalues[0] != undefined ? paygridvalues[0].taxGeoCode : ""
              }
              taxZip={
                paygridvalues[0] != undefined
                  ? paygridvalues[0].zipPlus4UsedForTax
                  : ""
              }
              convTax={paygridvalues[0] == undefined ? 0 : convtax}
              clubActivate={clubActivate}
              origin={Cardrequire.PaymentOrgin}
              profileAccResp={profileAccResp}
              checkDetail={adddataanother}
              cardDetail={cardDetails}
              lastFour={cardLastFour}
              ClubPayFlag={checkedAgr}
              cardAuthNumber={cardAuthNo}
              billingAddressCharge={chargeBillInfo}
              coworkerId={empId}
              actLDWArr={actLDWArr}
              actPolicyArr={actPolicyArr}
              swipeFlag={swipeFlag}
              finalagr={finalagr}
              paygridvalues={paygridvalues}
              disabled={
                isPendingAgrTransfer ||
                (diffStoreErrLbl ? true : Acceptpaymentbuttonenable) ||
                deliveryUnconfiremd ||
                CardErrorLbl ||
                isCommitmentInSIMS ||
                selectedCheckBoxCount == 0 ||
                (checkedAgr.every((el)=>el.store_number!=String(window.sessionStorage.getItem("storeNumber")))&& clubActivate==0?true:false)
              }
              setAcceptpaymentbuttonenable={setAcceptpaymentbuttonenable}
              cashamountcheckbox={cashamountcheckbox}
              getResponse={acceptPayment}
              TotalchkMoney={chkMoney}
              WidgetRadioBtn={radioBtn}
              Taxfield={getpayment}
              Totaldue={Number(totaldue) + Number(carryRentAmount)}
              GetPaymentVal={AgreementSelect}
              AcceptPay={AcceptPayments}
              ClubPay={AcceptclubPaymentParam}
              Reciept={ReceiptCategory}
              AgreementIdProp={agreementId}
              setpaymentMade={setpaymentMade}
              setpaymentMadeOptimized={(response:any)=>setpaymentMadeOptimized(response)}
              CustomerIdProp={customerId}
              Paymentchange={change}
              PayOrigin={paymentOrigin}
              avaliableCredits={availableCreds}
              getCardstate={getCardService_state}
              customerId={customerId}
              COAamountnum={coaamt}
              weblead={wldepositamount}
              Integratedsus={ipsuspense}
              Suspense={suspenseAmount}
              swipeCardAmt={swipeamt}
              //throwCheckedAgreement={checkedAgr}
              selectedAgreements={checkedAgr}
              throwtakeCommitmentData={takeCommitmentData}
              checkedAgr={checkedAgr}
              donationAmount={donationAmount}
              donationType={donationType}
              securityDepositData={securityDepositData}
              billingAddressValidation={billingAddressValidation}
              isOtherStoreAgrSelected={diffStoreErrLbl}
              exceededFTAmount={exceededAmount}
              setExceededFreeTime={setExceededAmount}
              cappedFreeTimeValues={cappedFreeTimeValues}
              cappedFreeTimeResponseValues={cappedFreeTimeResponseValues}
              currentRole={currentRole}
              showAutoPayPopup={EnrollAutoPayment}
              exchangeAgreementNumber = {exchangeAgreementNumber}
              isAutoPayEnabledAgreement = {isAutoPayEnabledAgreement}
              setIsAutoPayEnabledAgreement = {setIsAutoPayEnabledAgreement}
              pendingAgreements = {pendingAgreements}
              exchangeAgreementNumberMapper = {exchangeAgreementNumberMapper}
              isExchangeAgreementIndicator = {isExchangeAgreementIndicator}
              parentAgreementIdMapper = {parentAgreementIdMapper}
              isExchangeFuncEnabled = {isExchangeFuncEnabled}
              autoPayEnrolledAgreement = {autoPayEnrolledAgreement}
              setExchangeAgreementNumber = {setExchangeAgreementNumber}
            />
          ) : isFromAccountMgmt === false &&
            configFlags.optimizedPayment == "1" &&
            configFlags.RevenueRecognition == "1" ? (
            <ProcessPayment
              donationBtnFlag={donationBtnFlag}
              convAmtWTax={convAmtWTax}
              tendertypeglobalarray={tendertypeglobalarray}
              userTracePayload={userTracePayload}
              totalsuspensemaster={totalsuspensemaster}
              configFlags={configFlags}
              changeRadio={changeRadio}
              CreditRadio={CreditRadio}
              suspensehide={suspensehide}
              storeMerchantId={storeMerchantId}
              availableCredsremaining={availableCredsremaining}
              activateClubFeatureFlag={configFlags.isActivateClub}
              clubStatus={clubStatus}
              customerData={customerdata}
              ConvFeeWithOutTax={ConvFeeWithOutTax}
              tenderTypeArray={totalduereceiptid}
              carryRent={carryRentAmount}
              clubCheckDisabled={clubCheckDisabled}
              chargeCard={chargeCardArray}
              cardToken={cardToken}
              taxGeoCode={
                paygridvalues[0] != undefined ? paygridvalues[0].taxGeoCode : ""
              }
              taxZip={
                paygridvalues[0] != undefined
                  ? paygridvalues[0].zipPlus4UsedForTax
                  : ""
              }
              convTax={paygridvalues[0] == undefined ? 0 : convtax}
              clubActivate={clubActivate}
              origin={Cardrequire.PaymentOrgin}
              profileAccResp={profileAccResp}
              checkDetail={adddataanother}
              cardDetail={cardDetails}
              lastFour={cardLastFour}
              ClubPayFlag={checkedAgr}
              cardAuthNumber={cardAuthNo}
              billingAddressCharge={chargeBillInfo}
              coworkerId={empId}
              actLDWArr={actLDWArr}
              actPolicyArr={actPolicyArr}
              swipeFlag={swipeFlag}
              finalagr={finalagr}
              paygridvalues={paygridvalues}
              disabled={
                isPendingAgrTransfer ||
                Acceptpaymentbuttonenable ||
                deliveryUnconfiremd ||
                CardErrorLbl ||
                isCommitmentInSIMS ||
                selectedCheckBoxCount == 0 ||
                (checkedAgr.every((el)=>el.store_number!=String(window.sessionStorage.getItem("storeNumber")))&& clubActivate==0?true:false)
              }
              setAcceptpaymentbuttonenable={setAcceptpaymentbuttonenable}
              cashamountcheckbox={cashamountcheckbox}
              getResponse={acceptPayment}
              TotalchkMoney={chkMoney}
              WidgetRadioBtn={radioBtn}
              Taxfield={getpayment}
              Totaldue={Number(totaldue) + Number(carryRentAmount)}
              GetPaymentVal={AgreementSelect}
              AcceptPay={AcceptPayments}
              ClubPay={AcceptclubPaymentParam}
              Reciept={ReceiptCategory}
              AgreementIdProp={agreementId}
              setpaymentMade={setpaymentMade}
              setpaymentMadeOptimized={(response:any)=>setpaymentMadeOptimized(response)}
              CustomerIdProp={customerId}
              Paymentchange={change}
              PayOrigin={paymentOrigin}
              avaliableCredits={availableCreds}
              getCardstate={getCardService_state}
              customerId={customerId}
              COAamountnum={coaamt}
              weblead={wldepositamount}
              Integratedsus={ipsuspense}
              Suspense={suspenseAmount}
              swipeCardAmt={swipeamt}
              //throwCheckedAgreement={checkedAgr}
              selectedAgreements={checkedAgr}
              throwtakeCommitmentData={takeCommitmentData}
              checkedAgr={checkedAgr}
              donationType={donationType}
              securityDepositData={securityDepositData}
              billingAddressValidation={billingAddressValidation}
              donationAmount={donationAmount}
              isOtherStoreAgrSelected={diffStoreErrLbl}
              exceededFTAmount={exceededAmount}
              setExceededFreeTime={setExceededAmount}
              cappedFreeTimeValues={cappedFreeTimeValues}
              cappedFreeTimeResponseValues={cappedFreeTimeResponseValues}
              currentRole={currentRole}
              showAutoPayPopup={EnrollAutoPayment}
              exchangeAgreementNumber = {exchangeAgreementNumber}
              isAutoPayEnabledAgreement = {isAutoPayEnabledAgreement}
              setIsAutoPayEnabledAgreement = {setIsAutoPayEnabledAgreement}
              pendingAgreements = {pendingAgreements}
              exchangeAgreementNumberMapper = {exchangeAgreementNumberMapper}
              isExchangeAgreementIndicator = {isExchangeAgreementIndicator}
              parentAgreementIdMapper = {parentAgreementIdMapper}
              isExchangeFuncEnabled = {isExchangeFuncEnabled}
              autoPayEnrolledAgreement = {autoPayEnrolledAgreement}
              setExchangeAgreementNumber = {setExchangeAgreementNumber}
            />
          ) : null}
          {isFromAccountMgmt === true ? (
            <RACButton
              color="primary"
              variant="contained"
              className={classes.ms2}
              disabled={diffStoreErrLbl}
              onClick={() => continueAMClicked()}
            >
              {" "}
              Continue
            </RACButton>
          ) : null}
        </Grid>
      </Grid>
      {/*Footer ends here*/}

      {/*personal check alert*/}
      <RACModalCard
        isOpen={personalcheckalert}
        maxWidth="xs"
        onClose={() => setpersonalcheckalert(false)}
        // eslint-disable-next-line react/no-children-prop
        children={personalcheckalertpopup()}
      />

      {/*personal check alert End here*/}
      {/*Activateclub popup starts here*/}
      <RACModalCard
        isOpen={clubStatusPopup.activateClub}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() =>
          setclubStatusPopup({ ...clubStatusPopup, activateClub: false })
        }
        // eslint-disable-next-line react/no-children-prop
        children={activateClubPopup()}
      />
      {/*DeActivateclub popup starts here */}
      <RACModalCard
        isOpen={clubStatusPopup.deactivateClub}
        closeIcon={true}
        borderRadius="20px"
        maxWidth="xs"
        onClose={() =>
          setclubStatusPopup({ ...clubStatusPopup, deactivateClub: false })
        }
        // eslint-disable-next-line react/no-children-prop
        children={deactivateClubPopup()}
      />
      <RACModalCard
        isOpen={deactivateClubCustomerRedirection}
        closeIcon={true}
        borderRadius="20px"
        maxWidth="xs"
        onClose={() => setdeactivateClubCustomerRedirection(false)}
        // eslint-disable-next-line react/no-children-prop
        children={deactivateClubCustomerRedirectionPopup()}
      />
      {/*DeActivate popup ends here*/}
      {/*masterloader popup starts here */}
      {masterloader ? (
        <Grid className={classes.masterLoader}>
          <Grid className={classes.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      {/*masterloader popup ends here */}
      {/*Error popup starts here */}
      <RACModalCard
        isOpen={errorPopup}
        closeIcon={true}
        borderRadius="20px"
        maxWidth="xs"
        onClose={() => seterrorPopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={ErrorMsgPopup()}
      />

      <RACModalCard
        isOpen={primaryAddressErrorFlag}
        // closeIcon={true}
        borderRadius="20px"
        maxWidth="xs"
        // onClose={() => setPrimaryAddressErrorFlag(true)}
        // eslint-disable-next-line react/no-children-prop
        children={primAddressErrorMsgPopup()}
      />

      <RACModalCard
        isOpen={postalCodeZip5ErrorFlag != 0 ? true : false}
        // closeIcon={true}
        borderRadius="20px"
        maxWidth="xs"
        // onClose={() => setPrimaryAddressErrorFlag(true)}
        // eslint-disable-next-line react/no-children-prop
        children={postalCodeZip5ErrorMsgPopup()}
      />


      <RACModalCard
        isOpen={somethingWentWrong&&!primAddPopUp&&!primaryAddressUpdated}
        borderRadius="20px"
        maxWidth="xs"
        onClose={() => setSomethingWentWrong(false)}
        // eslint-disable-next-line react/no-children-prop
        children={pageLoadSomeThingWentWrong()}
      />


      {/*Error popup ends here */}
      {/*Traveller check alert*/}
      <RACModalCard
        isOpen={travellercheckalert}
        maxWidth="xs"
        onClose={() => settravellercheckalert(false)}
        // eslint-disable-next-line react/no-children-prop
        children={travellercheckalertPopup()}
      />

      {/*Traveller check alert ends here*/}
      {/*Money order alert starts here*/}
      <RACModalCard
        isOpen={overpayMoneyord}
        maxWidth="xs"
        onClose={() => setoverpayMoneyord(false)}
        // eslint-disable-next-line react/no-children-prop
        children={Moneyorderalertpopup()}
      />
      {/*Money order alert ends here*/}
      {/*Money Order Information starts*/}
      <RACModalCard
        isOpen={addmoneyorder}
        maxWidth="xs"
        closeIcon={true}
        title="Money Order Information"
        onClose={() => setaddmoneyorder(false)}
        // eslint-disable-next-line react/no-children-prop
        children={MoneyOrderPopup()}
      />
      {/*Money Order Information ends*/}

      {/* Card tab whole popup starrts here */}

      {/* Swipe card starts here   */}
      <RACModalCard
        isOpen={Cardrequire.swipcard}
        maxWidth="xs"
        closeIcon={true}
        title="Swipe Card"
        onClose={() => setCardrequire({ ...Cardrequire, swipcard: false })}
        // eslint-disable-next-line react/no-children-prop
        children={swipeCardpopup()}
      />
      {/* Swipe card ends here */}

      {/*Edit Card information starts here*/}
      <RACModalCard
        isOpen={Cardrequire.editcrd}
        maxWidth="xs"
        title="Billing Address"
        onClose={() => close()}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={editCardPopup()}
      />
      {/*Edit Card information ends here*/}

      {/* Delete card popup of enrolled card starts here */}
      <RACModalCard
        isOpen={Cardrequire.APDeletePopup}
        maxWidth="xs"
        onClose={() => setCardrequire({ ...Cardrequire, APDeletePopup: false })}
        // eslint-disable-next-line react/no-children-prop
        children={DeletePopupForEnrolledCard()}
      />
      {/* Delete card popup of enrolled card ends here */}

      {/* delete popup starts here */}

      <RACModalCard
        isOpen={Cardrequire.delt}
        maxWidth="xs"
        onClose={() => setCardrequire({ ...Cardrequire, delt: false })}
        // eslint-disable-next-line react/no-children-prop
        children={DeletePopup()}
      />
      {/* delete popup end here */}

      {/* Transaction completed Modal Starts here */}
      <RACModalCard
        isOpen={Cardrequire.tran}
        maxWidth="xs"
        onClose={() => setCardrequire({ ...Cardrequire, tran: false })}
        // eslint-disable-next-line react/no-children-prop
        children={TransactionCompletePopup()}
      />

      <RACModalCard
        isOpen={Cardrequire.txt2pay}
        maxWidth="xs"
        onClose={() => setCardrequire({ ...Cardrequire, txt2pay: false })}
        // eslint-disable-next-line react/no-children-prop
        children={Txt2paypopup()}
      />

      <RACModalCard
        isOpen={Cardrequire.txt2payCardOnFile}
        maxWidth="xs"
        onClose={() => {setCardrequire({ ...Cardrequire, txt2payCardOnFile: false }); handleReset()}}
        title="Send Text for Payment"
        borderRadius={"4%"}
        closeIcon={true}
        // titleVariant="h3"
        TitleClassName={`${classes.text2payPopupTitle}`}
        // eslint-disable-next-line react/no-children-prop
        children={Txt2payCardOnFilepopup()}
      />

      {/*phone pay starts*/}

      <RACModalCard
        isOpen={Cardrequire.drpdown}
        maxWidth="xs"
        title="Phone Payment"
        closeIcon={true}
        onClose={() => closephonepopup()}
        // eslint-disable-next-line react/no-children-prop
        children={InphonePaymentOrigin()}
      />
      {/*phone-pay ends*/}

      {/* Footer components modal starts here */}

      {/* This popup is to Activate Club  */}
      {/* <Modal isOpen={ActivateClub} className="modal-dialog ">
        <div
          className="Terminatecls"
          id="terminateldw"
          data-bs-backdrop="static"
          data-testid="TerminateLDWTestId"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="terminateldw"
          aria-hidden="true"
        >
          <div className="delt">
            <div className="modal-content">
              <div className="modal-header border-0">
                <label className="modal-title title popup-text">
                  Activate Club
                </label>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  data-testid="TerminateLDWclosebntId"
                  onClick={() => close()}
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <p className="popup-text mt-3">
                  Your regular payment with club will increase by
                  <span className="label-txt-style font-16-custom">
                    {ClubArr1}
                  </span>
                  .Do you want to activate club?
                </p>
              </div>
              <div className="col-md-12 p-3 text-center">
                <button
                  type="button"
                  className="btn btn-primary mx-1 float-end"
                  data-testid="ActiveLDWContinueId"
                  onClick={() => YesActivatingClub()}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-1 float-end"
                  data-testid="TerminateCancelbtn"
                  data-bs-dismiss="modal"
                  onClick={() => close()}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}

      {/* This popup is to DeActivate Club  */}
      {/* <Modal isOpen={DeActivateClub} className="modal-dialog delt">
        <div
          className="Terminatecls"
          id="terminateldw"
          data-bs-backdrop="static"
          data-testid="TerminateLDWTestId"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="terminateldw"
          aria-hidden="true"
        >
          <div className="delt">
            <div className="modal-content">
              <div className="modal-header border-0">
                <label className="modal-title title popup-text">
                  De-Activate Club
                </label>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  data-testid="TerminateLDWclosebntId"
                  onClick={() => close()}
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <p className="popup-text mt-3">
                  By deactivating club, your regular payment will decrease by
                  <span className="label-txt-style font-16-custom">
                    {" "}
                    {ClubArr2}
                  </span>
                  . Do you want to Deactivate club?
                </p>
              </div>
              <div className="col-md-12 p-3 text-center">
                <button
                  type="button"
                  className="btn btn-primary mx-1 float-end"
                  data-testid="ActiveLDWContinueId"
                  onClick={() => YesDeActivatingClub()}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-1 float-end"
                  data-testid="TerminateCancelbtn"
                  data-bs-dismiss="modal"
                  onClick={() => close()}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}

      {/* This popup is to Terminate LDw  */}

      <RACModalCard
        isOpen={TerminateLDW}
        maxWidth="xs"
        title="Terminate LDW"
        onClose={() => setTerminateLDW(false)}
        // eslint-disable-next-line react/no-children-prop
        children={TerminateLDWpopup()}
      />

      {/* This popup is to Activate LDW  */}
      <RACModalCard
        isOpen={ActiveLDW}
        maxWidth="xs"
        title="Activate LDW"
        onClose={() => setActiveLDW(false)}
        // eslint-disable-next-line react/no-children-prop
        children={ActiveLDWpopup()}
      />

      <RACModalCard
        isOpen={Activepolicy}
        maxWidth="xs"
        title="Activate Policy"
        onClose={() => setActivepolicy(false)}
        // eslint-disable-next-line react/no-children-prop
        children={ActivepolicypopupModal()}
      />

      {/* This popup is to DeActivate LDW  */}
      <RACModalCard
        isOpen={DeActivepolicy}
        maxWidth="xs"
        title="DeActivate Policy"
        onClose={() => setDeActivepolicy(false)}
        // eslint-disable-next-line react/no-children-prop
        children={DeActivepolicypopupModal()}
      />

      {/* footer components modal ends here */}
      {showAutoPayPopup === true &&
      (getCardService_state.cardResp !== undefined ||
        getCardService_state.length > 0) &&
      getpayment !== undefined ? (
        <Autopay
          PropsCustomerName={customerName} //customer name should be sent
          PropsCustomerPhoneNumber={getpayment.paymentInfo.customerInfo[0].phoneNumber.toString()} //phone number should be sent
          PropsCustomerId={customerId} //customer id should be sent
          setAPpopupcompleted={setAPpopupcompleted} //send text autopay completed is used for submit button triggeration
          GetCardResponse={getCardService_state} //get card response should be sent
          GetPaymentInfoResponse={getpayment} //getpaymentinfo response should be sent
          setResponseObject={setResponseObject}
          CheckedAgreementIds={fromManageCard ? [] : agreementarraystate}  // checked  agreement ids should be sent
          setIsClubSelectedForAutopay={setIsClubSelectedForAutopay} // This returns is the club is used to send whether the club is selected for the autopay
          IsClubSelectedForAutopay={IsClubSelectedForAutopay} ///this is the state which is used to trigger whether it is selected for a
          PropsIsManageCardPopup = {fromManageCard}
          setAutoPayLoader = {setAutoPayLoader}
        />
      ) : null}

      {secondLevelAuthenOn !== null ? (
        <SecondFactor
          setTwoFactorCancelClick={() => setSecondLevelAuthenOn(null)}
          setTwoFactorCompleted={(e: any) => seconduthenResponses(e)}
          moduleName={secondLevelAuthenOn}
          CurrentRole={coWorkerRole}
        ></SecondFactor>
      ) : null}

      {/**
       * changes : Promo Free Days v2
       * Developer : Muzzamil
       * Desc : to open pop calling Promo Free Days Component component
       */}
      {openPromoFreeDays ? (
        <PromoFreeDays
          // totalAmount={checkedAgr !== undefined && checkedAgr.length > 0 && checkedAgr[0].Amount !== undefined ? checkedAgr[0].Amount : ''}
          // totalTax={checkedAgr !== undefined && checkedAgr.length > 0 && checkedAgr[0].Taxamount !== undefined ? checkedAgr[0].Taxamount : ''}
          // currentDueDate={checkedAgr !== undefined && checkedAgr.length > 0 && checkedAgr[0].CurrentDueDate !== undefined ? checkedAgr[0].CurrentDueDate : ''}
          // nextDueDate ={checkedAgr !== undefined && checkedAgr.length > 0 && checkedAgr[0].Nextduedate !== undefined ? checkedAgr[0].Nextduedate : ''}
          // agreementid= {adjustDueDateSelectedAgre!==undefined?adjustDueDateSelectedAgre:[]}
          adjustDueDateReq={checkedAgr !== undefined ? checkedAgr : []}
          ldwChangeArr={actLDWArr}
          policyChangeArr={actPolicyArr}
          // customerId={customerId !== undefined ? customerId : ''}
          isOpen={openPromoFreeDays}
          isClosed={(action: any) => setopenPromoFreeDays(action)}
          response={(res: any) => {
            editAdjustRes(res);
          }}
        />
      ) : null}
      {/**
       * changes : Adjust Due Date v2
       * Developer : Muzzamil
       * Desc : to open pop calling adjust due date component
       */}
      {openAdjustDueDate ? (
        <AdjustDueDatePopup
          // totalAmount={checkedAgr !== undefined && checkedAgr.length > 0 && checkedAgr[0].Amount !== undefined ? checkedAgr[0].Amount : ''}
          // totalTax={checkedAgr !== undefined && checkedAgr.length > 0 && checkedAgr[0].Taxamount !== undefined ? checkedAgr[0].Taxamount : ''}
          // currentDueDate={checkedAgr !== undefined && checkedAgr.length > 0 && checkedAgr[0].CurrentDueDate !== undefined ? checkedAgr[0].CurrentDueDate : ''}
          // nextDueDate ={checkedAgr !== undefined && checkedAgr.length > 0 && checkedAgr[0].Nextduedate !== undefined ? checkedAgr[0].Nextduedate : ''}
          // agreementid= {adjustDueDateSelectedAgre!==undefined?adjustDueDateSelectedAgre:[]}
          customerId={customerId}
          storeNumber={String(window.sessionStorage.getItem("storeNumber"))}
          adjustDueDateReq={checkedAgr !== undefined ? checkedAgr : []}
          ldwChangeArr={actLDWArr}
          defaultPaygridvalues={defaultPaygridvalues}
          taxExemption={taxExemption}
          isInitialLoadData={isInitialLoad}
          setIsInitialLoadUpdate={() => {
            setIsInitialLoad(false);
          }}
          configFlags={configFlags}
          customerInformation={customerInformation}
          policyChangeArr={actPolicyArr}
          // customerId={customerId !== undefined ? customerId : ''}
          isOpen={openAdjustDueDate}
          isClosed={(action: any) => setopenAdjustDueDate(action)}
          response={(res: any) => {
            editAdjustRes(res);
          }}
        />
      ) : null}
      {nsfPopUp?
       <NsfCheckPay
       receivedCheckedAgr={checkedAgr}
       isOpen={nsfPopUp}
       customerName={customerName}
       checkWrittenBy={checkedAgr[0]?.nsfData?.checkWrittenBy}
       checkNumber={checkedAgr[0]?.nsfData?.checkNumber}
       nsfAmt={checkedAgr[0]?.nsfData?.balanceAmount}
       nsfTax={checkedAgr[0]?.nsfData?.nsfFeeTax}
       balanceDuensfFee={checkedAgr[0]?.nsfData?.balanceFee}
       originalAmountnsfFee={checkedAgr[0]?.nsfData?.nsfFee}
       balanceDueFeeTax={checkedAgr[0]?.nsfData?.feeTax}
       nsfFeeTaxRate={checkedAgr[0]?.taxRate}
       nsfFee={checkedAgr[0]?.nsfFee}
       nsfFeetax={checkedAgr[0]?.Taxamount}
       originalNsfFee={checkedAgr[0]?.nsfData?.nsfFee}
       nsfTotal={checkedAgr[0]?.nsfData?.amount}
       nsfData={checkedAgr[0]?.nsfData}
       isClosed={(action: any) => setNsfPopUp(action)}
       response={(response: any) => {
         nsfResponse(response);
       }}
     />:null}
     
      <CreditChargeBack
        receivedCheckedAgr={checkedAgr}
        isOpen={cccb}
        cccb={cccb}
        setCccb={setCccb}
        response={(response: any) => setCccb(false)}
      />
      {/**
       * changes : Edit Schedule v2
       * Developer : Muzzamil
       * Desc : to open pop calling Edit Schedule Component
       */}
      {editSchedulePopUp ? (
        <EditSchedule
          // agreementid= {adjustDueDateSelectedAgre!==undefined?adjustDueDateSelectedAgre:[]}
          configFlags={configFlags}
          customerId={customerId}
          storeNumber={String(window.sessionStorage.getItem("storeNumber"))}
          ldwChangeArr={actLDWArr}
          optimizedPayment={configFlags.optimizedPayment}
          throwLoadingController={enableScheduleLoader}
          throwTaxPayload={taxPayload}
          throwIsOptimizedPay={
            configFlags.optimizedPayment == "1" ? true : false
          }
          policyChangeArr={actPolicyArr}
          editScheduleReq={checkedAgr !== undefined ? checkedAgr : []}
          paymentSchedule={
            paymentEditScheduleDropDown != undefined
              ? paymentEditScheduleDropDown
              : []
          }
          // customerId={customerId !== undefined ? customerId : ''}
          isOpen={editSchedulePopUp}
          isClosed={(action: any) => seteditSchedulePopUp(action)}
          response={(response: any) => {
            editAdjustRes(response);
          }}
        />
      ) : null}
       <PopUpCarryWaive
        checkedAgr={checkedAgr}
        isOpen={popUpCarrywaive}
        response={(res: any) => {
          editAdjustRes(res);
        }}
        isClosed={(action: any) => setPopUpCarryWaive(action)}
      />

      <PopUpCarryWaiveLate
        configFlags={configFlags}
        customerId={customerId}
        storeNumber={String(window.sessionStorage.getItem("storeNumber"))}
        checkedAgr={checkedAgr}
        isOpen={popUpCarrywaiveLate}
        response={(res: any) => {
          editAdjustRes(res);
        }}
        isClosed={(action: any) => setPopupCarrywaiveLate(action)}
      />

      {backRentPopUp ? (
        <PopUpBackRent
          configFlags={configFlags}
          isOpen={backRentPopUp}
          customerId={customerId}
          storeNumber={String(window.sessionStorage.getItem("storeNumber"))}
          backRentInfo={backRentInfo}
          taxRate={backRentTax}
          backRentEditInfo={backRentEditInfo}
          setBackRentInfo={setBackRentInfo}
          response={(res: any) => {
            otherIncomeItems(res);
          }}
          isClosed={(action: any) => setBackRentPopUp(action)}
        />
      ) : null}

      {/**
       * changes : Accept EPO v2
       * Developer : Sid V
       * Desc : to open pop calling Accept and EPO Schedule Component
       */}
      {showAcceptEpoPopup ? (
        <AcceptEpo
          configFlags={configFlags}
          customerId={customerId}
          storeNumber={String(window.sessionStorage.getItem("storeNumber"))}
          info={epoPopup}
          agreementChecked={checkedAgr}
          actualEpoAmount={
            checkedAgr[0] != undefined ? checkedAgr[0].actualEpoAmount : 0
          }
          actualPastDueRent={
            checkedAgr[0] != undefined ? checkedAgr[0].actualPastDueRent : 0
          }
          checkedAgr={checkedAgr}
          triggercloseEPOModal={acceptEpoCloseButton}
          triggerTotalOnclick={acceptEpoButton}
        />
      ) : null}
      {epoShedulePopup ? (
        <EpoSchedule
          agreementId={
            checkedAgr[0] != undefined ? checkedAgr[0].Agreementid : 0
          }
          coCustomerProps={customerId}
          newCoCustomer={newcustomer}
          epoScheduleCompletedFunction={() => acceptEpoCloseButton()}
          triggercloseEPOModal={acceptEpoCloseButton}
        />
      ) : null}

      {chargeoffPopupFlag ? (
        <ChargeoffPopup
          isOpen={chargeoffPopupFlag}
          configFlags={configFlags}
          customerId={customerId}
          storeNumber={String(window.sessionStorage.getItem("storeNumber"))}
          chargeOffValues={chargeoffInfo}
          // reasonType={[{label:"Collection agency",value:"COLLAGCY"},
          //              {label:"Court ordered",value:"COURTORD"},
          //              {label:"Charge off ",value:"CHARGEOFF"},
          //              {label:"Customer Initiated ",value:"CUSTINIT"}]}
          reasonType={chargeoffDropdownReason}
          response={(response: any) => {
            chargeoffOnclick(response);
          }}
          tax={0.0825}
          // editItemInfo={
          //   editchargeoffdatas != undefined
          //     ? editchargeoffdatas[0] != undefined
          //       ? editchargeoffdatas[0].restitutionInfo
          //       : undefined
          //     : undefined
          // }
          editItemInfo={
            editchargeoffdatas != undefined ? editchargeoffdatas : undefined
          }
          editItemID={
            editchargeoffdatas != undefined
              ? 1
              : // ? editchargeoffdatas[0] != undefined
                //   ? editchargeoffdatas[0].restitutiontypeAgree
                //   : undefined
                undefined
          }
          fixedChange={fixedChange}
        />
      ) : null}

      {/*Enroll autopay popup starts here*/}
      {manageCardsla ? (
        <SecondFactor
          setTwoFactorCancelClick={() => {
            setManageCardsla(false);
          }}
          setTwoFactorCompleted={() => {
            showAutoPayPopup = true;
            setManageCardsla(false);
            setpropsautopayopen(1);
          }}
          moduleName="Accept Payment"
          CurrentRole={coWorkerRoleCarryWaive}
        ></SecondFactor>
      ) : null}

      {needCarryWaveAuth ? (
        <SecondFactor
          setTwoFactorCancelClick={() => {
            setNeedCarryWaveAuth(false);
          }}
          setTwoFactorCompleted={() => {
            setNeedCarryWaveAuth(false);
            setPopupCarrywaiveLate(true);
            // setPopUpCarryWaive(true);
          }}
          moduleName="Adjust Late Fee"
          CurrentRole={coWorkerRoleCarryWaive}
        ></SecondFactor>
      ) : null}
      {needNSFAuth ? (
        <SecondFactor
          setTwoFactorCancelClick={() => {
            setNeedNSFAuth(false);
          }}
          setTwoFactorCompleted={() => {
            setNeedNSFAuth(false);
            setNsfPopUp(true);
          }}
          moduleName="NSFPayments"
          CurrentRole={coWorkerRoleCarryWaive}
        ></SecondFactor>
      ) : null}
      {needCCCBAuth ? (
        <SecondFactor
          setTwoFactorCancelClick={() => {
            setNeedCCCBAuth(false);
          }}
          setTwoFactorCompleted={() => {
            setNeedCCCBAuth(false);
            setCccb(true);
          }}
          moduleName="CCCBPayments"
          CurrentRole={coWorkerRoleCarryWaive}
        ></SecondFactor>
      ) : null}

      <RACModalCard
        isOpen={openModal}
        closeIcon={true}
        onClose={onCloseSecondfacClick}
        maxWidth={"xs"}
        className={classes.racSecondFacPopup}
        title={""}
        //eslint-disable-next-line react/no-children-prop
        children={openModalFunc()}
      />

      {/*spinner part*/}

      <RACModalCard
        isOpen={load}
        maxWidth="xs"
        onClose={() => setload(false)}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={spinnerpopup()}
      />
      {/* spinner part end here */}

      <RACModalCard
        isOpen={openSecondModal}
        closeIcon={true}
        onClose={okClick}
        maxWidth={"sm"}
        title={""}
        //eslint-disable-next-line react/no-children-prop
        children={openSecondModalFunc()}
      />

      <RACModalCard
        isOpen={openPinModal}
        className={classes.racSecondFacPopup}
        closeIcon={true}
        onClose={setChargeOffclose}
        maxWidth={"xs"}
        title={"Enter PIN to continue"}
        //eslint-disable-next-line react/no-children-prop
        children={openPinModalFunc()}
      />

      <RACModalCard
        isOpen={openActivityLoading}
        maxWidth="xs"
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={activityLoadingRenderer()}
      />

      <RACModalCard
        isOpen={openOtherIncome}
        maxWidth="sm"
        title={"Other Income"}
        closeIcon={true}
        borderRadius={"25px !important"}
        onClose={() => setOpenOtherIncome(false)}
        // eslint-disable-next-line react/no-children-prop
        children={renderOtherIncomeModal()}
      />

      <RACModalCard
        isOpen={TranFail}
        maxWidth="xs"
        // eslint-disable-next-line react/no-children-prop
        children={unAvailable()}
      />
      <RACModalCard
        isOpen={openRetentionAlert}
        maxWidth="xs"
        // closeIcon={true}
        // onClose={setChargeOffclose}
        title={retentionType == "FREETIME" ? "Retention Extension" : ""}
        borderRadius={"25px !important"}
        // eslint-disable-next-line react/no-children-prop
        children={renderRetentionAlert()}
      />
    </Grid>
  ) : nonInventoryPopUp == true && Number(configFlags?.isAcimaRecoveryAllowed) == 0 && Number(configFlags?.isOtherSaleAllowed) == 0 && Number(configFlags?.isXboxGiftCardAllowed) == 0 && Number(configFlags?.isXboxMembershipAllowed) == 0 ? (
    <NonInventorySales
      customerInfo={customerdata}
      customerInformation={customerInformation}
      throwConfigResponse={props.throwConfigValues}
      taxExemption={taxExemption}
      configFlag={configFlags.optimizedPayment}
      isOpen={nonInventoryPopUp}
      currentPermissions={userdata}
      defaultPaygridvalues={defaultPaygridvalues}
      // throwConfigResponse={props.throwConfigValues}
      // configFlag={configFlags.optimizedPayment}
      editItemId={
        EditNoninventoryData != undefined
          ? EditNoninventoryData[0] != undefined
            ? EditNoninventoryData[0].miscellaneousItemsNum
            : 0
          : 0
      }
      editItemInfo={
        EditNoninventoryData != undefined
          ? EditNoninventoryData[0] != undefined
            ? EditNoninventoryData[0].miscellaneousItemInfo
            : undefined
          : undefined
      }
      isClosed={(action: any) => setnonInventoryPopUp(action)}
      response={(response: any) => {
        nonInventoryOnClick(response);
      }}
    />
  ) : (
    <NonInventorySalesUpdated
      customerInfo={customerdata}
      customerInformation={customerInformation}
      throwConfigResponse={props.throwConfigValues}
      configFlag={configFlags.optimizedPayment}
      taxExemption={taxExemption}
      throwConfigFlags={configFlags}
      isOpen={nonInventoryPopUp}
      currentPermissions={userdata}
      xBoxData={xBoxEditData}
      acimaEditData={acimaEditData}
      defaultPaygridvalues={defaultPaygridvalues}
      // throwConfigResponse={props.throwConfigValues}
      // configFlag={configFlags.optimizedPayment}
      editItemId={
        EditNoninventoryData != undefined
          ? EditNoninventoryData[0] != undefined
            ? EditNoninventoryData[0].miscellaneousItemsNum
            : 0
          : 0
      }
      editItemInfo={
        EditNoninventoryData != undefined
          ? EditNoninventoryData[0] != undefined
            ? EditNoninventoryData[0].miscellaneousItemInfo
            : undefined
          : undefined
      }
      isClosed={(action: any) => setnonInventoryPopUp(action)}
      response={(response: any) => {
        nonInventoryOnClick(response);
      }}
      xBoxResponse={(response: any, editData: any) => {
        xBoxSale(response, editData);
      }}
      acimaResponse={(response: any, editData: any) => {
        acimaSale(response, editData);
      }}
    />
  )
};
export default RevenueRecog;
